import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { normalize, Normalized } from '@/shared/lib/normalize';
import { DEFAULT_CANVAS_ELEMENT } from '@/entities/Flow/constants';
import { Block } from '@/entities/Block/types';
import { calculateFreePosition } from '@/entities/Flow/utils/calculate';

import { ElementEditor, Flow } from '../types';

interface ElementsByFlow {
  [flowId: string]: Normalized<ElementEditor>;
}

@singleton()
export class FlowCanvasStore {
  elements: ElementsByFlow | null;

  constructor() {
    this.elements = null;

    eventEmitter.on(CustomEvents.InitializeFlow, (flow: Flow) => {
      if (this.elements) {
        this.elements[flow.id] = normalize(flow.canvas?.elements || [], 'blockId');
      } else {
        this.elements = { [flow.id]: normalize(flow.canvas?.elements || [], 'blockId') };
      }
    });

    eventEmitter.on(CustomEvents.CreateBlock, (block: Block) => {
      if (this.elements) {
        const ids = this.elements[block.flowId].ids;
        const entities = this.elements[block.flowId].entities;

        let x;
        let y;
        if (!block.position) {
          const result = calculateFreePosition(
              ids.map((id) => ({
                x: entities[id].left,
                y: entities[id].top,
                height: entities[id].sx.height,
                width: entities[id].sx.width
              }))
          );
          x = result.x;
          y = result.y;
        } else {
          x = block.position.x - (DEFAULT_CANVAS_ELEMENT.sx.width / 2);
          y = block.position.y - (DEFAULT_CANVAS_ELEMENT.sx.height / 2);
        }

        this.elements[block.flowId].ids.push(block.id);
        this.elements[block.flowId].entities[block.id] = {
          ...DEFAULT_CANVAS_ELEMENT,
          blockId: block.id,
          left: x,
          top: y,
        };
      } else {
        this.elements = {
          [block.flowId]: {
            ids: [block.id],
            entities: {
              [block.id]: {
                ...DEFAULT_CANVAS_ELEMENT,
                blockId: block.id,
                left: 500,
              },
            },
          },
        };
      }
    });

    eventEmitter.on(CustomEvents.DeleteBlock, (flowId: string, blockId: string) => {
      if (this.elements) {
        this.elements[flowId].ids.splice(this.elements[flowId].ids.indexOf(blockId), 1);
        delete this.elements[flowId].entities[blockId];
      }
    });

    makeAutoObservable(this);
  }
}

import { FC, memo } from 'react';

import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@/shared/ui/Tooltip';
import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import dayjs from 'dayjs';
import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';

import {ExternalConfigResponse, ExternalService} from '../model/types';

import styles from './styles.module.scss';
import {DownloadManualExternalServiceButton} from "@/features/DownloadManualExternalService";

export interface ExternalAppCardProps
  extends Omit<ExternalService, 'isActive' | 'companyId'> {
  actions?: JSX.Element;
  toggle?: JSX.Element;
}

export const ExternalAppCard: FC<ExternalAppCardProps> = memo(
  ({
    name,
    description,
    isHealth,
    serviceStartDate,
    lastCheckStatus,
    serviceUrl,
    actions,
    toggle,
    externalConfigResponse,
    id
  }) => {
    return (
      <Card
        variant='outlined'
        sx={{
          width: 300,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent style={{
            padding:"16px 8px 16px 16px"
        }}>
          <Stack justifyContent='space-between' flex={1} height='100%'>
            <Stack gap={1}>
              <Stack gap={2.5} direction='row' justifyContent='space-between'>
                <Stack gap={1} width='100%'>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography fontWeight={600}>{name || 'Название отсутствует'}</Typography>
                    {externalConfigResponse && externalConfigResponse.manualFileName ? <DownloadManualExternalServiceButton externalAppId={id} fileName={externalConfigResponse.manualFileName}/> : null}
                  </Stack>

                  <Typography noWrap fontSize='0.75rem'>
                    {description}
                  </Typography>
                </Stack>
                {toggle}
              </Stack>

              <Link to={serviceUrl} target='_blank'>
                <Typography className={styles.link} noWrap>
                  {serviceUrl}
                </Typography>
              </Link>
            </Stack>
          </Stack>
        </CardContent>
        <CardActions>
          <Stack gap={2} flex={1}>
            <Stack gap={0.5} width='100%' paddingX={1}>
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontWeight={600} fontSize='0.75rem'>
                  Состояние внешнего сервиса:
                </Typography>
                <Tooltip title='' placement='top'>
                  <span>
                    <Indicator type={isHealth ? IndicatorTypes.Success : IndicatorTypes.Error} />
                  </span>
                </Tooltip>
              </Stack>

              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  Последний отклик:
                </Typography>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  {lastCheckStatus ? dayjs(lastCheckStatus).format('HH:mm:ss DD.MM.YYYY') : 'Нет'}
                </Typography>
              </Stack>

              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  Начало работы:
                </Typography>
                <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                  {serviceStartDate ? dayjs(serviceStartDate).format('HH:mm:ss DD.MM.YYYY') : 'Нет'}
                </Typography>
              </Stack>
            </Stack>

            {actions}
          </Stack>
        </CardActions>
      </Card>
    );
  }
);

ExternalAppCard.displayName = 'ExternalAppCard';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import {
  SettingsBasic,
  SettingsIdm,
  SettingsUserAuthService,
  SettingsUserAuthStore,
  SettingsUserAuthTypes,
} from '@/entities/SettingsUserAuth';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { buildEntity } from '../lib/buildEntity';

@singleton()
export class CreateSettingsUserAuthService {
  open: boolean;

  constructor(
    private apiService: ApiService,
    private settingsUserAuthStore: SettingsUserAuthStore,
    private settingsUserAuthService: SettingsUserAuthService
  ) {
    this.open = false;

    makeAutoObservable(this);
  }

  async createSettingsUserAuth(type: SettingsUserAuthTypes) {
    if (type === SettingsUserAuthTypes.SettingsBasic) {
      const entity = {
        name: null,
        isActive: true,
        accountList: [],
      } as unknown as SettingsBasic;

      const settingsBasic = buildEntity(entity, { ...this.settingsUserAuthStore.settingsBasic! });

      const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
        ...this.settingsUserAuthStore.settingsUserAuth,
        settingsBasicList: Object.values(settingsBasic.entities),
      });

      this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

      return response.data;
    }

    if (type === SettingsUserAuthTypes.SettingsIdm) {
      const entity = {
        type: null,
        realm: null,
        rsaKey: null,
        issuer: null,
        methodGetToken: null,
        urlGetToken: null,
        clientId: null,
        clientSecret: null,
        timeRefresh: null,
      } as SettingsIdm;

      const settingsIdm = buildEntity(entity, {
        ...this.settingsUserAuthStore.settingsIdm!,
      });

      const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
        ...this.settingsUserAuthStore.settingsUserAuth,
        settingsIDMList: Object.values(settingsIdm.entities),
      });

      this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

      return response.data;
    }
  }
}

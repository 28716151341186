import React, { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { capitalize } from 'lodash';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { DashboardList } from '@/entities/Dashboard/widgets/DashboardList/DashboardList';
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";

import styles from './DashboardSidebar.module.scss';

export const DashboardSidebar: FC = observer(() => {

  const { set, remove } = useSearchParamsTemplate();

  const handleSidebarAdd = useCallback(() => {
    set([SearchParams.ShowCreateDashboardForm]);
    remove([SearchParams.ShowEditDashboardForm]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remove, set]);


  return (
    <Sidebar
      className={styles.root}
      textAddButton={`Создать ${dashboardTitles.base}`}
      onAdd={handleSidebarAdd}
      id='flowsOnboarding'
      width='100%'
      title={capitalize(dashboardTitles.pl)}
      addonAfter={(
        <div className={styles.list}>
          <DashboardList />
        </div>
      )}
    />
  );
});
import { SearchParams } from "@/hooks/useTemplateSearchParams";

const actionSearchParams = [
  SearchParams.ShowEditDashboardForm,
  SearchParams.ShowCreateDashboardForm,
  SearchParams.ShowDashboardDeleteModal,
  SearchParams.ShowDashboardPanelSettingsForm,
  SearchParams.DashboardPanelId,
  SearchParams.DashboardId,
];

export default actionSearchParams;

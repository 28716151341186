import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Role, RoleStore } from '@/entities/Role';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class UpdateRoleService {
  constructor(private apiService: ApiService, private roleStore: RoleStore) {
    makeAutoObservable(this);
  }

  async updateRole(role: Role) {
    const response = await this.apiService.instance.put('/editor/role/save', role);

    this.roleStore.roles!.entities[role.id] = response.data;

    return response.data;
  }
}

import React, { FC, memo, useEffect } from 'react';

import ReactJson, { ReactJsonViewProps } from 'react-json-view';

import styles from './JsonTree.module.scss';

export interface JsonTreeProps
  extends Omit<ReactJsonViewProps, 'theme' | 'name' | 'displayObjectSize'> {}

export const JsonTree: FC<JsonTreeProps> = memo((props) => {
  useEffect(() => {
    document.querySelectorAll('#json-tree .copy-to-clipboard-container')[0].remove();

    document.querySelectorAll('#json-tree .data-type-label').forEach((node) => {
      if (node.textContent === 'int' || node.textContent === 'float') {
        node.textContent = 'number';
      }

      if (node.textContent === 'bool') {
        node.textContent = 'boolean';
      }
    });
  }, [props.src]);

  return (
    <div id='json-tree' className={styles.root}>
      <ReactJson
        {...props}
        name={false}
        theme={{
          base00: 'transparent',
          base01: '#383830',
          base02: 'var(--input-background)',
          base03: '#75715e',
          base04: '#a59f85',
          base05: '#f8f8f2',
          base06: '#f5f4f1',
          base07: 'var(--text-color)',
          base08: '#f92672',
          base09: 'var(--label-text-color)',
          base0A: '#f4bf75',
          base0B: 'var(--label-text-color)',
          base0C: '#a1efe4',
          base0D: 'var(--edge-color)',
          base0E: 'var(--label-text-color)',
          base0F: 'var(--label-text-color)',
        }}
        displayObjectSize={false}
        quotesOnKeys={false}
      />
    </div>
  );
});

JsonTree.displayName = 'JsonTree';

import { FC, MouseEventHandler, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import Tooltip from '@/shared/ui/Tooltip';
import { PlayButton } from '@/shared/ui/Button/PlayButton';
import { container } from 'tsyringe';

import { BlockTestingService } from '../../../services/BlockTestingService';
import { Block, IntegrationTest } from '../../../types';

const blockTestingService = container.resolve(BlockTestingService);

export interface RunTestProps {
  test: IntegrationTest;
  block: Block;
}

export const RunTest: FC<RunTestProps> = observer(({ test, block }) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();

      void blockTestingService.runTest({
        integrationTest: test,
        exchange: {
          block,
          blockId: block.id,
          isIntegrationTest: true,
          isSendOutputConnect: false,
          inputBody: {
            stringBody: test.inputBody,
          },
        },
      });
    },
    [block, test]
  );

  return (
    <Tooltip title='Выполнить тест' placement='top'>
      <span>
        <PlayButton loading={blockTestingService.isLoadingRunTest} onClick={handleClick} />
      </span>
    </Tooltip>
  );
});

RunTest.displayName = 'RunTest';

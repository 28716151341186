import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Flow, Relation } from '@/entities/Flow/types';
import { RelationPositions } from '@/entities/Block/types';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';

/**
 * Стор для отрисовки линий между блоками
 */
@singleton()
export class BlockRelationStore {
  relations: { [flowId: string]: Relation[] };
  relationPositions: { [flowId: string]: RelationPositions };
  lineIdWhichHover: string | undefined;
  lineIdManyToOne: string | undefined;

  constructor() {
    this.relations = {};
    this.relationPositions = {};
    this.lineIdWhichHover = undefined;
    this.lineIdManyToOne = undefined;

    eventEmitter.on(CustomEvents.InitializeFlow, (flow: Flow) => {
      this.setRelationBlock.call(this, flow.id, flow.relationSet);
    });

    makeAutoObservable(this);
  }

  setRelationBlock(flowId: string, relations: Relation[]): void {
    if (this.relations) {
      this.relations[flowId] = relations;
    } else {
      this.relations = {
        [flowId]: relations,
      };
    }
  }
}

import React, { FC, useCallback, useMemo, useState } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TooltipProps } from '@mui/material/Tooltip';
import cn from 'classnames';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { useResolve } from '@/hooks/useResolve';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { IndexedDBService } from '@/service/indexedDB';
import { BellIcon } from '@/shared/ui/Icons/BellIcon/BellIcon';

import styles from '../OpenModalUnSaveConectForm/OpenModalUnSaveConectForm.module.scss';

export interface OpenUnSaveConnectFormProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
  showSaveDataIndexedDB: boolean;
}

export const OpenUnSaveConnectForm: FC<OpenUnSaveConnectFormProps> = ({
  blockId,
  showSaveDataIndexedDB,
  toolbarPlacement = 'left',
}) => {
  const { get } = useSearchParamsTemplate();
  const indexedDBStore = useResolve(IndexedDBStore);
  const indexedDBService = useResolve(IndexedDBService);
  const className = cn('', {
    [styles.buttonSave]: showSaveDataIndexedDB,
  });
  const connectorName = get(SearchParams.ConnectorName);
  const connectorType = get(SearchParams.ConnectorType);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const entityName: string = connectorType === 'processor' ? 'обработчику' : 'коннектору';
  const keyForIndexedDB = `fieldData${blockId}.${connectorName}`;

  const message = useMemo(() => {
    return `По данному ${entityName} имеются несохраненные данные, использовать ? `;
  }, [entityName]);
  const handleClick = useCallback(() => {
    setIsOpenModal(true);
  }, []);

  const deleteDataIndexedDBHandler = useCallback(() => {
    void indexedDBService.deleteFieldData(keyForIndexedDB);
    indexedDBStore.isShowIndexedDB = false;
    setIsOpenModal(false);
  }, []);

  const saveDataIndexedDBHandler = () => {
    indexedDBStore.indexedDbValues = indexedDBStore.connector;
    indexedDBStore.isShowIndexedDB = false;
    setIsOpenModal(false);
  };

  return (
    <>
      <Tooltip
        title='Найдена недозаполненная версия'
        placement={toolbarPlacement}
        disableInteractive
      >
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            id='settingsButton'
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
            className={className}
          >
            <BellIcon color='var(--text-color)' />
          </Button>
        </Box>
      </Tooltip>
      <Dialog fullWidth open={isOpenModal}>
        <DialogTitle onClose={() => setIsOpenModal(false)}>
          Найдена недозаполненная версия
        </DialogTitle>
        <DialogContent>{message}</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={deleteDataIndexedDBHandler}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} onClick={saveDataIndexedDBHandler}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import { FC, SVGProps } from 'react';

export const AcceptIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='10'
      height='8'
      viewBox='0 0 10 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M9.4001 1.99998L8.0001 0.599976L4.0001 4.59998L2.0001 2.59998L0.600098 3.99998L4.0001 7.39998L9.4001 1.99998Z'
        fill='currentColor'
      />
    </svg>
  );
};

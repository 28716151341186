import React, { FC } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { CardWithActions } from '@/shared/ui/CardWithActions/CardWithActions';
import { PythonLibsRequest } from '@/entities/PythonLib/stores/types';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';

import Tooltip from '@/shared/ui/Tooltip';

import styles from './PythonLibsCard.module.scss';

const pythonLibsService = container.resolve(PythonLibsService);

export interface MessageQueueCardProps {
  pythonLib: string;
  active: boolean;
  actions: JSX.Element;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  isFirst?: boolean;
  pythonLibs: PythonLibsRequest;
}

export const PythonLibsCard: FC<MessageQueueCardProps> = observer(
  ({ active, actions, onClick, pythonLibs }) => {
    const { isLoading } = pythonLibsService;

    return (
      <CardWithActions
        loading={isLoading}
        active={active}
        title={pythonLibs?.title || 'Название отсутсвует'}
        actions={actions}
        onClick={onClick}
      >
        <div className={styles.root}>
          <Tooltip title={pythonLibs?.description} placement='bottom'>
            <p className={cn(styles.description, { [styles.active]: active })}>
              {pythonLibs?.description}
            </p>
          </Tooltip>
          <p className={styles.date}>{pythonLibs?.version}</p>
        </div>
      </CardWithActions>
    );
  }
);

PythonLibsCard.displayName = 'PythonLibsCard';

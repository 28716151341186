import React from 'react';

import { toast } from 'react-toastify';
import cn from 'classnames';
import { ToastOptions } from 'react-toastify/dist/types';

import { CrossIcon } from '../Icons/CrossIcon/CrossIcon';
import { AcceptIcon } from '../Icons/AcceptIcon/AcceptIcon';
import { ExclamationIcon } from '../Icons/ExclamationIcon/ExlacamationIcon';

import styles from './Toast.module.scss';

const commonOptions: ToastOptions = {
  theme: undefined,
  closeButton: false,
  position: 'top-right',
  hideProgressBar: true,
  autoClose: 5000,
};
export const notify = {
  error: (text: string) =>
    toast.error(text, {
      ...commonOptions,
      icon: () => (
        <div className={cn(styles.icon, styles.error)}>
          <CrossIcon width={10} height={10} />
        </div>
      ),
    }),
  success: (text: string) =>
    toast.success(text, {
      ...commonOptions,
      icon: () => (
        <div className={cn(styles.icon, styles.success)}>
          <AcceptIcon width={10} height={10} />
        </div>
      ),
    }),
  warning: (text: string) =>
    toast.warning(text, {
      ...commonOptions,
      icon: () => (
        <div className={cn(styles.icon, styles.warning)}>
          <ExclamationIcon width={10} height={10} />
        </div>
      )
    })
};

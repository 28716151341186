import { makeAutoObservable } from 'mobx';
import { injectable } from 'tsyringe';

import { Attachment } from './types';

@injectable()
export class FileUploadStore {
  attachments: Attachment[];
  files: File[];
  isLoadingFiles: boolean;

  constructor() {
    this.attachments = [];
    this.files = [];
    this.isLoadingFiles = false;

    makeAutoObservable(this);
  }
}

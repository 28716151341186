import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { UserStore } from '@/entities/User';

@singleton()
export class DeleteUserService {
  open: boolean;
  selectedUserId: string | null;

  constructor(private apiService: ApiService, private userStore: UserStore) {
    this.open = false;
    this.selectedUserId = null;

    makeAutoObservable(this);
  }

  async deleteUser() {
    if (this.selectedUserId) {
      await this.apiService.instance.delete(`/editor/user/${this.selectedUserId}`);

      const index = this.userStore.users?.ids.indexOf(this.selectedUserId);

      if (index || index === 0) {
        runInAction(() => {
          this.userStore.users?.ids.splice(index, 1);
          delete this.userStore.users?.entities[this.selectedUserId!];
          this.selectedUserId = null;
        });
      }
    }
  }
}

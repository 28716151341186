import * as React from 'react';
import { FC, MouseEventHandler, useCallback, useState } from 'react';

import { Stack } from '@mui/system';
import { MenuItemUnstyled, MenuUnstyled, MenuUnstyledProps } from '@mui/base';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { SelectOption } from '@/shared/ui/CronSelect/types';
import { Observer, observer } from 'mobx-react-lite';
import { ButtonWithModal } from '@/shared/ui/ButtonWithModal/ButtonWithModal';
import { FilterIcon } from '@/shared/ui/Icons/FilterIcon/FilterIcon';

import styles from './FilterByStatuses.module.scss';

export interface FilterByStatusesProps {
  title: string;
  value: string[];
  options: SelectOption[];
  tooltip?: string | JSX.Element;

  onChange(status: string): void;

  onClose(): void;
}

const slotProps: MenuUnstyledProps['slotProps'] = {
  root: { placement: 'bottom-end', style: { marginTop: '8px' } },
};

export const FilterByStatuses: FC<FilterByStatusesProps> = observer(
  ({ title, options, tooltip, value, onChange, onClose }) => {
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
      event.stopPropagation();
      setAnchor(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(
      (close: () => void) => () => {
        onClose();
        close();
      },
      [onClose]
    );

    const handleItemClick = useCallback(
      (status: string): MouseEventHandler =>
        (event) => {
          event.stopPropagation();
          onChange(status);
        },
      [onChange]
    );

    return (
      <ButtonWithModal
        title={title}
        icon={<FilterIcon width={12} height={12} />}
        isActive={Boolean(value.length)}
        tooltip={tooltip}
        onClick={handleButtonClick}
        render={({ isOpen, close }) => (
          <Observer>
            {() => (
              <MenuUnstyled
                className={styles.menu}
                keepMounted={false}
                anchorEl={anchor}
                open={isOpen}
                slotProps={slotProps}
                onClose={handleMenuClose(close)}
              >
                {options.map((option) => {
                  return (
                    <MenuItemUnstyled key={option.value} onClick={handleItemClick(option.value)}>
                      <Stack direction='row' justifyContent='space-between' width='100%'>
                        <p>{option.label}</p>
                        <Checkbox checked={value.includes(option.value)} />
                      </Stack>
                    </MenuItemUnstyled>
                  );
                })}
              </MenuUnstyled>
            )}
          </Observer>
        )}
      />
    );
  }
);

FilterByStatuses.displayName = 'FilterByStatuses';

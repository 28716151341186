import { FC, SVGProps } from 'react';

export const LogoIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='176'
      height='40'
      viewBox='0 0 176 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M18.8631 4.61014C16.0222 5.22031 13.8631 6.91522 12.6586 9.44629C11.9995 10.8248 11.9313 11.209 11.8631 13.4463L11.7949 15.9322L13.0904 16.6101C13.8176 16.9717 14.4767 17.3107 14.5904 17.3559C14.6813 17.4011 14.7722 16.3841 14.7722 15.096C14.7722 12.2034 15.1813 10.6892 16.2949 9.46889C18.9995 6.50844 23.6131 6.96042 25.6358 10.3728L26.2495 11.4124L26.3858 17.8531C26.454 21.7401 26.5904 24.5197 26.7495 24.8587C27.7949 27.209 29.8176 28.5649 32.3858 28.5649C33.7495 28.5649 34.1586 28.4745 35.2267 27.887C37.4767 26.644 38.7267 23.9774 38.1813 21.5593C37.4995 18.5084 34.8858 16.7005 31.5449 16.9039C30.9086 16.9491 30.9086 16.9717 30.9086 18.531V20.0904L31.9995 19.9774C34.8176 19.6836 36.3176 22.8926 34.2267 24.7909C32.9086 25.9887 31.0676 25.7853 30.0449 24.3164C29.5676 23.6158 29.5449 23.4124 29.5449 17.9435C29.5449 14.8474 29.4313 11.887 29.3176 11.3446C28.7267 8.85872 27.0449 6.64404 24.8404 5.46889C23.6131 4.83613 23.0676 4.67793 20.7949 4.40675C20.4313 4.36155 19.5449 4.45194 18.8631 4.61014Z'
        fill='currentColor'
      />
      <path
        d='M17.2055 12.5194C16.91 12.7454 16.8191 13.0844 16.8646 13.7172C16.91 14.4177 17.0691 14.6437 17.5919 14.8923C18.1828 15.1635 18.3419 15.1635 18.8419 14.8923C20.2282 14.124 19.8646 12.203 18.3419 12.203C17.9555 12.203 17.4328 12.3612 17.2055 12.5194Z'
        fill='currentColor'
      />
      <path
        d='M21.9554 12.5649C21.7509 12.7457 21.5918 13.2655 21.5918 13.6723C21.5918 14.305 21.7282 14.5084 22.2509 14.8022C23.7963 15.5706 25.2736 13.8305 24.0918 12.6553C23.5463 12.1129 22.4554 12.0677 21.9554 12.5649Z'
        fill='currentColor'
      />
      <path
        d='M7.52202 16.9719C4.79474 17.5142 2.72656 20.0227 2.72656 22.8476C2.72656 25.8984 5.61293 28.7007 8.77202 28.7007C9.65838 28.7007 11.7266 27.9549 12.4084 27.3899C13.1357 26.7798 14.3175 25.0623 14.3175 24.6103C14.3175 24.2487 13.272 23.5481 11.772 22.8702C11.6584 22.825 11.5902 22.9606 11.5902 23.164C11.5902 23.9323 10.6811 25.0397 9.74929 25.4012C7.3402 26.373 4.93111 23.8871 6.04474 21.582C6.54474 20.5199 7.56747 19.8871 8.77202 19.8871C9.52202 19.8871 10.2947 20.2487 12.8629 21.7176L16.022 23.5707L17.4311 22.825C18.2266 22.4182 18.8629 22.034 18.8629 21.9436C18.8629 21.808 18.4538 21.5368 14.3402 19.051C11.1129 17.1301 9.38565 16.5877 7.52202 16.9719Z'
        fill='currentColor'
      />
      <path
        d='M22.4995 21.4916C17.4086 24.2035 16.1131 25.1526 15.1813 26.9605C14.4995 28.2713 14.4995 30.6893 15.1813 32.0001C16.5904 34.7345 19.7267 36.0001 22.5904 35.0509C23.5904 34.7119 24.9995 33.5142 25.6586 32.452C26.8176 30.6215 26.7267 27.9775 25.454 26.2374L24.7722 25.3334L23.3858 26.147C22.2949 26.8023 22.0904 27.0057 22.3631 27.1639C23.3176 27.7063 23.704 29.7402 23.0904 30.9379C22.5904 31.8871 21.7722 32.3165 20.4767 32.3165C19.4767 32.3165 19.2267 32.2261 18.5222 31.5255C17.8404 30.8475 17.7267 30.5763 17.7267 29.695C17.7267 27.7967 18.4767 27.0961 23.1131 24.6102L24.9995 23.6159V21.9662C24.9995 21.0848 24.9313 20.3391 24.8404 20.3391C24.7267 20.3391 23.6813 20.8588 22.4995 21.4916Z'
        fill='currentColor'
      />
      <path
        d='M70.2512 7.47021V27.0702H64.7741V16.6262L56.689 27.0702H52.3711V7.47021H57.8481V17.9702L65.8753 7.47021H70.2512Z'
        fill='currentColor'
      />
      <path
        d='M88.7425 11.9502V27.0702H83.3814V21.7502H79.0925V27.0702H73.7314V11.9502H79.0925V17.1582H83.3814V11.9502H88.7425Z'
        fill='currentColor'
      />
      <path
        d='M106.036 16.4582H101.109V27.0702H95.8349V16.4582H90.8795V11.9502H106.036V16.4582Z'
        fill='currentColor'
      />
      <path
        d='M124.002 19.3702C124.002 19.5942 123.992 19.8182 123.973 20.0422C123.953 20.2662 123.924 20.4995 123.886 20.7422H112.265C112.439 21.6195 112.796 22.2822 113.337 22.7302C113.898 23.1595 114.603 23.3742 115.453 23.3742C116.09 23.3742 116.651 23.2529 117.134 23.0102C117.636 22.7489 118.022 22.3942 118.293 21.9462H123.654C123.383 22.8049 122.987 23.5889 122.466 24.2982C121.944 24.9889 121.326 25.5769 120.611 26.0622C119.916 26.5475 119.124 26.9209 118.235 27.1822C117.365 27.4436 116.438 27.5742 115.453 27.5742C114.236 27.5742 113.106 27.3689 112.062 26.9582C111.038 26.5475 110.14 25.9875 109.367 25.2782C108.614 24.5502 108.025 23.7009 107.6 22.7302C107.175 21.7409 106.962 20.6675 106.962 19.5102C106.962 18.3529 107.175 17.2795 107.6 16.2902C108.025 15.3009 108.614 14.4515 109.367 13.7422C110.121 13.0142 111.019 12.4542 112.062 12.0622C113.106 11.6515 114.236 11.4462 115.453 11.4462C116.709 11.4462 117.858 11.6515 118.901 12.0622C119.964 12.4729 120.872 13.0329 121.625 13.7422C122.379 14.4515 122.958 15.2915 123.364 16.2622C123.789 17.2142 124.002 18.2502 124.002 19.3702ZM115.453 15.5342C114.661 15.5342 113.994 15.7302 113.453 16.1222C112.932 16.5142 112.565 17.0929 112.352 17.8582H118.699C118.196 16.3089 117.114 15.5342 115.453 15.5342Z'
        fill='currentColor'
      />
      <path
        d='M137.408 16.4302H131.728V27.0702H126.454V11.9502H137.408V16.4302Z'
        fill='currentColor'
      />
      <path
        d='M148.685 11.4462C149.883 11.4462 150.984 11.6515 151.989 12.0622C152.993 12.4542 153.853 13.0049 154.568 13.7142C155.302 14.4235 155.872 15.2729 156.278 16.2622C156.684 17.2515 156.886 18.3342 156.886 19.5102C156.886 20.6862 156.684 21.7689 156.278 22.7582C155.872 23.7475 155.302 24.5969 154.568 25.3062C153.853 26.0155 152.993 26.5755 151.989 26.9862C150.984 27.3782 149.883 27.5742 148.685 27.5742C147.97 27.5742 147.294 27.4809 146.657 27.2942C146.019 27.1262 145.42 26.8835 144.86 26.5662V32.5302H139.557V11.9502H143.208L143.875 13.1542C144.532 12.6129 145.266 12.1929 146.077 11.8942C146.889 11.5955 147.758 11.4462 148.685 11.4462ZM147.961 22.9822C148.463 22.9822 148.927 22.8982 149.352 22.7302C149.777 22.5435 150.144 22.3009 150.453 22.0022C150.781 21.6849 151.033 21.3209 151.206 20.9102C151.38 20.4809 151.467 20.0142 151.467 19.5102C151.467 18.5022 151.139 17.6715 150.482 17.0182C149.825 16.3649 148.985 16.0382 147.961 16.0382C146.937 16.0382 146.096 16.3649 145.44 17.0182C144.783 17.6715 144.454 18.5022 144.454 19.5102C144.454 20.5182 144.783 21.3489 145.44 22.0022C146.096 22.6556 146.937 22.9822 147.961 22.9822Z'
        fill='currentColor'
      />
      <path
        d='M176.001 11.9222V27.0422H171.857L171.393 25.9782C170.736 26.4822 170.012 26.8742 169.22 27.1542C168.428 27.4342 167.578 27.5742 166.67 27.5742C165.472 27.5742 164.37 27.3782 163.366 26.9862C162.361 26.5755 161.492 26.0155 160.758 25.3062C160.024 24.5782 159.454 23.7195 159.048 22.7302C158.642 21.7409 158.439 20.6582 158.439 19.4822C158.439 18.3062 158.642 17.2329 159.048 16.2622C159.454 15.2729 160.024 14.4235 160.758 13.7142C161.492 13.0049 162.361 12.4542 163.366 12.0622C164.37 11.6702 165.472 11.4742 166.67 11.4742C167.597 11.4742 168.457 11.6142 169.249 11.8942C170.06 12.1742 170.794 12.5755 171.451 13.0982L172.002 11.9222H176.001ZM167.394 22.9542C167.896 22.9542 168.36 22.8702 168.785 22.7022C169.21 22.5155 169.577 22.2729 169.886 21.9742C170.195 21.6569 170.437 21.2929 170.611 20.8822C170.785 20.4529 170.871 19.9955 170.871 19.5102C170.871 19.0249 170.785 18.5769 170.611 18.1662C170.437 17.7369 170.195 17.3729 169.886 17.0742C169.577 16.7569 169.21 16.5142 168.785 16.3462C168.36 16.1595 167.896 16.0662 167.394 16.0662C166.351 16.0662 165.501 16.3929 164.844 17.0462C164.206 17.6995 163.888 18.5209 163.888 19.5102C163.888 19.9955 163.974 20.4529 164.148 20.8822C164.322 21.2929 164.564 21.6569 164.873 21.9742C165.182 22.2729 165.549 22.5155 165.974 22.7022C166.418 22.8702 166.892 22.9542 167.394 22.9542Z'
        fill='currentColor'
      />
    </svg>
  );
};

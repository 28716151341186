import { FC } from 'react';

import { get } from 'lodash';
import { Stack } from '@mui/system';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionDetails } from '@mui/material';
import { Label } from '@/shared/ui/Label/Label';
import { Fields, FieldWhichRenderInsideFieldsProps } from '@/shared/ui/Fields/Fields';
import { amendDraggable } from '@/shared/lib/extendDndkit';

export const FieldAccordion: FC<FieldWhichRenderInsideFieldsProps> = ({
  control,
  errors,
  namePrefix,
  isNamePrefixProps,
  register,
  parameter,
  setValue,
  getValues,
  updateSkeleton,
  defaultValue,
}) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...amendDraggable(true)}
      >
        <Label>{parameter.label}</Label>
      </AccordionSummary>
      <AccordionDetails>
        <Stack id='infoConnector' spacing={2.5}>
          <Fields
            parameters={parameter.parameterList || []}
            register={register}
            control={control}
            namePrefix={namePrefix}
            isNamePrefixProps={isNamePrefixProps}
            errors={errors}
            defaultValue={defaultValue}
            setValue={setValue}
            getValues={getValues}
            updateSkeleton={updateSkeleton}
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

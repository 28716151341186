import * as yup from 'yup';

import { LOG_SETTINGS_LEVEL_OPTIONS } from './constants';

export const logSettingsValidationSchema = yup.object({
  level: yup.string().oneOf(LOG_SETTINGS_LEVEL_OPTIONS.map(({ value }) => value)),
  // .default(LOG_SETTINGS_LEVEL_OPTIONS[0].value),
  retentionTime: yup
    .number()
    .typeError('Необходимо ввести число')
    .integer('Должно быть целое число')
    .positive('Число должно быть положительным')
    .default(5184000000),
});

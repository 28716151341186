import { FC, MouseEvent, MouseEventHandler, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertFlowVersionService } from '../api/UpsertFlowVersionService';

const upsertFlowVersionService = container.resolve(UpsertFlowVersionService);

export interface UpsertFlowVersionButtonProps {
  icon?: JSX.Element;
  title?: string;
  isCreating?: boolean;
}

export const UpsertFlowVersionButton: FC<UpsertFlowVersionButtonProps> = observer(
  ({ icon, title, isCreating }) => {
    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
      (event: MouseEvent) => {
        event.preventDefault();
        if (isCreating) {
          upsertFlowVersionService.showCreateForm = true;
        } else {
          upsertFlowVersionService.showEditForm = true;
        }
      },
      [isCreating]
    );

    return (
      <Tooltip title={title || 'Настройки версии'} placement='top'>
        <span>
          <Button
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            {icon || <SettingsIcon />}
          </Button>
        </span>
      </Tooltip>
    );
  }
);

UpsertFlowVersionButton.displayName = 'UpsertFlowVersionButton';

export enum CustomEvents {
  InitializeFlow = 'initialize-flow',
  InitializeDashboard = 'initialize-dashboard',
  InitializeBlocks = 'initialize-blocks',
  CreateBlock = 'create-block',
  UpdateBlock = 'update-block',
  DeleteBlock = 'delete-block',
  UpdateCanvas = 'update-canvas',
  ChangeConnects = 'change-connects',
  GetConnectSkeleton = 'get-connect-skeleton',
}

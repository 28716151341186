import React, { useEffect, useState } from 'react'
import { Dayjs } from "dayjs";
import { DatePicker, Input as AntInput, InputRef } from "antd";
import { container } from "tsyringe";
import { observer } from "mobx-react-lite";
import { CalendarOutlined } from '@ant-design/icons';

import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { isValid } from './datemath';

const periodService = container.resolve(PeriodService);

const showTime = { format: 'HH:mm', showSecond: false };
const rawFormat = 'DD.MM.YYYY HH:mm';

const { RangePicker } = DatePicker;

const mapName = { start: 'from', end: 'to' };

export const PeriodPicker = observer(() => {
  const { set } = useSearchParamsTemplate();
  const [activeInput, setActiveInput] = useState<String>(null);

  const { period } = periodService;
  const [from, to] = period;
  const [rawFrom, rawTo] = period.map(({ raw }) => raw);


   const format = (val: Dayjs) => val.raw ?? val.format(rawFormat);

  const handlePick = ([newFrom, newTo]: [Dayjs, Dayjs], _, { range }) => {
    if (periodService.mode === 'history') {
      periodService.period = [newFrom, newTo];
    } else if (range === 'start') {
      periodService.period = [newFrom, to];
    } else {
      periodService.period = [from, newTo];
    }
    const [unxFrom, unxTo] = periodService.unixPeriod;
    setActiveInput(null);
    set(range === 'start' ? { from: String(unxFrom) } : { to: String(unxTo) });
  }

  const handleClick = ({ target }) => {
    const input = target.closest('.ant-picker-input')
      ?.querySelector('input');
    const name = input?.getAttribute('date-range');
    if (target.closest('.ant-input-suffix')) {
      setActiveInput(name === activeInput ? null : name);
      return;
    }
    if (target.closest('.ant-picker-input') && document.activeElement !== input) {
      target.focus();
    }
  }

  useEffect(() => {
    const handler = ({ target }) => {
      if (target.closest('.ant-picker-dropdown') || target.closest('.ant-input-suffix')) return;
      setActiveInput(null);
    }
    document.body.addEventListener('click', handler);
    return () => {
      document.body.removeEventListener('click', handler);
    }
  }, []);

  const Input = React.forwardRef<InputRef, React.HTMLAttributes<InputRef>>(
    ({ onChange, ...props }: { [key: string]: any }, ref) => {
      const name = mapName[props['date-range'] as ('start' | 'end')];
      const handleInputChange = (a) => {
        onChange(a);
      };

      const handleBlur = ({ target: { value } }) => {
        const prevValue = name === 'to' ? rawTo : rawFrom;
        if (prevValue === value) return;
        const newValue = isValid(value) ? value : prevValue;
        if (!newValue) return;
        if (name === 'to') periodService.to = newValue;
        else periodService.from = newValue;
        set({ [name]: newValue });
      }
      return (
        <AntInput
          {...props}
          className="ant-input ant-input-outlined"
          onChange={handleInputChange}
          onBlur={handleBlur}
          readOnly={false}
          ref={ref}
          suffix={<CalendarOutlined />}
        />
      );
    },
  );

  return (
    <RangePicker
      className="picker"
      picker="date"
      allowClear={false}
      showTime={showTime}
      value={period}
      open={!!activeInput}
      format={format}
      onCalendarChange={handlePick}
      onClick={handleClick}
      separator={null}
      suffixIcon={null}
      variant="borderless"
      components={{
        input: Input,
      }}
      // placement='left'
      // dropdownAlign={{
      //   points: ['cr', 'cl'],
      //   offset: [0, 4],
      //   overflow: { adjustX: 1, adjustY: 1 },
      // }}
      // popupStyle={{ border: 'solid 1px red' }}
    />
  );
});

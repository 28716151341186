import React, { FC, useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import Box from '@mui/system/Box';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { Stack } from '@mui/system';
import { container } from 'tsyringe';
import { isEmpty } from 'lodash';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { PythonLibInstall, PythonLibsRequest } from '@/entities/PythonLib/stores/types';
import { FieldList } from '@/shared/ui/Fields/components/FieldList/FieldList';

import styles from './PythonLibForm.module.scss';

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export interface UpsertPythonLibFormProps {
  pythonLibName?: string;

  onSuccess?(pythonLib: PythonLibInstall): void;
}

export const PythonLibForm: FC<UpsertPythonLibFormProps> = observer(({ pythonLibName }) => {
  const { remove, has } = useSearchParamsTemplate();

  const selectedFindLib = pythonLibsStore.libs?.data?.find(
    (item: PythonLibsRequest) => item.title === pythonLibName && item
  );
  const isEditForm: boolean = has(SearchParams.ShowEditPythonLibForm);
  const arr = [];
  const formValues = isEditForm ? selectedFindLib : arr.push(selectedFindLib);
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    control,
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: formValues,
    defaultValues: {
      title: '',
      version: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if (!pythonLibName) {
      reset({
        title: '',
        version: '',
      });
    }
  }, [pythonLibName, reset]);

  const buttonText = isEditForm ? 'Сохранить' : 'Установить библиотеку';
  const handleClose = useCallback(() => {
    remove([
      SearchParams.ShowEditPythonLibForm,
      SearchParams.ShowAddPythonLibForm,
      SearchParams.PythonLibName,
    ]);
  }, [remove]);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      if (!isEmpty(data.data)) {
        await pythonLibsService.installManyLib(data.data).then(() => {
          handleClose();
        });
      }
      if (isEmpty(data?.data)) {
        await pythonLibsService.installLib(data?.title, data?.version).then(() => {
          handleClose();
        });
      }
    },
    [handleClose, pythonLibName, pythonLibsService]
  );

  return (
    <Stack
      component='form'
      className='p-5'
      onSubmit={handleSubmit(onSubmit)}
      justifyContent='space-between'
    >
      <div>
        <Stack>
          {isEditForm ? (
            <div className={styles.root}>
              <FieldInput
                label='Наименование библиотеки'
                name='title'
                register={register}
                error={errors.title}
                description='Введите наименование библиотеки, длинной не более 100 символов'
              />
              <FieldInput
                label='Версия библиотеки'
                name='version'
                register={register}
                error={errors.version}
                description='Введите версию библиотеки'
              />
            </div>
          ) : (
            <FieldList
              control={control}
              name='data'
              renderElement={(index) => (
                <div>
                  <FieldInput
                    label='Наименование библиотеки'
                    name={`data[${index}].title`}
                    id={index.toString()}
                    register={register}
                    error={`${errors}[${index}].title`}
                    description='Введите наименование библиотеки, длинной не более 100 символов'
                  />
                  <FieldInput
                    label='Версия библиотеки'
                    id={index.toString()}
                    name={`data[${index}].version`}
                    register={register}
                    error={`${errors}[${index}].version`}
                    description='Введите версию библиотеки'
                  />
                </div>
              )}
            />
          )}
        </Stack>
      </div>
      <Box>
        <Button
          disabled={!isValid}
          loading={pythonLibsStore.isLoadingInstall}
          type='submit'
          variant={ButtonVariants.Primary}
          onClick={handleSubmit(onSubmit)}
          id='addLibPython'
        >
          {buttonText}
        </Button>
      </Box>
    </Stack>
  );
});

PythonLibForm.displayName = 'PythonLibForm';

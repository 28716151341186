import { FC, SVGProps } from 'react';

export const CheckIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='14'
      viewBox='0 0 16 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16 3.36364L13.4545 0.818115L6.18182 8.09106L2.54545 4.45459L0 7.00012L6.18182 13.1821L16 3.36364Z'
        fill='currentColor'
      />
    </svg>
  );
};

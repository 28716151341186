import { RouteObject } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';

import { DashboardPage } from './pages/DashboardPage/DashboardPage';

export const dashboardRoutes: RouteObject[] = [
  {
    path: 'dashboard/*',
    element: (
      <Layout>
        <DashboardPage />
      </Layout>
    ),
    children: [
      {
        path: ':dashboardId',
      },
    ],
  },
];

import {
  ChangeEventHandler,
  FC,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
  WheelEventHandler,
} from 'react';

import { Stack } from '@mui/system';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { Input, Menu, MenuItem } from '@/shared/ui';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

import { PlusButton } from '../../../Block/components/PlusButton/PlusButton';
import { ConnectSizes, ConnectTypes } from '../ConnectList/types';
import { Parameter } from '../../types';

import styles from './ConnectCreator.module.scss';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import {qwertyReplace} from "@/shared/lib/textUtils";

export interface ConnectorButtonProps {
  connectOptions: Parameter[];
  size?: ConnectSizes;
  type: ConnectTypes;
  blockId: string;
  id: string;
}

const joyrideService = container.resolve(JoyRideService);

export const ConnectCreator: FC<ConnectorButtonProps> = memo(
  ({ connectOptions, size = ConnectSizes.Small, type, blockId, id }) => {
    const { set, remove } = useSearchParamsTemplate();

    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
    const [connectOptionsState, setConnectOptionsState] = useState(connectOptions);

    useEffect(() => {
      setAnchor(document.getElementById(id ?? '') as HTMLButtonElement)
    }, [isOpenMenu]);

    const handleButtonClick = () => {
        setIsOpenMenu(true);
      };

    eventEmitter.on(type + blockId, handleButtonClick);

    const button = useMemo(() => {
      switch (size) {
        case ConnectSizes.Small: {
          return <PlusButton onClick={handleButtonClick} id={id} />;
        }
        case ConnectSizes.Large: {
          return (
            <div
              role='button'
              tabIndex={1}
              aria-label='add'
              className={styles.root}
              onClick={handleButtonClick}
              id={id}
            >
              <PlusButton />
            </div>
          );
        }
      }
    }, [size]);

    const handleMenuClose = (): void => {
      setIsOpenMenu(false);
      setConnectOptionsState(connectOptions);
    };

    const handleClickMenuItem = useCallback(
      (name: string) => () => {
        if (joyrideService.run) {
          joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
        }

        set([
          SearchParams.ShowConnectorForm,
          {
            [SearchParams.ConnectorName]: name,
            [SearchParams.ConnectorType]: type,
            [SearchParams.BlockId]: blockId,
          },
        ]);

        remove([
          SearchParams.ShowMessageDeliverySettings,
          SearchParams.ShowLogSettings,
          SearchParams.ConnectorId,
        ]);

        handleMenuClose();
      },
      [blockId, remove, set, type, joyrideService.stepIndex]
    );

    const handleKeyDown: KeyboardEventHandler<HTMLLIElement> = (event) => {
      if (event.key !== 'Escape') {
        event.stopPropagation();
      }
    };

    const handleWheel: WheelEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
    };

    const handleMenuSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
      (event) => {
        setConnectOptionsState(
          connectOptions.filter(
            (connectOption) => {
              let textLowerCase = event.target.value.toLowerCase()
              let textQwertyReplace = qwertyReplace(textLowerCase)

              return connectOption.name?.toLowerCase().includes(textLowerCase) ||
              connectOption.localeName?.toLowerCase().includes(textLowerCase) ||
              connectOption.description?.toLowerCase().includes(textLowerCase) ||
                connectOption.name?.toLowerCase().includes(textQwertyReplace) ||
                connectOption.localeName?.toLowerCase().includes(textQwertyReplace) ||
                connectOption.description?.toLowerCase().includes(textQwertyReplace)
            }
          )
        );
      },
      [connectOptions]
    );

    return (
      <>
        {button}
        <Menu
          open={isOpenMenu}
          anchorEl={anchor}
          onClose={handleMenuClose}
          keepMounted={false}
          MenuListProps={{ style: { width: '400px' } }}
          onWheel={handleWheel}
        >
          <li role='menuitem' key='search' onKeyDown={handleKeyDown} className={styles.search}>
            <Input
              focused={false}
              size='small'
              placeholder='Поиск'
              fullWidth
              endAdornment={<SearchIcon />}
              onChange={handleMenuSearch}
            />
          </li>
            <div style={{
                height:'360px',
                overflowY:"scroll",
                paddingRight:"4px"
            }}>
              {connectOptionsState.map(({ name, localeName, description }) => {
                return (
                  <MenuItem key={name} onClick={handleClickMenuItem(name)}>
                    <Stack spacing={0.5} id={name.replaceAll(' ', '')}>
                      <Typography fontWeight={600}>{localeName}</Typography>
                      <Typography color='var(--input-label-text-color)'>{description}</Typography>
                    </Stack>
                  </MenuItem>
                );
              })}
            </div>
        </Menu>
      </>
    );
  }
);

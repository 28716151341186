import { MouseEvent, PropsWithChildren, useState } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {
  DeleteOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { deleteAllSearchParams } from '@/shared/lib/deleteAllSearchParams';
import { SearchParams } from '@/hooks/useTemplateSearchParams';

import styles from './TreeNode.module.scss';
import ArticleIcon from '@mui/icons-material/Article';

const items = [
  {
    label: 'создать поток',
    key: 'addFlow',
    icon: <FileAddOutlined style={{ fontSize: '1em', marginRight: '1em' }} />,
  },
  {
    label: 'создать группу',
    key: 'addGroup',
    icon: <FolderAddOutlined style={{ fontSize: '1em', marginRight: '1em' }} />,
  },
  {
    label: 'редактировать',
    key: 'edit',
    icon: <SettingOutlined style={{ fontSize: '1em', marginRight: '1em' }} />,
  },
  {
    label: 'удалить',
    key: 'remove',
    icon: <DeleteOutlined style={{ fontSize: '1em', marginRight: '1em' }} />,
    danger: true,
  },
  {
    label: 'очистить логи',
    key: 'clearLogs',
    icon: <ArticleIcon style={{ fontSize: '1em', marginRight: '1em' }} />,
    danger: true,
  },
];

type ContextMenuState = {
  left: number;
  top: number;
} | null;

export function WithActions({ children }: PropsWithChildren) {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [contextMenu, setContextMenu] = useState<ContextMenuState>(null);

  const handleContextMenu = (event: MouseEvent) => {
    event.preventDefault();
    const position = {
      left: event.clientX + 2,
      top: event.clientY - 6,
    };
    setContextMenu(contextMenu ? null : position);
  };

  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setContextMenu(null);
  };

  const actionsMap: { [index: string]: Function } = {
    edit: () => {
      deleteAllSearchParams(searchParams, ['mode', 'itemId']);
      searchParams.set(SearchParams.ShowEditTFlowForm, 'true');
      setSearchParams(searchParams);
    },
    remove: () => {
      deleteAllSearchParams(searchParams, ['mode', 'itemId']);
      searchParams.set(SearchParams.ShowTFlowDeleteModal, 'true');
      setSearchParams(searchParams);
    },
    addFlow: () => {
      deleteAllSearchParams(searchParams, ['mode', 'itemId']);
      searchParams.set(SearchParams.ShowCreateTFlowForm, 'flow');
      navigate('/flow');
      setSearchParams(searchParams);
    },
    addGroup: () => {
      deleteAllSearchParams(searchParams, ['mode', 'itemId']);
      searchParams.set(SearchParams.ShowCreateTFlowForm, 'group');
      setSearchParams(searchParams);
      navigate('/flow');
      setSearchParams(searchParams);
    },
    clearLogs: () => {
      console.log('a');
      deleteAllSearchParams(searchParams, ['mode', 'itemId']);
      searchParams.set(SearchParams.ShowClearLogsModal, 'true');
      setSearchParams(searchParams);
    },
  };
  const actionsHandler = (key: string) => (domEvent: MouseEvent) => {
    domEvent.stopPropagation();
    const actionHandler = actionsMap[key];
    actionHandler();
    setContextMenu(null);
  };

  return (
    <div onContextMenu={handleContextMenu}>
      <span className={styles.description}>{children}</span>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference='anchorPosition'
        anchorPosition={contextMenu || null}
      >
        {items.map(({ label, key, icon, danger }) => (
          <MenuItem
            key={key}
            onClick={actionsHandler(key)}
            sx={danger && { color: 'var(--error-color)' }}
          >
            {icon}
            {label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

import { FC, MouseEventHandler, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { observer } from 'mobx-react-lite';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { container } from 'tsyringe';

import { CloneVersionService } from '../api/CloneVersionService';

const cloneVersionService = container.resolve(CloneVersionService);

export interface CloneVersionButtonProps {}

export const CloneVersionButton: FC<CloneVersionButtonProps> = observer(() => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    cloneVersionService.showDialog = true;
  }, []);

  return (
    <Tooltip title='Клонировать версию' placement='top'>
      <span>
        <Button
          circle
          loading={cloneVersionService.isLoading}
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <ContentCopyIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

CloneVersionButton.displayName = 'CloneVersionButton';

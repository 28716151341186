import { FC, memo, MouseEventHandler, useCallback } from 'react';

import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { useParams } from 'react-router-dom';

export interface EditPropertyProps {}

export const EditProperty: FC<EditPropertyProps> = memo(() => {
  const { set } = useSearchParamsTemplate();
  const { propertyId } = useParams();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      set([SearchParams.ShowPropertyForm, { [SearchParams.PropertyId]: propertyId }]);
    },
    [propertyId, set]
  );

  return (
    <Tooltip title='Изменить конфигурацию' placement='top'>
      <Box height='max-content'>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

EditProperty.displayName = 'EditProperty';

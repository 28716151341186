import { Connect } from '@/entities/Connect/types';

export interface ParseConnectFormDataToConnectParams {
  connect: Connect;
  connectType: string;
  connectName: string;
  blockId: string;
}

export const parseConnectFormDataToConnect = ({
  connect,
  connectType,
  connectName,
  blockId,
}: ParseConnectFormDataToConnectParams): Connect => {
  const result = {
    // @ts-ignore
    queryParams: {},
    params: {},
    ...connect,
    blockId: blockId,
  };

  if (connectType === 'processor') {
    result['typeProcessor'] = connectName;
  } else {
    result['typeConnect'] = connectName;
  }

  return result;
};

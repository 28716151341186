import { FC, memo, MouseEvent, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";

export interface DeleteDashboardProps {
  dashboardId: string;
}

export const DeleteDashboard: FC<DeleteDashboardProps> = memo(({ dashboardId }) => {
  const { set, remove } = useSearchParamsTemplate();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      remove(actionSearchParams);
      set([SearchParams.ShowDashboardDeleteModal, { [SearchParams.DashboardId]: dashboardId }]);
    },
    [dashboardId, set]
  );

  return (
    <Tooltip title={`Удалить ${dashboardTitles.base}`} placement='top'>
      <span>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteDashboard.displayName = 'DeleteDashboard';

import { FC, memo, MouseEvent, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";

export interface OpenDashFormProps {
  dashboardId: string;
}

export const EditDashboard: FC<OpenDashFormProps> = memo(({ dashboardId }) => {
  const { get, set, remove } = useSearchParamsTemplate();
  const navigate = useNavigate();

  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();

      navigate(dashboardId);
      const showEditDashboardForm = get(SearchParams.ShowEditDashboardForm);
      remove(actionSearchParams);
      if (showEditDashboardForm) return;
      set([SearchParams.ShowEditDashboardForm]);
    },
    [dashboardId, navigate, remove, set]
  );

  return (
    <Tooltip title={`Редактировать ${dashboardTitles.base}`} placement='top'>
      <span>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

EditDashboard.displayName = 'OpenEditDashboardForm';

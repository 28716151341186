import { Segment } from '../enums';
import { MonthValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class MonthContainer extends BaseContainer {
    getSegment() {
        return Segment.month;
    }
    cloneWith(d) {
        return new MonthContainer(d);
    }
    createValues(values) {
        return new MonthValues(values);
    }
}

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { UnsavedChangesModel } from '@/entities/UnsavedChanges';
import { Flow } from '@/entities/Flow/types';
import { Normalized } from '@/shared/lib/normalize';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';

@singleton()
export class UnsavedFlowChangesStore implements UnsavedChangesModel<Flow> {
  areThereChanges: boolean;
  changes: Normalized<Flow> | null;
  isLoadingSave: boolean;

  constructor() {
    this.areThereChanges = false;
    this.changes = null;
    this.isLoadingSave = false;

    eventEmitter.on(CustomEvents.CreateBlock, this.handleEvents.bind(this));
    eventEmitter.on(CustomEvents.UpdateBlock, this.handleEvents.bind(this));
    eventEmitter.on(CustomEvents.DeleteBlock, this.handleEvents.bind(this));
    eventEmitter.on(CustomEvents.ChangeConnects, this.handleEvents.bind(this));
    eventEmitter.on(CustomEvents.UpdateCanvas, this.handleEvents.bind(this));

    makeAutoObservable(this);
  }

  private handleEvents() {
    this.areThereChanges = true;
  }
}

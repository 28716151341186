import { FC, memo } from 'react';

import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { Box } from '@mui/system';

import styles from './ButtonClose.module.scss';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface ButtonCloseProps {
  onClose: () => void;
  id?: string;
  width?: string | number;
  height?: string | number;
}

export const ButtonClose: FC<ButtonCloseProps> = memo(({ onClose, id, width = 48, height = 48 }) => {
  eventEmitter.on('ButtonClose', onClose);

  return (
    <Box minWidth={width} height={height}>
      <button type='button' tabIndex={0} className={styles.root} onClick={onClose} id={id}>
        <CrossIcon width={16} height={16} />
      </button>
    </Box>
  );
});

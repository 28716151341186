import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { GFlow, SelectGroup } from '../types';

@singleton()
export class GFlowStore {
  gflows: GFlow;
  loadedKeys: string[];
  gflowsMap: { [key: string]: GFlow };
  flowsHash: { [key: string]: string };
  groups: SelectGroup;
  isLoadingGroups: boolean;
  isLoadingGFlows: boolean;
  isLoadingReorderGFlow: boolean;
  isLoadingSaveGFlow: boolean;
  isLoadingDeleteGFlow: boolean;
  selectedNodeId: string | null;

  constructor() {
    this.gflows = null;
    this.loadedKeys = [];
    this.gflowsMap = {};
    this.flowsHash = {};
    this.groups = null;
    this.isLoadingGroups = false;
    this.isLoadingGFlows = false;
    this.isLoadingReorderGFlow = false;
    this.isLoadingSaveGFlow = false;
    this.isLoadingDeleteGFlow = false;
    this.selectedNodeId = null;

    makeAutoObservable(this);
  }
}

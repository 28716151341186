import { FC, MouseEventHandler, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertPythonBeanService } from '../api/UpsertPythonBeanService';

const upsertPythonBeanService = container.resolve(UpsertPythonBeanService);

export interface EditPythonBeanButtonProps {}

export const EditPythonBeanButton: FC<EditPythonBeanButtonProps> = observer(() => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    upsertPythonBeanService.showForm = true;
  }, []);

  return (
    <Tooltip title='Настроить скрипт' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

EditPythonBeanButton.displayName = 'EditPythonBeanButton';

import _ from 'lodash';
import { DEFAULT_CANVAS_ELEMENT } from '@/entities/Flow/constants';

interface Point {
  x: number;
  y: number;
  height?: number;
  width?: number;
}

export const calculateCenterOfMass = (points: Point[]): Point => {
  let sumX = 0;
  let sumY = 0;
  let count = 0;

  for (const point of points) {
    sumX += point.x;
    sumY += point.y;
    count++;
  }

  const centerX = sumX / count;
  const centerY = sumY / count;

  return { x: centerX, y: centerY };
};

export const calculateFreePosition = (points: Point[]): Point => {
  /**
   * Основные переменные
   * @param stepSearch Шаг поиска свободных позиций (чем меньше тем точнее, но вырастает нагрузка)
   * @param marginBlock Отступы между блоками
   * @param [sizeBlockX, sizeBlockY] Половина длинны стороны вставляемого блока + отступ.
   *                                 Это расстояние на которое расширяются существующие блоки.
   */
  const stepSearch = 50;
  const marginBlock = 60;
  const sizeBlockX = DEFAULT_CANVAS_ELEMENT.sx.width / 2 + marginBlock;
  const sizeBlockY = DEFAULT_CANVAS_ELEMENT.sx.height / 2 + marginBlock;

  /**
   * Раскрутка по спирали двумерного массива в одномерный
   * @param entryArray Двумерный массив
   */
  const spiralRound = (entryArray: any[]) => {
    let exitArray = [];
    while (entryArray.length) {
      exitArray.push(...entryArray.shift());
      entryArray.map(row => exitArray.push(row.pop()));
      entryArray.reverse().map(row => row.reverse());
    }
    return exitArray.reverse();
  };

  /**
   * Узнаем координаты зоны поиска
   */
  let minX = (_.minBy(points, 'x')?.x ?? 0) - sizeBlockX - stepSearch;
  let minY = (_.minBy(points, 'y')?.y ?? 0) - sizeBlockY - stepSearch;
  const maxByX = _.maxBy(points, 'x')
  let maxX = (maxByX?.x ?? 0) + (maxByX?.width ?? 0) + sizeBlockX + stepSearch;
  const maxByY = _.maxBy(points, 'y')
  let maxY = (maxByY?.y ?? 0) + (maxByY?.height ?? 0) + sizeBlockY + stepSearch;

  /**
   * Длинна зоны поиска и ее центр
   */
  const lengthX = maxX - minX;
  const lengthY = maxY - minY;
  const centerX = minX + lengthX / 2;
  const centerY = minY + lengthY / 2;

  /**
   * Если зона поиска прямоугольная, то делаем ее квадратной, иначе поиск от центра будет идти по овальной спирали
   */
  if (lengthX > lengthY) {
    maxY += (lengthX - lengthY) / 2;
    minY -= (lengthX - lengthY) / 2;
  } else {
    maxX += (lengthY - lengthX) / 2;
    minX -= (lengthY - lengthX) / 2;
  }

  /**
   * Смещаем зону поиска в центр
   */
  maxX -= centerX;
  minX -= centerX;
  maxY -= centerY;
  minY -= centerY;

  let matrixFreePoints: { x: number; y: number; inBlocksPoint: { point: Point, inBlock: boolean } | undefined }[][] = [];

  /**
   * Узнаем находится ли проверяемая точка в блоке
   * @param pointX Координата X проверяемой точки
   * @param pointY Координата Y проверяемой точки
   */
  const inBlockPoint = (pointX: number, pointY: number) => points.map((point) => {
      const { x, y, width = 0, height = 0 } = point;
      return ({
        point,
        inBlock: y - sizeBlockY < pointY
          && pointY < y + height + sizeBlockY
          && x - sizeBlockX < pointX
          && pointX < x + width + sizeBlockX,
      });
    },
  ).find(({ inBlock }) => inBlock);

  /**
   * Создаем карту свободных/занятых точек
   */
  let i = 0;
  for (let y = minY; y < maxY; y += stepSearch) {
    matrixFreePoints.push([]);
    for (let x = minX; x < maxX; x += stepSearch) {
      const inBlocksPoint = inBlockPoint(x, y);
      matrixFreePoints[i].push({ x, y, inBlocksPoint });
    }
    i++;
  }

  let correctFreeX = 0;
  let correctFreeY = 0;
  let firstFreePosition = {}

  /**
   * Раскручиваем карту свободных/занятых точек
   * Ищем лучшие свободные места для блока
   */
  let correctFreePosition = spiralRound(matrixFreePoints).find((point, index, array) => {
    // пропускаем первую точку и последнюю, тк алгоритм использует пред. и след. точки
    if (!index || index === array.length - 1) return false
      // ищем точку не в блоке
      if (point && point?.inBlocksPoint === undefined) {
        // сохраняем первую свободную точку на случай если не найдется лучших вариантов расположения блока
        if (_.isEmpty(firstFreePosition)) firstFreePosition = point
        // узнаем есть ли за нами или перед нами блок
        let flag = 0
        if (array[index - 1]?.inBlocksPoint) flag = -1;
        if (array[index + 1]?.inBlocksPoint) flag = 1;
        if (flag) {
          const { x, y, width, height } = array[index + flag]?.inBlocksPoint?.point;
          const centerBlockX = x + width / 2;
          const centerBlockY = y + height / 2;
          // узнаем на какой оси находится наша точка относительно блока
          if (point.y === array[index + flag].y) {
            // проверяем есть ли свободная точка относительно центра оси Y блока
            if (!inBlockPoint(point.x, centerBlockY)) {
              // сохраняем лучшее положение по оси Y
              correctFreeY = centerBlockY;
              // ищем с какой стороны блока по оси X находится точка
              if (point.x < centerBlockX) {
                // проверяем есть ли свободная точка оси X вплотную с блоком слева
                if (!inBlockPoint(x - sizeBlockX, centerBlockY)) {
                  correctFreeX = x - sizeBlockX;
                }
              } else {
                // проверяем есть ли свободная точка оси X вплотную с блоком справа
                if (!inBlockPoint(x + width + sizeBlockX, centerBlockY)) {
                  // сохраняем лучшее положение по оси X
                  correctFreeX = x + width + sizeBlockX;
                }
              }
              return true;
            }
          } else {
            if (!inBlockPoint(centerBlockX, point.y)) {
              correctFreeX = centerBlockX;
              if (point.y < centerBlockY) {
                if (!inBlockPoint(centerBlockX, y - sizeBlockY)) {
                  correctFreeY = y - sizeBlockY;
                }
              } else {
                if (!inBlockPoint(centerBlockX, y + height + sizeBlockY)) {
                  correctFreeY = y + height + sizeBlockY;
                }
              }
              return true;
            }
          }
        }
      }
    return false;
  });

  if (_.isEmpty(correctFreePosition)) correctFreePosition = firstFreePosition

  return {
    x: (correctFreeX ? correctFreeX : correctFreePosition?.x) - sizeBlockX + marginBlock,
    y: (correctFreeY ? correctFreeY : correctFreePosition?.y) - sizeBlockY + marginBlock
  };
};

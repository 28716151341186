import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import useRedux from '../../../hooks/useRedux';
import useFetch from '../../../hooks/useFetch';


function Preload(props) {
  const { children } = props;

  const href = useSelector((state) => state.location.path);

  const [settings, setSettings] = useRedux('settings');
  const fetch = useFetch();

  const getSettings = async () => {
    const response = await fetch({
      url: `editor/settings/current`,
    });
    setSettings(response);
  };

  useEffect(() => {
    if (href) {
      getSettings();
    }
  }, [href]);

  return children;
}

Preload.propTypes = {};

export default Preload;

import { FC } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { RegistrableValues } from '@/shared/lib/types';

import { BlockService } from '../../Block/services/BlockService';
import { CreatingTwoConnectsFormContainer } from './CreatingTwoConnectsFormContainer/CreatingTwoConnectsFormContainer';
import {ConnectTypes} from "@/entities/Connect/components/ConnectList/types";
import {container} from "tsyringe";
import {AllConnectService} from "@/entities/Connect/services/AllConnectService";

const allConnectService = container.resolve(AllConnectService);
export const CreatingTwoConnectsDrawerContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { remove, get, has } = useSearchParamsTemplate();

  const connectorName = get(SearchParams.ConnectorName);
  const targetId = get(SearchParams.TargetId);
  const sourceId = get(SearchParams.SourceId);

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId || '' });
  const connectorNameLocale = allConnectService.parameterByName(connectorName, ConnectTypes.Input)?.localeName

  const title = `Соединяем ${blockService.blocks?.entities[sourceId]?.name} с ${blockService.blocks?.entities[targetId]?.name} по ${connectorNameLocale}`;

  const handleDrawerClose = () => {
    remove([
      SearchParams.ShowCreatingTwoConnectsForm,
      SearchParams.SourceId,
      SearchParams.TargetId,
      SearchParams.ConnectorName,
    ]);
  };

  return has(SearchParams.ShowCreatingTwoConnectsForm) ? (
    <div className='col-span-1'>
      <Drawer largeTitle title={title} onClose={handleDrawerClose}>
        <CreatingTwoConnectsFormContainer />
      </Drawer>
    </div>
  ) : null;
});

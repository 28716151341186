import { FC, useCallback, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { FlowVersionStore, Version } from '@/entities/FlowVersion';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';

import { UpsertFlowVersionService } from '../api/UpsertFlowVersionService';

const flowVersionStore = container.resolve(FlowVersionStore);
const upsertFlowVersionService = container.resolve(UpsertFlowVersionService);

export interface UpsertFlowVersionFormProps {
  versionId: string;
}

export const UpsertFlowVersionForm: FC<UpsertFlowVersionFormProps> = observer(({ versionId }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Version>({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: upsertFlowVersionService.showEditForm
      ? flowVersionStore.versions?.entities[versionId]
      : undefined,
    mode: 'onChange',
  });

  const title = upsertFlowVersionService.showEditForm
    ? 'Изменить версию потока'
    : 'Создать версию потока';

  const buttonText = upsertFlowVersionService.showEditForm ? 'Сохранить' : 'Создать';

  const handleDrawerClose = () => {
    upsertFlowVersionService.showEditForm = false;
    upsertFlowVersionService.showCreateForm = false;
  };

  const handleOkButtonClick = useCallback<SubmitHandler<Version>>((data) => {
    upsertFlowVersionService.upsertFlowService(data).then(handleDrawerClose);
  }, []);

  useEffect(() => {
    return () => {
      upsertFlowVersionService.showEditForm = false;
      upsertFlowVersionService.showCreateForm = false;
    };
  }, []);

  useEffect(() => {
    upsertFlowVersionService.showEditForm = false;
    upsertFlowVersionService.showCreateForm = false;
  }, [versionId]);

  return upsertFlowVersionService.showEditForm || upsertFlowVersionService.showCreateForm ? (
    <Drawer width={434} onClose={handleDrawerClose} title={title}>
      <Box>
        <Stack paddingX={2.5} component='form' gap={2.5}>
          <FieldInput
            label='Наименование версии'
            error={errors.name}
            name='name'
            register={register}
            description='Введите название потока, длинной не более 100 символов'
          />
          <FieldInput
            textarea
            label='Описание версии'
            error={errors.description}
            name='description'
            register={register}
            description='Введите описание потока, длинной не более 1000 символов'
          />
        </Stack>
        <Box paddingX={2.5} paddingBottom={2.5}>
          <Button
            disabled={!isValid}
            loading={upsertFlowVersionService.isLoading}
            onClick={handleSubmit(handleOkButtonClick)}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </Drawer>
  ) : null;
});

UpsertFlowVersionForm.displayName = 'UpsertFlowVersionForm';

import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Icon7({
  size = 16,
}) {
  return (
    <SvgIcon
      viewBox={`0 0 ${size} ${size}`}
    >
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={`${size}px`}
        height={`${size}px`}
        viewBox={`0 0 ${size} ${size}`}
        enableBackground={`new 0 0 ${size} ${size}`}
        xmlSpace="preserve"
      >
        <image
          id="image0"
          width={size}
          height={size}
          x="0"
          y="0"
          href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABGdBTUEAALGPC/xhBQAAACBjSFJN
        AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
        CXBIWXMAAAsSAAALEgHS3X78AAAGvklEQVRYw6WWWYxlVRmFv/Xvc6eq6iq6W7pkSIM0LYiRQAIh
        RKFNlCFAeFCjDxgh4hBDDIoGJSZqjMIDaggYhxiHB+ILRGPUoMGhRX0QIsZIM4hCA900UEUPVXVv
        3Xvu3v/vw71IN13VXS1/snOmnbPWWXutfx8xrp+8cBUvPLWEgMBSiUbxMJxEiQYlGmRvUmiQo0n2
        FsNo/e849DbDaDP0DnW0qMsktXcsaASUmNx8Cr8470xeWwbwh3g/c7uHLNctWOwAXjINQUwDM8D0
        MYwZYApoupInliPT0PJLc1z9dH0YgQpg30KfxQXjC++8j6/+7gomsRsaDG4oVBPjeWLNFQBD4U2j
        3LuuuXzTBnuZA4MZ5h55nCufD351og4lANCshunW7ZeXdntwI3DH2gFXrtFSxqfn6tmHCb+7oboq
        g37uPbv38CUA1EzDMtnqT1RWPjO+NwT8dYycAKN8pefrpxf8+NxJSzqwe5FLno3DCBCIGK3f7PhW
        Y/z8/xoBFUSO0Jua6n22TQ8vKfX7sLgrH05AIwp7gd0HKRCvUwWNU3Vjz487fck35nWNeavndnPB
        03EIgXAsBRoAtx+kgF6PCqA0NuW0Rf5aYkhLB4h+j+H8a0wIlF5/A53W/u8gavBPARvGKhwxBbkY
        +7ptW6o75GhSlwp3GEamXwYsQ7RbrXdZc/qSWjP3i76lkUIjAuun2+zp9FCax4dtJQ1+sC9O+uEM
        8xv9EI6HVl2M7rBDLk3d8757uk0avsxb1OVUepxClxN4lM28l035sscWp/c8une9yhKNhsIPSgsw
        6oQvPrVEpZoSjdSPyWKRKbQoUa3QCZt0fYrvvvs2Wp//DfW/j7sOry/HB128b+Q+lDqInkHus/fl
        W0+67pJnX3zyOTv74rM8bTmTh87VqwT+EtewZ3+f5ZggEVTWV/EGhUSOJiVa1MtOvX8vvV7FWSc9
        w4e+98XqwONbhtWpz13Utv4DEY67E2V8dCc8RudJD8Qv/7yNc86E6Wm2ff0a/rhJx9LhxrG5fR/+
        0i4oA2u3u95pD9f5wP/m7lvdvSY8efHw4kS4wiN78VaE36+pzqWVTIOYDm6/+DATHrU2/9zJz+xg
        QTtpTk8SEVB0p5K2CmVDTXdDNoqBFwLzlmF9L3yOhT45Sdr4xnilFa2ZwJbfB90d/4B+j/bMTEVE
        Drg23K/DcQuqQCAnNLK4CY9CCuJmSX+nTar31UUH6b5mAmV+H6r7lIhkyTLB1gi/K7BRngKQYz66
        tiC7U5H0MyXu8lJUtVqlVkbHbeIVBdJawM/YHiz+6wmUs+oN5p2d4Ov4KejNiCyUxrsPCBRyiATa
        I+lKiZ5MVuph2Oxmyi1nveqpo4G/9cGg/+QTbFjfIaWUOovCT05fVrJtSsqSVTIhGUqGZKEkyQwz
        u96SzaVGs4oIV6NN2njyoaY+GoH6pWU07NNd7FXWSNmqtE3JvmQyzFKyZMgMS8LMULIiM1myb6bK
        7qsaVVrYuSOn9hTV5rPpf/KEQ95/xBi+7aGgt+M/5N6SRYRLmoniD0fEae5eoniKCMKdcexKeKRw
        f7AsdN9uE50cySSbjDJ7Ggc+9obDMFZV4NxHA3a9wPL+fVhKWEqAviWz0yRlSUlmSEImlCwkSzIN
        ZHZ9NTOV1WykyCV8at2K4EdMwfB5GHa7dKYmqojICl0fig+Ge0FWGeBykIGDcAdLODdJPBIRVe4t
        Z3UmqE7cuqrKKypw/pNBnn8OHwySKWWr0hkyu0MmZKbRVxsjHxgyyyZLZnavKX0bl3Wm23l52KBz
        +nnMfWD1tK/4ZPgi+KCr5rqpQidZ/XL3+5hP4coY1bjPjfi7u8wqYBfZP6GGUGXkqDj+gnew6z2N
        I5p8RQIhkCrLvbrQ48OSLsKUwSo8wBzCwDzAzNwJs486MT/s54rw3Nx0ylHBV10CM0bGqgxVdpmZ
        Idkr2UZmKAmZlbEBv6Eq/bp4pGtvuTCHmlSzs0cFX5WAEmN3j4ggja4lMGEyJMsyVTL7az3QzUmJ
        5kTH777zn0yecz47L13bRrsyATEGNST9dmQ+FUkuyTENZaokq83sI52p5GqQzCI6J25m5xVr3+VX
        JBCAJLeR5D+StF1mTZmZzMxMjTGpjyvZIzKrcl0KE5O0Tp5ZM/iqBBobwdoT4e4WEbWkqyTdJtMT
        Mj2DbLtkVyP9GLCZ49fn1J5k8vQtPHbhsf3jrDr73D8Fg107AUySRwRIVbh3POfFCPCSjYyrYaTZ
        U3n8ivYxga+qAIC9+i6PCEVEQsqIxYgQRPIcTiUQVBOtYwYH+C+ADF0oNZdMSwAAAKhlWElmSUkq
        AAgAAAAFABoBBQABAAAASgAAABsBBQABAAAAUgAAACgBAwABAAAAAgAAABMCAwABAAAAAQAAAGmH
        BAABAAAAWgAAAAAAAABIAAAAAQAAAEgAAAABAAAABgAAkAcABAAAADAyMTABkQcABAAAAAECAwAA
        oAcABAAAADAxMDABoAMAAQAAAP//AAACoAQAAQAAACAAAAADoAQAAQAAACAAAAAAAAAAUm3ogQAA
        ACV0RVh0ZGF0ZTpjcmVhdGUAMjAyNC0wNS0xN1QxNTo1NTowMSswMDowMKXBdakAAAAldEVYdGRh
        dGU6bW9kaWZ5ADIwMjQtMDUtMTdUMTU6NTU6MDErMDA6MDDUnM0VAAAAKHRFWHRkYXRlOnRpbWVz
        dGFtcAAyMDI0LTA1LTE3VDE1OjU1OjAxKzAwOjAwg4nsygAAABV0RVh0ZXhpZjpDb2xvclNwYWNl
        ADY1NTM1M3sAbgAAACF0RVh0ZXhpZjpDb21wb25lbnRzQ29uZmlndXJhdGlvbgAuLi4uRGG1ogAA
        ABJ0RVh0ZXhpZjpFeGlmT2Zmc2V0ADkwWYzemwAAABV0RVh0ZXhpZjpFeGlmVmVyc2lvbgAwMjEw
        uHZWeAAAABl0RVh0ZXhpZjpGbGFzaFBpeFZlcnNpb24AMDEwMBLUKKwAAAAXdEVYdGV4aWY6UGl4
        ZWxYRGltZW5zaW9uADMyiKxnFwAAABd0RVh0ZXhpZjpQaXhlbFlEaW1lbnNpb24AMzJVOr6SAAAA
        F3RFWHRleGlmOllDYkNyUG9zaXRpb25pbmcAMawPgGMAAAAASUVORK5CYII="
        />
      </svg>
    </SvgIcon>
  );
}
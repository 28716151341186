import { injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { uniq } from 'lodash';
import { AxiosResponse } from 'axios';

import { normalize } from '@/shared/lib/normalize';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { CounterStore } from '../stores/CounterStore';
import { CounterTimeline } from "../components/DashboardPanel/CounterPanel/types";

@injectable()
export class CounterService {
  constructor(
    private apiService: ApiService,
    private counterStore: CounterStore,
  ) {
    makeAutoObservable(this);
  }

  get counterTimelines(): CounterStore['counterTimelines'] {
    return this.counterStore.counterTimelines;
  }

  get interval() {
    return this.counterStore.interval;
  }

  async getCounterTimeLinesByBlockIds(blockIdList : string[], period: number[]): Promise<void> {
    const [start, end] = period;
    const { interval } = this;

    try {
      const response = await this.apiService.instance.post<
        CounterTimeline[],
        AxiosResponse<CounterTimeline[]>,
        CounterTimeline[]
      >('editor/dashboard/counter/search', {
        blockIdList,
        start,
        end,
        interval,
      });

      const normalizedCounterTimelines = normalize(response.data, 'blockId');

      runInAction(() => {
        this.counterStore.counterTimelines.ids = uniq([...this.counterStore.counterTimelines.ids, ...normalizedCounterTimelines.ids]);
        this.counterStore.counterTimelines.entities = {
          ...this.counterStore.counterTimelines.entities,
          ...normalizedCounterTimelines.entities,
        };
      });
    } catch (error) {
      // todo
      throw error;
    }
  }

}

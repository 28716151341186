import { FC } from 'react';

import { CardWithActions } from '@/shared/ui/CardWithActions/CardWithActions';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import cn from 'classnames';

import styles from './DashboardCard.module.scss';

const dashboardService = container.resolve(DashboardService);

export interface MessageQueueCardProps {
  dashboardId: string;
  active: boolean;
  actions: JSX.Element;
  onClick: () => void;
  isFirst?: boolean;
}

export const DashboardCard: FC<MessageQueueCardProps> = observer(
  ({ dashboardId, active, actions, onClick, isFirst}) => {
    const isLoading = dashboardService.isLoadingDashboards;

    const date = dayjs(dashboardService.dashboards.entities[dashboardId].lastUpdate).format('DD.MM.YYYY');

    return (
      <CardWithActions
        title={dashboardService.dashboards.entities[dashboardId].name}
        loading={isLoading}
        active={active}
        onClick={onClick}
        actions={actions}
      >
        <div className={styles.root}>
          <p className={cn(styles.description, {[styles.active]: active})}>
            {dashboardService.dashboards.entities[dashboardId].description}
          </p>
          <p className={styles.date}>{date}</p>
        </div>
      </CardWithActions>
    );
  }
);

DashboardCard.displayName = 'DashboardCard';

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import _ from 'lodash';
import { UserStore } from '@/entities/User';

import { DeleteUserService } from '../api/DeleteUserService';

const deleteUserService = container.resolve(DeleteUserService);
const userStore = container.resolve(UserStore);

export interface DeleteUserDialogProps {
  onSuccess?(): void;
}

export const DeleteUserDialog: FC<DeleteUserDialogProps> = observer(({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const userName = _.get(
    userStore.users?.entities[deleteUserService.selectedUserId || ''],
    'login',
    ''
  );

  const handleClose = useCallback(() => {
    deleteUserService.open = false;
  }, []);

  const handleOkButtonClick = useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteUserService.deleteUser();

      notify.success('Пользователь удален');
      handleClose();
      onSuccess?.();
    } catch {
      notify.error('Не удалось удалить пользователя');
    } finally {
      setIsLoading(false);
    }
  }, [handleClose, onSuccess]);

  return (
    <Dialog fullWidth open={deleteUserService.open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Удаление пользователя</DialogTitle>
      <DialogContent>Вы точно хотите удалить пользователя {userName}?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
          Отмена
        </Button>
        <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DeleteUserDialog.displayName = 'DeleteUserDialog';

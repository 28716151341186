import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      light: '#9789FB',
      main: '#6C5DD3',
      dark: '#484282',
    },
    secondary: {
      main: '#1B1D21',
    },
    background: {
      paper: '#242731',
      default: '#1f2128',
    },
  },

  typography: {
    body1: {
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
    },
    body2: {
      fontSize: 14,
      fontFamily: 'Inter, sans-serif',
      fontWeight: 500,
    },
    description: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '16px',
    },
    h1: {
      fontFamily: 'Mont, sans-serif',
    },
    h2: {
      fontFamily: 'Mont, sans-serif',
      fontSize: '1rem',
      fontWeight: 900,
    },
    h3: {
      fontFamily: 'Mont, sans-serif',
      fontSize: '1rem',
      fontWeight: 900,
    },
    h4: {
      fontFamily: 'Mont, sans-serif',
    },
    h5: {
      fontFamily: 'Mont, sans-serif',
    },
    h6: {
      fontFamily: 'Mont, sans-serif',
    },
    caption: {
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: '16px',
      color: '#B2B3BD',
    },
    title: {
      fontWeight: 900,
      fontSize: '1.125rem',
      lineHeight: '32px',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          description: 'p',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: 'var(--text-color)',
        },
        deleteIcon: {
          color: 'unset',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:before': {
            content: 'unset',
          },
          '&:last-of-type': {
            borderRadius: '8px',
          },
          '&:first-of-type': {
            borderRadius: '8px',
          },
        },
        rounded: {
          borderRadius: '8px',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          fontWeight: 600,
        },
        root: {
          '&.Mui-focusVisible': {
            backgroundColor: 'unset',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
        },
      },
    },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       borderRadius: '8px',
    //       '&:focus': {
    //         borderRadius: '8px',
    //         outline: '2px solid #5F75EE',
    //       },
    //     },
    //     focused: {
    //       outline: '2px solid #5F75EE',
    //     },
    //     notchedOutline: {
    //       border: 'unset',
    //     },
    //   },
    // },
    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       backgroundColor: '#2A2D36',
    //     },
    //     outlined: {
    //       border: 'unset',
    //     },
    //   },
    // },
    MuiMenu: {
      styleOverrides: {
        paper: {},
        list: {
          backgroundColor: '#242731',
          borderRadius: '8px',
          padding: '4px !important',
          border: '1px solid rgba(228, 228, 228, 0.1)',
          boxShadow: '2px 10px 10px 0px rgba(0, 0, 0, 0.24)',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: (props) => {
          return {
            borderRadius: '12px',
            minHeight: '48px',

            '&.Mui-selected': {
              backgroundColor: props.theme.palette.primary.main,

              '&:hover': {
                backgroundColor: props.theme.palette.primary.main,
              },
            },

            '&:hover': {
              backgroundColor: '#2A2D36',
            },
          };
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        selected: (props) => {
          return {
            backgroundColor: props.theme.palette.primary.main,
          };
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem',
        },
      },
    },

    MuiBadge: {
      styleOverrides: {
        standard: {
          fontFamily: 'Inter, sans-serif',
        },
      },
    },
  },
});

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { ExternalAppStore } from '@/entities/ExternalApp';

@singleton()
export class DeleteExternalAppService {
  open: boolean;
  selectedId: string | null;

  constructor(private apiService: ApiService, private externalAppStore: ExternalAppStore) {
    this.open = false;
    this.selectedId = null;

    makeAutoObservable(this);
  }

  async deleteExternalApp() {
    if (this.selectedId) {
      await this.apiService.instance.delete(`/editor/externalService/${this.selectedId}`);

      const index = this.externalAppStore.externalApps?.ids.indexOf(this.selectedId);

      if (index || index === 0) {
        runInAction(() => {
          this.externalAppStore.externalApps?.ids.splice(index, 1);
          delete this.externalAppStore.externalApps?.entities[this.selectedId!];
          this.selectedId = null;
        });
      }
    }
  }
}

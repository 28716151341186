import { FC, memo } from 'react';

import MuiMenuItem, { MenuItemProps as MuiMenuItemProps } from '@mui/material/MenuItem';

export interface MenuItemProps extends MuiMenuItemProps {}

export const MenuItem: FC<MenuItemProps> = memo((props) => {
  return <MuiMenuItem {...props} />;
});

MenuItem.displayName = 'MenuItem';

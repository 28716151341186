import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { ChoosePythonScriptService } from '../api/ChoosePythonScriptService';

const choosePythonScriptService = container.resolve(ChoosePythonScriptService);

export interface OpenPythonBeanPageButtonProps {}

export const OpenPythonBeanPageButton: FC<OpenPythonBeanPageButtonProps> = observer(() => {
  const handleClick = useCallback(() => {
    choosePythonScriptService.openDialog = true;
  }, []);

  return (
    <Button
      doesUseInFormOrTable
      variant={ButtonVariants.Secondary}
      onClick={handleClick}
    >
      Выберите скрипт
    </Button>
  );
});

OpenPythonBeanPageButton.displayName = 'OpenPythonBeanPageButton';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { Notification } from '@/entities/Notification';

@singleton()
export class SendNotificationService {
  constructor(private apiService: ApiService) {
    makeAutoObservable(this);
  }

  async sendNotification(notification: Notification) {
    return await this.apiService.instance.post('/editor/notification/send', notification);
  }
}

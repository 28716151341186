import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import {KeyValueStore, KeyValueService, KeyValue} from '@/entities/KeyValue';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

@singleton()
export class UpsertKeyValueService {
  open: boolean;
  selectedId: string | null;

  constructor(private apiService: ApiService, private keyValueStore: KeyValueStore) {
    this.open = false;
    this.selectedId = null;

    makeAutoObservable(this);
  }

  async upsertKeyValue(keyValue: KeyValue) {
    const response = await this.apiService.instance.put<KeyValue>(
      '/editor/keyValue/save',
        keyValue
    );

    if (this.keyValueStore.keyValues) {
      this.keyValueStore.keyValues.entities[response.data.id] = response.data;

      if (!keyValue.id) {
        this.keyValueStore.keyValues.ids.push(response.data.id);
      }
    } else {
      this.keyValueStore.keyValues = normalize([response.data], 'id');
    }

    return response.data;
  }

  get currentKeyValue() {
    if (this.selectedId) {
      return this.keyValueStore.keyValues?.entities[this.selectedId];
    }

    return undefined;
  }
}

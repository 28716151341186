import { Mode, Segment } from '../enums';
import { NoneValue, LastDayValue, LastDayWeekValue, DaysBeforeEndMonthValue, NearestWeekdayOfMonthValue } from '../values';
import { BaseValues } from './base.abstract';

var _a, _b, _c;

export class DayOfMonthValues extends BaseValues {
    constructor(d = {}) {
        super(d);
        this.segment = Segment.dayOfMonth;
        this[_a] = new NoneValue();
        this[_b] = new LastDayValue();
        this[_c] = new LastDayWeekValue();
        this.NEAREST_WEEKDAY_OF_MONTH = new NearestWeekdayOfMonthValue(d.NEAREST_WEEKDAY_OF_MONTH);
        this.DAYS_BEFORE_END_MONTH = new DaysBeforeEndMonthValue(d.DAYS_BEFORE_END_MONTH);
    }
    clone(d = {}) {
        return new DayOfMonthValues(Object.assign(Object.assign({}, this), d));
    }
}
_a = Mode.NONE; _b = Mode.LAST_DAY; _c = Mode.LAST_DAY_WEEK;// Mode.NEAREST_WEEKDAY_OF_MONTH; Mode.DAYS_BEFORE_END_MONTH;

import { FC, useCallback } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertKeyValueService } from '../api/UpsertKeyValueService';

import styles from './styles.module.scss';
import {ButtonVariants} from "@/shared/ui/Button/types";
import {PlusIcon} from "@/shared/ui/Icons/PlusIcon/PlusIcon";
import Button from "@/shared/ui/Button";

const upsertExternalAppService = container.resolve(UpsertKeyValueService);

export interface CreateExternalAppCardProps {}

export const CreateKeyValueCard: FC<CreateExternalAppCardProps> = observer(() => {
  const handleClick = useCallback(() => {
    upsertExternalAppService.open = true;
  }, []);

  return (
      <Button doesUseInFormOrTable variant={ButtonVariants.Secondary} onClick={handleClick}>
        <Stack direction='row' gap={1} alignItems='center'>
          <AddBoxOutlinedIcon />
          Добавить запись
        </Stack>
      </Button>
  );
});

CreateKeyValueCard.displayName = 'CreateKeyValueCard';

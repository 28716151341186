import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { cutText } from '@/shared/lib/cutText';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { actionsSearchParams } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';
import Button from '@/shared/ui/Button';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';

import { UnsavedFlowChangesStore } from '../../UnsavedChanges';

const flowService = container.resolve(FlowService);
const unsavedFlowChangesStore = container.resolve(UnsavedFlowChangesStore);

export const DeleteFlowModal: FC = observer(() => {
  const navigate = useNavigate();
  const { get, has, remove } = useSearchParamsTemplate();

  const flowId = get(SearchParams.FlowId);
  const isOpenDeleteModal = has(SearchParams.ShowFlowDeleteModal);

  const handleDialogClose = useCallback(() => {
    remove(actionsSearchParams);
  }, [remove]);

  const handleDialogOk = useCallback(() => {
    if (flowId) {
      flowService.deleteFlow(flowId).then(() => {
        unsavedFlowChangesStore.areThereChanges = false;

        setTimeout(() => {
          if (flowService.flows.ids[0]) {
            navigate(`${flowService.flows.ids[0]}`);
          } else {
            navigate('');
          }
        }, 0);
      });
    }
  }, [flowId, navigate]);

  if (!isOpenDeleteModal) return null;

  return (
    <Dialog fullWidth open onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>Удалить поток</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <div>
            <p>
              <span>Вы точно хотите удалить поток</span>{' '}
              <span title={flowService.flows.entities[flowId ?? '']?.name}>
                {cutText(flowService.flows.entities[flowId ?? '']?.name || '', 20, true)}?
              </span>
            </p>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button
          loading={flowService.isLoadingDeleteFlow}
          variant={ButtonVariants.Popup}
          onClick={handleDialogOk}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DeleteFlowModal.displayName = 'DeleteFlowModal';

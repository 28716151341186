import { FC, useLayoutEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import { RegistrableValues } from '@/shared/lib/types';
import { Box, Stack } from '@mui/system';
import { AnimatePresence } from 'framer-motion';
import { ReactFlowProvider } from 'reactflow';
import { FlowWorkspace } from '@/entities/Flow/widgets';
import { ConnectDrawerContainer } from '@/entities/Connect/containers/ConnectDrawerContainer';
import { MessageDeliverySettingsContainer } from '@/entities/Block/containers/MessageDeliverySettingsContainer';
import { LogSettingsContainer } from '@/entities/Block/containers/LogSettingsContainer';
import { CreatingTwoConnectsDrawerContainer } from '@/entities/Connect/containers/CreatingTwoConnectsDrawerContainer';
import { BlockDrawerContainer } from '@/entities/Block/containers/BlockDrawerContainer';
import { DeleteConnectModalContainers } from '@/entities/Connect/containers/DeleteConnectModalContainers';
import { BlockDeletionModalContainer } from '@/entities/Block/containers/BlockDeletionModalContainer';
import { DeleteFlowModal, FlowFormDrawer } from '@/entities/Flow/features';
import { MessageTracingPage } from '@/pages';
import { UnsavedChangesModal, UnsavedFlowChangesStore } from '@/entities/UnsavedChanges';
import { container } from 'tsyringe';
import { FlowService } from '@/entities/Flow/services/FlowService';
import {
  FlowVersionBlock,
  FlowVersionService,
  FlowVersionStore,
} from '@/entities/FlowVersion';
import { UpsertFlowVersionForm } from '@/features/UpsertFlowVersion';

export interface FlowVersionContentProps {}

const flowVersionStore = container.resolve(FlowVersionStore);
const flowVersionService = container.resolve(FlowVersionService);
const flowService = container.resolve(FlowService);
const unsavedFlowChangesStore = container.resolve(UnsavedFlowChangesStore);

const nodeTypes = { block: FlowVersionBlock };

export const FlowVersionContent: FC<FlowVersionContentProps> = observer(() => {
  const { versionId } = useParams();

  const { flowId } = useParams();

  container.register(RegistrableValues.FlowId, { useValue: flowId });
  const flowUpdaterService = container.resolve(FlowUpdaterService);

  useLayoutEffect(() => {
    if (!versionId) return;
    const flowVersion = flowVersionStore.versions?.entities[versionId]?.versionFlow;

    if (flowVersion) {
      flowService.setFlow(flowVersion as any);
      return;
    }

    flowVersionService.getFlowVersion(versionId).then((version) => {
      flowService.setFlow(version.versionFlow as any);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [versionId, flowId, flowVersionStore.versions?.ids.length]);

  return (
    <AnimatePresence key={2} mode='wait'>
      <Box display='grid' gridTemplateColumns='1fr max-content' position='relative'>
        <Box zIndex={1000}>
          {flowId && (
            <ReactFlowProvider>
              <FlowWorkspace key={versionId} isFlowVersion nodeTypes={nodeTypes} />
            </ReactFlowProvider>
          )}
        </Box>
        <Box
          position='absolute'
          display='grid'
          gridTemplateColumns='1fr max-content'
          width='100%'
          height='100%'
        >
          <div>
            <ConnectDrawerContainer />
            <MessageDeliverySettingsContainer />
            <LogSettingsContainer />
            <CreatingTwoConnectsDrawerContainer />
          </div>
          <Stack display='grid' gridTemplateColumns='max-content max-content'>
            <BlockDrawerContainer />
          </Stack>
        </Box>
        <DeleteConnectModalContainers />
        <BlockDeletionModalContainer />
        <DeleteFlowModal />
        <MessageTracingPage />
        <FlowFormDrawer />
        <UpsertFlowVersionForm versionId={versionId || ''} />
      </Box>
      <UnsavedChangesModal
        title='Есть несохранненные данные в потоке'
        text='Сохранить изменения в потоке перед переходом?'
        store={unsavedFlowChangesStore}
        onChangeSearchParams={(cur, next) => {
          const curMode = new URLSearchParams(cur).get('mode');
          const nextMode = new URLSearchParams(next).get('mode');
          return curMode === nextMode;
        }}
        onSave={() => flowUpdaterService.saveFlowVersion(versionId || '')}
        onDecline={() => {
          const flowVersion = flowVersionStore.versions?.entities[versionId]?.versionFlow;
          flowService.setFlow(flowVersion as any);
        }}
      />
    </AnimatePresence>
  );
});

FlowVersionContent.displayName = 'FlowVersionContent';

import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DownloadManualExternalServiceService } from '../api/DownloadManualExternalServiceService';
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const downloadManualExternalServiceService = container.resolve(DownloadManualExternalServiceService);

export interface DownloadManualExternalServiceButtonProps {
  externalAppId: string;
  fileName: string;
}

export const DownloadManualExternalServiceButton: FC<DownloadManualExternalServiceButtonProps> = observer(
  ({ externalAppId, fileName }) => {
    const handleClick = useCallback(async () => {
        downloadManualExternalServiceService.selectedId = externalAppId;
        downloadManualExternalServiceService.fileName = fileName;
        downloadManualExternalServiceService.downloadManualExternalService();
    }, [externalAppId]);

    return (
      <Tooltip title='Документация' placement='top'>
        <span>
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleClick}
          >
            <HelpOutlineIcon />
          </Button>
        </span>
      </Tooltip>
    );
  }
);

DownloadManualExternalServiceButton.displayName = 'DownloadManualExternalServiceButton';

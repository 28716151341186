import React, {FC, useCallback} from 'react';

import Stack from '@mui/material/Stack';
import {container} from 'tsyringe';
import {observer} from 'mobx-react-lite';
import {KeyValueCard, KeyValueService, KeyValueStore} from "@/entities/KeyValue";
import {UpdateKeyValueButton} from "@/features/UpsertKeyValue";
import {DeleteKeyValueButton, DeleteKeyValueDialog} from "@/features/DeleteKeyValue";
import {ListWithInfiniteScroll} from "@/shared/ui";

const keyValueStore = container.resolve(KeyValueStore);
const keyValueService = container.resolve(KeyValueService);
export interface KeyValueListProps {
}

export const KeyValueList: FC<KeyValueListProps> = observer(() => {

    const handleListFetchNextElement = useCallback(
        (page: number) => keyValueService.searchKeyValue({ pagination: { page } }),
        []
    );

    return (
        <Stack gap={2.5} display='flex' direction='row' flexWrap='wrap'>
            <ListWithInfiniteScroll
                elementWidth={'100%'}
                width={'100%'}
                elements={keyValueStore.keyValues?.ids || []}
                fetchNextElement={handleListFetchNextElement}
                lastPage={keyValueStore.lastPage}
                loading={keyValueStore.isLoading}
                renderElement={(id) => {
                    const keyValue = keyValueStore.keyValues!.entities[id];

                    return (
                        <KeyValueCard
                            key={id}
                            {...keyValue}
                            actions={
                                <Stack direction='row' justifyContent='space-between'>
                                    <UpdateKeyValueButton keyValueId={id}/>
                                    <DeleteKeyValueButton externalAppId={id}/>
                                </Stack>
                            }
                        />
                    )
                }}
            />
            <DeleteKeyValueDialog/>
        </Stack>
    );
});

KeyValueList.displayName = 'KeyValueList';

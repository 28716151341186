import { FC } from 'react';

import cn from 'classnames';
import { Stack } from '@mui/system';

import Tooltip from '../Tooltip';
import { QuestionIcon } from '../Icons/QuestionIcon/QuestionIcon';

import styles from './Label.module.scss';

export interface LabelProps {
  children: string | undefined | JSX.Element;
  name?: string;
  required?: boolean;
  tooltip?: string;
  className?: string;
  error?: boolean;
}

export const Label: FC<LabelProps> = ({ children, name, required, tooltip, error, className }) => {
  return (
    <Stack direction='row' spacing={0.5} alignItems='center'>
      <label
        className={cn(styles.root, className, {
          [styles.required]: required,
          [styles.error]: error
        })}
        htmlFor={name}
      >
        {children}
      </label>
      {tooltip && (
          <Tooltip
              title={<div className="preWrap" dangerouslySetInnerHTML={{__html: tooltip}}></div>}
          >
              <Stack>
                  <QuestionIcon
                      className={cn(styles.tooltipIcon, {[styles.error]: error})}
                      display='block'
                      fontSize='1rem'
                  />
              </Stack>
          </Tooltip>
      )}
    </Stack>
  );
};

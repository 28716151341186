import { container } from 'tsyringe';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';

const pythonLibsStore = container.resolve(PythonLibsStore);
export const sortByField = (fieldName: string) => {
  if (pythonLibsStore.isSort)
    return (a: { [x: string]: number }, b: { [x: string]: number }) =>
      a[fieldName] > b[fieldName] ? 1 : -1;

  if (!pythonLibsStore.isSort)
    return (a: { [x: string]: number }, b: { [x: string]: number }) =>
      a[fieldName] < b[fieldName] ? 1 : -1;
};

import { FC } from 'react';

import { ConnectorEditorProps } from '@/entities/Connect/components/ConnectEditor/ConnectEditor';
import { BlockRelationService } from '@/entities/Block/services/BlockRelationService';

interface WithBlockRelationService {
  blockId: string;
  blockRelationService: BlockRelationService;
  connectorEditorProps?: ConnectorEditorProps;
}

/**
 * HOC для подсветки коннекторов если курсор наведен на линию, которую они образуют
 * @param Component ConnectEditor
 * @param blockId Id блока
 * @param blockRelationService Сервис для отрисовки линий между блоками
 */
export const withHighlightConnectWhenLineHover =
  (
    Component: FC<ConnectorEditorProps>,
    { blockId, blockRelationService }: WithBlockRelationService
  ) =>
  ({ connectorId, ...props }: ConnectorEditorProps) => {
    const isHighlight =
      blockRelationService.lineIdWhichHover?.includes(blockId) &&
      blockRelationService.lineIdWhichHover?.includes(connectorId);

    return <Component {...props} connectorId={connectorId} isHighlight={isHighlight} />;
  };

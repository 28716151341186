import { difference, intersection, isEmpty, union } from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';
import { container } from 'tsyringe';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import { GFlowStore } from '@/entities/Flow/stores/GFlowStore';

const gflowStore = container.resolve(GFlowStore);
const gflowService = container.resolve(GFlowService);

const MAX_RETRY_TIMES = 10;
const useExpand = ({
  treeRef,
}) => {
  const onExpand = (keys, { expanded, node: rec }) => {
    const tree = treeRef.current;
    if (!tree || !expanded) return;
    if (!rec.children) return;
    const childIds = rec.children
      .filter(({ type }) => type === 'group').map(({ id }) => id);
    if (isEmpty(childIds)) return;

    const loadData = () => gflowService.getExpandedGroup(rec.id);
    const onLoad = (...args) => { // for debug
      // console.log('onLoad: ', ...args);
    }

    // source fragment below (rc-tree)
    // https://github.com/react-component/tree/blob/v5.8.5/src/Tree.tsx#L957
    const loadPromise = new Promise<void>((resolve, reject) => {
      // We need to get the latest state of loading/loaded keys
      tree.setState(({ loadedKeys = [], loadingKeys = [] }): any => {
        const isLoaded = !isEmpty(intersection(loadedKeys, childIds));
        const isLoading = !isEmpty(intersection(loadingKeys, childIds));
        if (isLoaded || isLoading) return null;

        // Process load data
        const promise = loadData();
        promise
          .then(() => {
            const { loadedKeys: currentLoadedKeys } = tree.state;
            const newLoadedKeys = union(currentLoadedKeys, childIds);

            // onLoad should trigger before internal setState to avoid `loadData` trigger twice.
            // https://github.com/ant-design/ant-design/issues/12464
            onLoad?.(newLoadedKeys, {
              event: 'load',
              node: rec,
            });
            gflowStore.loadedKeys = newLoadedKeys;
            tree.setUncontrolledState({
              loadedKeys: newLoadedKeys,
            });
            tree.setState((prevState) => ({
              loadingKeys: difference(prevState.loadingKeys, childIds),
            }));

            resolve();
          })
          .catch((e) => {
            tree.setState((prevState) => ({
              loadingKeys: difference(prevState.loadingKeys, childIds),
            }));

            // If exceed max retry times, we give up retry
            const hashKey = childIds.join();
            const counterMap = tree.loadingRetryTimes;
            counterMap[hashKey] = counterMap[hashKey] || 0;
            counterMap[hashKey] += 1;
            if (counterMap[hashKey] >= MAX_RETRY_TIMES) {
              const { loadedKeys: currentLoadedKeys } = tree.state;


              notify.error('Не удалось получить данные в течении 10 попыток');

              tree.setUncontrolledState({
                loadedKeys: difference(currentLoadedKeys, childIds),
              });
              resolve();
            }

            reject(e);
          });

        return {
          loadingKeys: union(loadingKeys, childIds),
        };
      });
    });
    // Not care warning if we ignore this
    loadPromise.catch(() => {});
    return loadPromise;
  };
  return onExpand;
};

export default useExpand;

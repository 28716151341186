import React, {FC, useCallback} from 'react';

import {Box, Stack} from '@mui/system';
import {observer} from 'mobx-react-lite';
import {ExecutorLogByIntegraView} from "@/entities/ExecutorLog/ui/ExecutorLogByIntegraView";

export interface ExecutorLogBlockProps {}

export const ExecutorLogIntegra: FC<ExecutorLogBlockProps> = observer(() => {
    return (
        <Stack padding='10px 0 10px 10px' height='100%' gap={1}>
            <Stack margin='0px 10px -10px 10px' paddingRight='20px' direction='row' justifyContent='space-between' alignItems='center' gap={1.5}>
                <Box component='h2' fontWeight={900} fontSize='2.5rem'>
                    Лог приложения
                </Box>
            </Stack>
            <Box style={{
                flexGrow:1,
                height:'100px',
                display:"flex",
                flexDirection:"column"
            }}>
                <ExecutorLogByIntegraView />
            </Box>
        </Stack>
    );
});

ExecutorLogIntegra.displayName = 'ExecutorLogBlockProps';

import { FC, memo, MouseEvent, useState } from 'react';

import { NavLink } from 'react-router-dom';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { Menu, MenuItem } from '@/shared/ui';
import Tooltip from '@/shared/ui/Tooltip';
import useRedux from "@/hooks/useRedux";
import { Documents } from './Documents';

import styles from './styles.module.scss';


const Onboarding = () => (
  <MenuItem>
    <ListItemIcon>
      <SupportAgentOutlinedIcon />
    </ListItemIcon>
    <NavLink to='/onboarding'>Онбординг</NavLink>
  </MenuItem>
);

const Version = () => {
  const [settings] = useRedux('settings');

  return (
    <Typography
      px={2}
      py={1.5}
      textAlign='center'
    >
      Интегра v.{settings?.version}
    </Typography>
  );
}

export interface HelpButtonProps {}

export const HelpButton: FC<HelpButtonProps> = memo(() => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title='Помощь' placement='top' disableInteractive>
        <span>
          <Button
            circle
            size={ButtonSizes.Medium}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <HelpOutlineIcon />
          </Button>
        </span>
      </Tooltip>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={styles.root}
      >
        <Onboarding />
        <Documents />
        <Divider />
        <Version />
      </Menu>
    </>
  );
});

HelpButton.displayName = 'HelpButton';

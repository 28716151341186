import { FC } from 'react';

import { container } from 'tsyringe';
import Stack from '@mui/material/Stack';
import { observer } from 'mobx-react-lite';
import { LicenseCard, LicenseStore } from '@/entities/License';
import { AddLicenseCard, EditLicenseButton } from '@/features/UpsertLicense';
import { DeleteLicenseButton } from '@/features/DeleteLicense';

const licenseStore = container.resolve(LicenseStore);

export interface LicenseListProps {}

export const LicenseList: FC<LicenseListProps> = observer(() => {
  return (
    <Stack direction='row' flexWrap='wrap' gap={2.5}>
      {licenseStore.licenses?.ids.map((id) => {
        const license = licenseStore.licenses?.entities[id];

        if (license) {
          return (
            <LicenseCard
              key={id}
              {...license}
              actions={
                <Stack width='100%' direction='row' justifyContent='space-between'>
                  <EditLicenseButton id={id} />
                  <DeleteLicenseButton id={id} />
                </Stack>
              }
            />
          );
        }

        return null;
      })}
      <AddLicenseCard />
    </Stack>
  );
});

LicenseList.displayName = 'LicenseList';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Parameter } from '@/entities/Connect/types';
import { DataNode } from 'rc-tree/es/interface';
import _ from 'lodash';

import { SEPARATOR } from '../lib/constants';
import { Connector1cStore } from '../model/Connector1cStore';

@singleton()
export class Connector1cService {
  constructor(private connector1cStore: Connector1cStore) {
    makeAutoObservable(this);
  }

  private buildTreeData(parameters: Parameter[]): DataNode[] {
    const result: DataNode[] = [];
    const traverse = (key: string, { label, name, parameterList }: Parameter): DataNode => {
      return {
        title: label || name,
        key,
        children: parameterList?.length
          ? parameterList.map((parameter) =>
              traverse(`${key}${SEPARATOR}${parameter.name}`, parameter)
            )
          : undefined,
      };
    };

    parameters.forEach((parameter) => {
      result.push(traverse(parameter.name, parameter));
    });

    return result;
  }

  init(parameters: Parameter[]) {
    this.connector1cStore.treeData = this.buildTreeData(parameters);
  }

  getFormValue(checkedKeys: string[]) {
    const result = {};

    checkedKeys.forEach((key) => {
      const handledKey = key.replaceAll('.', '&#46;').replaceAll(SEPARATOR, '.');

      _.set(result, handledKey, true);
    });

    return result;
  }

  getCheckedKeys(value: any) {
    const result: string[] = [];

    const traverse = (parentKey: string, value: any) => {
      Object.keys(value).forEach((key) => {
        if (typeof value[key] !== 'boolean') {
          traverse(`${parentKey}${SEPARATOR}${key}`, value[key]);
        } else {
          result.push(`${parentKey}${SEPARATOR}${key}`);
        }
      });
    };

    Object.keys(value).forEach((key) => traverse(`${key}`, value[key]));

    return result;
  }
}

import { FC, useEffect } from 'react';

import { Observer, observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { ListWithInfiniteScroll } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageQueueCard } from '../entities';
import { MessageQueueService } from '../../../services/MessageQueueService';

const messageQueueService = container.resolve(MessageQueueService);

export interface MessageQueueListProps {
  blockId: string;
}

export const MessageQueueList: FC<MessageQueueListProps> = observer(({ blockId }) => {
  const { get } = useSearchParamsTemplate();

  const isLoading = messageQueueService.isLoadingMessages;
  const errorId = get(SearchParams.ErrorId);

  useEffect(() => {
    return () => {
      messageQueueService.resetMessages();
    };
  }, [blockId]);

  return (
    <ListWithInfiniteScroll
      animated
      elements={messageQueueService.messages.ids}
      lastPage={messageQueueService.totalPages}
      stopAnimatedForKey={errorId}
      activeElementKey={errorId}
      loading={isLoading}
      fetchNextElement={(page) =>
        messageQueueService.getMessages({ blockId, pagination: { page, size: 10 } })
      }
      renderElement={(id, index, rest) => (
        <Observer key={id}>
          {() => {
            return <MessageQueueCard errorId={id} active={Boolean(rest?.active)} />;
          }}
        </Observer>
      )}
    />
  );
});

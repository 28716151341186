import { FC, useCallback, useEffect } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';
import Box from '@mui/system/Box';
import { container } from 'tsyringe';
import { NotFoundConnectorService } from '@/features/NotFoundConnector';

import styles from './styles.module.scss';

const notFoundConnectorService = container.resolve(NotFoundConnectorService);

export interface NotFoundConnectorDrawerProps {}

export const NotFoundConnectorDrawer: FC<NotFoundConnectorDrawerProps> = observer(() => {
  const { get, remove } = useSearchParamsTemplate();

  const connectorName = get(SearchParams.ConnectorName);
  const connectorType = get(SearchParams.ConnectorType);

  const typeText = connectorType === 'processor' ? 'обработчика' : 'коннектора';

  const handleDrawerClose = useCallback(() => {
    remove(CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE);
    notFoundConnectorService.connectorIsNotExist = false;
  }, [remove]);

  useEffect(() => {
    return () => {
      notFoundConnectorService.connectorIsNotExist = false;
    };
  }, []);

  return (
    <Drawer title='' id='handlerTimer' onClose={handleDrawerClose}>
      <Box className={styles.root}>
        <Stack px={2.5} gap={2} justifyItems='center' alignItems='center'>
          <Typography fontWeight={600} fontSize='1.5rem'>
            Просмотр этого {typeText} {connectorName} невозможен
          </Typography>
          <Typography fontWeight={600} fontSize='1rem'>
            Возможно, он был удален или у вас нет разрешения на просмотр
          </Typography>
        </Stack>
      </Box>
    </Drawer>
  );
});

NotFoundConnectorDrawer.displayName = 'NotFoundConnectorDrawer';

import { FC, MouseEventHandler, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { ExportButton } from '@/shared/ui/Button/ExportButton';

import { BlockTestingService } from '../../../services/BlockTestingService';

const blockTestingService = container.resolve(BlockTestingService);

export interface ExportTestProps {
  testId: string;
}

export const ExportTest: FC<ExportTestProps> = ({ testId }) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();

      blockTestingService.exportTest(testId);
    },
    [testId]
  );

  return (
    <Tooltip title='Экспорт теста' placement='top'>
      <span>
        <ExportButton onClick={handleClick} />
      </span>
    </Tooltip>
  );
};

import { forwardRef } from 'react';

import clsx from 'clsx';

import { Header } from './components/Header/Header';

import styles from './Layout.module.scss';

export interface LayoutProps {
  className?: string;
  children: JSX.Element;

  flex?: boolean;
}

export const Layout = forwardRef<HTMLDivElement, LayoutProps>(
  ({ className, flex = false, children }, ref) => {
    return (
      <div ref={ref} className={clsx(className, styles.wrapper)}>
        <Header />
        <div className={styles.content + (flex ? ` ${styles.flex}` : '')}>{children}</div>
      </div>
    );
  }
);

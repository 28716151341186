import React, { PropsWithChildren } from 'react';

import { GridItem } from '../components/GridItem/GridItem';

export interface GridItemContainerProps {
  i: string;
}

export function GridItemContainer ({
  children, i, ...props
}: PropsWithChildren<GridItemContainerProps>) {
  return (
    <GridItem i={i} {...props}>
      {children}
    </GridItem>
  )
}

import { FC, memo } from 'react';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Tooltip from '@/shared/ui/Tooltip';

import styles from './ErrorIcon.module.scss';

export interface ErrorIconProps {
  tooltip: string;
}

export const ErrorIcon: FC<ErrorIconProps> = memo(({ tooltip }) => {
  return (
    <Tooltip title={tooltip} placement='top'>
      <span className={styles.root}>
        <ReportProblemRoundedIcon />
      </span>
    </Tooltip>
  );
});

ErrorIcon.displayName = 'ErrorIcon';

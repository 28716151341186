import { Mode, Segment } from '../enums';
import { NoneValue } from '../values';
import { BaseValues } from './base.abstract';

var _a;

export class MonthValues extends BaseValues {
    constructor() {
        super(...arguments);
        this.segment = Segment.month;
        this[_a] = new NoneValue();
    }
    clone(d = {}) {
        return new MonthValues(Object.assign(Object.assign({}, this), d));
    }
}
_a = Mode.NONE;

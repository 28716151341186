import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { RegistrableValues } from '@/shared/lib/types';

import { ConnectService } from '../services/ConnectService';

export const DeleteConnectModalContainers: FC = observer(() => {
  const { remove, get, has } = useSearchParamsTemplate();

  const connectorType = get(SearchParams.ConnectorType);
  const blockId = get(SearchParams.BlockId);
  const connectorId = get(SearchParams.ConnectorId);

  container.register(RegistrableValues.BlockId, { useValue: blockId || '' });
  const connectService = container.resolve(ConnectService);

  const handleDialogClose = useCallback(() => {
    remove([SearchParams.ShowConnectDeleteModal]);
  }, [remove]);

  const handleDialogOk = useCallback(() => {
    connectService.deleteConnect(connectorId, connectorType);

    handleDialogClose();
  }, [connectorId, connectService, connectorType, handleDialogClose]);

  if (has(SearchParams.ShowConnectDeleteModal)) {
    const entityName = connectorType === 'processor' ? 'обработчик' : 'коннектор';

    return (
      <Dialog fullWidth open onClose={handleDialogClose}>
        <DialogTitle onClose={handleDialogClose}>Удалить {entityName}</DialogTitle>
        <DialogContent>
          <Stack spacing={5}>
            <div>
              <p>Вы точно хотите удалить {entityName}?</p>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} onClick={handleDialogOk}>
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return null;
});

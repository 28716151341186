import { FC } from 'react';

import { RoutePaths } from '@/shared/lib/types';
import { Link } from 'react-router-dom';

import { LogoIcon } from '../../../Icons/LogoIcon/LogoIcon';

export const Logo: FC = () => {
  return (
    <Link to={RoutePaths.Main}>
      <LogoIcon />
    </Link>
  );
};

import { MessageLog } from '@/entities/ExecutorLog';

/* Функция фильтрации по тексту сообщения */
export const filteredMessage = (searchTextMessage: string, executorLogStore: MessageLog[]) => {
  let reg = RegExp(searchTextMessage?.replace(/[\\^$|.*?+{}()[\]]/g, '\\$&'), 'gi');
  if (!searchTextMessage) return executorLogStore;
  return executorLogStore
    .map((message) => {
      const bodyHTML = message.text?.replace(reg, '<mark>$&</mark>');
      return { ...message, bodyHTML };
    })
    .filter(({ bodyHTML, text }) => bodyHTML !== text);
};

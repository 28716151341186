import { normalize, Normalized } from '@/shared/lib/normalize';
import ObjectID from 'bson-objectid';

/**
 * Формирует данные для создания нового хранилища
 * @param defaultValue значения по умолчанию
 * @param store нормализованные данные хранилища
 */
export function buildEntity<T extends { id: string }>(
  defaultValue: T,
  store: Normalized<T> | null
) {
  const id = ObjectID.toString();

  if (store?.ids) {
    store.ids.push(id);
    store.entities[id] = defaultValue;
  } else {
    store = normalize([defaultValue], 'id');
  }

  return store;
}

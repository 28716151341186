import { FC } from 'react';

import { gridTable } from '@/entities/Tracing/components/table/gridTable';
import { container } from 'tsyringe';
import { Table } from '@/entities/Tracing/components/table/Table';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import dayjs from 'dayjs';

import { testResultTableDescriptor } from './testResultTableDescriptor';
import { MatcherWithResult } from '../../../../types';
import { BlockTestingService } from '../../../../services/BlockTestingService';

import styles from './TestResultTable.module.scss';

const blockTestingService = container.resolve(BlockTestingService);

export interface TestResultTableProps {
  testId: string;
}

export const TestResultTable: FC<TestResultTableProps> = observer(({ testId }) => {
  const date = dayjs(blockTestingService.testChecks[testId]?.testDate).format(
    'DD.MM.YYYY HH:mm:ss'
  );

  const getData = (): MatcherWithResult[] => {
    if (testId) {
      return Object.values(blockTestingService.testChecks[testId]?.result || {}).map((result) => {
        return { ...result.matcher, isSuccess: result.isSuccess };
      });
    }

    return [];
  };

  return (
    <Stack className={styles.root} gap={1.5}>
      <Stack direction='row' gap={1.5} alignItems='center'>
        <h2 className={styles.title}>Результат выполнения последнего теста</h2>
        <p className={styles.date}>{date}</p>
      </Stack>
      <Table<MatcherWithResult>
        isLoading={blockTestingService.isLoadingTestResult}
        components={gridTable}
        data={getData()}
        descriptor={testResultTableDescriptor}
      />
    </Stack>
  );
});

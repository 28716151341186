import { NoneValue, NthWeekdayOfMonthValue, LastNthDayWeekValue } from '../values';
import { Mode, Segment } from '../enums';
import { BaseValues } from './base.abstract';

var _a;

export class DayOfWeekValues extends BaseValues {
    constructor(d = {}) {
        super(d);
        this.segment = Segment.dayOfWeek;
        this[_a] = new NoneValue();
        this.NTH_WEEKDAY_OF_MONTH = new NthWeekdayOfMonthValue(d.NTH_WEEKDAY_OF_MONTH);
        this.LAST_NTH_DAY_WEEK = new LastNthDayWeekValue(d.LAST_NTH_DAY_WEEK);
    }
    clone(d = {}) {
        return new DayOfWeekValues(Object.assign(Object.assign({}, this), d));
    }
}
_a = Mode.NONE;// Mode.NTH_WEEKDAY_OF_MONTH, Mode.LAST_NTH_DAY_WEEK;

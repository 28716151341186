export const dashboardTitles = {
  base: 'дашборд',
  pl: 'дашборды',
  gen: 'дашборда',
}

export const panelTitles = {
  base: 'панель',
  pl: 'панели',
  gen: 'панели',
}
import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { notify } from '@/shared/ui/Toast/notify';
import { SettingsUserAuthStore, SettingsUserAuthTypes } from '@/entities/SettingsUserAuth';

import { DeleteSettingsUserAuthService } from '../api/DeleteSettingsUserAuthService';

const deleteSettingsUserAuthService = container.resolve(DeleteSettingsUserAuthService);
const settingsUserAuthStore = container.resolve(SettingsUserAuthStore);

export interface DeleteSettingsUserAuthDialogProps {}

export const DeleteSettingsUserAuthDialog: FC<DeleteSettingsUserAuthDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const entityName =
    settingsUserAuthStore.selectedType === SettingsUserAuthTypes.SettingsBasic
      ? 'Basic хранилище'
      : 'Keycloack хранилище';

  const handleClose = useCallback(() => {
    deleteSettingsUserAuthService.open = false;
    settingsUserAuthStore.selectedId = null;
  }, []);

  const handleOk = useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteSettingsUserAuthService.deleteSettingsUserAuth();

      notify.success(`${entityName} удалено`);

      handleClose();
    } catch {
      notify.error(`Не удалось удалить ${entityName}`);
    } finally {
      setIsLoading(false);
    }
  }, [entityName, handleClose]);

  return (
    <Dialog open={deleteSettingsUserAuthService.open} fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Удалить {entityName}</DialogTitle>
      <DialogContent>Уверены что хотите удалить {entityName}?</DialogContent>
      <DialogActions>
        <Box width={280}>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
        </Box>
        <Box width={280}>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOk}>
            Удалить
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

DeleteSettingsUserAuthDialog.displayName = 'DeleteLicenseDialog';

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { FlowVersionStore, Version } from '@/entities/FlowVersion';

@singleton()
export class CloneVersionService {
  isLoading: boolean;
  showDialog: boolean;

  constructor(private apiService: ApiService, private flowVersionStore: FlowVersionStore) {
    this.isLoading = false;
    this.showDialog = false;

    makeAutoObservable(this);
  }

  async cloneVersion(versionId: string) {
    this.isLoading = true;

    try {
      const flowVersion = this.flowVersionStore.versions?.entities[versionId];

      const response = await this.apiService.instance.post<Version>(
        '/editor/version/clone',
        flowVersion
      );

      runInAction(() => {
        this.flowVersionStore.versions!.ids.unshift(response.data.id);
        this.flowVersionStore.versions!.entities[response.data.id] = response.data;
      });
    } catch (error) {
      notify.error('Не удалось клонировать версию');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';
import {ExecutorLogIntegraPage} from "@/pages/ExecutorLogIntegraPage";

export const ExecutorLogRoute: FC = () => {
    return (
        <Layout flex>
            <Routes>
                <Route index element={<ExecutorLogIntegraPage />} />
            </Routes>
        </Layout>
    );
};

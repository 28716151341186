import { useCallback} from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle} from '@/shared/ui';
import { observer} from "mobx-react-lite";
import { container } from "tsyringe";
import { DublicateFlowService } from "@/features/DublicateFlow/api/DublicateFlowDialog";
import Button from "@/shared/ui/Button";
import { ButtonVariants } from "@/shared/ui/Button/types";
import { RoutePaths } from "@/shared/lib/types";
import { useNavigate } from "react-router-dom";
import { FlowService } from "@/entities/Flow/services/FlowService";

const dublicateFlowService = container.resolve(DublicateFlowService);
const flowService = container.resolve(FlowService);

export const DublicateVersionDialog = observer(
  () => {
    const navigate = useNavigate();

    const handleClose = useCallback(() => {
      dublicateFlowService.showDialog = false;
    }, []);

    const submitOK = async () => {
      try {
        const dataInfo = dublicateFlowService.dataInfo;
        const flow = await flowService.createFlow(dataInfo);
        navigate(`/${RoutePaths.Flow}/${flow?.id}`);
      } catch (error) {
        console.error("Error in submitOK:", error);
      }
    }

    const handleOkButtonClick = async () => {
      submitOK();
      dublicateFlowService.showDialog = false;
    };

    return (
      <Dialog open={dublicateFlowService.showDialog}>
        <DialogTitle onClose={handleClose}>Дублирующее название потока</DialogTitle>
        <DialogContent>Поток с таким наименованием уже есть в системе. Сохранить поток с дублирующим наименованием?</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button
            variant={ButtonVariants.Popup}
            onClick={handleOkButtonClick}
          >
            Сохранить
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
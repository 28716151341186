import { useEffect, useState } from "react";
import { Dropdown, MenuProps } from 'antd';
import { SyncOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { container } from "tsyringe";
import { get as lodashGet, values } from 'lodash';

import Tooltip from "@/shared/ui/Tooltip";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";

import { refreshOptions } from "./constants";

import styles from "./HistoryManager.module.scss";


const counterService = container.resolve(CounterService);
const periodService = container.resolve(PeriodService);
const dashboardService = container.resolve(DashboardService);

const defaultRefresh = '5s';

const getOpt = (key: string | null) => refreshOptions.find((itm) => itm.key === key);

export function Refresh() {
  const { get, set } = useSearchParamsTemplate();
  const refresh = get('refresh');

  const [open, setOpen] = useState(false);

  const handleChangeTimeout: MenuProps['onClick'] = ({ key }) => {
    set({ refresh: key });
  };

  const handleRefreshData = () => {
    const dataPanels = lodashGet(dashboardService.selectedDashboard, 'data.panels', {});
    const blockIds = values(dataPanels).flatMap((panel) => lodashGet(panel, 'data.blockIdList', []));
    counterService.getCounterTimeLinesByBlockIds(blockIds, periodService.unixPeriod);
  }

  useEffect(() => {
    if (getOpt(refresh)) return;
    set({ refresh: defaultRefresh });
  }, []);

  const StateIcon = open ? UpOutlined : DownOutlined;
  const icon = (
    <>
      {getOpt(refresh)?.label || 'откл'}
      &nbsp;
      <StateIcon />
    </>
  );

  return (
    <Tooltip title={`Обновить ${dashboardTitles.base}`} placement="top" disableInteractive>
      <span>
        <Dropdown.Button
          className={styles['refresh-btn']}
          icon={icon}
          menu={{
            items: refreshOptions,
            selectable: true,
            defaultSelectedKeys: [defaultRefresh],
            onClick: handleChangeTimeout,
          }}
          onOpenChange={setOpen}
          onClick={handleRefreshData}
        >
          <SyncOutlined style={{ fontSize: '1.3em' }} />
        </Dropdown.Button>
      </span>
    </Tooltip>
  );
}

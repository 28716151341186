import {FC, useCallback, useState} from 'react';

import {CodeEditor, Dialog, DialogActions, DialogContent, DialogTitle} from '@/shared/ui';
import {container} from 'tsyringe';
import {observer} from 'mobx-react-lite';
import {SubmitHandler, useForm} from 'react-hook-form';
import {KeyValue} from '@/entities/KeyValue';
import Stack from '@mui/material/Stack';
import {FieldInput} from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import {ButtonVariants} from '@/shared/ui/Button/types';
import {notify} from '@/shared/ui/Toast/notify';

import {UpsertKeyValueService} from '../api/UpsertKeyValueService';
import {FieldCode} from "@/shared/ui/Fields/components/FieldCode/FieldCode";

const upsertKeyValueService = container.resolve(UpsertKeyValueService);

export interface UpsertKeyValueFormProps {
}

export const UpsertKeyValueDialogForm: FC<UpsertKeyValueFormProps> = observer(() => {
    const [isLoading, setIsLoading] = useState(false);

    const {control, register, handleSubmit, reset} = useForm<KeyValue>({
        values: upsertKeyValueService.currentKeyValue,
    });

    const title = upsertKeyValueService.selectedId
        ? 'Изменить запись'
        : 'Добавить запись';
    const buttonSubmitText = upsertKeyValueService.selectedId ? 'Изменить' : 'Добавить';

    const handleClose = useCallback(() => {
        upsertKeyValueService.open = false;
        upsertKeyValueService.selectedId = null;

        reset({name: '', value: ''});
    }, [reset]);

    const handleValidSubmit = useCallback<SubmitHandler<KeyValue>>(
        async (externalService) => {
            try {
                setIsLoading(true);

                await upsertKeyValueService.upsertKeyValue(externalService);

                handleClose();

                if (upsertKeyValueService.selectedId) {
                    notify.success('Запись изменена');
                } else {
                    notify.success('Запись добавлена');
                }
            } catch {
                if (upsertKeyValueService.selectedId) {
                    notify.error('Не удалось изменить запись');
                } else {
                    notify.error('Не удалось добавить запись');
                }
            } finally {
                setIsLoading(false);
            }
        },
        [handleClose]
    );

    return (
        <Dialog maxWidth='xl' fullWidth open={upsertKeyValueService.open} onClose={handleClose}>
            <form onSubmit={handleSubmit(handleValidSubmit)}>
                <DialogTitle onClose={handleClose}>{title}</DialogTitle>
                <DialogContent>
                    <Stack gap={2.5} marginY='2px'>
                        <FieldInput error='' name='name' label='Название' register={register}/>
                        <FieldCode error='' name='value' control={control} label='Значение' register={register} props={{
                            language: "json"
                        }}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
                        Отмена
                    </Button>
                    <Button type='submit' variant={ButtonVariants.Popup} loading={isLoading}>
                        {buttonSubmitText}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
});

UpsertKeyValueDialogForm.displayName = 'UpsertKeyValueForm';

import {FC, useEffect, useRef} from 'react';

import Typography from "@mui/material/Typography";
import {Parameter} from "@/entities/Connect/types";
import {Box} from "@mui/material";
import {notify} from "@/shared/ui/Toast/notify";

import styles from './SmartTypography.module.scss';

export interface ClowerTypographyProps {
  parameter: Parameter;
  field: any;
}

export const SmartTypography: FC<ClowerTypographyProps> = ({
                                                             parameter,
                                                             field
                                                           }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      try {
        let elements = ref.current.getElementsByClassName("navigator_copy")
        Array.from(elements).forEach(value => {
          value.addEventListener("dblclick", () => {
            // @ts-ignore
            navigator.clipboard.writeText(value.textContent)
            notify.success("Скопировано в буфер обмена: "+value.textContent)
          })
        })
      } catch (ex) {

      }
    }
  }, [parameter])
  //const title = !parameter.preWrap ? `${parameter.bottomText}${parameter.isAddValue ? field.value ? field.value : "" : ""}`.replace(/(<.*?>)/g, '') : undefined

  return (
    <Box ref={ref}>
      <Typography
        className={parameter.preWrap ? "preWrap" : ""}
        noWrap={!parameter.preWrap}
        //title={!parameter.preWrap ? title : undefined}
      >
        <div
          dangerouslySetInnerHTML={{__html: `${parameter.bottomText}${parameter.isAddValue ? field.value ? field.value : "" : ""}`}}
        />
      </Typography>
    </Box>
  );
};

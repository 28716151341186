import React, { FC } from 'react';

import { useParams } from "react-router-dom";
import { observer } from 'mobx-react-lite';
import { DeleteFlow, EditFlow } from "@/entities/Flow/features";
import { CloneFlowButton } from "@/features/CloneFlow";
import { SaveVersionButton } from "@/features/SaveVersion";
import { OpenVersionsButton } from "@/features/OpenVersions";
import { ActionPanel } from "@/shared/ui";
import { SearchParams } from "@/hooks/useTemplateSearchParams";

import styles from './FlowActionBar.module.scss';

export const actionsSearchParams = [
  SearchParams.ShowFlowVersionForm,
  SearchParams.ShowFlowVersionList,
  SearchParams.ShowFlowCloneModal,
  SearchParams.ShowCreateFlowForm,
  SearchParams.ShowEditFlowForm,
  SearchParams.ShowTFlowDeleteModal,
  SearchParams.ShowCreateTFlowForm,
  SearchParams.ShowEditTFlowForm,
  SearchParams.ShowFlowDeleteModal,
  SearchParams.FlowId,
];

export const FlowActionBar: FC = observer(() => {
  const { flowId } = useParams();

  return (
    <ActionPanel className={styles.root} small withoutBorder>
      <EditFlow flowId={flowId} />
      <CloneFlowButton />
      <SaveVersionButton />
      <OpenVersionsButton />
      <DeleteFlow flowId={flowId} />
    </ActionPanel>
  );
});

FlowActionBar.displayName = 'FlowActionBar';

export type WithId<T> = T & { id: string };

export enum Themes {
  Dark = 'dark',
  Light = 'light',
}

export enum RoutePaths {
  Main = '/',
  Flow = 'flow',
  Properties = 'properties',
  Settings = 'settings',
  Tracing = 'tracing',
  Dashboard = 'dashboard',
  Logs = 'logs',
  Onboarding = 'onboarding',
  FlowVersions = 'flow-versions',
}

export enum RegistrableValues {
  FlowId = 'flow-id',
  DashboardId = 'dashboard-id',
  BlockId = 'block-id',
  PropertyId = 'property-id',
  VersionId = 'version-id',
  ConnectorType = 'connect-type',
  ConnectorName = 'connect-name',
}

export interface PaginationRequestParam {
  page?: number;
  size?: number;
}

export interface PageableObject {
  offset?: number;
  sort?: never; //SortObject;
  pageSize?: number;
  pageNumber?: number;
  paged?: boolean;
  unpaged?: boolean;
}

export interface Page<T extends unknown> {
  totalElements: number;
  totalPages: number;
  size: number;
  content: T[];
  number: number;
  sort: never; //SortObject;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: PageableObject;
  empty: boolean;
}

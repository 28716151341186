import { FC } from 'react';

import cn from 'classnames';

import styles from './ActionPanel.module.scss';

export interface ActionPanelProps {
  children: JSX.Element | (JSX.Element | null)[];
  className?: string;
  withoutBorder?: boolean;
  small?: boolean;
}

export const ActionPanel: FC<ActionPanelProps> = ({
  children,
  className = '',
  withoutBorder,
  small,
}) => {
  return (
    <div
      className={cn(
        className,
        styles.root,
        { [styles.withoutBorder]: withoutBorder },
        { [styles.small]: small }
      )}
    >
      {children}
    </div>
  );
};

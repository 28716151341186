import { Segment } from '../enums';
import { DayOfMonthValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class DayOfMonthContainer extends BaseContainer {
    getSegment() {
        return Segment.dayOfMonth;
    }
    cloneWith(d) {
        return new DayOfMonthContainer(d);
    }
    createValues(values) {
        return new DayOfMonthValues(values);
    }
}

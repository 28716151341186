import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { ErrorPacket } from '@/entities/Block/types';

import { MessageQueueService } from '../../../services/MessageQueueService';

const messageQueueService = container.resolve(MessageQueueService);

export interface SendMessageForceProps {
  message: ErrorPacket;
}

export const SendMessageForce: FC<SendMessageForceProps> = observer(({ message }) => {
  const handleClick = useCallback(() => {
    void messageQueueService.sendMessageForce(message);
  }, [message]);

  return (
    <Button
      variant={ButtonVariants.Secondary}
      loading={messageQueueService.isLoadingSendMessageForce}
      onClick={handleClick}
    >
      Принудительная отправка сообщения
    </Button>
  );
});

SendMessageForce.displayName = 'SendMessageForce';

import { FC, useCallback } from 'react';

import { ControlButton } from 'reactflow';
import { Stack } from '@mui/system';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import cn from 'classnames';
import { CheckIcon } from '@/shared/ui/Icons/CheckIcon/CheckIcon';
import { useResolve } from '@/hooks/useResolve';
import { FlowUpdaterService } from '@/entities/Flow/services/FlowUpdaterService';
import { RegistrableValues } from '@/shared/lib/types';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { UnsavedFlowChangesStore } from '@/entities/UnsavedChanges';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { SearchParams, useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { FactCheckIcon } from "@/shared/ui/Icons/FactCheckIcon/FactCheckIcon";
import Tooltip from "@/shared/ui/Tooltip";

export interface ApplyFlowChangesProps {
  isFlowVersion?: boolean;
}

const unsavedFlowChangesStore = container.resolve(UnsavedFlowChangesStore);
const joyrideService = container.resolve(JoyRideService);

export const ApplyFlowChanges: FC<ApplyFlowChangesProps> = observer(({ isFlowVersion }) => {
  const { flowId, versionId } = useParams();
  const { has, remove, set } = useSearchParamsTemplate();

  const flowUpdaterService = useResolve(FlowUpdaterService, { [RegistrableValues.FlowId]: flowId });

  const handleAcceptChangeButton = useCallback(() => {
    if (isFlowVersion && versionId) {
      void flowUpdaterService.saveFlowVersion(versionId);
    } else {
      void flowUpdaterService.saveFlow();
    }
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
  }, [flowUpdaterService, isFlowVersion, versionId, joyrideService.stepIndex]);

  const isShowDiffFlow = has(SearchParams.ShowDiffFlow)

  const handleOpenDetails = useCallback(() => {
    if (isShowDiffFlow) {
      remove([SearchParams.ShowDiffFlow])
    } else {
      set(SearchParams.ShowDiffFlow)
    }
  }, [isShowDiffFlow]);

  const { areThereChanges: isChanges } = unsavedFlowChangesStore;
  const changeControl = isChanges
    ? (
      <Stack direction="row" spacing={1} alignItems="center" id="applyChanges">
        {unsavedFlowChangesStore.isLoadingSave ? <LoaderIcon /> : <CheckIcon />}
        <p>Применить изменения</p>
      </Stack>
    )
    : 'Изменения отсутствуют';

  return (
    <>
      <ControlButton
        className="react-flow__controls-details"
        onClick={handleOpenDetails}
      >
        <Tooltip title="Показать список изменений потока">
          <div>
            <FactCheckIcon />
          </div>
        </Tooltip>
      </ControlButton>

      <ControlButton
        className={cn(
          { pulse: isChanges,
            "react-flow__controls-actualFlow": !isChanges,
            'react-flow__controls-applyFlow': isChanges
          },
        )}
        onClick={isChanges ? handleAcceptChangeButton : undefined}
        style={{
          width: "176px",
        }}
      >
        {changeControl}
      </ControlButton>
    </>
  );
});

ApplyFlowChanges.displayName = 'ApplyFlowChanges';

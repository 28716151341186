import { FC } from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { actionsSearchParams } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';

import { CloneFlowService } from '../api/CloneFlowService';

const cloneFlowService = container.resolve(CloneFlowService);

export const CloneFlowButton: FC = observer(() => {
  const { has, set, remove } = useSearchParamsTemplate();
  const active = has(SearchParams.ShowFlowCloneModal);

  const handleClick = (event: { stopPropagation: () => void; }) => {
    event.stopPropagation();
    remove(actionsSearchParams);
    set([SearchParams.ShowFlowCloneModal]);
  };

  return (
    <Tooltip title='Клонировать поток' placement='top'>
      <span>
        <Button
          className={cn({ active })}
          circle
          loading={cloneFlowService.isLoading}
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <ContentCopyIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

CloneFlowButton.displayName = 'CloneFlowButton';

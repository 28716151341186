import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { Normalized } from '@/shared/lib/normalize';

import { CounterTimeline } from '../components/DashboardPanel/CounterPanel/types';

@singleton()
export class CounterStore {
  counterTimelines: Normalized<CounterTimeline>;

  interval: number;

  constructor() {
    this.counterTimelines = { ids: [], entities: {} };
    this.interval = 1;

    makeAutoObservable(this);
  }

}

import { FC, MouseEventHandler, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { DeletePythonBeanService } from '../api/DeletePythonBeanService';

const deletePythonBeanService = container.resolve(DeletePythonBeanService);

export interface DeletePythonBeanButtonProps {
  formButton?: boolean;
}

export const DeletePythonBeanButton: FC<DeletePythonBeanButtonProps> = observer(
  ({ formButton }) => {
    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
      event.preventDefault();
      deletePythonBeanService.showDialog = true;
    }, []);

    if (formButton) {
      return (
        <Button
          loading={deletePythonBeanService.isLoading}
          variant={ButtonVariants.Secondary}
          onClick={handleClick}
        >
          Удалить
        </Button>
      );
    }

    return (
      <Tooltip title='Удалить скрипт' placement='top'>
        <span>
          <Button
            loading={deletePythonBeanService.isLoading}
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleClick}
          >
            <TrashIcon />
          </Button>
        </span>
      </Tooltip>
    );
  }
);

DeletePythonBeanButton.displayName = 'DeletePythonBeanButton';

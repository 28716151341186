import { FC } from 'react';

import { container } from 'tsyringe';
import dayjs from 'dayjs';
import cn from 'classnames';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import {FlowActionBar} from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';

import { WithActions } from './WithActions';

import styles from './TreeNode.module.scss';

const gflowService = container.resolve(GFlowService);
const showPriority = Boolean(localStorage.getItem('debugmode.priority'));

type NodeContentProps = {
  name: string;
  description: string;
  date: string;
  priority: number;
  selected: boolean;
  type: string,
}

const NodeContent: FC<NodeContentProps> = ({
  name = '<undefined>',
  description,
  date,
  priority,
  selected,
  type,
}) => {
  return (
    <div
      className={cn(
        styles.root,
        { [styles.root_selected]: selected },
      )}
    >
      {name}
      {description && (
        <div className={styles.description_text}>{description}</div>
      )}
      {showPriority && <i className={styles.date}>&nbsp;{priority}</i>}
      {date && (
        <div>
          <i className={styles.date}>{date}</i>
        </div>
      )}
      {selected && type === "flow" && (
        <div
          className={cn(
            styles.actionBar,
            { [styles.actionBar_selected]: selected && type === "flow" },
          )}
        >
          <FlowActionBar />
        </div>
      )}
    </div>
  );
}

const TreeNode: FC<{ id: string; selected: boolean }> = ({
  id,
  selected = false,
}) => {
  const record = gflowService.gflowsMap[id];
  if (!record) {
    // eslint-disable-next-line no-console
    console.error('Attempt got absent node: ', id);
    return null;
  }

  const { priority, data, type } = record;
  const { name, lastUpdate, description } = data;
  const date = lastUpdate ? dayjs(lastUpdate).format('DD.MM.YYYY') : '';

  return (
    <WithActions>
      <NodeContent
        name={name}
        description={description}
        date={date}
        priority={priority}
        selected={selected}
        type={type}
      />
    </WithActions>
  );
};
export default TreeNode;
import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { FlowStore } from "@/entities/Flow/stores/FlowStore";

@singleton()
export class DublicateFlowService {
  isLoading: boolean;
  showDialog: boolean;
  data: any;

  constructor(private apiService: ApiService, private flowStore: FlowStore) {
    this.isLoading = false;
    this.showDialog = false;
    this.data = [];

    makeAutoObservable(this);
  }

  get dataInfo() {
    return this.data
  }

  set dataInfo(data: any) {
    this.data = data;
  }

}
import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ExternalAppStore, ExternalService } from '@/entities/ExternalApp';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

@singleton()
export class UpsertExternalAppService {
  open: boolean;
  selectedId: string | null;

  constructor(private apiService: ApiService, private externalAppStore: ExternalAppStore) {
    this.open = false;
    this.selectedId = null;

    makeAutoObservable(this);
  }

  async upsertExternalApp(externalApp: ExternalService) {
    const response = await this.apiService.instance.put<ExternalService>(
      '/editor/externalService/save',
      externalApp
    );

    if (this.externalAppStore.externalApps) {
      this.externalAppStore.externalApps.entities[response.data.id] = response.data;

      if (!externalApp.id) {
        this.externalAppStore.externalApps.ids.push(response.data.id);
      }
    } else {
      this.externalAppStore.externalApps = normalize([response.data], 'id');
    }

    return response.data;
  }

  get currentExternalApp() {
    if (this.selectedId) {
      return this.externalAppStore.externalApps?.entities[this.selectedId];
    }

    return undefined;
  }
}

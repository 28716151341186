import { FC, memo, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Tooltip from '@/shared/ui/Tooltip';
import {
  getBlockFormSearchParamsTemplate,
  MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { Box } from '@mui/system';
import { TooltipProps } from '@mui/material/Tooltip';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface OpenBlockFormProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
}

const joyrideService = container.resolve(JoyRideService);

export const OpenBlockForm: FC<OpenBlockFormProps> = memo(
  ({ blockId, toolbarPlacement = 'left' }) => {
    const { set, remove } = useSearchParamsTemplate();

    const handleClick = useCallback(() => {
      if (joyrideService.run) {
        joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
      }
      remove([
        ...MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE,
        SearchParams.ShowCreateFlowForm,
        SearchParams.ShowEditFlowForm,
      ]);
      set(getBlockFormSearchParamsTemplate(blockId));
    }, [blockId, remove, set, joyrideService.stepIndex]);

    eventEmitter.on(`OpenBlockForm${blockId}`, handleClick);

    return (
      <Tooltip title='Настройки блока' placement={toolbarPlacement} disableInteractive>
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            id='settingsButton'
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <SettingsIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

OpenBlockForm.displayName = 'OpenBlockForm';

import { FC, memo } from 'react';

import { Controller } from 'react-hook-form';
import cn from 'classnames';
import Stack from '@mui/material/Stack';
import { Label } from '@/shared/ui/Label/Label';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { CodeEditor, CodeEditorProps, ErrorIcon } from '@/shared/ui';
import { amendDraggable } from '@/shared/lib/extendDndkit';

import styles from './FieldCode.module.scss';

export const FieldCode: FC<FieldProps & { title?: string } & Omit<CodeEditorProps, 'value'>> = memo(
  ({
    name,
    label,
    description,
    defaultValue,
    control,
    title,
    id,
    props,
    error,
    hideFormatSelect,
    hideLanguageSelect,
  }) => {
    return (
      <Stack className={styles.root}>
        <Stack direction='row' gap={1.5}>
          <Label tooltip={description}>
            <h3 className={cn(styles.title, { [styles.error]: error })}>{label}</h3>
          </Label>
          {error && <ErrorIcon tooltip={error.message} />}
        </Stack>
        <div className='col-span-12' id={id} {...amendDraggable(true)}>
          <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            render={({ field }) => {
              return (
                <CodeEditor
                  defaultLanguage={props?.language}
                  value={field.value}
                  title={label || title}
                  onChange={(value) => field.onChange(value)}
                  hideFormatSelect={hideFormatSelect}
                  hideLanguageSelect={hideLanguageSelect}
                />
              );
            }}
          />
        </div>
      </Stack>
    );
  }
);

import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageQueueContent, MessageQueueSidebar } from '../../widgets';

import styles from './MessageQueuePage.module.scss';

export const MessageQueuePage: FC = observer(() => {
  const { has, remove } = useSearchParamsTemplate();

  const handleDrawerClose = () => {
    remove([
      SearchParams.ShowMessageQueue,
      SearchParams.MessageId,
      SearchParams.ShowMessageForm,
      SearchParams.Statuses,
    ]);
  };

  return has(SearchParams.ShowMessageQueue) ? (
    <Drawer title='Очередь сообщений' onClose={handleDrawerClose} largeTitle fullscreen>
      <div>
        <div className={styles.root}>
          <MessageQueueSidebar />
          <MessageQueueContent />
        </div>
      </div>
    </Drawer>
  ) : null;
});

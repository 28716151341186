import {FC, memo} from 'react';

import Stack from '@mui/system/Stack';
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import {ButtonSizes, ButtonVariants} from '@/shared/ui/Button/types';
import {DownloadIcon} from '@/shared/ui/Icons/DownloadIcon/DownloadIcon';
import {TrashIcon} from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import {LoaderIcon} from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import {transformBytesToKb} from '@/shared/lib/transformBytesToKb';

import styles from './FileCards.module.scss';
import Grid from "@mui/system/Unstable_Grid";
import {FileCard} from "@/shared/ui";
import {Attachment} from "@/features/UploadFiles/api/types";

export interface FileCardsProps extends Pick<File, 'name' | 'size'> {
  isNew?: boolean;
  loading?: boolean;
  downloadLink?: string;
  attachments: Attachment[];
  isRemove?: boolean;
}

export const FileCards: FC<FileCardsProps> = memo(
  ({attachments, isRemove}) => {
    return (
      <Stack className={styles.root}>
        {attachments.map((attachment) => {
          const {fileName, fileSize, guid, url, type} = attachment;
          return (
              <FileCard
                type={type}
                name={fileName}
                size={fileSize}
                downloadLink={url}
                isRemove={isRemove}
                onRemove={() => {}}
              />
          );
        })}
      </Stack>
    )
  }
);

FileCards.displayName = 'FileCards';

import { RouteObject } from 'react-router-dom';
import { FlowVersionsPage } from '@/pages';
import { FlowVersionContent } from '@/widgets/FlowVersionContent';
import { RoutePaths } from '@/shared/lib/types';

export const flowVersionsRoutes: RouteObject[] = [
  {
    path: RoutePaths.FlowVersions,
    element: <FlowVersionsPage />,
    children: [
      {
        path: ':versionId/flows/:flowId',
        element: <FlowVersionContent />,
      },
    ],
  },
];

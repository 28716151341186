import React from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import _ from 'lodash'
import MenuItem from '@mui/material/MenuItem';
import { Input } from '@/shared/ui';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { panelTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles"

import { Settings } from '../CounterPanel';

import styles from './FormSettings.module.scss';

const dashboardService = container.resolve(DashboardService);

export interface DashboardPanelSettingsProps {
  dashboardPanelId: string;
}

export const FormSettings = observer(({
    dashboardPanelId,
  }: DashboardPanelSettingsProps) => {

    const dashboard = dashboardService.selectedDashboard
    const data = _.get(dashboard, `data.panels.${dashboardPanelId}`, {
      type: "",
      name: "",
    })

    const onChangeType = (newType: string) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { ...data, type: newType })
    }

    const onChangeName = (newName: string) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { ...data, name: newName })
    }

    const renderSelectType = () => (
      <Input
        select
        label={`Тип ${panelTitles.gen}`}
        value={data.type}
        SelectProps={{
          value: data.type,
          onChange: (event) => {
            onChangeType(event.target.value as string);
          },
        }}
      >
        <MenuItem key="CounterPanel" value="CounterPanel">
          График работы блока
        </MenuItem>
      </Input>
    );

    const renderSettingsByType = () => {
      switch (data.type) {
        case "CounterPanel": {
          return <Settings dashboardPanelId={dashboardPanelId} />
        }
        default: {
          return null;
        }
      }
    }

    return (
      <div>
        <div className={styles.root}>
          <Input
            label={`Наименование ${panelTitles.gen}`}
            value={data.name}
            onChange={(event) => {
              onChangeName(event.target.value);
            }}
          />
          {renderSelectType()}
          {renderSettingsByType()}
        </div>
      </div>
    )
  },
)

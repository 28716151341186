import { FC, memo, useEffect } from 'react';

import { Stack } from '@mui/system';
import { Outlet } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';
import { FlowVersionsSidebar } from '@/widgets';
import { container } from 'tsyringe';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';

export interface FlowVersionsPageProps {}

const allConnectService = container.resolve(AllConnectService);

export const FlowVersionsPage: FC<FlowVersionsPageProps> = memo(() => {
  useEffect(() => {
    void allConnectService.run();
  }, []);

  return (
    <Layout>
      <Stack display='grid' gridTemplateColumns='max-content 1fr max-content'>
        <FlowVersionsSidebar />
        <Outlet />
      </Stack>
    </Layout>
  );
});

FlowVersionsPage.displayName = 'FlowVersionsPage';

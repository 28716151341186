import { FC, useEffect } from 'react';

import { CodeEditor } from '@/shared/ui';
import Stack from '@mui/system/Stack';
import {
  OpenPythonBeanPageButton,
  PythonBeanPageDialog,
} from '@/features/ChoosePythonScript';
import Box from '@mui/system/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Typography from '@mui/material/Typography';
import { PythonBeanService } from '@/entities/PythonBean';

import { ChoosePythonScriptService } from '../api/ChoosePythonScriptService';
import { amendDraggable } from "@/shared/lib/extendDndkit";

const choosePythonScriptService = container.resolve(ChoosePythonScriptService);
const pythonBeanService = container.resolve(PythonBeanService);

export interface ChoosePythonBeanFormProps {
  value?: string;

  onChange?(value?: string): void;
}

export const ChoosePythonBeanForm: FC<ChoosePythonBeanFormProps> = observer(
  ({ value, onChange }) => {
    useEffect(() => {
      if (value) {
        pythonBeanService.getPythonScript(value).then(() => {
          choosePythonScriptService.setSelectedScript(value);
        });
      }
    }, [value]);

    useEffect(() => {
      onChange?.(choosePythonScriptService.selectedScript?.id);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onChange, choosePythonScriptService.selectedScript?.id]);

    return (
      <>
        <Stack gap={3} {...amendDraggable(true)}>
          <Box width={212}>
            <OpenPythonBeanPageButton />
          </Box>
          {choosePythonScriptService.selectedScript ? (
            <CodeEditor
              readonly
              hideFormatSelect
              hideLanguageSelect
              language='python'
              title='Python Bean'
              value={choosePythonScriptService.selectedScript?.code || ''}
            />
          ) : (
            <Typography>Скрипт не выбран</Typography>
          )}
        </Stack>
        <PythonBeanPageDialog />
      </>
    );
  }
);

ChoosePythonBeanForm.displayName = 'ChoosePythonBeanForm';

import React from 'react';
import TypographyMaterial from '@mui/material/Typography';

const Typography = (props) => {
    const {children, ...other} = props
    return (
        <TypographyMaterial
            {...other}
        >
            {children}
        </TypographyMaterial>
    );
};

export default Typography;
import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import {
  SettingsUserAuthService,
  SettingsUserAuthStore,
  SettingsUserAuthTypes,
} from '@/entities/SettingsUserAuth';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class DeleteSettingsUserAuthService {
  open: boolean;

  constructor(
    private apiService: ApiService,
    private settingsUserAuthStore: SettingsUserAuthStore,
    private settingsUserAuthService: SettingsUserAuthService
  ) {
    this.open = false;

    makeAutoObservable(this);
  }

  async deleteSettingsUserAuth() {
    if (this.settingsUserAuthStore.selectedType === SettingsUserAuthTypes.SettingsBasic) {
      const settingsBasic = { ...this.settingsUserAuthStore.settingsBasic! };

      // @ts-ignore
      delete settingsBasic!.entities[this.settingsUserAuthStore.selectedId];
      settingsBasic.ids!.splice(
        settingsBasic.ids!.indexOf(this.settingsUserAuthStore.selectedId!),
        1
      );

      const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
        ...this.settingsUserAuthStore.settingsUserAuth,
        settingsBasicList: Object.values(settingsBasic.entities),
      });

      this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

      return response.data;
    }

    if (this.settingsUserAuthStore.selectedType === SettingsUserAuthTypes.SettingsIdm) {
      const settingsIdm = { ...this.settingsUserAuthStore.settingsIdm! };

      // @ts-ignore
      delete settingsIdm.entities[this.settingsUserAuthStore.selectedId];
      settingsIdm.ids.splice(settingsIdm.ids.indexOf(this.settingsUserAuthStore.selectedId!), 1);

      const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
        ...this.settingsUserAuthStore.settingsUserAuth,
        settingsIDMList: Object.values(settingsIdm.entities),
      });

      this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

      return response.data;
    }
  }
}

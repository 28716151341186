import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { FieldList } from '@/shared/ui/Fields/components/FieldList/FieldList';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';
import Button from '@/shared/ui/Button';
import _ from 'lodash';
import { useParams } from 'react-router-dom';
import { RegistrableValues } from '@/shared/lib/types';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { useResolve } from '@/hooks/useResolve';
import { Block } from '@/entities/Block/types';
import { messageDeliverySettingsFormValidationSchema } from '@/entities/Block/containers/MessageDeliverySettingsFormContainer/validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';

import { BlockService } from '../../services/BlockService';

export const MessageDeliverySettingsFormContainer: FC = observer(() => {
  const { flowId } = useParams();
  const { remove, get } = useSearchParamsTemplate();

  const blockService = useResolve(BlockService, { [RegistrableValues.FlowId]: flowId });

  const blockId = get(SearchParams.BlockId);

  const {
    control,
    register,
    formState: { errors },
    setValue,
    handleSubmit,
  } = useForm<Block>({
    resolver: yupResolver(messageDeliverySettingsFormValidationSchema),
    values: blockService.blocks?.entities[blockId] || undefined,
    defaultValues: messageDeliverySettingsFormValidationSchema.cast(undefined),
    mode: 'onChange',
  });

  const isActiveGuaranteedDelivery = useWatch({ control, name: 'isActiveGuaranteedDelivery' });
  const isGuaranteedDelivery = useWatch({ control, name: 'isGuaranteedDelivery' });
  const isLimitedCountDelivery = useWatch({ control, name: 'isLimitedCountDelivery' });

  const onSubmit: SubmitHandler<Block> = (block) => {
    blockService.updateBlock(block);

    remove([
      SearchParams.ShowMessageDeliverySettings,
      SearchParams.ShowLogSettings,
      ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
    ]);
  };

  useEffect(() => {
    if (!isActiveGuaranteedDelivery) {
      setValue('isLimitedCountDelivery', false);
    }
  }, [isActiveGuaranteedDelivery, setValue]);

  useEffect(() => {
    if (!isGuaranteedDelivery) {
      setValue('isGuaranteedOrder', false);
    }
  }, [isGuaranteedDelivery, setValue]);

  return (
    <div>
      <form className='px-5'>
        <div className='grid grid-cols-12 gap-10'>
          <div className='col-span-12'>
            <FieldSwitch
              label='Активность сервиса переотправки пакетов'
              error={errors.isActiveGuaranteedDelivery}
              name='isActiveGuaranteedDelivery'
              control={control}
              description={
                !isActiveGuaranteedDelivery
                  ? 'Сервис не работает, нажмите для включения'
                  : 'Сервис работает, нажмите для выключения'
              }
            />
          </div>
          <div className='col-span-12'>
            <FieldSwitch
              label='Гарантированная доставка'
              error={errors.isGuaranteedDelivery}
              name='isGuaranteedDelivery'
              control={control}
              description={
                !isGuaranteedDelivery
                  ? 'Не позволяет отправить новые сообщения, если по какому-то из сообщений была ошибка.'
                  : 'Новые сообщения сохраняются в очередь'
              }
            />
          </div>
          <div className='col-span-6'>
            <FieldSwitch
              isDisabled={!isGuaranteedDelivery}
              error={errors.isGuaranteedOrder}
              label='Гарантированный порядок доставки'
              name='isGuaranteedOrder'
              control={control}
              description='Не позволяет отправить новые сообщения, если по какому-то из сообщений была ошибка. Новые сообщения сохраняются в очередь'
            />
          </div>
          <div className='col-span-6'>
            <FieldSwitch
              error={errors.isLimitedCountDelivery}
              label='Ограничение количества попыток отправки'
              name='isLimitedCountDelivery'
              control={control}
              isDisabled={!isActiveGuaranteedDelivery}
              description='Общее количество повторных попыток отправки'
            />
          </div>
          <div className='col-span-6'>
            <FieldList
              disabled={!isActiveGuaranteedDelivery}
              control={control}
              name='settingsGuaranteeDelivery.countToTimeDelayList'
              label='Настройки времени обработки сообщений с ошибкой:'
              renderElement={(index) => {
                return (
                  <div className='grid grid-cols-12 col-span-12 gap-5'>
                    <div className='col-span-6'>
                      <FieldInputNumber
                        step={1}
                        name={`settingsGuaranteeDelivery.countToTimeDelayList.${index}.count`}
                        error={_.get(
                          errors,
                          `settingsGuaranteeDelivery.countToTimeDelayList.${index}.count`
                        )}
                        register={register}
                        control={control}
                        label='Начиная с'
                      />
                    </div>
                    <div className='col-span-6'>
                      <FieldInput
                        name={`settingsGuaranteeDelivery.countToTimeDelayList.${index}.time`}
                        error={_.get(
                          errors,
                          `settingsGuaranteeDelivery.countToTimeDelayList.${index}.time`
                        )}
                        register={register}
                        label='Время ожидания в мс'
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
          <div className='col-span-6'>
            <FieldList
              disabled={!isLimitedCountDelivery}
              withoutAdding
              name=''
              control={control}
              renderElement={() => {
                return (
                  <div className='grid grid-cols-12 col-span-12 gap-5'>
                    <div className='col-span-6'>
                      <FieldInputNumber
                        step={1}
                        name='limitedCount'
                        error={_.get(errors, `limitedCount`)}
                        register={register}
                        label='Количество попыток'
                        control={control}
                      />
                    </div>
                  </div>
                );
              }}
            />
          </div>
        </div>
      </form>
      <div className='grid grid-cols-12 pr-5 pl-5 pb-10'>
        <div className='col-span-3 col-start-10'>
          <Button onClick={handleSubmit(onSubmit)}>Сохранить</Button>
        </div>
      </div>
    </div>
  );
});

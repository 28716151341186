import { FC, useCallback, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { useParams } from "react-router-dom";
import { container } from 'tsyringe';
import Box from '@mui/system/Box';
import Typography from '@mui/material/Typography';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { ListWithInfiniteScroll } from '@/shared/ui';
import { FlowVersionStore } from '@/entities/FlowVersion';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import { actionsSearchParams } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';
import { OpenVersionsService } from '@/features/OpenVersions';
import { SetVersionButton } from '@/features';
import { SetVersionDialog } from '@/features/SetVersion';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { ItemList } from './ItemList';

export interface FlowVersionListByFlowProps {
  flowId: string;
}

const openVersionsService = container.resolve(OpenVersionsService);
const flowVersionStore = container.resolve(FlowVersionStore);
const flowService = container.resolve(FlowService);
const gflowService = container.resolve(GFlowService);

export const FlowVersionListByFlow: FC = observer(() => {
  const { get, remove } = useSearchParamsTemplate();
  const { flowId = '' } = useParams();

  const showDrawer = get(SearchParams.ShowFlowVersionList);
  const handleDrawerClose = () => {
    remove(actionsSearchParams);
  };

  const handleListFetchNextElement = useCallback(
    (page: number) =>
      openVersionsService.getFlowVersionsByFlowId(flowId, { pagination: { page, size: 10 } }),
    [flowId],
  );

  const handleVersionDialogSuccess = useCallback(() => {
    flowService.getFlow(flowId);
    gflowService.syncFlowData(flowId);
  }, [flowId]);

  useEffect(() => () => {
    flowVersionStore.reset();
  }, []);

  if (!showDrawer) return null;

  return (
    <>
      <Drawer width={434} onClose={handleDrawerClose} title='Версии потока'>
        <Box paddingX={2.5} paddingBottom={2.5}>
          {flowVersionStore.versions?.ids.length === 0 && (
            <Typography>Нет сохранненых версий</Typography>
          )}
          <ListWithInfiniteScroll
            elements={flowVersionStore.versions?.ids || []}
            lastPage={flowVersionStore.lastPage}
            loading={flowVersionStore.isLoading}
            fetchNextElement={handleListFetchNextElement}
            elementWidth='95%'
            width='100%'
            renderElement={(id) => <ItemList id={id} />}
          />
          <SetVersionButton variant='primary' disabled={!openVersionsService.selectedVersionId} />
        </Box>
      </Drawer>
      {openVersionsService.selectedVersionId && (
        <SetVersionDialog
          versionId={openVersionsService.selectedVersionId}
          onSuccess={handleVersionDialogSuccess}
        />
      )}
    </>
  );
});

FlowVersionListByFlow.displayName = 'FlowVersionListByFlow';

import { FC, SVGProps } from 'react';

export const SortDownIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='8'
      viewBox='0 0 16 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.55664 4.77588L3.88997 7.44255L1.22331 4.77588'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.88997 7.44287L3.88997 0.556804'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.55664 0.557129L14.5566 0.557129M8.55664 3.2238L10.89 3.2238L13.2233 3.2238M8.55664 5.89046L10.2233 5.89046L11.89 5.89046'
        stroke='white'
        strokeLinecap='round'
      />
    </svg>
  );
};

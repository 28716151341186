import * as React from 'react';
import { FC, MouseEventHandler, useCallback, useState } from 'react';

import { observer, Observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import { container } from 'tsyringe';
import { Typography } from '@mui/material';
import cn from 'classnames';
import { ButtonWithModal } from '@/shared/ui/ButtonWithModal/ButtonWithModal';
import { SortDownIcon } from '@/shared/ui/Icons/SortDownIcon/SortDownIcon';
import { Menu, MenuItem } from '@/shared/ui';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { options } from '@/entities/PythonLib/utils/PythonLibConst';

import styles from './PythonLibsSortPods.module.scss';

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export const PythonLibsSortPods: FC = observer(() => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(
    (close: () => void) => () => {
      close();
    },
    []
  );

  const handleItemClick = useCallback(
    (option: any, close: () => void): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        pythonLibsStore.podStatus = option.key;
        pythonLibsService.getMonitoringPods(option.key).then(() => {
          close();
        });
      },
    []
  );

  const handleResetClick = useCallback(
    (close: () => void): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        pythonLibsStore.podStatus = null;
        pythonLibsService.getMonitoringPods(options[0].key).then(() => {
          close();
        });
      },
    []
  );

  return (
    <ButtonWithModal
      title='Сортировать по статусу'
      icon={<SortDownIcon transform='scale(1.5)' width={12} height={12} />}
      isActive={Boolean(pythonLibsStore.podStatus)}
      onClick={handleButtonClick}
      render={({ isOpen, close }) => (
        <Observer>
          {() => (
            <Menu
              keepMounted={false}
              anchorEl={anchor}
              open={isOpen}
              onClose={handleMenuClose(close)}
            >
              {options.map((option) => {
                const key = `${option.key}`;
                const selectedPropertyAndDirection =
                  pythonLibsStore.podStatus && `${pythonLibsStore.podStatus}`;

                return (
                  <MenuItem
                    className={cn({
                      [styles.active]: key === selectedPropertyAndDirection,
                    })}
                    selected={option.key === selectedPropertyAndDirection}
                    key={option.key}
                    onClick={handleItemClick(option, close)}
                  >
                    <Stack direction='row' justifyContent='space-between' width='100%'>
                      <p>{option.label}</p>
                    </Stack>
                  </MenuItem>
                );
              })}
              <MenuItem focusRipple={false} onClick={handleResetClick(close)}>
                <Typography>Сбросить сортировку</Typography>
              </MenuItem>
            </Menu>
          )}
        </Observer>
      )}
    />
  );
});

PythonLibsSortPods.displayName = 'PythonLibsSortPods';

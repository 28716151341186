import * as types from './actionTypes';

const initState = {
    path: null,
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.UPDATE_PATH:
            return {
                ...state,
                path: action.path,
                serverPath: action.serverPath,
                origin: action.origin
            }
        default:
            return state;
    }
}

const getPath = (state) => {
    return state.location.path;
}

export {
    getPath
}
import { RouteObject } from 'react-router-dom';
import { PropertiesPage, PropertyContentPage } from '@/pages';
import { RoutePaths } from '@/shared/lib/types';

export const propertyRoutes: RouteObject[] = [
  {
    path: RoutePaths.Properties,
    element: <PropertiesPage />,
    children: [{ path: ':propertyId', element: <PropertyContentPage /> }],
  },
];

import { RouteObject } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';

import { FlowPage } from './pages';

export const flowRoutes: RouteObject[] = [
  {
    path: 'flow',
    element: (
      <Layout>
        <FlowPage />
      </Layout>
    ),
    children: [
      {
        path: ':flowId',
      },
    ],
  },
];

import { FC, memo } from 'react';

import { ButtonSizes, ButtonVariants } from './types';
import { PlayIcon } from '../Icons/PlayIcon';

import Button, { ButtonProps } from './index';

export const PlayButton: FC<ButtonProps> = memo((props) => {
  return (
    <Button {...props} circle variant={ButtonVariants.Outlined} size={ButtonSizes.Small}>
      <PlayIcon />
    </Button>
  );
});

PlayButton.displayName = 'PlayButton';

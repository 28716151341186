import { forwardRef } from 'react';

import { motion } from 'framer-motion';
import MuiBox, { BoxProps } from '@mui/material/Box';

const Box = forwardRef((props: BoxProps, ref) => <MuiBox {...props} ref={ref} />);

export const AnimatedBox = motion(Box);

AnimatedBox.displayName = 'AnimatedBox';

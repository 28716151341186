import React, { FC, memo } from 'react';

import { Stack } from '@mui/system';
import { date } from '@/entities/PythonLib/utils/PythonLibConst';
import Typography from '@/shared/ui/Typography';
import { PythonPodLog } from '@/entities/PythonLib/stores/types';

import styles from './PythonLogsByPod.module.scss';

export interface PythonLogsByPodProps {
  errorLog: PythonPodLog[];
}

export const PythonLogsByPod: FC<PythonLogsByPodProps> = memo(({ errorLog }) => {
  return (
    <Stack padding='10px 0 10px 10px' gap={1}>
      <div>
        {errorLog?.map((error, index) => (
          <div
            className={styles.message}
            style={{
              padding: '10px',
            }}
            key={`${index}logInfo`}
          >
            <div className={styles.name}>
              <Typography>{error?.message}</Typography>
            </div>
            <span>:</span>
            <span className={styles.time}>
              <Typography>{date(error?.date)}</Typography>
            </span>
          </div>
        ))}
      </div>
    </Stack>
  );
});

PythonLogsByPod.displayName = 'PythonLogsByPod';

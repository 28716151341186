import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { MonitoringResponse } from '../types';

interface Monitoring {
  [flowId: string]: {
    [blockId: string]: MonitoringResponse;
  };
}

@singleton()
export class BlockMonitoringStore {
  monitoring: Monitoring;

  constructor() {
    this.monitoring = {};

    makeAutoObservable(this);
  }
}

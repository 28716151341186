import { FC } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import {
  BLOCK_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { Resizable } from '@/shared/ui';

import { BlockFormContainer } from './BlockFormContainer';

const joyrideService = container.resolve(JoyRideService);

export const BlockDrawerContainer: FC = observer(() => {
  const { has, remove, get } = useSearchParamsTemplate();

  const handleDrawerClose = (): void => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
    remove(BLOCK_FORM_SEARCH_PARAMS_TEMPLATE);
  };
  if (!has(SearchParams.ShowBlockForm)) return null;

  return (
    <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
      <Drawer title='Настройка блока' onClose={handleDrawerClose}>
        <BlockFormContainer key={get(SearchParams.BlockId)} />
      </Drawer>
    </Resizable>
  );
});

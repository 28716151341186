import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { Role, RoleStore } from '@/entities/Role';

@singleton()
export class CreateRoleService {
  open: boolean;

  constructor(private apiService: ApiService, private roleStore: RoleStore) {
    this.open = false;

    makeAutoObservable(this);
  }

  async createRole(role: Omit<Role, 'id'>) {
    const response = await this.apiService.instance.put<Role>('/editor/role/save', role);

    if (this.roleStore.roles) {
      this.roleStore.roles.ids.push(response.data.id);
      this.roleStore.roles.entities[response.data.id] = response.data;
    } else {
      this.roleStore.roles = normalize([response.data], 'id');
    }

    return response.data;
  }
}

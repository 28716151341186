import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DeleteRoleService } from '../api/DeleteRoleService';

const deleteRoleService = container.resolve(DeleteRoleService);

export interface DeleteRoleButtonProps {
  roleId: string;
}

export const DeleteRoleButton: FC<DeleteRoleButtonProps> = observer(({ roleId }) => {
  const handleClick = useCallback(async () => {
    deleteRoleService.open = true;
    deleteRoleService.selectedRoleId = roleId;
  }, [roleId]);

  return (
    <Tooltip title='Удалить роль' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteRoleButton.displayName = 'DeleteRoleButton';

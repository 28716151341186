import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { useForm } from 'react-hook-form';
import { container } from 'tsyringe';
import { AnimatePresence, motion } from 'framer-motion';
import { Stack } from '@mui/system';
import Grid from '@mui/system/Unstable_Grid';
import Box from '@mui/material/Box';
import { MessageQueueService } from '@/entities/Block/services/MessageQueueService';
import { SaveMessage } from '@/entities/Block/containers/MessageQueue/features/SaveMessage';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageQueueForm, SendMessageForce } from '../../features';

import styles from './MessageQueueContent.module.scss';

const messageQueueService = container.resolve(MessageQueueService);

export const MessageQueueContent: FC = observer(() => {
  const { get, remove, has } = useSearchParamsTemplate();

  const isOpenMessageForm = has(SearchParams.ShowMessageForm);
  const errorId = get(SearchParams.ErrorId);

  const {
    control,
    formState: { errors },
    watch,
  } = useForm({
    values: messageQueueService.messages.entities[errorId],
  });

  const values = watch();

  const handleCancelButtonClick = useCallback(() => {
    remove([SearchParams.ShowMessageForm, SearchParams.ErrorId]);
  }, [remove]);

  if (!messageQueueService.messages.entities[errorId]) {
    return null;
  }

  return (
    <AnimatePresence mode='wait'>
      {isOpenMessageForm ? (
        <motion.div
          variants={{
            open: { x: 0 },
            close: { x: '100%' },
          }}
          initial='close'
          animate='open'
          exit='close'
          key={errorId}
          transition={{ ease: 'easeInOut' }}
          className={styles.root}
        >
          <h2 className={styles.title}>Сообщение</h2>

          <Grid marginTop={5} container overflow='auto' gap={4}>
            {isOpenMessageForm && (
              <Grid xs={12}>
                <MessageQueueForm control={control} errors={errors} />
              </Grid>
            )}
          </Grid>

          <Stack direction='row' marginTop={4} alignItems='flex-end' gap={1.5}>
            <Grid container width='100%' gap={1.5}>
              <Box width={312}>
                <SendMessageForce message={values} />
              </Box>
            </Grid>
            <Grid container gap={1.5} justifyContent='flex-end'>
              <Stack direction={"row"} gap={1.5}>
              <Box minWidth={200}>
                <Button variant={ButtonVariants.Secondary} onClick={handleCancelButtonClick}>
                  Отмена
                </Button>
              </Box>
              <Box minWidth={200}>
                <SaveMessage message={values} onClick={handleCancelButtonClick} />
              </Box>
              </Stack>
            </Grid>
          </Stack>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
});

import React, { FC, useCallback } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export interface DeletePythonLibDialogProps {
  pythonLibTitle?: string;

  onClose?(): void;

  onSuccess?(): void;
}

export const DeletePythonLibDialog: FC<DeletePythonLibDialogProps> = observer(
  ({ pythonLibTitle, onSuccess, onClose }) => {
    const handleAccept = useCallback(() => {
      pythonLibsService.deletePythonLib(pythonLibTitle).then(onSuccess).then(onClose);
    }, [onClose, onSuccess, pythonLibTitle]);
    return (
      <Dialog open={pythonLibsService.showDialog} onClose={onClose}>
        <DialogTitle onClose={onClose}>Удалить библиотеку</DialogTitle>
        <DialogContent>{`Вы точно хотите удалить ${pythonLibTitle}?`}</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={onClose}>
            Отменить
          </Button>
          <Button
            loading={pythonLibsStore.isLoadingDelete}
            variant={ButtonVariants.Popup}
            onClick={handleAccept}
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeletePythonLibDialog.displayName = 'DeletePythonLibDialog';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Notification } from './types';

@singleton()
export class NotificationStore {
  notifications: Normalized<Notification> | null;
  totalPages: number | null;

  constructor() {
    this.notifications = null;
    this.totalPages = null;

    makeAutoObservable(this);
  }
}

import { CSSProperties, Ref, useCallback, useEffect, useMemo, useRef } from 'react';

import { useInfiniteScroll } from '@/hooks/useInfiniteScroll';
import { motion } from 'framer-motion';
import { CardWithActions } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Box from '@mui/system/Box';
import { isFunction } from 'lodash';

import styles from './ListWithInfiniteScroll.module.scss';

export interface ListWithInfiniteScrollProps<T> {
  propertyForKey?: string;
  elements: T[];
  renderElement: (
    element: T,
    index: number,
    rest?: {
      loading?: boolean;
      active?: boolean;
    }
  ) => JSX.Element;
  fetchNextElement: (page: number) => void;
  lastPage?: number;
  animated?: boolean | ((key: string) => any);
  stopAnimatedForKey?: string | null;
  loading?: boolean;
  activeElementKey?: string;
  width?: CSSProperties['width'];
  elementWidth?: CSSProperties['width'];
}

export const ListWithInfiniteScroll = observer(function <T extends unknown>({
  elements,
  lastPage,
  renderElement,
  fetchNextElement,
  propertyForKey,
  animated,
  stopAnimatedForKey,
  loading,
  activeElementKey,
  elementWidth,
  width,
}: ListWithInfiniteScrollProps<T>) {
  const { setLastElement } = useInfiniteScroll(fetchNextElement, lastPage);

  const listRef = useRef<HTMLLIElement>(null);
  const timeoutId = useRef<number>();

  const getAnimationProps = useCallback(
    (key: string) => {
      if (isFunction(animated)) {
        return animated(key);
      }
      if (key === stopAnimatedForKey) {
        return {
          whileHover: { scale: 1 },
          whileTap: { scale: 1 },
        };
      }
      if (animated) {
        return {
          whileHover: { scale: 1.04 },
          whileTap: { scale: 1.04 },
        };
      }
    },
    [animated, stopAnimatedForKey]
  );

  const memoizedElements = useMemo(() => {
    if (!loading) {
      return elements.map((element, index) => {
        const ref =
          elements.length - 1 === index ? (setLastElement as Ref<HTMLLIElement>) : undefined;
        // @ts-ignore
        const key = typeof element === 'string' ? element : element[propertyForKey];
        const active = activeElementKey === key;

        return (
          <motion.li
            style={{ width: elementWidth || '216px' }}
            key={key}
            role='menuitem'
            id={active ? 'active' : undefined}
            ref={ref || null}
            {...getAnimationProps(key)}
          >
            {renderElement(element, index, { loading, active })}
          </motion.li>
        );
      });
    } else {
      return new Array(elements.length || 10).fill('').map((value, index) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <li key={index} role='menuitem' style={{ width: elementWidth || '216px' }}>
            <CardWithActions loading title='' />
          </li>
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    activeElementKey,
    elements,
    getAnimationProps,
    loading,
    propertyForKey,
    renderElement,
    setLastElement,
    elements.length,
  ]);

  useEffect(() => {
    timeoutId.current = window.setTimeout(() => {
      listRef.current?.querySelector('#active')?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });

      clearTimeout(timeoutId.current);
    }, 300);
  }, [activeElementKey]);

  return (
    <Box maxWidth={width} component='ul' className={styles.root} ref={listRef}>
      {memoizedElements}
    </Box>
  );
});

ListWithInfiniteScroll.displayName = 'ListWithInfiniteScroll';

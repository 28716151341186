import React, { FC, useCallback, useState } from 'react';

import MenuItem from '@mui/material/MenuItem';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { toUpperFirstLetter } from '@/shared/lib';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { RegistrableValues } from '@/shared/lib/types';

import { ToggleConnectorService } from '../api/ToggleConnectorService';

export interface ToggleConnectorMenuItemProps {
  isActiveConnector: boolean;
  blockId: string;
  connectorId: string;
  connectorType: string;

  onClick?(): void;
}

export const ToggleConnectorMenuItem: FC<ToggleConnectorMenuItemProps> = observer(
  ({ blockId, connectorId, connectorType, isActiveConnector, onClick }) => {
    container.register(RegistrableValues.BlockId, { useValue: blockId });
    const toggleConnectorService = container.resolve(ToggleConnectorService);

    const [isLoading, setIsLoading] = useState(false);

    const entityName = connectorType === 'processor' ? 'обработчик' : 'коннектор';

    const handleClick = useCallback(async () => {
      try {
        setIsLoading(true);
        await toggleConnectorService.toggleConnector(blockId, connectorId, connectorType);

        if (isActiveConnector) {
          notify.success(`${toUpperFirstLetter(entityName)} выключен`);
        } else {
          notify.success(`${toUpperFirstLetter(entityName)} включен`);
        }

        onClick?.();
      } catch {
        if (isActiveConnector) {
          notify.error(`Не удалось выключить ${entityName}`);
        } else {
          notify.error(`Не удалось включить ${entityName}`);
        }
      } finally {
        setIsLoading(false);
      }
    }, [
      blockId,
      connectorId,
      connectorType,
      entityName,
      isActiveConnector,
      onClick,
      toggleConnectorService,
    ]);

    return (
      <MenuItem onClick={handleClick}>
        <Stack direction='row' gap={2.5}>
          {isLoading && <LoaderIcon />}
          <Typography>Включить / выключить</Typography>
        </Stack>
      </MenuItem>
    );
  }
);

ToggleConnectorMenuItem.displayName = 'ToggleConnectorMenuItem';

/* eslint-disable react/jsx-handler-names */
import React, { memo, useMemo } from 'react';

import { Radio } from '@/shared/ui/Radio/Radio';
import { Select } from '@/shared/ui/CronSelect/Select';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { Stack } from '@mui/system';
import { Grid } from '@/shared/ui/Grid/Grid';
import { SelectSizes } from '@/shared/ui/CronSelect/types';

import { getDaysOfWeekCodes, getList, Mode, Segment, Type } from '../../../../cron-core';
import { genId, getCssClassPrefix, localizeLabel, localizeList } from '../../../helpers';
import { SimpleRange } from '../../../shared';
import { CronQuartzTabProps } from '../shared';

export const QuartzCronDay = memo(
  ({ service, session, localization, cssClassPrefix }: CronQuartzTabProps) => {
    const { common, quartz } = localization;
    const {
      every,
      dayOfWeekIncrement,
      dayOfMonthLastDay,
      dayOfWeekNTHWeekDayOfMonth,
      dayOfMonthNearestWeekDayOfMonth,
      dayOfMonthDaysBeforeEndMonth,
      dayOfWeekLastNTHDayWeek,
      dayOfMonthLastDayWeek,
      dayOfMonthIncrement,
      dayOfWeekAnd,
      dayOfWeekRange,
      dayOfMonthAnd,
    } = quartz.day;
    const classPrefix = getCssClassPrefix(cssClassPrefix);
    const api = service.getApi(Type.DAY);
    const daysOfWeekEvery = getList(Segment.dayOfWeek, true);
    const daysOfWeek = getList(Segment.dayOfWeek);
    const daysOfWeekCodes = getDaysOfWeekCodes();
    const daysOfMonthEvery = getList(Segment.dayOfMonth, true);
    const daysOfMonth = getList(Segment.dayOfMonth);
    const limitedDaysOfMonth = daysOfMonthEvery.slice(0, 5);

    const everyComponent = useMemo(
      () => (
        <Radio
          id={genId(Mode.EVERY, session, Segment.dayOfWeek)}
          value={Mode.EVERY}
          checked={api.isEverySelected()}
          disabled={service.isDisabled()}
          label={every.label}
          onChange={() => api.selectEvery()}
        />
      ),
      [api, every.label, service, session]
    );

    const dayOfWeekIncrementComponent = useMemo(() => {
      const dayOfWeekIncrementPrimaryValue = api.getDayOfWeekIncrementPrimary();
      const dayOfWeekIncrementSecondaryValue = api.getDayOfWeekIncrementSecondary();

      const daysOfWeekOptions = daysOfWeek.map((option) => ({
        ...option,
        label: localizeLabel(option.label, common.dayOfWeek),
      }));

      const filteredDayOfWeekIncrementPrimaryValue = daysOfWeekEvery.find((option) => {
        return option.value === dayOfWeekIncrementPrimaryValue;
      })?.value;

      const filteredDayOfWeekIncrementSecondaryValue = daysOfWeekOptions.find(
        (option) => option.value === dayOfWeekIncrementSecondaryValue
      )?.value;

      const checked = api.isDayOfWeekIncrementSelected();

      const handleDayOfWeekIncrementPrimaryChange = (event: any, value: any) => {
        api.setDayOfWeekIncrementPrimary(value);
      };

      const handleDayOfWeekIncrementSecondaryChange = (event: any, value: any) => {
        api.setDayOfWeekIncrementSecondary(value);
      };

      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 1}>
          <Grid>
            <Radio
              id={genId(Mode.INCREMENT, session, Segment.dayOfWeek)}
              value={Mode.INCREMENT}
              checked={checked}
              disabled={service.isDisabled()}
              label={`${dayOfWeekIncrement.label1} ${checked ? '' : 'N'}`}
              onChange={() => api.selectDayOfWeekIncrement()}
            />
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfWeekIncrementPrimaryValue}
                options={daysOfWeekEvery}
                onChange={handleDayOfWeekIncrementPrimaryChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.INCREMENT, session, Segment.dayOfWeek)}>
              {dayOfWeekIncrement.label2} {!checked && 'N'}
            </label>
          </Grid>
          {checked && (
            <Grid xs={2}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfWeekIncrementSecondaryValue}
                options={daysOfWeekOptions}
                onChange={handleDayOfWeekIncrementSecondaryChange}
              />
            </Grid>
          )}
        </Grid>
      );
    }, [api, common.dayOfWeek, dayOfWeekIncrement, daysOfWeek, daysOfWeekEvery, service, session]);

    const dayOfMonthIncrementComponent = useMemo(() => {
      const dayOfMonthIncrementPrimaryValue = api.getDayOfMonthIncrementPrimary();
      const dayOfMonthIncrementSecondaryValue = api.getDayOfMonthIncrementSecondary();

      const dayOfMonthOptions = daysOfMonthEvery.map((option) => ({
        ...option,
        label: localizeLabel(option.label, common.dayOfMonth),
      }));

      const filteredDayOfMonthIncrementPrimaryValue = daysOfWeekEvery.find(
        (option) => option.value === dayOfMonthIncrementPrimaryValue
      )?.value;

      const filteredDayOfMonthIncrementSecondaryValue = dayOfMonthOptions.find(
        (option) => option.value === dayOfMonthIncrementSecondaryValue
      )?.value;

      const checked = api.isDayOfMonthIncrementSelected();

      const handleDayOfMonthIncrementPrimaryChange = (event: any, value: any) => {
        api.setDayOfMonthIncrementPrimary(value);
      };

      const handleDayOfMonthIncrementSecondaryChange = (event: any, value: any) => {
        api.setDayOfMonthIncrementSecondary(value);
      };
      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 1}>
          <Grid>
            <Radio
              id={genId(Mode.INCREMENT, session, Segment.dayOfMonth)}
              value={Mode.INCREMENT}
              checked={checked}
              disabled={service.isDisabled()}
              label={`${dayOfMonthIncrement.label1} ${checked ? '' : 'N'}`}
              onChange={() => api.selectDayOfMonthIncrement()}
            />
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfMonthIncrementPrimaryValue}
                options={daysOfMonth}
                onChange={handleDayOfMonthIncrementPrimaryChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.INCREMENT, session, Segment.dayOfMonth)}>
              {dayOfMonthIncrement.label2} {!checked && 'N'}
            </label>
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfMonthIncrementSecondaryValue}
                options={dayOfMonthOptions}
                onChange={handleDayOfMonthIncrementSecondaryChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.INCREMENT, session, Segment.dayOfMonth)}>
              {dayOfMonthIncrement.label3}
            </label>
          </Grid>
        </Grid>
      );
    }, [
      api,
      common.dayOfMonth,
      dayOfMonthIncrement.label1,
      dayOfMonthIncrement.label2,
      dayOfMonthIncrement.label3,
      daysOfMonth,
      daysOfMonthEvery,
      daysOfWeekEvery,
      service,
      session,
    ]);

    const dayOfWeekAndComponent = useMemo(() => {
      const checked = api.isDayOfWeekAndSelected();

      return (
        <Grid container>
          <Grid xs={6}>
            <Stack gap={2}>
              <Radio
                id={genId(Mode.AND, session, Segment.dayOfWeek)}
                value={Mode.INCREMENT}
                checked={checked}
                disabled={service.isDisabled()}
                label={dayOfWeekAnd.label}
                onChange={() => api.selectDayOfWeekAnd()}
              />

              {checked && (
                <Stack
                  component='ul'
                  display='grid'
                  gridTemplateColumns={`repeat(${daysOfWeekCodes.length}, auto)`}
                  gap={2}
                >
                  {daysOfWeekCodes.map((item) => {
                    return (
                      <li key={item.value}>
                        <Checkbox
                          id={genId(Mode.AND, session, Segment.dayOfWeek + item.value)}
                          value={item.value}
                          disabled={api.isDayOfWeekAndControlsDisabled()}
                          checked={api.isSelectedDayOfWeekAndValue(item.value)}
                          label={localizeLabel(item.label, common.dayOfWeek)}
                          onChange={() => api.selectDayOfWeekAndValue(item.value)}
                        />
                      </li>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      );
    }, [api, common.dayOfWeek, dayOfWeekAnd.label, daysOfWeekCodes, service, session]);

    const dayOfWeekRangeComponent = useMemo(() => {
      return (
        <SimpleRange
          cssClassPrefix={classPrefix}
          segmentId={genId(Mode.RANGE, session)}
          checked={api.isDayOfWeekRangeSelected()}
          disabled={service.isDisabled()}
          onSelect={() => api.selectDayOfWeekRange()}
          disabledControls={api.isDayOfWeekRangeControlsDisabled()}
          label1={dayOfWeekRange.label1}
          label2={dayOfWeekRange.label2}
          primaryOptions={localizeList(daysOfWeekCodes, common.dayOfWeek)}
          primaryValue={api.getDayOfWeekRangePrimary()}
          onPrimaryValueChange={api.setDayOfWeekRangePrimary}
          secondaryOptions={localizeList(daysOfWeekCodes, common.dayOfWeek)}
          secondaryValue={api.getDayOfWeekRangeSecondary()}
          onSecondaryValueChange={api.setDayOfWeekRangeSecondary}
        />
      );
    }, [
      api,
      classPrefix,
      common.dayOfWeek,
      dayOfWeekRange.label1,
      dayOfWeekRange.label2,
      daysOfWeekCodes,
      service,
      session,
    ]);

    const dayOfMonthAndComponent = useMemo(() => {
      const checked = api.isDayOfMonthAndSelected();

      return (
        <Grid container>
          <Grid xs={6}>
            <Stack gap={2}>
              <Radio
                id={genId(Mode.AND, session, Segment.dayOfMonth)}
                value={Mode.INCREMENT}
                checked={checked}
                disabled={service.isDisabled()}
                label={dayOfMonthAnd.label}
                onChange={() => api.selectDayOfMonthAnd()}
              />

              {checked && (
                <Stack
                  component='ul'
                  display='grid'
                  gridTemplateColumns={`repeat(${Math.ceil(daysOfMonth.length / 5)}, auto)`}
                  gap={2}
                >
                  {daysOfMonth.map((item) => {
                    return (
                      <li key={item.value}>
                        <Checkbox
                          id={genId(Mode.AND, session, Segment.dayOfMonth + item.value)}
                          value={item.value}
                          disabled={api.isDayOfMonthAndControlsDisabled()}
                          checked={api.isSelectedDayOfMonthAndValue(item.value)}
                          label={item.label}
                          onChange={() => api.selectDayOfMonthAndValue(item.value)}
                        />
                      </li>
                    );
                  })}
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      );
    }, [api, dayOfMonthAnd.label, daysOfMonth, service, session]);

    const dayOfMonthLastDayComponent = useMemo(
      () => (
        <Radio
          id={genId(Mode.LAST_DAY, session, Segment.dayOfMonth)}
          value={Mode.LAST_DAY}
          checked={api.isDayOfMonthLastDaySelected()}
          disabled={service.isDisabled()}
          label={dayOfMonthLastDay.label}
          onChange={() => api.selectDayOfMonthLastDay()}
        />
      ),
      [api, dayOfMonthLastDay.label, service, session]
    );

    const dayOfMonthLastDayWeekComponent = useMemo(
      () => (
        <Radio
          id={genId(Mode.LAST_DAY_WEEK, session, Segment.dayOfMonth)}
          value={Mode.LAST_DAY_WEEK}
          checked={api.isDayOfMonthLastDayWeekSelected()}
          disabled={service.isDisabled()}
          label={dayOfMonthLastDayWeek.label}
          onChange={() => api.selectDayOfMonthLastDayWeek()}
        />
      ),
      [api, dayOfMonthLastDayWeek.label, service, session]
    );

    const dayOfWeekLastNTHDayWeekComponent = useMemo(() => {
      const dayOfWeekLastNTHDayWeekValue = api.getDayOfWeekLastNTHDayWeekValue();

      const daysOfWeekOptions = daysOfWeek.map((option) => ({
        value: option.value + 'L',
        label: localizeLabel(option.label, common.dayOfWeek),
      }));

      const filteredDayOfWeekLastNTHDayWeekValue = daysOfWeekOptions.find(
        (option) => option.value === dayOfWeekLastNTHDayWeekValue
      )?.value;

      const checked = api.isDayOfWeekLastNTHDayWeekSelected();

      const handleDayOfWeekLastNTHDayWeekValueChange = (event: any, value: any) => {
        api.setDayOfWeekLastNTHDayWeekValue(value);
      };

      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 0.5}>
          <Grid>
            <Radio
              id={genId(Mode.LAST_NTH_DAY_WEEK, session, Segment.dayOfWeek)}
              value={Mode.LAST_NTH_DAY_WEEK}
              checked={checked}
              disabled={service.isDisabled()}
              label={`${dayOfWeekLastNTHDayWeek.label1} ${checked ? '' : 'N'}`}
              onChange={() => api.selectDayOfWeekLastNTHDayWeek()}
            />
          </Grid>
          {checked && (
            <Grid xs={2}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfWeekLastNTHDayWeekValue}
                options={daysOfWeekOptions}
                onChange={handleDayOfWeekLastNTHDayWeekValueChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.LAST_NTH_DAY_WEEK, session, Segment.dayOfWeek)}>
              {dayOfWeekLastNTHDayWeek.label2}
            </label>
          </Grid>
        </Grid>
      );
    }, [
      api,
      common.dayOfWeek,
      dayOfWeekLastNTHDayWeek.label1,
      dayOfWeekLastNTHDayWeek.label2,
      daysOfWeek,
      service,
      session,
    ]);

    const dayOfMonthDaysBeforeEndMonthComponent = useMemo(() => {
      const dayOfMonthDaysBeforeEndMonthValue = api.getDayOfMonthDaysBeforeEndMonthValue();

      const daysOfMonthOptions = daysOfMonth.map((option) => ({
        value: 'L-' + option.value,
        label: option.label,
      }));

      const filteredDayOfMonthDaysBeforeEndMonthValue = daysOfMonthOptions.find(
        (option) => option.value === dayOfMonthDaysBeforeEndMonthValue
      )?.value;

      const checked = api.isDayOfMonthDaysBeforeEndMonthSelected();

      const handleDayOfMonthDaysBeforeEndMonthValueChange = (event: any, value: any) => {
        api.setDayOfMonthDaysBeforeEndMonthValue(value);
      };

      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 0.5}>
          <Grid>
            <Radio
              id={genId(Mode.DAYS_BEFORE_END_MONTH, session, Segment.dayOfMonth)}
              value={Mode.DAYS_BEFORE_END_MONTH}
              checked={checked}
              label={checked ? undefined : 'N'}
              disabled={service.isDisabled()}
              onChange={() => api.selectDayOfMonthDaysBeforeEndMonth()}
            />
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfMonthDaysBeforeEndMonthValue}
                options={daysOfMonthOptions}
                onChange={handleDayOfMonthDaysBeforeEndMonthValueChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.DAYS_BEFORE_END_MONTH, session, Segment.dayOfMonth)}>
              {dayOfMonthDaysBeforeEndMonth.label}
            </label>
          </Grid>
        </Grid>
      );
    }, [api, dayOfMonthDaysBeforeEndMonth.label, daysOfMonth, service, session]);

    const dayOfMonthNearestWeekDayOfMonthComponent = useMemo(() => {
      const dayOfMonthNearestWeekDayOfMonthValue = api.getDayOfMonthNearestWeekDayOfMonthValue();

      const daysOfMonthEveryOptions = daysOfMonthEvery.map((option) => ({
        value: option.value + 'W',
        label: localizeLabel(option.label, common.dayOfMonth),
      }));

      const filteredDayOfMonthNearestWeekDayOfMonthValue = daysOfMonthEveryOptions.find(
        (option) => option.value === dayOfMonthNearestWeekDayOfMonthValue
      )?.value;

      const checked = api.isDayOfMonthNearestWeekDayOfMonthSelected();

      const handleDayOfMonthNearestWeekDayOfMonthValueChange = (event: any, value: any) => {
        api.setDayOfMonthNearestWeekDayOfMonthValue(value);
      };

      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 0.5}>
          <Grid>
            <Radio
              id={genId(Mode.NEAREST_WEEKDAY_OF_MONTH, session, Segment.dayOfMonth)}
              value={Mode.NEAREST_WEEKDAY_OF_MONTH}
              checked={checked}
              disabled={service.isDisabled()}
              label={`${dayOfMonthNearestWeekDayOfMonth.label1} ${checked ? '' : 'N'}`}
              onChange={() => api.selectDayOfMonthNearestWeekDayOfMonth()}
            />
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfMonthNearestWeekDayOfMonthValue}
                options={daysOfMonthEveryOptions}
                onChange={handleDayOfMonthNearestWeekDayOfMonthValueChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.NEAREST_WEEKDAY_OF_MONTH, session, Segment.dayOfMonth)}>
              {dayOfMonthNearestWeekDayOfMonth.label2}
            </label>
          </Grid>
        </Grid>
      );
    }, [
      api,
      common.dayOfMonth,
      dayOfMonthNearestWeekDayOfMonth.label1,
      dayOfMonthNearestWeekDayOfMonth.label2,
      daysOfMonthEvery,
      service,
      session,
    ]);

    const dayOfWeekNTHWeekDayOfMonthComponent = useMemo(() => {
      const dayOfWeekNTHWeekDayOfMonthPrimaryValue =
        api.getDayOfWeekNTHWeekDayOfMonthPrimaryValue();

      const dayOfWeekNTHWeekDayOfMonthSecondaryValue =
        api.getDayOfWeekNTHWeekDayOfMonthSecondaryValue();

      const limitedDaysOfMonthOptions = limitedDaysOfMonth.map((option) => ({
        value: option.value,
        label: localizeLabel(option.label, common.dayOfMonth),
      }));

      const daysOfWeekOptions = daysOfWeek.map((option) => ({
        value: option.value,
        label: localizeLabel(option.label, common.dayOfWeek),
      }));

      const filteredDayOfWeekNTHWeekDayOfMonthPrimaryValue = limitedDaysOfMonthOptions.find(
        (option) => option.value === dayOfWeekNTHWeekDayOfMonthPrimaryValue
      )?.value;

      const filteredDayOfWeekNTHWeekDayOfMonthSecondaryValue = daysOfWeekOptions.find(
        (option) => option.value === dayOfWeekNTHWeekDayOfMonthSecondaryValue
      )?.value;

      const checked = api.isDayOfWeekNTHWeekDayOfMonthSelected();

      const handleDayOfWeekNTHWeekDayOfMonthPrimaryValueChange = (event: any, value: any) => {
        api.setDayOfWeekNTHWeekDayOfMonthPrimaryValue(value);
      };

      const handleDayOfWeekNTHWeekDayOfMonthSecondaryValueChange = (event: any, value: any) => {
        api.setDayOfWeekNTHWeekDayOfMonthSecondaryValue(value);
      };

      return (
        <Grid xs={12} container alignItems='center' gap={checked ? 4 : 0.5}>
          <Grid>
            <Radio
              id={genId(Mode.NTH_WEEKDAY_OF_MONTH, session, Segment.dayOfWeek)}
              value={Mode.NTH_WEEKDAY_OF_MONTH}
              checked={checked}
              disabled={service.isDisabled()}
              label={`${dayOfWeekNTHWeekDayOfMonth.label1} ${checked ? '' : 'N'}`}
              onChange={() => api.selectDayOfWeekNTHWeekDayOfMonth()}
            />
          </Grid>
          {checked && (
            <Grid xs={1.5}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfWeekNTHWeekDayOfMonthPrimaryValue}
                options={limitedDaysOfMonthOptions}
                onChange={handleDayOfWeekNTHWeekDayOfMonthPrimaryValueChange}
              />
            </Grid>
          )}
          {checked && (
            <Grid xs={2}>
              <Select
                size={SelectSizes.Small}
                defaultValue={filteredDayOfWeekNTHWeekDayOfMonthSecondaryValue}
                options={daysOfWeekOptions}
                onChange={handleDayOfWeekNTHWeekDayOfMonthSecondaryValueChange}
              />
            </Grid>
          )}
          <Grid>
            <label htmlFor={genId(Mode.NTH_WEEKDAY_OF_MONTH, session, Segment.dayOfWeek)}>
              {dayOfWeekNTHWeekDayOfMonth.label2}
            </label>
          </Grid>
        </Grid>
      );
    }, [
      api,
      common.dayOfMonth,
      common.dayOfWeek,
      dayOfWeekNTHWeekDayOfMonth.label1,
      dayOfWeekNTHWeekDayOfMonth.label2,
      daysOfWeek,
      limitedDaysOfMonth,
      service,
      session,
    ]);

    return (
      <Stack gap={4}>
        {everyComponent}
        {dayOfWeekIncrementComponent}
        {dayOfMonthIncrementComponent}
        {dayOfWeekAndComponent}
        {dayOfWeekRangeComponent}
        {dayOfMonthAndComponent}
        {dayOfMonthLastDayComponent}
        {dayOfMonthLastDayWeekComponent}
        {dayOfWeekLastNTHDayWeekComponent}
        {dayOfMonthDaysBeforeEndMonthComponent}
        {dayOfMonthNearestWeekDayOfMonthComponent}
        {dayOfWeekNTHWeekDayOfMonthComponent}
      </Stack>
    );
  }
);

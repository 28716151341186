import React, { FC, useCallback, useState } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { NotificationStore } from '@/entities/Notification';

import { SendNotificationService } from '../api/SendNotificationService';

const sendNotificationService = container.resolve(SendNotificationService);
const notificationStore = container.resolve(NotificationStore);

export interface SendNotificationProps {
  notificationId: string;
}

export const SendNotification: FC<SendNotificationProps> = observer(({ notificationId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = useCallback(async () => {
    try {
      setIsLoading(true);

      const notification = notificationStore.notifications?.entities[notificationId];
      if (notification) {
        await sendNotificationService.sendNotification(notification);
      }

      notify.success('Уведомление отправлено');
    } catch {
      notify.error('Не удалось отправить уведомление');
    } finally {
      setIsLoading(false);
    }
  }, [notificationId]);

  return (
    <Box width={212}>
      <Button variant={ButtonVariants.Secondary} loading={isLoading} onClick={handleClick}>
        Отправить сейчас
      </Button>
    </Box>
  );
});

SendNotification.displayName = 'SendNotification';

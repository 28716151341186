import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { KeyValueStore } from '@/entities/KeyValue';

@singleton()
export class DeleteKeyValueService {
  open: boolean;
  selectedId: string | null;

  constructor(private apiService: ApiService, private keyValueStore: KeyValueStore) {
    this.open = false;
    this.selectedId = null;

    makeAutoObservable(this);
  }

  async deleteKeyValue() {
    if (this.selectedId) {
      await this.apiService.instance.delete(`/editor/keyValue/${this.selectedId}`);

      const index = this.keyValueStore.keyValues?.ids.indexOf(this.selectedId);

      if (index || index === 0) {
        runInAction(() => {
          this.keyValueStore.keyValues?.ids.splice(index, 1);
          delete this.keyValueStore.keyValues?.entities[this.selectedId!];
          this.selectedId = null;
        });
      }
    }
  }
}

import { MessageNodeProps } from '@/entities';
import { Node } from 'reactflow';

import { DisplayMessageService } from '../../entities/Connect/services/DisplayMessageService';
import {AllConnectService} from "@/entities/Connect/services/AllConnectService";

export function getNodesForMessageTracing(
  displayMessageService: DisplayMessageService,
  allConnectService: AllConnectService
): Node<MessageNodeProps>[] {
  return (
    displayMessageService.exchangeList?.ids.map((id, index) => {
      if (displayMessageService.exchangeList!.entities[id].type === "start") {
        let type = displayMessageService.exchangeList?.entities[id].inputConnect?.typeConnect || ''
        let typeLocale = allConnectService.parameterInputByName(type)?.localeName
        return {
          id,
          data: {
            id,
            processorName: `Входящее сообщение ${displayMessageService.exchangeList?.entities[id].inputConnect?.name || ''}`,
            value: displayMessageService.exchangeList!.entities[id].inputBody?.outputBody || '',
            context: displayMessageService.exchangeList!.entities[id].context?.outputBody || '',
            headers: displayMessageService.exchangeList!.entities[id].inputHeaders,
            pathParams: displayMessageService.exchangeList!.entities[id].inputPathParams,
            queryParams: displayMessageService.exchangeList!.entities[id].inputQueryParams,
            processorType: type,
            processorTypeLocale: typeLocale,
            files: displayMessageService.exchangeList!.entities[id].files,
            exception: displayMessageService.exchangeList!.entities[id].exception,
            stackTrace: displayMessageService.exchangeList!.entities[id].stackTrace,
            messageId: '',
            dateExecute: displayMessageService.exchangeList!.entities[id].timeStart ? new Date(displayMessageService.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: displayMessageService.exchangeList!.entities[id].timeExecute,
            onDownloadBody: displayMessageService.exchangeList!.entities[id].inputBody?.bodyDataComponentId &&
             !displayMessageService.exchangeList!.entities[id].inputBody?.isSuccessDownload ? () => {
              displayMessageService.downloadBodyData(id, displayMessageService.exchangeList!.entities[id].inputBody?.bodyDataComponentId, "inputBody")
            } : undefined,
            valueSize: displayMessageService.exchangeList!.entities[id].inputBody?.size || 0,
          },
          position: {
            x: displayMessageService.canvas?.entities[id]?.left || 0,
            y: displayMessageService.canvas?.entities[id]?.top || 0,
          },
          style: {
            width: displayMessageService.canvas?.entities[id]?.sx?.width || 420,
            height: displayMessageService.canvas?.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      if (displayMessageService.exchangeList!.entities[id].type === "work") {
        let type = displayMessageService.exchangeList!.entities[id].processor?.typeProcessor || ''
        let typeLocale = allConnectService.parameterProcessorByName(type)?.localeName
        return {
          id,
          data: {
            id,
            processorName: displayMessageService.exchangeList?.entities[id].processor?.name || '',
            processorType: type,
            processorTypeLocale: typeLocale,
            value: displayMessageService.exchangeList!.entities[id].body?.outputBody || '',
            context: displayMessageService.exchangeList!.entities[id].context?.outputBody || '',
            headers: displayMessageService.exchangeList!.entities[id].headers,
            pathParams: displayMessageService.exchangeList!.entities[id].inputPathParams,
            queryParams: displayMessageService.exchangeList!.entities[id].inputQueryParams,
            exception: displayMessageService.exchangeList!.entities[id].exception,
            stackTrace: displayMessageService.exchangeList!.entities[id].stackTrace,
            messageId: displayMessageService.exchangeList?.entities[id]?.headers?.messageId || '',
            dateExecute: displayMessageService.exchangeList!.entities[id].timeStart ? new Date(displayMessageService.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: displayMessageService.exchangeList!.entities[id].timeExecute,
            onDownloadBody: displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId &&
            !displayMessageService.exchangeList!.entities[id].body?.isSuccessDownload ? () => {
              displayMessageService.downloadBodyData(id, displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId, "body")
            } : undefined,
            valueSize: displayMessageService.exchangeList!.entities[id].body?.size || 0,
          },
          position: {
            x: displayMessageService.canvas?.entities[id]?.left || 0,
            y: displayMessageService.canvas?.entities[id]?.top || 0,
          },
          style: {
            width: displayMessageService.canvas?.entities[id]?.sx?.width || 420,
            height: displayMessageService.canvas?.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      if (displayMessageService.exchangeList!.entities[id].type === "finish") {
        return {
          id,
          data: {
            id,
            processorName: 'Исходящее сообщение',
            processorType: '',
            value: displayMessageService.exchangeList!.entities[id].body?.outputBody || '',
            headers: displayMessageService.exchangeList!.entities[id].outputHeaders,
            pathParams: displayMessageService.exchangeList!.entities[id].inputPathParams,
            queryParams: displayMessageService.exchangeList!.entities[id].inputQueryParams,
            exception: displayMessageService.exchangeList!.entities[id].exception,
            stackTrace: displayMessageService.exchangeList!.entities[id].stackTrace,
            messageId: '',
            dateExecute: displayMessageService.exchangeList!.entities[id].timeStart ? new Date(displayMessageService.exchangeList!.entities[id].timeStart).toISOString() : undefined,
            timeExecute: displayMessageService.exchangeList!.entities[id].timeExecute,
            onDownloadBody: displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId &&
            !displayMessageService.exchangeList!.entities[id].body?.isSuccessDownload ? () => {
              displayMessageService.downloadBodyData(id, displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId, "body")
            } : undefined,
            valueSize: displayMessageService.exchangeList!.entities[id].body?.size || 0,
          },
          position: {
            x: displayMessageService.canvas?.entities[id]?.left || 0,
            y: displayMessageService.canvas?.entities[id]?.top || 0,
          },
          style: {
            width: displayMessageService.canvas?.entities[id]?.sx?.width || 420,
            height: displayMessageService.canvas?.entities[id]?.sx?.height || 420,
            display: 'flex',
          },
          type: 'messageNode',
        };
      }
      return {
        id,
        data: {
          id,
          processorName: displayMessageService.exchangeList?.entities[id].processor?.name || '',
          processorType:
            displayMessageService.exchangeList!.entities[id].processor?.typeProcessor || '',
          value: displayMessageService.exchangeList!.entities[id].body?.outputBody || '',
          headers: displayMessageService.exchangeList!.entities[id].headers,
          pathParams: displayMessageService.exchangeList!.entities[id].inputPathParams,
          queryParams: displayMessageService.exchangeList!.entities[id].inputQueryParams,
          exception: displayMessageService.exchangeList!.entities[id].exception,
          stackTrace: displayMessageService.exchangeList!.entities[id].stackTrace,
          messageId: displayMessageService.exchangeList?.entities[id]?.headers?.messageId || '',
          dateExecute: displayMessageService.exchangeList!.entities[id].timeStart ? new Date(displayMessageService.exchangeList!.entities[id].timeStart).toISOString() : undefined,
          timeExecute: displayMessageService.exchangeList!.entities[id].timeExecute,
          onDownloadBody: displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId &&
          !displayMessageService.exchangeList!.entities[id].body?.isSuccessDownload ? () => {
            displayMessageService.downloadBodyData(id, displayMessageService.exchangeList!.entities[id].body?.bodyDataComponentId, "body")
          } : undefined,
          valueSize: displayMessageService.exchangeList!.entities[id].body?.size || 0,
        },
        position: {
          x: displayMessageService.canvas?.entities[id].left || 0,
          y: displayMessageService.canvas?.entities[id].top || 0,
        },
        style: {
          width: displayMessageService.canvas?.entities[id]?.sx?.width || 420,
          height: displayMessageService.canvas?.entities[id]?.sx?.height || 420,
          display: 'flex',
        },
        type: 'messageNode',
      };
    }) || []
  );
}

import React, { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { User } from '@/entities/User';
import Grid from '@mui/material/Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import Box from '@mui/material/Box';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import Stack from '@mui/material/Stack';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { RoleStore } from '@/entities/Role';
import { notify } from '@/shared/ui/Toast/notify';

import { CreateUserService } from '../api/CreateUserService';

const createUserService = container.resolve(CreateUserService);
const roleStore = container.resolve(RoleStore);

export interface CreateUserFormDialogProps {}

export const CreateUserFormDialog: FC<CreateUserFormDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, control, handleSubmit, reset } = useForm<User>();

  const handleClose = useCallback(() => {
    createUserService.open = false;
    reset({ login: '', isAdmin: false, isIntegraInterface: false, roles: [] });
  }, [reset]);

  const handleValidSubmit = useCallback<SubmitHandler<User>>(
    async (user) => {
      try {
        setIsLoading(true);

        await createUserService.createUser(user);
        handleClose();

        notify.success('Пользователь добавлен');
      } catch {
        notify.error('Не удалось добавить пользователя');
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose]
  );

  return (
    <Dialog open={createUserService.open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Добавить пользователя</DialogTitle>
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <DialogContent>
          <Stack gap={2.5}>
            <Grid container spacing={2.5} alignItems='center'>
              <Grid item xs={12}>
                <FieldInput register={register} error='' name='login' label='Логин' />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  control={control}
                  name='roles'
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        multiple
                        label='Роли'
                        options={roleStore.roleOptions}
                        value={field.value}
                        filterSelectedOptions
                        onChange={(event, value) => {
                          field.onChange(value);
                        }}
                      />
                    );
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Box display='flex'>
                  <FieldSwitch
                    control={control}
                    error=''
                    name='isAdmin'
                    label='Права администратора'
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display='flex'>
                  <FieldSwitch
                    control={control}
                    error=''
                    name='isIntegraInterface'
                    label='Доступ к интерфейсу Интегры'
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button type='submit' variant={ButtonVariants.Secondary}>
            Отмена
          </Button>
          <Button type='submit' variant={ButtonVariants.Popup} loading={isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

CreateUserFormDialog.displayName = 'CreateUserFormDialog';

import { ForwardedRef, forwardRef, memo } from 'react';

import { SelectUnstyledRootSlotProps } from '@mui/base';
import { useEllipsisText } from '@/hooks/useEllipsisText';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import cn from 'classnames';
import { SelectSizes } from '@/shared/ui/CronSelect/types';

import styles from '@/shared/ui/CronSelect/Select.module.scss';

export const Button = memo(
  forwardRef(
    <TValue extends {}, Multiple extends boolean>(
      {
        ownerState,
        size,
        ...props
      }: // eslint-disable-next-line react/no-unused-prop-types
      SelectUnstyledRootSlotProps<TValue, Multiple> & { size: SelectSizes },
      ref: ForwardedRef<HTMLButtonElement>
    ) => {
      const { isEllipsisActive, ellipsisTextRef } = useEllipsisText<HTMLButtonElement>();

      return (
        <button
          {...props}
          className={cn(styles.root, { [styles.small]: size === SelectSizes.Small })}
          type='button'
          ref={ref}
          title={props.children as string}
        >
          <span
            ref={ellipsisTextRef}
            className={styles.text}
            title={isEllipsisActive ? (props.children as string) : undefined}
          >
            {props.children}
          </span>
          {ownerState.open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </button>
      );
    }
  )
);

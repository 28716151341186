import { Segment } from '../enums';
import { BaseValues } from './base.abstract';
export class HoursValues extends BaseValues {
    constructor() {
        super(...arguments);
        this.segment = Segment.hours;
    }
    clone(d = {}) {
        return new HoursValues(Object.assign(Object.assign({}, this), d));
    }
}

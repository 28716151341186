import { FC, useCallback } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';

import { DeleteVersionService } from '../api/DeleteVersionService';

export interface DeleteVersionDialogProps {
  versionId: string;
  versionName: string;

  onSuccess?(): void;
}

const deleteVersionService = container.resolve(DeleteVersionService);

export const DeleteVersionDialog: FC<DeleteVersionDialogProps> = observer(
  ({ versionId, versionName, onSuccess }) => {
    const handleClose = useCallback(() => {
      deleteVersionService.showDialog = false;
    }, []);

    const handleOkButtonClick = useCallback(() => {
      deleteVersionService.deleteVersion(versionId).then(() => {
        onSuccess?.();
        handleClose();
      });
    }, [handleClose, onSuccess, versionId]);

    return (
      <Dialog fullWidth open={deleteVersionService.showDialog} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удаление версии потока</DialogTitle>
        <DialogContent>Вы точно хотите удалить версию потока {versionName}?</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button
            variant={ButtonVariants.Popup}
            loading={deleteVersionService.isLoading}
            onClick={handleOkButtonClick}
          >
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeleteVersionDialog.displayName = 'DeleteVersionDialog';

import { FC, SVGProps } from 'react';

export const PlayIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.99984 0.652344C3.58864 0.652344 0 4.24123 0 8.65234C0 13.0635 3.58864 16.6523 7.99984 16.6523C12.411 16.6523 16 13.0638 16 8.65234C16 4.24092 12.411 0.652344 7.99984 0.652344ZM12.2091 8.65234L5.6798 12.4228V4.88219L12.2091 8.65234Z'
        fill='currentColor'
      />
    </svg>
  );
};

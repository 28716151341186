import { ChangeEventHandler, memo, MouseEventHandler, useCallback, useState } from 'react';

import { Control, Controller, Path } from 'react-hook-form';
import { Dialog, DialogActions, DialogContent, DialogTitle, Input } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';

export interface FieldPasswordWithConfirmationProps<T extends {}> {
  name: Path<T>;
  control: Control<T>;
  label?: string;
}

const FieldPasswordWithConfirmationBase = <T extends {}>({
  name,
  control,
  label,
}: FieldPasswordWithConfirmationProps<T>): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [isShowText, setIsShowText] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');

  const handleButtonIconClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.stopPropagation();
    setIsShowText((prevState) => !prevState);
  }, []);

  const handleFocus = () => setOpen(true);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setValue(event.target.value);
  }, []);

  const handleOkButtonClick = useCallback(
    (onChange: (value: string) => void) => () => {
      onChange(value);
      handleClose();
      setIsShowText(false);
    },
    [handleClose, value]
  );

  const renderInput = (value: string, readonly?: boolean) => {
    return (
      <Input
        key={value}
        type={isShowText ? 'text' : 'password'}
        endAdornment={
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleButtonIconClick}
          >
            {isShowText ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </Button>
        }
        label={label}
        defaultValue={value}
        InputProps={{ readOnly: readonly }}
        onClick={handleFocus}
        onChange={handleInputChange}
      />
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        return (
          <>
            {renderInput(field.value, true)}
            <Dialog fullWidth open={open} onClose={handleClose}>
              <DialogTitle onClose={handleClose}>Изменить пароль</DialogTitle>
              <DialogContent>
                <Box marginY='2px'>{renderInput(field.value)}</Box>
              </DialogContent>
              <DialogActions>
                <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
                  Отменить
                </Button>
                <Button
                  variant={ButtonVariants.Popup}
                  onClick={handleOkButtonClick(field.onChange)}
                >
                  Изменить
                </Button>
              </DialogActions>
            </Dialog>
          </>
        );
      }}
    />
  );
};

export const FieldPasswordWithConfirmation = memo(
  FieldPasswordWithConfirmationBase
) as unknown as typeof FieldPasswordWithConfirmationBase;

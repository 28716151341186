import React from 'react';

import { FloatButton, Tooltip } from 'antd';
import { panelTitles } from '@/entities/Dashboard/widgets/DashboardPlatform/constants/titles';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';

import styles from './DashboardContent.module.scss';

export function DashboardCreateBtn ({
  handler = () => {},
}) {

  return (
    <Tooltip
      overlayClassName={styles.description}
      title={`Добавить ${panelTitles.base}`}
      open
      color='transparent'
    >
      <FloatButton
        className={styles['create-btn']}
        type='primary'
        icon={<PlusIcon width={28} height={28} strokeWidth={1} />}
        onClick={handler}
      />
    </Tooltip>
  );
}
import { SearchParams, SearchParamsTemplate } from '@/hooks/useTemplateSearchParams/types';

export const getBlockFormSearchParamsTemplate = (blockId: string): SearchParamsTemplate => {
  return [SearchParams.ShowBlockForm, { [SearchParams.BlockId]: blockId }];
};

export const getMessageTracingSearchParamsTemplate = (
  blockId: string,
  isError: boolean
): SearchParamsTemplate => {
  return [
    SearchParams.ShowMessageTracing,
    {
      [SearchParams.BlockId]: blockId,
      [SearchParams.IsError]: isError.toString(),
    },
  ];
};

export const getConnectorFormSearchParamsTemplate = ({
  connectorType,
  connectorId,
  connectorName,
  blockId,
}: {
  blockId: string;
  connectorId: string;
  connectorName: string;
  connectorType: string;
}): SearchParamsTemplate => {
  return [
    SearchParams.ShowConnectorForm,
    {
      [SearchParams.ConnectorName]: connectorName,
      [SearchParams.ConnectorType]: connectorType,
      [SearchParams.ConnectorId]: connectorId,
      [SearchParams.BlockId]: blockId,
    },
  ];
};

export const CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ConnectorType,
  SearchParams.ConnectorId,
  SearchParams.ConnectorName,
  SearchParams.ShowConnectorForm,
];

export const BLOCK_FORM_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowBlockForm,
  SearchParams.BlockId,
];

export const MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowMessageTracing,
  SearchParams.IsError,
  SearchParams.MessageId,
];

export const EXECUTOR_LOG_BLOCK_SEARCH_PARAMS_TEMPLATE: SearchParamsTemplate = [
  SearchParams.ShowExecutorLogBlock,
  SearchParams.BlockId,
];

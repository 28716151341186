import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

import { SettingsUserAuth } from '../model/types';
import { SettingsUserAuthStore } from '../model/SettingsUserAuthStore';

@singleton()
export class SettingsUserAuthService {
  constructor(
    private apiService: ApiService,
    private settingsUserAuthStore: SettingsUserAuthStore
  ) {
    makeAutoObservable(this);
  }

  /**
   * Обрабатывает данные из запроса и склавдывает их в структуру стора
   * @param settingsUserAuth данные из запроса
   */
  putSettingsUserAuthToStore(settingsUserAuth: SettingsUserAuth) {
    this.settingsUserAuthStore.settingsBasic = normalize(settingsUserAuth.settingsBasicList, 'id');
    this.settingsUserAuthStore.settingsIdm = normalize(settingsUserAuth.settingsIDMList, 'id');

    this.settingsUserAuthStore.settingsUserAuth = settingsUserAuth;
  }

  async getSettingsUserAuth() {
    const response = await this.apiService.instance.get<SettingsUserAuth>(
      '/editor/settingsUserAuth/get'
    );

    this.putSettingsUserAuthToStore(response.data);

    return response.data;
  }
}

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import _ from 'lodash';
import { RoleStore } from '@/entities/Role';

import { DeleteRoleService } from '../api/DeleteRoleService';

const deleteRoleService = container.resolve(DeleteRoleService);
const roleStore = container.resolve(RoleStore);

export interface DeleteRoleDialogProps {
  onSuccess?(): void;
}

export const DeleteRoleDialog: FC<DeleteRoleDialogProps> = observer(({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState(false);

  const userName = _.get(
    roleStore.roles?.entities[deleteRoleService.selectedRoleId || ''],
    'name',
    ''
  );

  const handleClose = useCallback(() => {
    deleteRoleService.open = false;
  }, []);

  const handleOkButtonClick = useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteRoleService.deleteRole();

      notify.success('Роль удалена');
      handleClose();
      onSuccess?.();
    } catch {
      notify.error('Не удалось удалить роль');
    } finally {
      setIsLoading(false);
    }
  }, [handleClose, onSuccess]);

  return (
    <Dialog fullWidth open={deleteRoleService.open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Удаление роли</DialogTitle>
      <DialogContent>Вы точно хотите удалить роль {userName}?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
          Отмена
        </Button>
        <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
          Удалить
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DeleteRoleDialog.displayName = 'DeleteRoleDialog';

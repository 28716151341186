import { ChangeEventHandler, FC, memo, useCallback, useEffect, useState } from 'react';

import MuiPagination, { PaginationProps as MuiPaginationProps } from '@mui/lab/Pagination';
import Stack from '@mui/system/Stack';
import { Input } from '@/shared/ui';
import Box from '@mui/system/Box';

export interface PaginationProps extends MuiPaginationProps {}

export const Pagination: FC<PaginationProps> = memo(
  ({ defaultPage, count, onChange, ...props }) => {
    const [pageState, setPageState] = useState<number | undefined>(defaultPage);

    const handleInputChange = useCallback<
      ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    >(
      (event) => {
        const value = event.target.value;

        if (value === '') {
          // @ts-ignore
          setPageState('');
        }

        if (/^-?\d+$/.test(value)) {
          // @ts-ignore
          if (Number(value) >= 1 && Number(value) <= count) {
            setPageState(Number(event.target.value));
          }
        }
      },
      [count]
    );

    const handlePaginationChange = useCallback((event: unknown, page: number) => {
      setPageState(page);
    }, []);

    useEffect(() => {
      // @ts-ignore
      onChange?.(undefined, pageState === 0 ? 0 : pageState - 1);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageState]);

    return (
      <Stack direction='row'>
        <MuiPagination
          {...props}
          page={pageState}
          count={count}
          onChange={handlePaginationChange}
        />
        <Box width={100}>
          <Input size='small' value={pageState} onChange={handleInputChange} />
        </Box>
      </Stack>
    );
  }
);

Pagination.displayName = 'Pagination';

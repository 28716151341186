import { Segment } from '../enums';
import { SecondsValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class SecondsContainer extends BaseContainer {
    getSegment() {
        return Segment.seconds;
    }
    cloneWith(d) {
        return new SecondsContainer(d);
    }
    createValues(values) {
        return new SecondsValues(values);
    }
}

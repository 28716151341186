import React, { useLayoutEffect } from 'react';

import { AuthProvider } from 'react-oidc-context';

import useLocalStorageRedux from '../../../hooks/useLocalStorageRedux';
import useFetch from '../../../hooks/useFetch';
import useRedux from '../../../hooks/useRedux';
import { MemorizedKeyCloakAuth } from './KeyCloakAuth';
import BasicAuth from './BasicAuth';
import {container} from "tsyringe";
import {AuthService} from "@/shared/ui/AuthVerifier/services/AuthService";

const authService = container.resolve(AuthService);

const AuthVerifier = (props) => {
  const { children } = props;
  const [context, setContext] = useLocalStorageRedux('context');
  const [typeAuth, setTypeAuth] = useLocalStorageRedux('typeAuth');
  const [typeAuthJSON, setTypeAuthJSON] = useLocalStorageRedux('typeAuthJSON');
  const [typeAuthLogin, setTypeAuthLogin] = useLocalStorageRedux('typeAuthLogin', 'Basic');
  const [authUserByCompany, setAuthUserByCompany] = useRedux('authUserByCompany');

  const fetchHook = useFetch();

  useLayoutEffect(() => {
    getContext();
    authService.sendIsCheck()
  }, []);

  useLayoutEffect(() => {
    if (typeAuth !== undefined && typeAuth !== 'undefined') {
      let authInfo = JSON.parse(typeAuth);
      if (authInfo) {
        setTypeAuthLogin(authInfo.type);
      }
      setTypeAuthJSON(authInfo);
    }
  }, [typeAuth]);

  const getContext = async () => {
    let response = await fetchHook({
      url: `editor/application/information`,
    });
    if (response) {
      setContext(response);
    }
  };

  useLayoutEffect(() => {
    if (authUserByCompany) {
      if (authUserByCompany.authUserOptionList && authUserByCompany.authUserOptionList.length > 0) {
        if (!typeAuth || typeAuth === 'undefined') {
          setTypeAuth(JSON.stringify(authUserByCompany.authUserOptionList[0]));
        }
      }
    }
  }, [authUserByCompany]);

  return (
    <>
      {typeAuthLogin === 'Bearer' && typeAuthJSON ? (
        <MemorizedKeyCloakAuth
          url={typeAuthJSON.url}
          clientId={typeAuthJSON.clientId}
          clientSecret={typeAuthJSON.clientSecret}
          issuer={typeAuthJSON.issuer}
        >
          {children}
        </MemorizedKeyCloakAuth>
      ) : typeAuthLogin === 'Basic' ? (
        <AuthProvider>
          <BasicAuth>{children}</BasicAuth>
        </AuthProvider>
      ) : null}
    </>
  );
};

AuthVerifier.propTypes = {};

export default AuthVerifier;

import { FC, SVGProps } from 'react';

export const BellIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g clipPath='url(#clip0_229_37726)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M12.521 7.52129V5.5649C12.521 3.04378 10.4772 1 7.9561 1C5.43498 1 3.3912 3.04378 3.3912 5.5649V7.52129C3.3912 9.67331 1.43481 10.195 1.43481 11.4341C1.43481 12.5427 3.97812 13.3904 7.9561 13.3904C11.9341 13.3904 14.4774 12.5427 14.4774 11.4341C14.4774 10.195 12.521 9.67331 12.521 7.52129Z'
          fill='currentColor'
          stroke='currentColor'
          strokeWidth='1.30426'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.95631 14.6947C7.29701 14.6947 6.67749 14.6726 6.09448 14.6295C6.34957 15.4432 7.10355 15.997 7.95631 15.997C8.80907 15.997 9.56305 15.4432 9.81814 14.6295C9.23513 14.6726 8.61561 14.6947 7.95631 14.6947Z'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_229_37726'>
          <rect width='16' height='16' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

import { FC } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import {
    BLOCK_FORM_SEARCH_PARAMS_TEMPLATE,
    SearchParams,
    useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import { observer } from 'mobx-react-lite';
import { Resizable } from '@/shared/ui';
import {DiffFlowContainer} from "@/entities/Flow/containers/DiffFlow/DiffFlowContainer";

export const DiffFlowDrawerContainer: FC = observer(() => {
    const { has, remove, get } = useSearchParamsTemplate();

    const handleDrawerClose = (): void => {
        remove([SearchParams.ShowDiffFlow]);
    };
    if (!has(SearchParams.ShowDiffFlow)) return null;

    return (
        <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
            <Drawer title='Список изменений потока' onClose={handleDrawerClose}>
                <DiffFlowContainer/>
            </Drawer>
        </Resizable>
    );
});

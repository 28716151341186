import {singleton} from "tsyringe";
import {makeAutoObservable} from "mobx";

@singleton()
export class CloseOnboardingStore {
    run: boolean;
    isClose: boolean;

    constructor() {
        this.run = false;
        this.isClose = false;
        makeAutoObservable(this);
    }
}
import { FC } from 'react';

import { ConnectionLineComponentProps } from 'reactflow';

export const ConnectionLine: FC<ConnectionLineComponentProps> = ({ fromX, fromY, toX, toY }) => {
  // path from KeyboardArrowDown '@mui/icons-material'
  return (
    <g>
      <path
        fill='none'
        stroke='#fff'
        strokeWidth={1.5}
        className='animated'
        d={`M${fromX},${fromY} C ${fromX} ${toY} ${fromX} ${toY} ${toX},${toY}`}
      />
      <circle
        cx={toX - 2}
        cy={toY}
        fill='var(--edge-color)'
        r={10}
        stroke='var(--tag-hover-color)'
        strokeWidth={2}
      />
      <path
        fill='#6c5dd3'
        stroke='#6c5dd3'
        d={`m ${toX - 7} ${toY - 3} l 4.59 4.58 l 4.59 -4.58 l 1.41 1.41 l -6 6 l -6 -6 l 1.41 -1.41 z`}
      />
    </g>
  );
};
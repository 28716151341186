import { FC, memo } from 'react';

import cn from 'classnames';

import styles from './Tab.module.scss';

export interface TapProps {
  children: JSX.Element | string;
  isActive?: boolean;
  onClick?: () => void;
  className?: any;
}

/**
 * Компонент для отображения табов
 */
export const Tab: FC<TapProps> = memo(({ children, isActive, onClick, className, }) => {
  return (
    <li role='tab' className={cn(styles.root, { [styles.active]: isActive }, className)} onClick={onClick}>
      <span className={styles.title}>{children}</span>
      <div className={styles.line} />
    </li>
  );
});

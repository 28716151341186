import { FC, useCallback } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';

import { CloneVersionService } from '../api/CloneVersionService';

export interface CloneVersionDialogProps {
  flowId: string;
  flowName: string;
}

const cloneVersionService = container.resolve(CloneVersionService);

export const CloneVersionDialog: FC<CloneVersionDialogProps> = observer(
  ({ flowId: flowId, flowName: flowName }) => {
    const handleClose = useCallback(() => {
      cloneVersionService.showDialog = false;
    }, []);

    const handleOkButtonClick = useCallback(() => {
      cloneVersionService.cloneVersion(flowId).then(handleClose);
    }, [handleClose, flowId]);

    return (
      <Dialog fullWidth open={cloneVersionService.showDialog} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Клонирование потока</DialogTitle>
        <DialogContent>Вы точно хотите клонировать версию потока {flowName}?</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button
            variant={ButtonVariants.Popup}
            loading={cloneVersionService.isLoading}
            onClick={handleOkButtonClick}
          >
            Клонировать
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

CloneVersionDialog.displayName = 'CloneVersionDialog';

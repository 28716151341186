import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';

import { Dashboard } from '../types';

@singleton()
export class DashboardStore {
  dashboards: Normalized<Dashboard>;
  isLoadingDashboards: boolean;
  isLoadingDashboard: boolean;
  isLoadingUpdateDashboard: boolean;
  isLoadingDeleteDashboard: boolean;
  selectedDashboardId: string | null;
  selectedDashboard: Dashboard | null;

  constructor() {
    this.dashboards = { ids: [], entities: {} };
    this.isLoadingDashboards = false;
    this.isLoadingDashboard = false;
    this.isLoadingUpdateDashboard = false;
    this.isLoadingDeleteDashboard = false;
    this.selectedDashboardId = null;
    this.selectedDashboard = null;

    makeAutoObservable(this);
  }

}

import React from 'react';

// @ts-ignore
import { Responsive, WidthProvider } from 'react-grid-layout';


import { container } from 'tsyringe';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { GridItemContainer } from '@/entities/Dashboard/widgets/DashboardPlatform/containers/GridItemContainer';
import { Layout, LayoutDashboard } from '@/entities/Dashboard/types';

const ResponsiveGridLayout = WidthProvider(Responsive);

const dashboardService = container.resolve(DashboardService);

export interface GridLayoutProps {
  layouts: LayoutDashboard
}

export function GridLayout(props: GridLayoutProps) {
  const {
    layouts = {
      lg: [],
    },
  } = props;

  const handleLayoutChange = (newLayout: Layout[], layouts: LayoutDashboard) => {
    dashboardService.setLayoutsDashboard(layouts)
  };

  return (
    <ResponsiveGridLayout
      className="layout"
      layouts={layouts}
      onLayoutChange={handleLayoutChange}
      isDraggable
      isResizable
      breakpoints={{ lg: 1280, md: 992, sm: 767, xs: 480, xxs: 0 }}
      cols={{ lg: 24, md: 20, sm: 12, xs: 8, xxs: 4 }}
      compactType="vertical"
      draggableHandle=".grid-item__draggable_handle"
    >
      {layouts && layouts.lg && layouts.lg.map((item) => (
        <div key={item.i}>
          <GridItemContainer i={item.i} />
        </div>
      ))}
    </ResponsiveGridLayout>
  );
}

import React, { useState } from "react";
import { Input, List } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { container } from "tsyringe";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";

import { quickOptions } from "./constants";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { parse } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/datemath";

const periodService = container.resolve(PeriodService);

export const PredefinedPeriods = ({
  closePopup = () => {},
}) => {
  const { set } = useSearchParamsTemplate();
  const [search, setSearch] = useState('');
  const { period } = periodService;
  const [start, end] = period || [];

  const handleSelect = ({ target }) => {
    const { from, to, display } = target?.dataset || {};
    if (!display) return;
    periodService.period = [parse(from), parse(to, true)];
    set({ from, to });
    // closePopup();
  };

  const handleSearch = ({ target: { value } }) => {
    setSearch(value);
  }

  const filteredOptions = quickOptions.filter(({ display }) => display.toLowerCase().indexOf(search.toLowerCase()) > -1);

  return (
    <div className="periods">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="быстрый поиск"
        onChange={handleSearch}
      />
      <List
        className="period-list"
        size="small"
        split={false}
        dataSource={filteredOptions}
        itemLayout="vertical"
        renderItem={({ from, to, display}) => (
          <List.Item
            data-from={from}
            data-to={to}
            data-display={display}
            className={start?.raw === from && end?.raw === to ? 'active' : ''}
          >
            {display}
          </List.Item>
        )}
        onClick={handleSelect}
      />
    </div>
  );
}

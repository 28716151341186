import { FC, SVGProps } from 'react';

export const PaperPlaneIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M16 8.00003L0 0L2.4616 7.33331L12.4307 8L2.4616 8.66669L0 16L16 8.00003Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { ExternalService } from './types';

@singleton()
export class ExternalAppStore {
  externalApps: Normalized<ExternalService> | null;

  constructor() {
    this.externalApps = null;

    makeAutoObservable(this);
  }
}

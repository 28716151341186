import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Account } from './types';

@singleton()
export class AccountStore {
  accounts: Normalized<Account> | null;

  constructor() {
    this.accounts = null;

    makeAutoObservable(this);
  }

  get accountList() {
    if (this.accounts?.entities) {
      return Object.values(this.accounts.entities);
    }

    return [];
  }
}

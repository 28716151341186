import { forwardRef, memo, useMemo } from 'react';

import { Stack } from '@mui/system';
import cn from 'classnames';
import { InputUnstyled, InputUnstyledProps } from '@mui/base';

import styles from './Input.module.scss';

export interface InputProps extends Omit<InputUnstyledProps, 'error'> {
  // eslint-disable-next-line react/no-unused-prop-types
  error?: string;
}

export const Input = memo(
  forwardRef<HTMLInputElement, InputProps>(
    ({ endAdornment, startAdornment, error, className, ...props }, ref) => {
      const slotProps = useMemo(() => {
        return {
          root: {
            className: cn(styles.root, {
              [styles.error]: error,
            }),
          },
          input: {
            className: cn(styles.input, { [styles.endAdornment]: endAdornment }, className),
            ref,
          },
        };
      }, [className, endAdornment, error, ref]);

      const memoizedEndAdornment = useMemo(
        () => endAdornment && <span className={styles.adornment}>{endAdornment}</span>,
        [endAdornment]
      );

      const memoizedStartAdornment = useMemo(
        () => startAdornment && <span className={styles.adornment}>{startAdornment}</span>,
        [startAdornment]
      );

      return (
        <Stack spacing={1} flex={1}>
          {/*// @ts-ignore*/}
          <InputUnstyled
            {...props}
            endAdornment={memoizedEndAdornment}
            startAdornment={memoizedStartAdornment}
            slotProps={slotProps}
          />
        </Stack>
      );
    }
  )
);

import React, { FC, SVGProps } from 'react';

export const ExclamationIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 1.66667V6.66667'
        stroke='currentColor'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5 8.33333C5.46053 8.33333 5.83333 7.96053 5.83333 7.5C5.83333 7.03947 5.46053 6.66667 5 6.66667C4.53947 6.66667 4.16667 7.03947 4.16667 7.5C4.16667 7.96053 4.53947 8.33333 5 8.33333Z'
        fill='currentColor'
      />
    </svg>
  );
};
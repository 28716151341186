import React, { memo } from 'react';

import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuiAutocompleteProps,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isArray, isString } from 'lodash';
import { Input } from '@/shared/ui';

import styles from './Autocomplete.module.scss';

export interface AutocompleteProps<
  TValue,
  Multiple extends boolean,
  DisableClearable extends boolean,
  FreeSolo extends boolean
> extends Omit<MuiAutocompleteProps<TValue, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  label?: string;
  placeholder?: string;
  width?: number;
  onBlur?: (event: React.FocusEvent<HTMLDivElement>) => void;
}

const classes = { listbox: styles.listbox };

const AutocompleteBase = <
  TValue,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
  FreeSolo extends boolean = false
>({
  label,
  placeholder,
  width,
  onBlur,
  renderOption = (props: any) => {
    return (
      <li {...props} className={styles.option}>
        {props.key}
      </li>
    )},
  ...restProps
}: AutocompleteProps<TValue, Multiple, DisableClearable, FreeSolo>): JSX.Element => {
  return (
    <MuiAutocomplete
      noOptionsText='Нет опций'
      {...restProps}
      sx={{ width }}
      className={styles.autocomplete}
      popupIcon={<ExpandMoreIcon className={styles.icon} />}
      classes={classes}
      onBlur={onBlur}
      renderInput={(params) => {
        const hidePlaceholder =
          isString(restProps.value) || isArray(restProps.value) ? restProps.value.length : true;

        return <Input {...params} label={label} placeholder={hidePlaceholder ? '' : placeholder} />;
      }}
      renderOption={renderOption}
    />
  );
};

export const Autocomplete = memo(AutocompleteBase) as typeof AutocompleteBase;

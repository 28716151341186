import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { Settings } from './types';

@singleton()
export class SettingsStore {
  settings: Settings | null;

  constructor() {
    this.settings = null;

    makeAutoObservable(this);
  }
}

import { FC, SVGProps } from 'react';

export const CrossIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='10'
      height='10'
      viewBox='0 0 10 10'
      fill='currentColor'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.33268 1.66675L1.66602 8.33341'
        stroke='currentColor'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.33268 8.33341L1.66602 1.66675'
        stroke='currentColor'
        strokeWidth='1.66667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

import { FC } from 'react';

import Stack from '@mui/material/Stack';
import { container } from 'tsyringe';
import { ExternalAppCard, ExternalAppStore } from '@/entities/ExternalApp';
import { observer } from 'mobx-react-lite';
import {
  CreateExternalAppCard,
  UpdateExternalAppButton,
} from '@/features/UpsertExternalApp';
import {
  DeleteExternalAppButton,
  DeleteExternalAppDialog,
} from '@/features/DeleteExternalApp';

const externalAppStore = container.resolve(ExternalAppStore);

export interface ExternalAppListProps {}

export const ExternalAppList: FC<ExternalAppListProps> = observer(() => {
  return (
    <Stack gap={2.5} direction='row' flexWrap='wrap'>
      {externalAppStore.externalApps?.ids.map((id) => {
        const externalApp = externalAppStore.externalApps!.entities[id];

        return (
          <ExternalAppCard
            key={id}
            {...externalApp}
            actions={
              <Stack direction='row' justifyContent='space-between'>
                <UpdateExternalAppButton externalAppId={id} />
                <DeleteExternalAppButton externalAppId={id} />
              </Stack>
            }
          />
        );
      })}
      <CreateExternalAppCard />
      <DeleteExternalAppDialog />
    </Stack>
  );
});

ExternalAppList.displayName = 'ExternalAppList';

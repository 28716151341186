import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { OnCopyProps, CollapsedFieldProps } from 'react-json-view';
import _ from 'lodash';
import { Stack } from '@mui/system';
import Box from '@mui/system/Box';
import { MapObject } from '@/shared/ui/Fields/components/MappingData/MapObject';
import { Grid } from '@/shared/ui/Grid/Grid';
import { JsonTree } from '@/shared/ui';
import { JsonSettingsButton } from '@/shared/ui/Fields/components/MappingData/ui/JsonSettingsButton/JsonSettingsButton';
import { JsonSettingsForm } from '@/shared/ui/Fields/components/MappingData/ui/JsonSettingsForm';
import { amendDraggable } from "@/shared/lib/extendDndkit";

import { parseMapData } from './lib/parseMapData';
import { CopyPasteContext } from './lib/CopyPasteContext';

import styles from './MappingData.module.scss';

export interface MappingDataProps {
  control: any;
  register: any;
  name: string;
  draggable?: boolean;
}

export const MappingData: FC<MappingDataProps> = memo(({ control, register, name, draggable = false }) => {
  const [copiedValue, setCopiedValue] = useState<unknown>(undefined);
  const [jsonFormVisible, setJsonFormVisible] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => { // to save smooth draw animation
    const timer = setTimeout(() => {
      setIsExpanded(true);
    }, 150);
    return () => clearTimeout(timer);
  }, []);

  const handleCopy = useCallback(
    (field: ControllerRenderProps<FieldValues, 'paramsObject.inputData'>) =>
      (copy: OnCopyProps) => {
        const pathList = _.drop(copy.namespace);
        const pathForGet = pathList.join('.');
        const parent = _.get(field.value, _.dropRight(pathList) as string[]);
        const key = _.last(pathList);
        const value = _.get(field.value, pathForGet);
        const path = _.dropRight(pathList).join('.');

        const copiedValue: any = parseMapData({ [key as string]: value }, path)[0];

        if (typeof value === 'object' && !Array.isArray(value)) {
          copiedValue.children = parseMapData(value, pathForGet);
        }

        if (Array.isArray(parent)) {
          copiedValue.targetName = `element${key}`;
        }

        setCopiedValue(copiedValue);
      },
    []
  );

  const copyPasteContextValue = useMemo(() => {
    return { copiedValue, setCopiedValue };
  }, [copiedValue]);

  const handleJsonSettingsButtonClick = useCallback(() => {
    setJsonFormVisible(true);
  }, []);

  const shouldCollapse = useCallback((field: CollapsedFieldProps) => {
    if (!isExpanded) return true;
    const { name, type } = field;
    return Number.parseInt(name) > 0 && type === 'object';
  }, [isExpanded]);

  return (
    <div className={styles.root} {...amendDraggable(!draggable)}>
      <Grid container alignItems='baseline'>
        <Grid xs={3}>
          <Controller
            control={control}
            name='paramsObject.inputData'
            render={({ field }) => {
              return (
                <Stack gap={2.5}>
                  <JsonSettingsButton onClick={handleJsonSettingsButtonClick} />
                  <Box overflow='auto' position='relative'>
                    <JsonTree
                      src={field.value}
                      enableClipboard={handleCopy(field)}
                      shouldCollapse={shouldCollapse}
                    />
                  </Box>
                </Stack>
              );
            }}
          />
        </Grid>
        <Grid xs={9}>
          <CopyPasteContext.Provider value={copyPasteContextValue}>
            <MapObject
              name={name}
              control={control}
              register={register}
              draggable={draggable}
            />
          </CopyPasteContext.Provider>
        </Grid>
      </Grid>
      <Controller
        control={control}
        name='paramsObject.inputData'
        render={({ field }) => {
          return (
            <JsonSettingsForm
              defaultValue={JSON.stringify(field.value)}
              visible={jsonFormVisible}
              onOk={(value) => {
                field.onChange(value);
                setJsonFormVisible(false);
              }}
              onCancel={() => setJsonFormVisible(false)}
            />
          );
        }}
      />
    </div>
  );
});

MappingData.displayName = 'MappingData';

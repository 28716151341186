import React, {FC, memo, useLayoutEffect, useMemo} from 'react';

import { useGetRoles } from '@/hooks/role/useGetRoles';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';
import {container} from "tsyringe";
import {BlockMiniService} from "@/entities/Block/services/BlockMiniService";
import {observer} from "mobx-react-lite";

/**
 * Компонент для генерации поля с типом BlockSelect
 */

const blockMiniService = container.resolve(BlockMiniService);

export const BlockSelectContainer: FC<FieldProps> = observer((props) => {
  const miniBlocks = blockMiniService.miniBlocks

  useLayoutEffect(() => {
    if (miniBlocks.ids.length === 0) {
      blockMiniService.getAllMiniBlocks()
    }
  }, []);

  return (
    <FieldAutocomplete
      {...props}
      options={miniBlocks.ids}
      multiple
      renderOption={(props: any, id) => {
        return (
          <li {...props} key={id} value={id} >
            {`${miniBlocks.entities[id]?.flowName} - ${miniBlocks.entities[id]?.name}`}
          </li>
        );
      }}
      getOptionLabel={(id) => `${miniBlocks.entities[id]?.flowName} - ${miniBlocks.entities[id]?.name}`}
    />
  );
});

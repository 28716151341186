import React, { FC, useState } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import cn from 'classnames';
import { SortDownIcon } from '@/shared/ui/Icons/SortDownIcon/SortDownIcon';

import styles from '@/shared/ui/ButtonWithModal/ButtonWithModal.module.scss';

const pythonLibsStore = container.resolve(PythonLibsStore);

export const PythonLibsSortButton: FC = observer(() => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsOpen((prevState) => !prevState);
    pythonLibsStore.isSort = !isOpen;
  };

  return (
    <span>
      <button
        className={cn(styles.button, { [styles.active]: isOpen })}
        type='button'
        onClick={handleClick}
      >
        <Stack flexDirection='row' gap={1} alignItems='center'>
          <SortDownIcon transform='scale(1.5)' width={12} height={12} />
          <p>{'Сортировать'}</p>
        </Stack>
      </button>
    </span>
  );
});

PythonLibsSortButton.displayName = 'PythonLibsSortButton';

import { isEmpty } from 'lodash';
import dayjs from 'dayjs';

export const modeUserLib: string = 'user';
export const modeAllLib: string = 'all';

export const options = [
  { key: 'broken%2Cnot%20ready%2Crefreshing', label: 'Готов' },
  { key: 'ready%2CCbroken%2Crefreshing', label: 'Не готов' },
  { key: 'not%20ready%2CCrefreshing%2Cready', label: 'Сломан' },
  { key: 'not%20ready%2Cbroken%2Cready', label: 'Обновляется' },
];

export const date = (date: string) =>
  !isEmpty(date) && dayjs(date).format('DD.MM.YYYY | HH:mm:ss.SSS ');

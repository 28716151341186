import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DeleteUserService } from '../api/DeleteUserService';

const deleteUserService = container.resolve(DeleteUserService);

export interface DeleteUserButtonProps {
  userId: string;
}

export const DeleteUserButton: FC<DeleteUserButtonProps> = observer(({ userId }) => {
  const handleClick = useCallback(async () => {
    deleteUserService.open = true;
    deleteUserService.selectedUserId = userId;
  }, [userId]);

  return (
    <Tooltip title='Удалить пользователя' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteUserButton.displayName = 'DeleteUserButton';

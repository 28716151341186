import React from 'react';

import { NavLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { ThemeContext } from '@/context/ThemeContext';
import { activeMenuStyle } from '@/shared/lib/themeHelper';
import { bySize } from '@/shared/lib/sizeHelper';

const MenuList = (props) => {
  const { elements, size = 'medium', sx = {} } = props;

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
            borderBottom: '1px solid var(--line-color)',
            background: 'var(--paper-color)',
          }}
        >
          {elements &&
            elements.map((element) => {
              return (
                <>
                  <NavLink key={element.link} to={element.link} className='pointer'>
                    {({ isActive }) => (
                      <Box
                        sx={{
                          padding: '0.5rem',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          height: '100%',
                          fontFamily: 'Inter',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          fontSize: '16px',
                          lineHeight: '20px',
                          color: '#808191',
                          textAlign:'center',
                          ...sx,
                          ...activeMenuStyle({ theme: theme, isActive: isActive }),
                        }}
                      >
                        {element.label && bySize(size, 'medium') ? element.label : null}
                      </Box>
                    )}
                  </NavLink>
                  <Divider />
                </>
              );
            })}
        </Box>
      )}
    </ThemeContext.Consumer>
  );
};

export default MenuList;

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { notify } from '@/shared/ui/Toast/notify';

import { DeletePropertyService } from '../api/DeletePropertyService';

const deletePropertyService = container.resolve(DeletePropertyService);

export interface DeletePropertyDialogProps {
  onSuccess?(): void;
}

export const DeletePropertyDialog: FC<DeletePropertyDialogProps> = observer(({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    deletePropertyService.open = false;
    deletePropertyService.selectedId = null;
  }, []);

  const handleOk = useCallback(async () => {
    try {
      setIsLoading(true);

      await deletePropertyService.deleteProperty();
      handleClose();
      onSuccess?.();
    } catch {
      notify.error('Не удалось удалить конфигурацию');
    } finally {
      setIsLoading(false);
    }
  }, [handleClose, onSuccess]);

  return (
    <Dialog open={deletePropertyService.open} fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Удалить конфигурацию</DialogTitle>
      <DialogContent>Уверены что хотите удалить конфигурацию?</DialogContent>
      <DialogActions>
        <Box width={280}>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
        </Box>
        <Box width={280}>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOk}>
            Удалить
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

DeletePropertyDialog.displayName = 'DeletePropertyDialog';

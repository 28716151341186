import * as React from 'react';
import { FC, MouseEventHandler, useState } from 'react';

import { Box, Stack } from '@mui/system';
import Tooltip from '@/shared/ui/Tooltip';
import cn from 'classnames';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { observer } from 'mobx-react-lite';

import styles from './ButtonWithModal.module.scss';

export interface ButtonWithModalProps {
  title: string;
  icon: JSX.Element;
  tooltip?: string | JSX.Element;
  isActive?: boolean;

  render(params: { isOpen: boolean; close(): void }): JSX.Element;

  onClick?: MouseEventHandler;
}

export const ButtonWithModal: FC<ButtonWithModalProps> = observer(
  ({ title, icon, tooltip, isActive, render, onClick }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [isShowTooltip, setIsShowTooltip] = useState<boolean>(false);

    const handleButtonClick: MouseEventHandler = (event) => {
      if (isOpen) {
        setIsOpen(false);
      } else {
        setIsOpen(true);
      }

      onClick?.(event);
    };

    const close = () => {
      setIsOpen(false);
    };

    const handleTooltipOpen = () => {
      if (!isOpen && isActive) {
        setIsShowTooltip(true);
      }
    };

    const handleTooltipClose = () => {
      setIsShowTooltip(false);
    };

    return (
      <Stack gap={0.5}>
        <Tooltip
          title={tooltip}
          open={isShowTooltip}
          placement='right'
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
        >
          <button
            className={cn(
              styles.button,
              { [styles.active]: isOpen || isActive },
              { [styles.marker]: isActive }
            )}
            type='button'
            onClick={handleButtonClick}
          >
            <Stack flexDirection='row' gap={1} alignItems='center'>
              {isOpen ? <CrossIcon width={12} height={12} /> : icon}
              <p>{title}</p>
            </Stack>
          </button>
        </Tooltip>
        <Box position='relative'>{render({ isOpen, close })}</Box>
      </Stack>
    );
  }
);

ButtonWithModal.displayName = 'ButtonWithModal';

import { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { Control, DeepPartial, UseFormRegister } from 'react-hook-form';
import { Settings, SettingsStore } from '@/entities/Settings';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from "@/shared/ui/Typography";

const settingsStore = container.resolve(SettingsStore);

export interface EmailServerSettingsProps {
  errors: any;
  register: UseFormRegister<Settings>;
  control: Control<Settings, any>;
  defaultValues: Readonly<DeepPartial<Settings>> | undefined;
}

export const TelegramSettings: FC<EmailServerSettingsProps> = observer(
  ({ errors, register, control, defaultValues }) => {
    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Настройки Telegram</AccordionSummary>
        <AccordionDetails>
          <Grid container gap={3} justifyContent='center'>
            <Grid xs={12} lg={12}>
              <FieldInput
                error={errors?.telegramSettings?.token}
                register={register}
                name='telegramSettings.token'
                label='Токен бота Telegram'
                defaultValue={defaultValues?.telegramSettings?.token}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }
);

TelegramSettings.displayName = 'TelegramSettings';

import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import Stack from '@mui/material/Stack';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { CreateUserService } from '../api/CreateUserService';
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";

const createUserService = container.resolve(CreateUserService);

export interface CreateUserButtonProps {}

export const RequestAdminRightButton: FC<CreateUserButtonProps> = observer(() => {
  const handleClick = useCallback(() => {
    createUserService.requestAdminRule({})
  }, []);

  return (
    <Button doesUseInFormOrTable variant={ButtonVariants.Secondary} onClick={handleClick}>
      <Stack direction='row' gap={1} alignItems='center'>
        Запросить права администратора
      </Stack>
    </Button>
  );
});

RequestAdminRightButton.displayName = 'RequestAdminRightButton';

import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { SkeletonConnectDictionary } from '../types';

@singleton()
export class SkeletonConnectStore {
  input: SkeletonConnectDictionary;
  processor: SkeletonConnectDictionary;
  output: SkeletonConnectDictionary;
  isLoadingSkeleton: boolean;

  constructor() {
    this.input = {};
    this.processor = {};
    this.output = {};
    this.isLoadingSkeleton = false;

    makeAutoObservable(this);
  }
}

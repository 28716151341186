import React, { FC, memo } from 'react';

import {TextFieldSearchParams} from "@/shared/ui/TextFieldSearchParams/TextFieldSearchParams";
import SearchIcon from "@mui/icons-material/Search";
import styles from "@/entities/ExecutorLog/ui/styles.module.scss";
import {IconButton} from "@mui/material";
import {CrossIcon} from "@/shared/ui/Icons/CrossIcon/CrossIcon";

export interface FilterKeyValueStoreProps {}

const propsTextField = {
  startAdornment: <SearchIcon className={styles.searchIcon} />,
};

export const FilterKeyValueStore: FC<FilterKeyValueStoreProps> = memo(() => {
  return (
      <TextFieldSearchParams name={'search'} InputProps={propsTextField}/>
  );
});

FilterKeyValueStore.displayName = 'FilterKeyValueStore';

import { FC, FormEventHandler, useCallback, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Role, RoleStore } from '@/entities/Role';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { notify } from '@/shared/ui/Toast/notify';
import { UpdateRoleService } from '@/features/UpdateRole';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';

const roleStore = container.resolve(RoleStore);
const updateRoleService = container.resolve(UpdateRoleService);

export interface UpdateRoleFormProps {
  roleId: string;
}

export const UpdateRoleForm: FC<UpdateRoleFormProps> = observer(({ roleId }) => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit } = useForm<Role>({
    values: roleStore.roles?.entities[roleId],
  });

  const handleValidSubmit = useCallback<SubmitHandler<Role>>(async (role) => {
    try {
      setIsLoading(true);

      await updateRoleService.updateRole(role);

      notify.success('Роль изменена');
    } catch {
      notify.error('Не удалось изменить роль');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const preventSubmit = useCallback<FormEventHandler<HTMLFormElement>>((event) => {
    event.preventDefault();
  }, []);

  return (
    <form onSubmit={preventSubmit} onBlur={handleSubmit(handleValidSubmit)}>
      <FieldInput
        register={register}
        error=''
        name='name'
        label='Название роли'
        endAdornment={isLoading ? <LoaderIcon /> : undefined}
      />
    </form>
  );
});

UpdateRoleForm.displayName = 'UpdateRoleForm';

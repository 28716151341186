import {ExecutorHistory} from "@/entities/Connect/types";

export const findExecutorHistoryByTracing = (executorHistory : ExecutorHistory, findId: string) : ExecutorHistory | null => {
  if (executorHistory != null) {
    if (executorHistory.id === findId) {
      return executorHistory;
    } else {
      if (Array.isArray(executorHistory.executorHistoryNext)) {
        for (let i = 0; i < executorHistory.executorHistoryNext.length; i++) {
          let find = findExecutorHistoryByTracing(executorHistory.executorHistoryNext[i], findId)
          if (find !== null && find !== undefined) {
            return find
          }
        }
      }
    }
  } else {
    return null
  }
}
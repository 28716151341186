import { FC, memo } from 'react';

import { TreeNodeProps } from 'rc-tree/es/interface';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';

import styles from './styles.module.scss';

export interface IconProps extends TreeNodeProps {}

export const Icon: FC<IconProps> = memo(({ checked, halfChecked }) => {
  return <Checkbox className={styles.root} checked={checked || halfChecked} />;
});

Icon.displayName = 'Icon';

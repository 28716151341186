import { FC } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';

import { ChoosePythonScriptService } from '../api/ChoosePythonScriptService';

const choosePythonScriptService = container.resolve(ChoosePythonScriptService);

export interface ChoosePythonBeanButtonProps {
  pythonScriptId?: string;
}

export const ChoosePythonBeanButton: FC<ChoosePythonBeanButtonProps> = observer(
  ({ pythonScriptId }) => {
    const handleClick = () => {
      if (pythonScriptId) {
        choosePythonScriptService.setSelectedScript(pythonScriptId);
      }

      choosePythonScriptService.openDialog = false;
    };

    return (
      <Button variant={ButtonVariants.Secondary} onClick={handleClick}>
        Выбрать скрипт
      </Button>
    );
  }
);

ChoosePythonBeanButton.displayName = 'ChoosePythonBeanButton';

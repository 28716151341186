import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { PythonBeanStore, PythonScript } from '@/entities/PythonBean';

@singleton()
export class ChoosePythonScriptService {
  openDialog: boolean;
  selectedScript: PythonScript | null;

  constructor(private pythonBeanStore: PythonBeanStore) {
    this.openDialog = false;
    this.selectedScript = null;

    makeAutoObservable(this);
  }

  setSelectedScript(pythonScriptId: string) {
    this.selectedScript = this.pythonBeanStore.scripts!.entities[pythonScriptId];
  }
}

import React from 'react';
import {Panel, getNodesBounds, getViewportForBounds, useReactFlow} from 'reactflow';
import { toPng } from 'html-to-image';
import Button from "@/shared/ui/Button";
import {ButtonSizes, ButtonVariants} from "@/shared/ui/Button/types";
import {DownloadIcon} from "@/shared/ui/Icons/DownloadIcon/DownloadIcon";
import Tooltip from "@/shared/ui/Tooltip";



function ReactFlowToImageButton(props) {
    const {selector = '', imageWidth = 3024, imageHeight = 20680, fileName = 'integra.png'} = props
    const { getNodes } = useReactFlow();

    function downloadImage(dataUrl) {
        const a = document.createElement('a');

        a.setAttribute('download', fileName);
        a.setAttribute('href', dataUrl);
        a.click();
    }

    const onClick = () => {
        // we calculate a transform for the nodes so that all nodes are visible
        // we then overwrite the transform of the `.react-flow__viewport` element
        // with the style option of the html-to-image library
        const nodesBounds = getNodesBounds(getNodes());
        const transform = getViewportForBounds(nodesBounds, imageWidth, imageHeight, 1, 1);

        toPng(document.querySelector(`${selector} .react-flow__viewport`), {
            backgroundColor: '#1F2128',
            width: imageWidth,
            height: imageHeight,
            style: {
                width: imageWidth,
                height: imageHeight,
                transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
            },
        }).then(downloadImage);
    };

    return (
        <Panel position="top-right">
            <Tooltip title='Выгрузить в PNG' placement='top'>
              <span>
                <Button
                    circle
                    variant={ButtonVariants.Outlined}
                    size={ButtonSizes.Small}
                    onClick={onClick}
                >
                  <DownloadIcon />
                </Button>
              </span>
            </Tooltip>
        </Panel>
    );
}

export default ReactFlowToImageButton;
import { FC, useCallback } from 'react';

import { CardWithActions } from '@/shared/ui/CardWithActions/CardWithActions';
import { container } from 'tsyringe';
import { BlockTestingService } from '@/entities/Block/services/BlockTestingService';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import styles from './BlockTestingCard.module.scss';

const blockTestingService = container.resolve(BlockTestingService);

export interface BlockTestingCardProps {
  testId: string;
  actions: JSX.Element;
}

export const BlockTestingCard: FC<BlockTestingCardProps> = observer(({ actions, testId }) => {
  const { set, get } = useSearchParamsTemplate();
  const testIdSearchParam = get(SearchParams.TestId);

  const lastResult = blockTestingService.tests.entities[testId]?.lastResult;
  const lastDateTest = blockTestingService.tests.entities[testId]?.lastDateTest;

  const indicatorType = lastResult ? IndicatorTypes.Success : IndicatorTypes.Error;
  const indicatorTooltip = lastResult ? 'Пройден' : 'Провален';
  const date = lastDateTest
    ? `Последний запуск: ${dayjs(blockTestingService.tests.entities[testId]?.lastDateTest).format(
        'DD.MM.YYYY HH:mm:ss'
      )}`
    : 'Тест не запускался';

  const isLoading = blockTestingService.isLoadingTests || blockTestingService.isLoadingRunTests;

  const handleElementClick = useCallback(
    (testId: string) => () => {
      set([SearchParams.ShowTestForm, { [SearchParams.TestId]: testId }]);
    },
    [set]
  );

  return (
    <CardWithActions
      showIndicator={blockTestingService.tests.entities[testId]?.lastResult !== null}
      indicatorType={indicatorType}
      indicatorTooltip={indicatorTooltip}
      title={blockTestingService.tests.entities[testId]?.name || 'Название отсутствует'}
      loading={isLoading}
      active={testIdSearchParam === testId}
      onClick={handleElementClick(testId)}
      actions={actions}
    >
      <p className={styles.date}>{date}</p>
    </CardWithActions>
  );
});

BlockTestingCard.displayName = 'BlockTestingCard';

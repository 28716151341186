import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { Box } from '@mui/system';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertLicenseService } from '../api/UpsertLicenseService';

const upsertLicenseService = container.resolve(UpsertLicenseService);

export interface EditLicenseButtonProps {
  id: string;
}

export const EditLicenseButton: FC<EditLicenseButtonProps> = observer(({ id }) => {
  const handleClick = useCallback(() => {
    upsertLicenseService.open = true;
    upsertLicenseService.selectedLicenseId = id;
  }, [id]);

  return (
    <Tooltip title='Редактировать лицензионный ключ' placement='top'>
      <Box>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

EditLicenseButton.displayName = 'EditLicenseButton';

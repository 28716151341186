import * as React from 'react';
import { CSSProperties, FC, useEffect, useState, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import SplitPane from 'react-split-pane';
import cn from "classnames";

import { DashboardContent, DashboardSidebar } from '../../widgets';

import styles from './DashboardPage.module.scss';

const minSize = 260;
const defaultSize = 330;

export const DashboardPage: FC = observer(() => {
  const [size, setSize] = useState<CSSProperties['width']>(defaultSize);
  const [expanded, setExpanded] = useState(true);
  const prevSize = useRef(defaultSize);
  const direction = useRef(0);

  return (
    <div className={styles.root}>
      <SplitPane
        split="vertical"
        defaultSize={defaultSize}
        size={size}
        minSize={0}
        maxSize={-500}
        resizerClassName={cn(styles.resizer, styles.vertical)}
        pane1ClassName={cn(
          styles.pane1, {
            [styles.togglepane]: !expanded,
          })}
        className={styles.splitter}
        allowResize={expanded}
        onDragStarted={() => {
          setSize(undefined);
        }}
        onChange={(width) => {
          direction.current = width - prevSize.current;
          prevSize.current = width;
        }}
        onDragFinished={(width) => {
          if (width === 0 || width >= minSize) return;
          setExpanded(false);
          if (width > minSize * 0.7) setSize(minSize);
          else if (width < minSize * 0.3) setSize(0);
          else setSize(direction.current > 0 ? minSize : 0);
          // delay for transition in .togglePane
          setTimeout(() => { setExpanded(true); }, 750);
        }}
        onResizerDoubleClick={({ clientX: x}) => {
          if (x >= minSize) {
            setExpanded(false);
            setSize(0);
          } else {
            setSize(minSize);
            // delay for transition in .togglePane
            setTimeout(() => { setExpanded(true); }, 750);
          }
        }}
      >
        <DashboardSidebar />
        <DashboardContent />
      </SplitPane>
    </div>
  );
});

DashboardPage.displayName = 'DashboardPage';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { User } from './types';

@singleton()
export class UserStore {
  users: Normalized<User> | null;

  isNotRight: boolean;

  constructor() {
    this.users = { ids: [], entities: {} };
    this.isNotRight = false;

    makeAutoObservable(this);
  }
}

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';
import { License } from '@/entities/License';

@singleton()
export class LicenseStore {
  licenses: Normalized<License> | null;

  constructor() {
    this.licenses = null;

    makeAutoObservable(this);
  }

  get licenseList() {
    if (this.licenses?.entities) {
      return Object.values(this.licenses.entities);
    }

    return [];
  }
}

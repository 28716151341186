import { FC, useEffect, useState } from 'react';

import { container } from 'tsyringe';
import { RoleService, RoleStore } from '@/entities/Role';
import { observer } from 'mobx-react-lite';
import Stack from '@mui/material/Stack';
import { UpdateRoleForm } from '@/features/UpdateRole';
import { ErrorWrapper } from '@/shared/ui';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import Box from '@mui/material/Box';
import { CreateRoleButton, CreateRoleFormDialog } from '@/features/CreateRole';
import { DeleteRoleButton, DeleteRoleDialog } from '@/features/DeleteRole';
import {Loader} from "@/shared/ui/Loader/Loader";

const roleStore = container.resolve(RoleStore);
const roleService = container.resolve(RoleService);

export interface RolesPageProps {}

export const RolesPage: FC<RolesPageProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [thereIsError, setThereIsError] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);

    roleService
      .getRoles()
      .then(() => setThereIsError(false))
      .catch(() => setThereIsError(true))
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return (
        <Loader width={60} height={60}/>
    );
  }

  if (thereIsError) {
    return (
      <ErrorWrapper title='Не удалось получить роли пользователей' repeat={roleService.getRoles} />
    );
  }

  return (
    <>
      <Stack gap={2.5} display='flex' flexDirection='column'>
        <Stack height='calc(100vh - 357px)' sx={{ overflowY: 'auto' }}>
          <Stack direction='row' flexWrap='wrap' gap={5}>
            {roleStore.roles?.ids.map((id) => (
              <Stack gap={2.5} direction='row' key={id}>
                <Box width={268}>
                  <UpdateRoleForm roleId={id} />
                </Box>
                <Box display='flex' height={56} alignItems='center' alignSelf='flex-end'>
                  <DeleteRoleButton roleId={id} />
                </Box>
              </Stack>
            ))}
          </Stack>
        </Stack>
        <Box width={300} alignSelf='end'>
          <CreateRoleButton />
        </Box>
      </Stack>
      <CreateRoleFormDialog />
      <DeleteRoleDialog />
    </>
  );
});

RolesPage.displayName = 'RolesPage';

import React, { FC, MouseEventHandler, useCallback, useState } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import cn from 'classnames';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import styles from '@/shared/ui/ButtonWithModal/ButtonWithModal.module.scss';
import { ReloadIcon } from '@/shared/ui/Icons/ReloadIcon/ReloadIcon';

const pythonLibsService = container.resolve(PythonLibsService);

export interface UpsertPythonLibRefreshButtonProps {}

const pythonLibsStore = container.resolve(PythonLibsStore);

export const PythonLibsRefreshButton: FC<UpsertPythonLibRefreshButtonProps> = observer(() => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      setIsOpen((prevState) => !prevState);
      pythonLibsService.getPythonLibs();
    },
    [pythonLibsStore.mode]
  );

  return (
    <span>
      <button
        className={cn(styles.button, { [styles.active]: isOpen })}
        type='button'
        onClick={handleClick}
      >
        <Stack flexDirection='row' gap={1} alignItems='center'>
          <ReloadIcon width={12} height={12} />
          <p>{'Обновить'}</p>
        </Stack>
      </button>
    </span>
  );
});

PythonLibsRefreshButton.displayName = 'PythonLibsRefreshButton';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import {
  SettingsBasic,
  SettingsUserAuthService,
  SettingsUserAuthStore,
} from '@/entities/SettingsUserAuth';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class UpdateSettingsBasicAuthService {
  constructor(
    private apiService: ApiService,
    private settingsUserAuthStore: SettingsUserAuthStore,
    private settingsUserAuthService: SettingsUserAuthService
  ) {
    makeAutoObservable(this);
  }

  async updateSettingsBasicAuth(data: SettingsBasic) {
    const settingsBasic = { ...this.settingsUserAuthStore.settingsBasic! };

    settingsBasic.entities[data.id] = data;

    const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
      ...this.settingsUserAuthStore.settingsUserAuth,
      settingsBasicList: Object.values(settingsBasic.entities),
    });

    this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

    return response.data;
  }
}

import { FC, useCallback, useState } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { FieldInput, FieldInputProps } from '@/shared/ui/Fields/components/FieldInput/FieldInput';

/**
 * Компонент инпута с паролем для формы
 */
export const FieldPassword: FC<FieldInputProps> = (props) => {
  const [isShowText, setIsShowText] = useState<boolean>(false);

  const handleButtonIconClick = useCallback(() => {
    setIsShowText((prevState) => !prevState);
  }, []);

  return (
    <FieldInput
      {...props}
      type={isShowText ? 'text' : 'password'}
      endAdornment={
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleButtonIconClick}
        >
          {isShowText ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Button>
      }
    />
  );
};

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { ZoomOutOutlined } from '@ant-design/icons';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";

const periodService = container.resolve(PeriodService);


export const ZoomOutBtn = observer(() => {
  const { set } = useSearchParamsTemplate();

  const handleClick = () => {
    const [start, end] = periodService.unixPeriod;
    periodService.fixPeriod();
    const halfDuration = Math.floor((end - start) / 2);
    const from = start - halfDuration;
    const to = end + halfDuration;
    const unixPeriod: [number, number] = [from, to];
    periodService.unixToDayjs(unixPeriod);
    set({ from: String(from), to: String(to) });
  };

  return (
    <Tooltip title="Расширить период" placement="top" disableInteractive>
      <span>
        <Button
          className="zoom-btn"
          variant={ButtonVariants.Secondary}
          size={ButtonSizes.Medium}
          doesUseInFormOrTable
          onClick={handleClick}
        >
          <ZoomOutOutlined style={{ fontSize: '1.7em', color: 'var(--label-text-color)' }} />
        </Button>
      </span>
    </Tooltip>
  );
});

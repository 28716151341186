import { useEffect, useState } from 'react';

import _ from 'lodash';

export default function useDebounceState(startValue, delay = 1000, props = {}) {
  const [valueState, setValueState] = useState(startValue);

  const { value = valueState, setValue = setValueState, ignoreVariables = [] } = props;

  const [debouncedValue, setDebouncedValue] = useState(startValue);
  const [countChange, setCountChange] = useState(0);

  useEffect(() => {
    const handler = setTimeout(() => {
      let valueAfterIgnore = _.cloneDeep(value);
      let debouncedValueAfterIgnore = _.cloneDeep(debouncedValue);
      ignoreVariables.forEach((name) => {
        _.set(valueAfterIgnore, name, undefined);
        _.set(debouncedValueAfterIgnore, name, undefined);
      });
      if (!_.isEqual(valueAfterIgnore, debouncedValueAfterIgnore)) {
        setDebouncedValue(_.cloneDeep(value));
        setCountChange(countChange + 1);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  const setState = (newValue) => {
    setValue(newValue);
  };

  return [value, setState, debouncedValue, countChange];
}

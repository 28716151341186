import { Monaco } from '@monaco-editor/react';

export function defineCodeEditorDarkTheme(monaco: Monaco) {
  monaco.editor.defineTheme('dark-theme', {
    base: 'vs-dark',
    inherit: true,
    rules: [],
    colors: {
      foreground: '#242731',
      background: '#242731',
      // 'editor.foreground': '#242731',
      'editor.background': '#242731',
      'minimap.background': '#242731',
      'editorOverviewRuler.background': '#242731',
    },
  });
}

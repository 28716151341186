import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { singleton } from 'tsyringe';
import { FlowVersionStore, PageVersion, VersionRequest } from '@/entities/FlowVersion';
import { normalize } from '@/shared/lib/normalize';
import _ from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';

@singleton()
export class OpenVersionsService {
  isLoading: boolean;
  showDrawer: boolean;
  selectedVersionId: string | null;

  constructor(private apiService: ApiService, private flowVersionStore: FlowVersionStore) {
    this.isLoading = false;
    this.showDrawer = false;
    this.selectedVersionId = null;

    makeAutoObservable(this);
  }

  async getFlowVersionsByFlowId(flowId: string, request: VersionRequest) {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.post<PageVersion>(
        `/editor/version/byFlowId/${flowId}`,
        request
      );

      const normalizedVersions = normalize(response.data.versionPage.content, 'id');

      runInAction(() => {
        if (this.flowVersionStore.versions) {
          this.flowVersionStore.versions.ids = _.uniq([
            ...this.flowVersionStore.versions.ids,
            ...normalizedVersions.ids,
          ]);

          this.flowVersionStore.versions.entities = {
            ...this.flowVersionStore.versions.entities,
            ...normalizedVersions.entities,
          };
        } else {
          this.flowVersionStore.versions = normalizedVersions;
        }

        this.flowVersionStore.lastPage = response.data.versionPage.totalPages;
      });
    } catch (error) {
      notify.error('Не удалось получить версии потока');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { FC } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { InDrawer } from '@/entities/Flow/features/TreeNodeActions/InDrawer';
import TFlowForm from '@/entities/Flow/features/TreeNodeActions/TFlowForm';

const gflowService = container.resolve(GFlowService);

const defaultValues = {
  parentId: '',
  type: 'flow',
  name: '',
  description: '',
};


const toFormValues = ({
  type,
  parentId,
  data: { name, description },
}) => ({
  type,
  parentId,
  name,
  description,
});

export const TFlowFormDrawer: FC = observer(() => {

  const { get, has } = useSearchParamsTemplate();
  if(!gflowService.gflows) return null;

  const nodeId = get('itemId');
  const createType = get(SearchParams.ShowCreateTFlowForm);
  const selected = gflowService.gflowsMap[nodeId] || null;
  const root = gflowService.gflows;

  if(!createType && !selected) return null;

  let parentId = selected?.parentId || root.id;
  if (createType && selected?.type === 'group') parentId = nodeId;

  const values = createType
    ? { ...defaultValues, type: createType, parentId }
    : toFormValues(selected);
  if (values.parentId === root.id) values.parentId = '';
  if (!values || gflowService.isLoadingGFlows) return null;

  const titleAct = has(SearchParams.ShowCreateTFlowForm) ? 'Создать' : 'Редактировать';
  const titleEntity = values.type === 'group' ? 'группу' : 'поток';
  const title = `${titleAct} ${titleEntity}`;

  return (
    <InDrawer
      title={title}
      searchParams={{
        create: SearchParams.ShowCreateTFlowForm,
        edit: SearchParams.ShowEditTFlowForm,
      }}
    >
      <TFlowForm initialValues={values} />
    </InDrawer>
  );
})

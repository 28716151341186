import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { ConsumerInformation } from './types';

@singleton()
export class ConsumerInformationStore {
  elements: Record<string, ConsumerInformation[]> | null;

  constructor() {
    this.elements = null;

    makeAutoObservable(this);
  }
}

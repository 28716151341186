import * as React from 'react';
import { CSSProperties, FC, useEffect, useState, useRef } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import SplitPane from 'react-split-pane';
import cn from "classnames";
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';

import { FlowContent, FlowSidebar } from '../../widgets';

import styles from './FlowPage.module.scss';

const allConnectService = container.resolve(AllConnectService);

const minSize = 260;
const defaultSize = 330;

export const FlowPage: FC = observer(() => {
  const [size, setSize] = useState<CSSProperties['width']>(defaultSize);
  const [expanded, setExpanded] = useState(true);
  const prevSize = useRef(defaultSize);
  const direction = useRef(0);

  useEffect(() => {
    void allConnectService.run();
  }, []);

  const handleChange = (width: number) => {
    direction.current = width - prevSize.current;
    prevSize.current = width;
  }
  const wrapAnimation = (width: number) => {
    setExpanded(false);
    setSize(width);
    // delay for transition in .togglePane
    setTimeout(() => { setExpanded(true); }, 750);
  }

  const calcWidth = (w: number) => {
    if (w > minSize * 0.7) return minSize;
    if (w < minSize * 0.3) return 0;
    return direction.current > 0 ? minSize : 0;
  }

  const handleDragFinish = (width: number) => {
    if (width === 0 || width >= minSize) return;
    const newWidth = calcWidth(width);
    wrapAnimation(newWidth);
  }

  const handleDoubleClickResizer = ({ clientX: x }: { clientX: number }) => {
    wrapAnimation(x < minSize ? minSize : 0);
  }

  return (
    <div className={styles.root}>
      <SplitPane
        split="vertical"
        defaultSize={defaultSize}
        size={size}
        minSize={0}
        maxSize={-500}
        resizerClassName={cn(styles.resizer, styles.vertical)}
        pane1ClassName={cn(
          styles.pane1,
          { [styles.togglepane]: !expanded },
        )}
        className={styles.splitter}
        allowResize={expanded}
        onDragStarted={() => {
          setSize(undefined);
        }}
        onChange={handleChange}
        onDragFinished={handleDragFinish}
        onResizerDoubleClick={handleDoubleClickResizer}
      >
        <FlowSidebar />
        <FlowContent />
      </SplitPane>
    </div>
  );
});

FlowPage.displayName = 'FlowPage';

import Stack from '@mui/system/Stack';
import { ExitButton } from '@/features/Exit';
import { HelpButton } from '@/features/Help';
import { OpenOnboardingModal } from '@/modules/JoyRideOnboarding/features/OpenOnboarding';

import { Logo } from '../Logo/Logo';
import { Navigation } from '../Navigation/Navigation';

import styles from './Header.module.scss';
import { ExitConfirmModal } from '@/features/Exit/ui/ExitConfirmModal';

/**
 * TODO
 * Добавить поиск и аву, когда они будут
 */

export function Header() {
  return (
    <div className={styles.wrapper}>
      <Logo />
      <Navigation />

      <Stack direction='row' gap={0.5}>
        <HelpButton />
        <ExitButton />
        <OpenOnboardingModal />
        <ExitConfirmModal />
      </Stack>
    </div>
  );
}

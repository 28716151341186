import { Groups, GFlow, Tree, SelectGroup } from "@/entities/Flow/types";
import { GFlowStore } from "@/entities/Flow/stores/GFlowStore";

export function traverse<T> (node: Tree<T>, callback: (item: Tree<T>) => void) {
  const iterate = (item: Tree<T>) => {
    callback(item);
    if (!item.children) return;
    item.children.forEach(iterate);
  }
  iterate(node);
}

export const prepareGroups = (root: GFlow): SelectGroup => {
  const reducer = (acc: SelectGroup[], item: Groups): SelectGroup[] => {
    const { children, data, id, type } = item;
    if (type !== 'group') return acc;
    const node: SelectGroup = {
      title: data.name,
      value: id,
      isLeaf: false,
      children: [],
    }
    if(!children) return [...acc, node];
    const newChildren = children.reduce(reducer, []);
    return [...acc, { ...node, children: newChildren }]
  }
  const [result] = [root].reduce(reducer, []);
  return result;
}

export const applyFilter = (
  list: SelectGroup[],
  predicate: (item: SelectGroup) => boolean,
): SelectGroup[] => {
  const reducer = (
    acc: SelectGroup[],
    item: SelectGroup,
  ): SelectGroup[] => {
    const { children, ...node } = item;
    if(!children) {
      return predicate(node)
        ? [...acc, node]
        : acc;
    }
    if (!predicate(node)) return acc;
    const newChildren = children.reduce(reducer, []);
    return [...acc, { ...node, children: newChildren }]
  }
  return list.reduce(reducer, []);
}

export const buildMaps = (root: GFlowStore['gflows']) => {
  const gflowsMap: GFlowStore['gflowsMap'] = {};
  const flowsHash: GFlowStore['flowsHash'] = {};
  const callback = function(rec: GFlowStore['gflows']) {
    const { id, componentId, type } = rec;
    gflowsMap[id] = rec;
    if (type === 'group') return;
    flowsHash[componentId] = id;
  }
  traverse(root, callback);
  return {
    gflowsMap,
    flowsHash,
  };
}

import { FC, memo } from 'react';

import RcTree, { TreeProps as RcTreeProps } from 'rc-tree';
import Typography from '@mui/material/Typography';

import { amendDraggable } from '@/shared/lib/extendDndkit';
import { Icon } from './Icon/Icon';
import { SwitcherIcon } from './SwitcherIcon/SwitcherIcon';

import styles from './styles.module.scss';

export interface TreeProps extends Omit<RcTreeProps, 'prefixCls'> {}

export const Tree: FC<TreeProps> = memo((props) => {
  return (
    <div className={styles.root} {...amendDraggable}>
      <RcTree
        {...props}
        showLine={false}
        icon={(props) => <Icon {...props} />}
        switcherIcon={(props) => <SwitcherIcon {...props} />}
        titleRender={(node) => (
          <Typography
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {/*@ts-ignore*/}
            {node.title}
          </Typography>
        )}
      />
    </div>
  );
});

Tree.displayName = 'Tree';

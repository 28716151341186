import React, { CSSProperties, FC, memo, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { container } from 'tsyringe';
import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import MaterialTabs, { tabsClasses } from '@mui/material/Tabs';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsList } from '@/entities/PythonLib/entities/PythonLibsList/PythonLibsList';
import { CreateButton } from '@/shared/ui/Sidebar/Sidebar';

import { PythonLibsRefreshButton } from '@/entities/PythonLib/features/actions/PythonLibsRefreshButton';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { PythonLibFilter } from '@/entities/PythonLib/PythonLibFilter/PythonLibFilter';
import { TabPanel } from '@/shared/ui/TabPanel/TabPanel';
import { Tab } from '@/shared/ui/Tab/Tab';
import Typography from '@/shared/ui/Typography';
import { PythonLibsSortButton } from '@/entities/PythonLib/features/actions/PythonLibsSortButton';

import styles from './PythonLibsSidebar.module.scss';

export interface PythonLibsSidebarProps {
  height?: CSSProperties['height'];
}

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export enum Tabs {
  user = 'user',
  system = 'all',
}

export const PythonLibsSidebar: FC<PythonLibsSidebarProps> = memo(() => {
  const navigate = useNavigate();
  const { get, set, remove } = useSearchParamsTemplate();

  const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.user);
  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      setActiveTab(tab);
    },
    []
  );
  const tabValue = ['Установленные', 'Системные'];
  const renderTabs = () => {
    return (
      <MaterialTabs
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs'
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': { opacity: 0.3 },
          },
        }}
      >
        <Tab
          isActive={activeTab === Tabs.user}
          onClick={handleTabClick(Tabs.user)}
          className={styles.tab}
        >
          <Typography>{tabValue[0]}</Typography>
        </Tab>
        <Tab
          isActive={activeTab === Tabs.system}
          onClick={handleTabClick(Tabs.system)}
          className={styles.tab}
        >
          <Typography>{tabValue[1]}</Typography>
        </Tab>
      </MaterialTabs>
    );
  };

  pythonLibsStore.mode = activeTab;

  useEffect(() => {
    pythonLibsService.getPythonLibs();
  }, [pythonLibsStore.mode]);

  const handleAdd = useCallback(() => {
    navigate('./');
    set([SearchParams.ShowAddPythonLibForm]);
    remove([
      SearchParams.ShowEditPythonLibForm,
      SearchParams.PythonLibName,
      SearchParams.PythonPodsId,
      SearchParams.ShowPythonPodLog,
      SearchParams.ShowEditPythonLibForm,
    ]);
  }, [navigate, remove, set]);

  return (
    <Stack className={cn(styles.root)} spacing={2} width='370px'>
      <Stack spacing={2.5} marginX={2.5} marginTop={2.5}>
        <CreateButton text='Добавить библиотеку' onClick={handleAdd} />
        <Stack direction='column' justifyContent='space-between' alignItems='flex-start'>
          <span className={styles.title}>Библиотеки</span>
          <span>{renderTabs()}</span>
        </Stack>
        <TabPanel index={pythonLibsStore.mode} value={activeTab} height='100%' width='100%'>
          <Stack marginTop={2.5} direction='row' justifyContent='space-between'>
            <PythonLibFilter />
            <PythonLibsSortButton />
            <PythonLibsRefreshButton />
          </Stack>
          <Stack alignItems='center' className={styles.list}>
            <PythonLibsList pythonLibTitle={get(SearchParams.PythonLibName)} />
          </Stack>
        </TabPanel>
      </Stack>
    </Stack>
  );
});

PythonLibsSidebar.displayName = 'PythonLibsSidebar';

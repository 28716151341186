import { FC } from 'react';

import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { AnimatedBox } from '@/shared/ui';

import { FieldSelect } from '../FieldSelect/FieldSelect';
import { Fields, FieldWhichRenderInsideFieldsProps } from '../../Fields';
import { UpdateSkeletonWrapper } from '@/features/UpdateSkeleton';

export const SelectByParameter: FC<FieldWhichRenderInsideFieldsProps> = ({
  error,
  parameter,
  animationProps,
  register,
  control,
  errors,
  isNamePrefixProps,
  namePrefix,
  setValue,
  getValues,
  updateSkeleton,
}) => {
  const parameters = _.get(parameter, 'parameterList', []);

  const selectOptions = parameters.map(({ name, label }) => ({
    label,
    value: name,
  }));

  return (
    <>
      <UpdateSkeletonWrapper
        isSendSkeletonForEnrichByButton={parameter.isSendSkeletonForEnrichByButton}
        isSendSkeletonForEnrichByDebounce={parameter.isSendSkeletonForEnrichByDebounce}
        getValues={getValues}
        updateSkeleton={updateSkeleton}
        sx={parameter.style}
        render={(onChange) => {
          return (
            <AnimatedBox {...animationProps} width='100%'>
              <FieldSelect
                {...parameter}
                onChange={onChange}
                error={error}
                control={control}
                options={selectOptions}
                defaultValue={parameter.defaultValue}
              />
            </AnimatedBox>
          );
        }}
      />

      <Controller
        name={parameter.name}
        control={control}
        render={({ field }) => {
          return (
            <Fields
              parameters={
                parameters.find((parameter) => parameter.name === field.value)?.parameterList || []
              }
              register={register}
              control={control}
              errors={errors}
              setValue={setValue}
              namePrefix={namePrefix}
              isNamePrefixProps={isNamePrefixProps}
              getValues={getValues}
              updateSkeleton={updateSkeleton}
            />
          );
        }}
      />
    </>
  );
};

export interface Account {
  name: string;
  login: string;
  password: string;
  createDate: Date;
  isActive: boolean;
  mobile: string;
  checkMobile: boolean;
}

export interface SettingsBasic {
  id: string;
  name: string | null;
  isActive: boolean;
  accountList: Account[];
}

export interface SettingsIdm {
  id: string;
  type: string | null;
  realm: string | null;
  rsaKey: string | null;
  issuer: string | null;
  methodGetToken: string | null;
  urlGetToken: string | null;
  clientId: string | null;
  clientSecret: string | null;
  timeRefresh: number | null;
}

export interface SettingsUserAuth {
  id: string;
  companyId: string;
  settingsBasicList: SettingsBasic[];
  settingsIDMList: SettingsIdm[];
}

export enum SettingsUserAuthTypes {
  SettingsBasic = 'settingsBasic',
  SettingsIdm = 'settingsIdm',
}

export interface IdmResponse {
  'account-service': string;
  public_key: string;
  realm: string;
  'token-service': string;
  'tokens-not-before': number;
}

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { FlowStore } from '@/entities/Flow/stores/FlowStore';
import { Flow } from '@/entities/Flow/types';

@singleton()
export class CloneFlowService {
  isLoading: boolean;
  showDialog: boolean;

  constructor(private apiService: ApiService, private flowStore: FlowStore) {
    this.isLoading = false;
    this.showDialog = false;

    makeAutoObservable(this);
  }

  /**
   * TODO
   * Изменить на метод клонирования потока, когда он будет на беке
   */
  async cloneFlow(flowId: string) {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.post<Flow>(`/editor/flow/${flowId}/clone`);

      runInAction(() => {
        this.flowStore.flows!.ids.unshift(response.data.id);
        this.flowStore.flows!.entities[response.data.id] = response.data;
      });
    } catch (error) {
      notify.error('Не удалось клонировать поток');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { FlowVersionStore, Version } from '@/entities/FlowVersion';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class UpsertFlowVersionService {
  showEditForm: boolean;
  showCreateForm: boolean;
  isLoading: boolean;

  constructor(private apiService: ApiService, private flowVersionStore: FlowVersionStore) {
    this.showEditForm = false;
    this.showCreateForm = false;
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async upsertFlowService(version: Version) {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.put<Version>('/editor/version/save', version);

      if (version.id) {
        this.flowVersionStore.versions!.entities[version.id] = response.data;
      } else {
        this.flowVersionStore.versions!.ids.unshift(response.data.id);
        this.flowVersionStore.versions!.entities[response.data.id] = response.data;
      }
    } catch (error) {
      if (version.id) {
        notify.error('Не удалось изменить версию');
      } else {
        notify.error('Не удалось создать версию');
      }
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { FC, useCallback } from 'react';

import { observer, Observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ActionPanel, CardWithActions, ListWithInfiniteScroll } from '@/shared/ui';
import { SetVersionButton } from '@/features';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { container } from 'tsyringe';
import { FlowVersionService, FlowVersionStore } from '@/entities/FlowVersion';
import { DeleteVersionButton } from '@/features/DeleteVersions';
import { ExportVersionButton } from '@/features/ExportVersion';
import { CloneVersionButton } from '@/features/CloneVersion';
import dayjs from 'dayjs';
import { UpsertFlowVersionButton } from '@/features/UpsertFlowVersion';

export interface FlowVersionListProps {
  versionId?: string;
}

const flowVersionStore = container.resolve(FlowVersionStore);
const flowVersionService = container.resolve(FlowVersionService);

export const FlowVersionList: FC<FlowVersionListProps> = observer(({ versionId }) => {
  const animated = useCallback((key: string) => {
    const fromWidth = { width: 'calc(100% - 50px)' };
    const toWidth = { width: 'calc(100% - 25px)' };
    const props = {
      whileHover: toWidth,
      whileTap: toWidth,
      initial: fromWidth,
      animate: fromWidth,
    };

    if (key === versionId) {
      return {
        ...props,
        initial: toWidth,
        animate: toWidth,
      };
    }
    return props;
  }, [versionId]);

  const handleListFetchNextElement = useCallback(
    (page: number) => flowVersionService.getFlowVersions({ pagination: { page, size: 20 } }),
    []
  );

  return (
    <ListWithInfiniteScroll
      animated={animated}
      elementWidth={'calc(100% - 50px)'}
      elements={flowVersionStore.versions?.ids || []}
      fetchNextElement={handleListFetchNextElement}
      lastPage={flowVersionStore.lastPage}
      activeElementKey={versionId}
      stopAnimatedForKey={versionId}
      loading={flowVersionStore.isLoading}
      renderElement={(id) => (
        <Observer key={id}>
          {() => {
            return (
              <Link
                to={`${id}/flows/${flowVersionStore.versions?.entities[id].versionFlow?.id}`}
                title={flowVersionStore.versions?.entities[id].versionFlow?.name}
              >
                <CardWithActions
                  active={versionId === id}
                  title={
                    `Поток: ${
                      flowVersionStore.versions?.entities[id].versionFlow?.name ||
                      'Название отсутсвует'
                    }` || 'Название отсутствует'
                  }
                  actions={
                    <ActionPanel small withoutBorder>
                      <UpsertFlowVersionButton />
                      <SetVersionButton />
                      <ExportVersionButton versionId={id} />
                      <CloneVersionButton />
                      <DeleteVersionButton />
                    </ActionPanel>
                  }
                >
                  <Stack>
                    <Typography variant={'description' as any}>
                      {flowVersionStore.versions?.entities[id].name}
                    </Typography>
                    <Typography variant={'description' as any}>
                      {flowVersionStore.versions?.entities[id].description}
                    </Typography>
                    <Typography paddingTop={0.5} variant={'description' as any}>
                      {dayjs(flowVersionStore.versions?.entities[id].versionDate).format(
                        'DD.MM.YYYY | HH:mm:ss'
                      )}
                    </Typography>
                  </Stack>
                </CardWithActions>
              </Link>
            );
          }}
        </Observer>
      )}
    />
  );
});

FlowVersionList.displayName = 'FlowVersionList';

import React, { useEffect } from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import { Normalized } from "@/shared/lib/normalize";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { CounterService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/CounterService";
import { PeriodService } from "@/entities/Dashboard/widgets/DashboardPlatform/services/PeriodService";
import { GraphView } from "@/entities/Dashboard/widgets/DashboardPlatform/components/View/GraphView/GraphView";
import { CounterTimeline } from "./types";
import { useSearchParamsTemplate } from "@/hooks/useTemplateSearchParams";
import { refreshOptions } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/constants";

const periodService = container.resolve(PeriodService);
const counterService = container.resolve(CounterService);
const dashboardService = container.resolve(DashboardService);

export interface CounterPanelProps {
  dashboardPanelId: string;
}

const getRefreshTimeout = (key: string) => {
  const opt = refreshOptions.find((itm) => itm.key === key);
  return opt?.value;
}

export const CounterPanel = observer((props: CounterPanelProps) => {
  const { dashboardPanelId } = props;
  const { get } = useSearchParamsTemplate();
  const refresh = get('refresh');
  const { period } = periodService;
  const { mode } = periodService;

    const data = dashboardService.getPanelData(dashboardPanelId);

    const { counterTimelines } = counterService;

    useEffect(() => {
      counterService.getCounterTimeLinesByBlockIds(data.blockIdList, periodService.unixPeriod);

      if (!refresh) return undefined;

      const timeout = getRefreshTimeout(refresh) * 1000; // seconds
      if (!timeout || mode !== 'live') return undefined;

      const id = setInterval(() => {
        if (periodService.mode === 'history') {
          clearInterval(id);
          return;
        }

        counterService.getCounterTimeLinesByBlockIds(data.blockIdList, periodService.unixPeriod);
      }, timeout);
      return () => clearInterval(id);
    }, [mode, data, refresh, period]);


    const constructData = (counterTimelineList: Normalized<CounterTimeline>) => {
      const arrCounter = counterTimelineList.ids
          .filter(id => {
            if (data.blockIdList.length == 0) {
              return true
            }
            data.blockIdList.indexOf(id) > -1
          })
          .map(id => counterTimelineList.entities[id])
      const obj = {}
      arrCounter.forEach(counterTimeline => {
        counterTimeline.counterInformationList.forEach(counterInformation => {
          // @ts-ignore
          obj[counterInformation.t] = {
            // @ts-ignore
            ...obj[counterInformation.t],
            t: counterInformation.t,
            [counterTimeline.blockId]: counterInformation.v
          }
        })
      })
      // @ts-ignore
      return Object.keys(obj).map((key) => obj[key]).sort((a, b) => a.t - b.t)
    }

  const constructXKeys = (counterTimelineList: Normalized<CounterTimeline>) => {
    return counterTimelineList.ids
        .filter(id => {
          if (data.blockIdList.length == 0) {
            return true
          }
          data.blockIdList.indexOf(id) > -1
        })
  }

    return (
      <GraphView
        type={data.graphType}
        data={constructData(counterTimelines)}
        xKeys={constructXKeys(counterTimelines)}
      />
    )
  }
)

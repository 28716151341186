import { FC, memo, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Box } from '@mui/system';
import { TooltipProps } from '@mui/material/Tooltip';
import { PaperPlaneIcon } from '@/shared/ui/Icons/PaperPlaneIcon/PaperPlaneIcon';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

export interface OpenSendMessageProps {
  blockId: string;
  toolbarPlacement?: TooltipProps['placement'];
}

export const OpenSendMessage: FC<OpenSendMessageProps> = memo(
  ({ blockId, toolbarPlacement = 'left' }) => {
    const { set } = useSearchParamsTemplate();

    const handleClick = useCallback(() => {
      set([SearchParams.ShowSendMessage, { [SearchParams.BlockId]: blockId }]);
    }, [blockId, set]);

    eventEmitter.on('OpenSendMessage' + blockId, handleClick)

    return (
      <Tooltip title='Отправить сообщение' placement={toolbarPlacement} disableInteractive>
        <Box height='max-content'>
          <Button
            tabIndex={1}
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <PaperPlaneIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

OpenSendMessage.displayName = 'OpenSendMessage';

import React from 'react';

import AuthForm from '../AuthForm';
import {container} from "tsyringe";
import {AuthService} from "@/shared/ui/AuthVerifier/services/AuthService";
import {observer} from "mobx-react-lite";

const authService = container.resolve(AuthService);

const BasicAuth = observer((props) => {
  const { children } = props;

  const isAuth = authService.isAuth

  return <>{isAuth ? <>{children}</> : <AuthForm />}</>;
});

BasicAuth.propTypes = {};

export default BasicAuth;

import { FC, useCallback } from 'react';
import { observer } from 'mobx-react-lite';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { container } from 'tsyringe';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { useParams } from 'react-router-dom';

const flowService = container.resolve(FlowService);
export const ClearLogsModal: FC = observer(() => {
  const { has, remove } = useSearchParamsTemplate();
  const { flowId } = useParams();
  const open = has(SearchParams.ShowClearLogsModal);

  const handleClose = useCallback(() => {
    remove([SearchParams.ShowClearLogsModal]);
  }, [remove]);

  const handleDeleteLogs = useCallback(() => {
    flowService.clearLog(flowId);
    remove([SearchParams.ShowClearLogsModal]);
  }, []);

  const handleDeleteErrors = useCallback(() => {
    flowService.clearError(flowId);
    remove([SearchParams.ShowClearLogsModal]);
  }, []);
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Очистить</DialogTitle>
      <DialogContent>Вы действительно хотите очистить логи?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDeleteLogs}>
          Удалить все логи
        </Button>
        <Button
          variant={ButtonVariants.Secondary}
          style={{ width: 330 }}
          onClick={handleDeleteErrors}
        >
          Удалить только ошибки
        </Button>
      </DialogActions>
    </Dialog>
  );
});

ClearLogsModal.displayName = 'ClearLogsModal';

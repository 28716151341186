import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { container } from 'tsyringe';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import { useReactFlow } from 'reactflow';
import { useParams } from 'react-router-dom';
import { RegistrableValues } from '@/shared/lib/types';
import { BlockService } from '@/entities/Block/services/BlockService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import styles from './PanelChooseConnects.module.scss';

export interface PanelChooseConnectsProps {
  x: number;
  y: number;
}

const allConnectService = container.resolve(AllConnectService);

export const PanelChooseConnects: FC<PanelChooseConnectsProps> = observer(({ x, y }) => {
  const reactFlowInstance = useReactFlow();
  const { set } = useSearchParamsTemplate();
  const { flowId } = useParams();

  container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
  const blockService = container.resolve(BlockService);

  const handleClose = () => {
    blockService.isVisiblePanelChooseConnects = false;
    blockService.isVisibleConnectionLine = false;
  };

  const handleElementClick = (connectorName: string) => () => {
    const edges = reactFlowInstance.getEdges();
    const lastEdge = edges[edges.length - 1];

    set([
      SearchParams.ShowCreatingTwoConnectsForm,
      {
        [SearchParams.SourceId]: lastEdge.source,
        [SearchParams.TargetId]: lastEdge.target,
        [SearchParams.ConnectorName]: connectorName,
      },
    ]);
  };

  useEffect(() => {
    const removeConnectionEdge = () => {
      const edges = [...reactFlowInstance.getEdges()];
      edges.pop();
      reactFlowInstance.setEdges(edges);
    };

    if (!blockService.isVisiblePanelChooseConnects) {
      removeConnectionEdge();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockService.isVisiblePanelChooseConnects]);

  return (
    <div
      className={styles.root}
      style={{
        transform: `translate(-50%, -50%) translate(${x}px,${y}px) scale(1)`,
      }}
    >
      <div className={cn(styles.header, 'flex justify-between')}>
        <h4 className={styles.title}>Выберите коннектор</h4>
        <button type='button' className={styles.buttonClose} onClick={handleClose}>
          <CrossIcon width={16} height={16} />
        </button>
      </div>
      <div className={cn(styles.content, 'flex flex-col gap-3')}>
        <p className={styles.title}>Коннекторы</p>
        <ul className={styles.list}>
          {allConnectService.dictionaryForBothConnectOptions.map((option) => {
            return (
              <li
                role='menuitem'
                className={styles.tag}
                key={option.name}
                onClick={handleElementClick(option.name)}
              >
                {option.localeName}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
});

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { uniq } from "lodash";

import { normalize } from '@/shared/lib/normalize';
import { ApiService } from '@/shared/api/Api/services/ApiService';

import { UserStore } from '../model/UserStore';

type Filter = {
  name: string;
  operator: string;
  value: string;
};

@singleton()
export class UserService {
  search: string;

  totalPages: number;

  constructor(private apiService: ApiService, private userStore: UserStore) {
    this.search = '';
    this.totalPages = 0;
    makeAutoObservable(this);
  }

  get users() {
    return this.userStore.users;
  }

  async getUsers(page: number) {
    const filterList: Filter[] = this.search
      ? [{
          name: 'login',
          operator: 'regex',
          value: this.search,
        }]
      : [];
    try {
      const response = await this.apiService.instance.post('/editor/user/search', {
        filterList,
        pagination: {
          page,
          size: 10,
        },
      });
      const { data: { userPage } } = response;

      const normalizedUsers = normalize(userPage.content, 'id');

      runInAction(() => {
        this.totalPages = userPage.totalPages;
        this.userStore.users.ids = uniq([...this.users.ids, ...normalizedUsers.ids]);
        this.userStore.users.entities = {
          ...this.userStore.users.entities,
          ...normalizedUsers.entities,
        };
        this.userStore.isNotRight = false;
      });
    } catch (err) {
      this.userStore.isNotRight = true;
    }
  }

  resetUsers() {
    runInAction(() => {
      this.userStore.users = { ids: [], entities: {} };
      this.totalPages = 0;
    });
  }
}

import { CSSProperties, FC, useEffect } from 'react';

import { Stack } from '@mui/system';
import { container } from 'tsyringe';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import { PythonBeanSidebar } from '@/widgets/PythonBeanSidebar';
import { PythonBeanContent } from '@/widgets/PythonBeanContent';
import { observer } from 'mobx-react-lite';

export interface PythonBeanPageProps {
  sidebarHeight?: CSSProperties['height'];
  hideChooseScriptButton?: boolean;
}

const allConnectService = container.resolve(AllConnectService);

export const PythonBeanPage: FC<PythonBeanPageProps> = observer(
  ({ sidebarHeight, hideChooseScriptButton }) => {
    useEffect(() => {
      void allConnectService.run();
    }, []);

    return (
      <Stack direction='row'>
        <PythonBeanSidebar height={sidebarHeight} />
        <Stack padding={4} flex={1}>
          <PythonBeanContent hideChooseScriptButton={hideChooseScriptButton} />
        </Stack>
      </Stack>
    );
  }
);

PythonBeanPage.displayName = 'PythonBeanPage';

import { FC, memo } from 'react';

import MuiDialog, { DialogProps as MuiDialogProps } from '@mui/material/Dialog';
import cn from 'classnames';

import styles from './Dialog.module.scss';

export interface DialogProps extends MuiDialogProps {}

export const Dialog: FC<DialogProps> = memo(({ className, ...props }) => {
  return <MuiDialog {...props} className={cn(styles.root, className)} />;
});

import React, { FC, useCallback, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { container } from 'tsyringe';
import { RoleStore } from '@/entities/Role';
import { UserStore } from '@/entities/User/model/UserStore';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Stack from '@mui/material/Stack';
import { AcceptIcon } from '@/shared/ui/Icons/AcceptIcon/AcceptIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { User } from '@/entities/User';
import { notify } from '@/shared/ui/Toast/notify';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';

import { UpdateUserService } from '../api/UpdateUserService';

const roleStore = container.resolve(RoleStore);
const userStore = container.resolve(UserStore);
const updateUserService = container.resolve(UpdateUserService);

export interface UpdateUserFormProps {
  userId: string;
  deleteButton?: JSX.Element;
}

export const UpdateUserForm: FC<UpdateUserFormProps> = observer(({ userId, deleteButton }) => {
  const { register, control, handleSubmit } = useForm({
    values: userStore.users?.entities[userId],
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleValidSubmit = useCallback<SubmitHandler<User>>(async (user) => {
    try {
      setIsLoading(true);

      await updateUserService.updateUser(user);

      notify.success('Пользователь сохранен');
    } catch {
      notify.error('Не удалось сохранить пользователя');
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(handleValidSubmit)}>
      <Stack direction='row' gap={2.5}>
        <Grid container spacing={2.5} alignItems='center'>
          <Grid item xs={12} md={3} lg={2}>
            <FieldInput register={register} error='' name='login' label='Логин' />
          </Grid>

          <Grid item xs={12} md={3} lg={1}>
            <FieldInput register={register} error='' name='language' label='Языковой пакет' />
          </Grid>

          <Grid item xs={12} md={4} lg={5}>
            <Controller
              control={control}
              name='roles'
              render={({ field }) => {
                return (
                  <Autocomplete
                    loading={isLoading}
                    multiple
                    label='Роли'
                    options={roleStore.roleOptions}
                    value={field.value}
                    filterSelectedOptions
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                  />
                );
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={3}>
            <Box display='flex' gap={2}>
              <FieldSwitch control={control} error='' name='isIntegraInterface' label='Доступ к интерфейсу Интегры' />
              <FieldSwitch control={control} error='' name='isAdmin' label='Права администратора' />
            </Box>
          </Grid>

          <Grid item xs={12} md={2} lg={1}>
            <Stack direction='row' gap={2.5}>
              <Tooltip title='Сохранить изменения' placement='top'>
                <span>
                  <Button
                    circle
                    type='submit'
                    size={ButtonSizes.Small}
                    variant={ButtonVariants.Outlined}
                    loading={isLoading}
                  >
                    <AcceptIcon />
                  </Button>
                </span>
              </Tooltip>
              {deleteButton}
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </form>
  );
});

UpdateUserForm.displayName = 'UpdateUserForm';

import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { injectable } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { FlowVersionStore, Version } from '@/entities/FlowVersion';

@injectable()
export class ImportVersionsService {
  isLoading: boolean;

  constructor(private apiService: ApiService, private flowVersionStore: FlowVersionStore) {
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async importVersion(version: Version) {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.post<Version>(
        '/editor/version/import',
        version
      );

      runInAction(() => {
        if (this.flowVersionStore.versions) {
          this.flowVersionStore.versions.ids.unshift(response.data.id);
          this.flowVersionStore.versions.entities[response.data.id] = response.data;
        } else {
          this.flowVersionStore.versions = {
            ids: [response.data.id],
            entities: { [response.data.id]: response.data },
          };
        }
      });
    } catch (error) {
      notify.error(`Не удалось импортировать ${version.name}`);

      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

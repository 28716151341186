import React, { FC, memo, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { AppBar, Tab, Tabs } from '@mui/material';
import { tabsClasses } from '@mui/material/Tabs';
import { Parameter } from '@/entities/Connect/types';
import { Stack } from '@mui/system';
import { Fields, FieldsProps } from '@/shared/ui/Fields/Fields';

import fieldStyle from '../FieldTabs/styles.module.scss';
import { amendDraggable } from "@/shared/lib/extendDndkit";

/**
 * Компонент для отображения табов в формах
 */
export const FieldTabs: FC<FieldsProps> = memo(
  ({
    parameters,
    warnings,
    isNamePrefixProps,
    namePrefix,
    control,
    register,
    errors,
    setValue,
    getValues,
    updateSkeleton,
  }) => {
    const tabListPanel = useMemo(() => {
      return parameters?.map((tab: Parameter) => ({ label: tab['label'], name: tab['name'] }));
    }, [parameters]);
    const [activeTab, setActiveTab] = useState<string>(tabListPanel[0].name);
    const handleChangeActiveTab = (event: React.SyntheticEvent, newTab: string) => {
      setActiveTab(newTab);
    };

    return (
      <Box {...amendDraggable(true)}>
        <AppBar position='static' color='default' className={fieldStyle.root}>
          <Tabs
            value={activeTab}
            onChange={handleChangeActiveTab}
            textColor='inherit'
            variant='scrollable'
            scrollButtons='auto'
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                '&.Mui-disabled': { opacity: 0.3 },
              },
            }}
          >
            {tabListPanel?.map((tab: { label: string; name: string }) => {
              return <Tab disableRipple label={tab.label} value={tab.name} key={tab.name} />;
            })}
          </Tabs>
        </AppBar>

        {parameters.map((field) => {
          return (
            <div role='tabpanel' hidden={activeTab !== field.name} id={field.name} key={field.name}>
              {activeTab === field.name && (
                <Stack gap={2} flexGrow={1} paddingTop={2}>
                  <Fields
                    parameters={field.parameterList || []}
                    register={register}
                    control={control}
                    errors={errors}
                    warnings={warnings}
                    setValue={setValue}
                    namePrefix={namePrefix}
                    isNamePrefixProps={isNamePrefixProps}
                    getValues={getValues}
                    updateSkeleton={updateSkeleton}
                  />
                </Stack>
              )}
            </div>
          );
        })}
      </Box>
    );
  }
);

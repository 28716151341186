import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import {KeyValue, RequestFilter} from './types';
import {Page} from "@/shared/lib/types";

@singleton()
export class KeyValueStore {
  keyValues: Normalized<KeyValue> | null;
  filterList: RequestFilter[] | null;
  isLoading: boolean;
  lastPage: number;

  constructor() {
    this.keyValues = null;
    this.isLoading = false;
    this.lastPage = 0;
    this.filterList = null

    makeAutoObservable(this);
  }

  reset() {
    this.keyValues = null;
    this.isLoading = false;
    this.lastPage = 0;
    this.filterList = null
  }
}

import { makeAutoObservable, runInAction } from 'mobx';
import { injectable } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { normalize } from '@/shared/lib/normalize';
import _ from 'lodash';

import { PageVersion, Version, VersionRequest } from '../model/types';
import { FlowVersionStore } from '../model/FlowVersionStore';

@injectable()
export class FlowVersionService {
  constructor(private flowVersionStore: FlowVersionStore, private apiService: ApiService) {
    makeAutoObservable(this);
  }

  async getFlowVersions(request: VersionRequest) {
    this.flowVersionStore.isLoading = true;

    try {
      const response = await this.apiService.instance.post<PageVersion>(
        '/editor/version/search',
        request
      );

      const normalizedVersions = normalize(response.data.versionPage.content, 'id');

      runInAction(() => {
        if (this.flowVersionStore.versions) {
          this.flowVersionStore.versions.ids = _.uniq([
            ...this.flowVersionStore.versions.ids,
            ...normalizedVersions.ids,
          ]);

          this.flowVersionStore.versions.entities = {
            ...this.flowVersionStore.versions.entities,
            ...normalizedVersions.entities,
          };
        } else {
          this.flowVersionStore.versions = normalizedVersions;
        }

        this.flowVersionStore.lastPage = response.data.versionPage.totalPages;
      });
    } catch (error) {
      notify.error('Не удалось получить версии потока');
      throw error;
    } finally {
      this.flowVersionStore.isLoading = false;
    }
  }

  async getFlowVersion(id: string) {
    try {
      const response = await this.apiService.instance.get<Version>(`/editor/version/${id}`);

      return response.data;
    } catch (error) {
      notify.error('Не удалось получить версию потока');
      throw error;
    }
  }
}

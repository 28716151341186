import React, { CSSProperties, FC, useCallback } from 'react';

import { Box, Stack } from '@mui/system';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { PythonBeanPage } from '@/pages/PythonBeanPage';
import { PythonLibsSidebar } from '@/entities/PythonLib/entities/PythonLibsSidebar/PythonLibsSidebar';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PythonLibFormDrawer } from '@/entities/PythonLib/widgets/PythonLibFormDrawer';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { PythonLogsByPod } from '@/entities/PythonLib/widgets/PythonLogsByPod/PythonLogsByPod';
import { Resizable } from '@/shared/ui';

import { PythonInstanceRequest } from '@/entities/PythonLib/stores/types';
import { DeletePythonLibDialog } from '@/features/DeletePythonLib/ui/DeletePythonLibDialog';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibContent } from '@/entities/PythonLib/entities/PythonLibContent/PythonLibContent';

import styles from '@/entities/PythonLib/entities/PythonLibContent/PythonLibContent.module.scss';

export interface PythonBeanPageProps {
  sidebarHeight?: CSSProperties['height'];
}

const pythonLibsStore = container.resolve(PythonLibsStore);
const pythonLibsService = container.resolve(PythonLibsService);
export const PythonLibsPage: FC<PythonBeanPageProps> = observer(() => {
  const { get, remove, has } = useSearchParamsTemplate();
  const podId = get(SearchParams.PythonPodsId);
  const podsInfo = JSON.parse(JSON.stringify(pythonLibsStore.pods))?.data;

  const handleClose = useCallback(() => {
    remove([SearchParams.ShowPythonPodLog, SearchParams.PythonPodsId]);
  }, [remove]);

  const findByLog = podsInfo?.find(
    (item: PythonInstanceRequest) => item.executorId === podId && item
  );

  const errorLog = findByLog?.errorLog;

  const handleCloseDelete = () => {
    pythonLibsService.showDialog = false;
  };

  return (
    <div className={styles.root}>
      <PythonLibsSidebar />
      <PythonLibContent />

      <Box display='grid' gridTemplateColumns='1fr max-content' height='100%'>
        {has(SearchParams.ShowPythonPodLog) && (
          <Stack flex={1}>
            <Resizable maxWidth={window.innerWidth - 260} initialWidth={434} minWidth={434}>
              <Drawer title='Лог пода' onClose={handleClose}>
                <PythonLogsByPod errorLog={errorLog} />
              </Drawer>
            </Resizable>
          </Stack>
        )}

        <PythonLibFormDrawer
          key={`${get(SearchParams.PythonLibName)}${get(SearchParams.ShowEditPythonLibForm)}`}
        />
      </Box>
      <DeletePythonLibDialog
        onClose={handleCloseDelete}
        pythonLibTitle={pythonLibsStore.selectedLib?.title}
      />
    </div>
  );
});

PythonBeanPage.displayName = 'PythonBeanPage';

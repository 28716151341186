import React, { useEffect, useState } from 'react';

import { Tab } from '@/shared/ui/Tab/Tab';
import { Stack } from '@mui/system';

import {
  CronQuartzUIService,
  getQuartzTypes,
  getSegmentsList,
  getTypeSegments,
  QuartzType,
  Type,
} from '../../cron-core';
import { genSessionId, getLocalization } from '../helpers';
import { CronHostProps } from '../cron-props.type';
import {
  QuartzCronDay,
  QuartzCronHour,
  QuartzCronMinute,
  QuartzCronMonth,
  QuartzCronSecond,
  QuartzCronYear,
} from './tabs';
import styles from '../../Cron.module.scss';

export type ReQuartzCronProps = CronHostProps<QuartzType>;
export const ReQuartzCron = ({
  localization: propLocalization,
  hideTabs: propHideTabs,
  value = '',
  activeTab,
  tabs = getQuartzTypes(),
  renderYearsFrom,
  renderYearsTo,
  cssClassPrefix,
  disabled,
  onTabChange,
  onChange,
}: ReQuartzCronProps) => {
  const [tab, setTab] = useState(activeTab || tabs[0]);
  const [service] = useState(new CronQuartzUIService(renderYearsFrom));
  const [renderCount, setRenderCount] = useState(0);
  const [session] = useState(genSessionId());
  const localization = getLocalization(propLocalization);
  const hasTabs = !propHideTabs && Boolean(tabs.length);
  const tabProps = {
    cssClassPrefix,
    localization,
    session,
    service,
  };
  const yearTabProps = {
    ...tabProps,
    renderYearsFrom,
    renderYearsTo,
  };

  useEffect(() => {
    const shouldUpdate = Boolean(activeTab) && activeTab !== tab;
    shouldUpdate && setTab(activeTab as any);
  }, [activeTab]);
  useEffect(() => () => service.destroy(), [service]);
  useEffect(() => listenChangas());
  useEffect(() => service.fillFromExpression(value || ''), [value]);
  useEffect(() => service.setDisabled(disabled), [disabled]);

  const listenChangas = () => {
    const segments = getSegmentsList();
    return service.listen(segments, (_, segment) => {
      const shouldApply = getTypeSegments(tab).includes(segment);
      if (shouldApply) {
        applyChanges();
      }
    });
  };

  const genContent = () => {
    if (tab === Type.SECONDS) {
      return <QuartzCronSecond {...tabProps} />;
    } else if (tab === Type.MINUTES) {
      return <QuartzCronMinute {...tabProps} />;
    } else if (tab === Type.HOURS) {
      return <QuartzCronHour {...tabProps} />;
    } else if (tab === Type.MONTH) {
      return <QuartzCronMonth {...tabProps} />;
    } else if (tab === Type.YEAR) {
      return <QuartzCronYear {...yearTabProps} />;
    } else {
      return <QuartzCronDay {...tabProps} />;
    }
  };

  const genTabs = (activeTab: QuartzType) => {
    return (
      <ul className={styles.tabs} role='tablist' aria-label='Cron Generator Tabs'>
        {tabs.map((t) => genTab(t, activeTab))}
      </ul>
    );
  };

  const genTab = (tab: QuartzType, activeTab: QuartzType) => {
    const { tabs: tabsLocalization } = localization;
    const isActive = activeTab === tab;
    const tabKey = tab.toLowerCase() as keyof typeof tabsLocalization;

    return (
      <Tab isActive={isActive} onClick={() => changeTab(tab)}>
        {tabsLocalization[tabKey]}
      </Tab>
    );
  };

  const changeTab = (tab: QuartzType) => {
    setTab(tab);
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  const applyChanges = () => {
    const str = service.toString();
    if (str !== value && onChange) {
      onChange(str);
    }
    setRenderCount(renderCount + 1);
  };

  return (
    <Stack gap={4}>
      {hasTabs && genTabs(tab)}

      <div className={styles.root} style={{ outline: 'none' }} role='tabpanel' tabIndex={0}>
        {genContent()}
      </div>
    </Stack>
  );
};

export default ReQuartzCron;

import { FC, memo } from 'react';

import { Resizable } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Box } from '@mui/system';

import { ExecutorLogBlock } from '../../../widgets/ExecutorLogBlock';

export interface ExecutorLogBlockPageProps {}

export const ExecutorLogBlockPage: FC<ExecutorLogBlockPageProps> = memo(() => {
  const { has } = useSearchParamsTemplate();

  return has(SearchParams.ShowExecutorLogBlock) ? (
    <Box position='absolute' top={0} right={0} height='100%'>
      <Resizable maxWidth={window.innerWidth - 260} initialWidth={833}>
        <ExecutorLogBlock />
      </Resizable>
    </Box>
  ) : null;
});

ExecutorLogBlockPage.displayName = 'ExecutorLogBlockPage';

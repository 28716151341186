import React, { PropsWithChildren } from "react";

import _ from "lodash";
import cn from "classnames";
import { container } from "tsyringe";
import { panelTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";

import { Toolbar } from "./Toolbar";

import styles from "./WithHeader.module.scss";

const dashboardService = container.resolve(DashboardService);

interface WithHeaderProps {
  id: string;
}
export function WithHeader ({
  id,
  children,
}: PropsWithChildren<WithHeaderProps>) {
  const dashboard = dashboardService.selectedDashboard
  const data = _.get(dashboard, `data.panels.${id}`, {
    type: '',
    name: `Новая ${panelTitles.base}`,
  });

  return (
    <>
      <div className={cn("grid-item__draggable_handle dotted", styles.handle)} />
      <div className={cn("grid-item__title", styles.title)}>
        <Toolbar dashboardPanelId={id} title={data.name} />
      </div>
      <div className={styles.body}>
        {children}
      </div>
    </>
  )
}

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { normalize } from '@/shared/lib/normalize';
import _ from 'lodash';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { NotificationRequest, NotificationResponse } from '../model/types';
import { NotificationStore } from '../model/NotificationStore';

@singleton()
export class NotificationService {
  constructor(private apiService: ApiService, private notificationStore: NotificationStore) {
    makeAutoObservable(this);
  }

  async getNotifications(notificationRequest: NotificationRequest) {
    const response = await this.apiService.instance.post<NotificationResponse>(
      '/editor/notification/search',
      notificationRequest
    );

    const normalizedNotifications = normalize(response.data.notificationPage.content, 'id');

    runInAction(() => {
      if (this.notificationStore.notifications) {
        this.notificationStore.notifications.ids = _.uniq([
          ...this.notificationStore.notifications.ids,
          ...normalizedNotifications.ids,
        ]);

        this.notificationStore.notifications.entities = {
          ...this.notificationStore.notifications.entities,
          ...normalizedNotifications.entities,
        };
      } else {
        this.notificationStore.notifications = normalizedNotifications;
      }

      this.notificationStore.totalPages = response.data.notificationPage.totalPages;
    });

    return response.data.notificationPage;
  }
}

import { FC, memo, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';

import { UpsertExternalAppService } from '../api/UpsertExternalAppService';

const upsertExternalAppService = container.resolve(UpsertExternalAppService);

export interface UpdateExternalAppButtonProps {
  externalAppId: string;
}

export const UpdateExternalAppButton: FC<UpdateExternalAppButtonProps> = memo(
  ({ externalAppId }) => {
    const handleClick = useCallback(() => {
      upsertExternalAppService.open = true;
      upsertExternalAppService.selectedId = externalAppId;
    }, [externalAppId]);

    return (
      <Tooltip title='Редактировать внешний сервис' placement='top'>
        <Box>
          <Button
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <SettingsIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

UpdateExternalAppButton.displayName = 'UpdateExternalAppButton';

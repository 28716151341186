import * as React from 'react';
import { FC, memo, useState } from 'react';

import { DatePicker, DatePickerProps, ConfigProvider } from 'antd';
import { Stack } from '@mui/material';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import { RANGE_PICKER_PRESETS } from '@/shared/lib/constants';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';

import styles from './styles.module.scss';

const { RangePicker: AntRangePicker } = DatePicker;

const showTime = { format: 'HH:mm', showSecond: false };

export const RangePicker: FC<DatePickerProps> = memo(({ ...props }) => {
  const designTokens = {
    zIndexPopup: 1500,
    colorBgContainer: 'var(--input-color)',
    colorBorder: 'var(--input-color)',
    hoverBorderColor: 'var(--input-color)',
    activeShadow: 'none',
    paddingBlockLG: 0,
    cellHeight: 32,
    cellWidth: 40,
    cellActiveWithRangeBg: 'var(--primary-color)',
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          DatePicker: designTokens,
        },
      }}
    >
      <AntRangePicker
        picker='date'
        showTime={showTime}
        format='DD.MM.YYYY HH:mm'
        allowClear={{
          clearIcon: (
            <Button circle variant={ButtonVariants.Outlined} size={ButtonSizes.Small}>
              <Stack width={10} height={10} alignItems='center' justifyContent='center'>
                <CrossIcon />
              </Stack>
            </Button>
          ),
        }}
        presets={RANGE_PICKER_PRESETS}
        {...props}
        separator={<SyncAltIcon />}
        placeholder={['От', 'До']}
        size='large'
        className={styles.input}
        popupClassName={styles.popup}
        suffixIcon={null}
      />
    </ConfigProvider>
  );
});

RangePicker.displayName = 'RangePicker';

import { FC, MouseEventHandler, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { ExportTestProps } from '@/entities/Block/containers/BlockTesting/features/ExportTest';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { container } from 'tsyringe';
import { BlockTestingService } from '@/entities/Block/services/BlockTestingService';
import { observer } from 'mobx-react-lite';
import Stack from '@mui/system/Stack';
import { cutText } from '@/shared/lib/cutText';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';

const blockTestingService = container.resolve(BlockTestingService);

export const DeleteTest: FC<ExportTestProps> = observer(({ testId }) => {
  const { set } = useSearchParamsTemplate();

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.stopPropagation();
      set([{ [SearchParams.TestId]: testId }, SearchParams.ShowBlockTestingDeleteModal]);
    },
    [set, testId]
  );

  return (
    <Tooltip title='Удалить тест' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});
export const DeleteTestModal: FC = observer(() => {
  const { remove, get, has } = useSearchParamsTemplate();

  const testId = get(SearchParams.TestId);
  const isOpenDeleteModal = has(SearchParams.ShowBlockTestingDeleteModal);

  const handleDialogClose = () => {
    remove([SearchParams.ShowBlockTestingDeleteModal]);
  };

  const handleDialogOk = useCallback(() => {
    if (testId) {
      blockTestingService.deleteTest(testId).then(() => {
        if (!blockTestingService.isError) {
          remove([SearchParams.ShowBlockTestingDeleteModal, SearchParams.ShowTestForm]);
        }
      });
    }
  }, [remove, testId]);

  return isOpenDeleteModal ? (
    <Dialog fullWidth open onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>Удалить тест</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <p>
            Уверены что хотите удалить &quot;
            {cutText(
              blockTestingService.tests.entities[testId || '']?.name || 'Название отсутсвует',
              20
            )}
            &quot; ?
          </p>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button
          variant={ButtonVariants.Popup}
          loading={blockTestingService.isLoadingDeleteTest}
          onClick={handleDialogOk}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});

import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { PythonBeanStore, PythonScript } from '@/entities/PythonBean';

@singleton()
export class DeletePythonBeanService {
  isLoading: boolean;
  showDialog: boolean;

  constructor(private apiService: ApiService, private pythonBeanStore: PythonBeanStore) {
    this.isLoading = false;
    this.showDialog = false;

    makeAutoObservable(this);
  }

  async deletePythonBean(pythonScriptId: string) {
    this.isLoading = true;

    try {
      await this.apiService.instance.delete<PythonScript>(`/editor/pythonScript/${pythonScriptId}`);

      runInAction(() => {
        this.pythonBeanStore.scripts!.ids.splice(
          this.pythonBeanStore.scripts!.ids.indexOf(pythonScriptId),
          1
        );
        delete this.pythonBeanStore.scripts!.entities[pythonScriptId];
      });
    } catch (error) {
      notify.error(`Не удалось удалить скрипт`);

      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { FC, memo } from 'react';

import { CardWithActions, CardWithActionsProps } from '@/shared/ui';

export interface SettingsUserAuthCardProps extends Omit<CardWithActionsProps, 'id'> {}

export const SettingsUserAuthCard: FC<SettingsUserAuthCardProps> = memo(({ title, ...props }) => {
  return <CardWithActions {...props} title={title || 'Название отсутствует'} />;
});

SettingsUserAuthCard.displayName = 'NotificationCard';

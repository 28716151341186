/**
 * Проверка объекта на переданный в функцию тип
 * @param object объект для проверки
 * @param properties свойство по которому происходит проверка типа
 */
export const isType = <T>(object: unknown, properties: keyof T): object is T => {
  if (object) {
    if (Array.isArray(object) && object.length) {
      return properties in object[0];
    }

    return typeof object === 'object' && properties in object;
  }

  return false;
};

import { FC, memo } from 'react';

import { TreeNodeProps } from 'rc-tree/es/interface';
import { ArrowDropDown } from '@mui/icons-material';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface SwitcherIconProps extends TreeNodeProps {}

export const SwitcherIcon: FC<SwitcherIconProps> = memo(({ isLeaf, expanded }) => {
  if (isLeaf) {
    return null;
  }

  return <ArrowDropDown className={cn(styles.root, { [styles.expanded]: expanded })} />;
});

SwitcherIcon.displayName = 'SwitcherIcon';

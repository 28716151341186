import * as React from "react";

import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Placement, Step } from 'react-joyride';
import { data } from '@/entities/Onboarding/utils/mock_data';


type TStyles = {
  buttonNext: {
    display: string;
  };
  spotlight?: {
    borderRadius: string;
  };
};

interface IStep extends Step {
  styles: TStyles;
}

@singleton()
export class SaveChangesStore {
  steps: Step[];
  currentStep: Step | null;
  target: string | HTMLElement;
  content: React.ReactNode;
  placement: Placement | 'auto' | 'center';
  disableBeacon: boolean;
  styles: TStyles;
  title: React.ReactNode;
  spotlightClicks: boolean;
  disableScrolling: boolean;
  spotlightPadding: number;

  getCurrentStep(): Step {
    return {
      target: this.target,
      content: this.content,
      placement: this.placement,
      disableBeacon: this.disableBeacon,
      styles: this.styles,
      title: this.title,
      spotlightClicks: this.spotlightClicks,
      disableScrolling: this.disableScrolling,
      spotlightPadding: this.spotlightPadding
    };
  }

  updateStep(newData: Partial<IStep>) {
    if (newData) {
      this.target = newData.target || this.target;
      this.content = newData.content || this.content;
      this.placement = newData.placement || this.placement;
      this.disableBeacon = newData.disableBeacon !== undefined ? newData.disableBeacon : this.disableBeacon;
      this.styles = newData.styles || this.styles;
      this.title = newData.title || this.title;
      this.spotlightClicks = newData.spotlightClicks !== undefined ? newData.spotlightClicks : this.spotlightClicks;
      this.disableScrolling = newData.disableScrolling !== undefined ? newData.disableScrolling : this.disableScrolling;
      this.spotlightPadding = newData.spotlightPadding !== undefined ? newData.spotlightPadding : this.spotlightPadding;
    }
  }

  constructor() {
    this.steps = data;
    this.currentStep = null;
    this.target = '';
    this.content = '';
    this.placement = 'auto';
    this.disableBeacon = true;
    this.styles = {
      buttonNext: {
        display: 'none',
      },
    };
    this.title = '';
    this.spotlightClicks = true;
    this.disableScrolling = true;
    this.spotlightPadding = 0;
    makeAutoObservable(this);
  }
}

import { FC, useCallback } from 'react';

import { observer, Observer } from 'mobx-react-lite';
import { Link, useSearchParams } from 'react-router-dom';
import { ActionPanel, CardWithActions, ListWithInfiniteScroll } from '@/shared/ui';
import { container } from 'tsyringe';
import { PythonBeanService, PythonBeanStore } from '@/entities/PythonBean';
import Stack from '@mui/system/Stack';
import { EditPythonBeanButton, UpsertPythonBeanService } from '@/features/UpsertPythonBean';
import Typography from '@mui/material/Typography';
import { DeletePythonBeanButton } from '@/features/DeletePythonBean';
import { SearchParams } from '@/hooks/useTemplateSearchParams';

export interface PythonBeanListProps {
  pythonScriptId?: string;
}

const pythonBeanStore = container.resolve(PythonBeanStore);
const pythonBeanService = container.resolve(PythonBeanService);
const upsertPythonBeanService = container.resolve(UpsertPythonBeanService);

export const PythonBeanList: FC<PythonBeanListProps> = observer(({ pythonScriptId }) => {
  const [searchParams] = useSearchParams();

  const handleListFetchNextElement = useCallback(
    (page: number) => pythonBeanService.getPythonScripts({ pagination: { page, size: 10 } }),
    []
  );

  const getSearchParams = useCallback(
    (id: string) => {
      searchParams.set(SearchParams.PythonScriptId, id);
      return searchParams.toString();
    },
    [searchParams]
  );

  const handleLinkClick = () => {
    upsertPythonBeanService.showForm = true;
  };

  return (
    <ListWithInfiniteScroll
      animated
      elements={pythonBeanStore.scripts?.ids || []}
      fetchNextElement={handleListFetchNextElement}
      lastPage={pythonBeanStore.lastPage}
      activeElementKey={pythonScriptId}
      stopAnimatedForKey={pythonScriptId}
      loading={pythonBeanService.isLoading}
      renderElement={(id) => (
        <Observer key={id}>
          {() => {
            return (
              <Link
                to={{ search: getSearchParams(id) }}
                title={pythonBeanStore.scripts?.entities[id].name}
                onClick={handleLinkClick}
              >
                <CardWithActions
                  active={pythonScriptId === id}
                  title={pythonBeanStore.scripts?.entities[id].name || 'Название отсутсвует'}
                  actions={
                    <ActionPanel small withoutBorder>
                      <EditPythonBeanButton />
                      <DeletePythonBeanButton />
                    </ActionPanel>
                  }
                >
                  <Stack>
                    <Typography variant={'description' as any}>
                      {pythonBeanStore.scripts?.entities[id].description}
                    </Typography>
                  </Stack>
                </CardWithActions>
              </Link>
            );
          }}
        </Observer>
      )}
    />
  );
});

PythonBeanList.displayName = 'PythonBeanList';

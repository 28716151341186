import React, { FC } from 'react';

import { PageTitle } from '@/shared/ui/PageTitle';
import { Paper } from '@/shared/ui/Paper';
import Stack from '@mui/material/Stack';

import { TracingTableContainer } from './containers/TracingTableContainer';

import styles from './Tracing.module.scss';
import {MessageTracingPage} from "@/pages";

export const Tracing: FC = () => {
  return (
    <Stack className={styles.wrapper}>
      {/*<PageTitle title='Трассировка пакетов' />*/}
      <MessageTracingPage />
      <Paper className={styles.paper}>
        <TracingTableContainer />
      </Paper>
    </Stack>
  );
};

import { RoutePaths } from '@/shared/lib/types';

import { RoutePathsWithoutMain } from './types';

export const routePathsDictionary: { [key in RoutePathsWithoutMain]: string } = {
  [RoutePaths.Flow]: 'Потоки',
  [RoutePaths.FlowVersions]: 'Версии потоков',
  [RoutePaths.Tracing]: 'Трассировка пакетов',
  [RoutePaths.Dashboard]: 'Панель управления',
  [RoutePaths.Logs]: 'Лог приложения',
  [RoutePaths.Properties]: 'Переменные',
  [RoutePaths.Settings]: 'Настройки',
};

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { Switch } from '@/shared/ui/Switch/Switch';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { container } from 'tsyringe';

import { SetVersionService } from '../api/SetVersionService';

export interface SetVersionDialogProps {
  versionId: string;

  onSuccess?(): void;
}

const setVersionService = container.resolve(SetVersionService);

export const SetVersionDialog: FC<SetVersionDialogProps> = observer(({ versionId, onSuccess }) => {
  const [checked, setChecked] = useState(false);

  const handleClose = useCallback(() => {
    setVersionService.showDialog = false;
  }, []);

  const handleSwitchClick = useCallback(() => {
    setChecked((prevState) => !prevState);
  }, []);

  const handleOkButtonClick = useCallback(() => {
    setVersionService.setVersion(versionId, checked).then(() => {
      setVersionService.showDialog = false;
      onSuccess?.();
    });
  }, [checked, onSuccess, versionId]);

  return (
    <Dialog fullWidth open={setVersionService.showDialog} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Установка версии потока</DialogTitle>
      <DialogContent>
        <Stack gap={4}>
          <label>
            <Stack direction='row' gap={1.5} alignItems='center'>
              <Switch checked={checked} onClick={handleSwitchClick} />
              <Typography className='cursor-pointer' fontWeight={600}>
                Установить поток, как клон текущего потока
              </Typography>
            </Stack>
          </label>
          <Typography>Вы точно хотите установить версию потока?</Typography>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
          Отмена
        </Button>
        <Button
          variant={ButtonVariants.Popup}
          loading={setVersionService.isLoading}
          onClick={handleOkButtonClick}
        >
          Установить
        </Button>
      </DialogActions>
    </Dialog>
  );
});

SetVersionDialog.displayName = 'SetVersionDialog';

import React, { PropsWithChildren } from 'react';

import { DashboardPanel } from '../DashboardPanel/DashboardPanel';

import styles from './GridItem.module.scss'

export interface GridItemProps {
  i: string;
}

export function GridItem ({
  i,
  children,
  ...props
}: PropsWithChildren<GridItemProps>) {
  return (
    <div className={styles.root} {...props}>
      <DashboardPanel dashboardPanelId={i} />
      {children}
    </div>
  );
}
import { FC, memo, useMemo } from 'react';

import { useWatch } from 'react-hook-form';
import Typography from '@mui/material/Typography';

import styles from './Summary.module.scss';

export interface SummaryProps {
  control: any;
  name: string;
}

export const Summary: FC<SummaryProps> = memo(({ control, name }) => {
  const targetName = useWatch({ control, name: `${name}.targetName` });
  const sourceName = useWatch({ control, name: `${name}.sourceName` });
  const type = useWatch({ control, name: `${name}.type` });

  const title = useMemo(() => {
    if (!targetName) {
      return 'Название отсутствует';
    }

    return `${targetName} = ${sourceName} `;
  }, [sourceName, targetName]);

  return (
    <div className={styles.root}>
      <Typography noWrap className={styles.title}>
        {title}
      </Typography>
      <Typography noWrap className={styles.type}>
        {type}
      </Typography>
    </div>
  );
});

Summary.displayName = 'Summary';

import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { normalize } from '@/shared/lib/normalize';
import { RegistrableValues } from '@/shared/lib/types';

import { Properties } from '../model/types';
import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { PropertiesStore } from '../model/PropertiesStore';

@injectable()
export class PropertyService {
  constructor(
    @inject(RegistrableValues.PropertyId) private propertyId: string,
    private propertiesStore: PropertiesStore,
    private apiService: ApiService
  ) {
    makeAutoObservable(this);
  }

  get property() {
    return this.propertiesStore.properties?.entities[this.propertyId];
  }

  get isLoadingEditProperty() {
    return this.propertiesStore.isLoadingEditProperty;
  }

  async updateProperty(data: Properties) {
    this.propertiesStore.isLoadingEditProperty = true;

    try {
      const response = await this.apiService.instance.put('/editor/properties', data);

      if (this.propertiesStore.properties) {
        this.propertiesStore.properties.entities[this.propertyId] = response.data;
      }
    } catch (error) {
      notify.error('Не удалось изменить конфигурацию');
      throw error;
    } finally {
      this.propertiesStore.isLoadingEditProperty = false;
    }
  }

  async createProperty(data: Properties) {
    this.propertiesStore.isLoadingCreateProperty = true;

    try {
      const response = await this.apiService.instance.put<Properties>('/editor/properties', data);

      if (this.propertiesStore.properties) {
        this.propertiesStore.properties.ids.unshift(response.data.id);
        this.propertiesStore.properties.entities[response.data.id] = response.data;
      } else {
        this.propertiesStore.properties = normalize([response.data], 'id');
      }
    } catch (error) {
      notify.error('Не удалось создать конфигурацию');
      throw error;
    } finally {
      this.propertiesStore.isLoadingCreateProperty = false;
    }
  }
}

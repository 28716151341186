import {FC, useEffect} from 'react';

import {observer} from 'mobx-react-lite';
import {container} from "tsyringe";
import {useParams} from "react-router-dom";
import {FlowUpdaterService} from "@/entities/Flow/services/FlowUpdaterService";
import styles from './DiffFlowContainer.module.scss';
import {DiffCodeEditor} from "@/shared/ui/DiffCodeEditor/DiffCodeEditor";

export const DiffFlowContainer: FC = observer(() => {
    const {flowId} = useParams();
    const flowUpdaterService = container.resolve(FlowUpdaterService);

    useEffect(() => {
        flowUpdaterService.getDiffFlow(flowId)
    }, []);

    const renderString = (str: any | boolean | undefined): any => {
        if (str === null) {
            return "null"
        }
        if (str === undefined) {
            return "null"
        }
        if (str === true) {
            return "true"
        }
        if (str === false) {
            return "false"
        }
        if (str === "") {
            return "\"\""
        }
        return str;
    }

    const renderName = (name: any) => {
        if (name) {
            return `${name}: `
        } else {
            return;
        }
    }

    const renderDiffString = (diff: any) => {
        let result = []
        if (diff.before !== null && diff.after !== null) {
            result.push(<div>
                {renderName(diff.name)}<span className={styles.beforeText}>{renderString(diff.before)}</span> <span
                className={styles.arrow}>→</span> <span className={styles.afterText}>{renderString(diff.after)}</span>
            </div>)
        } else {
            if (diff.before === null && diff.after !== null) {
                result.push(<div>
                    {renderName(diff.name)}<span className={styles.afterText}>{renderString(diff.after)}</span>
                </div>)
            } else {
                if (diff.before !== null && diff.after === null) {
                    result.push(<div>
                        {renderName(diff.name)}<span className={styles.beforeText}>{renderString(diff.before)}</span>
                    </div>)
                }
            }
        }
        return result
    }

    const renderDiffCode = (diff: any) => {
        let result = []
        if (diff.before !== null && diff.after !== null) {
            result.push(<div>
                <div>{renderName(diff.name)}</div>
                <DiffCodeEditor
                    title={diff.name}
                    original={diff.before}
                    value={diff.after}
                    defaultLanguage={diff.props?.language}
                    hideFormatSelect={diff.props?.hideFormatSelect}
                    hideLanguageSelect={diff.props?.hideLanguageSelect}
                    gapHeader={diff.props?.gapHeader}
                    readonly
                />
            </div>)
        } else {
            if (diff.before === null && diff.after !== null) {
                result.push(<div>
                    {renderName(diff.name)}<span className={styles.afterText}>{renderString(diff.after)}</span>
                </div>)
            } else {
                if (diff.before !== null && diff.after === null) {
                    result.push(<div>
                        {renderName(diff.name)}<span className={styles.beforeText}>{renderString(diff.before)}</span>
                    </div>)
                }
            }
        }
        return result
    }

    const renderDiff = (diff: any) => {
        switch (diff.type) {
            case "Code":
                return renderDiffCode(diff)
            default:
                return renderDiffString(diff)
        }
    }

    const renderDiffElement = (diffElement: any) => {
        if (diffElement == null) {
            return;
        }
        let result = []
        if (diffElement.elementName != null) {
            result.push(<div
                className={styles.elementName}
            >
                <h4>{renderString(diffElement.elementName)}</h4>
            </div>)
        }
        if (diffElement.diffList) {
            diffElement.diffList.forEach((diff: any) => {
                result.push(<div className={styles.diffList}>
                    {renderDiff(diff)}
                </div>)
            })
        }
        if (diffElement.diffElementList) {
            diffElement.diffElementList.forEach((diffEl: any) => {
                result.push(<div className={styles.elementDiffList}>
                    {renderDiffElement(diffEl)}
                </div>)
            })
        }
        return result
    }

    if (flowUpdaterService.diffFlow) {
        return (
            <div>
                <div className={styles.root}>
                    {renderDiffElement(flowUpdaterService.diffFlow)}
                </div>
            </div>
        );
    } else {
        return (
            <>
            </>
        )
    }

});

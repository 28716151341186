import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { SettingsUserAuthTypes } from '@/entities/SettingsUserAuth';
import { notify } from '@/shared/ui/Toast/notify';

import { CreateSettingsUserAuthService } from '../api/CreateSettingsUserAuthService';

const createSettingsUserAuthService = container.resolve(CreateSettingsUserAuthService);

export interface CreateSettingsUserAuthDialogProps {}

export const CreateSettingsUserAuthDialog: FC<CreateSettingsUserAuthDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState<SettingsUserAuthTypes>();

  const handleClose = useCallback(() => {
    createSettingsUserAuthService.open = false;
  }, []);

  const handleOk = useCallback(
    (type: SettingsUserAuthTypes) => async () => {
      const entityName =
        type === SettingsUserAuthTypes.SettingsBasic ? 'Basic хранилище' : 'Keycloack хранилище';

      setSelectedType(type);

      try {
        setIsLoading(true);

        await createSettingsUserAuthService.createSettingsUserAuth(type);

        notify.success(`${entityName} добавлено`);

        handleClose();
      } catch {
        notify.error(`Не удалось добавить ${entityName}`);
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose]
  );

  return (
    <Dialog fullWidth open={createSettingsUserAuthService.open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Добавить хранилище</DialogTitle>
      <DialogContent>Выберите тип хранилища</DialogContent>
      <DialogActions>
        <Button
          variant={ButtonVariants.Secondary}
          loading={isLoading && SettingsUserAuthTypes.SettingsBasic === selectedType}
          onClick={handleOk(SettingsUserAuthTypes.SettingsBasic)}
        >
          Basic хранилище
        </Button>
        <Button
          variant={ButtonVariants.Secondary}
          loading={isLoading && SettingsUserAuthTypes.SettingsIdm === selectedType}
          onClick={handleOk(SettingsUserAuthTypes.SettingsIdm)}
        >
          Keycloack хранилище
        </Button>
      </DialogActions>
    </Dialog>
  );
});

CreateSettingsUserAuthDialog.displayName = 'CreateSettingsUserAuthDialog';

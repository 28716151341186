import React, { FC, useCallback, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { isEmpty } from 'lodash';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import ArticleIcon from '@mui/icons-material/Article';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';

import './PythonMonitoringPods.scss';
import { PythonInstanceRequest } from '@/entities/PythonLib/stores/types';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { PythonLibsSortPods } from '@/entities/PythonLib/features/actions/PythonLibsSortPods/PythonLibsSortPods';
import { options } from '@/entities/PythonLib/utils/PythonLibConst';

export interface PythonMonitoringPodsProps {}

enum EStatusPod {
  ready = 'ready',
  not_ready = 'not ready',
  broken = 'broken',
  refreshing = 'refreshing',
}

enum EStatusPodColor {
  green = 'ready',
  yellow = 'not ready',
  red = 'broken',
  gray = 'refreshing',
}

const EStatusPodTitle: { [key: string]: string } = {
  [EStatusPod.not_ready]: 'Не готов',
  [EStatusPod.ready]: 'Готов',
  [EStatusPod.broken]: 'Сломан',
  [EStatusPod.refreshing]: 'Обновляется',
};

const EStatusPodTitleColor: { [key: string]: string } = {
  [EStatusPodColor.yellow]: 'yellow',
  [EStatusPodColor.green]: 'green',
  [EStatusPodColor.red]: 'red',
  [EStatusPodColor.gray]: 'gray',
};

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);

export const PythonMonitoringPods: FC<PythonMonitoringPodsProps> = observer(() => {
  const podsInfo = JSON.parse(JSON.stringify(pythonLibsStore.pods))?.data;
  const { set, remove } = useSearchParamsTemplate();
  const date = (date: string) => !isEmpty(date) && dayjs(date).format('DD.MM.YYYY | HH:mm:ss.SSS ');

  const handlePOpenLog = useCallback(
    (id: string) => {
      set([SearchParams.ShowPythonPodLog, { [SearchParams.PythonPodsId]: id }]);
      remove([
        SearchParams.ShowEditPythonLibForm,
        SearchParams.ShowAddPythonLibForm,
        SearchParams.PythonLibName,
      ]);
    },

    [set, remove]
  );

  useEffect(() => {
    pythonLibsService.getMonitoringPods(options[0].key);
  }, []);

  return (
    <Stack flexDirection='column'>
      <Stack className='headerCards'>
        <Box component='h2' fontWeight={900} fontSize='2.5rem'>
          Мониторинг состояния Python
        </Box>
      </Stack>

      <div>
        <PythonLibsSortPods />
        {!isEmpty(podsInfo) ? (
          <Stack flexDirection='row'>
            <Stack className='overflow-y-auto overflow-x-hidden' gap={2.5}>
              <div className='cards'>
                {podsInfo?.map((pod: PythonInstanceRequest, index: number) => (
                  <div className='card' key={`${pod.executorId}pod`}>
                    <p>
                      <span className='reading'>
                        <strong>Id:</strong> <span> {pod.executorId}</span>
                      </span>
                    </p>
                    <p>
                      <span className='reading'>
                        <strong>Дата создания: </strong> <span> {date(pod.startDate)}</span>
                      </span>
                    </p>
                    <p>
                      <span className='reading'>
                        <strong>Дата изменения состояния:</strong>{' '}
                        <span>{date(pod.timestampChangeStatus)}</span>
                      </span>
                    </p>
                    <p>
                      <span className='reading'>
                        <strong>Текущий статус:</strong>{' '}
                        <span className={`reading--${EStatusPodTitleColor[pod.status]}`}>
                          {EStatusPodTitle[pod.status]}
                        </span>
                        {!isEmpty(podsInfo[index].errorLog) && (
                          <span>
                            <Box height='max-content' className='buttonContainer'>
                              <Button
                                tabIndex={1}
                                className='buttonLog'
                                size={ButtonSizes.Small}
                                variant={ButtonVariants.Outlined}
                                onClick={() => handlePOpenLog(pod.executorId)}
                              >
                                <ArticleIcon className='icon' /> Посмотреть лог
                              </Button>
                            </Box>
                          </span>
                        )}
                      </span>
                    </p>
                    <p>
                      <span className='reading'>
                        <strong>Последний отклик:</strong> <span> {date(pod.timestampPing)}</span>
                      </span>
                    </p>
                    <p>
                      <span className='reading'>
                        <strong>Установленные библиотеки:</strong>
                      </span>

                      <div className='buttonContainer'>
                        {pod.installedUserLibraries.map((lib) => (
                          <div className='arm' key={`${pod.executorId}lib`}>
                            <p className='armText'>{lib.title}</p>
                            <p className='armText'>{lib.version}</p>
                          </div>
                        ))}
                      </div>
                    </p>
                  </div>
                ))}
              </div>
            </Stack>
          </Stack>
        ) : null}
      </div>
    </Stack>
  );
});

PythonMonitoringPods.displayName = 'PythonMonitoringPods';

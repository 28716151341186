import React, { forwardRef } from 'react';

import { IMonitoringItem } from "@/hooks/tracing/types";

import { Table } from '../table/Table';
import { tracingTableDescriptor } from './tracingTableDescriptor';
import { gridTable } from '../table/gridTable';

interface IProps {
  isLoading: boolean;
  data: IMonitoringItem[];
}

export const TracingTable = forwardRef<any, IProps>(({ isLoading, data }, ref) => {
  return (
    <Table<IMonitoringItem>
      setItemRef={ref}
      isLoading={isLoading}
      components={gridTable}
      data={data}
      descriptor={tracingTableDescriptor}
    />
  );
});

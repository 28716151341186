/* eslint-disable no-useless-escape */
import ObjectID from 'bson-objectid';

import { getType } from './getType';

export function parseMapData(objInputData: any, path: any): any {
  let newMapData;
  if (Array.isArray(objInputData)) {
    newMapData = {};
    if (objInputData.length > 0) {
      let arrayMapData = [];
      for (let i = 0; i < objInputData.length; i++) {
        if (Array.isArray(objInputData[i])) {
          arrayMapData.push({
            id: ObjectID().toString(),
            type: getType(objInputData[i]),
            status: 'OK',
            arrayElement: parseMapData(objInputData[i], `${path}`),
            emptyRule: 'toNull',
          });
        } else {
          if (typeof objInputData[i] === 'object') {
            arrayMapData.push({
              id: ObjectID().toString(),
              type: getType(objInputData[i]),
              status: 'OK',
              children: parseMapData(objInputData[i], `${path}`),
              emptyRule: 'toNull',
            });
          } else {
            arrayMapData.push({
              id: ObjectID().toString(),
              type: getType(objInputData[i]),
              status: 'OK',
              emptyRule: 'toNull',
            });
          }
        }
      }
      return arrayMapData[0];
    }
  } else {
    newMapData = [];
    if (typeof objInputData === 'object') {
      for (let name in objInputData) {
        if (Array.isArray(objInputData[name])) {
          newMapData.push({
            id: ObjectID().toString(),
            sourceName: `\$\{${path}${path.length > 0 ? '.' : ''}${name}\}`,
            targetName: name,
            type: getType(objInputData[name]),
            status: 'OK',
            arrayElement: parseMapData(
              objInputData[name],
              `${path}${path.length > 0 ? '.' : ''}${name}`
            ),
            emptyRule: 'toNull',
          });
        } else {
          if (typeof objInputData[name] === 'object') {
            newMapData.push({
              id: ObjectID().toString(),
              sourceName: `\$\{${path}${path.length > 0 ? '.' : ''}${name}\}`,
              targetName: name,
              type: getType(objInputData[name]),
              status: 'OK',
              children: parseMapData(
                objInputData[name],
                `${path}${path.length > 0 ? '.' : ''}${name}`
              ),
              emptyRule: 'toNull',
            });
          } else {
            newMapData.push({
              id: ObjectID().toString(),
              sourceName: `\$\{${path}${path.length > 0 ? '.' : ''}${name}\}`,
              targetName: name,
              type: getType(objInputData[name]),
              status: 'OK',
              emptyRule: 'toNull',
            });
          }
        }
      }
    }
  }
  return newMapData;
}

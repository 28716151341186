import * as React from 'react';
import { FC, MouseEventHandler, useCallback, useState } from 'react';

import { ButtonWithModal } from '@/shared/ui/ButtonWithModal/ButtonWithModal';
import { observer, Observer } from 'mobx-react-lite';
import { SortDownIcon } from '@/shared/ui/Icons/SortDownIcon/SortDownIcon';
import { Stack } from '@mui/system';
import cn from 'classnames';
import { Option } from '@/entities/Flow/features/FlowSort/types';
import { container } from 'tsyringe';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { Menu, MenuItem } from '@/shared/ui';
import { Typography } from '@mui/material';

import { OPTIONS } from './constants';

import styles from './FlowSort.module.scss';

export interface FlowSortProps {}

const flowService = container.resolve(FlowService);

export const FlowSort: FC<FlowSortProps> = observer(() => {
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

  const handleButtonClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.stopPropagation();
    setAnchor(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(
    (close: () => void) => () => {
      close();
    },
    []
  );

  const handleItemClick = useCallback(
    (option: Option, close: () => void): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        flowService.setSorting(option.property, option.direction);
        flowService.resetFlows();

        flowService.getFlows(0).then(() => {
          close();
        });
      },
    []
  );

  const handleResetClick = useCallback(
    (close: () => void): MouseEventHandler =>
      (event) => {
        event.stopPropagation();
        flowService.resetFlows();
        flowService.resetSorting();

        flowService.getFlows(0).then(() => {
          close();
        });
      },
    []
  );

  return (
    <ButtonWithModal
      title='Сортировать'
      icon={<SortDownIcon transform='scale(1.5)' width={12} height={12} />}
      isActive={Boolean(flowService.sorting)}
      onClick={handleButtonClick}
      render={({ isOpen, close }) => (
        <Observer>
          {() => (
            <Menu
              keepMounted={false}
              anchorEl={anchor}
              open={isOpen}
              onClose={handleMenuClose(close)}
            >
              {OPTIONS.map((option) => {
                const key = `${option.property}${option.direction}`;
                const selectedPropertyAndDirection =
                  flowService.sorting &&
                  `${flowService.sorting.name}${flowService.sorting.direction}`;

                return (
                  <MenuItem
                    className={cn({
                      [styles.active]: key === selectedPropertyAndDirection,
                    })}
                    selected={key === selectedPropertyAndDirection}
                    key={key}
                    onClick={handleItemClick(option, close)}
                  >
                    <Stack direction='row' justifyContent='space-between' width='100%'>
                      <p>{option.label}</p>
                    </Stack>
                  </MenuItem>
                );
              })}
              <MenuItem focusRipple={false} onClick={handleResetClick(close)}>
                <Typography>Сбросить сортировку</Typography>
              </MenuItem>
            </Menu>
          )}
        </Observer>
      )}
    />
  );
});

FlowSort.displayName = 'FlowSort';

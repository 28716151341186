import { FC, useCallback } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';

const joyrideService = container.resolve(JoyRideService);

export const OpenOnboardingModal: FC = observer(() => {
  const handleOnboardingOpen = () => {
    joyrideService.handleOpen(false);
    joyrideService.handleJoyRide(true);
  };

  const handleOnboardingClose = useCallback(() => {
    joyrideService.isOpen = false;
  }, []);

  const handleOnboardingNext = useCallback(() => {}, []);

  return joyrideService.isOpen ? (
    <Dialog fullWidth open={joyrideService.isOpen} onClose={handleOnboardingClose}>
      <DialogTitle onClose={handleOnboardingClose}>Открытие онбординга</DialogTitle>
      <DialogContent>Вы хотите открыть обучение с сохраненного шага или с начала?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleOnboardingNext}>
          С сохраненного шага
        </Button>
        <Button variant={ButtonVariants.Popup} onClick={handleOnboardingOpen}>
          С начала
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});

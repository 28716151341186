import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { SubmitHandler, useForm } from 'react-hook-form';
import { container } from 'tsyringe';
import { AnimatePresence, motion } from 'framer-motion';
import { Stack } from '@mui/system';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { BlockExecutionResult } from '@/entities/Block/containers/entities';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { TestResultTable } from '@/entities/Block/containers/BlockTesting/entities/TestResultTable/TestResultTable';
import _ from 'lodash';
import Grid from '@mui/system/Unstable_Grid';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { BlockTestingService } from '../../../../services/BlockTestingService';
import { BlockTestingForm } from '../../features';
import { IntegrationTest } from '../../../../types';

import styles from './BlockTestingContent.module.scss';

const blockTestingService = container.resolve(BlockTestingService);

export const BlockTestingContent: FC = observer(() => {
  const { get, has, remove } = useSearchParamsTemplate();

  const [isShowForm, setIsShowForm] = useState<boolean>();

  const isShowTest = has(SearchParams.ShowTestForm);
  const testId = get(SearchParams.TestId);

  const {
    register,
    control,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm({
    values: blockTestingService.tests.entities[testId || ''],
  });

  const ref = useRef<HTMLDivElement>(null);

  const onSubmit = useCallback<SubmitHandler<IntegrationTest>>(
    (data) => {
      blockTestingService.saveTest(data).then(() => {
        remove([SearchParams.ShowTestForm, SearchParams.TestId]);
      });
    },
    [remove]
  );

  const handleCancelButtonClick = useCallback(() => {
    remove([SearchParams.ShowTestForm, SearchParams.TestId]);
  }, [remove]);

  const handleShowFormButtonToggle = useCallback(() => {
    setIsShowForm((prevState) => !prevState);
    ref.current?.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    reset({ matcherList: [], ...blockTestingService.tests.entities[testId || ''] });
  }, [reset, isShowTest, testId]);

  useEffect(() => {
    if (testId) {
      void blockTestingService.getTestResultById(testId);
    }
  }, [testId]);

  useEffect(() => {
    setIsShowForm(!blockTestingService.testChecks[testId!]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockTestingService.testChecks[testId!]]);

  if (blockTestingService.isLoadingTestResult) {
    return (
      <Stack width='100%' height='100%' alignItems='center' justifyContent='center'>
        <LoaderIcon width={24} height={24} />
      </Stack>
    );
  }

  return (
    <AnimatePresence mode='wait'>
      {isShowTest ? (
        <motion.div
          variants={{
            open: { x: 0 },
            close: { x: '100%' },
          }}
          initial='close'
          animate='open'
          exit='close'
          key={testId}
          transition={{ ease: 'easeInOut' }}
          className={styles.root}
        >
          <Grid container>
            <Grid xs={3}>
              <Button
                doesUseInFormOrTable
                variant={ButtonVariants.Secondary}
                onClick={handleShowFormButtonToggle}
              >
                <Stack
                  padding='0 16px'
                  direction='row'
                  width='100%'
                  gap={2.5}
                  justifyContent='space-between'
                  alignItems='center'
                >
                  <p>Настройки теста</p>
                  {isShowForm ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                </Stack>
              </Button>
            </Grid>
          </Grid>

          <Grid ref={ref} marginTop={2.5} container overflow='auto' gap={4}>
            {isShowForm && (
              <Grid xs={12}>
                <BlockTestingForm register={register} control={control} errors={errors} />
              </Grid>
            )}
            {blockTestingService.testChecks[testId!] && (
              <Grid xs={12}>
                <BlockExecutionResult
                  value={blockTestingService.testChecks[testId!].body?.stringBody || ''}
                />
              </Grid>
            )}
            {testId && !_.isEmpty(blockTestingService.testChecks[testId]?.result) && (
              <TestResultTable testId={testId} />
            )}
          </Grid>

          <Grid marginTop={4} container justifyContent='flex-end' alignItems='flex-end' gap={1.5}>
            <Grid xs={2}>
              <Button variant={ButtonVariants.Secondary} onClick={handleCancelButtonClick}>
                Отмена
              </Button>
            </Grid>
            <Grid xs={2}>
              <Button
                loading={blockTestingService.isLoadingSaveTest}
                onClick={handleSubmit(onSubmit)}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
});

import { FC, useEffect } from 'react';

import { BlockProps } from '@/entities/Block/Block';
import { container } from 'tsyringe';

import { ConsumerInformationService } from '../api/ConsumerInformationService';

const consumerInformationService = container.resolve(ConsumerInformationService);

export const withConsumerInformation =
  (Component: FC<BlockProps>) =>
  ({ id, ...props }: BlockProps) => {
    useEffect(() => {
      consumerInformationService.getElementsByBlockId(id);
    }, [id]);

    return <Component id={id} {...props} />;
  };

import {makeAutoObservable, runInAction} from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

import { KeyValueStore } from '../model/KeyValueStore';
import {KeyValue, KeyValueSearchRequest, KeyValueSearchResponse, RequestFilter} from '../model/types';
import _ from "lodash";

@singleton()
export class KeyValueService {
  constructor(private apiService: ApiService, private keyValueStore: KeyValueStore) {
    makeAutoObservable(this);
  }

  async searchKeyValue(body: KeyValueSearchRequest) {
    if (this.keyValueStore.filterList == null) {
      return ;
    }
    if (!body.filterList) {
      body.filterList = this.keyValueStore.filterList
    }
    if (!body.pagination) {
      body.pagination = {
        page:0
      }
    }
    if (!body.pagination.size) {
      body.pagination.size = 100;
    }

    this.keyValueStore.isLoading = true
    const response = await this.apiService.instance.post<KeyValueSearchResponse>(
        '/editor/keyValue/search',
        body
    );

    const normalizedVersions = normalize(response.data.keyValuePage.content, 'id');

    runInAction(() => {
      if (this.keyValueStore.keyValues) {
        this.keyValueStore.keyValues.ids = _.uniq([
          ...this.keyValueStore.keyValues.ids,
          ...normalizedVersions.ids,
        ]);

        this.keyValueStore.keyValues.entities = {
          ...this.keyValueStore.keyValues.entities,
          ...normalizedVersions.entities,
        };
      } else {
        this.keyValueStore.keyValues = normalizedVersions;
      }

      this.keyValueStore.lastPage = response.data.keyValuePage.totalPages;
    });
    this.keyValueStore.isLoading = false

    return response.data;
  }

  setFilterList(filterList: RequestFilter[]) {
    this.keyValueStore.keyValues = null
    this.keyValueStore.filterList = filterList
    this.searchKeyValue({})
  }
}

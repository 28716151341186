import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { Box } from '@mui/system';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';

import { DeleteLicenseService } from '../api/DeleteLicenseService';

const deleteLicenseService = container.resolve(DeleteLicenseService);

export interface DeleteLicenseButtonProps {
  id: string;
}

export const DeleteLicenseButton: FC<DeleteLicenseButtonProps> = observer(({ id }) => {
  const handleClick = useCallback(() => {
    deleteLicenseService.open = true;
    deleteLicenseService.selectedLicenseId = id;
  }, [id]);

  return (
    <Tooltip title='Удалить лицензионный ключ' placement='top'>
      <Box>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

DeleteLicenseButton.displayName = 'EditLicenseButton';

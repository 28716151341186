import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { Control, Controller, UseFormRegister } from 'react-hook-form';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Grid from '@mui/system/Unstable_Grid';
import { CodeEditor } from '@/shared/ui';
import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MatcherListForm } from './MatcherListForm';
import { IntegrationTest } from '../../../../types';

import styles from './BlockTestingForm.module.scss';

export interface BlockTestingFormProps {
  register: UseFormRegister<IntegrationTest>;
  control: Control<IntegrationTest>;
  errors: Record<string, unknown>;
}

export const BlockTestingForm: FC<BlockTestingFormProps> = observer(
  ({ control, errors, register }) => {
    const { get } = useSearchParamsTemplate();

    const blockId = get(SearchParams.BlockId);

    return (
      <motion.div
        className={styles.root}
        variants={{
          open: { opacity: 1 },
          close: { opacity: 0 },
        }}
        initial='close'
        animate='open'
        exit='close'
        transition={{ ease: 'easeInOut' }}
      >
        <form>
          <input hidden {...register('blockId', { value: blockId })} />

          <Stack gap={2.5}>
            <h2 className={styles.title}>Имя и описание теста</h2>
            <Grid container gap={2.5}>
              <Grid xs={3}>
                <FieldInput
                  error={errors.name}
                  name='name'
                  register={register}
                  label='Наименование'
                />
              </Grid>
              <Grid xs={6}>
                <FieldInput
                  error={errors.description}
                  name='description'
                  register={register}
                  label='Описание'
                />
              </Grid>
            </Grid>
          </Stack>

          <div className='mt-15'>
            <MatcherListForm control={control} register={register} errors={errors} />
          </div>

          <Stack className='mt-15' gap={2.5}>
            <h2 className={styles.title}>Входящее тело теста</h2>
            <Controller
              control={control}
              name='inputBody'
              render={({ field }) => {
                return (
                  <CodeEditor
                    height={450}
                    defaultLanguage='python'
                    value={field.value || ''}
                    title='Входящее тело теста'
                    onChange={(value) => {
                      field.onChange(value);
                    }}
                  />
                );
              }}
            />
          </Stack>
        </form>
      </motion.div>
    );
  }
);

BlockTestingForm.displayName = 'BlockTestingForm';

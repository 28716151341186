import { FC, SVGProps } from 'react';

export const FilterIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M12.5 4.5L16.5 4.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1.5 4.5L3.5 4.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M5.5 13.5L1.5 13.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.5 13.5L14.5 13.5'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='6.5'
        cy='4.5'
        r='3'
        transform='rotate(90 6.5 4.5)'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <circle
        cx='11.5'
        cy='13.5'
        r='3'
        transform='rotate(90 11.5 13.5)'
        stroke='currentColor'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

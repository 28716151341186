import { FC } from 'react';

import { useWatch } from 'react-hook-form';
import { motion } from 'framer-motion';
import { Stack } from '@mui/system';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { Fields, FieldWhichRenderInsideFieldsProps } from '@/shared/ui/Fields/Fields';
import { CHECKED_DICTIONARY } from '@/shared/ui/Fields/constants';

export const FieldSwitchWithParameter: FC<FieldWhichRenderInsideFieldsProps> = ({
  animationProps,
  error,
  control,
  errors,
  namePrefix,
  isNamePrefixProps,
  register,
  parameter,
  setValue,
  getValues,
  updateSkeleton,
  defaultValue,
}) => {
  const value = useWatch({ control, name: parameter.name, defaultValue: parameter.defaultValue });
  return (
    <>
      <motion.div {...animationProps} className='col-span-full' style={parameter.style}>
        <FieldSwitch {...parameter} error={error} control={control} />
      </motion.div>
      {CHECKED_DICTIONARY[value as keyof typeof CHECKED_DICTIONARY] && (
        <Stack id='infoConnector' spacing={2.5}>
          <Fields
            parameters={parameter.parameterList || []}
            register={register}
            control={control}
            errors={errors}
            namePrefix={namePrefix}
            isNamePrefixProps={isNamePrefixProps}
            defaultValue={defaultValue}
            setValue={setValue}
            getValues={getValues}
            updateSkeleton={updateSkeleton}
          />
        </Stack>
      )}
    </>
  );
};

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { User, UserStore } from '@/entities/User';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

@singleton()
export class CreateUserService {
  open: boolean;

  constructor(private apiService: ApiService, private userStore: UserStore) {
    this.open = false;

    makeAutoObservable(this);
  }

  async createUser(user: Omit<User, 'id'>) {
    const response = await this.apiService.instance.put<User>('/editor/user/save', user);

    if (this.userStore.users) {
      this.userStore.users.ids.push(response.data.id);
      this.userStore.users.entities[response.data.id] = response.data;
    } else {
      this.userStore.users = normalize([response.data], 'id');
    }

    return response.data;
  }
}

import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { ErrorPacket } from '@/entities/Block/types';

import { MessageQueueService } from '../../../services/MessageQueueService';

const messageQueueService = container.resolve(MessageQueueService);

export interface SendMessageForceProps {
  message: ErrorPacket;
  onClick?: () => void;
}

export const SaveMessage: FC<SendMessageForceProps> = observer(({ message, onClick }) => {
  const handleClick = useCallback(() => {
    messageQueueService.saveMessage(message).then(() => {
      onClick?.();
    });
  }, [message, onClick]);

  return (
    <Button loading={messageQueueService.isLoadingSaveMessage} onClick={handleClick}>
      Сохранить
    </Button>
  );
});

SaveMessage.displayName = 'SaveMessage';

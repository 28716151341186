import { FC, useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/system';
import { useNavigate, useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import { RoutePaths } from '@/shared/lib/types';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dashboard } from '@/entities/Dashboard/types';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";

const dashboardService = container.resolve(DashboardService);

export const DashboardForm: FC = observer(() => {
  const navigate = useNavigate();
  const { has } = useSearchParamsTemplate();
  const { dashboardId } = useParams();
  const { remove } = useSearchParamsTemplate();
  const editExist = has(SearchParams.ShowEditDashboardForm) && dashboardId;

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: editExist
      ? dashboardService.dashboards.entities[dashboardId || '']
      : undefined,
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  });

  const buttonText = dashboardService.dashboards.entities[dashboardId ?? ''] ? 'Сохранить' : 'Создать';
  const onSubmit: SubmitHandler<Dashboard> = async (data) => {
    if (editExist) {
      await dashboardService.updateMiniDashboard(dashboardId, data.name, data.description);
      remove(actionSearchParams);
    } else {
      const dashboard = await dashboardService.updateDashboard(data);
      navigate(`/${RoutePaths.Dashboard}/${dashboard?.id}`);
    }
  };

  useEffect(() => {
    if (dashboardId) return;
    reset({ name: '', description: '' });
  }, [dashboardId, reset]);

  return (
    <div>
      <Stack
        component='form'
        className='p-5'
        onSubmit={handleSubmit(onSubmit)}
        justifyContent='space-between'
      >
        <div>
          <Stack spacing={2.5}>
            <FieldInput
              label={`Наименование ${dashboardTitles.gen}`}
              name='name'
              register={register}
              error={errors.name}
              description={`Введите наименование ${dashboardTitles.gen}, длинной не более 100 символов`}
              id='nameDashboard'
            />
            <FieldInput
              textarea
              label={`Описание ${dashboardTitles.gen}`}
              name='description'
              register={register}
              error={errors.description}
              description={`Введите описание  ${dashboardTitles.gen}, длинной не более 1000 символов`}
              id='descriptionDashboard'
            />
          </Stack>
        </div>
        <Box>
          <Button
            disabled={!isValid}
            loading={dashboardService.isLoadingUpdateDashboard}
            type='submit'
            variant={ButtonVariants.Primary}
            onClick={handleSubmit(onSubmit)}
            id='createDashboard'
          >
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </div>
  );
});

DashboardForm.displayName = 'DashboardForm';

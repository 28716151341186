import { memo } from 'react';

import Skeleton from '@mui/material/Skeleton';

import { IData, ITableDescriptor } from './types';

const SKELETON_CELL_WIDTHS = [200, 200, 150, 110, 100, 50];

export const makeLoadableDescriptor = <T extends IData>(
  descriptor: ITableDescriptor<T>
): ITableDescriptor<T> => {
  return {
    ...descriptor,
    renderers: descriptor.columns.reduce<ITableDescriptor<T>['renderers']>((acc, columnId, i) => {
      acc[columnId] = {
        ...descriptor.renderers[columnId],
        Value: memo(({ data, isLoading, children }) => {
          if (isLoading) {
            const currentCellWidth = SKELETON_CELL_WIDTHS[i % SKELETON_CELL_WIDTHS.length];

            return <Skeleton variant='rectangular' width={currentCellWidth} height={15} />;
          }

          const renderer = descriptor.renderers[columnId];
          if (!renderer) {
            return null;
          }

          return <renderer.Value data={data}>{children}</renderer.Value>;
        }),
      };

      return acc;
    }, {}),
  };
};

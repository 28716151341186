import { FC, useCallback } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { BlockService } from '@/entities/Block/services/BlockService';
import { RegistrableValues } from '@/shared/lib/types';
import { observer } from 'mobx-react-lite';
import { ActionPanel } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { ExportTests } from '../features/ExportTests';
import { ImportTests } from '../features/ImportTests';
import { BlockTestingList } from './BlockTestingList/BlockTestingList';
import { RunTests } from '../features/RunTests';

export const BlockTestingSidebar: FC = observer(() => {
  const { set, get, remove } = useSearchParamsTemplate();
  const { flowId } = useParams();
  const blockService = useResolve(BlockService, {
    [RegistrableValues.FlowId]: flowId,
  });
  const blockId = get(SearchParams.BlockId);

  const handleSidebarAdd = useCallback(() => {
    remove([SearchParams.TestId]);
    set([SearchParams.ShowTestForm]);
  }, [remove, set]);

  return (
    <Sidebar
      title='Тесты'
      textAddButton='Добавить тест'
      onAdd={handleSidebarAdd}
      addonBefore={
        <ActionPanel>
          {blockService.blocks && (
            <>
              <RunTests block={blockService.blocks?.entities[blockId]} />
              <ExportTests
                blockName={blockService.blocks?.entities[blockId]?.name}
                blockId={blockId}
              />
            </>
          )}
          <ImportTests blockId={blockId} />
        </ActionPanel>
      }
      addonAfter={
        <div style={{ height: 'calc(100vh - 415px)', display: 'flex', width: '100%' }}>
          <BlockTestingList />
        </div>
      }
    />
  );
});

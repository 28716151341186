import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { CreateSettingsUserAuthService } from '../api/CreateSettingsUserAuthService';
import {PlusIcon} from "@/shared/ui/Icons/PlusIcon/PlusIcon";
import {Stack} from "@mui/material";

const createSettingsUserAuthService = container.resolve(CreateSettingsUserAuthService);

export interface CreateSettingsUserAuthButtonProps {}

export const CreateSettingsUserAuthButton: FC<CreateSettingsUserAuthButtonProps> = observer(() => {
  const handleClick = useCallback(() => {
    createSettingsUserAuthService.open = true;
  }, []);

  return (
    <Button variant={ButtonVariants.Popup} onClick={handleClick}>
        <Stack direction='row' gap={1} alignItems='center'>
          <PlusIcon />
          Добавить
        </Stack>
    </Button>
  );
});

CreateSettingsUserAuthButton.displayName = 'CreateSettingsUserAuthButton';

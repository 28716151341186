import { WeekDay, WeekDayCode } from '../enums';
const codeMap = {
    [WeekDay.Monday]: WeekDayCode.MON,
    [WeekDay.Tuesday]: WeekDayCode.TUE,
    [WeekDay.Wednesday]: WeekDayCode.WED,
    [WeekDay.Thursday]: WeekDayCode.THU,
    [WeekDay.Friday]: WeekDayCode.FRI,
    [WeekDay.Saturday]: WeekDayCode.SAT,
    [WeekDay.Sunday]: WeekDayCode.SUN
};
export const getWeekDayCode = (weekDay) => codeMap[weekDay];
export const getWeekDay = (code) => {
    const keys = Object.keys(codeMap);
    const info = keys.find(key => {
        const value = codeMap[key];
        return value === code;
    });
    return info || null;
};

export const localization = {
  common: {
    month: {
      january: 'Январь',
      february: 'Февраль',
      march: 'Март',
      april: 'Апрель',
      may: 'Май',
      june: 'Июнь',
      july: 'Июль',
      august: 'Август',
      september: 'Сентябрь',
      october: 'Октябрь',
      november: 'Ноябрь',
      december: 'Декабрь',
    },
    dayOfWeek: {
      monday: 'Понедельник',
      tuesday: 'Вторник',
      wednesday: 'Среда',
      thursday: 'Четверг',
      friday: 'Пятница',
      saturday: 'Суббота',
      sunday: 'Воскресенье',
    },
    dayOfMonth: {
      '1st': '1',
      '2nd': '2',
      '3rd': '3',
      '4th': '4',
      '5th': '5',
      '6th': '6',
      '7th': '7',
      '8th': '8',
      '9th': '9',
      '10th': '10',
      '11th': '11',
      '12th': '12',
      '13th': '13',
      '14th': '14',
      '15th': '15',
      '16th': '16',
      '17th': '17',
      '18th': '18',
      '19th': '19',
      '20th': '20',
      '21st': '21',
      '22nd': '22',
      '23rd': '23',
      '24th': '24',
      '25th': '25',
      '26th': '26',
      '27th': '27',
      '28th': '28',
      '29th': '29',
      '30th': '30',
      '31st': '31',
    },
  },
  tabs: {
    seconds: 'Секунды',
    minutes: 'Минуты',
    hours: 'Часы',
    day: 'Дни',
    month: 'Месяца',
    year: 'Года',
  },
  quartz: {
    day: {
      every: {
        label: 'Каждый день',
      },
      dayOfWeekIncrement: {
        label1: 'Каждый',
        label2: 'день недели начиная с дня недели',
      },
      dayOfMonthIncrement: {
        label1: 'Каждый',
        label2: 'день недели начиная с',
        label3: 'числа месяца',
      },
      dayOfWeekAnd: {
        label: 'Конкретный день недели (выберите один или несколько)',
      },
      dayOfWeekRange: {
        label1: 'Каждый день между',
        label2: 'и',
      },
      dayOfMonthAnd: {
        label: 'Конкретный день месяца (выберите один или несколько)',
      },
      dayOfMonthLastDay: {
        label: 'В последний день месяца',
      },
      dayOfMonthLastDayWeek: {
        label: 'В последний рабочий день месяца',
      },
      dayOfWeekLastNTHDayWeek: {
        label1: 'В последний',
        label2: 'в месяце',
      },
      dayOfMonthDaysBeforeEndMonth: {
        label: 'день до конца месяца',
      },
      dayOfMonthNearestWeekDayOfMonth: {
        label1: 'Ближайший рабочий день (с понедельника по пятницу) к',
        label2: 'числу месяца',
      },
      dayOfWeekNTHWeekDayOfMonth: {
        label1: 'В',
        label2: 'месяца',
      },
    },
    month: {
      every: {
        label: 'Каждый месяц',
      },
      increment: {
        label1: 'Каждый',
        label2: 'месяц начиная с месяца',
      },
      and: {
        label: 'Конкретный месяц (выберите один или несколько)',
      },
      range: {
        label1: 'Каждый месяц между месяцем',
        label2: 'и месяцем',
      },
    },
    second: {
      every: {
        label: 'Каждая секунда',
      },
      increment: {
        label1: 'Каждая секунда',
        label2: 'начиная с секунды',
      },
      and: {
        label: 'Конкретная секунда (выберите одну или несколько)',
      },
      range: {
        label1: 'Каждая секунда между секундой',
        label2: 'и секундой',
      },
    },
    minute: {
      every: {
        label: 'Каждая минута',
      },
      increment: {
        label1: 'Каждая',
        label2: 'минута начиная с минуты',
      },
      and: {
        label: 'Конкретная минута (выберите одну или несколько)',
      },
      range: {
        label1: 'Каждая минута между минутой',
        label2: 'и минутой',
      },
    },
    hour: {
      every: {
        label: 'Каждый час',
      },
      increment: {
        label1: 'Каждый',
        label2: 'час начиная с часа',
      },
      and: {
        label: 'Конкретный час (выберите один или несколько)',
      },
      range: {
        label1: 'Каждый час между часом',
        label2: 'и часом',
      },
    },
    year: {
      every: {
        label: 'Каждый год',
      },
      increment: {
        label1: 'Каждый',
        label2: 'год начиная с года',
      },
      and: {
        label: 'Конкретный год (выберите один или несколько)',
      },
      range: {
        label1: 'Каждый год между годом',
        label2: 'и годом',
      },
    },
  },
  unix: {
    day: {
      every: {
        label: 'Каждый день',
      },

      dayOfWeekIncrement: {
        label1: 'Каждый',
        label2: 'день в неделе',
      },
      dayOfMonthIncrement: {
        label1: 'Каждый',
        label2: 'день в месяце',
      },
      dayOfWeekAnd: {
        label: 'Конкретный день недели (выберите один или несколько)',
      },
      dayOfMonthAnd: {
        label: 'Конкретный день месяца (выберите один или несколько)',
      },
    },
    month: {
      every: {
        label: 'Каждый месяц',
      },
      increment: {
        label1: 'Каждый',
        label2: 'месяц',
      },
      and: {
        label: 'Конкретный месяц (выберите один или несколько)',
      },
      range: {
        label1: 'Каждый месяц между месяцем',
        label2: 'и месяцем',
      },
    },
    minute: {
      every: {
        label: 'Каждую минуту',
      },
      increment: {
        label1: 'Каждую',
        label2: 'минуту',
      },
      and: {
        label: 'Конкретная минута (выберите одну или несколько)',
      },
      range: {
        label1: 'Каждую минуту между минутой',
        label2: 'и минутой',
      },
    },
    hour: {
      every: {
        label: 'Каждый час',
      },
      increment: {
        label1: 'Каждый',
        label2: 'час',
      },
      and: {
        label: 'Конкретный час (выберите один или несколько)',
      },
      range: {
        label1: 'Каждый час между часом',
        label2: 'и часом',
      },
    },
  },
};

export type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export type CronLocalization = DeepPartial<typeof localization>;

import { FC, SVGProps } from 'react';

export const MinusIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='10'
      height='4'
      viewBox='0 0 10 4'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M1.33337 0.865234C0.712054 0.865234 0.208374 1.36891 0.208374 1.99023H1.70837C1.70837 2.19734 1.54048 2.36523 1.33337 2.36523V0.865234ZM8.66671 0.865234H1.33337V2.36523H8.66671V0.865234ZM9.79171 1.99023C9.79171 1.36891 9.28803 0.865234 8.66671 0.865234V2.36523C8.4596 2.36523 8.29171 2.19734 8.29171 1.99023H9.79171ZM8.66671 3.11523C9.28803 3.11523 9.79171 2.61155 9.79171 1.99023H8.29171C8.29171 1.78313 8.4596 1.61523 8.66671 1.61523V3.11523ZM1.33337 3.11523H8.66671V1.61523H1.33337V3.11523ZM0.208374 1.99023C0.208374 2.61155 0.712054 3.11523 1.33337 3.11523V1.61523C1.54048 1.61523 1.70837 1.78313 1.70837 1.99023H0.208374Z'
        fill='currentColor'
      />
    </svg>
  );
};

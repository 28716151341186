import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { ErrorPacket, ErrorPacketRequest, ErrorPacketResponse } from '@/entities/Block/types';
import { normalize } from '@/shared/lib/normalize';
import { notify } from '@/shared/ui/Toast/notify';
import { singleton } from 'tsyringe';
import _ from 'lodash';

import { MessageQueueStore } from '../stores/MessageQueueStore';

@singleton()
export class MessageQueueService {
  constructor(private messageQueueStore: MessageQueueStore, private apiService: ApiService) {
    makeAutoObservable(this);
  }

  get messages() {
    return this.messageQueueStore.messages;
  }

  get statusList() {
    return this.messageQueueStore.statusList;
  }

  set statusList(statuses) {
    this.messageQueueStore.statusList = statuses;
  }

  get prevStatusList() {
    return this.messageQueueStore.prevStatusList;
  }

  get totalPages() {
    return this.messageQueueStore.totalPages;
  }

  get isLoadingMessages() {
    return this.messageQueueStore.isLoadingMessages;
  }

  get isLoadingSendMessageForce() {
    return this.messageQueueStore.isLoadingSendMessageForce;
  }

  get isLoadingSaveMessage() {
    return this.messageQueueStore.isLoadingSaveMessage;
  }

  resetMessages() {
    runInAction(() => {
      this.messageQueueStore.messages = { ids: [], entities: {} };
      this.messageQueueStore.totalPages = 0;
    });
  }

  addStatus(status: string) {
    this.messageQueueStore.statusList.push(status);
  }

  removeStatus(status: string) {
    this.messageQueueStore.statusList.splice(this.messageQueueStore.statusList.indexOf(status), 1);
  }

  async getMessages(data: Omit<ErrorPacketRequest, 'statusList'>) {
    this.messageQueueStore.isLoadingMessages = true;

    try {
      const response = await this.apiService.instance.post<ErrorPacketResponse>(
        `/editor/errorPacket/search`,
        { ...data, statusList: this.messageQueueStore.statusList }
      );

      const normalizedMessages = normalize(response.data.errorPacketPage.content, 'id');

      runInAction(() => {
        this.messageQueueStore.messages.ids = _.uniq([
          ...this.messageQueueStore.messages.ids,
          ...normalizedMessages.ids,
        ]);

        this.messageQueueStore.messages.entities = {
          ...this.messageQueueStore.messages.entities,
          ...normalizedMessages.entities,
        };

        this.messageQueueStore.totalPages = response.data.errorPacketPage.totalPages;

        this.messageQueueStore.prevStatusList = [...this.messageQueueStore.statusList];
      });

      return response;
    } catch {
      notify.error('Не удалось получить сообщения');
    } finally {
      runInAction(() => {
        this.messageQueueStore.isLoadingMessages = false;
      });
    }
  }

  async sendMessageForce(message: ErrorPacket) {
    this.messageQueueStore.isLoadingSendMessageForce = true;

    try {
      const response = await this.apiService.instance.put<ErrorPacket>(
        `editor/errorPacket/save`,
        message
      );

      await this.apiService.instance.post<ErrorPacketResponse>(
        `editor/errorPacket/forceSendMessage/${message.id}`,
        { }
      );

      this.messageQueueStore.messages.entities[response.data.messageId] = response.data;

      notify.success('Сообщение отправлено');
    } catch {
      notify.error('Не удалось принудительно отправить сообщение');
    } finally {
      this.messageQueueStore.isLoadingSendMessageForce = false;
    }
  }

  async saveMessage(message: ErrorPacket) {
    this.messageQueueStore.isLoadingSaveMessage = true;

    try {
      const response = await this.apiService.instance.put<ErrorPacket>(
        `editor/errorPacket/save`,
        message
      );

      this.messageQueueStore.messages.entities[response.data.messageId] = response.data;
    } catch {
      notify.error('Не удалось сохранить сообщение');
    } finally {
      this.messageQueueStore.isLoadingSaveMessage = false;
    }
  }
}

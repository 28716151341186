import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { PlayButton } from '@/shared/ui/Button/PlayButton';
import Tooltip from '@/shared/ui/Tooltip';

import { BlockTestingService } from '../../../services/BlockTestingService';
import { Block } from '../../../types';

const blockTestingService = container.resolve(BlockTestingService);

export interface RunTestsProps {
  block: Block;
}

export const RunTests: FC<RunTestsProps> = observer(({ block }) => {
  const handleClick = useCallback(() => {
    void blockTestingService.runTests(block);
  }, [block]);

  return (
    <Tooltip title='Выполнить все тесты' placement='top'>
      <span>
        <PlayButton loading={blockTestingService.isLoadingRunTests} onClick={handleClick} />
      </span>
    </Tooltip>
  );
});

RunTests.displayName = 'RunTests';

import React, {FC} from 'react';

import {
    Typography,
    TypographyProps,
} from '@mui/material';
import dayjs from "dayjs";

export type TypographyDateProps = TypographyProps
    & {
    format?: string,
    time: number
};

const TypographyDate: FC<TypographyDateProps> = ({format = ' DD.MM.YYYY | HH:mm:ss.SSS ', time, ...props}) => {

    const date = dayjs(time).format(format);

    return <Typography>
        {date}
    </Typography>
};

export default TypographyDate;

import { FC } from 'react';

import Button, { ButtonProps } from '@/shared/ui/Button';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';

import { UpsertPythonBeanService } from '../api/UpsertPythonBeanService';

const upsertPythonBeanService = container.resolve(UpsertPythonBeanService);

export interface AcceptPythonBeanButtonProps extends ButtonProps {
  pythonScriptId?: string;
}

export const AcceptPythonBeanButton: FC<AcceptPythonBeanButtonProps> = observer(
  ({ pythonScriptId, ...props }) => {
    const title = pythonScriptId ? 'Изменить' : 'Создать';

    return (
      <Button {...props} loading={upsertPythonBeanService.isLoading}>
        {title}
      </Button>
    );
  }
);

AcceptPythonBeanButton.displayName = 'AcceptPythonBeanButton';

export const DEFAULT_CANVAS_ELEMENT = {
  name: null,
  description: null,
  type: null,
  left: 0,
  top: 0,
  sx: {
    minWidth: 300,
    minHeight: 200,
    height: 395,
    width: 395
  }
};

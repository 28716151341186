import {Node} from 'reactflow';

import {DisplayMessageService} from '../../entities/Connect/services/DisplayMessageService';
import {BlockNodeProps} from "@/entities/BlockNode/BlockNode";
import {ExecutorHistory} from "@/entities/Connect/types";

const recursExecutorHistory = (arr: Node<BlockNodeProps, string>[], executorHistory: ExecutorHistory, mapCount, level) => {
  if (mapCount[level] == undefined) {
    mapCount[level] = 0
  } else {
    mapCount[level]++
  }
  arr.push({
    id: executorHistory.id,
    data: {
      id: executorHistory.id,
      blockId: executorHistory.blockId,
      name: executorHistory.blockName || '',
      flowId: executorHistory.flowId,
      flowName: executorHistory.flowName || '',
      value: '',
      executorHistory: executorHistory
    },
    position: {
      x:  level * 220 + 50,
      y:  mapCount[level] * 100 + 50,
    },
    style: {
      width:  160,
      height:  60,
      display: 'flex',
    },
    type: 'blockNode',
  });
  if (Array.isArray(executorHistory.executorHistoryNext)) {

    executorHistory.executorHistoryNext.forEach((executorHistory2) => {
      recursExecutorHistory(arr, executorHistory2, mapCount, level + 1)
    })
  }
}

export function getNodesForBlockTracing(
  displayMessageService: DisplayMessageService
): Node<BlockNodeProps>[] {

  let res: Node<BlockNodeProps, string>[] = []

  let executorHistory = displayMessageService.executorHistory;
  if (executorHistory) {
    recursExecutorHistory(res, executorHistory, {}, 0)
  }

  return res;
}

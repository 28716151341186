import { Segment } from '../enums';
import { HoursValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class HoursContainer extends BaseContainer {
    getSegment() {
        return Segment.hours;
    }
    cloneWith(d) {
        return new HoursContainer(d);
    }
    createValues(values) {
        return new HoursValues(values);
    }
}

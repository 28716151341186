import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { ExternalAppStore } from '@/entities/ExternalApp';

@singleton()
export class DownloadManualExternalServiceService {
  selectedId: string | null;
  fileName: string  | null;

  constructor(private apiService: ApiService, private externalAppStore: ExternalAppStore) {
    this.selectedId = null;
    this.fileName = null;

    makeAutoObservable(this);
  }

  async downloadManualExternalService() {
    if (this.selectedId) {
      const byteArray = await this.apiService.instance.get(`/editor/externalService/${this.selectedId}/manual`,
          {
            responseType: 'arraybuffer',
          });
      // Создаем Blob из byte[]
      const blob = new Blob([byteArray.data], { type: 'application/octet-stream' });

      // Создаем ссылку на Blob
      const url = URL.createObjectURL(blob);

      // Создаем элемент <a> для скачивания файла
      const link = document.createElement('a');
      link.href = url;
      if (typeof this.fileName === "string") {
        link.download = this.fileName;
      }

      // Добавляем элемент <a> на страницу
      document.body.appendChild(link);

      // Кликаем по элементу <a>, чтобы начать скачивание файла
      link.click();

      // Удаляем элемент <a> со страницы
      document.body.removeChild(link);

      // Освобождаем ресурсы Blob
      URL.revokeObjectURL(url);
    }
  }
}

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { PythonScript } from './types';

@singleton()
export class PythonBeanStore {
  scripts: Normalized<PythonScript> | null;
  lastPage: number;

  constructor() {
    this.scripts = null;
    this.lastPage = 0;

    makeAutoObservable(this);
  }
}

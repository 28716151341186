import React from "react";

import { observer } from "mobx-react-lite";
import { container } from "tsyringe";
import _ from "lodash"
import MenuItem from "@mui/material/MenuItem";
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { Input } from "@/shared/ui";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";
import { BlockMiniService } from "@/entities/Block/services/BlockMiniService";

const dashboardService = container.resolve(DashboardService);
const blockMiniService = container.resolve(BlockMiniService);

export interface CounterPanelSettingsProps {
  dashboardPanelId: string;
}

export const Settings = observer((props: CounterPanelSettingsProps) => {
  const { dashboardPanelId } = props;

    const { miniBlocks } = blockMiniService

    const dashboard = dashboardService.selectedDashboard
    const dataPanel = _.get(dashboard, `data.panels.${dashboardPanelId}`, {
      type: "",
    })

    const data = _.get(dataPanel, `data`, {
      graphType: "",
      blockIdList: [],
    })

    const onChangeGraphType = (newType: string) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { ...dataPanel, data: { ...data, graphType: newType } })
    }

    const onChangeBlockIdList = (newList: string[]) => {
      dashboardService.changeDashboardPanel(dashboardPanelId, { ...dataPanel, data: { ...data, blockIdList: newList } })
    }

    const renderSelectGraphType = () => (
      <Input
        select
        label="Тип графика"
        value={data.graphType}
        SelectProps={{
          value: data.graphType,
          onChange: (event) => {
            onChangeGraphType(event.target.value as string);
          },
        }}
      >
        <MenuItem key="line" value="line">
          Линейный график
        </MenuItem>
        {/* <MenuItem key={'bar'} value={'bar'}> */}
        {/*  Столбчатый график */}
        {/* </MenuItem> */}
        {/* <MenuItem key={'area'} value={'area'}> */}
        {/*  Area график */}
        {/* </MenuItem> */}
        {/* <MenuItem key={'pie'} value={'pie'}> */}
        {/*  Pie график */}
        {/* </MenuItem> */}
      </Input>
    )

    const renderSelectBlockIdList = () => (
      <Autocomplete
        value={data.blockIdList || []}
        options={miniBlocks.ids}
        autoComplete
        freeSolo
        multiple
        filterSelectedOptions
        onChange={(event, value) => {
          onChangeBlockIdList(value);
        }}
        renderOption={(props: any, id) => (
          <li {...props} key={id} value={id}>
            {`${miniBlocks.entities[id].flowName} - ${miniBlocks.entities[id].name}`}
          </li>
        )}
        getOptionLabel={(id) => `${miniBlocks.entities[id].flowName} - ${miniBlocks.entities[id].name}`}
      />
    )

    return (
      <>
        {renderSelectGraphType()}
        {renderSelectBlockIdList()}
      </>
    )
  },
)

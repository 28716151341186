import React, { memo } from 'react';

import { Stack } from '@mui/material';

import { Label } from '../Label/Label';
import { Switch, SwitchProps } from './Switch';

import styles from './Switch.module.scss';

interface Props extends SwitchProps {
  tooltip?: string;
  label: string;
}

export const SwitchWithLabel = memo<Props>(({ label, required, id, tooltip, ...restProps }) => {
  return (
    <Stack direction='row' alignItems='center' gap={1.5}>
      <Switch {...restProps} required={required} id={id} />
      <Label name={id} tooltip={tooltip} required={required} className={styles.switchLabel}>
        {label}
      </Label>
    </Stack>
  );
});

import { FC, memo } from 'react';

import MuiDialogContent, {
  DialogContentProps as MuiDialogContentProps,
} from '@mui/material/DialogContent';
import cn from 'classnames';

import styles from './Dialog.module.scss';

export interface DialogContentProps extends MuiDialogContentProps {}

export const DialogContent: FC<DialogContentProps> = memo(({ className, ...props }) => {
  return <MuiDialogContent {...props} className={cn(styles.root, className)} />;
});

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { ExecutorLog } from './types';

@singleton()
export class ExecutorLogStore {
  executorLog: ExecutorLog | null;
  isLoading:boolean;
  executorLogByBlock: ExecutorLog[];
  isLoadingByBlock:boolean;
  executorLogByIntegra: ExecutorLog[];
  isLoadingByIntegra:boolean;

  constructor() {
    this.executorLog = null;
    this.isLoading = false;
    this.executorLogByBlock = []
    this.isLoadingByBlock = false;
    this.executorLogByIntegra = []
    this.isLoadingByIntegra = false;

    makeAutoObservable(this);
  }
}

import { FC, useCallback } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { useForm } from 'react-hook-form';
import { useResolve } from '@/hooks/useResolve';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Stack from '@mui/system/Stack';
import Button from '@/shared/ui/Button';
import { Box } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Properties } from '@/entities/Properties/model/types';
import { PropertyService } from '@/entities/Properties/api/PropertyService';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';
import { RegistrableValues } from '@/shared/lib/types';

export interface PropertyFormProps {}

export const PropertyForm: FC<PropertyFormProps> = observer(() => {
  const { has, get, remove } = useSearchParamsTemplate();

  const propertyId = get(SearchParams.PropertyId);
  const propertyService = useResolve(PropertyService, {
    [RegistrableValues.PropertyId]: propertyId,
  });

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(nameAndDescriptionFieldsValidation),
    values: propertyService.property,
    defaultValues: {
      name: '',
      description: '',
    },
    mode: 'onChange',
  });

  const title = propertyId ? 'Изменить конфигурацию' : 'Создать конфигурацию';
  const buttonText = propertyId ? 'Сохранить' : 'Создать';

  const handleClose = useCallback(() => {
    remove([SearchParams.ShowPropertyForm, SearchParams.PropertyId]);
  }, [remove]);

  const onSubmit = useCallback(
    (data: Properties) => {
      if (propertyId) {
        propertyService.updateProperty(data).then(() => {
          handleClose();
        });
      } else {
        propertyService.createProperty(data).then(() => {
          handleClose();
        });
      }
    },
    [handleClose, propertyId, propertyService]
  );

  return has(SearchParams.ShowPropertyForm) ? (
    <Drawer width='434px' title={title} onClose={handleClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <Stack height='100%' justifyContent='space-between'>
            <Stack gap={2} paddingX={2.5}>
              <FieldInput register={register} error={errors.name} name='name' label='Название' />
              <FieldInput
                textarea
                register={register}
                error={errors.description}
                name='description'
                label='Описание'
              />
            </Stack>

            <Box padding={2.5}>
              <Button
                disabled={!isValid}
                loading={propertyService.isLoadingEditProperty}
                type='submit'
              >
                {buttonText}
              </Button>
            </Box>
          </Stack>
        </div>
      </form>
    </Drawer>
  ) : null;
});

PropertyForm.displayName = 'PropertyForm';

import { FC, useCallback } from 'react';

import { Pagination } from '@mui/lab';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { ArrowLeftIcon } from '@/shared/ui/Icons/ArrowLeftIcon';
import { ArrowRightIcon } from '@/shared/ui/Icons/ArrowRightIcon';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { container } from 'tsyringe';
import { DisplayMessageService } from '@/entities/Connect/services/DisplayMessageService';
import { observer } from 'mobx-react-lite';
import { RequestFilter } from '@/entities/Flow/types';

import styles from './PaginationForTracingMessages.module.scss';

export interface PaginationForTracingMessagesProps {
  blockId: string;
  filterList: RequestFilter[];
  isTrace: boolean;
}

const displayMessageService = container.resolve(DisplayMessageService);

export const PaginationForTracingMessages: FC<PaginationForTracingMessagesProps> = observer(
  ({ blockId, filterList, isTrace }) => {
    const handlePageClick = useCallback(
      (page: number | null) => () => {
        if (page) {
          void displayMessageService.getMessages(blockId, {
            pagination: { page: page - 1 },
            filterList,
            addTrace:isTrace
          });
        }
      },
      [blockId, filterList, isTrace]
    );

    return (
      <Pagination
        showFirstButton
        showLastButton
        className={styles.root}
        count={displayMessageService.messageTotal}
        page={displayMessageService.messageNumber}
        siblingCount={3}
        boundaryCount={0}
        renderItem={({ page, type, selected, disabled }) => {
          switch (type) {
            case 'page': {
              return (
                <Button
                  active={selected}
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  onClick={handlePageClick(page)}
                >
                  {page}
                </Button>
              );
            }
            case 'previous': {
              return (
                <Button
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  disabled={disabled}
                  onClick={handlePageClick(page)}
                >
                  <ArrowLeftIcon />
                </Button>
              );
            }
            case 'next': {
              return (
                <Button
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  disabled={disabled}
                  onClick={handlePageClick(page)}
                >
                  <ArrowRightIcon />
                </Button>
              );
            }
            case 'start-ellipsis':
            case 'end-ellipsis': {
              return <p className={styles.dots}>...</p>;
            }
            case 'first': {
              return (
                <Button
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  disabled={disabled}
                  onClick={handlePageClick(page)}
                >
                  <KeyboardDoubleArrowLeftIcon />
                </Button>
              );
            }
            case 'last': {
              return (
                <Button
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  disabled={disabled}
                  onClick={handlePageClick(page)}
                >
                  <KeyboardDoubleArrowRightIcon />
                </Button>
              );
            }
          }
        }}
      />
    );
  }
);

PaginationForTracingMessages.displayName = 'PaginationForTracingMessages';

import { FC, memo, useCallback, useEffect, useState } from 'react';

import { CodeEditor, Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Stack from '@mui/system/Stack';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { notify } from '@/shared/ui/Toast/notify';
import Box from '@mui/material/Box';
import { isArray, isObject, mapValues, merge, find } from 'lodash';

type json = { [key: string]: any };
const minimize = (obj: json): json => mapValues(obj, (value) => {
  if (isArray(value)) return [merge({}, ...value)];
  if (isObject(value)) return minimize(value);
  return value;
})

const findArray = (obj: json): json[] | undefined => find(obj, (itm) => {
  if (!isArray(itm)) return false;
  if (itm?.length > 1) return true;
  return findArray(itm[0]);
});

const parseJson = (str: string): json => {
  let parsedValues = null;
  try {
    parsedValues = JSON.parse(str);

    if (Array.isArray(parsedValues)) {
      throw new Error('PASSED_ARRAY');
    }

  } catch (error: any) {
    if (error.message === 'PASSED_ARRAY') {
      notify.error('Необходимо ввести объект, а не массив');
    } else {
      notify.error('Неккоректный JSON');
    }
  }
  return parsedValues;
}
export interface JsonSettingsFormProps {
  defaultValue: string;
  visible: boolean;
  onOk: (value: json) => void;
  onCancel: () => void;
}

export const JsonSettingsForm: FC<JsonSettingsFormProps> = memo(
  ({ visible, defaultValue, onOk, onCancel }) => {
    const [value, setValue] = useState('');
    const [rerenderEditor, setRerenderEditor] = useState(false);

    const handleChange = useCallback((value: string | undefined) => {
      setValue(value || '');
    }, []);

    const handleOkButtonClick = useCallback(() => {
      const val = parseJson(value);
      if (!val) return;
      onOk(val);
    }, [value]);

    const handleCancelButtonClick = useCallback(() => {
      setValue(defaultValue);
      onCancel();
    }, [onCancel, defaultValue]);

    const allowMinimize = value && findArray(parseJson(value));

    const handleMinimize = useCallback(() => {
      const val = parseJson(value);
      if (!val) return;
      const minimized = minimize(val);
      setValue(JSON.stringify(minimized));
      setRerenderEditor(!rerenderEditor);
    }, [value]);

    useEffect(() => {
      setValue(defaultValue);
    }, [defaultValue]);

    return (
      <Dialog fullScreen open={visible} onClose={handleCancelButtonClick}>
        <DialogTitle onClose={handleCancelButtonClick}>Настройки JSON</DialogTitle>
        <DialogContent>
          <Stack gap={2.5}>
            <CodeEditor
              hideFormatSelect
              hideLanguageSelect
              title='Настройки JSON'
              height='70vh'
              value={value}
              onChange={handleChange}
              rerender={rerenderEditor}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Box width={256}>
            <Button variant={ButtonVariants.Secondary} onClick={handleCancelButtonClick}>
              Отмена
            </Button>
          </Box>
          {Boolean(allowMinimize) && (
            <Box width={256}>
              <Button variant={ButtonVariants.Secondary} onClick={handleMinimize}>
                Свернуть пример
              </Button>
            </Box>
          )}
          <Box width={256}>
            <Button variant={ButtonVariants.Primary} onClick={handleOkButtonClick}>
              Применить
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    );
  }
);

JsonSettingsForm.displayName = 'JsonSettingsForm';

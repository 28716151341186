import { FC, SVGProps } from 'react';

export const SaveIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M6.07643 3.81079H7.67636C7.82343 3.81079 7.94297 3.69113 7.94297 3.54418V1.23305C7.94297 1.08597 7.82331 0.966431 7.67636 0.966431H6.07643C5.92936 0.966431 5.80981 1.0861 5.80981 1.23305V3.54418C5.80969 3.69125 5.92936 3.81079 6.07643 3.81079Z'
        fill='currentColor'
      />
      <path d='M4.04028 9.81384H11.9599V10.7027H4.04028V9.81384Z' fill='currentColor' />
      <path d='M4.04028 12.2455H11.9599V13.1344H4.04028V12.2455Z' fill='currentColor' />
      <path
        d='M15.8258 4.78997L13.5894 0.810304C13.5411 0.724987 13.4222 0.655048 13.3245 0.655048H12.0485C11.9509 0.655048 11.8706 0.735279 11.8706 0.832875V4.55477C11.8706 5.04359 11.4706 5.44365 10.9818 5.44365L5.01797 5.44353C4.52914 5.44353 4.12908 5.04347 4.12908 4.55464V0.460054C4.12908 0.362461 4.0491 0.282227 3.95125 0.282227H1.42225C0.639997 0.282227 0 0.922224 0 1.70448V14.3441C0 15.1264 0.639997 15.7664 1.42225 15.7664H14.5778C15.36 15.7664 16 15.1264 16 14.3441V5.45494C16 5.25938 15.9216 4.96014 15.826 4.78976L15.8258 4.78997ZM13.5022 14.5293H2.49748V9.15154C2.49748 8.66272 2.89754 8.26266 3.38637 8.26266H12.6133C13.1021 8.26266 13.5022 8.66272 13.5022 9.15154V14.5293Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { Box } from '@mui/system';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { SettingsUserAuthTypes } from '@/entities/SettingsUserAuth';

import { DeleteSettingsUserAuthService } from '../api/DeleteSettingsUserAuthService';

const deleteSettingsUserAuthService = container.resolve(DeleteSettingsUserAuthService);

export interface DeleteSettingsUserAuthProps {
  type: SettingsUserAuthTypes;
}

export const DeleteSettingsUserAuthButton: FC<DeleteSettingsUserAuthProps> = observer(
  ({ type }) => {
    const entityName =
      type === SettingsUserAuthTypes.SettingsBasic ? 'Basic хранилище' : 'Keycloack хранилище';

    const handleClick = useCallback(() => {
      deleteSettingsUserAuthService.open = true;
    }, []);

    return (
      <Tooltip title={`Удалить ${entityName}`} placement='top'>
        <Box>
          <Button
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <TrashIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

DeleteSettingsUserAuthButton.displayName = 'DeleteSettingsUserAuthButton';

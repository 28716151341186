import React from 'react';

import { Radio } from '@/shared/ui/Radio/Radio';
import { Checkbox } from '@/shared/ui/Checkbox/Checkbox';
import { Stack } from '@mui/system';
import { Grid } from '@/shared/ui/Grid/Grid';
import cn from 'classnames';

import { Mode } from '../../cron-core';
import { SharedProps } from './props.type';
import styles from '../../Cron.module.scss';

type Props = {
  disabledControls?: boolean;
  onValueChange: (value: string) => void;
  isValueSelected: (value: string) => boolean;
  options: {
    label: string;
    value: string;
  }[];
  label: string;
} & SharedProps;

export const SimpleAnd = ({
  checked = false,
  disabled = false,
  disabledControls = false,
  label,
  options,
  onSelect,
  onValueChange,
  isValueSelected,
  segmentId,
}: Props) => (
  <Grid container>
    <Grid xs={6}>
      <Stack gap={2}>
        <Radio
          id={segmentId}
          value={Mode.AND}
          checked={checked}
          disabled={disabled}
          onChange={onSelect}
          label={label}
        />

        {checked && (
          <Stack
            component='ul'
            display='grid'
            gridTemplateColumns={`repeat(${options.length / 4}, auto)`}
            gap={2}
            className={cn({ [styles.inactive]: !checked })}
          >
            {options.map((item) => {
              return (
                <li key={item.value}>
                  <Checkbox
                    id={`${segmentId}_${item.value}`}
                    value={item.value}
                    disabled={disabledControls}
                    checked={isValueSelected(item.value)}
                    onChange={() => onValueChange(item.value)}
                    label={item.label}
                  />
                </li>
              );
            })}
          </Stack>
        )}
      </Stack>
    </Grid>
  </Grid>
);

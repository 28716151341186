import {makeAutoObservable, runInAction} from 'mobx';
import { injectable } from 'tsyringe';
import { AxiosResponse } from 'axios';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';

import {AuthStore} from "@/shared/ui/AuthVerifier/stores/AuthStore";

@injectable()
export class AuthService {
  constructor(
    private authStore: AuthStore,
    private apiService: ApiService
  ) {

    makeAutoObservable(this);
  }

  get isAuth() {
    return this.authStore.isAuth
  }

  async sendIsCheck() {
    try {
      let headerResult = localStorage.getItem('headerAuth')
      if (headerResult === undefined || headerResult === null || headerResult === '') {
        return false;
      }
      let response = await this.apiService.instance.get<string, AxiosResponse<string>>(
        `editor/settingsUserAuth/check`,
        {
          validateStatus: (status)=> {
            return status < 500; // Разрешить, если код состояния меньше 500
          }
        }
      )
      if (response.status === 401 || response.status === 403) {
        if (response.status === 401) {
          notify.error('Неверный логин или пароль')
        } else if (response.status === 403) {
          notify.warning('Отсутствует доступ к интерфейсу системы')
        }
        localStorage.removeItem('headerAuth');
        this.authStore.isAuth = false;
        return false
      } else {
        if (response.status === 200) {
          this.authStore.isAuth = true;
          return true;
        }
      }
    } catch (e) {
      this.authStore.isAuth = false;
      localStorage.removeItem('headerAuth');
      return false
    }
  };

  logout() {
    localStorage.removeItem('headerAuth');
    localStorage.removeItem('resultTokenRequest');
    this.authStore.isAuth = false;
  }

}

import { FC, useCallback, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { FilterByStatuses } from '@/shared/ui';
import { container } from 'tsyringe';
import { MessageQueueService } from '@/entities/Block/services/MessageQueueService';
import _ from 'lodash';
import { Stack } from '@mui/system';

import { MESSAGE_STATUSES } from '../../constants';

import styles from './MessageQueueFilter.module.scss';

const messageQueueService = container.resolve(MessageQueueService);

export interface MessageQueueFilterProps {
  blockId: string;
  onClose: () => void;
}

export const MessageQueueFilter: FC<MessageQueueFilterProps> = observer(({ blockId, onClose }) => {
  const activeLabels = useMemo(() => {
    return messageQueueService.statusList.map((value) => {
      return MESSAGE_STATUSES.find((status) => status.value === value)!.label;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageQueueService.statusList.length]);

  const handleFilterChange = useCallback((status: string) => {
    if (messageQueueService.statusList.includes(status)) {
      messageQueueService.removeStatus(status);
    } else {
      messageQueueService.addStatus(status);
    }
  }, []);

  const handleFilterClose = useCallback(() => {
    if (!_.isEqual(messageQueueService.prevStatusList, messageQueueService.statusList)) {
      messageQueueService.resetMessages();
      void messageQueueService.getMessages({ blockId, pagination: { page: 0, size: 10 } });
      onClose();
    }
  }, [blockId, onClose]);

  return (
    <FilterByStatuses
      tooltip={
        <Stack>
          <p>Статусы:</p>
          <Stack component='ol'>
            {activeLabels.map((label) => {
              return (
                <li className={styles.tooltipElement} key={label}>
                  {label}
                </li>
              );
            })}
          </Stack>
        </Stack>
      }
      title='Статус'
      options={MESSAGE_STATUSES}
      value={messageQueueService.statusList}
      onChange={handleFilterChange}
      onClose={handleFilterClose}
    />
  );
});

MessageQueueFilter.displayName = 'MessageQueueFilter';

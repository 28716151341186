import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Toast } from '@/shared/ui/Toast/Toast';
import { darkTheme } from '@/app';
import { ruRU } from '@mui/material/locale';

import { updatePath } from '../../../store/location/action';
import { ThemeContext } from '../../../context/ThemeContext';
import useLocalStorage from '../../../hooks/useLocalStorage';

const Configuration = (props) => {
  const [theme, setTheme] = useLocalStorage('theme', 'dark');
  const { children } = props;
  const dispatch = useDispatch();

  const href = useSelector((state) => {
    return state.location.path;
  });

  useEffect(() => {
    dispatch(updatePath(window.location));
  }, []);

  const changeTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        changeTheme: changeTheme,
      }}
    >
      <ThemeContext.Consumer>
        {() => (
          <ThemeProvider
            theme={createTheme({
              ...ruRU,
              ...darkTheme,
            })}
          >
            <CssBaseline />
            {href ? children : null}
            <Toast />
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};

Configuration.propTypes = {};

export default Configuration;

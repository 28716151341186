import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { SettingsBasic, SettingsIdm, SettingsUserAuth, SettingsUserAuthTypes } from './types';

@singleton()
export class SettingsUserAuthStore {
  settingsUserAuth: SettingsUserAuth | null;
  settingsBasic: Normalized<SettingsBasic> | null;
  settingsIdm: Normalized<SettingsIdm> | null;
  selectedId: string | null;
  selectedType: SettingsUserAuthTypes | null;

  constructor() {
    this.settingsBasic = null;
    this.settingsIdm = null;
    this.settingsUserAuth = null;
    this.selectedId = null;
    this.selectedType = null;

    makeAutoObservable(this);
  }

  get basicAndIdmSettingsIds() {
    let result: string[] = [];

    if (this.settingsBasic) {
      result = result.concat(this.settingsBasic.ids);
    }

    if (this.settingsIdm) {
      result = result.concat(this.settingsIdm.ids);
    }

    return result;
  }
}

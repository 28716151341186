import { HandleElement, internalsSymbol, Node, Position } from 'reactflow';
import { InputConnectIdAndOutputConnectId } from '@/entities/Block/components/FloatingEdge/types';

/**
 * Логика переключения линии на ближайшую доступную точку
 * @param node Блок
 * @param handlePosition Позиция точни
 * @param data Содержит в себе ids коннекторов
 */
export const getHandleCoordsByPosition = (
  node: Node,
  handlePosition: Position,
  data: InputConnectIdAndOutputConnectId
): [number, number] => {
  const sources = node[internalsSymbol]!.handleBounds!.source;
  const targets = node[internalsSymbol]!.handleBounds!.target;

  let handle: HandleElement | undefined;

  if (sources) {
    handle = sources.find((handleElement) => {
      // Нужно для того чтобы линия не соединялась с точкой, которая уже занята другой линией
      if (handleElement.id === data.outputConnectId) {
        return handleElement.position === handlePosition;
      }

      return false;
    });
  }

  if (!handle && targets) {
    // Нужно для того чтобы линия не соединялась с точкой, которая уже занята другой линией
    handle = targets.find((handleElement) => {
      if (handleElement.id === data.inputConnectId) {
        return handleElement.position === handlePosition;
      }

      return false;
    });
  }

  let offsetX = handle!.width! / 2;
  let offsetY = handle!.height! / 2;

  // Отступ для стрелки у линии перед блоком
  switch (handlePosition) {
    case Position.Left:
      offsetX = 0;
      break;
    case Position.Right:
      offsetX = handle!.width;
      break;
    case Position.Top:
      offsetY = 0;
      break;
    case Position.Bottom:
      offsetY = handle!.height;
      break;
  }

  const x = node.positionAbsolute!.x + handle!.x + offsetX;
  const y = node.positionAbsolute!.y + handle!.y + offsetY;

  return [x, y];
};

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import _ from 'lodash';
import { KeyValueStore } from '@/entities/KeyValue';

import { DeleteKeyValueService } from '../api/DeleteKeyValueService';

const deleteKeyValueService = container.resolve(DeleteKeyValueService);
const keyValueStore = container.resolve(KeyValueStore);

export interface DeleteKeyValueDialogProps {
  onSuccess?(): void;
}

export const DeleteKeyValueDialog: FC<DeleteKeyValueDialogProps> = observer(
  ({ onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);

    const name = _.get(
      keyValueStore.keyValues?.entities[deleteKeyValueService.selectedId || ''],
      'name',
      ''
    );

    const handleClose = useCallback(() => {
      deleteKeyValueService.open = false;
    }, []);

    const handleOkButtonClick = useCallback(async () => {
      try {
        setIsLoading(true);

        await deleteKeyValueService.deleteKeyValue();

        notify.success('Запись удалена');
        handleClose();
        onSuccess?.();
      } catch {
        notify.error('Не удалось удалить запись');
      } finally {
        setIsLoading(false);
      }
    }, [handleClose, onSuccess]);

    return (
      <Dialog fullWidth open={deleteKeyValueService.open} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удаление записи</DialogTitle>
        <DialogContent>Вы точно хотите удалить запись {name}?</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeleteKeyValueDialog.displayName = 'DeleteKeyValueDialog';

import * as React from 'react';
import { FC, useCallback } from 'react';

import { container } from 'tsyringe';
import { Stack } from '@mui/system';
import { Controller, useForm } from 'react-hook-form';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Filter, RangePicker } from '@/shared/ui';
import { Input } from '@/shared/ui/InputNumber/Input';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { Label } from '@/shared/ui/Label/Label';

import { FlowService } from '../services/FlowService';


export interface FlowFilterProps {}

const flowService = container.resolve(FlowService);

export const FlowFilter: FC<FlowFilterProps> = observer(() => {
  const { register, control, handleSubmit, reset, getValues } = useForm({
    defaultValues: {
      nameOrDescription: '',
      lastUpdate: [null, null],
    },
  });

  const handleClearInputButtonClick = useCallback(() => {
    reset((formValues) => ({ ...formValues, nameOrDescription: '' }));
  }, [reset]);

  const tooltip = () => {
    const format = 'DD.MM.YYYY';
    const { nameOrDescription, lastUpdate } = getValues();
    const [start = null, end = null] = lastUpdate || [];
    const renderDate = (date: Dayjs) => {
      if (!date) return '<не указано>';
      return dayjs(date).format(format);
    };

    return (
      <Stack gap={1}>
        <p>Фильтрация</p>
        {nameOrDescription && (
          <Stack>
            <p>По имени или описанию:</p>
            <p>{nameOrDescription}</p>
          </Stack>
        )}
        {(start || end) && (
          <Stack>
            <p>По дате последнего обновления:</p>
            <p>
              {renderDate(start)} - {renderDate(end)}
            </p>
          </Stack>
        )}
      </Stack>
    );
  };

  /**
   * TODO
   * - Вынести в useCallback`и
   */
  return (
    <Filter
      isActive={Boolean(flowService.filter.length)}
      title='Фильтр'
      tooltip={tooltip()}
      onFilter={handleSubmit(({ nameOrDescription, lastUpdate }) => {
        if (lastUpdate) {
          flowService.setFilter(
            nameOrDescription,
            dayjs(lastUpdate[0]).valueOf(),
            dayjs(lastUpdate[1]).valueOf()
          );
        } else {
          flowService.setFilter(nameOrDescription);
        }

        void flowService.getFlows(0);
      })}
      onReset={() => {
        reset();
        flowService.resetFilter();
        void flowService.getFlows(0);
      }}
    >
      <form>
        <Stack gap={5}>
          <Stack>
            <Input
              {...register('nameOrDescription')}
              placeholder='Введите наименование или описание'
              endAdornment={(
                <Button
                  circle
                  size={ButtonSizes.Small}
                  variant={ButtonVariants.Outlined}
                  onClick={handleClearInputButtonClick}
                >
                  <CrossIcon />
                </Button>
              )}
            />
          </Stack>
          <Stack gap={2.5}>
            <Label>Дата последнего изменения</Label>
            <Stack direction='row' gap={2.5} height={48}>
              <Controller
                name='lastUpdate'
                control={control}
                render={({ field }) => (
                  <RangePicker
                    value={field.value}
                    onCalendarChange={(values) => {
                      const [start, end] = values;
                      const val = start || end ? values : [null, null];
                      field.onChange(val);
                    }}
                  />
                  )}
              />
            </Stack>
          </Stack>
        </Stack>
      </form>
    </Filter>
  );
});

FlowFilter.displayName = 'FlowFilter';

import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { EdgeLabelRenderer, EdgeProps, getBezierPath, useStore, useViewport } from 'reactflow';
import cn from 'classnames';
import { getEdgeParams } from '@/entities/Block/components/FloatingEdge/utils/getEdgeParams';
import { useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { RegistrableValues } from '@/shared/lib/types';
import { BlockRelationService } from '@/entities/Block/services/BlockRelationService';
import { observer } from 'mobx-react-lite';

import styles from './FloatingEdge.module.scss';

/**
 * Компонент для линий, которые цепляются за точки входа и выхода на блоке
 */
export const FloatingEdge: FC<EdgeProps> = observer(
  ({ id, source, target, interactionWidth, data, label }) => {
    const { zoom } = useViewport();
    const { flowId } = useParams();

    container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
    const blockRelationService = container.resolve(BlockRelationService);

    const [labelPosition, setLabelPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
    const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

    const edgeRef = useRef<SVGPathElement>(null);

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode!, targetNode!, data);

    const [edgePath] = getBezierPath({
      sourceX: sx,
      sourceY: sy,
      sourcePosition: sourcePos,
      targetPosition: targetPos,
      targetX: tx,
      targetY: ty,
    });

    const handleMouseEnter = (): void => {
      blockRelationService.lineIdWhichHover = id;
    };
    const handleMouseLeave = (): void => {
      blockRelationService.lineIdWhichHover = undefined;
    };

    const handleMouseMove = useCallback(
      (event: MouseEvent) => {
        const x = event.offsetX - ((2 - zoom + 0.5) * -10) / 2;
        const y = event.offsetY - ((2 - zoom + 0.5) * 100) / 2;

        setLabelPosition({ x, y });
      },
      [zoom]
    );

    useEffect(() => {
      const edge = edgeRef.current;

      if (edge) {
        edge.addEventListener('mousemove', handleMouseMove);

        return () => {
          edge.removeEventListener('mousemove', handleMouseMove);
        };
      }
    }, [handleMouseMove]);

    useEffect(() => {
      data.setRelationPosition(id, sourcePos);
    }, [id, sourcePos]);

    return (
      <>
        <defs>
          <marker
            className='react-flow__arrowhead'
            id='1__color=#CFC8FF&height=15&type=arrowclosed&width=15'
            markerWidth='15'
            markerHeight='15'
            viewBox='-10 -10 20 20'
            markerUnits='strokeWidth'
            orient='auto-start-reverse'
            refX='0'
            refY='0'
          >
            <polyline
              stroke='#CFC8FF'
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='1'
              fill='#CFC8FF'
              points='-5,-4 0,0 -5,4 -5,-4'
            />
          </marker>
        </defs>
        <path
          id={id}
          className={cn(styles.edge, {
            [styles.hover]: blockRelationService.lineIdWhichHover === id,
          })}
          d={edgePath}
          markerEnd={`url(#1__color=${
            blockRelationService.lineIdWhichHover === id ? '#CFC8FF' : '#E4E4E4'
          }&height=15&type=arrowclosed&width=15)`}
        />
        <path
          ref={edgeRef}
          d={edgePath}
          fill='none'
          style={{ strokeWidth: interactionWidth }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <EdgeLabelRenderer>
          {blockRelationService.lineIdWhichHover === id && (
            <span
              style={{
                transform: `translate(${labelPosition.x}px,${labelPosition.y}px) scale(${
                  2 - zoom + 0.5
                })`,
              }}
              className={cn(styles.label, 'nodrag nopan')}
            >
              {label}
            </span>
          )}
        </EdgeLabelRenderer>
      </>
    );
  }
);

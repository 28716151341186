import { useSearchParams } from 'react-router-dom';
import { isArray } from 'lodash';

import { SearchParams, SearchParamsTemplate, SearchParamsTemplateElement } from './types';

export function useSearchParamsTemplate() {
  const [searchParams, setSearchParams] = useSearchParams();

  function get(key: SearchParams | string) {
    return searchParams.get(key) as string;
  }

  function getAll(key: SearchParams) {
    return searchParams.getAll(key);
  }

  function has(key: SearchParams) {
    return searchParams.has(key);
  }

  function set(params: SearchParamsTemplate | SearchParamsTemplateElement) {
    const list = isArray(params) ? params : [params];
    list.forEach((param) => {
      if (typeof param === 'string') {
        searchParams.set(param, 'true');
      } else if (typeof param === 'object') {
        Object.keys(param).forEach((key) => {
          searchParams.set(key, param[key]);
        });
      }
    });

    setSearchParams(searchParams);
  }

  function append(params: { [key in SearchParams]?: string[] }) {
    Object.keys(params).forEach((key) => {
      searchParams.delete(key);

      const values = params[key] as string[];

      values.forEach((value) => {
        searchParams.append(key, value);
      });
    });

    setSearchParams(searchParams);
  }

  function remove(params: SearchParamsTemplate) {
    params.forEach((param) => {
      searchParams.delete(param as string);
    });

    setSearchParams(searchParams);
  }

  return {
    get,
    getAll,
    has,
    set,
    remove,
    append,
  };
}

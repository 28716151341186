import {injectable} from "tsyringe";
import {CloseOnboardingStore} from "@/modules/JoyRideOnboarding/stores/CloseOnboardingStore";
import {makeAutoObservable} from "mobx";


@injectable()
export class CloseOnbordingService {
    constructor(private closeOnboarding: CloseOnboardingStore) {
        makeAutoObservable(this);
    }

    handleCloseOnboarding = (isClose: boolean) => {
        this.closeOnboarding.isClose = isClose;
    }

    get isClose(): boolean {
        return this.closeOnboarding.isClose
    }
}
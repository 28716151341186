import { FC, SVGProps } from 'react';

export const SettingsIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M15.9144 9.16731C16.0247 8.41311 16.0323 7.64006 15.9144 6.83264L14.1068 6.3161C13.9665 5.80648 13.7643 5.32259 13.5085 4.87295L14.4218 3.229C13.955 2.60162 13.3984 2.045 12.771 1.57815L11.127 2.49146C10.6774 2.23568 10.1938 2.03346 9.68387 1.89315L9.16734 0.0856329C8.41314 -0.0246981 7.64008 -0.0323268 6.83266 0.0856329L6.31613 1.89315C5.8065 2.03345 5.32261 2.23567 4.87297 2.49146L3.22901 1.57815C2.60163 2.045 2.04501 2.60162 1.57815 3.229L2.49147 4.87295C2.23569 5.32261 2.03347 5.80616 1.89316 6.3161L0.0856332 6.83264C-0.0246981 7.58684 -0.0323269 8.35989 0.0856332 9.16731L1.89316 9.68384C2.03346 10.1935 2.23568 10.6773 2.49147 11.127L1.57815 12.7709C2.04501 13.3983 2.60163 13.9549 3.22901 14.4218L4.87297 13.5085C5.32263 13.7643 5.80618 13.9665 6.31613 14.1068L6.83266 15.9143C7.58686 16.0246 8.35992 16.0323 9.16734 15.9143L9.68387 14.1068C10.1937 13.9665 10.6774 13.7643 11.127 13.5085L12.771 14.4218C13.3984 13.9549 13.955 13.3983 14.4218 12.7709L13.5085 11.127C13.7643 10.6773 13.9665 10.1938 14.1068 9.68384L15.9144 9.16731ZM8.00041 11.4286C6.10688 11.4286 4.57181 9.89367 4.57181 7.99997C4.57181 6.10645 6.1067 4.57139 8.00041 4.57139C9.89412 4.57139 11.429 6.10627 11.429 7.99997C11.4288 9.89331 9.89376 11.4286 8.00041 11.4286Z'
        fill='currentColor'
      />
      <path
        d='M10.2867 8.00038C10.2867 9.26263 9.26336 10.2863 8.00075 10.2863C6.73815 10.2863 5.71484 9.26299 5.71484 8.00038C5.71484 6.73778 6.73815 5.71484 8.00075 5.71484C9.26336 5.71467 10.2867 6.73796 10.2867 8.00038Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { inject, injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { RegistrableValues } from '@/shared/lib/types';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { MonitoringRequest, MonitoringResponse } from '../types';
import { BlockMonitoringStore } from '../stores/BlockMonitoringStore';

@injectable()
export class BlockMonitoringService {
  constructor(
    @inject(RegistrableValues.FlowId) private flowId: string,
    @inject(RegistrableValues.BlockId) private blockId: string,
    private apiService: ApiService,
    private blockMonitoringStore: BlockMonitoringStore
  ) {
    makeAutoObservable(this);
  }

  get monitoring(): MonitoringResponse {
    return this.blockMonitoringStore.monitoring?.[this.flowId]?.[this.blockId];
  }

  async get(): Promise<void> {
    if (this.blockId) {
      const response = await this.apiService.instance.post<
        MonitoringRequest,
        AxiosResponse<MonitoringResponse>
      >(`/editor/api/v2/monitoring/block/${this.blockId}`, { pagination: { page: 0, size: 1 } });

      runInAction(() => {
        _.set(this.blockMonitoringStore.monitoring, `${this.flowId}.${this.blockId}`, response.data);
      });
    }
  }
}

import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { cutText } from '@/shared/lib/cutText';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import Button from '@/shared/ui/Button';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { dashboardTitles } from '@/entities/Dashboard/widgets/DashboardPlatform/constants/titles';
import actionSearchParams from '@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams';

const dashboardService = container.resolve(DashboardService);

export const DeleteDashboardModal: FC = observer(() => {
  const navigate = useNavigate();
  const { get, has, remove } = useSearchParamsTemplate();

  const dashboardId = get(SearchParams.DashboardId);
  const isOpenDeleteModal = has(SearchParams.ShowDashboardDeleteModal);

  const handleDialogClose = useCallback(() => {
    remove(actionSearchParams);
  }, [remove]);

  const handleDialogOk = useCallback(() => {
    if (!dashboardId) return;
    dashboardService.deleteDashboard(dashboardId).then(() => {
      setTimeout(() => {
        const firstDashboardId = dashboardService.dashboards.ids[0];
        navigate(firstDashboardId || '');
      }, 0);
    });
  }, [dashboardId, navigate]);

  if (!isOpenDeleteModal) return null;
  return (
    <Dialog fullWidth open onClose={handleDialogClose}>
      <DialogTitle onClose={handleDialogClose}>Удалить {dashboardTitles.base}</DialogTitle>
      <DialogContent>
        <Stack spacing={5}>
          <div>
            <p>
              <span>Вы точно хотите удалить:</span>&nbsp;
              <b title={dashboardService.dashboards.entities[dashboardId ?? '']?.name}>
                {cutText(dashboardService.dashboards.entities[dashboardId ?? '']?.name || '', 20, true)}
              </b>
              <span>?</span>
            </p>
          </div>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
          Отмена
        </Button>
        <Button
          loading={dashboardService.isLoadingDeleteDashboard}
          variant={ButtonVariants.Popup}
          onClick={handleDialogOk}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
});

DeleteDashboardModal.displayName = 'DeleteDashboardModal';

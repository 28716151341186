import { FC, memo } from 'react';

import { ButtonUnstyledProps } from '@mui/base';
import cn from 'classnames';

import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';

import styles from './PlusButton.module.scss';

export const PlusButton: FC<ButtonUnstyledProps> = memo((props) => {
  return (
    <button {...props} aria-label='add' type='button' tabIndex={1} className={cn(styles.root, 'nodrag')}>
      <PlusIcon width={10} height={10} id={props.id} />
    </button>
  );
});

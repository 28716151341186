import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';

import { GridLayout, Header } from './components';

import styles from './DashboardPlatform.module.scss'

const dashboardService = container.resolve(DashboardService);


export const DashboardPlatform: FC = observer(() => {
    const dashboard = dashboardService.selectedDashboard

    const save = () => {
      dashboardService.updateDashboard(dashboard)
    }

    return (
      <div className={styles.root}>
        <Header
          save={save}
        />
        <div className={styles.body}>
          <GridLayout layouts={dashboard?.data?.layouts || {}} />
        </div>
      </div>
    );
  },
)
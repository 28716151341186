import React, { FC } from 'react';

import {
  Tooltip as TooltipMaterial,
  tooltipClasses,
  TooltipProps as TooltipMaterialProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export type TooltipProps = TooltipMaterialProps
    & {
      isTooltipActive?: boolean | string | undefined
    };

const CustomizedTooltip = styled(({ className, children, ...props }: TooltipMaterialProps) => {
  return (
    <TooltipMaterial {...props} classes={{ popper: className }}>
      {children}
    </TooltipMaterial>
  );
})(() => {
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#FFFFFF',
      fontSize: '13px',
      fontWeight: 600,
      color: '#0049C6',
      fontFamily: 'unset',
      borderRadius: '6px',
      padding: ' 4px 12px',
      maxWidth: 500,
      lineHeight: '18px',
      whiteSpace: 'pre-wrap',
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFFFFF',
      fontSize: '8px',
    },
  };
});

/**
 * TODO
 * Проблемный компонент, найти способ переписать стили на CSS
 */
const Tooltip: FC<TooltipProps> = ({ isTooltipActive = true, children, ...props }) => {
  if (isTooltipActive) {
    return (
        <CustomizedTooltip {...props} arrow enterDelay={500} enterNextDelay={500} leaveDelay={100} leaveTouchDelay={100} enterTouchDelay={500}>
          {children}
        </CustomizedTooltip>
    );
  } else {
    return children
  }
};

export default Tooltip;

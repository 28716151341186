export interface Normalized<T> {
  entities: { [key: string]: T };
  ids: string[];
}

export const normalize = <T>(list: T[], key: keyof T): Normalized<T> => {
  const entities: any = {};
  const ids: Set<any> = new Set();

  list.forEach((element) => {
    entities[element[key]] = element;
    ids.add(element[key]);
  });

  return { ids: Array.from(ids), entities };
};

import { Mode } from '../enums';
export const commonAcceptedMode = (mode) => [
    Mode.AND,
    Mode.RANGE,
    Mode.INCREMENT,
    Mode.EVERY
].includes(mode);
export const dayOfMonthAcceptedMode = (mode) => commonAcceptedMode(mode) || [
    Mode.NONE,
    Mode.LAST_DAY,
    Mode.NEAREST_WEEKDAY_OF_MONTH,
    Mode.DAYS_BEFORE_END_MONTH,
    Mode.LAST_DAY_WEEK
].includes(mode);
export const dayOfWeekAcceptedMode = (mode) => commonAcceptedMode(mode) || [
    Mode.NONE,
    Mode.NTH_WEEKDAY_OF_MONTH,
    Mode.LAST_NTH_DAY_WEEK
].includes(mode);
export const monthAcceptedMode = (mode) => commonAcceptedMode(mode) || mode === Mode.NONE;

import { FC, memo } from 'react';

import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import { Stack } from '@mui/system';

import styles from './TextWithIndicator.module.scss';

export interface TextWithIndicatorProps {
  indicatorType: IndicatorTypes;
  text: string;
}

/**
 * Текст с индикатором для отображения в блоке
 */
export const TextWithIndicator: FC<TextWithIndicatorProps> = memo(({ indicatorType, text }) => {
  return (
    <div className={styles.root}>
      <Stack direction='row' spacing={0.5} alignItems='center'>
        <Indicator type={indicatorType} />
        <p className={styles.description}>{text}</p>
      </Stack>
    </div>
  );
});

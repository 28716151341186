import React, { memo, useCallback, useMemo } from 'react';

import { Radio } from '@/shared/ui/Radio/Radio';
import { Select } from '@/shared/ui/CronSelect/Select';
import { Grid } from '@/shared/ui/Grid/Grid';
import { SelectSizes } from '@/shared/ui/CronSelect/types';

import { Mode } from '../../../../cron-core';
import { CronBaseProps } from '../../../cron-base-props.type';

type Props = {
  checked?: boolean;
  disabled?: boolean;
  onSelect: () => void;
  primaryOptions: {
    label: string | number;
    value: string;
  }[];
  primaryValue: string;
  onPrimaryValueChange: (value: unknown) => void;
  secondaryOptions: {
    label: string | number;
    value: string;
  }[];
  secondaryValue: string;
  onSecondaryValueChange: (value: unknown) => void;
  label1: string;
  label2: string;
  segmentId: string;
} & CronBaseProps;

export const SimpleIncrement = memo(
  ({
    checked = false,
    disabled = false,
    label1,
    label2,
    onSelect,
    primaryOptions,
    primaryValue,
    onPrimaryValueChange,
    secondaryOptions,
    secondaryValue,
    onSecondaryValueChange,
    segmentId,
  }: Props) => {
    const memoizedPrimaryValue = useMemo(() => {
      return primaryOptions.find(({ value }) => value === primaryValue)?.value;
    }, [primaryOptions, primaryValue]);

    const memoizedSecondaryValue = useMemo(() => {
      return secondaryOptions.find(({ value }) => value === secondaryValue)?.value;
    }, [secondaryOptions, secondaryValue]);

    const handlePrimarySelectChange = useCallback(
      (event: any, value: any) => {
        onPrimaryValueChange(value);
      },
      [onPrimaryValueChange]
    );

    const handleSecondarySelectChange = useCallback(
      (event: any, value: any) => {
        onSecondaryValueChange(value);
      },
      [onSecondaryValueChange]
    );

    return (
      <Grid xs={12} container alignItems='center' gap={checked ? 4 : 1}>
        <Grid>
          <Radio
            id={segmentId}
            value={Mode.INCREMENT}
            checked={checked}
            disabled={disabled}
            onChange={onSelect}
            label={`${label1} ${checked ? '' : 'N'}`}
          />
        </Grid>
        {checked && (
          <Grid xs={1.5}>
            <Select
              size={SelectSizes.Small}
              defaultValue={memoizedPrimaryValue}
              onChange={handlePrimarySelectChange}
              options={primaryOptions}
            />
          </Grid>
        )}
        <Grid>
          <label htmlFor={segmentId}>
            {label2} {!checked && 'N'}
          </label>
        </Grid>
        {checked && (
          <Grid xs={1.5}>
            <Select
              size={SelectSizes.Small}
              defaultValue={memoizedSecondaryValue}
              onChange={handleSecondarySelectChange}
              options={secondaryOptions}
            />
          </Grid>
        )}
      </Grid>
    );
  }
);

import { FC, memo, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { Box } from '@mui/system';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { container } from 'tsyringe';

import { UpsertKeyValueService } from '../api/UpsertKeyValueService';

const upsertKeyValueService = container.resolve(UpsertKeyValueService);

export interface UpdateKeyValueButtonProps {
  keyValueId: string;
}

export const UpdateKeyValueButton: FC<UpdateKeyValueButtonProps> = memo(
  ({ keyValueId }) => {
    const handleClick = useCallback(() => {
      upsertKeyValueService.open = true;
      upsertKeyValueService.selectedId = keyValueId;
    }, [keyValueId]);

    return (
      <Tooltip title='Редактировать ключ-значение' placement='top'>
        <Box>
          <Button
            circle
            size={ButtonSizes.Small}
            variant={ButtonVariants.Outlined}
            onClick={handleClick}
          >
            <SettingsIcon />
          </Button>
        </Box>
      </Tooltip>
    );
  }
);

UpdateKeyValueButton.displayName = 'UpdateKeyValueButton';

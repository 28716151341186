import { makeAutoObservable } from 'mobx';
import { PropertiesListService } from '@/entities/Properties';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { Parameter } from '@/entities/Connect/types';
import { Settings, SettingsStore } from '@/entities/Settings';

@singleton()
export class UpsertSettingsService {
  propertiesNameOptions: string[];
  isLoadingSkeleton: boolean;
  isLoadingUpdateSettings: boolean;
  parameters: Parameter[];

  constructor(
    private propertiesListService: PropertiesListService,
    private apiService: ApiService,
    private settingsStore: SettingsStore
  ) {
    this.propertiesNameOptions = [];
    this.isLoadingSkeleton = false;
    this.isLoadingUpdateSettings = false;
    this.parameters = [];

    makeAutoObservable(this);
  }

  async initial() {
    await this.propertiesListService.getProperties();

    if (this.propertiesListService.properties?.entities) {
      this.propertiesNameOptions = Object.values(
        this.propertiesListService.properties.entities
      ).map((property) => property.name || '');
    }
  }

  async getSkeleton(settings: any) {
    try {
      this.isLoadingSkeleton = true;

      return await this.apiService.instance.post<{ parameterList: Parameter[] }>(
        '/editor/settings/skeleton',
        settings
      );
    } finally {
      this.isLoadingSkeleton = false;
    }
  }

  async updateSettings(settings: Settings) {
    try {
      this.isLoadingUpdateSettings = true;

      const response = await this.apiService.instance.put<Settings>('/editor/settings', settings);

      this.settingsStore.settings = response.data;

      return response.data;
    } finally {
      this.isLoadingUpdateSettings = false;
    }
  }
}

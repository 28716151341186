import { Segment } from '../enums';
import { DayOfWeekValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class DayOfWeekContainer extends BaseContainer {
    getSegment() {
        return Segment.dayOfWeek;
    }
    cloneWith(d) {
        return new DayOfWeekContainer(d);
    }
    createValues(values) {
        return new DayOfWeekValues(values);
    }
}

import React, { FC, memo } from 'react';

import { useFormContext } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Stack from '@mui/material/Stack';
import { FieldCron } from '@/shared/ui/Fields/components/FieldCron/FieldCron';
import { Notification } from '@/entities/Notification';
import { FieldInputNumber } from '@/shared/ui/Fields/components/FieldInputNumber/FieldInputNumber';

import { SelectFlows } from './SelectFlows';
import { EmailList } from './EmailList';

export interface MainNotificationSettingsProps {
  notificationId: string | null;
}

export const MainNotificationSettings: FC<MainNotificationSettingsProps> = memo(
  ({ notificationId }) => {
    const {
      register,
      control,
      getValues,
      formState: { errors },
    } = useFormContext<Notification>();

    return (
      <Stack gap={3}>
        <FieldInput error='' name='subject' register={register} label='Тема обращения' />
        <SelectFlows />
        <EmailList name='emailList' title='Кому отправить' />
        <Grid container spacing={2.5}>
          <Grid xs={12} lg={6} xl={6} item>
            <FieldInputNumber
              error={errors.interval}
              name='interval'
              defaultValue='1'
              min={1}
              step={1}
              control={control}
              label='Количество минут за которое будут взяты ошибки'
              register={register}
            />
          </Grid>
          <Grid xs={12} lg={6} xl={6} item>
            <FieldCron
              control={control}
              getValues={getValues}
              error=''
              name='cron'
              defaultValue='0 * * ? * * *'
              label='Таймер отправки уведомления'
              notificationId={notificationId}
            />
          </Grid>
        </Grid>
      </Stack>
    );
  }
);

MainNotificationSettings.displayName = 'MainNotificationSettings';

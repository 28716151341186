import * as React from 'react';
import { FC, memo } from 'react';

import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Controller, Control } from 'react-hook-form';

export interface FieldControlProps extends FieldProps {
  control: Control;
  Field: FC<{[key: string]: any;}>;
  data?: any[];
}

/**
 * Компонент CronSelect для генератора форм
 */
export const FieldControl: FC<FieldControlProps> = memo(
  ({
    control,
    name,
    defaultValue,
    description,
    Field,
    onChange,
    ...props
  }) => {
    return (
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field }) => {
          const handleChange = (value: any) => {
            field.onChange(value);
            if (onChange) onChange();
          };
          return (
            <Field
              name={field.name}
              value={field.value}
              onChange={handleChange}
              tooltip={description}
              {...props}
            />
          );
        }}
      />
    );
  }
);

import { DBSchema, IDBPDatabase, openDB } from 'idb';
import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { IndexedDBStore } from '@/store/IndexedDBStore/IndexedDBStore';
import { notify } from '@/shared/ui/Toast/notify';
import { Connect } from '@/entities/Connect/types';

export interface FieldDataDB extends DBSchema {
  fieldData: {
    key: string;
    value: {
      value: Connect;
      connectorName: string;
      date: Date;
    };
  };
}

let db: IDBPDatabase<FieldDataDB>;

@injectable()
export class IndexedDBService {
  openNotification: boolean;

  constructor(private indexedDBStore: IndexedDBStore) {
    this.openNotification = false;

    makeAutoObservable(this);
  }

  /** Создание базы */
  initDB = async () => {
    db = await openDB<FieldDataDB>('fieldDataDB', 1, {
      upgrade(db) {
        if (!db.objectStoreNames.contains('fieldData')) {
          db.createObjectStore('fieldData');
        }
      },
    });
  };

  /** Функция  сохранения данных  */
  saveFieldData = async (key: string, value: Connect, date: Date) => {
    if (!db) await this.initDB();
    return db.put('fieldData', { value, connectorName: key, date }, key);
  };

  /** Подсчет срока хранения записи */
  daysBetween = (startDate: Date, endDate: Date) => {
    const diffTime = Math.abs(
      Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate()) -
        Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
    );
    return Math.round(diffTime / (1000 * 60 * 60 * 24));
  };

  /** Функция поиска данных по ключу */
  getFieldData = async (key: string): Promise<Connect> => {
    if (!db) await this.initDB();
    try {
      const response = await db.get('fieldData', key);
      const today = new Date();
      const date = new Date(response?.date);
      const limitDays = 7;
      const diffDays = this.daysBetween(today, date);
      if (diffDays >= limitDays) {
        await this.deleteFieldData(key);
      }
      if (diffDays <= limitDays) {
        this.indexedDBStore.connector = response?.value;
        this.indexedDBStore.connectName = response?.connectorName;
        this.indexedDBStore.isShowIndexedDB = true;
        return this.indexedDBStore.connector;
      }
    } catch (err) {
      notify.error('Не удалось получить данные');
    }
  };

  /** Удаление записи */
  deleteFieldData = async (key: string) => {
    if (!db) await this.initDB();
    return db.delete('fieldData', key);
  };
}

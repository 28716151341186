import React, { useEffect } from 'react';

import cn from 'classnames';
import { Button } from 'antd';
import {
  MenuOutlined,
  MenuUnfoldOutlined,
} from '@ant-design/icons';
import { map } from 'lodash';
import { Popover, Stack } from '@mui/material';
import { useSearchParamsTemplate, SearchParams } from '@/hooks/useTemplateSearchParams';
import { actionsSearchParams } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';

import styles from './FlowSidebar.module.scss';


const modes = ['tree', 'list'] as const;
type ViewMode = typeof modes[number];

export const defaultMode = 'list';

export const castMode = (urlMode: string) => {
  const mode = urlMode as ViewMode;
  const lsMode = localStorage.getItem('flow.viewMode');
  return modes.includes(mode) ? mode : (lsMode || defaultMode);
}


export const useMode = () => { // sync income link with mode
  const { get, set, remove } = useSearchParamsTemplate();
  const mode = castMode(get(SearchParams.FlowsMode));

  useEffect(() => {
    if (mode === defaultMode) remove([SearchParams.FlowsMode, SearchParams.ItemId]);
    else set({ mode });
  }, []);
}

export function ModeSwitcher() {
  const { set, remove } = useSearchParamsTemplate(); // virtual
  const params = new URLSearchParams(window.location.search); // certainly this way need for UnsavedChangesModal
  const viewMode = params.get(SearchParams.FlowsMode) || defaultMode;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const changeMode = (
    mode: ViewMode,
  ) => {
    localStorage.setItem('flow.viewMode', mode);
    remove(actionsSearchParams);
    if (mode === defaultMode) remove([SearchParams.FlowsMode, SearchParams.ItemId]);
    else set({ mode })
  };

  const handleHidePopover = (node: Element) => {
    const handleMouseEnter = () => {
      node.removeEventListener('mouseenter', handleMouseEnter);
      node.addEventListener('mouseleave', handleClose);
    }
    node.addEventListener('mouseenter', handleMouseEnter);
  }

  const rendersMap = {
    list: {
      label: 'список',
      Icon: MenuOutlined,
    },
    tree: {
      label: 'дерево',
      Icon: MenuUnfoldOutlined,
    },
  };
  const settings = map(rendersMap, ({ Icon, label }, key: ViewMode) => (
    <div key={key} style={{ textAlign: 'center' }}>
      <Button
        key={key}
        className={cn(styles.btn, {
          active: key !== viewMode,
        })}
        size='large'
        icon={<Icon style={{ fontSize: '1em' }} />}
        onClick={() => changeMode(key)}
      />
      <br />
      {label}
    </div>
  ));
  // @ts-ignore
  const { Icon } = rendersMap[viewMode];
  return (
    <>
      <Button
        size='small'
        type='text'
        icon={<Icon />}
        onClick={handleClick}
      />
      <Popover
        classes={{
          paper: styles.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        TransitionProps={{
          onEnter: handleHidePopover,
        }}
      >
        <Stack direction="row" spacing={1} sx={{ p: 1.5, bgcolor: '#3e4354' }}>
          {settings}
        </Stack>
      </Popover>
    </>
  );
}

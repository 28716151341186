import React from 'react';

import { AuthProvider } from 'react-oidc-context';

import KeyCloakProve from './KeyCloakProve';

const KeyCloakAuth = (props) => {
  const {
    clientId = 'integra_site',
    issuer = 'http://localhost:8080/realms/integra_test',
    clientSecret,
    children,
  } = props;

  return (
    <AuthProvider
      {...{
        //realm: realm,
        client_id: clientId,
        authority: issuer,
        redirect_uri: window.location.origin,
        client_secret: clientSecret,
        automaticSilentRenew: true,
      }}
    >
      <KeyCloakProve>{children}</KeyCloakProve>
    </AuthProvider>
  );
};

KeyCloakAuth.propTypes = {};

export default KeyCloakAuth;
export const MemorizedKeyCloakAuth = React.memo(KeyCloakAuth);

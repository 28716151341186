import axios from 'axios';
import { getBaseUrl } from '@/shared/api/Api/services/ApiService';

export const api = axios.create({
  baseURL: getBaseUrl(window.location),
});

api.interceptors.request.use((config) => {
  let authorization: null;

  try {
    authorization = JSON.parse(localStorage.getItem('headerAuth') || '');
  } catch {
    authorization = null;
  }

  config.headers.authorization = authorization;
  config.headers.set('company-id', JSON.parse(localStorage.getItem('companyId') || ''));

  return config;
});

import { Dispatch, SetStateAction, useEffect, useState } from 'react';

export const useInfiniteScroll = (
  fetchPage: (page: number) => void,
  lastPage: number
): { setLastElement: Dispatch<SetStateAction<HTMLLIElement | null>> } => {
  const [lastElement, setLastElement] = useState<HTMLLIElement | null>(null);
  const [page, setPage] = useState(0);
  const [intersectionObserver, setIntersectionObserver] = useState<any>();

  useEffect(() => {
    setIntersectionObserver(
      new IntersectionObserver((entries) => {
        const first = entries[0];
        if (first.isIntersecting) {
          setPage((prevState) => {
            if (prevState + 1 < lastPage) {
              return prevState + 1;
            }

            return prevState;
          });
        }
      })
    );
  }, [lastPage]);

  useEffect(() => {
    const currentElement = lastElement;

    if (currentElement) {
      intersectionObserver?.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        intersectionObserver?.unobserve(currentElement);
      }
    };
  }, [lastElement, intersectionObserver]);

  useEffect(() => {
    void fetchPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  /**
   * Необходимо если делаем ресет списка
   */
  useEffect(() => {
    if (lastPage === 0) {
      setPage(0);
    }
  }, [lastPage]);

  return { setLastElement };
};

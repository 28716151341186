import { FC, memo, useCallback } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import Box from '@mui/system/Box';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { DeletePropertyDialog } from '@/features/DeleteProperty';
import { useNavigate } from 'react-router-dom';

import { PropertiesList } from './PropertiesList/ui/PropertiesList';

export interface PropertySidebarProps {}

export const PropertySidebar: FC<PropertySidebarProps> = memo(() => {
  const { set } = useSearchParamsTemplate();
  const navigate = useNavigate();

  const handleAdd = useCallback(() => {
    navigate('./');
    set([SearchParams.ShowPropertyForm]);
  }, [navigate, set]);

  const handleDialogSuccess = useCallback(() => {
    navigate('./');
  }, [navigate]);

  return (
    <>
      <Box bgcolor='var(--paper-color)'>
        <Sidebar
          title='Конфигурации'
          textAddButton='Создать конфигурацию'
          onAdd={handleAdd}
          addonAfter={
            <Box height='calc(100vh - 224px)' overflow='auto' width='100%'>
              <PropertiesList />
            </Box>
          }
        />
      </Box>
      <DeletePropertyDialog onSuccess={handleDialogSuccess} />
    </>
  );
});

PropertySidebar.displayName = 'PropertySidebar';

import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { NotFoundConnectorService } from '@/features/NotFoundConnector';

const notFoundConnectorService = container.resolve(NotFoundConnectorService);

export interface NotFoundConnectorCatcherProps {
  thenElement: JSX.Element;
  catchElement: JSX.Element;
}

export const NotFoundConnectorCatcher: FC<NotFoundConnectorCatcherProps> = observer(
  ({ catchElement, thenElement }) => {
    if (notFoundConnectorService.connectorIsNotExist) {
      return catchElement;
    }

    return thenElement;
  }
);

NotFoundConnectorCatcher.displayName = 'NotFoundConnectorCatcher';

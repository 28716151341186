import { useQuery, UseQueryResult } from 'react-query';
import { RequestFilter } from '@/entities/Flow/types';

import { api } from '../../service/apiInstance';
import { IMonitoringSearch } from './types';

export const useTracing = ({
  onlyError = false,
  flowIdList = [],
  filterList,
  page = 0,
  rowsPerPage = 0,
  timeInterval = {},
  getData = true,
  getCount = true,
  key = 'tracingList'
}: {
  page: number;
  rowsPerPage: number;
  onlyError: boolean;
  flowIdList?: string[];

  filterList?: RequestFilter[];

  timeInterval?: {
    start?: Date | null;
    end?: Date | null;
  };
  getData?: boolean;
  getCount?: boolean;
  key?: string
}): UseQueryResult<IMonitoringSearch> => {
  return useQuery<IMonitoringSearch>({
    keepPreviousData: false,
    refetchInterval: 5000,
    enabled: false,
    queryKey: [key],
    queryFn: async () => {
      const response = await api.post<IMonitoringSearch>('editor/api/v2/monitoring/search/byTracingTable', {
        onlyError,
        flowIdList,
        timeInterval,
        filterList,
        pagination: {
          page: page,
          size: rowsPerPage,
          sorted: true,
        },
        getData,
        getCount
      });

      return response.data;
    },
  });
};

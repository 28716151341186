import { singleton } from 'tsyringe';
import { ValidationConnectStore } from '@/entities/Connect/stores/ValidationConnectStore';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { makeAutoObservable } from 'mobx';
import { Connect, ValidationResponse } from '@/entities/Connect/types';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import * as yup from 'yup';

const urls = {
  input: 'editor/connect/inputValidation',
  output: 'editor/connect/outputValidation',
  processor: 'editor/processor/validation',
};

@singleton()
export class ValidationConnectService {
  constructor(
    private validationConnectStore: ValidationConnectStore,
    private apiService: ApiService
  ) {
    makeAutoObservable(this);
  }

  get errors() {
    const { paramsDescription, queryParamsDescription } =
      this.validationConnectStore.validationResponse;

    if (!_.isEmpty(paramsDescription) || !_.isEmpty(queryParamsDescription)) {
      const transformErrors = (errors: { [key: string]: string[] }) => {
        return Object.keys(errors).reduce((previousValue, currentValue) => {
          previousValue[currentValue] = { message: errors[currentValue][0] };

          return previousValue;
        }, {} as any);
      };

      return {
        params: transformErrors(paramsDescription || {}),
        paramsObject: transformErrors(queryParamsDescription || {}),
      };
    }

    // if (!_.isEmpty(warningParamsDescription) || !_.isEmpty(warningQueryParamsDescription)) {
    //   const transformWarnings = (warnings: { [key: string]: string[] }) => {
    //     return Object.keys(warnings).reduce((previousValue, currentValue) => {
    //       previousValue[currentValue] = { message: warnings[currentValue][0] };
    //
    //       return previousValue;
    //     }, {} as any);
    //   };
    //
    //   return {
    //     warningParams: transformWarnings(warningParamsDescription || {}),
    //     warningParamsObject: transformWarnings(warningQueryParamsDescription || {}),
    //   };
    // }

    return {};
  }

  get warnings() {
    const { warningParamsDescription, warningQueryParamsDescription } =
      this.validationConnectStore.validationResponse;

    if (!_.isEmpty(warningParamsDescription) || !_.isEmpty(warningQueryParamsDescription)) {
      const transformWarnings = (warnings: { [key: string]: string[] }) => {
        return Object.keys(warnings).reduce((previousValue, currentValue) => {
          previousValue[currentValue] = { message: warnings[currentValue][0] };

          return previousValue;
        }, {} as any);
      };

      return {
        params: transformWarnings(warningParamsDescription),
        paramsObject: transformWarnings(warningQueryParamsDescription || {}),
      };
    }

    return {};
  }

  get validationSchema() {
    return yup.object({
      name: yup
        .string()
        .default('')
        .max(100, 'Название коннектора не должно превышать 100 символов'),
      description: yup
        .string()
        .default('')
        .max(1000, 'Описание коннектора не должно превышать 1000 символов'),
      isActive: yup.boolean().default(true),
    });
  }

  get isValid() {
    return this.validationConnectStore.isValid;
  }

  async validateConnectForm(connectType: string, connect: Connect) {
    const response = await this.apiService.instance.post<
      Connect,
      AxiosResponse<ValidationResponse>
    >(urls[connectType as keyof typeof urls], connect);
    this.validationConnectStore.validationResponse = response.data;

    return response;
  }

  clear() {
    this.validationConnectStore.clear();
  }
}

import { singleton } from 'tsyringe';
import { DataNode } from 'rc-tree/es/interface';
import { makeAutoObservable } from 'mobx';

import { Parameter } from '../../Connect/types';

@singleton()
export class Connector1cStore {
  parameters: Parameter[];
  treeData: DataNode[];

  constructor() {
    this.parameters = [];
    this.treeData = [];

    makeAutoObservable(this);
  }
}

import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import Button from '@/shared/ui/Button';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui/Dialog';
import { ButtonVariants } from '@/shared/ui/Button/types';

const joyrideService = container.resolve(JoyRideService);

export const CloseOnboarding: FC = observer(() => {
  const handleOnboardingNext = useCallback(() => {
    // здесь логика сохранения шага
  }, []);

  const handleOnboardingClose = useCallback(() => {
    joyrideService.handleClose(false);
    // const stepI = joyrideService.stepIndex;
    // joyrideService.unpause();
    // joyrideService.updateCurrentStep(stepI);
  }, []);

  const handleOnboardingOk = useCallback(() => {
    // joyrideService.handleJoyRide(false);
    // joyrideService.updateCurrentStep(0);
    joyrideService.handleClose(false);
  }, []);

  return joyrideService.isClose ? (
    <Dialog
      open={joyrideService.isClose}
      sx={{ '.MuiPaper-root': { maxWidth: 700, width: '100%' } }}
      onClose={handleOnboardingClose}
    >
      <DialogTitle onClose={handleOnboardingClose}>Закрытие онбординга</DialogTitle>
      <DialogContent>Вы точно хотите закрыть обучение?</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleOnboardingClose}>
          Отмена
        </Button>
        <Button variant={ButtonVariants.Secondary} onClick={handleOnboardingNext}>
          Сохранить обучение и закрыть
        </Button>
        <Button variant={ButtonVariants.Popup} onClick={handleOnboardingOk}>
          Да
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
});

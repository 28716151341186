import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { PythonScript } from '@/entities/PythonBean';
import { UpsertPythonBeanForm } from '@/features/UpsertPythonBean';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { DeletePythonBeanDialog } from '@/features/DeletePythonBean';
import Paper from '@mui/material/Paper';

export interface PythonBeanContentProps {
  hideChooseScriptButton?: boolean;
}

export const PythonBeanContent: FC<PythonBeanContentProps> = observer(
  ({ hideChooseScriptButton }) => {
    const { get, set, remove } = useSearchParamsTemplate();

    const pythonScriptId = get(SearchParams.PythonScriptId);

    const handleFormSuccess = (pythonScript: PythonScript) => {
      set([{ [SearchParams.PythonScriptId]: pythonScript.id }]);
    };

    const handleDialogSuccess = () => {
      remove([SearchParams.PythonScriptId]);
    };

    return (
      <>
        <Paper elevation={0} sx={{ padding: 4 }}>
          <UpsertPythonBeanForm
            pythonScriptId={pythonScriptId}
            hideChooseScriptButton={hideChooseScriptButton}
            onSuccess={handleFormSuccess}
          />
        </Paper>
        <DeletePythonBeanDialog pythonScriptId={pythonScriptId} onSuccess={handleDialogSuccess} />
      </>
    );
  }
);

PythonBeanContent.displayName = 'PythonBeanContent';

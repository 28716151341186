import { FC, memo } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import dayjs from 'dayjs';
import Tooltip from '@/shared/ui/Tooltip';
import { CardActions } from '@mui/material';

import { License } from '../model/types';

export interface LicenseCardProps extends License {
  actions: JSX.Element | JSX.Element[];
}

type EndDate = string | Date | number;

export const LicenseCard: FC<LicenseCardProps> = memo(
  ({licenseBody, validLicenseBody, error, actions}) => {
    const checkIfLicenseExpiresThisMonth = (endDate: EndDate) => {
      const now = dayjs();
      const end = dayjs(endDate);

      return now.month() === end.month() && now.year() === end.year();
    };

    const isLicenseExpired = (endDate: EndDate) => {
      const now = dayjs();
      const end = dayjs(endDate);

      return now.isAfter(end);
    };
    const dayNotValid = licenseBody?.dateEnd ? dayjs(licenseBody?.dateEnd).isBefore(dayjs()) : false;
    const shouldNotify = licenseBody?.dateEnd ? (checkIfLicenseExpiresThisMonth(licenseBody?.dateEnd) && !isLicenseExpired(licenseBody?.dateEnd)) : false;

    const getIndicatorType = () => {

      if (shouldNotify) {
        return IndicatorTypes.Warning;
      }

      if (validLicenseBody && !dayNotValid && (licenseBody?.blockLimit !== undefined && licenseBody.blockLimit > 0)) {
        return IndicatorTypes.Success;
      }

      return IndicatorTypes.Error;
    }

    const renderCountBlockLimit = (blockLimit) => {
        if (blockLimit === undefined || blockLimit === null) {
            return '-'
        }
        if (blockLimit === 999999999 || blockLimit === 999999999999) {
            return '∞'
        } else {
            return blockLimit
        }
    }

    return (
      <Card variant='outlined' sx={{ minWidth: 305, minHeight: 144, display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
        <CardContent>
          <Stack gap={0.5}>
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontWeight={600} fontSize='0.75rem'>
                Состояние ключа:
              </Typography>
              <Tooltip title={error} placement='top'>
                <span>
                  <Indicator
                    type={getIndicatorType()}
                  />
                </span>
              </Tooltip>
            </Stack>

              {licenseBody?.name && <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                      {licenseBody?.name}
                  </Typography>
              </Stack>}
              {licenseBody?.description && <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                      Описание:
                  </Typography>
                  <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                      {licenseBody?.description}
                  </Typography>
              </Stack>}

              {licenseBody?.dateStart ? <Stack direction='row' justifyContent='space-between' alignItems='center'>
                  <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                      Лицензия выдана:
                  </Typography>
                  <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                      {dayjs(licenseBody?.dateStart).format('DD.MM.YYYY HH:mm:ss')}
                  </Typography>
              </Stack>
                  : undefined
              }
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                Ограничение по сроку:
              </Typography>
              <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                {licenseBody?.dateEnd ? dayjs(licenseBody?.dateEnd).format('DD.MM.YYYY HH:mm:ss') : '-'}
              </Typography>
            </Stack>

            <Stack direction='row' justifyContent='space-between' alignItems='center'>
              <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                Количество блоков:
              </Typography>
              <Typography fontSize='0.75rem' sx={{ opacity: 0.5 }}>
                {renderCountBlockLimit(licenseBody?.blockLimit)}
              </Typography>
            </Stack>

            {shouldNotify ?
              <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography fontSize='0.75rem'>
                  Лицензия истекает в этом месяце
                </Typography>
              </Stack>
              : ''}
          </Stack>
        </CardContent>
        <CardActions>{actions}</CardActions>
      </Card>
    );
  }
);

LicenseCard.displayName = 'LicenseCard';

import { inject, injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { RegistrableValues } from '@/shared/lib/types';

import { ElementEditor } from '../types';
import { FlowCanvasStore } from '../stores/FlowCanvasStore';

@injectable()
export class FlowCanvasService {
  constructor(
    @inject(RegistrableValues.FlowId) private flowId: string,
    private flowCanvasStore: FlowCanvasStore
  ) {
    makeAutoObservable(this);
  }

  get elements(): Normalized<ElementEditor> | null {
    return this.flowCanvasStore.elements?.[this.flowId] || null;
  }

  updateElement(blockId: string, top: number, left: number, height?: number, width?: number): void {
    if (this.flowCanvasStore.elements) {
      const element = this.flowCanvasStore.elements[this.flowId].entities[blockId];
      const {
        top: prevTop,
        left: prevLeft,
        sx: { height: prevHeight, width: prevWidth },
      } = element;

      if (prevTop !== top || prevLeft !== left || prevHeight !== height || prevWidth !== width) {
        eventEmitter.emit(CustomEvents.UpdateCanvas);

        element.sx.height = height ?? prevHeight;
        element.sx.width = width ?? prevWidth;
        element.top = top;
        element.left = left;
      }
    }
  }
}

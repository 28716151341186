const regex = /\${([\s\S]+?)}/g;

/**
 * Редактирует text из Flow.relationSet
 * @param text
 */
export const formatRelationText = (text: string): string | undefined => {
  let formattedText = text;

  // Убираю лишние пробелы
  formattedText = formattedText.replaceAll('\\n ', '\\n');

  try {
    if (regex.test(formattedText)) {
      // Иногда приходит текст с переменной, по идее на ее месте должно что то быть, но если значения для перменной нет,
      // то бек присылает необработанную строку, убираю переменную и вместо нее вписываю строку
      formattedText = formattedText.replaceAll(regex, 'Отсутствует');
    }

    return JSON.parse(`"${formattedText}"`);
  } catch {
    return undefined;
  }
};

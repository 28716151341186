import { Segment } from '../enums';
import { BaseValues } from './base.abstract';
export class SecondsValues extends BaseValues {
    constructor() {
        super(...arguments);
        this.segment = Segment.seconds;
    }
    clone(d = {}) {
        return new SecondsValues(Object.assign(Object.assign({}, this), d));
    }
}

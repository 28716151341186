import { makeAutoObservable } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';
import { Version } from '@/entities/FlowVersion';
import { singleton } from 'tsyringe';

@singleton()
export class SaveVersionService {
  isLoading: boolean;
  showForm: boolean;

  constructor(private apiService: ApiService) {
    this.isLoading = false;
    this.showForm = false;

    makeAutoObservable(this);
  }

  async saveVersion(version: Version) {
    this.isLoading = true;

    try {
      await this.apiService.instance.put('/editor/version/save', version);
    } catch (error) {
      notify.error('Не удалось сохранить версию');
    } finally {
      this.isLoading = false;
    }
  }
}

import { RefObject, useEffect, useRef, useState } from 'react';

export interface UseEllipsisTextReturn<RefType> {
  ellipsisTextRef: RefObject<RefType>;
  isEllipsisActive: boolean;
}

export const useEllipsisText = <RefType extends HTMLElement>(): UseEllipsisTextReturn<RefType> => {
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);

  const ref = useRef<RefType>(null);

  useEffect(() => {
    const label = ref.current;
    if (label) {
      if (label.offsetWidth < label.scrollWidth) {
        setIsEllipsisActive(true);
      }
    }
  }, [ref]);

  return { isEllipsisActive, ellipsisTextRef: ref };
};

import React, { FC, useCallback } from 'react';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PythonLibForm } from '@/entities/PythonLib/PythonLibForm/PythonLibForm';

export const PythonLibFormDrawer: FC = () => {
  const { has, get, remove } = useSearchParamsTemplate();
  const pythonLibName = get(SearchParams.PythonLibName);

  const isOpenDrawer =
    has(SearchParams.ShowAddPythonLibForm) || has(SearchParams.ShowEditPythonLibForm);

  const title: string = has(SearchParams.PythonLibName) ? 'Редактировать' : 'Добавить';

  const handleDrawerClose = useCallback((): void => {
    remove([
      SearchParams.PythonLibName,
      SearchParams.ShowAddPythonLibForm,
      SearchParams.ShowEditPythonLibForm,
    ]);
  }, [remove]);

  if (!isOpenDrawer) return null;

  return (
    <div className='grid-cols-1'>
      <Drawer width={434} title={title} onClose={handleDrawerClose}>
        <PythonLibForm pythonLibName={pythonLibName} />
      </Drawer>
    </div>
  );
};

import { FC, memo } from 'react';

import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import Tooltip from '@/shared/ui/Tooltip';

import styles from './WarningIcon.module.scss';

export interface IWarningIconProps {
  tooltip: string;
}

export const WarningIcon: FC<IWarningIconProps> = memo(({ tooltip }) => {
  return (
    <Tooltip title={tooltip} placement='top'>
      <span className={styles.root}>
        <ReportProblemRoundedIcon />
      </span>
    </Tooltip>
  )
});

WarningIcon.displayName = 'WarningIcon';
import * as types from './actionTypes';
import _ from "lodash";

const initState = {
    requests: {},
    states: {},
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.UPDATE_REQUEST_CACHE:
            return {
                ...state,
                requests: {
                    ...state.requests,
                    [action.key]:action.body
                },
            }
        case types.UPDATE_STATE_CACHE:
            let res = {...state}
            _.set(res.states, action.key, action.body)
            return res
        default:
            return state;
    }
}
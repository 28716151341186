import * as React from 'react';
import { FC, memo, useMemo } from 'react';

import { SelectOption } from '@mui/base';
import { isType } from '@/shared/lib/isType';
import SelectUnstyled, { SelectUnstyledProps } from '@mui/base/SelectUnstyled';
import { Option } from '@/shared/ui/CronSelect/Option';
import { Button } from '@/shared/ui/CronSelect/Button';
import cn from 'classnames';
import { SelectSizes } from '@/shared/ui/CronSelect/types';
import { Stack } from '@mui/system';
import { Label, LabelProps } from '@/shared/ui/Label/Label';

import styles from './Select.module.scss';

export interface SelectProps
  extends SelectUnstyledProps<any, boolean>,
    Omit<LabelProps, 'children'> {
  options: string[] | SelectOption<string>[];
  size?: SelectSizes;
  label?: string;
}

const slots = { root: Button };

export const Select: FC<SelectProps> = memo(
  ({ options, size, required, tooltip, label, hidden, ...props }) => {
    const slotProps = useMemo(
      () => ({
        listbox: { className: styles.listbox },
        popper: { className: styles.popper, keepMounted: false },
        root: { size, hidden },
      }),
      [hidden, size]
    );

    return (
      <div className={cn(styles.wrapper, { [styles.hidden]: hidden })}>
        <Stack spacing={2} position='relative'>
          {label && (
            <Label required={required} tooltip={tooltip}>
              {label}
            </Label>
          )}
          <SelectUnstyled {...props} slots={slots} slotProps={slotProps}>
            {options.length ? (
              options.map((option) => {
                const isOptionEqualSelectOptionType = isType<SelectOption<string>>(option, 'value');
                const value = isOptionEqualSelectOptionType ? option.value : option;
                const label = isOptionEqualSelectOptionType ? option.label : option;

                return (
                  <Option className={styles.option} key={value} value={value}>
                    {label}
                  </Option>
                );
              })
            ) : (
              <Option className={cn(styles.option, styles.noData)} value=''>
                Нет данных
              </Option>
            )}
          </SelectUnstyled>
        </Stack>
      </div>
    );
  }
);

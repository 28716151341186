import { FC, memo, useCallback } from 'react';

import { motion, PanInfo, useMotionValue } from 'framer-motion';
import { Stack } from '@mui/system';

import styles from './Resizable.module.scss';

export interface ResizableProps {
  maxWidth: number;
  minWidth?: number;
  initialWidth: number;
  children: JSX.Element;
}

export const Resizable: FC<ResizableProps> = memo(({ minWidth = 0, maxWidth, children, initialWidth }) => {
  const width = useMotionValue(initialWidth);

  const handleDrag = useCallback(
    (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
      const newWidth = width.get() - info.delta.x;

      if (newWidth > 0) {
        width.set(width.get() - info.delta.x);
      }
    },
    [width]
  );

  const handleDoubleClick = () => {
    if (width.get() === maxWidth) {
      width.set(minWidth);
    } else {
      width.set(maxWidth);
    }
  };

  return (
    <motion.div
      className={styles.root}
      initial={{ x: '100%' }}
      animate={{ x: 0 }}
      transition={{ ease: 'easeInOut' }}
      exit={{ x: '100%' }}
      style={{
        width,
        minWidth: `${minWidth}px`,
        maxWidth: `${maxWidth}px`,
      }}
    >
      <motion.button
        className={styles.handle}
        drag='x'
        dragConstraints={{ top: 0, left: 0, right: 0, bottom: 0 }}
        dragElastic={0}
        dragMomentum={false}
        onDrag={handleDrag}
        onDoubleClick={handleDoubleClick}
      />
      <div className={styles.content}>{children}</div>
    </motion.div>
  );
});

Resizable.displayName = 'Resizable';

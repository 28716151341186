import { FC } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import UploadFileJSON from '@/shared/ui/UploadFileJSON';
import { container } from 'tsyringe';
import { Version } from '@/entities/FlowVersion';
import { observer } from 'mobx-react-lite';

import { ImportVersionsService } from '../api/ImportVersionsService';

export interface ImportVersionsProps {}

const importVersionsService = container.resolve(ImportVersionsService);

export const ImportVersions: FC<ImportVersionsProps> = observer(() => {
  const handleAddFile = (version: Version) => {
    void importVersionsService.importVersion(version);
  };

  return (
    <UploadFileJSON addFile={handleAddFile}>
      <Button variant={ButtonVariants.Popup} loading={importVersionsService.isLoading}>
        Импорт версий
      </Button>
    </UploadFileJSON>
  );
});

ImportVersions.displayName = 'ImportVersions';

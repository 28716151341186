import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { User, UserStore } from '@/entities/User';

@singleton()
export class UpdateUserService {
  constructor(private apiService: ApiService, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  async updateUser(user: User) {
    const response = await this.apiService.instance.put<User>('/editor/user/save', user);

    this.userStore.users!.entities[user.id] = response.data;

    return response.data;
  }
}

import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import {Placement, Step} from 'react-joyride';
import { SaveChangesStore } from '@/entities/Onboarding/stores/SaveChangesStore';

type TStyles = {
  buttonNext: {
    display: string;
  };
  spotlight?: {
    borderRadius: string;
  };
};

interface IStep extends Step {
  styles: TStyles;
}

@injectable()
export class SaveChangesService {
  constructor(private saveStore: SaveChangesStore) {
    makeAutoObservable(this);
  }

  fetchCurrentStep = () => {
    return this.saveStore.getCurrentStep();
  };

  updateCurrentStepData = (updates: any) => {
    this.saveStore.updateStep(updates);
  };

  handleTarget = (target: string) => {
    this.saveStore.target = target;
  };

  handleContent = (content: string | JSX.Element) => {
    this.saveStore.content = content;
  };

  handlePlacement = (placement: Placement | 'auto' | 'center') => {
    this.saveStore.placement = placement;
  }

  updateCurrentSaveStep = (updates: Partial<Step>) => {
    if (!this.saveStore.currentStep) return;
    this.saveStore.currentStep = {
      ...this.saveStore.currentStep,
      ...updates
    };
  };

  setCurrentStep = (step: Step) => {
    this.saveStore.currentStep = step;
  };

  createStepObject = () => {
    const step: Step = {
      target: this.saveStore.target,
      content: this.saveStore.content,
      placement: this.saveStore.placement,
      disableBeacon: this.saveStore.disableBeacon,
      styles: this.saveStore.styles,
      title: this.saveStore.title,
      spotlightClicks: this.saveStore.spotlightClicks,
      disableScrolling: this.saveStore.disableScrolling,
      spotlightPadding: this.saveStore.spotlightPadding,
    };

    return step;
  };
}

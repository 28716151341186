import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { PropertiesStore } from '@/entities/Properties';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class DeletePropertyService {
  open: boolean;
  selectedId: string | null;

  constructor(private apiService: ApiService, private propertiesStore: PropertiesStore) {
    this.open = false;
    this.selectedId = null;

    makeAutoObservable(this);
  }

  async deleteProperty() {
    const response = await this.apiService.instance.delete(`/editor/properties/${this.selectedId}`);

    this.propertiesStore.properties!.ids.splice(
      this.propertiesStore.properties!.ids.indexOf(this.selectedId!),
      1
    );
    delete this.propertiesStore.properties!.entities[this.selectedId!];

    return response;
  }
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useBlocker, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { UnsavedChangesModel } from '@/entities/UnsavedChanges/index';

export interface UnsavedChangesModalProps<T> {
  title: string;
  text: string;
  store: UnsavedChangesModel<T>;
  onChangeSearchParams?: (cur: string, next: string) => boolean;

  onSave(): Promise<void>;

  onDecline?(): void;
}

export const UnsavedChangesModal = observer(function <T extends unknown>({
  text,
  store,
  title,
  onChangeSearchParams,
  onSave,
  onDecline,
}: UnsavedChangesModalProps<T>): JSX.Element {
  const location = useLocation();
  const [open, setOpen] = useState<boolean>(false);

  const blocker = useBlocker(store.areThereChanges);

  const isEqualPathname = useMemo(() => {
    return location.pathname === blocker.location?.pathname;
  }, [blocker.location?.pathname, location.pathname]);

  const isEqualSearchParams = onChangeSearchParams(location.search, blocker.location?.search);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOkClick = useCallback(() => {
    /**
     * TODO
     * Разбраться с тем почему не работает blocker.proceed?.() в промисе
     */
    void onSave();
    setOpen(false);
    blocker.proceed?.();
  }, [blocker, onSave]);

  const handleGoWithoutSaveClick = useCallback(() => {
    if (onDecline) onDecline();
    blocker.proceed?.();
    store.areThereChanges = false;
    setOpen(false);
  }, [blocker, store]);

  useEffect(() => {
    if (isEqualPathname && isEqualSearchParams) {
      blocker.proceed?.();
    } else if (blocker.state === 'blocked') {
      setOpen(true);
    }
  }, [blocker, isEqualPathname, isEqualSearchParams]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Button variant={ButtonVariants.Secondary} onClick={handleGoWithoutSaveClick}>
          Перейти без сохранения
        </Button>
        <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
          Отмена
        </Button>
        <Button
          variant={ButtonVariants.Popup}
          loading={store.isLoadingSave}
          onClick={handleOkClick}
        >
          Да
        </Button>
      </DialogActions>
    </Dialog>
  );
});

UnsavedChangesModal.displayName = 'UnsavedChangesModal';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { User, UserStore } from '@/entities/User';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

@singleton()
export class CreateUserService {

  constructor(private apiService: ApiService, private userStore: UserStore) {
    makeAutoObservable(this);
  }

  async requestAdminRule(user: Omit<User, 'id'>) {
    const response = await this.apiService.instance.post<User>('/editor/user/requestAdminRight', user);

    return response.data;
  }
}

import * as yup from 'yup';

export const messageDeliverySettingsFormValidationSchema = yup.object({
  isActiveGuaranteedDelivery: yup.boolean().default(true),
  isLimitedCountDelivery: yup.boolean().default(true),
  limitedCount: yup
    .number()
    .typeError('Необходимо ввести число')
    .integer('Должно быть целое число')
    .positive('Число должно быть положительным')
    .default(15),
  settingsGuaranteeDelivery: yup.object({
    countToTimeDelayList: yup
      .array()
      .of(
        yup.object({
          count: yup
            .number()
            .typeError('Необходимо ввести число')
            .integer('Должно быть целое число')
            .positive('Число должно быть положительным'),
          time: yup
            .number()
            .typeError('Необходимо ввести число')
            .integer('Должно быть целое число')
            .positive('Число должно быть положительным'),
        })
      )
      .default([
        { count: 1, time: 5000 },
        { count: 4, time: 60000 },
        { count: 10, time: 600000 },
      ]),
  }),
  isGuaranteedDelivery: yup.boolean().default(true),
  isGuaranteedOrder: yup.boolean(),
});

import { Option } from './types';

export const OPTIONS: Option[] = [
  {
    label: 'По наименованию',
    property: 'name',
    direction: 'asc',
  },
  {
    label: 'По дате обновления (новые)',
    property: 'lastUpdate',
    direction: 'desc',
  },
  {
    label: 'По дате обновления (старые)',
    property: 'lastUpdate',
    direction: 'asc',
  },
];

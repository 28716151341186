export const DEFAULT_BLOCK_CANVAS = {
  left: 0,
  top: 0,
  sx: {
    minWidth: 300,
    minHeight: 200,
    height: 0,
    width: 0
  },
};

export const DEFAULT_BLOCK = {
  isActive: true,
  isManyToOne: false,
  isGuaranteedDelivery: true,
  isGuaranteedOrder: false,
  isLimitedCountDelivery: true,
  limitedCount: 15,
  isActiveGuaranteedDelivery: true,
  inputList: [],
  processorList: [],
  outputList: [],
  settingsGuaranteeDelivery: {
    countToTimeDelayList: [
      {
        count: 1,
        time: 5000,
      },
      {
        count: 4,
        time: 60000,
      },
      {
        count: 10,
        time: 600000,
      },
    ],
  },
};

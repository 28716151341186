import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';

import {useParams} from "react-router-dom";
import {DeleteDashboard, EditDashboard} from "@/entities/Dashboard/features";
import {ActionPanel} from "@/shared/ui";


export const DashboardActionBar: FC = observer(() => {
    const { dashboardId } = useParams();

    return (
      <ActionPanel small withoutBorder>
        <EditDashboard dashboardId={dashboardId} />
        <DeleteDashboard dashboardId={dashboardId} />
      </ActionPanel>
);
});

DashboardActionBar.displayName = 'DashboardActionBar';

import { memo } from 'react';

import { ITableDescriptor } from '@/entities/Tracing/components/table/types';
import { MatcherWithResult } from '@/entities/Block/types';
import { AcceptIcon } from '@/shared/ui/Icons/AcceptIcon/AcceptIcon';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';

enum Columns {
  Name = 'name',
  Operation = 'operation',
  Value = 'value',
  Result = 'result',
}

export const testResultTableDescriptor: ITableDescriptor<MatcherWithResult> = {
  columns: [Columns.Name, Columns.Operation, Columns.Value, Columns.Result],

  renderers: {
    [Columns.Name]: {
      width: 25,
      Title() {
        return <span>Название</span>;
      },

      Value: memo(({ data: { nameProperty } }) => {
        return <p>{nameProperty}</p>;
      }),
    },

    [Columns.Operation]: {
      width: 25,
      Title() {
        return <span>Операция</span>;
      },

      Value: memo(({ data: { operation } }) => {
        return <p>{operation}</p>;
      }),
    },

    [Columns.Value]: {
      width: 25,
      Title() {
        return <span>Значение</span>;
      },

      Value: memo(({ data: { value } }) => {
        return <p>{value}</p>;
      }),
    },

    [Columns.Result]: {
      width: 25,
      Title() {
        return <span>Результат</span>;
      },

      Value: memo(({ data: { isSuccess } }) => {
        return (
          <p>
            {isSuccess ? (
              <AcceptIcon width={16} height={16} />
            ) : (
              <CrossIcon width={16} height={16} />
            )}
          </p>
        );
      }),
    },
  },
};

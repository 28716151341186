import { commonAcceptedMode, dayOfWeekAcceptedMode, dayOfMonthAcceptedMode, monthAcceptedMode } from '../utils';
import { AndValue, EveryValue, RangeValue, IncrementValue } from '../values';
import { Segment, Mode } from '../enums';

var _a;

export class BaseValues {
    constructor(d = {}) {
        this[_a] = new EveryValue();
        this.AND = new AndValue(d.AND);
        this.RANGE = new RangeValue(d.RANGE);
        this.INCREMENT = new IncrementValue(d.INCREMENT);
    }
    get(mode) {
        const value = this[mode];
        if (this.segment === Segment.dayOfMonth && dayOfMonthAcceptedMode(mode)) {
            return value ? value.clone() : null;
        }
        else if (this.segment === Segment.dayOfWeek && dayOfWeekAcceptedMode(mode)) {
            return value ? value.clone() : null;
        }
        else if (this.segment === Segment.month && monthAcceptedMode(mode)) {
            return value ? value.clone() : null;
        }
        else if (commonAcceptedMode(mode)) {
            return value ? value.clone() : null;
        }
        return null;
    }
}
_a = Mode.EVERY;// Mode.AND, Mode.RANGE, Mode.INCREMENT, Mode.NONE, Mode.LAST_NTH_DAY_WEEK, Mode.NTH_WEEKDAY_OF_MONTH, Mode.LAST_DAY, Mode.NEAREST_WEEKDAY_OF_MONTH, Mode.DAYS_BEFORE_END_MONTH, Mode.LAST_DAY_WEEK;

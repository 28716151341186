import { FC, useCallback } from 'react';

import { CardWithActions } from '@/shared/ui/CardWithActions/CardWithActions';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { Stack } from '@mui/system';
import cn from 'classnames';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageQueueService } from '../../../../services/MessageQueueService';

import styles from './MessageQueueCard.module.scss';

const messageQueueService = container.resolve(MessageQueueService);

export interface MessageQueueCardProps {
  errorId: string;
  active: boolean;
}

export const MessageQueueCard: FC<MessageQueueCardProps> = observer(({ errorId, active }) => {
  const { set } = useSearchParamsTemplate();

  const isLoading = messageQueueService.isLoadingMessages;

  const date = dayjs(messageQueueService.messages.entities[errorId]?.createDate).format(
    'DD.MM.YYYY'
  );
  const time = dayjs(messageQueueService.messages.entities[errorId]?.createDate).format(
    'HH:mm:ss'
  );

  const handleElementClick = useCallback(() => {
    set([SearchParams.ShowMessageForm, { [SearchParams.ErrorId]: errorId }]);
  }, [errorId, set]);

  return (
    <CardWithActions
      title={
        <Stack className={styles.title} flexDirection='row'>
          <p>{date}</p>
          <div className={styles.separator} />
          <span className={cn(styles.time, { [styles.active]: active })}>{time}</span>
        </Stack>
      }
      loading={isLoading}
      active={active}
      onClick={handleElementClick}
    />
  );
});

MessageQueueCard.displayName = 'MessageQueueCard';

import { injectable } from 'tsyringe';
import axios, { AxiosInstance } from 'axios';
import { identity } from 'lodash';

const { REACT_APP_ETL_HOST: etlHost = 'localhost:8081' } = process.env;
export const getBaseUrl = ({ host }) => (host === 'localhost:3000' ? etlHost : '/');

@injectable()
export class ApiService {
  instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: getBaseUrl(window.location),
    });

    this.instance.interceptors.request.use((config) => {
      let authorization: null;
      let companyId: null;

      try {
        authorization = JSON.parse(localStorage.getItem('headerAuth') || '');
        companyId = JSON.parse(localStorage.getItem('companyId') || 'default');
      } catch {
        authorization = null;
        companyId = null;
      }
      config.headers.authorization = authorization;
      config.headers['Company-Id'] = companyId;

      return config;
    });
  }
}

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
export const mockApi = async ({ data, handler = identity, delayTime = 250 }) => {
  const resp = handler(data);
  await delay(delayTime);
  return resp;
};

import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { ParameterDictionary } from '../types';

@singleton()
export class AllConnectStore {
  input: ParameterDictionary;
  processor: ParameterDictionary;
  output: ParameterDictionary;
  dictionaryForBothConnects: ParameterDictionary;

  constructor() {
    this.input = {};
    this.processor = {};
    this.output = {};
    this.dictionaryForBothConnects = {};

    makeAutoObservable(this);
  }
}

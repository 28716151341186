import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { Flow } from '@/entities/Flow/types';
import { normalize, Normalized } from '@/shared/lib/normalize';
import { ConnectStore } from '@/entities/Connect/stores/ConnectStore';

import Block, { Exchange } from '../types';

interface BlocksByFlow {
  [flowId: string]: Normalized<Block>;
}

@singleton()
export class BlockStore {
  blocks: BlocksByFlow | null;
  copyBlockNew: Block | null;
  sendMessageResponse: Exchange | null;
  sendMessageRequest: Exchange | null;
  flowId: string | null;
  nodeIdStartConnect: string | null;
  isVisibleConnectionLine: boolean;
  isVisiblePanelChooseConnects: boolean;
  isLoadingSendMessage: boolean;
  isOpenSnackbar: boolean;

  constructor() {
    this.blocks = null;
    this.copyBlockNew = null;
    this.isOpenSnackbar = false;
    this.sendMessageResponse = null;
    this.sendMessageRequest = {
      inputBody: {
        stringBody: '{\r\n   \r\n}',
      },
      inputQueryParamsString: '{\r\n   \r\n}',
      inputHeadersString: '{\r\n   \r\n}',
      inputPathParamsString: '{\r\n   \r\n}',
    };
    this.flowId = null;
    this.nodeIdStartConnect = null;
    this.isVisibleConnectionLine = false;
    this.isVisiblePanelChooseConnects = false;
    this.isLoadingSendMessage = false;

    eventEmitter.on(CustomEvents.InitializeFlow, (flow: Flow) => {
      if (this.blocks) {
        this.blocks[flow.id] = normalize(flow.blockList, 'id');
      } else {
        this.blocks = { [flow.id]: normalize(flow.blockList, 'id') };
      }

      this.isVisibleConnectionLine = false;
      this.isVisiblePanelChooseConnects = false;

      eventEmitter.emit(CustomEvents.InitializeBlocks, flow.blockList);
    });

    eventEmitter.on(CustomEvents.ChangeConnects, this.setConnectsToBlock.bind(this));

    makeAutoObservable(this);
  }

  private setConnectsToBlock(
    blockId: string,
    { inputList, processorList, outputList }: ConnectStore
  ) {
    if (this.flowId) {
      this.blocks![this.flowId].entities[blockId].inputList = Object.values(
        inputList?.[blockId].entities || {}
      );
      this.blocks![this.flowId].entities[blockId].processorList = Object.values(
        processorList?.[blockId].entities || {}
      );
      this.blocks![this.flowId].entities[blockId].outputList = Object.values(
        outputList?.[blockId].entities || {}
      );
    }
  }
}

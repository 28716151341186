import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import { License, LicenseStore } from '@/entities/License';
import { AccountStore } from '@/entities/Account';

import { CompanyStore } from '../model/CompanyStore';
import { Company } from '../model/types';

@singleton()
export class CompanyService {
  isLoadingGetCompany: boolean;
  isLoadingUpdateCompany: boolean;

  constructor(
    private apiService: ApiService,
    private companyStore: CompanyStore,
    private licenseStore: LicenseStore,
    private accountStore: AccountStore
  ) {
    this.isLoadingGetCompany = false;
    this.isLoadingUpdateCompany = false;

    makeAutoObservable(this);
  }

  async getCompany() {
    try {
      this.isLoadingGetCompany = true;
      const response = await this.apiService.instance.get<Company>('/editor/company');

      runInAction(() => {
        this.companyStore.company = response.data;
        this.licenseStore.licenses = normalize(response.data.licenseList, 'id');
        this.accountStore.accounts = normalize(response.data.accountList, 'name');
      });

      return response.data;
    } finally {
      this.isLoadingGetCompany = false;
    }
  }

  async updateCompany(licenseList?: License[]) {
    try {
      this.isLoadingUpdateCompany = true;

      const company: Company = {
        ...this.companyStore.company!,
        licenseList: licenseList || this.licenseStore.licenseList,
        accountList: this.accountStore.accountList,
      };

      const response = await this.apiService.instance.put<Company>('/editor/company', company);

      runInAction(() => {
        this.licenseStore.licenses = normalize(response.data.licenseList, 'id');
        this.accountStore.accounts = normalize(response.data.accountList, 'name');
        this.companyStore.company = response.data;
      });

      return response.data;
    } finally {
      this.isLoadingUpdateCompany = false;
    }
  }
}

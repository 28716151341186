import React from 'react';

import { Radio } from '@/shared/ui/Radio/Radio';

import { Mode } from '../../cron-core';
import { SharedProps } from './props.type';

type Props = {
  label: string;
} & SharedProps;

export const SimpleEvery = ({
  checked = false,
  disabled = false,
  label,
  onSelect,
  segmentId,
}: Props) => (
  <Radio
    id={segmentId}
    label={label}
    checked={checked}
    value={Mode.EVERY}
    disabled={disabled}
    onChange={onSelect}
  />
);

import { Mode, ConstantValue } from '../enums';
import { BaseValue } from './base.abstract';
export class NearestWeekdayOfMonthValue extends BaseValue {
    constructor(d = { values: [ConstantValue.EVERY] }) {
        super(d);
    }
    getMode() {
        return Mode.NEAREST_WEEKDAY_OF_MONTH;
    }
    clone(values) {
        return new NearestWeekdayOfMonthValue(values ? { values } : this);
    }
}

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import {
  IdmResponse,
  SettingsIdm,
  SettingsUserAuthService,
  SettingsUserAuthStore,
} from '@/entities/SettingsUserAuth';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import axios from 'axios';
import { isType } from '@/shared/lib/isType';

@singleton()
export class UpdateSettingsIdmAuthService {
  constructor(
    private apiService: ApiService,
    private settingsUserAuthStore: SettingsUserAuthStore,
    private settingsUserAuthService: SettingsUserAuthService
  ) {
    makeAutoObservable(this);
  }

  async updateSettingsIdmAuth(data: SettingsIdm) {
    const settingsIdm = { ...this.settingsUserAuthStore.settingsIdm! };

    settingsIdm.entities[data.id] = data;

    const response = await this.apiService.instance.put('/editor/settingsUserAuth', {
      ...this.settingsUserAuthStore.settingsUserAuth,
      settingsIDMList: Object.values(settingsIdm.entities),
    });

    this.settingsUserAuthService.putSettingsUserAuthToStore(response.data);

    return response.data;
  }

  async connectIdm(settingsIdm: SettingsIdm) {
    try {
      if (settingsIdm.issuer) {
        const response = await axios.get<IdmResponse | null>(settingsIdm.issuer, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        });

        runInAction(() => {
          if (isType<IdmResponse>(response.data, 'public_key')) {
            settingsIdm.rsaKey = response.data.public_key;
            settingsIdm.realm = response.data.realm;
            settingsIdm.methodGetToken = 'post';
          }
        });

        await this.updateSettingsIdmAuth(settingsIdm);

        return response.data;
      }
    } catch (error) {
      this.settingsUserAuthStore.settingsIdm!.entities[settingsIdm.id].rsaKey = null;

      throw error;
    }
  }
}

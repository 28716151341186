import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { PythonInstanceRequest, PythonLibsRequest, PythonPodLog } from './types';

@singleton()
export class PythonLibsStore {
  libs: PythonLibsRequest[] | [];

  pods: PythonInstanceRequest[] | null;

  isLoadingInstall: boolean;

  isLoadingDelete: boolean;

  isLoadingInstance: boolean;

  mode: 'user' | 'all';

  selectedLib: PythonLibsRequest | null;

  selectedManyLib: PythonLibsRequest[] | [];

  selectedPodLog: PythonPodLog[] | [];

  selectedPodId: string | null;

  isSort: boolean;

  podStatus: string | null;

  constructor() {
    this.libs = [];
    this.pods = null;
    this.isLoadingInstall = false;
    this.isLoadingDelete = false;
    this.isLoadingInstance = false;
    this.mode = null;
    this.selectedManyLib = [];
    this.selectedLib = null;
    this.selectedPodLog = [];
    this.selectedPodId = null;
    this.isSort = true;
    this.podStatus = null;

    makeAutoObservable(this);
  }
}

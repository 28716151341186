import { FC, memo } from 'react';

import { CardWithActions, CardWithActionsProps } from '@/shared/ui';
import Typography from '@mui/material/Typography';

import { Notification } from '../model/types';

export interface NotificationCardProps
  extends Pick<Notification, 'name' | 'description' | 'id'>,
    Omit<CardWithActionsProps, 'id' | 'title'> {}

export const NotificationCard: FC<NotificationCardProps> = memo(
  ({ name, id, description, ...props }) => {
    return (
      <CardWithActions {...props} key={id} title={name || 'Название отсутствует'}>
        <Typography noWrap>{description}</Typography>
      </CardWithActions>
    );
  }
);

NotificationCard.displayName = 'NotificationCard';

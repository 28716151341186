import { FC } from 'react';

import { Stack } from '@mui/system';
import { CodeEditor } from '@/shared/ui';
import Grid from '@mui/system/Unstable_Grid';

import styles from './BlockExecutionResult.module.scss';

export interface BlockExecutionResultProps {
  value: string;
}

export const BlockExecutionResult: FC<BlockExecutionResultProps> = ({ value }) => {
  return (
    <Stack gap={2.5}>
      <h2 className={styles.title}>Результат выполнения блока</h2>
      <Grid container gap={2.5}>
        <Grid xs={12}>
          <CodeEditor title='Результат выполнения блока' height={450} value={value} />
        </Grid>
      </Grid>
    </Stack>
  );
};

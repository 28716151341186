import { FC, useCallback, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { SettingsIdm, SettingsUserAuthStore } from '@/entities/SettingsUserAuth';
import Grid from '@mui/material/Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { AcceptIcon } from '@/shared/ui/Icons/AcceptIcon/AcceptIcon';
import { notify } from '@/shared/ui/Toast/notify';

import { UpdateSettingsIdmAuthService } from '../api/UpdateSettingsIdmAuthService';

const settingsUserAuthStore = container.resolve(SettingsUserAuthStore);
const updateSettingsIdmAuthService = container.resolve(UpdateSettingsIdmAuthService);

export interface UpdateSettingsIdmAuthFormProps {}

export const UpdateSettingsIdmAuthForm: FC<UpdateSettingsIdmAuthFormProps> = observer(() => {
  const { register, handleSubmit, getValues } = useForm<SettingsIdm>({
    values: settingsUserAuthStore.settingsIdm?.entities[settingsUserAuthStore.selectedId!],
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConnectIdm, setIsLoadingConnectIdm] = useState(false);

  const isActive = Boolean(
    settingsUserAuthStore.selectedId &&
      settingsUserAuthStore.settingsIdm?.entities[settingsUserAuthStore.selectedId]?.rsaKey
  );

  const handleValidSubmit = useCallback<SubmitHandler<SettingsIdm>>(async (settingsIdmWithId) => {
    try {
      setIsLoading(true);
      await updateSettingsIdmAuthService.updateSettingsIdmAuth(settingsIdmWithId);

      notify.success('Keycloak хранилище изменено');
    } catch {
      notify.error('Не удалось изменить Keycloak хранилище');
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleConnectButtonClick = useCallback(async () => {
    try {
      const values = getValues();

      setIsLoadingConnectIdm(true);

      await updateSettingsIdmAuthService.connectIdm(values);

      notify.success('Keycloak подключен');
    } catch {
      notify.error('Не удалось подключить Keycloak');
    } finally {
      setIsLoadingConnectIdm(false);
    }
  }, [getValues]);

  return (
    <form style={{ height: '100%' }} onSubmit={handleSubmit(handleValidSubmit)}>
      <Stack height='100%' justifyContent='space-between'>
        <Grid container spacing={2.5}>
          <Grid item xs={12}>
            <FieldInput error='' name='issuer' label='Issuer' register={register} />
          </Grid>
          <Grid item xs={12}>
            <FieldInput error='' name='clientId' label='Client id' register={register} />
          </Grid>
          <Grid item xs={12}>
            <FieldInput error='' name='clientSecret' label='Client secret' register={register} />
          </Grid>
        </Grid>
        <Stack direction='row' justifyContent='space-between'>
          <Box width={344}>
            {settingsUserAuthStore.selectedId && (
              <Button
                doesUseInFormOrTable
                variant={ButtonVariants.Secondary}
                loading={isLoadingConnectIdm}
                onClick={handleConnectButtonClick}
              >
                {isActive ? (
                  <Stack gap={1} direction='row' alignItems='center'>
                    <AcceptIcon />
                    Обновить keycloak
                  </Stack>
                ) : (
                  'Подключить keycloak'
                )}
              </Button>
            )}
          </Box>
          <Box width={212}>
            <Button type='submit' loading={isLoading}>
              Сохранить
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
});

UpdateSettingsIdmAuthForm.displayName = 'UpdateSettingsIdmAuthForm';

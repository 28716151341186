import { Node, Position } from 'reactflow';
import { InputConnectIdAndOutputConnectId } from '@/entities/Block/components/FloatingEdge/types';
import { getParams } from '@/entities/Block/components/FloatingEdge/utils/getParams';

/**
 * Возвращает значения для react-flow getBezierPath, значения для цепляющейся за точки линии
 * @param source Блок откуда идет линия
 * @param target Блок в который идет линия
 * @param data Содержит в себе ids коннекторов
 */
export const getEdgeParams = (
  source: Node,
  target: Node,
  data: InputConnectIdAndOutputConnectId
): {
  sx: number;
  sy: number;
  tx: number;
  ty: number;
  sourcePos: Position;
  targetPos: Position;
} => {
  const [sx, sy, sourcePos] = getParams(source, target, data);
  const [tx, ty, targetPos] = getParams(target, source, data);

  return {
    sx,
    sy,
    tx,
    ty,
    sourcePos,
    targetPos,
  };
};

import { FC, memo, useCallback } from 'react';

import Typography from '@mui/material/Typography';
import Stack from '@mui/system/Stack';

import styles from './styles.module.scss';

export interface ErrorWrapperProps {
  title: string;

  repeat(): void;
}

export const ErrorWrapper: FC<ErrorWrapperProps> = memo(({ title, repeat }) => {
  const handleReloadPageButtonClick = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <Stack className={styles.root} gap={1}>
      <Typography variant='h2' fontSize='2rem'>
        {title}
      </Typography>
      <Typography>
        Попробуйте{' '}
        <button type='button' className={styles.action} onClick={repeat}>
          повторить действие
        </button>{' '}
        или{' '}
        <button type='button' className={styles.action} onClick={handleReloadPageButtonClick}>
          обновите страницу
        </button>
      </Typography>
    </Stack>
  );
});

ErrorWrapper.displayName = 'ErrorWrapper';

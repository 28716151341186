import {MarkerType, Node} from 'reactflow';

import { DisplayMessageService } from '../../entities/Connect/services/DisplayMessageService';
import {BlockNodeProps} from "@/entities/BlockNode/BlockNode";
import {ExecutorHistory} from "@/entities/Connect/types";


const recursExecutorHistory = (arr : any[], executorHistory: ExecutorHistory) => {
  if (Array.isArray(executorHistory.executorHistoryNext)) {
    executorHistory.executorHistoryNext.forEach(executorHistory2 => {
      arr.push({
        id: `${executorHistory.id}${executorHistory2.id}`,
        source: executorHistory.id,
        target: executorHistory2.id,
        style: { strokeWidth: '2px', stroke: 'var(--edge-color)' },
        markerEnd: {
          type: MarkerType.ArrowClosed,
          strokeWidth: 2,
          color: '#E4E4E4',
        },
      });
      recursExecutorHistory(arr, executorHistory2)
    })
  }
}

export function getEdgesForBlockTracing(displayMessageService: DisplayMessageService) {

  let res: any[] = []

  let executorHistory = displayMessageService.executorHistory;
  if (executorHistory) {
    recursExecutorHistory(res, executorHistory)
  }

  return res;
}

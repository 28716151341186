// parser src from @grafana/data src/datetime/datemath.ts
// @grafana/data src/datetime/rangeutil.ts
// http://github.com/grafana/grafana.git v1.11.13
// change moment >> dayjs, reduce some code
import { includes, isDate, set } from 'lodash';
import dayjs, { Dayjs, OpUnitType, QUnitType, ManipulateType, isDayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';

import { WDay } from "@/entities/Dashboard/types";
import { quickOptions } from "@/entities/Dashboard/widgets/DashboardPlatform/components/HistoryManager/constants";

type UnitType = (OpUnitType | QUnitType);

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);

const units: UnitType [] = ['y', 'M', 'w', 'd', 'h', 'm', 's', 'Q'];
const ISOformat = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const defaultFormat = 'DD.MM.YYYY HH:mm';

const optionsMap: { [key: string]: any } = quickOptions.reduce((acc, itm) => ({
  ...acc,
  [`${itm.from} to ${itm.to}`]: itm,
}), {});

/**
 * Determine if a string contains a relative date time.
 * @param text
 */
export function isMathString(text: string | Dayjs | Date): boolean {
  if (!text) return false;

  return typeof text === 'string'
    && (text.startsWith('now') || text.includes('||'));
}

export function roundToFiscal(
  fyStartMonth: number,
  dateTime: any,
  unit: string,
  roundUp: boolean | undefined
): Dayjs | undefined {
  switch (unit) {
    case 'y':
      if (roundUp) {
        return roundToFiscal(fyStartMonth, dateTime, unit, false).add(11, 'M').endOf('M');
      }
      return dateTime.subtract((dateTime.month() - fyStartMonth + 12) % 12, 'M').startOf('M');
    case 'Q':
      if (roundUp) {
        return roundToFiscal(fyStartMonth, dateTime, unit, false).add(2, 'M').endOf('M');
      }
      // why + 12? to ensure this number is always a positive offset from fyStartMonth
      return dateTime.subtract((dateTime.month() - fyStartMonth + 12) % 3, 'M').startOf('M');
    default:
      return undefined;
  }
}

/**
 * Parses math part of the time string and shifts supplied time according to that math. See unit tests for examples.
 * @param mathString
 * @param time
 * @param roundUp If true it will round the time to endOf time unit, otherwise to startOf time unit.
 */
export function parseDateMath(
  mathString: string,
  time: any,
  roundUp?: boolean,
  fiscalYearStartMonth = 0
): WDay | undefined {
  const strippedMathString = mathString.replace(/\s/g, '');
  let result = dayjs(time);
  let i = 0;
  const len = strippedMathString.length;

  while (i < len) {
    const c = strippedMathString.charAt(i++);
    let type;
    let num;
    let unitString: string;
    let isFiscal = false;

    if (c === '/') {
      type = 0;
    } else if (c === '+') {
      type = 1;
    } else if (c === '-') {
      type = 2;
    } else {
      return undefined;
    }

    if (Number.isNaN(parseInt(strippedMathString.charAt(i), 10))) {
      num = 1;
    } else if (strippedMathString.length === 2) {
      num = parseInt(strippedMathString.charAt(i), 10);
    } else {
      const numFrom = i;
      while (!Number.isNaN(parseInt(strippedMathString.charAt(i), 10))) {
        i++;
        if (i > 10) {
          return undefined;
        }
      }
      num = parseInt(strippedMathString.substring(numFrom, i), 10);
    }

    if (type === 0) {
      // rounding is only allowed on whole, single, units (eg M or 1M, not 0.5M or 2M)
      if (num !== 1) {
        return undefined;
      }
    }

    unitString = strippedMathString.charAt(i++);

    if (unitString === 'f') {
      unitString = strippedMathString.charAt(i++);
      isFiscal = true;
    }

    const unit = unitString as ManipulateType;

    if (!includes(units, unit)) return undefined;

    if (type === 1) result = result.add(num, unit);
    else if (type === 2) result = result.subtract(num, unit);
    else if (type === 0) {
      if (isFiscal) result = roundToFiscal(fiscalYearStartMonth, result, unit, roundUp);
      else if (roundUp) result = result.endOf(unit);
      else result = result.startOf(unit);
    }
  }
  return result;
}

/**
 * Parses different types input to a moment instance. There is a specific formatting language that can be used
 * if text arg is string. See unit tests for examples.
 * @param text
 * @param roundUp See parseDateMath function.
 * @param timezone Only string 'utc' is acceptable here, for anything else, local timezone is used.
 */
export function parse(
  text?: string | Dayjs | Date | null,
  roundUp?: boolean,
  // timezone?: TimeZone,
  // fiscalYearStartMonth?: number
): WDay | undefined {

  if (!text) return undefined;
  if (dayjs.isDayjs(text)) return text;
  if (isDate(text)) return dayjs(text);
  if (typeof text !== 'string') return undefined;

  if (/^\d+$/.test(text)) {
    const unix = Number.parseInt(text, 10);
    const fixDate = dayjs(unix * 1000);
    return fixDate.isValid() ? fixDate : undefined;
  }

  let time;
  let mathString = '';
  let parseString;

  if (text.startsWith('now')) {
    time = dayjs();
    mathString = text.substring(3);
  } else {
    [parseString, mathString = ''] = text.split('||');
    // We're going to just require ISO8601 timestamps, k?
    time = dayjs(parseString, ISOformat);
  }

  if (!mathString.length) {
    set(time, 'raw', text);
    return time as WDay;
  }

  const parsed = parseDateMath(mathString, time, roundUp/* , fiscalYearStartMonth */);
  if (!parsed) return parsed;
  set(parsed, 'raw', text);
  return parsed;
}

/**
 * Checks if text is a valid date which in this context means that it is either a Moment instance or it can be parsed
 * by parse function. See parse function to see what is considered acceptable.
 * @param text
 */
export function isValid(text: string | Dayjs): boolean {
  const date = parse(text);
  if (!date) {
    return false;
  }

  if (dayjs.isDayjs(date)) {
    return date.isValid();
  }

  return false;
}

// handles expressions like
// 5m
// 5m to now/d
// now/d to now
// now/d
// if no to <expr> then to now is assumed
export function describeTextRange(expression: string) {
  let expr = expression;
  const isLast = expr.indexOf('+') !== 0;
  if (expr.indexOf('now') === -1) {
    expr = `${isLast ? 'now-' : 'now'}${expr}`;
  }

  let opt = optionsMap[`${expr} to now`];
  if (opt) {
    return opt;
  }

  if (isLast) {
    opt = { from: expr, to: 'now', display: '' };
  } else {
    opt = { from: 'now', to: expr, display: '' };
  }

  // const parts = /^now([-+])(\d+)(\w)/.exec(expr);
  const date = parse(expr);
  if (!date) {
    opt.display = `${opt.from} по ${opt.to}`;
    opt.invalid = true;
    return opt;
  }

  const chain = [
    isLast ? 'Последние' : 'Следующие',
    date.fromNow(true)
  ]
  return { ...opt, display: chain.join(' ') };
}


export function describeTimeRange([from, to]: [(Dayjs | string), (Dayjs | string)]/* , timeZone?: TimeZone */): string {
  const idx = `${from?.toString()} to ${to?.toString()}`;
  const option = optionsMap[idx];

  if (option) return option.display;

  // const options = { timeZone };

  if (isDayjs(from) && isDayjs(to)) {
    return `${from.format(defaultFormat)} по ${to.format(defaultFormat)}`;
  }

  if (isDayjs(from)) {
    const parsed = parse(to, true/* , 'utc' */);
    if (!parsed) return '';
    return `${from.format(defaultFormat)} по ${parsed.fromNow()}`;
  }

  if (isDayjs(to)) {
    const parsed = parse(from, false/* , 'utc' */);
    if (!parsed) return '';
    return `${parsed.fromNow()} по ${to.format(defaultFormat)}`;
  }

  if (to === 'now') {
    const res = describeTextRange(from);
    return res.display;
  }

  return `${from} по ${to}`;
}
import { makeAutoObservable } from 'mobx';
import { inject, injectable } from 'tsyringe';
import { RegistrableValues } from '@/shared/lib/types';
import { BlockRelationStore } from '@/entities/Block/stores/BlockRelationStore';
import { Relation } from '@/entities/Flow/types';
import { Edge, MarkerType, Position } from 'reactflow';
import { formatRelationText } from '@/entities/Block/utils/formatRelationText';
import { RelationPositions } from '@/entities/Block/types';

/**
 * Сервис для отрисовки линий между блоками
 */
@injectable()
export class BlockRelationService {
  constructor(
    @inject(RegistrableValues.FlowId) private flowId: string,
    private blockRelationStore: BlockRelationStore
  ) {
    makeAutoObservable(this);
  }

  get relations(): Relation[] {
    return this.blockRelationStore.relations[this.flowId];
  }

  get relationPositions(): RelationPositions {
    return this.blockRelationStore.relationPositions[this.flowId];
  }

  get lineIdWhichHover(): string | undefined {
    return this.blockRelationStore.lineIdWhichHover;
  }

  set lineIdWhichHover(lineIdWhichHover: string | undefined) {
    this.blockRelationStore.lineIdWhichHover = lineIdWhichHover;
  }

  get lineIdManyToOne(): string | undefined {
    return this.blockRelationStore.lineIdManyToOne;
  }

  set lineIdManyToOne(lineIdManyToOne: string | undefined) {
    this.blockRelationStore.lineIdManyToOne = lineIdManyToOne;
  }

  getEdges(): Edge[] {
    if (this.relations) {
      return this.relations.map((relation) => {
        return {
          id: `${relation.outputBlockId}-${relation.inputBlockId}-${relation.outputConnectId}-${relation.inputConnectId}`,
          source: relation.outputBlockId,
          target: relation.inputBlockId,
          sourceHandle: `${relation.outputConnectId}`,
          targetHandle: `${relation.inputConnectId}`,
          type: 'floating',
          interactionWidth: 50,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: '#E4E4E4',
          },
          label: formatRelationText(relation.text),
          data: {
            offset: 0,
            inputConnectId: relation.inputConnectId,
            outputConnectId: relation.outputConnectId,
            setRelationPosition: this.setRelationPosition.bind(this),
          },
        };
      });
    } else {
      return [];
    }
  }

  getEdgesWithManyToOne(): Edge[] {
    if (this.relations) {
      return this.relations.map((relation) => {
        return {
          id: `${relation.outputBlockId}-${relation.inputBlockId}-${relation.outputConnectId}-${relation.inputConnectId}`,
          source: relation.outputBlockId,
          target: relation.inputBlockId,
          sourceHandle: `${relation.outputConnectId}`,
          targetHandle: `${relation.inputConnectId}`,
          type: 'floating',
          interactionWidth: 50,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 15,
            height: 15,
            color: '#E4E4E4',
          },
          label: formatRelationText(relation.text),
          style: {

          },
          data: {
            offset: 0,
            inputConnectId: relation.inputConnectId,
            outputConnectId: relation.outputConnectId,
            setRelationPosition: this.setRelationPosition.bind(this),
          },
        };
      })
    } else {
      return [];
    }
  }

  setRelationBlock(relations: Relation[]): void {
    if (this.blockRelationStore.relations) {
      this.blockRelationStore.relations[this.flowId] = relations;
    } else {
      this.blockRelationStore.relations = {
        [this.flowId]: relations,
      };
    }
  }

  setRelationPosition(id: string, position: Position): void {
    if (this.blockRelationStore.relationPositions[this.flowId]) {
      this.blockRelationStore.relationPositions[this.flowId][id] = position;
    } else {
      this.blockRelationStore.relationPositions[this.flowId] = { [id]: position };
    }
  }
}

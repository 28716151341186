import React from 'react';
import PropTypes from 'prop-types';

const TypographyMsToText = props => {
    const {time} = props

    const render = (time) => {
        let parseDate = {}
        let newTime = time / 1.0
        parseDate.ms = newTime % 1000
        newTime = (newTime - parseDate.ms) / 1000
        parseDate.s = newTime % 60
        newTime = (newTime - parseDate.s) / 60
        parseDate.minute = newTime % 60
        newTime = (newTime - parseDate.minute) / 60
        parseDate.hour = newTime % 24
        newTime = (newTime - parseDate.hour) / 24
        parseDate.day = newTime
        let stringDate = ""
        if (parseDate.day > 0) {
            if (parseDate.day >= 11 && parseDate.day <= 14) {
                stringDate = stringDate + parseDate.day + " дней "
            } else {
                if (parseDate.day % 10 === 1) {
                    stringDate = stringDate + parseDate.day + " день "
                } else {
                    if (parseDate.day % 10 >= 2 && parseDate.day % 10 <= 4) {
                        stringDate = stringDate + parseDate.day + " дня "
                    } else {
                        stringDate = stringDate + parseDate.day + " дней "
                    }
                }
            }
        }
        if (parseDate.hour > 0) {
            if (parseDate.hour >= 11 && parseDate.hour <= 14) {
                stringDate = stringDate + parseDate.hour + " часов "
            } else {
                if (parseDate.hour % 10 === 1) {
                    stringDate = stringDate + parseDate.hour + " час "
                } else {
                    if (parseDate.hour % 10 >= 2 && parseDate.hour % 10 <= 4) {
                        stringDate = stringDate + parseDate.hour + " часа "
                    } else {
                        stringDate = stringDate + parseDate.hour + " часов "
                    }
                }
            }
        }
        if (parseDate.minute > 0) {
            if (parseDate.minute >= 11 && parseDate.minute <= 14) {
                stringDate = stringDate + parseDate.minute + " минут "
            } else {
                if (parseDate.minute % 10 === 1) {
                    stringDate = stringDate + parseDate.minute + " минута "
                } else {
                    if (parseDate.minute % 10 >= 2 && parseDate.minute % 10 <= 4) {
                        stringDate = stringDate + parseDate.minute + " минуты "
                    } else {
                        stringDate = stringDate + parseDate.minute + " минут "
                    }
                }
            }
        }
        if (parseDate.s > 0) {
            if (parseDate.s >= 11 && parseDate.s <= 14) {
                stringDate = stringDate + parseDate.s + " секунд "
            } else {
                if (parseDate.s % 10 === 1) {
                    stringDate = stringDate + parseDate.s + " секунда "
                } else {
                    if (parseDate.s % 10 >= 2 && parseDate.s % 10 <= 4) {
                        stringDate = stringDate + parseDate.s + " секунды "
                    } else {
                        stringDate = stringDate + parseDate.s + " секунд "
                    }
                }
            }
        }
        if (parseDate.ms > 0) {
            if (parseDate.ms % 100 >= 11 && parseDate.ms % 100 <= 14) {
                stringDate = stringDate + parseDate.ms + " миллисекунд"
            } else {
                if (parseDate.ms % 10 === 1) {
                    stringDate = stringDate + parseDate.ms + " миллисекунда"
                } else {
                    if (parseDate.ms % 10 >= 2 && parseDate.ms % 10 <= 4) {
                        stringDate = stringDate + parseDate.ms + " миллисекунды"
                    } else {
                        stringDate = stringDate + parseDate.ms + " миллисекунд"
                    }
                }
            }
        }
        return stringDate
    }

    return (
        <>
            {render(time)}
        </>
    );
};

TypographyMsToText.propTypes = {
    time: PropTypes.number
};

export default TypographyMsToText;
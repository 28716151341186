import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';

import { IntegrationTest, IntegrationTestResult } from '../types';

@singleton()
export class BlockTestingStore {
  tests: Normalized<IntegrationTest>;
  testsResult: Record<string, IntegrationTestResult>;
  isLoadingTests: boolean;
  isLoadingRunTests: boolean;
  isLoadingRunTest: boolean;
  isLoadingImportTests: boolean;
  isLoadingDeleteTest: boolean;
  isLoadingSaveTest: boolean;
  isLoadingTestResult: boolean;
  isError: boolean;
  totalPages: number;
  totalElements: number;

  constructor() {
    this.tests = { ids: [], entities: {} };
    this.testsResult = {};
    this.isLoadingTests = false;
    this.isLoadingRunTests = false;
    this.isLoadingRunTest = false;
    this.isError = false;
    this.isLoadingImportTests = false;
    this.isLoadingDeleteTest = false;
    this.isLoadingSaveTest = false;
    this.isLoadingTestResult = false;
    this.totalPages = 0;
    this.totalElements = 0;

    makeAutoObservable(this);
  }
}

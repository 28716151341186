import React, { FC, useState } from 'react';

import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import { useFormContext } from 'react-hook-form';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import { observer } from 'mobx-react-lite';
import { Notification } from '@/entities/Notification';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { FieldCode } from '@/shared/ui/Fields/components/FieldCode/FieldCode';

import { EmailList } from './EmailList';

export interface AdditionalNotificationSettingsProps {}

export const AdditionalNotificationSettings: FC<AdditionalNotificationSettingsProps> = observer(
  () => {
    const [isSendWithoutAlerts, setIsSendWithoutAlerts] = useState<boolean>(true);
    const {
      register,
      control,
      formState: { errors },
    } = useFormContext<Notification>();
    const handlerSwitch = () => {
      setIsSendWithoutAlerts((prevState) => !prevState);
    };
    return (
      <Accordion variant='outlined' elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Дополнительные настройки
        </AccordionSummary>
        <AccordionDetails>
          <Stack gap={5}>
            <Grid container xs={12} lg={4} xl={3}>
              <FieldInput
                error={errors.fromEmail}
                register={register}
                name='fromEmail'
                label='Отправитель'
              />
            </Grid>
            <EmailList name='сс' title='Копии' />
            <EmailList name='bсс' title='Скрытые копии' />
            <Grid container xs={12} lg={4} xl={3}>
              <FieldInput
                error={errors.replyTo}
                register={register}
                name='replyTo'
                label='Почта для получения ответа'
              />
            </Grid>
            <Grid container spacing={2.5} alignItems='center'>
              <Grid item xs={12} lg={4} xl={3}>
                <FieldSwitch
                  error={errors.isSendFile}
                  control={control}
                  name='isSendFile'
                  label='Отправить файлом'
                />
              </Grid>
              <Grid item xs={12} lg={4} xl={3}>
                <FieldSelect
                  error={errors.type}
                  control={control}
                  name='type'
                  label='Тип файла'
                  options={['Json', 'XML', 'Текст']}
                />
              </Grid>
            </Grid>
            <Grid xs={12} item>
              <FieldSwitch
                error=''
                control={control}
                name='isSendWithoutAlerts'
                defaultValue={isSendWithoutAlerts.toString()}
                label='Отправлять уведомление, если нет ошибок'
                onChange={handlerSwitch}
              />
            </Grid>
            {isSendWithoutAlerts && (
              <FieldCode
                hideLanguageSelect
                hideFormatSelect
                error={errors.templateWithoutAlerts}
                name='templateWithoutAlerts'
                control={control}
                label='Шаблон при отсутствии ошибок'
              />
            )}
            <FieldCode
              hideLanguageSelect
              hideFormatSelect
              error={errors.emailTemplate}
              name='emailTemplate'
              control={control}
              label='Шаблон письма'
            />
            <FieldCode
              hideLanguageSelect
              hideFormatSelect
              error={errors.alertsTemplate}
              name='alertsTemplate'
              control={control}
              label='Шаблон ошибок'
            />
          </Stack>
        </AccordionDetails>
      </Accordion>
    );
  }
);

AdditionalNotificationSettings.displayName = 'AdditionalNotificationSettings';

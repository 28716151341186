import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { DeleteTestModal } from '../features/DeleteTest';
import styles from '../BlockTesting.module.scss';
import { BlockTestingContent, BlockTestingSidebar } from '../widgets';

export const BlockTestingPage: FC = observer(() => {
  const { remove, has } = useSearchParamsTemplate();

  const handleDrawerClose = () => {
    remove([SearchParams.ShowBlockTesting, SearchParams.ShowTestForm, SearchParams.TestId]);
  };

  return has(SearchParams.ShowBlockTesting) ? (
    <>
      <Drawer title='Интеграционные тесты' onClose={handleDrawerClose} largeTitle fullscreen>
        <div>
          <div className={styles.root}>
            <BlockTestingSidebar />
            <BlockTestingContent />
          </div>
        </div>
      </Drawer>
      <DeleteTestModal />
    </>
  ) : null;
});

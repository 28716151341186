import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Dayjs } from 'dayjs';
import { BlockStore } from '@/entities/Block/stores/BlockStore';
import { Block } from '@/entities/Block/types';
import { Flow } from '@/entities/Flow/types';

@singleton()
export class TracingFilterStore {
  fromDate: Dayjs | null;
  toDate: Dayjs | null;
  selectedFlows: Flow[];
  selectedBlocks: Block[];
  onlyError: boolean;

  constructor(private blockStore: BlockStore) {
    this.fromDate = null;
    this.toDate = null;
    this.selectedFlows = [];
    this.selectedBlocks = [];
    this.onlyError = false;

    makeAutoObservable(this);
  }

  get blocksOptions() {
    let result: Block[] = [];

    this.selectedFlows.forEach((flow) => {
      if (this.blockStore.blocks) {
        result = result.concat(Object.values(this.blockStore.blocks[flow.id]?.entities || {}));
      }
    });

    return result;
  }
}

import React, { FC } from 'react';

import Badge, { BadgeProps } from '@mui/material/Badge';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Tooltip from '@/shared/ui/Tooltip';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';
import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';

import { ConsumerInformationStore } from '../model/ConsumerInformationStore';

import styles from './styles.module.scss';

const consumerInformationStore = container.resolve(ConsumerInformationStore);

export interface ConsumerInformationBadgeProps extends BadgeProps {
  id: string;
  children: JSX.Element;
}

const renderInstancesCount = (count: number): JSX.Element => {
  if (count === 0) {
    return <span>Не запущен</span>;
  }
  if (count % 10 === 1 && count !== 11) {
    return <span>Запущен на {count} машине:</span>;
  }
  return <span>Запущен на {count} машинах:</span>;
};

const renderTitle = (id: string) => {
  if (consumerInformationStore.elements?.[id]) {
    return (
      <Stack gap={0.5}>
        {renderInstancesCount(consumerInformationStore.elements[id].length)}
        {consumerInformationStore.elements[id].map((element) => {
          return (
            <Stack key={element.instanceId} gap={1} direction='row' alignItems='center'>
              <Indicator
                pulse
                type={element.status === 'running' ? IndicatorTypes.Success : IndicatorTypes.Error}
              />
              <span>{dayjs(element.timestampChangeStatus).format('DD.MM.YYYY HH:mm:ss')}</span>
              <span>{element.instanceInformation.name}</span>
            </Stack>
          );
        })}
      </Stack>
    );
  }

  return <span>subject</span>;
};

export const ConsumerInformationBadge: FC<ConsumerInformationBadgeProps> = observer(
  ({ id, children, ...props }) => {
    if (consumerInformationStore.elements?.[id]) {
      return (
        <Badge
          {...props}
          badgeContent={
            <Tooltip title={renderTitle(id)} placement='right'>
              <span className={styles.badgeContent}>
                {consumerInformationStore.elements[id].length}
              </span>
            </Tooltip>
          }
        >
          {children}
        </Badge>
      );
    }

    return children;
  }
);

ConsumerInformationBadge.displayName = 'ConsumerInformationBadge';

import { FC, FormEventHandler, useState } from 'react';

import { useForm } from 'react-hook-form';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import Box from '@mui/material/Box';

import { CompanyStore } from '../model/CompanyStore';
import { CompanyService } from '../api/CompanyService';

const companyStore = container.resolve(CompanyStore);
const companyService = container.resolve(CompanyService);

export interface CompanyFormProps {
  addonAfter: JSX.Element;
}

export const CompanyForm: FC<CompanyFormProps> = observer(({ addonAfter }) => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    formState: { errors, defaultValues },
    getValues,
  } = useForm({
    values: companyStore.company ? companyStore.company : undefined,
  });

  const preventSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
  };

  const handleBlur = async () => {
    try {
      setIsLoading(true);

      if (companyStore.company?.name) {
        companyStore.company.name = getValues()['name'];
      }

      await companyService.updateCompany();
    } catch {
      notify.error('Не удалось изменить имя компании');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={preventSubmit} onBlur={handleBlur}>
      <Box maxWidth={745}>
        <FieldInput
          error={errors.name}
          name='name'
          defaultValue={defaultValues?.name}
          label='Компания'
          register={register}
          endAdornment={isLoading ? <LoaderIcon /> : undefined}
        />
      </Box>
      {addonAfter}
    </form>
  );
});

CompanyForm.displayName = 'CompanyForm';

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { NotificationStore } from '@/entities/Notification';

@singleton()
export class DeleteNotificationService {
  open: boolean;

  constructor(private apiService: ApiService, private notificationStore: NotificationStore) {
    this.open = false;

    makeAutoObservable(this);
  }

  async deleteNotification(notificationId: string) {
    await this.apiService.instance.delete(`/editor/notification/${notificationId}`);

    const index = this.notificationStore.notifications?.ids.indexOf(notificationId);

    if (index || index === 0) {
      runInAction(() => {
        this.notificationStore.notifications?.ids.splice(index, 1);
        delete this.notificationStore.notifications?.entities[notificationId];
      });
    }
  }
}

import React, { FC, memo, useCallback } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Notification } from '@/entities/Notification';
import _ from 'lodash';

export interface EmailListProps {
  name: string;
  title: string;
}

export const EmailList: FC<EmailListProps> = memo(({ name, title }) => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<Notification>();

  const { fields, append, remove } = useFieldArray<any>({
    control,
    name,
  });

  const handleAppendButtonClick = useCallback(() => {
    append('');
  }, []);

  const handleRemoveButtonClick = useCallback(
    (index: number) => () => {
      remove(index);
    },
    []
  );

  return (
    <Grid container gap={2.5}>
      <Grid xs={12}>
        <Typography variant='title'>{title}</Typography>
      </Grid>
      <Grid xs={12}>
        <Stack flexWrap='wrap' direction='row' rowGap={2.5} columnGap={5}>
          {fields.map((field, index) => {
            return (
              <Grid key={field.id} xs={12} lg={5.6} xl={3.6}>
                <Stack direction='row' gap={2.5}>
                  <FieldInput
                    error={_.get(errors, `${name}.${index}`, '')}
                    name={`${name}.${index}`}
                    register={register}
                    label='Email'
                  />
                  <Stack height='56px' alignSelf='flex-end' justifyContent='center'>
                    <Button
                      circle
                      variant={ButtonVariants.Outlined}
                      size={ButtonSizes.Small}
                      onClick={handleRemoveButtonClick(index)}
                    >
                      <TrashIcon />
                    </Button>
                  </Stack>
                </Stack>
              </Grid>
            );
          })}
          <Grid xs={3.2} height='88px' display='flex' alignItems='flex-end'>
            <Button
              doesUseInFormOrTable
              variant={ButtonVariants.Secondary}
              onClick={handleAppendButtonClick}
            >
              <Stack direction='row' gap={1.5} alignItems='center'>
                <PlusIcon />
                Добавить
              </Stack>
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
});

EmailList.displayName = 'EmailList';

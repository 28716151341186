import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { DiffEditor, EditorProps, Monaco, OnChange, OnMount } from '@monaco-editor/react';
import loader from "@monaco-editor/loader";
import * as monaco from 'monaco-editor';
import { FullscreenIcon } from '@/shared/ui/Icons/FullscreenIcon';
import { motion } from 'framer-motion';
import Tooltip from '@/shared/ui/Tooltip';
import { FORMAT_OPTIONS, LANGUAGE_OPTIONS } from '@/shared/ui/CodeEditor/constants';
import useLocalStorage from '@/hooks/useLocalStorage';
import xmlFormatter from 'xml-formatter';
import { notify } from '@/shared/ui/Toast/notify';
import { defineCodeEditorDarkTheme } from '@/shared/lib/defineCodeEditorDarkTheme';
import Stack from '@mui/material/Stack';
import { Dialog, DialogContent, DialogTitle, Select } from '@/shared/ui';
import { SelectChangeEvent } from '@mui/material/Select';
import Box from '@mui/material/Box';

import styles from './DiffCodeEditor.module.scss';

loader.config({
  monaco
});

export interface DiffCodeEditorProps extends EditorProps {
  original : string;
  title?: string;
  gapHeader?: number;
  value: string;
  isFormatted?: boolean
  defaultLanguage?: string;
  height?: string;
  hideLanguageSelect?: boolean;
  hideFormatSelect?: boolean;
  readonly?: boolean;
  minimapEnabled?: boolean;
  fullScreenHeader?: React.ReactNode;
  rerender?: boolean;
}

const handleBeforeMount = (monaco: Monaco) => {
  defineCodeEditorDarkTheme(monaco);
};

export const DiffCodeEditor: FC<DiffCodeEditorProps> = ({
    original,
  title,
  value,
  isFormatted = true,
  onChange,
  defaultLanguage,
  height,
  hideFormatSelect,
  hideLanguageSelect,
  readonly,
  minimapEnabled,
  fullScreenHeader,
  rerender= false,
    gapHeader = 2.5,
  ...props
}) => {
  const [stateValue, setStateValue] = useState("");
  const [format, setFormat] = useState(FORMAT_OPTIONS[0]);
  const [language, setLanguage] = useState(defaultLanguage || 'python');
  const [isFullscreen, setIsFullscreen] = useState<boolean>(false);

  const options = useMemo(() => {
    return {
      scrollbar: { alwaysConsumeMouseWheel: false },
      readOnly: readonly,
      "acceptSuggestionOnCommitCharacter": true,
      "acceptSuggestionOnEnter": "on",
      "accessibilitySupport": "auto",
      "autoIndent": false,
      "automaticLayout": true,
      "codeLens": true,
      "colorDecorators": true,
      "contextmenu": true,
      "cursorBlinking": "blink",
      "cursorSmoothCaretAnimation": false,
      "cursorStyle": "line",
      "disableLayerHinting": false,
      "disableMonospaceOptimizations": false,
      "dragAndDrop": false,
      "fixedOverflowWidgets": false,
      "folding": true,
      "foldingStrategy": "auto",
      "fontLigatures": false,
      "formatOnPaste": false,
      "formatOnType": false,
      "hideCursorInOverviewRuler": false,
      "highlightActiveIndentGuide": true,
      "links": true,
      "mouseWheelZoom": false,
      "multiCursorMergeOverlapping": true,
      "multiCursorModifier": "alt",
      "overviewRulerBorder": true,
      "overviewRulerLanes": 2,
      "quickSuggestions": true,
      "quickSuggestionsDelay": 100,
      "renderControlCharacters": false,
      "renderFinalNewline": true,
      "renderIndentGuides": true,
      "renderLineHighlight": "all",
      "renderWhitespace": "none",
      "revealHorizontalRightPadding": 30,
      "roundedSelection": true,
      "rulers": [],
      "scrollBeyondLastColumn": 5,
      "scrollBeyondLastLine": true,
      "selectOnLineNumbers": true,
      "selectionClipboard": true,
      "selectionHighlight": true,
      "showFoldingControls": "mouseover",
      "smoothScrolling": false,
      "suggestOnTriggerCharacters": true,
      "wordBasedSuggestions": true,
      "wordSeparators": "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
      "wordWrap": "off",
      "wordWrapBreakAfterCharacters": "\t})]?|&,;",
      "wordWrapBreakBeforeCharacters": "{([+",
      "wordWrapBreakObtrusiveCharacters": ".",
      "wordWrapColumn": 80,
      "wordWrapMinified": true,
      "wrappingIndent": "none",
      minimap: {
        enabled: minimapEnabled
      }
    };
  }, [readonly, minimapEnabled]);

  const handleLanguageSelectChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setLanguage(event.target.value);
    },
    [setLanguage]
  );

  const handleEditorChange: OnChange = useCallback(
    (value, ev) => {
      setStateValue(value as string);

      onChange?.(value, ev);
    },
    [onChange]
  );

  const handleFormatSelectChange = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setFormat(event.target.value as string);

      let result = stateValue;

      switch (event.target.value) {
        case 'XML':
          try {
            result = xmlFormatter(stateValue, {
              indentation: '  ',
              collapseContent: true,
              lineSeparator: '\n',
            });
          } catch (error) {
            notify.error('Не удалось отформатировать в XML');
          }
          break;
        case 'JSON':
          try {
            result = JSON.stringify(JSON.parse(stateValue), null, 2);
          } catch (error) {
            notify.error('Не удалось отформатировать в JSON');
          }
          break;
      }

      setStateValue(result);
    },

    [setStateValue, stateValue]
  );

  const handleFullscreenButtonClick = () => {
    setIsFullscreen(true);
  };

  const handleFullscreenButtonClose = () => {
    setIsFullscreen(false);
  };

  const setValueWithFormatted = (value : string) => {
    let result = value;
    let format = 'Текст';
    let lang = defaultLanguage ? defaultLanguage : 'python';

    if (format === 'Текст') {
      try {
        result = JSON.stringify(JSON.parse(value), null, 2);
        format = 'JSON';
        lang = 'json';
      } catch (error) {}
    }
    if (format === 'Текст') {
      try {
        result = xmlFormatter(value, {
          indentation: '  ',
          collapseContent: true,
          lineSeparator: '\n',
        });
        format = 'XML';
        lang = 'xml';
      } catch (error) {}
    }

    setLanguage(lang);
    setStateValue(() => result);
    setFormat(format);
  }

  const handleEditorMount = useCallback<OnMount>(() => {
    let result = value;
    let format = 'Текст';
    let lang = defaultLanguage ? defaultLanguage : 'python';

    if (format === 'Текст') {
      try {
        result = JSON.stringify(JSON.parse(value), null, 2);
        format = 'JSON';
        lang = 'json';
      } catch (error) {}
    }
    if (format === 'Текст') {
      try {
        result = xmlFormatter(value, {
          indentation: '  ',
          collapseContent: true,
          lineSeparator: '\n',
        });
        format = 'XML';
        lang = 'xml';
      } catch (error) {}
    }

    setLanguage(lang);
    setStateValue(result);
    setFormat(format);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setValueWithLogic = (newValue : string) => {
      if (isFormatted) {
        setValueWithFormatted(value);
      } else {
        setStateValue(newValue)
      }
  }

  useEffect(() => {
    setValueWithLogic(value);
  }, [rerender]);

  useEffect(() => {
    readonly && setValueWithLogic(value);
  }, [value]);

  return (
    <div className={styles.root}>
      {isFullscreen ? (
        <Dialog open fullScreen onClose={handleFullscreenButtonClose}>
          <DialogTitle onClose={handleFullscreenButtonClose}>{title}</DialogTitle>
          <DialogContent>
            {fullScreenHeader}
            <DiffEditor
              {...props}
              theme='dark-theme'
              height='80vh'
              original={original}
              modified={stateValue}
              language={language}
              loading={<LoaderIcon width={20} height={20} />}
              className={styles.editor}
              // options={options}
              onChange={handleEditorChange}
              beforeMount={handleBeforeMount}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Stack gap={gapHeader} width='100%' display='flex' flexDirection='column'>
          <Stack gap={2.5} direction='row' alignItems='flex-end'>
            <Stack gap={2.5} direction='row' width='100%'>
              <Box maxWidth={256} width='100%'>
                <Select
                  size='small'
                  hidden={hideLanguageSelect}
                  label='Язык'
                  options={LANGUAGE_OPTIONS}
                  value={language}
                  onChange={handleLanguageSelectChange}
                />
              </Box>
              <Box maxWidth={256} width='100%'>
                <Select
                  size='small'
                  hidden={hideFormatSelect}
                  label='Формат'
                  options={FORMAT_OPTIONS}
                  value={format}
                  onChange={handleFormatSelectChange}
                />
              </Box>
            </Stack>

            <Tooltip title='На весь экран' placement='top'>
              <motion.button
                type='button'
                className={styles.icon}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleFullscreenButtonClick}
              >
                <FullscreenIcon />
              </motion.button>
            </Tooltip>
          </Stack>
          <Stack flexGrow={1}>
            <DiffEditor
                {...props}
                theme='dark-theme'
                height={height || '400px'}
                original={original}
                modified={stateValue}
                language={language}
                loading={<LoaderIcon width={20} height={20} />}
                className={styles.editor}
                options={options}
                //onChange={handleEditorChange}
                beforeMount={handleBeforeMount}
            />
          </Stack>
        </Stack>
      )}
    </div>
  );
};

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Properties } from './types';

@singleton()
export class PropertiesStore {
  properties: Normalized<Properties> | null;
  isLoadingProperties: boolean;
  isLoadingEditProperty: boolean;
  isLoadingCreateProperty: boolean;

  constructor() {
    this.properties = null;
    this.isLoadingProperties = false;
    this.isLoadingEditProperty = false;
    this.isLoadingCreateProperty = false;

    makeAutoObservable(this);
  }
}

import { FC, MouseEventHandler, useCallback } from 'react';

import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { AcceptIcon } from '@/shared/ui/Icons/AcceptIcon/AcceptIcon';
import Button, { ButtonProps } from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';

import { SetVersionService } from '../api/SetVersionService';

export interface SetVersionButtonProps {
  variant?: 'outlined' | 'primary';
  disabled?: ButtonProps['disabled'];
}

const setVersionService = container.resolve(SetVersionService);

export const SetVersionButton: FC<SetVersionButtonProps> = observer(
  ({ variant = 'outlined', disabled }) => {
    const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
      event.preventDefault();
      setVersionService.showDialog = true;
    }, []);

    if (variant === 'outlined') {
      return (
        <Tooltip title='Установить версию' placement='top'>
          <span>
            <Button
              circle
              disabled={disabled}
              variant={ButtonVariants.Outlined}
              size={ButtonSizes.Small}
              onClick={handleClick}
            >
              <AcceptIcon />
            </Button>
          </span>
        </Tooltip>
      );
    }

    if (variant === 'primary') {
      return (
        <Button disabled={disabled} variant={ButtonVariants.Primary} onClick={handleClick}>
          Применить версию
        </Button>
      );
    }

    return null;
  }
);

SetVersionButton.displayName = 'SetVersionButton';

import {FC, SVGProps} from 'react';

export const ManyToOneIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg style={{
          rotate:"180deg",
          fill:"var(--primary-color)"
        }} fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m20 14.75c-1.52 0-2.75-1.23-2.75-2.75s1.23-2.75 2.75-2.75 2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75zm0-4c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"/>
                <path
                    d="m20 6.75c-1.52 0-2.75-1.23-2.75-2.75s1.23-2.75 2.75-2.75 2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75zm0-4c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"/>
                <path
                    d="m20 22.75c-1.52 0-2.75-1.23-2.75-2.75s1.23-2.75 2.75-2.75 2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75zm0-4c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"/>
                <path
                    d="m4 14.75c-1.52 0-2.75-1.23-2.75-2.75s1.23-2.75 2.75-2.75 2.75 1.23 2.75 2.75-1.23 2.75-2.75 2.75zm0-4c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25-.56-1.25-1.25-1.25z"/>
                <path d="m18 12.75h-12c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h12c.41 0 .75.34.75.75s-.34.75-.75.75z"/>
                <path
                    d="m18 20.75h-4c-2.42 0-3.75-1.33-3.75-3.75v-10c0-2.42 1.33-3.75 3.75-3.75h4c.41 0 .75.34.75.75s-.34.75-.75.75h-4c-1.58 0-2.25.67-2.25 2.25v10c0 1.58.67 2.25 2.25 2.25h4c.41 0 .75.34.75.75s-.34.75-.75.75z"/>
            </g>
        </svg>
    );
};

import { inject, injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { RegistrableValues } from '@/shared/lib/types';
import { notify } from '@/shared/ui/Toast/notify';
import { ResolverResult } from 'react-hook-form';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { AxiosResponse } from 'axios';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { Connect, GetBothSkeletonConnectRequest, SkeletonConnect } from '../types';
import { SkeletonConnectStore } from '../stores/SkeletonConnectStore';

@injectable()
export class SkeletonConnectService {
  constructor(
    private apiService: ApiService,
    private skeletonConnectStore: SkeletonConnectStore,
    private joyrideService: JoyRideService,
    @inject(RegistrableValues.ConnectorName) private connectName: string,
    @inject(RegistrableValues.ConnectorType) private connectType: string
  ) {
    makeAutoObservable(this);
  }

  get skeleton() {
    // @ts-ignore
    return this.skeletonConnectStore[this.connectType][this.connectName];
  }

  get isLoadingSkeleton() {
    return this.skeletonConnectStore.isLoadingSkeleton;
  }

  resolver(connect: Connect) {
    let result: ResolverResult = {
      values: connect,
      errors: {},
    };

    return {
      async on(callback: (prevResult: ResolverResult) => ResolverResult) {
        result = await callback(result);
        return this;
      },
      result() {
        return result;
      },
    };
  }

  async getSkeleton(connect: Connect) {
    this.skeletonConnectStore.isLoadingSkeleton = true;

    try {
      let url = `/editor/connect/type/${this.connectName}/${this.connectType}`;

      if (this.connectType === 'processor') {
        url = `editor/processor/type/${this.connectName}`;
      }

      const response = await this.apiService.instance.post<SkeletonConnect>(url, connect);
      // @ts-ignore
      this.skeletonConnectStore[this.connectType][this.connectName] = response.data;

      eventEmitter.emit(CustomEvents.GetConnectSkeleton, this.connectType, response.data);
    } catch {
      notify.error('Не удалось открыть форму');
    } finally {
      this.skeletonConnectStore.isLoadingSkeleton = false;
    }
  }

  async updateSkeleton(connect: Connect) {
    try {
      let url = `/editor/connect/type/${this.connectName}/${this.connectType}`;
      let method = 'post';

      if (this.connectType === 'processor') {
        url = `editor/processor/type/${this.connectName}`;
        method = 'post';
      }

      const response = await this.apiService.instance({ url, method, data: connect });

      // @ts-ignore
      this.skeletonConnectStore[this.connectType][this.connectName] = response.data;
    } catch {
      notify.error('Не удалось обновить форму');
    }
  }

  async getSkeletonForBoth(connectName: string, data: GetBothSkeletonConnectRequest) {
    const response = await this.apiService.instance.post<
      any,
      AxiosResponse<SkeletonConnect>,
      GetBothSkeletonConnectRequest
    >(`/editor/api/v2/connect/type/${connectName}/both`, data);

    eventEmitter.emit(CustomEvents.GetConnectSkeleton, 'input', response.data);

    return response.data;
  }
}

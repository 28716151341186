import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { ValidationResponse } from '@/entities/Connect/types';

@singleton()
export class ValidationConnectStore {
  validationResponse: ValidationResponse;
  isValid: boolean;
  validationSchema: any;

  constructor() {
    this.validationResponse = {
      paramsDescription: {},
      queryParamsDescription: {},
      filterDescription: {},
      warningParamsDescription: {},
      warningQueryParamsDescription: {},
      warningFilterDescription: {},
      result: false,
    };
    this.isValid = true;
    makeAutoObservable(this);
  }

  clear() {
    this.validationResponse = {
      paramsDescription: {},
      queryParamsDescription: {},
      filterDescription: {},
      warningParamsDescription: {},
      warningQueryParamsDescription: {},
      warningFilterDescription: {},
      result: false,
    };
    this.isValid = true;
  }
}

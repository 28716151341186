import { useQuery, UseQueryResult } from 'react-query';

import { api } from '../../service/apiInstance';
import { notify } from '../../shared/ui/Toast/notify';
import { Role } from './types';

export const useGetRoles = (): UseQueryResult<Role[]> => {
  return useQuery<Role[]>(
    ['roles'],
    async () => {
      const response = await api.get<Role[]>(`/editor/role/all`);

      return response.data;
    },
    {
      onError() {
        notify.error('Не удалось получить роли пользователя');
      },
    }
  );
};

import { FC, useCallback, useMemo } from 'react';

import { useNavigate, useParams } from 'react-router-dom';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { BlockService } from '@/entities/Block/services/BlockService';
import { ObjectId } from 'bson';
import { Block } from '@/entities/Block/types';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { RegistrableValues } from '@/shared/lib/types';

import Button from '../../../../shared/ui/Button';

import styles from './BlockCreationButton.module.scss';

const joyrideService = container.resolve(JoyRideService);

export const BlockCreationButton: FC = observer(() => {
  const navigate = useNavigate();
  const { flowId } = useParams();

  const blockService = useMemo(() => {
    container.register(RegistrableValues.FlowId, { useValue: flowId || '' });
    return container.resolve(BlockService);
  }, [flowId]);

  const handleButtonClick = useCallback(() => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
    const block = {
      flowId: flowId || '',
      id: new ObjectId().toString(),
      name: `Блок ${(blockService.blocks?.ids.length || 0) + 1}`,
      logSettings: {
        level: "debug",
        retentionTime: 5184000000
      }
    } as Block;

    blockService.createBlock(block);
  }, [blockService, flowId, navigate, joyrideService.stepIndex]);

  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData('application/reactflow', nodeType);
    event.dataTransfer.effectAllowed = 'move';
  };

  return (
    <div className={styles.root}>
      <span className={styles.title}>Добавить блок</span>
      <Button circle variant={ButtonVariants.Popup} onClick={handleButtonClick} id='buttonAddBlock' onDragStart={(event) => onDragStart(event, 'block')} draggable>
        <PlusIcon width={28} height={28} strokeWidth={1} />
      </Button>
    </div>
  );
});

import {makeAutoObservable, runInAction} from 'mobx';
import { injectable } from 'tsyringe';
import { AxiosResponse } from 'axios';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { notify } from '@/shared/ui/Toast/notify';

import Block from '../types';
import {BlockMiniStore} from "@/entities/Block/stores/BlockMiniStore";
import {normalize} from "@/shared/lib/normalize";
import {uniq} from "lodash";

@injectable()
export class BlockMiniService {
  constructor(
    private blockMiniStore: BlockMiniStore,
    private apiService: ApiService
  ) {

    makeAutoObservable(this);
  }

  get miniBlocks() {
    return this.blockMiniStore.miniBlocks;
  }

  async getAllMiniBlocks() {
    try {
      const response = await this.apiService.instance.get<Block[], AxiosResponse<Block[]>>(
        `editor/block/allMini`
      );

      const normalizedBlockMini = normalize(response.data, 'id');

      runInAction(() => {
        this.blockMiniStore.miniBlocks.ids = uniq([...this.blockMiniStore.miniBlocks.ids, ...normalizedBlockMini.ids]);
        this.blockMiniStore.miniBlocks.entities = {
          ...this.blockMiniStore.miniBlocks.entities,
          ...normalizedBlockMini.entities,
        };
      });

    } catch (error) {
      throw error;
    }
  }

}

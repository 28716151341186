import {container} from "tsyringe";
import {BlockMiniService} from "@/entities/Block/services/BlockMiniService";
import styles from "./GraphTooltip.module.scss"
import dayjs from "dayjs";

const blockMiniService = container.resolve(BlockMiniService);

export interface GraphTooltipProps {
  active?: boolean;
  payload?: object[];
  label?: number;
}

export const GraphTooltip = ({ active, payload, label } : GraphTooltipProps) => {

  const blockMini = blockMiniService.miniBlocks;
  const date = dayjs(label*1000).format("DD.MM.YYYY HH:mm:ss")
  const getBlockName = (elem : any) => {
    return `${blockMini.entities[elem].flowName} - ${blockMini.entities[elem].name}`
  }

  if (active && payload && payload.length) {
    return (
      <div className={styles.root}>
        {payload.map(elem => {
          // @ts-ignore
          return <p className={styles.label}>{`${getBlockName(elem.name)} : ${elem.value}`}</p>
        })}
        <p>
          {`Время: ${date}`}
        </p>
      </div>
    );
  }

  return null;
};
import { FC, memo, MouseEventHandler, useCallback } from 'react';
import { container } from 'tsyringe';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';

const pythonLibsStore = container.resolve(PythonLibsStore);
export const EditPythonLibsButton: FC = memo(() => {
  const { set, get } = useSearchParamsTemplate();
  const pythonLibName = get(SearchParams.PythonLibName);

  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      set([
        SearchParams.ShowEditPythonLibForm,
        { [SearchParams.PythonLibName]: pythonLibsStore.selectedLib?.title },
      ]);
    },
    [pythonLibName, set, pythonLibsStore.selectedLib?.title]
  );
  return (
    <Tooltip title='Изменить библиотеку' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <SettingsIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

EditPythonLibsButton.displayName = 'EditPythonLibsButton';

import React, { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { Role } from '@/entities/Role';
import { notify } from '@/shared/ui/Toast/notify';

import { CreateRoleService } from '../api/CreateRoleService';

const createRoleService = container.resolve(CreateRoleService);

export interface CreateRoleFormDialogProps {}

export const CreateRoleFormDialog: FC<CreateRoleFormDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, handleSubmit, reset } = useForm<Role>();

  const handleClose = useCallback(() => {
    createRoleService.open = false;
    reset({ name: '' });
  }, [reset]);

  const handleValidSubmit = useCallback<SubmitHandler<Role>>(
    async (role) => {
      try {
        setIsLoading(true);

        await createRoleService.createRole(role);
        handleClose();

        notify.success('Роль добавлена');
      } catch {
        notify.error('Не удалось добавить роль');
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose]
  );

  return (
    <Dialog fullWidth open={createRoleService.open} onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Добавить роль</DialogTitle>
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <DialogContent>
          <Grid container alignItems='center'>
            <Grid item xs={12} paddingY={0.5}>
              <FieldInput register={register} error='' name='name' label='Наименование роли' />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button type='submit' variant={ButtonVariants.Popup} loading={isLoading}>
            Добавить
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

CreateRoleFormDialog.displayName = 'CreateRoleFormDialog';

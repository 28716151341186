import { injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { AxiosResponse } from 'axios';
import { ObjectId } from "bson";
import { uniq, get } from 'lodash';
import { normalize } from '@/shared/lib/normalize';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { dashboardTitles } from "@/entities/Dashboard/widgets/DashboardPlatform/constants/titles";

import { DashboardStore } from '../stores/DashboardStore';
import { Dashboard, DataPanelDashboard } from '../types';

const missDashbordError = `Не удалось получить ${dashboardTitles.base}`;

@injectable()
export class DashboardService {
  constructor(private apiService: ApiService, private dashboardStore: DashboardStore) {
    makeAutoObservable(this);
  }

  get dashboards(): DashboardStore['dashboards'] {
    return this.dashboardStore.dashboards;
  }

  get isLoadingDashboards() {
    return this.dashboardStore.isLoadingDashboards;
  }

  get isLoadingDashboard() {
    return this.dashboardStore.isLoadingDashboard;
  }

  get isLoadingDeleteDashboard() {
    return this.dashboardStore.isLoadingDeleteDashboard;
  }

  get isLoadingUpdateDashboard() {
    return this.dashboardStore.isLoadingUpdateDashboard
  }

  get selectedDashboardId() {
    return this.dashboardStore.selectedDashboardId
  }

  set selectedDashboardId(id) {
    this.dashboardStore.selectedDashboard = this.dashboardStore.dashboards.entities[id]
    this.dashboardStore.selectedDashboardId = id
  }

  get selectedDashboard() {
    return this.dashboardStore.selectedDashboard
  }

  getPanelData(panelId: string) {
    const dataPanel = get(
      this.selectedDashboard,
      `data.panels.${panelId}`,
      { type: "" },
    );
    return get(dataPanel, 'data', {
      graphType: "",
      blockIdList: [],
    });
  }

  setLayoutsDashboard(layouts: object) {
    // @ts-ignore
    if (this.dashboardStore.selectedDashboard) {
      if (!this.dashboardStore.selectedDashboard.data) {
        this.dashboardStore.selectedDashboard.data = {
          layouts: {lg: [], md: [], sm: [], xs: [], xxs: [] }
        }
      }
      this.dashboardStore.selectedDashboard.data.layouts = layouts
    }
  }

  addElementToLayoutDashboard() {
    let id = new ObjectId().toHexString();
    let resultDashboard = {...this.dashboardStore.selectedDashboard}

    if (!resultDashboard.data.panels) {
      resultDashboard.data.panels = {}
    }
    resultDashboard.data.panels[id] = {
      type: ""
    }

    if (!resultDashboard.data.layouts) {
      resultDashboard.data = {
        ...resultDashboard.data,
        layouts: {lg: [], md: [], sm: [], xs: [], xxs: [] }
      }
    } else {
      if (!resultDashboard.data.layouts.lg) {
        resultDashboard.data.layouts.lg = []
      }
      if (!resultDashboard.data.layouts.md) {
        resultDashboard.data.layouts.md = []
      }
      if (!resultDashboard.data.layouts.sm) {
        resultDashboard.data.layouts.sm = []
      }
      if (!resultDashboard.data.layouts.xs) {
        resultDashboard.data.layouts.xs = []
      }
      if (!resultDashboard.data.layouts.xxs) {
        resultDashboard.data.layouts.xxs = []
      }
    }

    for (let key in resultDashboard.data.layouts) {
      // @ts-ignore
      resultDashboard.data.layouts[key].push({
        i: id,
        w: 4,
        h: 1,
        x: 1,
        y: 1
      })
    }
    this.dashboardStore.selectedDashboard = resultDashboard
    return resultDashboard
  }

  changeDashboardPanel(id:string, newPanel: DataPanelDashboard) {
    let resultDashboard = {...this.dashboardStore.selectedDashboard}
    if (!resultDashboard.data.panels) {
      resultDashboard.data.panels = {}
    }
    resultDashboard.data.panels[id] = newPanel
    this.dashboardStore.selectedDashboard = resultDashboard
  }

  deleteDashboardPanel(id:string) {
    let resultDashboard = {...this.dashboardStore.selectedDashboard}
    delete resultDashboard.data.panels[id]
    for (let key in resultDashboard.data.layouts) {
      let indexFind = resultDashboard.data.layouts[key].findIndex(layout => {
        return layout.i === id
      })
      if (indexFind >= 0) {
        resultDashboard.data.layouts[key].splice(indexFind, 1)
      }
    }
    this.dashboardStore.selectedDashboard = resultDashboard
  }

  resetDashboards() {
    runInAction(() => {
      this.dashboardStore.dashboards = {ids: [], entities: {}};
    });
  }

  setIsLoadingDashboard(value: boolean) {
    this.dashboardStore.isLoadingDashboard = value;
  }

  setDashboard(dashboard: Dashboard, updateDashboardStore?: boolean) {
    if (updateDashboardStore) {
      this.dashboardStore.dashboards.entities[dashboard.id] = dashboard;
    }
    eventEmitter.emit(CustomEvents.InitializeDashboard, dashboard);
  }

  async getDashboards(): Promise<void> {
    this.dashboardStore.isLoadingDashboards = true;

    try {
      const response = await this.apiService.instance.get<
        Dashboard[],
        AxiosResponse<Dashboard[]>,
        Dashboard[]
      >('editor/dashboard/allMini');

      const normalizedDashboards = normalize(response.data, 'id');

      runInAction(() => {
        this.dashboardStore.dashboards.ids = uniq(normalizedDashboards.ids);
        this.dashboardStore.dashboards.entities = {
          ...normalizedDashboards.entities,
        };
        if (this.dashboardStore.selectedDashboardId && !this.dashboardStore.selectedDashboard) {
          this.dashboardStore.selectedDashboard = normalizedDashboards.entities[this.dashboardStore.selectedDashboardId]
        }
      });
    } catch (error) {
      notify.error(`Не удалось получить ${dashboardTitles.pl}`);
      throw error;
    } finally {
      this.dashboardStore.isLoadingDashboards = false;
    }
  }

  async getDashboard(dashboardId: string): Promise<void> {
    this.dashboardStore.isLoadingDashboard = true;

    try {
      const response = await this.apiService.instance.get<Dashboard>(`editor/dashboard/${dashboardId}`);

      this.dashboardStore.dashboards.entities[dashboardId] = response.data;
      this.dashboardStore.selectedDashboard = response.data
      eventEmitter.emit(CustomEvents.InitializeDashboard, response.data);
    } catch {
      notify.error(missDashbordError);
    } finally {
      this.dashboardStore.isLoadingDashboard = false;
    }
  }

  async updateDashboard(dashboard: Dashboard) {
    this.dashboardStore.isLoadingUpdateDashboard = true;

    try {
      const formattedDashboard = {
        ...dashboard
      };

      const response = await this.apiService.instance.put<Dashboard>('/editor/dashboard', formattedDashboard);

      runInAction(() => {
        this.dashboardStore.dashboards.entities[response.data.id] = response.data;
        this.dashboardStore.selectedDashboard = response.data
        this.getDashboards()
      });

      return response.data;
    } catch {
      notify.error(missDashbordError);
    } finally {
      this.dashboardStore.isLoadingUpdateDashboard = false;
    }
  }

  async deleteDashboard(dashboardId: string): Promise<void> {
    this.dashboardStore.isLoadingDeleteDashboard = true;

    try {
      await this.apiService.instance.delete(`/editor/dashboard/${dashboardId}`);

      runInAction(() => {
        this.dashboardStore.dashboards.ids.splice(this.dashboardStore.dashboards.ids.indexOf(dashboardId), 1);
        delete this.dashboardStore.dashboards.entities[dashboardId];
        this.getDashboards()
      });
    } catch (error) {
      notify.error(missDashbordError);
      throw error;
    } finally {
      this.dashboardStore.isLoadingDeleteDashboard = false;
    }
  }

  async updateMiniDashboard(
    dashboardId: string,
    name: string,
    description: string,
  ): Promise<void> {
    this.dashboardStore.isLoadingUpdateDashboard = true;

    try {
      const dashboard = this.dashboardStore.dashboards.entities[dashboardId];

      await this.apiService.instance.put('/editor/dashboard', {
        ...dashboard,
        name,
        description
      });

      runInAction(() => {
        dashboard.name = name;
        dashboard.description = description;
        this.dashboardStore.dashboards.ids = uniq([dashboardId, ...this.dashboardStore.dashboards.ids]);
      });
    } catch {
      notify.error(missDashbordError);
    } finally {
      this.dashboardStore.isLoadingUpdateDashboard = false;
    }
  }
}

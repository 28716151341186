import React, { FC } from 'react';

import { observer } from 'mobx-react-lite';
import Joyride, { CallBackProps, EVENTS, STATUS, ACTIONS } from 'react-joyride';
import { container } from 'tsyringe';
import { CloseOnbordingService } from '@/modules/JoyRideOnboarding/services/CloseOnbordingService';
import { SaveChangesService } from '@/entities/Onboarding/services/SaveChangesService';

import { JoyrideContext } from './utils/context';
import { JoyrideProviderProps } from '../JoyRideOnboarding/types';
import { JoyRideService } from './api/JoyRideService';

/*eslint-disable*/

const joyrideService = container.resolve(JoyRideService);
const closeOnboarding = container.resolve(CloseOnbordingService);
const saveChanges = container.resolve(SaveChangesService);

export const JoyrideProvider: FC<JoyrideProviderProps> = observer(
  ({ steps, joyrideProps, onStepChange, children }) => {
    const handleJoyrideCallback = (data: CallBackProps) => {
      const { action, index, status, type, step } = data;

      saveChanges.updateCurrentStepData(step);
      saveChanges.fetchCurrentStep();

      if (([ACTIONS.RESET] as string[]).includes(action)) {
        joyrideService.handleJoyRide(false);
        joyrideService.updateCurrentStep(0);
      }

      if (([STATUS.FINISHED, STATUS.SKIPPED] as string[]).includes(status)) {
        let stepI = joyrideService.stepIndex;
        joyrideService.handleJoyRide(false);
        joyrideService.updateCurrentStep(0);
      }

      if (([EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
        if (step?.target) {
          const nextStepIndex = index + 1;
          joyrideService.updateCurrentStep(nextStepIndex);
        }
      }

      if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
        if (step?.target) {
          const nextStepIndex = index + 1;
          joyrideService.updateCurrentStep(nextStepIndex);
          joyrideService.handleJoyRide(false);

          setTimeout(() => {
            joyrideService.updateCurrentStep(nextStepIndex);
            joyrideService.handleJoyRide(true);
          }, 400);
        }

        if (([EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
          joyrideService.handleJoyRide(false);
          joyrideService.updateCurrentStep(0);
        }
      }

      setTimeout(() => {
        if (([EVENTS.TOUR_END] as string[]).includes(type)) {
            joyrideService.handleJoyRide(false);
            joyrideService.updateCurrentStep(0);
          if (joyrideService.isTourEnded) {
            joyrideService.handleJoyRide(false);
            joyrideService.updateCurrentStep(0);
          }
          if (!joyrideService.isTourEnded) {
          }
        }
      }, 450);
    };

    const value = {
      steps,
      joyrideProps,
    };

    return (
      <JoyrideContext.Provider value={{ ...value }}>
        <Joyride
          steps={joyrideService.steps}
          continuous
          run={joyrideService.run}
          showSkipButton
          hideBackButton
          hideCloseButton
          disableOverlayClose
          disableCloseOnEsc
          callback={handleJoyrideCallback}
          stepIndex={joyrideService.stepIndex}
          styles={{
            options: {
              zIndex: 10000,
            },
            tooltipTitle: {
              fontSize: '1.25rem',
              fontWeight: '550',
            },
            tooltip: {
              borderRadius: '16px',
            },
            buttonNext: {
              backgroundColor: '#6c5dd3',
            },
          }}
          locale={{
            next: 'Далее',
            back: 'Назад',
            skip: 'Пропустить обучение',
            last: 'Готово',
            close: 'Закрыть',
          }}
          {...joyrideProps}
        />
        {children}
      </JoyrideContext.Provider>
    );
  }
);

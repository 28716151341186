import React from 'react';

import { container } from "tsyringe";
import { get as lget } from "lodash";
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { panelTitles } from '@/entities/Dashboard/widgets/DashboardPlatform/constants/titles';
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";
import { DashboardService } from "@/entities/Dashboard/services/DashboardService";

import { FormSettings } from './FormSettings';

const dashboardService = container.resolve(DashboardService);


export function DashboardPanelSettings() {

  const { has, get, remove } = useSearchParamsTemplate();

  const isOpenDrawer = has(SearchParams.ShowDashboardPanelSettingsForm);
  const dashboardPanelId = get(SearchParams.DashboardPanelId);
  const dashboardPanel = lget(dashboardService, ['selectedDashboard', 'data', 'panels', dashboardPanelId], null);

  const handleDrawerClose = (): void => {
    remove(actionSearchParams);
  };

  if (!dashboardPanel || !isOpenDrawer) return null;

  return (
    <Drawer
      width={434}
      title={`Редактирование ${panelTitles.gen}`}
      onClose={handleDrawerClose}
    >
      <FormSettings
        dashboardPanelId={dashboardPanelId}
      />
    </Drawer>
  );
}

import { makeAutoObservable } from 'mobx';
import { injectable } from 'tsyringe';
import { normalize } from '@/shared/lib/normalize';
import { notify } from '@/shared/ui/Toast/notify';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { PropertiesStore } from '../model/PropertiesStore';

@injectable()
export class PropertiesListService {
  constructor(private apiService: ApiService, private propertyStore: PropertiesStore) {
    makeAutoObservable(this);
  }

  get properties() {
    return this.propertyStore.properties;
  }

  get isLoadingProperties() {
    return this.propertyStore.isLoadingProperties;
  }

  async getProperties() {
    this.propertyStore.isLoadingProperties = true;
    try {
      const response = await this.apiService.instance.get('/editor/properties/all');

      this.propertyStore.properties = normalize(response.data, 'id');
    } catch (error) {
      notify.error('Не удалось получить список конфигураций');
      throw error;
    } finally {
      this.propertyStore.isLoadingProperties = false;
    }
  }
}

import { FC } from 'react';

import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { useParams } from 'react-router-dom';

import { FlowForm } from './FlowForm';

export const FlowFormDrawer: FC = () => {
  const { has, get, remove } = useSearchParamsTemplate();
  const { flowId } = useParams();

  const isOpenDrawer = has(SearchParams.ShowCreateFlowForm) || has(SearchParams.ShowEditFlowForm);
  const title = has(SearchParams.ShowEditFlowForm) ? 'Редактировать поток' : 'Создать поток';

  const handleDrawerClose = (): void => {
    remove([SearchParams.FlowId, SearchParams.ShowCreateFlowForm, SearchParams.ShowEditFlowForm]);
  };

  return isOpenDrawer ? (
    <Drawer width={434} title={title} onClose={handleDrawerClose}>
      <FlowForm
        key={`${flowId}${get(SearchParams.ShowEditFlowForm)}${get(
          SearchParams.ShowCreateFlowForm
        )}`}
      />
    </Drawer>
  ) : null;
};

import { FC, SVGProps } from 'react';

export const MessageIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 16 17' fill='currentColor' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M14.6286 0C15.3883 0 16.0001 0.597027 16.0001 1.33885V10.264C16.0001 11.0056 15.3885 11.6028 14.6286 11.6028H12.1143L12.5857 16.0654L8.00004 11.6028H1.3715C0.611793 11.6028 0 11.0058 0 10.264V1.33885C0 0.597226 0.611589 0 1.3715 0H14.6286Z'
        fill='currentColor'
      />
    </svg>
  );
};

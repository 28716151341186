import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

@singleton()
export class AuthStore {
  headerAuth: string
  isAuth: boolean

  constructor() {
    this.headerAuth = ''
    this.isAuth = false
    makeAutoObservable(this);
  }
}

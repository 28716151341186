import { Segment } from '../enums';
import { BaseValues } from './base.abstract';
export class YearValues extends BaseValues {
    constructor() {
        super(...arguments);
        this.segment = Segment.year;
    }
    clone(d = {}) {
        return new YearValues(Object.assign(Object.assign({}, this), d));
    }
}

import { FC, memo, useCallback } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import Typography from '@mui/material/Typography';
import { container } from 'tsyringe';

import { UpsertLicenseService } from '../../api/UpsertLicenseService';

import styles from './styles.module.scss';

const upsertLicenseService = container.resolve(UpsertLicenseService);

export interface AddLicenseCardProps {}

export const AddLicenseCard: FC<AddLicenseCardProps> = memo(() => {
  const handleClick = useCallback(() => {
    upsertLicenseService.open = true;
  }, []);

  return (
    <Card className={styles.root} variant='outlined' onClick={handleClick}>
      <CardContent sx={{ height: '100%', minWidth: 235, minHeight: 144 }}>
        <Stack direction='row' gap={1.5} alignItems='center' justifyContent='center' height='100%'>
          <AddBoxOutlinedIcon />
          <Typography>Добавить ключ</Typography>
        </Stack>
      </CardContent>
    </Card>
  );
});

AddLicenseCard.displayName = 'AddLicenseCard';

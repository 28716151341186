import { FC } from 'react';

import cn from 'classnames';

import { IndicatorTypes } from './types';

import styles from './Indicator.module.scss';

export interface IndicatorProps {
  type: IndicatorTypes;
  pulse?: boolean;
}

export const Indicator: FC<IndicatorProps> = ({ type, pulse }) => {
  const className = cn(styles.root, {
    [styles.error]: type === IndicatorTypes.Error,
    [styles.success]: type === IndicatorTypes.Success,
    [styles.disabled]: type === IndicatorTypes.Disabled,
    [styles.pulse]: pulse,
    [styles.warning]: type === IndicatorTypes.Warning
  });

  return <div className={className} />;
};

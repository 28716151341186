import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';
import _ from 'lodash';

import { PagePythonScript, PythonScript, PythonScriptRequest } from '../model/types';
import { PythonBeanStore } from '../model/PythonBeanStore';

@singleton()
export class PythonBeanService {
  isLoading: boolean;

  constructor(private apiService: ApiService, private pythonBeanStore: PythonBeanStore) {
    this.isLoading = false;

    makeAutoObservable(this);
  }

  async getPythonScripts(request: PythonScriptRequest) {
    this.isLoading = true;
    try {
      const response = await this.apiService.instance.post<PagePythonScript>(
        '/editor/pythonScript/search',
        request
      );

      const normalizedVersions = normalize(response.data.pythonScriptPage.content, 'id');

      runInAction(() => {
        if (this.pythonBeanStore.scripts) {
          this.pythonBeanStore.scripts.ids = _.uniq([
            ...this.pythonBeanStore.scripts.ids,
            ...normalizedVersions.ids,
          ]);

          this.pythonBeanStore.scripts.entities = {
            ...this.pythonBeanStore.scripts.entities,
            ...normalizedVersions.entities,
          };
        } else {
          this.pythonBeanStore.scripts = normalizedVersions;
        }

        this.pythonBeanStore.lastPage = response.data.pythonScriptPage.totalPages;
      });
    } catch (error) {
      notify.error('Не удалось получить python скрипты');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }

  async getPythonScript(pythonScriptId: string) {
    try {
      const response = await this.apiService.instance.get<PythonScript>(
        `/editor/pythonScript/${pythonScriptId}`
      );

      runInAction(() => {
        if (this.pythonBeanStore.scripts) {
          this.pythonBeanStore.scripts.entities[response.data.id] = response.data;
        } else {
          this.pythonBeanStore.scripts = {
            entities: { [response.data.id]: response.data },
            ids: [],
          };
        }
      });
    } catch (error) {
      notify.error('Не удалось получить python скрипт');
      throw error;
    }
  }
}

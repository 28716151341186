import { SearchParams } from "@/hooks/useTemplateSearchParams";

const modes: ({ [key: string]: any }) = {
  tree: {
    edit: SearchParams.ShowEditTFlowForm,
    create: SearchParams.ShowCreateTFlowForm,
    delete: SearchParams.ShowTFlowDeleteModal,
  },
  list: {
    edit: SearchParams.ShowEditFlowForm,
    create: SearchParams.ShowCreateFlowForm,
    delete: SearchParams.ShowFlowDeleteModal,
  }
};
export default (mode: string) => (modes[mode] || modes.list);

import { ChangeEventHandler, FC, useCallback, useEffect, useMemo, useState } from 'react';

import { Stack } from '@mui/system';
import cn from 'classnames';
import { Handle, NodeProps, Position, useStore } from 'reactflow';
import { motion, Variants } from 'framer-motion';
import { useParams } from 'react-router-dom';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { BlockRelationService } from '@/entities/Block/services/BlockRelationService';
import { HandleListBetweenBlocks } from '@/entities/Block/components/HandleListBetweenBlocks/HandleListBetweenBlocks';
import { withHighlightConnectWhenLineHover } from '@/entities/Connect/hoc/withHighlightConnectWhenLineHover';
import { ConnectEditor } from '@/entities/Connect/components/ConnectEditor/ConnectEditor';
import { TextWithIndicator } from '@/entities/Block/components/TextWithIndicator/TextWithIndicator';
import { Switch } from '@/shared/ui/Switch/Switch';
import { ConnectionHandle } from '@/entities/Block/components/ConnectionHandle/ConnectionHandle';
import { OpenBlockForm, OpenDeleteBlock } from '@/features';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import { RegistrableValues } from '@/shared/lib/types';
import { ConnectService } from '@/entities/Connect/services/ConnectService';
import { BlockService } from '@/entities/Block/services/BlockService';
import { BlockMonitoringService } from '@/entities/Block/services/BlockMonitoringService';
import { ConnectList } from '@/entities/Connect/components/ConnectList/ConnectList';
import { ConnectTypes } from '@/entities/Connect/components/ConnectList/types';

import styles from '@/entities/Block/Block.module.scss';

export type FlowVersionBlockProps = NodeProps;

const variants: Variants = {
  open: {
    x: '0%',
  },
  close: {
    x: '100%',
  },
};

const allConnectService = container.resolve(AllConnectService);
const joyrideService = container.resolve(JoyRideService);

export const FlowVersionBlock: FC<FlowVersionBlockProps> = observer(({ id }) => {
  const { flowId, blockId } = useParams();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isSelectedBlock, setIsSelectedBlock] = useState<boolean>(false);

  const connectService = useMemo(() => {
    container.register(RegistrableValues.BlockId, { useValue: id });
    return container.resolve(ConnectService);
  }, [id]);

  const { blockService, blockRelationService } = useMemo(() => {
    container.register(RegistrableValues.FlowId, { useValue: flowId || '' });

    const blockService = container.resolve(BlockService);
    const blockMonitoringService = container.resolve(BlockMonitoringService);
    const blockRelationService = container.resolve(BlockRelationService);

    return {
      blockService,
      blockMonitoringService,
      blockRelationService,
    };
  }, [flowId]);

  const connectionHandleId = useStore(useCallback((store) => store.connectionHandleId, []));

  const block = blockService.blocks?.entities[id];

  const isConnectable = useMemo(
    () =>
      blockService.isVisibleConnectionLine &&
      !blockService.isVisiblePanelChooseConnects &&
      connectionHandleId !== id,
    [
      blockService.isVisibleConnectionLine,
      blockService.isVisiblePanelChooseConnects,
      connectionHandleId,
      id,
    ]
  );
  withHighlightConnectWhenLineHover(ConnectEditor, {
    blockId: block?.id || '',
    blockRelationService,
  });
  const handleSwitchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      if (block) {
        blockService.updateBlock({ ...block, isActive: event.target.checked });

        setIsActive(event.target.checked);
      }
    },
    [block, blockService]
  );

  const handleBlockMouseEnter = (): void => {
    setIsActive(true);
    if (joyrideService.run) {
      setIsActive(true);
    }
  };

  const handleBlockMouseLeave = (): void => {
    if (!isSelectedBlock) {
      setIsActive(false);
    }
    if (joyrideService.run) {
      setIsActive(true);
    }
  };

  useEffect(() => {
    const status = block?.id === blockId;

    setIsSelectedBlock(status);
    setIsActive(status);
  }, [block?.id, blockId]);

  useEffect(() => {
    if (!block?.isActive) {
      setIsSelectedBlock(false);
      setIsActive(false);
    }
  }, [block?.isActive]);

  return (
    <div onMouseEnter={handleBlockMouseEnter} onMouseLeave={handleBlockMouseLeave} id='block1'>
      <ConnectionHandle id={id} visible={!blockService.isVisiblePanelChooseConnects} />
      <Handle
        className={styles.targetHandle}
        id={id}
        position={Position.Bottom}
        type='target'
        isConnectable={isConnectable}
      />
      <HandleListBetweenBlocks
        connectIds={connectService.inputList?.ids || []}
        relationPositions={blockRelationService.relationPositions}
        blockId={block?.id || ''}
        handlesType='target'
        lineIdWhichHover={blockRelationService.lineIdWhichHover}
      />
      <HandleListBetweenBlocks
        connectIds={connectService.outputList?.ids || []}
        relationPositions={blockRelationService.relationPositions}
        blockId={block?.id || ''}
        handlesType='source'
        lineIdWhichHover={blockRelationService.lineIdWhichHover}
      />
      <div className={styles.root}>
        <Stack direction='row' sx={{ height: '100%' }}>
          <motion.div
            className={cn(styles.toolbar, {
              [styles.active]: isActive,
              [styles.inactive]: !block?.isActive,
            })}
            variants={variants}
            initial='close'
            animate={isActive ? 'open' : 'close'}
            transition={{ ease: 'easeInOut', duration: 0.2 }}
          >
            <Stack justifyContent='space-between'>
              <OpenBlockForm blockId={id} />
              <OpenDeleteBlock blockId={id} />
            </Stack>
          </motion.div>
          <div
            className={cn(styles.body, {
              [styles.active]: isActive,
              [styles.inactive]: !block?.isActive,
            })}
          >
            <div className={styles.section_title}>
              <Stack spacing={1} overflow='hidden'>
                <p className={cn(styles.title, { [styles.inactive]: !block?.isActive })}>
                  {block?.name}
                </p>
                <p className={styles.description}>{block?.description}</p>
              </Stack>
            </div>
            <div className={styles.section}>
              <Stack width='100%' spacing={2}>
                <Stack spacing={2.5}>
                  <Stack width='100%' direction='row' justifyContent='space-between'>
                    <p>
                      Статус:{' '}
                      <span
                        className={cn(styles.status, {
                          [styles.success]: block?.isActive,
                          [styles.error]: !block?.isActive,
                        })}
                      >
                        {block?.isActive ? 'Активен' : 'Неактивен'}
                      </span>
                    </p>
                    <Switch checked={block?.isActive} onChange={handleSwitchChange} />
                  </Stack>
                  <Stack width='100%' direction='row' justifyContent='space-between' spacing={1.5}>
                    <TextWithIndicator
                      indicatorType={
                        block?.isGuaranteedDelivery
                          ? IndicatorTypes.Success
                          : IndicatorTypes.Disabled
                      }
                      text='Гарантия доставки'
                    />
                    <TextWithIndicator
                      indicatorType={
                        block?.isGuaranteedOrder ? IndicatorTypes.Success : IndicatorTypes.Disabled
                      }
                      text='Гарантия порядка'
                    />
                  </Stack>
                </Stack>
              </Stack>
            </div>
            <div className={styles.section_connect} id='addInConnector'>
              <ConnectList
                title='Коннекторы входящих данных'
                connectors={connectService.inputList}
                connectorCreatorOptions={allConnectService.inputOptions}
                type={ConnectTypes.Input}
                blockRelationService={blockRelationService}
                blockId={block?.id || ''}
                id={`addInInput-${id}`}
              />
            </div>
            <div className={styles.section_connect} id='addHandler'>
              <ConnectList
                title='Обработчики'
                connectors={connectService.processorList}
                connectorCreatorOptions={allConnectService.processorOptions}
                type={ConnectTypes.Processor}
                blockId={block?.id || ''}
                id={`addInInput -${id}`}
              />
            </div>
            <div className={styles.bottom_section}>
              <ConnectList
                title='Коннекторы исходящих данных'
                connectors={connectService.outputList}
                connectorCreatorOptions={allConnectService.outputOptions}
                type={ConnectTypes.Output}
                blockRelationService={blockRelationService}
                blockId={block?.id || ''}
                id={`addInOutput -${id}`}
              />
            </div>
          </div>
        </Stack>
      </div>
    </div>
  );
});

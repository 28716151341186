import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { UseFormGetValues } from 'react-hook-form';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Stack from '@mui/system/Stack';
import Box from '@mui/system/Box';
import _ from 'lodash';

import { Connect } from '../../../entities/Connect/types';

export interface UpdateSkeletonWrapperProps {
  isSendSkeletonForEnrichByButton: boolean;
  isSendSkeletonForEnrichByDebounce: boolean;
  getValues: UseFormGetValues<Connect>;
  sx?: object;
  isManyToOne?: boolean;
  render: (onChange: () => void, onEnrichButtonClick?: () => void) => JSX.Element;

  updateSkeleton(values: any): void;
}

export const UpdateSkeletonWrapper: FC<UpdateSkeletonWrapperProps> = observer(
  ({
    isSendSkeletonForEnrichByButton,
    isSendSkeletonForEnrichByDebounce,
     sx,
    render,
    getValues,
    updateSkeleton,
  }) => {
    const debouncedChange = _.debounce(() => {
      if (isSendSkeletonForEnrichByDebounce) {
        updateSkeleton(getValues());
      }
    }, 600);

    const onChange = () => {
      debouncedChange();
    };

    const handleEnrichButtonClick = () => {
      updateSkeleton(getValues());
    };

    return (
      <Stack alignItems='flex-end' gap={2} direction='row' sx={sx}>
        {render(onChange)}
        {isSendSkeletonForEnrichByButton && (
          <Box width={320}>
            <Button
              doesUseInFormOrTable
              variant={ButtonVariants.Secondary}
              onClick={handleEnrichButtonClick}
            >
              Применить
            </Button>
          </Box>
        )}
      </Stack>
    );
  }
);

UpdateSkeletonWrapper.displayName = 'UpdateSkeletonWrapper';

import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DeleteNotificationButtonVariants } from '../lib/types';
import { DeleteNotificationService } from '../api/DeleteNotificationService';

const deleteNotificationService = container.resolve(DeleteNotificationService);

export interface DeleteNotificationButtonProps {
  variant?: DeleteNotificationButtonVariants;
}

export const DeleteNotificationButton: FC<DeleteNotificationButtonProps> = observer(
  ({ variant = DeleteNotificationButtonVariants.Default }) => {
    const handleClick = useCallback(async () => {
      deleteNotificationService.open = true;
    }, []);

    if (variant === DeleteNotificationButtonVariants.Circle) {
      return (
        <Tooltip title='Удалить уведомление' placement='top'>
          <span>
            <Button
              circle
              variant={ButtonVariants.Outlined}
              size={ButtonSizes.Small}
              onClick={handleClick}
            >
              <TrashIcon />
            </Button>
          </span>
        </Tooltip>
      );
    }

    return (
      <Box width={212}>
        <Button variant={ButtonVariants.Text} onClick={handleClick}>
          Удалить
        </Button>
      </Box>
    );
  }
);

DeleteNotificationButton.displayName = 'DeleteNotificationButton';

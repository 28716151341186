import React, { FC, memo } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/system/Box';

export interface JsonSettingsButtonProps {
  onClick(): void;
}

export const JsonSettingsButton: FC<JsonSettingsButtonProps> = memo(({ onClick }) => {
  return (
    <Box maxWidth={300}>
      <Button
        doesUseInFormOrTable
        size={ButtonSizes.Small}
        variant={ButtonVariants.Secondary}
        onClick={onClick}
      >
        Настройки JSON
      </Button>
    </Box>
  );
});

JsonSettingsButton.displayName = 'JsonSettingsButton';

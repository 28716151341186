import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Version } from './types';

@singleton()
export class FlowVersionStore {
  versions: Normalized<Version> | null;
  isLoading: boolean;
  lastPage: number;

  constructor() {
    this.versions = null;
    this.isLoading = false;
    this.lastPage = 0;

    makeAutoObservable(this);
  }

  reset() {
    this.versions = null;
    this.isLoading = false;
    this.lastPage = 0;
  }
}

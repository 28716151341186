import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

import { ExternalAppStore } from '../model/ExternalAppStore';
import { ExternalService } from '../model/types';

@singleton()
export class ExternalAppService {
  constructor(private apiService: ApiService, private externalAppStore: ExternalAppStore) {
    makeAutoObservable(this);
  }

  async getExternalApps() {
    const response = await this.apiService.instance.get<ExternalService[]>(
      '/editor/externalService/all'
    );

    this.externalAppStore.externalApps = normalize(response.data, 'id');

    return response.data;
  }
}

import {FC, useCallback, useEffect, useState} from 'react';

import {observer} from 'mobx-react-lite';
import {SubmitErrorHandler, SubmitHandler, useForm} from 'react-hook-form';
import {container} from 'tsyringe';
import {Settings, SettingsService, SettingsStore} from '@/entities/Settings';
import {FieldSelect} from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import Box from '@mui/system/Box';
import {Fields} from '@/shared/ui/Fields/Fields';
import Stack from '@mui/system/Stack';
import {notify} from '@/shared/ui/Toast/notify';
import {ErrorWrapper} from '@/shared/ui';
import {EmailServerSettings} from '@/features/UpsertSettings/ui/EmailServerSettings';
import Grid from '@mui/material/Grid';
import Button from '@/shared/ui/Button';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {UpsertSettingsService} from '../api/UpsertSettingsService';
import {TelegramSettings} from "@/features/UpsertSettings/ui/TelegramSettings";

import styles from './styles.module.scss';

const settingsStore = container.resolve(SettingsStore);
const settingsService = container.resolve(SettingsService);
const upsertSettingsService = container.resolve(UpsertSettingsService);

export interface UpsertSettingsFormProps {
}

export const UpsertSettingsForm: FC<UpsertSettingsFormProps> = observer(() => {
  const [isError, setIsError] = useState(false);

  const {
    control,
    register,
    formState: {errors, defaultValues},
    setValue,
    getValues,
    handleSubmit,
  } = useForm({
    values: settingsStore.settings ? settingsStore.settings : undefined,
  });

  const initial = useCallback(async () => {
    Promise.all([
      upsertSettingsService.initial(),
      settingsService.getCurrentSettings(),
      upsertSettingsService.getSkeleton(settingsStore.settings || {}),
    ])
      .then(([, , value]) => {
        upsertSettingsService.parameters = value.data.parameterList;
        setIsError(false);
      })
      .catch(() => {
        setIsError(true);
      });
  }, []);

  const onValid: SubmitHandler<Settings> = (data) => {
    upsertSettingsService.updateSettings(data).then(() => {
      notify.success('Настройки изменены');
    });
  };

  const onInvalid: SubmitErrorHandler<Settings> = () => {
    notify.error('Не удалось отправить форму, проверьте правильность заполнения');
  };

  useEffect(() => {
    void initial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return <ErrorWrapper title='Не удалось открыть настройки' repeat={initial}/>;
  }

  return (
    <Box component='form' height='inherit' onSubmit={handleSubmit(onValid, onInvalid)}>
      <Stack height='inherit'>
        <Box display='flex' position={'relative'} flexDirection='column' height='inherit'
             justifyContent='space-between'>
          <Stack className={styles.root}>
            <Grid container gap={3}>
              <Grid xs={12}>
                <Box maxWidth={600}>
                  <FieldSelect
                    label='Выбранная конфигурация переменных'
                    control={control}
                    options={upsertSettingsService.propertiesNameOptions}
                    error={errors.propertiesName}
                    name='propertiesName'
                    defaultValue={defaultValues?.propertiesName}
                  />
                </Box>
              </Grid>
              <Grid xs={12}>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon/>}>Общие настройки</AccordionSummary>
                  <AccordionDetails>
                    <Stack gap={3}>
                      <Fields
                        namePrefix='paramsObject'
                        parameters={upsertSettingsService.parameters}
                        register={register}
                        control={control}
                        isNamePrefixProps={true}
                        errors={errors}
                        setValue={setValue}
                        getValues={getValues}
                        updateSkeleton={upsertSettingsService.getSkeleton}
                      />
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Grid>
              <Grid xs={12}>
                <EmailServerSettings
                  errors={errors}
                  register={register}
                  control={control}
                  defaultValues={defaultValues}
                />
              </Grid>
              <Grid xs={12}>
                <TelegramSettings
                  errors={errors}
                  register={register}
                  control={control}
                  defaultValues={defaultValues}
                />
              </Grid>
            </Grid>
          </Stack>
          <Stack className={styles.saveButtonPanel}>
            <Box className={styles.saveButton} width={280} mt={3} alignSelf='flex-end'>
              <Button loading={upsertSettingsService.isLoadingUpdateSettings} type='submit'>
                Сохранить
              </Button>
            </Box>
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
});

UpsertSettingsForm.displayName = 'UpsertSettingsForm';

import React, { FC, HTMLInputTypeAttribute, memo, useState } from 'react';

import { Controller } from 'react-hook-form';
import { get } from 'lodash';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import Tooltip from '@/shared/ui/Tooltip';

import styles from '@/shared/ui/Fields/components/FieldInput/FieldInput.module.scss';

export interface AutocompleteProps {
  label?: string;
  name: string;
  required?: boolean;
  tooltip?: string;
  type?: HTMLInputTypeAttribute;
  defaultValue?: unknown;
}

export const FieldListString: FC<FieldProps> = memo(
  ({ name, defaultValue, control, label, error, description, options, props }) => {
    const draggable = get(props, 'draggable', false);
    const [inputValueState, setInputValueState] = useState<string>('');
    /** Сеттер значения исходя из статуса инпута */
    const deleteTagsHandler = (event: React.SyntheticEvent, value: string, reason: string) => {
      const tag = reason === 'reset' ? '' : value;
      setInputValueState(tag);
    };

    return (
      <Controller
        name={name}
        defaultValue={defaultValue || []}
        control={control}
        render={({ field }) => {
          const handleBlur = (inputValue: any) => {
            if (!inputValue || field.value?.indexOf(inputValue) !== -1) {
              return;
            }
            field.onChange([...field.value, inputValue]);
            setInputValueState('');
          };

          return (
            <div className='grid gap-2' {...amendDraggable(true)}>
              <Tooltip title={description || ''}>
                <Autocomplete
                  multiple
                  freeSolo
                  autoComplete
                  filterSelectedOptions
                  label={label}
                  options={options || []}
                  inputValue={inputValueState}
                  value={field.value || []}
                  onBlur={() => handleBlur(inputValueState)}
                  onInputChange={deleteTagsHandler}
                  onChange={(event, value) => {
                    handleBlur(inputValueState);
                    field.onChange(value);
                  }}
                />
              </Tooltip>
              {error?.message && <p className={styles.helpText}>{error?.message}</p>}
            </div>
          );
        }}
      />
    );
  }
);

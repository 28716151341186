import { makeAutoObservable } from 'mobx';
import { Normalized } from '@/shared/lib/normalize';
import { singleton } from 'tsyringe';

import { Role } from './types';

@singleton()
export class RoleStore {
  roles: Normalized<Role> | null;

  constructor() {
    this.roles = null;

    makeAutoObservable(this);
  }

  get roleOptions() {
    if (this.roles) {
      return this.roles.ids.map((id) => this.roles!.entities[id].name);
    }

    return [];
  }
}

import { ComponentProps, ReactElement, Ref, useCallback } from 'react';

import { IComponents, IData, ITableDescriptor } from './types';
import { TableController } from './TableController';
import { TableView } from './TableView';

interface IProps<T extends IData = IData> {
  isLoading?: boolean;

  components?: Partial<IComponents>;

  data: T[];

  setItemRef?: Ref<any>;

  descriptor: ITableDescriptor<T>;

  TableLayout?: typeof TableView;
}

export const Table = <T extends IData = IData>({
  isLoading,
  data,
  components,
  setItemRef,
  descriptor,
  TableLayout = TableView,
}: IProps<T>): ReactElement<any, any> | null => {
  const renderTableContent: ComponentProps<typeof TableController<T>>['children'] = useCallback(
    ({ components, descriptor }) => {
      return (
        <TableLayout<T>
          setItemRef={setItemRef}
          isLoading={isLoading}
          data={data}
          components={components}
          descriptor={descriptor}
        />
      );
    },
    [TableLayout, data, isLoading, setItemRef]
  );

  return (
    <TableController<T> descriptor={descriptor} components={components}>
      {renderTableContent}
    </TableController>
  );
};

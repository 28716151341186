import { FC, useEffect } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { Tree } from '@/shared/ui';
import Typography from '@mui/material/Typography';

import { Parameter } from '../../Connect/types';
import { Connector1cStore } from '../model/Connector1cStore';
import { Connector1cService } from '../api/Connector1cService';

import 'rc-tree/assets/index.css';

const connector1cStore = container.resolve(Connector1cStore);
const connector1cService = container.resolve(Connector1cService);

export interface Connector1cTreeProps {
  parameters: Parameter[];
  defaultValue: any;
  onChange: (value: any) => void;
}

export const Connector1cTree: FC<Connector1cTreeProps> = observer(
  ({ parameters, defaultValue = {}, onChange }) => {
    useEffect(() => {
      if (connector1cStore.treeData.length === 0) {
        connector1cService.init(parameters);
      }
    }, [parameters]);

    if (connector1cStore.treeData.length === 0) {
      return <Typography>Загрузка справочников</Typography>;
    }

    return (
      <Tree
        checkable
        selectable={false}
        treeData={connector1cStore.treeData}
        defaultCheckedKeys={connector1cService.getCheckedKeys(defaultValue)}
        onCheck={(checked) => {
          onChange(connector1cService.getFormValue(checked as string[]));
        }}
      />
    );
  }
);

Connector1cTree.displayName = 'Connector1cTree';

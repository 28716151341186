import { combineReducers } from 'redux';

import notifications from './notifications/reducer';
import errors from './errors/reducer';
import location from './location/reducer';
import cache from './cache/reducer';
import signal from './signal/reducer';

export default combineReducers({
  errors,
  notifications,
  location,
  cache,
  signal,
});

import { Segment } from '../enums';
import { MinutesValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class MinutesContainer extends BaseContainer {
    getSegment() {
        return Segment.minutes;
    }
    cloneWith(d) {
        return new MinutesContainer(d);
    }
    createValues(values) {
        return new MinutesValues(values);
    }
}

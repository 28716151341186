import { FC, memo } from 'react';

import { Resizable } from '@/shared/ui';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Box } from '@mui/system';

import { MessageTracingWorkspace } from '../widgets';

export interface MessageTracingPageProps {}

export const MessageTracingPage: FC<MessageTracingPageProps> = memo(() => {
  const { has } = useSearchParamsTemplate();

  return has(SearchParams.ShowMessageTracing) ? (
    <Box position='absolute' right={0} height='100%'>
      <Resizable maxWidth={window.innerWidth - 260} initialWidth={833}>
        <MessageTracingWorkspace />
      </Resizable>
    </Box>
  ) : null;
});

MessageTracingPage.displayName = 'MessageTracingPage';

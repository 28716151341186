import { FC, memo } from 'react';

import {
  Checkbox as MaterialCheckbox,
  CheckboxProps as MaterialCheckboxProps,
} from '@mui/material';
import { Stack } from '@mui/system';
import cn from 'classnames';

import styles from './Checkbox.module.scss';

export interface CheckboxProps extends MaterialCheckboxProps {
  label?: string;
}

export const Checkbox: FC<CheckboxProps> = memo(({ id, label, disabled, ...props }) => {
  return (
    <Stack className={cn(styles.root, { [styles.disabled]: disabled })} direction='row' spacing={1}>
      <MaterialCheckbox
        {...props}
        id={id}
        className={styles.checkbox}
        disableRipple
        disabled={disabled}
        icon={<span className={cn(styles.icon, { [styles.disabled]: disabled })} />}
        checkedIcon={
          <span className={cn(styles.checkedIcon, { [styles.disabled]: disabled })}>
            <svg
              width='8'
              height='7'
              viewBox='0 0 8 7'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M2.8 4L1.2 2.4L0 3.6L2.8 6.4L8 1.2L6.8 0L2.8 4Z' fill='white' />
            </svg>
          </span>
        }
      />
      {label && <label htmlFor={id}>{label}</label>}
    </Stack>
  );
});

import { createValue } from '../../utils';
import { getSegmentValues } from './get-segment-value';
export const setInValue = (view, mode, index, value, segment) => {
    const segmentContainer = view.get(segment);
    const values = getSegmentValues(view, segment, mode);
    values[index] = value;
    return segmentContainer.setValues({
        [mode]: createValue(mode, [...values])
    });
};

import { FC, useCallback } from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import { UpsertExternalAppService } from '../api/UpsertExternalAppService';

import styles from './styles.module.scss';

const upsertExternalAppService = container.resolve(UpsertExternalAppService);

export interface CreateExternalAppCardProps {}

export const CreateExternalAppCard: FC<CreateExternalAppCardProps> = observer(() => {
  const handleClick = useCallback(() => {
    upsertExternalAppService.open = true;
  }, []);

  return (
    <Card className={styles.root} variant='outlined' onClick={handleClick}>
      <CardContent sx={{ height: '100%', minWidth: 300, minHeight: 240 }}>
        <Stack direction='row' gap={1.5} alignItems='center' justifyContent='center' height='100%'>
          <AddBoxOutlinedIcon />
          Добавить сервис
        </Stack>
      </CardContent>
    </Card>
  );
});

CreateExternalAppCard.displayName = 'CreateExternalAppCard';

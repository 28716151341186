import { FC, memo, useMemo } from 'react';

import { useGetRoles } from '@/hooks/role/useGetRoles';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { FieldAutocomplete } from '@/shared/ui/Fields/components/FieldAutocomplete/FieldAutocomplete';

/**
 * Компонент для генерации поля с типом RoleSelect
 */
export const RoleSelectContainer: FC<FieldProps> = memo((props) => {
  const { data: roles } = useGetRoles();

  const options = useMemo<string[]>(() => {
    if (roles) {
      return roles.map((role) => {
        return role.name;
      });
    }

    return [];
  }, [roles]);

  return <FieldAutocomplete {...props} options={options} multiple />;
});

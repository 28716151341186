import { useEffect } from 'react';

import useRedux from './useRedux';

const useLocalStorageRedux = (key, defaultValue) => {
  const getValue = () => {
    let currentValue;
    currentValue = JSON.parse(localStorage.getItem(key));
    if (defaultValue && (currentValue === undefined || currentValue === null)) {
      onChange(defaultValue);
      return defaultValue;
    }
    return currentValue;
  };

  const [value, setValue] = useRedux(key);

  useEffect(() => {
    if (!value) {
      setValue(getValue());
    }
  }, []);

  const onChange = (value) => {
    setValue(value);
    localStorage.setItem(key, JSON.stringify(value));
  };

  return [value, onChange, getValue];
};

export default useLocalStorageRedux;

import { FC, MouseEventHandler, useCallback } from 'react';

import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import Tooltip from '@/shared/ui/Tooltip';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';

import { DeleteVersionService } from '../api/DeleteVersionService';

export interface DeleteVersionButtonProps {}

const deleteVersionService = container.resolve(DeleteVersionService);

export const DeleteVersionButton: FC<DeleteVersionButtonProps> = observer(() => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    deleteVersionService.showDialog = true;
  }, []);

  return (
    <Tooltip title='Удалить версию' placement='top'>
      <span>
        <Button
          loading={deleteVersionService.isLoading}
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeleteVersionButton.displayName = 'SetVersion';

import { FC, SVGProps } from 'react';

export const DownloadIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M7.76642 1.65234V11.919'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.4329 7.25195L7.76628 11.9186L3.09961 7.25195'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M15 15.6523H1'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DeleteKeyValueService } from '../api/DeleteKeyValueService';

const deleteKeyValueService = container.resolve(DeleteKeyValueService);

export interface DeleteKeyValueButtonProps {
  externalAppId: string;
}

export const DeleteKeyValueButton: FC<DeleteKeyValueButtonProps> = observer(
  ({ externalAppId }) => {
    const handleClick = useCallback(async () => {
      deleteKeyValueService.open = true;
      deleteKeyValueService.selectedId = externalAppId;
    }, [externalAppId]);

    return (
      <Tooltip title='Удалить запись из базы данных' placement='top'>
        <span>
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleClick}
          >
            <TrashIcon />
          </Button>
        </span>
      </Tooltip>
    );
  }
);

DeleteKeyValueButton.displayName = 'DeleteKeyValueButton';

import { FC, useCallback } from 'react';

import Button, { ButtonProps } from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';

import {DisplayMessageService} from "@/entities/Connect/services/DisplayMessageService";

const displayMessageService = container.resolve(DisplayMessageService);

export interface ResendMessageErrorButtonProps extends ButtonProps {
  messageErrorId: string;
}

export const ResendMessageErrorButton: FC<ResendMessageErrorButtonProps> = observer(
  ({ messageErrorId }) => {
    const handleClick = useCallback(() => {
      void displayMessageService.resendMessage(messageErrorId);
    }, [messageErrorId]);

    return (
      <Button
        loading={displayMessageService.isResendLoading}
        variant={ButtonVariants.Primary}
        onClick={handleClick}
      >
        Переотправить сообщение
      </Button>
    );
  }
);

ResendMessageErrorButton.displayName = 'ResendMessageErrorButton';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { Exchange, ExecutorHistory } from '../types';
import { ElementEditor, Relation } from '../../Flow/types';
import {Tabs} from "@/widgets/MessageTracingWorkspace/lib/types";

@singleton()
export class DisplayMessageStore {
  executorHistory: ExecutorHistory;
  exchangeList: Normalized<Exchange> | null;
  relationSet: Relation[];
  canvas: Normalized<ElementEditor> | null;
  messages: Normalized<ExecutorHistory> | null;
  messageNumber: number;
  messageTotal: number;
  isLoadingMessages: boolean;
  selectedExecutorHistoryId: string | null;
  selectedExecutorHistory: ExecutorHistory | null;
  isResendLoading: boolean;
  activeTab: string;
  maxWidth?: number | null;
  maxHeight?: number | null;
  updateData?: number;

  constructor() {
    this.executorHistory = null;
    this.exchangeList = null;
    this.canvas = null;
    this.messages = null;
    this.relationSet = [];
    this.messageNumber = 0;
    this.messageTotal = 0;
    this.isLoadingMessages = false;
    this.selectedExecutorHistoryId = null;
    this.selectedExecutorHistory = null;
    this.isResendLoading = false;
    this.activeTab = Tabs.tracing
    this.updateData = 0

    makeAutoObservable(this);
  }
}

import { inject, injectable } from 'tsyringe';
import { makeAutoObservable, runInAction } from 'mobx';
import { notify } from '@/shared/ui/Toast/notify';
import { RegistrableValues } from '@/shared/lib/types';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';
import { CustomEvents } from '@/shared/api/EventEmitter/types';

import { FlowCanvasStore } from '../stores/FlowCanvasStore';
import { FlowService } from './FlowService';
import { UnsavedFlowChangesStore } from '../../UnsavedChanges';
import { FlowVersionStore, Version } from '../../FlowVersion';
import { FlowStore } from '../stores/FlowStore';
import { BlockStore } from '../../Block/stores/BlockStore';
import { ConnectStore } from '../../Connect/stores/ConnectStore';
import { Flow } from '../types';

@injectable()
export class FlowUpdaterService {
  constructor(
    @inject(RegistrableValues.FlowId) private flowId: string,
    private flowStore: FlowStore,
    private blockStore: BlockStore,
    private connectStore: ConnectStore,
    private apiService: ApiService,
    private flowCanvasStore: FlowCanvasStore,
    private flowService: FlowService,
    private unsavedFlowChangesStore: UnsavedFlowChangesStore,
    private flowVersionStore: FlowVersionStore
  ) {
    makeAutoObservable(this);
  }

  get diffFlow(): any {
    return this.flowStore.diffFlow;
  }

  get updatedFlow(): Flow {
    const flow = this.flowStore.flows.entities[this.flowId];
    const blocks = this.blockStore.blocks?.[this.flowId];
    const canvas = this.flowCanvasStore.elements?.[this.flowId];
    const updatedBlocks = blocks!.ids.map((blockId) => {
      const inputList = this.connectStore.inputList![blockId].ids.map(
        (id) => this.connectStore.inputList![blockId].entities[id]
      );

      const processorList = this.connectStore.processorList![blockId].ids.map(
        (id) => this.connectStore.processorList![blockId].entities[id]
      );

      const outputList = this.connectStore.outputList![blockId].ids.map(
        (id) => this.connectStore.outputList![blockId].entities[id]
      );

      return { ...blocks!.entities[blockId], inputList, processorList, outputList };
    });
    const updatedCanvas = Object.values(canvas?.entities || {});
    return {
      ...flow,
      blockList: updatedBlocks || [],
      canvas: { elements: updatedCanvas, width: null, height: null },
    };
  }

  async saveFlow(): Promise<void> {
    this.unsavedFlowChangesStore.isLoadingSave = true;

    const data = this.updatedFlow;

    data.blockList.forEach((block) => {
      if (block.inputList) {
        const newIsManyToOne = block.isManyToOne;
        block.inputList.forEach((input) => {
          input.isManyToOne = newIsManyToOne;
        });
      }
    });

    try {
      await this.apiService.instance.put('/editor/flow/save', data);
      await this.flowService.getFlow(this.flowId, false);

      this.unsavedFlowChangesStore.areThereChanges = false;
    } catch (error) {
      notify.error(error?.response?.data?.message || 'Не удалось сохранить поток');
      throw error;
    } finally {
      this.unsavedFlowChangesStore.isLoadingSave = false;
    }
  }

  async enrichRelationFlow(flow: Flow): Promise<void> {
    try {
      const response = await this.apiService.instance.post<Flow>(
        '/editor/flow/enrichRelation',
        flow
      );
      this.flowService.setFlow(response.data, true);
    } catch (error) {
      throw error;
    }
  }

  async saveFlowVersion(versionId: string) {
    this.unsavedFlowChangesStore.isLoadingSave = true;

    try {
      const version = this.flowVersionStore.versions?.entities[versionId];
      const response = await this.apiService.instance.put<Version>('/editor/version/save', {
        ...version,
        versionFlow: { ...version?.versionFlow, ...this.updatedFlow },
      });

      this.flowVersionStore.versions!.entities[versionId].versionFlow = response.data.versionFlow;
      eventEmitter.emit(CustomEvents.InitializeFlow, response.data.versionFlow);

      this.unsavedFlowChangesStore.areThereChanges = false;
    } catch (error) {
      notify.error('Не удалось сохранить версию потока');
      throw error;
    } finally {
      this.unsavedFlowChangesStore.isLoadingSave = false;
    }
  }

  async getDiffFlow(flowId: string): Promise<void> {
    try {
      const flow = this.updatedFlow;

      flow.blockList.forEach((block) => {
        if (block.inputList) {
          const newIsManyToOne = block.isManyToOne;
          block.inputList.forEach((input) => {
            input.isManyToOne = newIsManyToOne;
          });
        }
      });

      const response = await this.apiService.instance.post('/editor/flow/diff', {
        flowIdFirst: flowId,
        flowSecond: { ...flow },
      });

      runInAction(() => {
        this.flowStore.diffFlow = response.data;
      });
    } catch {}
  }
}

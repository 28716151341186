import { FC, memo } from 'react';

import MuiMenu, { MenuProps as MuiMenuProps } from '@mui/material/Menu';
import cn from 'classnames';

import styles from './styles.module.scss';

export interface MenuProps extends MuiMenuProps {}

export const Menu: FC<MenuProps> = memo(({ className, ...props }) => {
  return (
    <MuiMenu className={cn(styles.root, className)} classes={{ paper: styles.paper }} {...props} />
  );
});

Menu.displayName = 'Menu';

import { FC, useCallback, useState } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { ActionPanel, ListWithInfiniteScroll } from '@/shared/ui';
import {
  NotificationCard,
  NotificationService,
  NotificationStore,
} from '@/entities/Notification';
import { container } from 'tsyringe';
import { Observer, observer } from 'mobx-react-lite';
import { Link, To } from 'react-router-dom';
import { SearchParams } from '@/hooks/useTemplateSearchParams';
import Paper from '@mui/material/Paper';
import {
  DeleteNotificationButton,
  DeleteNotificationButtonVariants,
} from '@/features/DeleteNotification';
import Box from '@mui/material/Box';

const notificationStore = container.resolve(NotificationStore);
const notificationService = container.resolve(NotificationService);

export interface NotificationSidebarProps {
  selectedNotificationId: string | null;

  onAdd(): void;
}

export const NotificationSidebar: FC<NotificationSidebarProps> = observer(
  ({ selectedNotificationId, onAdd }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getLink = useCallback((id: string): To => {
      return { search: `${SearchParams.NotificationId}=${id}` };
    }, []);

    const handleNextElementFetch = useCallback(async (page: number) => {
      try {
        setIsLoading(true);
        await notificationService.getNotifications({ pagination: { page, size: 10 } });
      } catch {
      } finally {
        setIsLoading(false);
      }
    }, []);

    return (
      <Paper elevation={0}>
        <Sidebar
          title='Уведомления'
          textAddButton='Добавить'
          onAdd={onAdd}
          addonAfter={
            <Box sx={{ overflowY: 'auto' }} height='calc(100vh - 285px)' width='100%'>
              <ListWithInfiniteScroll
                loading={isLoading}
                animated
                elements={notificationStore.notifications?.ids || []}
                activeElementKey={selectedNotificationId || ''}
                stopAnimatedForKey={selectedNotificationId || ''}
                fetchNextElement={handleNextElementFetch}
                lastPage={notificationStore.totalPages ?? 0}
                renderElement={(id) => (
                  <Observer key={id}>
                    {() => {
                      const notification = notificationStore.notifications?.entities[id];

                      if (notification) {
                        return (
                          <Link to={getLink(id)}>
                            <NotificationCard
                              active={id === selectedNotificationId}
                              id={id}
                              name={notification.name}
                              description={notification.description}
                              actions={
                                <ActionPanel withoutBorder small>
                                  <DeleteNotificationButton
                                    variant={DeleteNotificationButtonVariants.Circle}
                                  />
                                </ActionPanel>
                              }
                            />
                          </Link>
                        );
                      }
                      return <p />;
                    }}
                  </Observer>
                )}
              />
            </Box>
          }
        />
      </Paper>
    );
  }
);

NotificationSidebar.displayName = 'NotificationSidebar';

import { FC, memo } from 'react';

import { theme, TreeSelect as TS, ConfigProvider } from 'antd';
import { StandardTextFieldProps } from '@mui/material/TextField';
import { ErrorIcon } from '@/shared/ui';
import Stack from '@mui/system/Stack';
import { Label } from '@/shared/ui/Label/Label';
import cn from 'classnames';

import styles from './TreeSelect.module.scss';

export interface TreeSelectProps extends Omit<StandardTextFieldProps, 'error' | 'label'> {
  data: any[];
  tooltip?: string;
  error?: string;
  warning?: string;
  label?: string;
  isVisible?: boolean;
}

export const TreeSelect: FC<TreeSelectProps> = memo(
  ({
    error,
    warning,
    tooltip,
    label,
    required,
    data,
    ...props
  }) => {
    return (
      <Stack className={cn(styles.root, { [styles.warning]: Boolean(warning) })} gap={2}>
        {label && (
          <div className={styles.labelBlock}>
            <Label error={Boolean(error)} required={required} tooltip={tooltip}>
              {label}
            </Label>
            {error && <ErrorIcon tooltip={error} />}
          </div>
        )}
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            token: {
              colorBgContainer: '#242731'
            },
          }}
        >
          <TS
            {...props}
            treeData={data}
          />
        </ConfigProvider>
      </Stack>
    );
  }
);

TreeSelect.displayName = 'TreeSelect';

export const data = [
  {
    target: '#flow',
    content:
      'Добавление потока. Для начала работы с системой необходимо добавить первый интеграционный поток. Нажмите на кнопку «Потоки».',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    title: 'Добавление потока',
  },
  {
    target: '#addFlow',
    content:
      'Для начала работы с системой необходимо добавить первый интеграционный поток. Нажмите на кнопку «Создать поток»',
    placement: 'auto' as const,
    spotlightClicks: true,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    title: 'Добавление потока',
  },
  {
    target: '#nameFlow',
    content:
      'Введём название потока.\n' +
      'Это позволит нам легче найти его в списке, производить фильтрацию и сортировку.',
    placement: 'auto' as const,
    event: 'input',
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление потока',
  },
  {
    target: '#descriptionFlow',
    content:
      'Введём описание потока.\n' +
      'Описание потребуется для быстрого понимания, что выполняет поток, поэтому его лучше развернуть подробнее.',
    placement: 'auto' as const,
    event: 'input',
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 25,
    title: 'Добавление потока',
  },
  {
    target: '#createFlow',
    content: 'Сохраним введённые данные.',
    placement: 'auto' as const,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление потока',
  },
  {
    target: '#flowsOnboarding',
    content:
      'Отлично, поток добавлен!\n' +
      'Мы можем увидеть его в списке потоков. Потоки можно фильтровать и сортировать',
    placement: 'right' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление потока',
  },
  {
    target: '#buttonAddBlock',
    content: 'Нажмите, чтобы добавить блок обработки в поток.',
    placement: 'auto' as const,
    styles: {
      buttonNext: {
        display: 'none',
      },
      spotlight: {
        borderRadius: '50%',
      }
    },
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление блока',
  },
  {
    target: '#block_0',
    content:
      'Ура, блок добавлен!\n' +
      'Давайте вглянем на него поближе.\n' +
      'Кстати, можно менять масштаб полотна с помощью колёсика мыши с зажатой кнопкой ctrl.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    title: 'Добавление блока',
  },
  {
    target: '#block_0',
    content:
      'Ура, блок добавлен!\n' +
      'Давайте вглянем на него поближе.\n' +
      'Кстати, можно менять масштаб полотна с помощью колёсика мыши с зажатой кнопкой ctrl.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    title: 'Добавление блока',
  },
  {
    target: '#settingsButton',
    content: 'Наведите на блок и нажмите на настройки блока на левой панели.',
    placement: 'auto' as const,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление блока',
  },
  {
    target: '#descriptionBlock',
    content:
      'Наименование и описание блока также помогут найти нужный блок и понять его назначение в дальнейшем.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Добавление блока',
  },
  {
    target: '#nameBlock',
    content: 'Лучше всего давать максимально простое название. Заполните данные поля.',
    placement: 'top' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Добавление блока',
  },
  {
    target: '#descriptionBlock',
    content: 'Описание блока должно быть достаточно подробным. Заполните данные поля.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    spotlightPadding: 25,
    title: 'Добавление блока',
  },
  {
    target: '#handlerConnector',
    content:
      'Для получения данных на «вход» блоку нужны коннекторы, в которых мы передадим необходимую информацию.',
    placement: 'auto' as const,
    disableBeacon: true,
    title: 'Добавление блока',
  },
  {
    target: '#handlerInfo',
    content:
      'Далее, полученные данные могут быть трансформированы/изменены/удалены с помощью обработчика данных.',
    placement: 'auto' as const,
    disableBeacon: true,
    title: 'Добавление блока',
  },
  {
    target: '#handlerOutputConnector',
    content: 'После чего есть возможность передать их далее, на «выход» из блока.',
    placement: 'auto' as const,
    disableBeacon: true,
    title: 'Добавление блока',
  },
  {
    target: '#handlerConnector',
    content: 'Настроим входящий коннектор, по которому данные будут приходить в поток.',
    placement: 'auto' as const,
    disableBeacon: true,
    title: 'Добавление блока',
  },
  {
    target: '#handlerConnector',
    content: 'Нажмите на коннектор входящих данных и выберите Timer',
    placement: 'top' as const,
    disableBeacon: true,
    spotlightClicks: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightPadding: 350,
    title: 'Добавление блока',
  },
  {
    target: '#handlerTimer',
    content:
      'Для простоты проверки работоспособности потока создадим коннектор типа Timer.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    spotlightPadding: 50,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#nameConnector',
    content:
      'При необходимости можно обозначить имя самого планировщика для дальнейшего поиска, либо нажмите кнопку «Далее».',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#descriptionConnector',
    content: 'Также можно оставить подробное описание, либо нажмите кнопку «Далее».',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    spotlightPadding: 25,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#infoConnector',
    content:
      'Каждый из коннекторов имеет свой набор параметров для настройки. Прокрутите немного наверх.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: 'input[value="SECONDS"]',
    // target: '#handlerSeconds', // params.initialDelay
    content: 'Настроим отправку сообщения каждые 5 секунд. Поле Seconds нужно оставить нетронутым.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: 'input[name="params.initialDelay"]',
    content: 'Введите 0',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: 'input[name="params.delay"]',
    content: 'Введите 5',
    placement: 'auto' as const,
    disableBeacon: true,
    disableScrolling: true,
    disableScrollParentFix: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    title: 'Настройка коннектора Timer',
  },
  {
    target: 'input[name="params.repeatCount"]',
    content: 'Введите -1',
    placement: 'auto' as const,
    disableBeacon: true,
    disableScrolling: true,
    disableScrollParentFix: true,
    spotlightClicks: true,
    spotlightPadding: 10,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#handlerMessage',
    content: (
      <div>
        А также настроим его на отправку сообщения со входящим доходом -{' '}
        <strong>{'{"IncomeUSD": 1300}'}</strong>
      </div>
    ),
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#saveButton',
    content: 'Обязательно сохраним введённые данные.',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    title: 'Настройка коннектора Timer',
  },
  {
    target: '#addInConnector',
    content:
      'Ура, теперь у нас есть первый Timer коннектор! Он отобразился в форме нашего блока.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Добавление блока',
  },
  {
    target: '#handlerInfo',
    content: 'Теперь добавим HTTP-обработчик. Нажмите на Обработчики и выберите соединение HTTP.',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    spotlightPadding: 270,
    title: 'Добавление блока',
  },
  {
    target: '#nameConnector',
    content:
      'Каждый коннектор имеет название и наименование, по аналогии с потоками и блоками.\n' +
      'Они помогают понять куда обращается коннектор или для чего нужен в рамках данного блока.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: '#nameConnector',
    content:
      'Мы будем запрашивать данные курсов валют с сайта ЦБ РФ. Введите подходящее наименование и нажмите «Далее»',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: '#descriptionConnector',
    content: 'Введите подходящее описание и нажмите «Далее»',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    disableScrolling: true,
    spotlightPadding: 20,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: '#infoConnector',
    content: 'Каждый из коннекторов имеет свой набор параметров для настройки.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: 'input[value="GET"]',
    content:
      'Давайте выберем тип HTTP запроса, с помощью которого мы будем обращаться.\n' +
      'Выберите тип GET.',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: 'input[name="params.url"]',
    content:
      'Здесь вводится адрес метода, к которому мы будем обращаться.\n' +
      'Адрес вводится без указания URL-а сервера, в формате "/название_без_пробелов".\n' +
      '\n' +
      'Введём "https://cbr.ru/scripts/XML_daily.asp".',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: 'span[id="isActive"]',
    content:
      'Настройки активности позволяют детально включить или выключить любой из обработчиков.\n' +
      'Оставим включённым',
    placement: 'auto' as const,
    disableBeacon: true,
    spotlightClicks: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: '#saveButton',
    content: 'Обязательно сохраним введённые данные.',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    title: 'Настройка коннектора HTTP',
  },
  {
    target: '#closeSettings',
    content: 'Теперь можно закрыть настройки, они сохраняются автоматически.',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
      spotlight: {
        borderRadius: '50%',
      }
    },
    spotlightClicks: true,
    title: 'Добавление блока',
  },
  {
    target: '#applyChanges',
    content: 'Для получения результата нужно применить изменения.',
    placement: 'auto' as const,
    disableBeacon: true,
    styles: {
      buttonNext: {
        display: 'none',
      },
    },
    spotlightClicks: true,
    title: 'Сохранение изменений',
  },
  {
    target: '#block_0',
    content: 'Поздравляем! Вы успешно прошли онбординг.',
    placement: 'auto' as const,
    disableBeacon: true,
    title: 'Конец',
  },
];

import React, { FC, useCallback, useEffect, useState } from 'react';

import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';
import { container } from 'tsyringe';
import { FlowStore } from '@/entities/Flow/stores/FlowStore';
import { FlowService } from '@/entities/Flow/services/FlowService';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';

const flowStore = container.resolve(FlowStore);
const flowService = container.resolve(FlowService);

export interface SelectFlowsProps {}

export const SelectFlows: FC<SelectFlowsProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { control } = useFormContext();

  const handleOptionLabelGet = useCallback((option: string) => {
    return _.get(flowStore.flows.entities[option], 'name', '');
  }, []);

  useEffect(() => {
    if (!flowStore.allFlowsAreReceived) {
      setIsLoading(true);

      flowService.getAllFlows().finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  return (
    <Controller
      control={control}
      name='flowIdList'
      render={({ field }) => {
        return (
          <Autocomplete
            key={field.value}
            loading={isLoading}
            multiple
            label='Потоки'
            options={flowStore.flows.ids}
            value={field.value || []}
            getOptionLabel={handleOptionLabelGet}
            filterSelectedOptions
            onChange={(event, value) => {
              field.onChange(value);
            }}
          />
        );
      }}
    />
  );
});

SelectFlows.displayName = 'SelectFlows';

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { PythonBeanStore, PythonScript } from '@/entities/PythonBean';
import { notify } from '@/shared/ui/Toast/notify';

@singleton()
export class UpsertPythonBeanService {
  isLoading: boolean;
  showForm: boolean;

  constructor(private apiService: ApiService, private pythonBeanStore: PythonBeanStore) {
    this.isLoading = false;
    this.showForm = false;

    makeAutoObservable(this);
  }

  async upsertPythonBean({
    request,
    formOfCreate,
  }: {
    request: PythonScript;
    formOfCreate: boolean;
  }) {
    this.isLoading = true;

    try {
      const response = await this.apiService.instance.put<PythonScript>(
        '/editor/pythonScript',
        request
      );

      runInAction(() => {
        if (formOfCreate) {
          if (this.pythonBeanStore.scripts) {
            this.pythonBeanStore.scripts.ids.unshift(response.data.id);
            this.pythonBeanStore.scripts.entities[response.data.id] = response.data;
          } else {
            this.pythonBeanStore.scripts = {
              ids: [response.data.id],
              entities: { [response.data.id]: response.data },
            };
          }
        } else {
          this.pythonBeanStore.scripts!.entities[response.data.id] = response.data;
        }
      });

      return response.data;
    } catch (error) {
      if (formOfCreate) {
        notify.error('Не удалось создать скрипт');
      } else {
        notify.error('Не удалось изменить скрипт');
      }
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { Node, Position } from 'reactflow';
import { InputConnectIdAndOutputConnectId } from '@/entities/Block/components/FloatingEdge/types';
import { getNodeCenter } from '@/entities/Block/components/FloatingEdge/utils/getNodeCenter';
import { getHandleCoordsByPosition } from '@/entities/Block/components/FloatingEdge/utils/getHandleCoordsByPosition';

/**
 * Возвращает координаты точек блока в которые идет линий
 * @param nodeA Блок из которого идет линия
 * @param nodeB Блок в который идет линия
 * @param data Содержит в себе ids коннекторов
 */
export const getParams = (
  nodeA: Node,
  nodeB: Node,
  data: InputConnectIdAndOutputConnectId
): [number, number, Position] => {
  const centerA = getNodeCenter(nodeA);
  const centerB = getNodeCenter(nodeB);

  const horizontalDiff = Math.abs(centerA.x - centerB.x);
  const verticalDiff = Math.abs(centerA.y - centerB.y);

  let position;

  if (horizontalDiff > verticalDiff) {
    position = centerA.x > centerB.x ? Position.Left : Position.Right;
  } else {
    position = centerA.y > centerB.y ? Position.Top : Position.Bottom;
  }

  const [x, y] = getHandleCoordsByPosition(nodeA, position, data);
  return [x, y, position];
};

import React, { FC, memo, useEffect, useMemo, useState } from 'react';

import { container } from 'tsyringe';
import { ExecutorLogService, ExecutorLogStore } from '@/entities/ExecutorLog';
import TypographyDate from '@/shared/ui/Typography/TypographyDate';
import Typography from '@/shared/ui/Typography';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { Loader } from '@/shared/ui/Loader/Loader';
import { filteredMessage } from '@/entities/ExecutorLog/utils/ExecutorLogSearch';
import { IconButton } from '@mui/material';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { Interweave } from 'interweave';

import styles from './styles.module.scss';

const executorLogService = container.resolve(ExecutorLogService);
const executorLogStore = container.resolve(ExecutorLogStore);

export interface ExecutorLogProps {
  id: string;
  blockId: string;
}

export const ExecutorLogView: FC<ExecutorLogProps> = memo(({ id }) => {
  const [rerender, setRerender] = useState<boolean>(false);
  /* Состояние для поиска по тексту сообщения */
  const [searchTextMessage, setSearchTextMessage] = useState('');

  useEffect(() => {
    executorLogService.findById(id).then(() => {
      setRerender(!rerender);
    });
  }, [id]);
  /* Функция ввода текста для поиска */
  const handlerSearch = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchTextMessage(event.target.value);
  };
  /* Функция вычисления массива фильтрации для отображения */
  const filteredExecutorLog = useMemo(() => {
    const messageLog =
      executorLogStore.executorLog?.messageLogs &&
      Object.values(executorLogStore.executorLog?.messageLogs);
    return filteredMessage(searchTextMessage, messageLog);
  }, [searchTextMessage, rerender, executorLogStore, id]);

  const propsTextField = {
    startAdornment: <SearchIcon className={styles.searchIcon} />,
    endAdornment: (
      <IconButton type='button' onClick={() => setSearchTextMessage('')}>
        <CrossIcon width={16} height={16} />
      </IconButton>
    ),
  };

  if (executorLogStore.isLoading || executorLogStore.executorLog?.id !== id) return <Loader />;

  return (
    <>
      <div className={styles.root}>
        <TextField
          className={styles.searchInput}
          value={searchTextMessage}
          onChange={(event) => handlerSearch(event)}
          InputProps={propsTextField}
        />
        {filteredExecutorLog?.map((message) => {
          return (
            <div className={styles.message} key={message.id}>
              <div className={styles.time}>
                <TypographyDate time={message.createDate} />
              </div>
              <span>:</span>
              <span className={styles.name}>
                <Typography>[{message.name}]</Typography>
              </span>
              <span>:</span>
              <div className={styles[message.level]}>
                <Interweave
                  content={message.bodyHTML || message.text}
                  className={styles.text}
                  allowList={['mark']}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
});

ExecutorLogView.displayName = 'ExecutorLogView';

import { FC, memo } from 'react';

import { Box } from '@mui/system';

import {ExecutorLogIntegra} from "@/widgets/ExecutorLogIntegra";

export interface ExecutorLogIntegraPageProps {}

export const ExecutorLogIntegraPage: FC<ExecutorLogIntegraPageProps> = memo(() => {
  return (
    <Box sx={{
        flexGrow:1,
        width:"100%"
    }}>
        <ExecutorLogIntegra />
    </Box>
  )
});

ExecutorLogIntegraPage.displayName = 'ExecutorLogIntegraPage';

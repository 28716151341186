import { CSSProperties, FC, memo, ReactNode } from 'react';

import Stack from '@mui/system/Stack';
import { motion } from 'framer-motion';
import cn from 'classnames';
import { ButtonClose } from '@/shared/ui/Button/ButtonClose/ButtonClose';

import styles from './Drawer.module.scss';

export interface DrawerProps {
  children: JSX.Element | ReactNode;
  largeTitle?: boolean;
  onClose: () => void;
  title: string;
  className?: string;
  width?: CSSProperties['width'];
  fullscreen?: boolean;
  titleAddon?: JSX.Element;
  id?: string;
}

export const Drawer: FC<DrawerProps> = memo(
  ({
    children,
    largeTitle = false,
    onClose,
    title,
    width,
    className,
    fullscreen,
    titleAddon,
    id,
  }) => {
    return (
      <motion.div
        className={cn(styles.root, className, { [styles.fullscreen]: fullscreen })}
        initial={{ x: '100%' }}
        animate={{ x: 0 }}
        transition={{ ease: 'easeInOut' }}
        exit={{ x: '100%' }}
        style={{ width }}
      >
        <Stack spacing={2.5} height='100%'>
          <Stack
            className={styles.titleWrapper}
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            gap={2}
          >
            <Stack direction='row' gap={2.5} alignItems='center' id={id}>
              <h6 className={cn(styles.title, { [styles.large]: largeTitle })}>{title}</h6>
              {titleAddon}
            </Stack>
            <ButtonClose onClose={onClose} id='closeSettings' />
          </Stack>
          <div className={styles.children}>{children}</div>
        </Stack>
      </motion.div>
    );
  }
);

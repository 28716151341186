import * as types from './actionTypes';
import { SET_ERROR_COMMON } from '../errors/actionTypes'

const initState = {
    data: [],
};

export default function reduce(state = initState, action = {}) {
    switch (action.type) {
        case types.SHOW_NOTIFICATION:
        case types.HIDE_NOTIFICATION:
            return {
                ...state,
                data: action.data,
            }
        case SET_ERROR_COMMON:
            return {
                ...state,
                data:
                    [...state.data,
                    {
                        id: new Date().getMilliseconds() + Math.random(),
                        message: action.message,
                        type: "error",
                        isOpen: true
                    }]
            }
        default:
            return state;
    }
}

const getNotificationData = (state) => {
    return state.notifications.data;
}

export {
    getNotificationData
}
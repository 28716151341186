import { FC, SVGProps } from 'react';

export const FullscreenIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_2104_166352)'>
        <path
          d='M8.50032 1.00004C8.50032 0.471873 8.0907 0.039375 7.57197 0.00262213L7.56149 0H7.49992H0.500032C-0.0520966 0 -0.5 0.44766 -0.5 0.99995V7.49911C-0.5 8.05135 -0.0521487 8.49906 0.500032 8.49906C1.0522 8.49906 1.50006 8.05139 1.50006 7.49911V1.99999H7.50029C8.05241 1.99999 8.50032 1.55233 8.50032 1.00004Z'
          fill='currentColor'
          stroke='currentColor'
        />
        <path
          d='M22.4993 1.99999V7.49911C22.4993 8.05135 22.9471 8.49906 23.4993 8.49906C24.0515 8.49906 24.4993 8.05139 24.4993 7.49911V0.99995C24.4993 0.447714 24.0515 0 23.4993 0H23.4377L23.4374 9.14633e-05H16.4991C15.9469 9.14633e-05 15.499 0.447752 15.499 1.00004C15.499 1.55232 15.9469 1.99999 16.4991 1.99999H22.4993Z'
          fill='currentColor'
          stroke='currentColor'
        />
        <path
          d='M8.49995 23.9991C8.49995 23.447 8.05229 22.9994 7.5003 22.9992L7.50029 22.9991H1.50006V17.4999C1.50006 16.9477 1.05221 16.5 0.500031 16.5C-0.0521345 16.5 -0.5 16.9477 -0.5 17.4999V23.9991C-0.5 24.5514 -0.0521489 24.9991 0.500031 24.9991H7.49992C8.05205 24.9991 8.49995 24.5514 8.49995 23.9991Z'
          fill='currentColor'
          stroke='currentColor'
        />
        <path
          d='M23.4989 16.5001C22.947 16.5003 22.4993 16.9479 22.4993 17.5V22.9992H16.4991C15.9469 22.9992 15.499 23.4468 15.499 23.9991C15.499 24.5514 15.9469 24.9991 16.4991 24.9991H23.4989C24.0511 24.9991 24.499 24.5514 24.499 23.9991V17.5C24.499 16.9477 24.0511 16.5 23.4989 16.5L23.4989 16.5001Z'
          fill='currentColor'
          stroke='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_2104_166352'>
          <rect width='24' height='24' fill='white' transform='translate(0 0.5)' />
        </clipPath>
      </defs>
    </svg>
  );
};

import { FC, useEffect } from 'react';

import { ActionPanel, ListWithInfiniteScroll } from '@/shared/ui';
import { observer, Observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { EditProperty } from '@/features';
import { DeletePropertyButton } from '@/features/DeleteProperty';
import { PropertiesListService, PropertyCard } from '@/entities/Properties';
import { useParams } from 'react-router-dom';

export interface PropertiesListProps {}

const propertiesListService = container.resolve(PropertiesListService);

export const PropertiesList: FC<PropertiesListProps> = observer(() => {
  const { propertyId } = useParams();

  useEffect(() => {
    void propertiesListService.getProperties();
  }, []);

  return (
    <ListWithInfiniteScroll
      animated
      elements={propertiesListService.properties?.ids || []}
      lastPage={0}
      loading={propertiesListService.isLoadingProperties}
      fetchNextElement={() => undefined}
      activeElementKey={propertyId}
      stopAnimatedForKey={propertyId}
      renderElement={(id, index, rest) => (
        <Observer key={id}>
          {() => {
            return (
              <PropertyCard
                propertyId={id}
                active={Boolean(rest?.active)}
                actions={
                  <ActionPanel small withoutBorder>
                    <EditProperty />
                    <DeletePropertyButton id={id} />
                  </ActionPanel>
                }
              />
            );
          }}
        </Observer>
      )}
    />
  );
});

PropertiesList.displayName = 'PropertiesList';

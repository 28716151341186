import { injectable } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { ConnectTypes } from '@/entities/Connect/components/ConnectList/types';

import { ApiService } from '../../../shared/api/Api/services/ApiService';
import { Parameter, ParameterDictionary } from '../types';
import { AllConnectStore } from '../stores/AllConnectStore';

@injectable()
export class AllConnectService {
  constructor(private apiService: ApiService, private allConnectStore: AllConnectStore) {
    makeAutoObservable(this);
  }

  get inputOptions(): Parameter[] {
    return Object.values(this.allConnectStore.input);
  }

  get processorOptions(): Parameter[] {
    return Object.values(this.allConnectStore.processor);
  }

  get outputOptions(): Parameter[] {
    return Object.values(this.allConnectStore.output);
  }

  get dictionaryForBothConnectOptions(): Parameter[] {
    return Object.values(this.allConnectStore.dictionaryForBothConnects);
  }

  parameterInputByName(name: string): Parameter {
    return this.allConnectStore.input[name];
  }

  parameterOutputByName(name: string): Parameter {
    return this.allConnectStore.output[name];
  }

  parameterProcessorByName(name: string): Parameter {
    return this.allConnectStore.processor[name];
  }

  parameterByName(name: string, type: string): Parameter | undefined {
    switch (type) {
      case ConnectTypes.Input:
        return this.allConnectStore.input[name];
      case ConnectTypes.Output:
        return this.allConnectStore.output[name];
      case ConnectTypes.Processor:
        return this.allConnectStore.processor[name];
    }
  }

  async getInput(): Promise<void> {
    if (_.isEmpty(this.allConnectStore.input)) {
      const response = await this.apiService.instance.get<ParameterDictionary>(
        '/editor/connect/type/input/all'
      );

      this.allConnectStore.input = response.data;
    }
  }

  async getProcessor(): Promise<void> {
    if (_.isEmpty(this.allConnectStore.processor)) {
      const response = await this.apiService.instance.get<ParameterDictionary>(
        '/editor/processor/type/all'
      );
      this.allConnectStore.processor = response.data;
    }
  }

  async getOutput(): Promise<void> {
    if (_.isEmpty(this.allConnectStore.output)) {
      const response = await this.apiService.instance.get<ParameterDictionary>(
        '/editor/connect/type/output/all'
      );

      this.allConnectStore.output = response.data;
    }
  }

  async getDictionaryForBothConnects(): Promise<void> {
    if (_.isEmpty(this.allConnectStore.dictionaryForBothConnects)) {
      const response = await this.apiService.instance.get<ParameterDictionary>(
        '/editor/connect/type/both/all'
      );

      this.allConnectStore.dictionaryForBothConnects = response.data;
    }
  }

  async run(): Promise<void> {
    await Promise.all([
      this.getInput(),
      this.getProcessor(),
      this.getOutput(),
      this.getDictionaryForBothConnects(),
    ]);
  }
}

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import Box from '@mui/material/Box';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { notify } from '@/shared/ui/Toast/notify';

import { DeleteLicenseService } from '../api/DeleteLicenseService';

const deleteLicenseService = container.resolve(DeleteLicenseService);

export interface DeleteLicenseDialogProps {}

export const DeleteLicenseDialog: FC<DeleteLicenseDialogProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    deleteLicenseService.open = false;
    deleteLicenseService.selectedLicenseId = null;
  }, []);

  const handleOk = useCallback(async () => {
    try {
      setIsLoading(true);

      await deleteLicenseService.deleteLicense();
      handleClose();
    } catch {
      notify.error('Не удалось удалить лицензионный ключ');
    } finally {
      setIsLoading(false);
    }
  }, [handleClose]);

  return (
    <Dialog open={deleteLicenseService.open} fullWidth onClose={handleClose}>
      <DialogTitle onClose={handleClose}>Удалить лицензионный ключ</DialogTitle>
      <DialogContent>Уверены что хотите удалить лицензионный ключ?</DialogContent>
      <DialogActions>
        <Box width={280}>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
        </Box>
        <Box width={280}>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOk}>
            Удалить
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

DeleteLicenseDialog.displayName = 'DeleteLicenseDialog';

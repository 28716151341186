export enum ButtonVariants {
  Primary = 'primary',
  Secondary = 'secondary',
  Blue = 'blue',
  Outlined = 'outlined',
  Text = 'text',
  Popup = 'popup',
  Contained = 'contained',
}

export enum ButtonSizes {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

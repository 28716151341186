import React, { useContext, useEffect } from 'react';

import { RouterProvider } from 'react-router-dom';
import { ThemeContext } from '@/context/ThemeContext';
import { JoyrideProvider } from '@/entities/JoyRideOnboarding/JoyrideContext';
import { data } from '@/entities/Onboarding/utils/mock_data';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { router } from '@/app';
import { RunOnKeys } from '@/shared/ui/RunOnKeys/RunOnKeys';
import { KEYBOARD_KEYS } from '@/shared/lib/constants';
import { eventEmitter } from '@/shared/api/EventEmitter/EventEmitter';

import Preload from '../Preload';

const joyrideService = container.resolve(JoyRideService);

const Main = () => {
  const { theme } = useContext(ThemeContext);

  useEffect(() => {
    document.body.dataset.theme = theme;
  }, [theme]);

  const { Escape } = KEYBOARD_KEYS;
  const keysEvents = [
    {
      keys: [Escape],
      event: () => eventEmitter.emit('ButtonClose'),
    },
  ];

  return (
    <Preload>
      <JoyrideProvider steps={data} run={joyrideService.run}>
        <RunOnKeys keysEvents={keysEvents}>
          <RouterProvider router={router} />
        </RunOnKeys>
      </JoyrideProvider>
    </Preload>
  );
};

export default Main;

import { FC, useEffect, useState } from 'react';

import { CompanyForm, CompanyService } from '@/entities/Company';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { UpsertLicenseDialog } from '@/features/UpsertLicense';
import { LicenseList } from '@/widgets/LicenseList';
import { DeleteLicenseDialog } from '@/features/DeleteLicense';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import { notify } from '@/shared/ui/Toast/notify';
import Box from '@mui/material/Box';
import {Loader} from "@/shared/ui/Loader/Loader";

const companyService = container.resolve(CompanyService);

export interface CompanySettingsProps {}

export const CompanySettings: FC<CompanySettingsProps> = observer(() => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    companyService
      .getCompany()
      .catch(() => {
        notify.error('Не удалось получить список лицензионных ключей');
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
      return (
          <Loader width={60} height={60}/>
      )
  }

  return (
    <Box position='relative' height='100%'>
      <CompanyForm
        addonAfter={
          <Stack gap={2.5} mt={5}>
            <Typography variant='caption'>Лицензионные ключи</Typography>
              <LicenseList />
          </Stack>
        }
      />
      <UpsertLicenseDialog />
      <DeleteLicenseDialog />
    </Box>
  );
});

CompanySettings.displayName = 'CompanySettings';

import React, { FC } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Box from '@mui/system/Box';
import { Stack } from '@mui/system';
import { Layout } from '@/shared/ui/Layout/Layout';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';

import { JoyRideService } from '../JoyRideOnboarding/api/JoyRideService';

const joyrideService = container.resolve(JoyRideService);

const Onboarding: FC = observer(() => {
  const handleRun = () => {
    joyrideService.handleOpen(true);
  };

  return (
    <Layout>
      <Stack alignItems='center' marginTop={2.5}>
        <Box width={300}>
          <Button variant={ButtonVariants.Popup} type='button' onClick={handleRun}>
            НАЧАТЬ ОБУЧЕНИЕ
          </Button>
        </Box>
      </Stack>
    </Layout>
  );
});

export default Onboarding;

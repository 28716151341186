import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { Company } from './types';

@singleton()
export class CompanyStore {
  company: Company | null;

  constructor() {
    this.company = null;

    makeAutoObservable(this);
  }
}

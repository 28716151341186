import { FC, useCallback } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { ActionPanel, ListWithInfiniteScroll } from '@/shared/ui';
import { Observer, observer } from 'mobx-react-lite';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  SettingsUserAuthCard,
  SettingsUserAuthStore,
  SettingsUserAuthTypes,
} from '@/entities/SettingsUserAuth';
import { container } from 'tsyringe';
import { CreateSettingsUserAuthButton } from '@/features/CreateSettingsUserAuth';
import { DeleteSettingsUserAuthButton } from '@/features/DeleteSettingsUserAuth';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';

const settingsUserAuthStore = container.resolve(SettingsUserAuthStore);

export interface SettingsUserAuthSidebarProps {
  isLoading: boolean;
}

export const SettingsUserAuthSidebar: FC<SettingsUserAuthSidebarProps> = observer(
  ({ isLoading }) => {
    let settingsBasicIndex = 0;
    let settingsIdmIndex = 0;

    const handleCardClick = useCallback(
      (id: string, type: SettingsUserAuthTypes) => () => {
        settingsUserAuthStore.selectedId = id;
        settingsUserAuthStore.selectedType = type;
      },
      []
    );

    return (
      <Paper elevation={0}>
        <Sidebar
          title='Хранилища'
          createButton={<CreateSettingsUserAuthButton />}
          addonAfter={
            <Box sx={{ overflowY: 'auto' }} height='calc(100vh - 285px)' width='100%'>
              <ListWithInfiniteScroll
                animated
                lastPage={0}
                loading={isLoading}
                elements={settingsUserAuthStore.basicAndIdmSettingsIds}
                activeElementKey={settingsUserAuthStore.selectedId || ''}
                fetchNextElement={() => undefined}
                renderElement={(id, index, rest) => (
                  <Observer key={id}>
                    {() => {
                      const settingsBasicEntity = settingsUserAuthStore.settingsBasic?.entities[id];
                      const settingsIdmEntity = settingsUserAuthStore.settingsIdm?.entities[id];

                      if (settingsBasicEntity) {
                        settingsBasicIndex++;

                        return (
                          <SettingsUserAuthCard
                            title={`Basic №${settingsBasicIndex}`}
                            active={rest?.active}
                            actions={
                              <ActionPanel small withoutBorder>
                                <DeleteSettingsUserAuthButton
                                  type={SettingsUserAuthTypes.SettingsBasic}
                                />
                              </ActionPanel>
                            }
                            onClick={handleCardClick(id, SettingsUserAuthTypes.SettingsBasic)}
                          />
                        );
                      }

                      if (settingsIdmEntity) {
                        settingsIdmIndex++;

                        return (
                          <SettingsUserAuthCard
                            showIndicator
                            title={`Keycloak №${settingsIdmIndex}`}
                            active={rest?.active}
                            indicatorType={
                              settingsIdmEntity.rsaKey
                                ? IndicatorTypes.Success
                                : IndicatorTypes.Error
                            }
                            indicatorTooltip={
                              settingsIdmEntity.rsaKey
                                ? 'Keycloak подключен'
                                : 'Keycloak не подключен'
                            }
                            actions={
                              <ActionPanel small withoutBorder>
                                <DeleteSettingsUserAuthButton
                                  type={SettingsUserAuthTypes.SettingsIdm}
                                />
                              </ActionPanel>
                            }
                            onClick={handleCardClick(id, SettingsUserAuthTypes.SettingsIdm)}
                          />
                        );
                      }

                      return <p />;
                    }}
                  </Observer>
                )}
              />
            </Box>
          }
        />
      </Paper>
    );
  }
);

SettingsUserAuthSidebar.displayName = 'SettingsUserAuthSidebar';

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { RoleStore } from '@/entities/Role';

@singleton()
export class DeleteRoleService {
  open: boolean;
  selectedRoleId: string | null;

  constructor(private apiService: ApiService, private roleStore: RoleStore) {
    this.open = false;
    this.selectedRoleId = null;

    makeAutoObservable(this);
  }

  async deleteRole() {
    if (this.selectedRoleId) {
      await this.apiService.instance.delete(`/editor/role/${this.selectedRoleId}`);

      const index = this.roleStore.roles?.ids.indexOf(this.selectedRoleId);

      if (index || index === 0) {
        runInAction(() => {
          this.roleStore.roles?.ids.splice(index, 1);
          delete this.roleStore.roles?.entities[this.selectedRoleId!];
          this.selectedRoleId = null;
        });
      }
    }
  }
}

import { FC, memo, useMemo } from 'react';

import cn from 'classnames';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/system/Stack';
import TextField, { StandardTextFieldProps } from '@mui/material/TextField';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { ErrorIcon } from '@/shared/ui';
import { Label } from '@/shared/ui/Label/Label';
import { WarningIcon } from '@/shared/ui/WarningIcon/WarningIcon';
import { amendDraggable } from "@/shared/lib/extendDndkit";

import styles from './Input.module.scss';

export interface InputProps extends Omit<StandardTextFieldProps, 'error' | 'label'> {
  tooltip?: string;
  error?: string;
  warning?: string;
  label?: string;
  endAdornment?: JSX.Element;
  isVisible?: boolean;
  disableUnderline?: boolean;
}

export const Input: FC<InputProps> = memo(
  ({
    error,
    warning,
    tooltip,
    InputProps,
    label,
    required,
    endAdornment,
    SelectProps,
    ...props
  }) => {
    const memoizedInputProps = useMemo(() => {
      const result: StandardTextFieldProps['InputProps'] = {
        ...InputProps,
        disableUnderline: true,
        endAdornment: endAdornment && (
          <InputAdornment position='end'>{endAdornment}</InputAdornment>
        ),
      };

      if (warning) {
        result.endAdornment = (
          <InputAdornment position='end'>
            <WarningIcon tooltip={warning} />
          </InputAdornment>
        );
      }

      return result;
    }, [InputProps, endAdornment, warning]);

    const memoizedSelectProps = {
      ...SelectProps,
      MenuProps: {
        classes: { paper: styles.paper, warning: styles.warning },
        slotProps: { root: amendDraggable(true) },
      },
      IconComponent: ExpandMoreRoundedIcon,
    };
    return (
      <Stack
        className={cn(styles.root, { [styles.warning]: Boolean(warning) })}
        gap={2}
        {...amendDraggable(true)}
      >
        {label && (
          <div className={styles.labelBlock}>
            <Label
              error={Boolean(error)}
              required={required}
              tooltip={tooltip}
            >
              {label}
            </Label>
            {error && <ErrorIcon tooltip={error} />}
          </div>
        )}
        <TextField
          {...props}
          className={styles.root}
          SelectProps={memoizedSelectProps}
          error={Boolean(error)}
          hiddenLabel
          InputProps={memoizedInputProps}
        />
      </Stack>
    );
  }
);

Input.displayName = 'Input';

import { FC, MouseEvent } from 'react';

import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { SaveIcon } from '@/shared/ui/Icons/SaveIcon/SaveIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { actionsSearchParams } from '@/entities/Flow/widgets/FlowActionBar/FlowActionBar';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';


export const SaveVersionButton: FC = observer(() => {
  const { set, has, remove } = useSearchParamsTemplate();
  const active = has(SearchParams.ShowFlowVersionForm);

  const handleClick = (event: MouseEvent) => {
    event.stopPropagation();
    remove(actionsSearchParams);
    if (active) return;
    set([SearchParams.ShowFlowVersionForm]);
  };

  return (
    <Tooltip title='Сохранить версию' placement='top'>
      <span>
        <Button
          className={cn({ active })}
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <SaveIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

SaveVersionButton.displayName = 'SaveVersionButton';

import { FC } from 'react';

import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { container } from 'tsyringe';
import { JoyRideService } from '@/entities/JoyRideOnboarding/api/JoyRideService';
import { observer } from 'mobx-react-lite';
import { FlowStore } from '@/entities/Flow/stores/FlowStore';
import { RoutePaths } from '@/shared/lib/types';

import { routePathsDictionary } from './constants';
import { RoutePathsWithoutMain } from './types';

import styles from './Navigation.module.scss';

const routeKeys = Object.keys(routePathsDictionary) as RoutePathsWithoutMain[];

const joyrideService = container.resolve(JoyRideService);
const flowStore = container.resolve(FlowStore);

export const Navigation: FC = observer(() => {
  const handleNavLinkClick = () => {
    if (joyrideService.run) {
      joyrideService.updateCurrentStep(joyrideService.stepIndex + 1);
    }
  };

  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        {routeKeys.map((key) => {
          const route = routePathsDictionary[key];

          const to =
            flowStore.selectedFlowId && key === RoutePaths.Flow
              ? `/${key}/${flowStore.selectedFlowId}`
              : `/${key}`;

          return (
            <li key={key} id={key}>
              <NavLink
                onClick={handleNavLinkClick}
                to={to}
                className={({ isActive }) => cn(styles.element, [{ [styles.active]: isActive }])}
              >
                {route}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
});

import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { makeAutoObservable } from 'mobx';

import { ConsumerInformationStore } from '../model/ConsumerInformationStore';
import { ResponseConsumerInformationByBlock } from '../model/types';

@singleton()
export class ConsumerInformationService {
  constructor(
    private apiService: ApiService,
    private consumerInformationStore: ConsumerInformationStore
  ) {
    makeAutoObservable(this);
  }

  async getElementsByBlockId(blockId: string) {
    const response = await this.apiService.instance.get<ResponseConsumerInformationByBlock>(
      `/editor/consumerInformation/blockId/${blockId}`
    );

    if (this.consumerInformationStore.elements) {
      Object.assign(this.consumerInformationStore.elements, response.data.mapConsumerInformation);
    } else {
      this.consumerInformationStore.elements = response.data.mapConsumerInformation;
    }

    return response.data;
  }
}

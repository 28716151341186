import { Segment } from '../enums';
import { SecondsContainer, MinutesContainer, HoursContainer, MonthContainer, DayOfMonthContainer, DayOfWeekContainer, YearContainer } from '../segment-containers';
export class ViewData {
    constructor(d) {
        this.seconds = new SecondsContainer(d.seconds);
        this.minutes = new MinutesContainer(d.minutes);
        this.hours = new HoursContainer(d.hours);
        this.month = new MonthContainer(d.month);
        this.dayOfMonth = new DayOfMonthContainer(d.dayOfMonth);
        this.dayOfWeek = new DayOfWeekContainer(d.dayOfWeek);
        this.year = new YearContainer(d.year);
    }
    set(container) {
        const segment = container.getSegment();
        return new ViewData(Object.assign(Object.assign({}, this), { [segment]: container }));
    }
    get(segment) {
        return this[segment].clone();
    }
    getSelected() {
        const secondsValue = this[Segment.seconds].getSelected();
        const minutesValue = this[Segment.minutes].getSelected();
        const hoursValue = this[Segment.hours].getSelected();
        const monthValue = this[Segment.month].getSelected();
        const dayOfMonthValue = this[Segment.dayOfMonth].getSelected();
        const dayOfWeekValue = this[Segment.dayOfWeek].getSelected();
        const yearValue = this[Segment.year].getSelected();
        return {
            [Segment.seconds]: secondsValue || undefined,
            [Segment.minutes]: minutesValue || undefined,
            [Segment.hours]: hoursValue || undefined,
            [Segment.month]: monthValue || undefined,
            [Segment.dayOfMonth]: dayOfMonthValue || undefined,
            [Segment.dayOfWeek]: dayOfWeekValue || undefined,
            [Segment.year]: yearValue || undefined
        };
    }
}
//Segment.seconds, Segment.minutes, Segment.hours, Segment.month, Segment.dayOfMonth, Segment.dayOfWeek, Segment.year;

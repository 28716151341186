import { FC, useEffect } from 'react';

import { Observer, observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { ActionPanel, ListWithInfiniteScroll } from '@/shared/ui';
import { useParams } from 'react-router-dom';
import { useResolve } from '@/hooks/useResolve';
import { RegistrableValues } from '@/shared/lib/types';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { BlockService } from '../../../../services/BlockService';
import { DeleteTest, ExportTest, RunTest } from '../../features';
import { BlockTestingService } from '../../../../services/BlockTestingService';
import { BlockTestingCard } from '../../entities';

const blockTestingService = container.resolve(BlockTestingService);

export interface BlockTestingListProps {}

export const BlockTestingList: FC<BlockTestingListProps> = observer(() => {
  const { get } = useSearchParamsTemplate();
  const { flowId } = useParams();

  const blockService = useResolve(BlockService, {
    [RegistrableValues.FlowId]: flowId,
  });

  const isLoading = blockTestingService.isLoadingTests || blockTestingService.isLoadingRunTests;

  const blockId = get(SearchParams.BlockId);
  const testId = get(SearchParams.TestId);

  useEffect(() => {
    void blockTestingService.getTestsByBlockId(blockId, { pagination: { page: 0, size: 10 } });

    return () => {
      blockTestingService.resetTests();
    };
  }, [blockId]);

  return (
    <ListWithInfiniteScroll
      animated
      elements={blockTestingService.tests.ids}
      lastPage={blockTestingService.totalPages}
      stopAnimatedForKey={testId}
      loading={isLoading}
      fetchNextElement={(page) =>
        blockTestingService.getTestsByBlockId(blockId, { pagination: { page, size: 10 } })
      }
      renderElement={(id) => (
        <Observer key={id}>
          {() => {
            return (
              <BlockTestingCard
                testId={id}
                actions={
                  <ActionPanel small withoutBorder>
                    {blockService.blocks && (
                      <RunTest
                        test={blockTestingService.tests.entities[id]}
                        block={blockService.blocks.entities[blockId]}
                      />
                    )}
                    <ExportTest testId={id} />
                    <DeleteTest testId={id} />
                  </ActionPanel>
                }
              />
            );
          }}
        </Observer>
      )}
    />
  );
});

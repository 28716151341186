import { MarkerType } from 'reactflow';

import { DisplayMessageService } from '../../entities/Connect/services/DisplayMessageService';

export function getEdgesForMessageTracing(displayMessageService: DisplayMessageService) {
  return displayMessageService.relationSet.map(({ inputId, outputId }) => {
    return {
      id: `${inputId}${outputId}`,
      source: outputId,
      target: inputId,
      style: { strokeWidth: '2px', stroke: 'var(--edge-color)' },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        strokeWidth: 2,
        color: '#E4E4E4',
      },
    };
  });
}

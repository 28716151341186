import { Mode, ConstantValue } from '../enums';
import { BaseValue } from './base.abstract';
export class NthWeekdayOfMonthValue extends BaseValue {
    constructor(d = { values: [ConstantValue.EVERY] }) {
        super(d);
    }
    getMode() {
        return Mode.NTH_WEEKDAY_OF_MONTH;
    }
    clone(values) {
        return new NthWeekdayOfMonthValue(values ? { values } : this);
    }
}

import { MarkerType } from 'reactflow';

import { TracingMessageModel } from '../model/TracingMessageModel';

export function getEdges(store: TracingMessageModel) {
  return store.relationSet.map(({ inputId, outputId }) => {
    return {
      id: `${inputId}${outputId}`,
      source: outputId,
      target: inputId,
      style: { strokeWidth: '2px', stroke: 'var(--edge-color)' },
      markerEnd: {
        type: MarkerType.ArrowClosed,
        strokeWidth: 2,
        color: '#E4E4E4',
      },
    };
  });
}

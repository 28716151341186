import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { Normalized } from '@/shared/lib/normalize';

import { ErrorPacket } from '../types';

@singleton()
export class MessageQueueStore {
  messages: Normalized<ErrorPacket>;
  statusList: string[];
  isLoadingMessages: boolean;
  isLoadingSendMessageForce: boolean;
  isLoadingSaveMessage: boolean;
  totalPages: number;
  prevStatusList: string[];

  constructor() {
    this.messages = { ids: [], entities: {} };
    this.statusList = [];
    this.prevStatusList = [];
    this.isLoadingMessages = false;
    this.isLoadingSendMessageForce = false;
    this.isLoadingSaveMessage = false;
    this.totalPages = 0;

    makeAutoObservable(this);
  }
}

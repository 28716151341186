import { ReactElement, useMemo } from 'react';

import { gridTable } from './gridTable';
import { IComponents, IData, ITableDescriptor } from './types';
import { makeLoadableDescriptor } from './makeLoadableDescriptor';

interface IProps<T extends IData = IData> {
  descriptor: ITableDescriptor<T>;

  components?: Partial<IComponents>;

  children: (props: {
    descriptor: ITableDescriptor<T>;
    components: IComponents;
  }) => ReactElement<any, any> | null;

}

export const TableController = <T extends IData = IData>({
  children,
  components = {},
  descriptor,
}: IProps<T>): ReactElement<any, any> | null => {
  const loadingDescriptor = useMemo(() => makeLoadableDescriptor(descriptor), [descriptor]);

  const mergedComponents = useMemo(
    () => ({
      ...gridTable,
      ...components,
    }),
    [components]
  );

  return useMemo(
    () =>
      children({
        descriptor: loadingDescriptor,
        components: mergedComponents,
      }),
    [children, loadingDescriptor, mergedComponents]
  );
};

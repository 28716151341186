import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import hash from 'object-hash';

import { deleteRequest, getRequest, postRequest, putRequest } from '../service/restService';
import { updateCache } from '../store/cache/action';
import useLocalStorageRedux from './useLocalStorageRedux';
import useRedux from './useRedux';

const useFetch = (isCacheProp = false, timeLiveCacheProp = 10000) => {
  const [isCache, setIsCache] = useState(isCacheProp);
  const [timeLiveCache, setTimeLiveCache] = useState(timeLiveCacheProp);
  const [headerAuth, setHeaderAuth] = useLocalStorageRedux('headerAuth');
  const [companyId, setCompanyId] = useLocalStorageRedux('companyId');
  const [sendCheck, setSendCheck] = useRedux('send_auth_check', true);

  const href = useSelector(({ location: { path } }) => {
    if (path.endsWith('/')) return path;
    return `${path}/`;
  });
  const cache = useSelector((state) => {
    return state.cache;
  });
  const dispatch = useDispatch();

  const fetch = async ({
    url,
    method = 'get',
    auth,
    params = {},
    headers = {},
    isHeadersDefault = true,
    ...other
  }) => {
    if (!auth) {
      auth = headerAuth;
    }
    headers['company-id'] = companyId;
    //const {body} = other
    let hashBody = '';
    if (other.body) {
      hashBody = hash.MD5(other.body);
    }
    let hashParams = '';
    if (params) {
      hashParams = hash.MD5(params);
    }
    if (isCache) {
      let cacheRequest = cache.requests[`${method}_${url}_${hashParams}_${hashBody}`];
      if (cacheRequest) {
        let cacheBody = cacheRequest.body;
        let cacheTime = cacheRequest.time;
        let now = Date.now();
        if (cacheTime && now - cacheTime < timeLiveCache) {
          return cacheBody;
        }
      }
    }
    let response;
    if (method === 'get') {
      response = await getRequest(
        {
          url: `${href}${url}`,
          auth: auth,
          params,
          headers,
          isHeadersDefault,
          ...other,
        },
        () => {
          setSendCheck(new Date().getTime());
        }
      );
    }
    if (method === 'post') {
      response = await postRequest(
        {
          url: `${href}${url}`,
          auth: auth,
          params,
          headers,
          isHeadersDefault,
          ...other,
        },
        () => {
          setSendCheck(new Date().getTime());
        }
      );
    }
    if (method === 'put') {
      response = await putRequest(
        {
          url: `${href}${url}`,
          auth: auth,
          params,
          headers,
          isHeadersDefault,
          ...other,
        },
        () => {
          setSendCheck(new Date().getTime());
        }
      );
    }
    if (method === 'delete') {
      response = await deleteRequest(
        {
          url: `${href}${url}`,
          auth: auth,
          params,
          headers,
          isHeadersDefault,
          ...other,
        },
        () => {
          setSendCheck(new Date().getTime());
        }
      );
    }
    if (isCache && response) {
      dispatch(updateCache(`${method}_${url}_${hashParams}_${hashBody}`, response));
    }
    return response;
  };

  return fetch;
};

export default useFetch;

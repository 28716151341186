import { FC, useCallback, useMemo } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import Box from '@mui/system/Box';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FlowVersionList,
  FlowVersionService,
  FlowVersionStore,
} from '@/entities/FlowVersion';
import { SetVersionDialog } from '@/features/SetVersion';
import { DeleteVersionDialog } from '@/features/DeleteVersions';
import { container } from 'tsyringe';
import { CloneVersionDialog } from '@/features/CloneVersion';
import { ImportVersions } from '@/features/ImportVersions';

export interface FlowVersionsSidebarProps {}

const flowVersionService = container.resolve(FlowVersionService);
const flowVersionStore = container.resolve(FlowVersionStore);

export const FlowVersionsSidebar: FC<FlowVersionsSidebarProps> = observer(() => {
  const { versionId } = useParams();
  const navigate = useNavigate();

  const versionName = useMemo(() => {
    const name = flowVersionStore.versions?.entities[versionId || '']?.name;

    if (name) {
      return name;
    }

    return 'Название отсутствует';
  }, [versionId]);

  const handleDeleteVersionDialogSuccess = useCallback(() => {
    navigate('.');
    void flowVersionService.getFlowVersions({ pagination: { page: 0, size: 20 } });
  }, [navigate]);

  return (
    <>
      <Box bgcolor='var(--paper-color)'>
        <Sidebar
          title='Версии потоков'
          createButton={<ImportVersions />}
          width={330}
          addonAfter={
            <Box height='calc(100vh - 224px)' overflow='auto' width='100%'>
              <FlowVersionList versionId={versionId} />
            </Box>
          }
        />
      </Box>
      <SetVersionDialog versionId={versionId || ''} />
      <DeleteVersionDialog
        versionId={versionId || ''}
        versionName={versionName}
        onSuccess={handleDeleteVersionDialogSuccess}
      />
      <CloneVersionDialog flowId={versionId || ''} flowName={versionName} />
    </>
  );
});

FlowVersionsSidebar.displayName = 'FlowVersionsSidebar';

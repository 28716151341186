import { FC } from 'react';

import { ToastContainer } from 'react-toastify';

import styles from './Toast.module.scss';

export const Toast: FC = () => {
  return (
    <ToastContainer
      className={styles.root}
      toastClassName={styles.toast}
      bodyClassName={styles.body}
    />
  );
};

import React, { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';

import { DeleteExternalAppService } from '../api/DeleteExternalAppService';

const deleteExternalAppService = container.resolve(DeleteExternalAppService);

export interface DeleteExternalAppButtonProps {
  externalAppId: string;
}

export const DeleteExternalAppButton: FC<DeleteExternalAppButtonProps> = observer(
  ({ externalAppId }) => {
    const handleClick = useCallback(async () => {
      deleteExternalAppService.open = true;
      deleteExternalAppService.selectedId = externalAppId;
    }, [externalAppId]);

    return (
      <Tooltip title='Удалить внешний сервис' placement='top'>
        <span>
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleClick}
          >
            <TrashIcon />
          </Button>
        </span>
      </Tooltip>
    );
  }
);

DeleteExternalAppButton.displayName = 'DeleteExternalAppButton';

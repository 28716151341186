import { Mode, CronType } from '../enums';
import { getSeparator, containsSeparator } from './index';
export const stringifySegment = (v, cronType) => {
    const mode = v.getMode();
    if (containsSeparator(mode)) {
        const values = [...v.values];
        if (mode === Mode.INCREMENT && cronType === CronType.UNIX) {
            values[0] = '*';
        }
        return values.join(getSeparator(mode));
    }
    return v.values.join('');
};

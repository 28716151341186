import { FC, SVGProps } from 'react';

export const VersionIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_2907_11868)'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 0L0 3.6129L4.57143 5.67742L1.82222 6.919L0 7.74194L1.82222 8.56487L5.00535 10.0024L1.77349 11.5422L0 12.3871L1.79037 13.1957L8 16L14.2096 13.1957L16 12.3871L14.2265 11.5422L10.9947 10.0024L14.1778 8.56487L16 7.74194L14.1778 6.919L11.4286 5.67742L16 3.6129L8 0ZM6.39364 6.50036L8 7.22581L9.60635 6.50036L12.3556 7.74194L8 9.70897L3.64443 7.74194L6.39364 6.50036ZM9.40832 10.9082L8.48805 11.2248L8 11.3928L7.51195 11.2248L6.59168 10.9082L3.56387 12.3507L8 14.3541L12.4361 12.3507L9.40832 10.9082Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_2907_11868'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

import { makeAutoObservable, runInAction } from 'mobx';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { FlowVersionStore, Version } from '@/entities/FlowVersion';

@singleton()
export class DeleteVersionService {
  isLoading: boolean;
  showDialog: boolean;

  constructor(private apiService: ApiService, private flowVersionStore: FlowVersionStore) {
    this.isLoading = false;
    this.showDialog = false;

    makeAutoObservable(this);
  }

  async deleteVersion(versionId: string) {
    this.isLoading = true;

    try {
      await this.apiService.instance.delete<Version>(`/editor/version/${versionId}`);

      runInAction(() => {
        this.flowVersionStore.versions!.ids.splice(
          this.flowVersionStore.versions!.ids.indexOf(versionId),
          1
        );
        delete this.flowVersionStore.versions!.entities[versionId];
      });
    } catch (error) {
      notify.error(`Не удалось удалить версию`);

      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { FC, SVGProps } from 'react';

export const DocumentIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M11.6001 0.468506V3.19974H14.3313L11.6001 0.468506Z' fill='currentColor' />
      <path
        d='M10.8003 4.79993C10.5882 4.79993 10.3846 4.71555 10.2346 4.56555C10.0846 4.41555 10.0003 4.21209 10.0003 3.99983V0H4.40003C3.76372 0.000669644 3.15366 0.253797 2.70379 0.703656C2.25379 1.15366 2.00079 1.76372 2 2.40003V13.6C2.0008 14.2363 2.2538 14.8463 2.70379 15.2963C3.15366 15.7462 3.76372 15.9993 4.40003 16H12.4004C13.0367 15.9993 13.6467 15.7462 14.0966 15.2963C14.5466 14.8463 14.7996 14.2363 14.8004 13.6V4.80007L10.8003 4.79993ZM6.00024 4.79993H8.40027C8.68608 4.79993 8.95019 4.95234 9.0931 5.19984C9.236 5.44734 9.236 5.75243 9.0931 5.99995C8.95019 6.24746 8.68608 6.39986 8.40027 6.39986H6.00024C5.71443 6.39986 5.45032 6.24745 5.30742 5.99995C5.16451 5.75244 5.16451 5.44735 5.30742 5.19984C5.45032 4.95233 5.71443 4.79993 6.00024 4.79993ZM10.8003 12.7999H6.00024C5.71443 12.7999 5.45032 12.6474 5.30742 12.3999C5.16451 12.1524 5.16451 11.8474 5.30742 11.5999C5.45032 11.3524 5.71443 11.1999 6.00024 11.1999H10.8003C11.0861 11.1999 11.3502 11.3524 11.4931 11.5999C11.636 11.8474 11.636 12.1524 11.4931 12.3999C11.3502 12.6474 11.0861 12.7999 10.8003 12.7999ZM10.8003 9.59993H6.00024C5.71443 9.59993 5.45032 9.44738 5.30742 9.19988C5.16451 8.95237 5.16451 8.64742 5.30742 8.39991C5.45032 8.1524 5.71443 7.99986 6.00024 7.99986H10.8003C11.0861 7.99986 11.3502 8.15241 11.4931 8.39991C11.636 8.64742 11.636 8.95236 11.4931 9.19988C11.3502 9.44739 11.0861 9.59993 10.8003 9.59993Z'
        fill='currentColor'
      />
    </svg>
  );
};

import { FC, useCallback, useState } from 'react';

import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SendMessageForm } from '@/features';
import Stack from '@mui/system/Stack';
import { Tab } from '@/shared/ui/Tab/Tab';
import { TracingMessageWorkspace } from '@/entities';
import { observer } from 'mobx-react-lite';
import { SendMessageStore } from '@/entities/Block/stores/SendMessageStore';
import { container } from 'tsyringe';
import { Box } from '@mui/system';
import { ErrorIcon } from '@/shared/ui';

import { Tabs } from '../lib/types';

export interface SendMessagePageProps {}

const sendMessageStore = container.resolve(SendMessageStore);

export const SendMessagePage: FC<SendMessagePageProps> = observer(() => {
  const { has, remove } = useSearchParamsTemplate();
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Send);

  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      setActiveTab(tab);
    },
    []
  );

  const handleDrawerClose = useCallback(() => {
    remove([SearchParams.ShowSendMessage]);
    setActiveTab(Tabs.Send);
  }, [remove]);

  return has(SearchParams.ShowSendMessage) ? (
    <Drawer title='Отправить сообщение в блок' onClose={handleDrawerClose} largeTitle fullscreen>
      <div>
        <Stack gap={3.5}>
          <Stack
            gap={2.5}
            direction='row'
            borderTop='1px solid var(--handle-color)'
            borderBottom='1px solid var(--handle-color)'
            paddingX={2.5}
          >
            <Tab isActive={activeTab === Tabs.Send} onClick={handleTabClick(Tabs.Send)}>
              Отправление
            </Tab>
            <Tab isActive={activeTab === Tabs.Tracing} onClick={handleTabClick(Tabs.Tracing)}>
              <Stack direction='row' gap={1.5}>
                <span>Трассировка</span>
                {sendMessageStore.exception && <ErrorIcon tooltip={sendMessageStore.exception} />}
              </Stack>
            </Tab>
          </Stack>
          {activeTab === Tabs.Send && <SendMessageForm />}
          {activeTab === Tabs.Tracing && (
            <Box height='100vh'>
              <TracingMessageWorkspace store={sendMessageStore} />
            </Box>
          )}
        </Stack>
      </div>
    </Drawer>
  ) : null;
});

SendMessagePage.displayName = 'SendMessagePage';

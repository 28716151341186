import * as types from './actionTypes';

const endpoint = process.env.REACT_APP_ETL_HOST || 'http://localhost:8101';
const serverEntries = [
  'flow',
  'properties',
  'tracing',
  'onboarding',
  'monitoring',
  'settings',
  'versionFlow',
  'flow-versions',
  'logs',
  'api'
];

const getDomain = (path) => {
  if (path === '/') return path;
  const serverEntry = serverEntries.find((entry) => path.includes(entry));
  if (!serverEntry) return path;
  return path.split(serverEntry).slice(0, -1).join(serverEntry); // search from right
};

export const updatePath = ({ origin, pathname }) => {
  let path = origin;
  if (origin === 'http://localhost:3000') {
    path = endpoint;
  }

  return {
    type: types.UPDATE_PATH,
    path: path.endsWith('/') ? path : `${path}/`,
    origin,
    serverPath: getDomain(pathname),
  };
};

//import { Segment } from './enums';
import { EveryValue } from './values';
export class DataModel {
    constructor(d) {
        this.seconds = d.seconds ? d.seconds.clone() : new EveryValue();
        this.minutes = d.minutes ? d.minutes.clone() : new EveryValue();
        this.hours = d.hours ? d.hours.clone() : new EveryValue();
        this.dayOfMonth = d.dayOfMonth ? d.dayOfMonth.clone() : new EveryValue();
        this.month = d.month ? d.month.clone() : new EveryValue();
        this.dayOfWeek = d.dayOfWeek ? d.dayOfWeek.clone() : new EveryValue();
        this.year = d.year ? d.year.clone() : new EveryValue();
    }
}
//Segment.seconds, Segment.minutes, Segment.hours, Segment.dayOfMonth, Segment.month, Segment.dayOfWeek, Segment.year;

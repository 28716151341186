import React, {FC, useCallback, useEffect, useState, WheelEvent} from 'react';

import {MiniMap, ReactFlow, useEdgesState, useNodesState} from 'reactflow';
import {Box, Stack} from '@mui/system';
import {observer} from 'mobx-react-lite';
import {container} from 'tsyringe';
import {DisplayMessageService} from '@/entities/Connect/services/DisplayMessageService';
import {LoaderIcon} from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';
import {ButtonClose} from '@/shared/ui/Button/ButtonClose/ButtonClose';
import {PaginationForTracingMessages} from '@/features';
import {getNodesForMessageTracing} from '@/widgets/lib/getNodesForMessageTracing';
import {getEdgesForMessageTracing} from '@/widgets/lib/getEdgesForMessageTracing';
import {
  MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';
import {RequestFilter} from '@/entities/Flow/types';
import {SwitchWithLabel} from '@/shared/ui/Switch/SwitchWithLabel';
import {ResendMessageErrorButton} from '@/features/ResendMessageError';
import {Tabs} from '@/widgets/MessageTracingWorkspace/lib/types';
import MaterialTabs, {tabsClasses} from '@mui/material/Tabs';
import {Tab} from '@/shared/ui/Tab/Tab';
import Typography from '@/shared/ui/Typography';
import {TabPanel} from '@/shared/ui/TabPanel/TabPanel';
import {ExecutorLogView} from '@/entities/ExecutorLog';
import ReactFlowToImageButton from '@/shared/ui/ReactFlowToImageButton';
import {AllConnectService} from '@/entities/Connect/services/AllConnectService';
import {MessageNode, MessageNodeProps} from '@/entities';
import {BlockNode, BlockNodeProps} from "@/entities/BlockNode/BlockNode";
import {getNodesForBlockTracing} from "@/entities/BlockNode/lib/getNodesForBlockTracing";
import {getEdgesForBlockTracing} from "@/entities/BlockNode/lib/getEdgesForBlockTracing";
import SplitPane from 'react-split-pane';
import styles from './MessageTracingWorkspace.module.scss'
import cn from "classnames";
import useLocalStorage from "@/hooks/useLocalStorage";
import {FlowPlatform} from "@/shared/ui/FlowPlatform/FlowPlatform";

export interface MessageTracingWorkspaceProps {
}

const proOptions = {
  hideAttribution: true,
};
const nodeTypes = {messageNode: MessageNode};
const proBlockOptions = {
  hideAttribution: true,
};
const nodeBlockTypes = {blockNode: BlockNode};

const displayMessageService = container.resolve(DisplayMessageService);
const allConnectService = container.resolve(AllConnectService);

const defaultSize = 200;

export const MessageTracingWorkspace: FC<MessageTracingWorkspaceProps> = observer(() => {
  const {get, set, remove, has} = useSearchParamsTemplate();

  const [size, setSize] = useLocalStorage('messageTracingWorkspace_heightBlockTrace', defaultSize);
  const [isTrace, setIsTrace] = useLocalStorage('messageTracingWorkspace_isTrace', true);

  const blockId = get(SearchParams.BlockId);
  const isError = get(SearchParams.IsError);
  const messageId = get(SearchParams.MessageId);

  const [filterList, setFilterList] = useState<RequestFilter[]>([]);

  const updateData = displayMessageService.updateData

  const handleTabClick = useCallback(
    (tab: Tabs) => () => {
      displayMessageService.setActiveTab(tab);
    },
    []
  );

  useEffect(() => {
    if (isError === 'true') {
      setFilterList([
        {
          name: 'isFailed',
          operator: 'is',
          value: true,
        },
      ]);
    } else {
      setFilterList([]);
    }
  }, [isError]);

  const [nodes, setNodes] = useNodesState<MessageNodeProps>([]);
  const [edges, setEdges] = useEdgesState([]);

  const [nodesBlock, setNodesBlock] = useNodesState<BlockNodeProps>([]);
  const [edgesBlock, setEdgesBlock] = useEdgesState([]);

  const handleButtonCloseClick = useCallback(() => {
    remove([SearchParams.ShowMessageTracing, ...MESSAGE_TRACING_SEARCH_PARAMS_TEMPLATE]);
  }, [remove]);

  const handleIsErrorChange = useCallback(
    (e: any) => {
      set([SearchParams.IsError, {[SearchParams.IsError]: e.target.checked.toString()}]);
    },
    [set]
  );

  const handleIsTrace = useCallback(
    (e: any) => {
      if (isTrace == true) {
        setIsTrace(false)
      } else {
        setIsTrace(true)
      }
    },
    [isTrace]
  )

  useEffect(() => {
     if (updateData) {
       setNodes(getNodesForMessageTracing(displayMessageService, allConnectService));
     }
  }, [updateData]);

  useEffect(() => {
    displayMessageService
      .getMessages(blockId, {pagination: {size: 1, page: 0}, filterList, addTrace: isTrace, messageId: messageId})
      .then(() => {
        setNodes(getNodesForMessageTracing(displayMessageService, allConnectService));
        setEdges(getEdgesForMessageTracing(displayMessageService));
        setNodesBlock(getNodesForBlockTracing(displayMessageService));
        setEdgesBlock(getEdgesForBlockTracing(displayMessageService));
      });
  }, [filterList, blockId, messageId]);

  useEffect(() => {
    setNodes(getNodesForMessageTracing(displayMessageService, allConnectService));
    setEdges(getEdgesForMessageTracing(displayMessageService));
    setNodesBlock(getNodesForBlockTracing(displayMessageService));
    setEdgesBlock(getEdgesForBlockTracing(displayMessageService));
  }, [displayMessageService.selectedExecutorHistoryId]);


  useEffect(() => {
    return () => {
      displayMessageService.reset();
    };
  }, []);

  const renderTabs = () => {
    if (displayMessageService.selectedExecutorHistory?.executorLogId) {
      return (
        <MaterialTabs
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs'
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              '&.Mui-disabled': {opacity: 0.3},
            },
          }}
        >
          <Tab
            isActive={displayMessageService.activeTab === Tabs.tracing}
            onClick={handleTabClick(Tabs.tracing)}
          >
            <Typography size='small'>Трассировка</Typography>
          </Tab>
          <Tab
            isActive={displayMessageService.activeTab === Tabs.logs}
            onClick={handleTabClick(Tabs.logs)}
          >
            <Typography size='small'>Лог сообщения</Typography>
          </Tab>
        </MaterialTabs>
      );
    } else {
      return null;
    }
  };

  return (
    <Stack padding='10px 0 10px 10px' height='100%' gap={1}>
      <Stack
        margin='0px 10px -10px 10px'
        paddingRight='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={1.5}
      >

        <Box component='h2' fontWeight={900} fontSize='2.5rem'>
          Мониторинг
        </Box>
        <Stack
          direction='row'
          justifyContent='space-between'
          gap={1.5}
        >
          {!messageId ?
            <SwitchWithLabel
              checked={isTrace}
              label='Трассировка'
              onChange={handleIsTrace}
            /> : null
          }
          <ButtonClose onClose={handleButtonCloseClick} width={40} height={40}/>
        </Stack>
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: '100px',
          position: 'relative'
        }}
      >
        <SplitPane
          split="horizontal"
          minSize={0}
          maxSize={5000}
          defaultSize={size}
          className={styles.splitPane}
          resizerClassName={cn(styles.resizer, styles.horizontal)}
          onDragFinished={(width) => {
            setSize(width);
          }}
        >
          <Box sx={{
            height: '100%',
            width: '100%'
          }}>
            <ReactFlow
              nodeTypes={nodeBlockTypes}
              proOptions={proBlockOptions}
              nodes={nodesBlock}
              edges={edgesBlock}
              defaultViewport={{x: 0, y: 0, zoom: 0.75}}
              className='blockTracing'
            >
            </ReactFlow>
          </Box>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: "flex",
              flexDirection: "column"
            }}
          >
            {renderTabs()}
            {displayMessageService.isLoadingMessages ? (
              <Box height='100%' alignItems='center' justifyContent='center' display='flex'>
                <LoaderIcon width={24} height={24}/>
              </Box>
            ) : (
              <>
                <TabPanel index={Tabs.tracing} value={displayMessageService.activeTab} height='100%'>
                  {!displayMessageService.exchangeList && <p>Сообщения отсутствуют</p>}
                  <Box height={'100%'}>
                    <FlowPlatform
                      nodeTypes={nodeTypes}
                      proOptions={proOptions}
                      nodes={nodes}
                      edges={edges}
                      defaultViewport={{x: 0, y: 50, zoom: 0.75}}
                      onlyRenderVisibleElements={nodes.length > 30}
                      translateExtent={[
                        [-100, -100],
                        [
                          (displayMessageService.maxWidth || Infinity) + 1000,
                          (displayMessageService.maxHeight || Infinity) + 1000,
                        ],
                      ]}
                      className='messageTracing'
                    >
                      {nodes.length <= 30 && (
                          <ReactFlowToImageButton
                            selector='.messageTracing'
                            imageWidth={displayMessageService.maxWidth || 1000}
                            imageHeight={displayMessageService.maxHeight || 1000}
                          />
                      )}
                      {/*{nodes.length <= 300 && (*/}
                      {/*    <MiniMap*/}
                      {/*      zoomable*/}
                      {/*      pannable*/}
                      {/*      ariaLabel={null}*/}
                      {/*      position='bottom-left'*/}
                      {/*      maskColor='#2a2d36b8'*/}
                      {/*      nodeColor='var(--paper-color)'*/}
                      {/*      nodeStrokeColor='var(--primary-color)'*/}
                      {/*      nodeBorderRadius={16}*/}
                      {/*    />*/}
                      {/*)}*/}
                    </FlowPlatform>
                  </Box>
                </TabPanel>
                {displayMessageService.selectedExecutorHistory?.executorLogId && (
                  <TabPanel index={Tabs.logs} value={displayMessageService.activeTab} height='100%'>
                    <ExecutorLogView
                      blockId={blockId}
                      id={displayMessageService.selectedExecutorHistory?.executorLogId}
                    />
                  </TabPanel>
                )}
              </>
            )}
          </Box>
        </SplitPane>
      </Box>
      {!messageId ?
        <Box display='flex' flexDirection='column' gap='1rem'>
          <Box display='flex' justifyContent='space-between' flexGrow={1} margin='0 1rem'>
            <SwitchWithLabel
              checked={isError === 'true'}
              label='Показать только с ошибками'
              onChange={handleIsErrorChange}
            />
            <Box width={300} alignSelf='flex-end'>
              <ResendMessageErrorButton
                messageErrorId={displayMessageService.selectedExecutorHistory?.id || ''}
              />
            </Box>
          </Box>
          <Box display='flex' justifyContent='center'>
            <PaginationForTracingMessages blockId={blockId} filterList={filterList} isTrace={isTrace}/>
          </Box>
        </Box>
        : null
      }
    </Stack>
  );
});

MessageTracingWorkspace.displayName = 'MessageTracingWorkspaceProps';

import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';

import { Connect } from '@/entities/Connect/types';

@singleton()
export class IndexedDBStore {
  connector?: Connect;
  valuesForSaveIdb?: Connect;
  isShowIndexedDB: boolean;
  connectName: string;
  indexedDbValues: Connect;

  constructor() {
    this.connector = null;
    this.connectName = null;
    this.valuesForSaveIdb = null;
    this.isShowIndexedDB = false;
    this.indexedDbValues = null;
    makeAutoObservable(this);
  }
}

import * as types from './actionTypes';

export const updateCache = (key, body) => {
    return {
        type: types.UPDATE_REQUEST_CACHE,
        key:key,
        body:{
            body: body,
            time: Date.now()
        }
    }
}

export const updateStateCache = (key, body) => {
    return {
        type: types.UPDATE_STATE_CACHE,
        key:key,
        body:body
    }
}


import React, { ReactElement, Ref, useMemo } from 'react';

import { IData, IComponents, ITableDescriptor } from './types';

interface IProps<T extends IData = any> {
  setItemRef?: Ref<any>;
  isLoading?: boolean;
  components: IComponents;
  descriptor: ITableDescriptor<T>;
  data: Array<T>;
}

const COUNT_OF_LOADING_ROWS = 5;

export const TableView = <T extends IData = IData>(
  props: IProps<T>
): ReactElement<any, any> | null => {
  const { setItemRef, data, components, descriptor, isLoading = false } = props;
  const { Scroller, Table, TableRow, TableHead, TableCell, TableBody } = components;

  const newData = useMemo(() => {
    return isLoading
      ? Array.from({ length: COUNT_OF_LOADING_ROWS }, (_, i) => ({ id: 'mock-item' + i } as T))
      : data;
  }, [data, isLoading])

  const columnsWidth = useMemo(() => {
    return descriptor.columns.reduce<number[]>((acc, column) => {
      const renderer = descriptor.renderers[column];

      if (!renderer) {
        return acc;
      }

      return acc.concat(renderer.width ?? 10);
    }, []);
  }, [descriptor.columns, descriptor.renderers]);

  return (
    <Scroller>
      <Table columnsWidth={columnsWidth}>
        <TableHead>
          <TableRow columnsWidth={columnsWidth} columnsCount={descriptor.columns.length}>
            {descriptor.columns.map((columnId) => {
              const renderer = descriptor.renderers[columnId];

              if (!renderer) {
                return null;
              }

              return (
                <TableCell key={columnId}>
                  <renderer.Title />
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {newData.map((rowData) => {
            return (
              <TableRow
                ref={setItemRef}
                columnsCount={descriptor.columns.length}
                data={rowData}
                columnsWidth={columnsWidth}
                key={rowData.id}
              >
                {descriptor.columns.map((columnId) => {
                  const renderer = descriptor.renderers[columnId];

                  if (!renderer) {
                    return null;
                  }

                  return (
                    <TableCell key={columnId}>
                      <renderer.Value isLoading={isLoading} data={rowData} />
                    </TableCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Scroller>
  );
};

import * as yup from 'yup';

export const nameAndDescriptionFieldsValidation = yup.object({
  name: yup.string().default('').max(100, 'Название не должно превышать 100 символов'),
  description: yup
    .string()
    .default('')
    .nullable()
    .max(1000, 'Описание не должно превышать 1000 символов'),
});

export const withGroupFieldsValidation = yup.object({
  parentId: yup.string().default('').max(30, 'Длинна ключа не может превышать 30 символов'),
  name: yup.string().default(''),
  description: yup
    .string()
    .default('')
    .nullable()
    .max(1000, 'Описание не должно превышать 1000 символов'),
  type: yup.string(),
});

import { FC, useCallback, useState } from 'react';

import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { SettingsBasic, SettingsUserAuthStore } from '@/entities/SettingsUserAuth';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import { FieldSwitch } from '@/shared/ui/Fields/components/FieldSwitch/FieldSwitch';
import Divider from '@mui/material/Divider';
import { notify } from '@/shared/ui/Toast/notify';
import { FieldPasswordWithConfirmation } from '@/shared/ui';

import { UpdateSettingsBasicAuthService } from '../api/UpdateSettingsBasicAuthService';

const settingsUserAuthStore = container.resolve(SettingsUserAuthStore);
const updateSettingsBasicAuthService = container.resolve(UpdateSettingsBasicAuthService);

export interface UpdateSettingsBasicAuthFormProps {}

export const UpdateSettingsBasicAuthForm: FC<UpdateSettingsBasicAuthFormProps> = observer(() => {
  const { register, control, handleSubmit } = useForm<SettingsBasic>({
    values: settingsUserAuthStore.settingsBasic?.entities[settingsUserAuthStore.selectedId!],
  });

  const { fields, append, remove } = useFieldArray<SettingsBasic>({
    name: 'accountList',
    control,
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleAddButtonClick = useCallback(() => {
    append({
      name: '',
      login: '',
      password: '',
      createDate: new Date(),
      isActive: true,
      checkMobile: false,
      mobile: '',
    });
  }, [append]);

  const handleRemoveButtonClick = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const handleValidSubmit = useCallback<SubmitHandler<SettingsBasic>>(
    async (settingsBasicWithId) => {
      try {
        setIsLoading(true);
        await updateSettingsBasicAuthService.updateSettingsBasicAuth(settingsBasicWithId);

        notify.success('Basic хранилище изменено');
      } catch {
        notify.error('Не удалось изменить Basic хранилище');
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  return (
    <form onSubmit={handleSubmit(handleValidSubmit)}>
      <Stack gap={2.5}>
        <Stack gap={2.5} padding='2px' height='calc(100vh - 361px)' sx={{ overflowY: 'auto' }}>
          {fields.map((field, index) => {
            return (
              <Stack key={field.id}>
                <Grid container gap={2.5}>
                  <Grid item>
                    <FieldInput
                      error=''
                      name={`accountList.${index}.name`}
                      label='Имя'
                      register={register}
                    />
                  </Grid>
                  <Grid item>
                    <FieldInput
                      error=''
                      name={`accountList.${index}.login`}
                      label='Логин'
                      register={register}
                    />
                  </Grid>
                  <Grid item>
                    <FieldPasswordWithConfirmation
                      name={`accountList.${index}.password`}
                      control={control}
                      label='Пароль'
                    />
                  </Grid>
                  <Grid item alignSelf='flex-end'>
                    <Box height={38}>
                      <FieldSwitch
                        error=''
                        name={`accountList.${index}.isActive`}
                        label='Активность'
                        control={control}
                      />
                    </Box>
                  </Grid>

                  <Grid item alignSelf='flex-end'>
                    <Box height={44}>
                      <Button
                        circle
                        variant={ButtonVariants.Outlined}
                        size={ButtonSizes.Small}
                        onClick={handleRemoveButtonClick(index)}
                      >
                        <TrashIcon />
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
                <Divider variant='middle' sx={{ padding: 2.5 }} />
              </Stack>
            );
          })}
        </Stack>
        <Stack direction='row' justifyContent='space-between'>
          <Box width={344}>
            <Button
              doesUseInFormOrTable
              variant={ButtonVariants.Secondary}
              onClick={handleAddButtonClick}
            >
              <Stack gap={1} direction='row' alignItems='center'>
                <PlusIcon />
                Добавить учетную запись
              </Stack>
            </Button>
          </Box>
          <Box width={212}>
            <Button type='submit' loading={isLoading}>
              Сохранить
            </Button>
          </Box>
        </Stack>
      </Stack>
    </form>
  );
});

UpdateSettingsBasicAuthForm.displayName = 'UpdateSettingsBasicAuthForm';

import * as React from 'react';
import { FC, memo, useMemo } from 'react';

import { SwitchUnstyled, SwitchUnstyledProps } from '@mui/base';
import cn from 'classnames';

import styles from './Switch.module.scss';

export interface SwitchProps extends SwitchUnstyledProps {
  inputProps?: any;
  id?: string;
  invertChecked?: boolean;
}

export const Switch: FC<SwitchProps> = memo(
  ({ id, checked, defaultChecked, inputProps, invertChecked, ...props }) => {
    const finalChecked = invertChecked ? !checked : checked;
    const slotProps = useMemo(() => {
      return {
        root: { className: cn(styles.root, { [styles.checked]: checked || defaultChecked }) },
        thumb: { className: styles.thumb },
        track: { className: styles.track },
        input: {
          ...inputProps,
          className: styles.input,
          id,
        },
      };
    }, [checked, defaultChecked, id, inputProps]);

    return (
      <SwitchUnstyled
        {...props}
        checked={finalChecked}
        defaultChecked={defaultChecked}
        slotProps={slotProps}
        id={id}
      />
    );
  }
);

import { FC } from 'react';

import { CardWithActions } from '@/shared/ui/CardWithActions/CardWithActions';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { FlowService } from '@/entities/Flow/services/FlowService';
import cn from 'classnames';

import styles from './FlowCard.module.scss';

const flowService = container.resolve(FlowService);

export interface MessageQueueCardProps {
  flowId: string;
  active: boolean;
  actions: JSX.Element;
  onClick: () => void;
  isFirst?: boolean;
}

export const FlowCard: FC<MessageQueueCardProps> = observer(
  ({ flowId, active, actions, onClick, isFirst}) => {
    const isLoading = flowService.isLoadingFlows;

    const date = dayjs(flowService.flows.entities[flowId].lastUpdate).format('DD.MM.YYYY');

    return (
      <CardWithActions
        title={flowService.flows.entities[flowId].name}
        loading={isLoading}
        active={active}
        onClick={onClick}
        actions={actions}
      >
        <div className={styles.root}>
          <p className={cn(styles.description, { [styles.active]: active })}>
            {flowService.flows.entities[flowId].description}
          </p>
          <p className={styles.date}>{date}</p>
        </div>
      </CardWithActions>
    );
  }
);

FlowCard.displayName = 'FlowCard';

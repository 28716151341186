import React from 'react';

import { ButtonUnstyled, ButtonUnstyledProps } from '@mui/base';
import cn from 'classnames';
import { LoaderIcon } from '@/shared/ui/Icons/LoaderIcon/LoaderIcon';

import { ButtonSizes, ButtonVariants } from './types';

import styles from './Button.module.scss';

export interface ButtonProps extends ButtonUnstyledProps {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  active?: boolean;
  circle?: boolean;
  noDrag?: boolean;
  doesUseInFormOrTable?: boolean;
  loading?: boolean;
  id?: string;
  className?: any;
}

export function Button ({
  variant = ButtonVariants.Primary,
  circle,
  size,
  active,
  noDrag = true,
  doesUseInFormOrTable,
  loading,
  children,
  className,
  ...props
}: ButtonProps) {
  const classNameCN = cn(
    styles.root,
    styles[variant],
    { nodrag: noDrag },
    {
      [styles.circle]: circle,
      [styles.small]: size === ButtonSizes.Small,
      [styles.medium]: size === ButtonSizes.Medium,
      [styles.active]: active,
      [styles.inFormOrTable]: doesUseInFormOrTable,
    },
    className
  );

  return (
    <ButtonUnstyled {...props} className={classNameCN}>
      {loading ? <LoaderIcon /> : children}
    </ButtonUnstyled>
  );
}

export default Button;

import { FC, useCallback } from 'react';

import { Stack } from '@mui/system';
import Tooltip from '@/shared/ui/Tooltip';
import { Label } from '@/shared/ui/Label/Label';
import { Normalized } from '@/shared/lib/normalize';
import { isType } from '@/shared/lib/isType';
import { observer } from 'mobx-react-lite';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove, rectSortingStrategy, SortableContext } from '@dnd-kit/sortable';
import { AllConnectService } from '@/entities/Connect/services/AllConnectService';
import { container } from 'tsyringe';
import { ConnectEditor } from '../ConnectEditor/ConnectEditor';
import { ConnectCreator } from '../ConnectCreator/ConnectCreator';
import { ConnectSizes, ConnectTypes } from './types';
import { Connect, Parameter, Processor } from '../../types';

import styles from './ConnectList.module.scss';

export interface ConnectorListProps {
  connectorCreatorOptions: Parameter[];
  connectors: Normalized<Connect | Processor> | null;
  size?: ConnectSizes;
  title: string;
  type: ConnectTypes;
  blockRelationService?: any;
  manyToOne?: boolean;
  blockId: string;
  draggable?: boolean;
  onReorder?: (newOrder: string[]) => void;
  id: string;
}

const allConnectService = container.resolve(AllConnectService);

export const ConnectList: FC<ConnectorListProps> = observer(
  ({
    connectorCreatorOptions,
    connectors,
    size = ConnectSizes.Small,
    title,
    type,
    blockRelationService,
    blockId,
    onReorder,
    draggable,
    id,
  }) => {
    const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));
    const handleDragEnd = useCallback(
      (event: DragEndEvent) => {
        const { active, over } = event;

        if (active.id !== over?.id) {
          const oldIndex = connectors!.ids.indexOf(String(active.id));
          const newIndex = connectors!.ids.indexOf(String(over!.id));

          onReorder?.(arrayMove(connectors!.ids, oldIndex, newIndex));
        }
      },
      [connectors, onReorder]
    );

    const connectorCreator = (
      <ConnectCreator
        connectOptions={connectorCreatorOptions}
        size={size}
        type={type}
        blockId={blockId}
        id={id}
      />
    );

    const connectorEditorList = connectors?.ids.map((id) => {
      const connector = connectors.entities[id];
      let title: string | undefined = '';
      let connectorName: string | undefined = '';
      let connectorNameLocale: string | undefined = '';
      let manyToOneString: string | undefined;

      if (connector.isManyToOne) {
        manyToOneString = connector?.params?.manyToOneId || 'Невалидный коннектор';
      }

      if (connector.typeProcessor) {
        title = connector.name;
        connectorName = connector.typeProcessor;
        connectorNameLocale = allConnectService.parameterByName(
          connector.typeProcessor,
          type
        )?.localeName;
      } else {
        if (isType<Connect>(connector, 'typeConnect')) {
          title = connector.name;
          connectorName = connector.typeConnect;
          connectorNameLocale =
            connector.typeConnect &&
            allConnectService.parameterByName(connector.typeConnect, type)?.localeName;
        }
      }
      if (!connectorNameLocale) {
        connectorNameLocale = connectorName;
      }
      const isHighlightManyToOne = connector.isManyToOne ? manyToOneString : undefined;
      const isHighlight =
        blockRelationService?.lineIdWhichHover?.includes(blockId) &&
        blockRelationService?.lineIdWhichHover?.includes(id);
      const titleString = `${connectorNameLocale} : ${title}`;

      return (
        <Tooltip
          key={id}
          title={titleString}
          placement='top'
          isTooltipActive={title && title.length > 25}
          disableInteractive
        >
          <span className={styles.connect}>
            <ConnectEditor
              key={id}
              title={title ?? ''}
              size={size}
              type={type}
              manyToOne={connector.isManyToOne}
              isHighlightManyToOne={isHighlightManyToOne}
              connector={connectorName ?? ''}
              connectorNameLocale={connectorNameLocale}
              connectorId={id ?? ''}
              blockId={blockId}
              draggable={draggable}
              active={connector.isActive}
              isHighlight={isHighlight}
            />
          </span>
        </Tooltip>
      );
    });

    switch (size) {
      case ConnectSizes.Small: {
        return (
          <Stack
            direction='row'
            gap={2}
            width='100%'
            justifyContent='space-between'
            className={styles.rootSmall}
          >
            <Stack component='ul' className={styles.connectList}>
              {connectors?.ids.length === 0 ? <p>{title}</p> : connectorEditorList}
            </Stack>
            <Stack component='ul' className={styles.connectCreator}>
              {connectorCreator}
            </Stack>
          </Stack>
        );
      }

      case ConnectSizes.Large: {
        return (
          <Stack spacing={2}>
            <Label>{title}</Label>
            {draggable ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={connectors?.ids || []} strategy={rectSortingStrategy}>
                  <ul className='flex flex-wrap gap-3 overflow-hidden'>
                    {connectorEditorList}
                    {connectorCreator}
                  </ul>
                </SortableContext>
              </DndContext>
            ) : (
              <ul className='flex flex-wrap gap-3'>
                {connectorEditorList}
                {connectorCreator}
              </ul>
            )}
          </Stack>
        );
      }
    }
  }
);

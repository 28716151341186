import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';

@singleton()
export class SetVersionService {
  isLoading: boolean;
  showDialog: boolean;

  constructor(private apiService: ApiService) {
    this.isLoading = false;
    this.showDialog = false;

    makeAutoObservable(this);
  }

  async setVersion(versionId: string, clone: boolean = false) {
    this.isLoading = true;

    try {
      await this.apiService.instance.post(`/editor/version/changeVersion`, undefined, {
        params: {
          versionId,
          clone,
        },
      });

      notify.success('Версия установлена');
    } catch (error) {
      notify.error('Не удалось установить версию');
      throw error;
    } finally {
      this.isLoading = false;
    }
  }
}

import { FC, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { ExportButton } from '@/shared/ui/Button/ExportButton';

import { BlockTestingService } from '../../../services/BlockTestingService';

const blockTestingService = container.resolve(BlockTestingService);

export interface ExportTestsProps {
  blockName: string;
  blockId: string;
}

export const ExportTests: FC<ExportTestsProps> = ({ blockName, blockId }) => {
  const handleClick = useCallback(() => {
    void blockTestingService.exportAllTests(blockName, blockId);
  }, [blockId, blockName]);

  return (
    <Tooltip title='Экспорт всех тестов' placement='top'>
      <span>
        <ExportButton loading={blockTestingService.isLoadingTests} onClick={handleClick} />
      </span>
    </Tooltip>
  );
};

import { showNotification } from "../store/notifications/actions";

function constructURL(url, params) {
    const queryString = new URLSearchParams(params).toString();
    return queryString ? `${url}?${queryString}` : url;
}

function constructorHeaders(auth, headers, isDefault = true) {
    let headerRes
    if (isDefault) {
        headerRes = {
            'Accept': 'application/json; charset=utf-8',
            'Content-Type': 'application/json; charset=utf-8'
        }
    } else {
        headerRes = {
            ...headers
        }
    }
    if (auth) {
        headerRes['authorization']=auth
    }
    return headerRes;
}

function status(response) {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response)
    } else {
        return Promise.reject(new Error(response.statusText))
    }
}

function json(response) {
    return response.json()
}

export async function getRequest({url, auth, headers = {}, isHeadersDefault, params, dispatch, type}, onError) {
    const URL = constructURL(url, params)
    const headersDefault = constructorHeaders(auth, headers, isHeadersDefault)
    const response = fetch(URL, {
        headers: {
            ...headersDefault,
            ...headers
        },
        method: 'GET'
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
            } else {
                console.log("ERROR")
                onError && onError()
                return Promise.reject(new Error(response.statusText))
            }
        })
        .then((response) =>{
            if (type === "String") {
                return response.text()
            } else {
                return response.json()
            }
        })
        .then(function (response) {
            dispatch && dispatch(showNotification("success", URL))
            return response;
        })
        .catch(function (error) {
            dispatch && dispatch(showNotification("error", URL))
            onError && onError()
        });
    return await response;
}

export async function postRequest({url, auth, body, headers = {}, isHeadersDefault, params, dispatch, requestOptions}, onError) {
    const URL = constructURL(url, params)
    const headersDefault = constructorHeaders(auth, headers, isHeadersDefault)
    dispatch && dispatch(showNotification("info", `Вызвали ${URL}`))
    const response = await fetch(URL, {
        headers: {
            ...headersDefault,
            ...headers
        },
        method: 'POST',
        body: body ? JSON.stringify(body) : undefined,
        ...requestOptions
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
            } else {
                onError && onError()
                return Promise.reject(new Error(response.statusText))
            }
        })
        .then((response) => {
            try {
                return response.json()
            } catch (e) {
                return response
            }
        })
        .then(function (response) {
            dispatch && dispatch(showNotification("success", URL))
            return response;
        })
        .catch(function (error) {
            dispatch && dispatch(showNotification("error", URL))
            onError && onError()
        });
    return response;
}

export async function putRequest({url, auth, headers = {}, body, isHeadersDefault, params, dispatch}, onError) {
    const URL = constructURL(url, params)
    const headersDefault = constructorHeaders(auth, headers, isHeadersDefault)
    const response = await fetch(URL, {
        headers: {
            ...headersDefault,
            ...headers
        },
        method: 'PUT',
        body: JSON.stringify(body)
    })
        .then((response) => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
            } else {
                onError && onError()
                response.json().then((messageJson) => {
                    dispatch && dispatch(showNotification("error", messageJson.message))
                    }
                )
                return Promise.reject(new Error(response.statusText))
            }
        })
        .then((response) => {
            try {
                return response.json()
            } catch (e) {
                return response
            }
        })
        .then(function (response) {
            return response;
        })
        .catch(function (error) {
            onError && onError()
        });
    return response;
}


export async function deleteRequest({url, auth, headers = {}, body, isHeadersDefault, params, dispatch}, onError) {
    const URL = constructURL(url, params)
    const headersDefault = constructorHeaders(auth, headers, isHeadersDefault)
    const response = await fetch(URL, {
        headers: {
            ...headersDefault,
            ...headers
        },
        method: 'DELETE',
        body: JSON.stringify(body)
    })
        .then((response) => response.json())
        .then(function (response) {
            dispatch && dispatch(showNotification("success", URL))
            return response;
        })
        .catch(function (error) {
            dispatch && dispatch(showNotification("error", URL))
            onError && onError()
        });
    return response
}
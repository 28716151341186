import { FC, memo } from 'react';

import { Radio as MaterialRadio, RadioProps as MaterialRadioProps } from '@mui/material';
import { Stack } from '@mui/system';

import styles from './Radio.module.scss';

export interface RadioProps extends MaterialRadioProps {
  label?: string;
}

export const Radio: FC<RadioProps> = memo(({ id, label, ...props }) => {
  return (
    <Stack className={styles.root} direction='row' spacing={1} alignItems='center'>
      <MaterialRadio
        id={id}
        className={styles.radio}
        disableRipple
        prefix='das'
        {...props}
        icon={<span className={styles.icon} />}
        checkedIcon={
          <span className={styles.checkedIcon}>
            <span className={styles.circle} />
          </span>
        }
      />
      {label && <label htmlFor={id}>{label}</label>}
    </Stack>
  );
});

import { singleton } from 'tsyringe';
import { makeAutoObservable } from 'mobx';
import { Step } from 'react-joyride';
import { data } from '@/entities/Onboarding/utils/mock_data';

@singleton()
export class JoyRideStore {
  run: boolean;
  steps: Step[];
  stepIndex: number;
  currentStep: number;
  closeOnboarding: boolean;
  openOnboarding: boolean;
  isTourEnded: boolean;

  constructor() {
    this.run = false;
    this.stepIndex = 0;
    this.currentStep = 0;
    this.steps = data;
    this.closeOnboarding = false;
    this.openOnboarding = false;
    this.isTourEnded = false;
    makeAutoObservable(this);
  }
}

import { injectable } from 'tsyringe';
import {
  makeAutoObservable,
  runInAction,
  remove,
  set,
} from 'mobx';
import { last, identity, union, keyBy } from 'lodash';
import { notify } from '@/shared/ui/Toast/notify';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { traverse, buildMaps, prepareGroups } from "@/shared/lib/buildTree";
import { GFlow, RestrictedGFlow } from "@/entities/Flow/types";

import { GFlowStore } from '../stores/GFlowStore';


@injectable()
export class GFlowService {
  constructor(
    private apiService: ApiService,
    private gflowStore: GFlowStore,
  ) {
    makeAutoObservable(this);
  }

  get groups(): GFlowStore['groups'] {
    return this.gflowStore.groups;
  }

  get gflows(): GFlowStore['gflows'] {
    return this.gflowStore.gflows;
  }

  get gflowsMap(): { [key: string]: GFlow } {
    return this.gflowStore.gflowsMap;
  }

  get flowsHash() {
    return this.gflowStore.flowsHash;
  }

  get isLoadingGFlows(): boolean {
    return this.gflowStore.isLoadingGFlows;
  }

  get isLoadingUpdateNode(): boolean {
    return this.gflowStore.isLoadingSaveGFlow;
  }

  get isLoadingDeleteNode(): boolean {
    return this.gflowStore.isLoadingDeleteGFlow;
  }

  get selectedNodeId() {
    return this.gflowStore.selectedNodeId;
  }

  set selectedNodeId(id) {
    this.gflowStore.selectedNodeId = id;
  }

  get loadedKeys() {
    return this.gflowStore.loadedKeys;
  }

  set selectedFlowId(id: string) {
    const nodeId = this.flowsHash[id];
    this.gflowStore.selectedNodeId = nodeId || null;
  }

  async getExpandedGroup(nodeId: string): Promise<void> {
    const params = {
      childrenLevel: 2,
    };
    const { data } = await this.apiService.instance.post<GFlow>(`editor/tree/${nodeId}`, params);

    const groups = data.children.filter(({ type }) => type === 'group');
    runInAction(() => {
      groups.forEach((rec) => {
        const parentNode = this.gflowsMap[rec.id];
        set(parentNode, 'children', rec.children);
        parentNode.children.forEach((el) => {
          el.isLeaf = el.type === 'flow';
          set(this.gflowsMap, el.id, el);
          if (!el.isLeaf) return;
          set(this.flowsHash, el.componentId, el.id);
        });
      });
    });
  }

  getPathToNode(nodeId: (string | null)) {
    if (!nodeId) return [];

    let id = nodeId;
    const path = [];
    while (id) {
      const rec = this.gflowsMap[id];
      if (!rec) {
        id = '';
        break;
      }
      path.push(id);
      id = rec.parentId;
    }
    return path;
  }

  private prepareRecord = (rec: { [key: string]: any }) => {
    rec.isLeaf = rec.type === 'flow';
    rec.title = '';
  };

  private getInitialLoadedIds() {
    const level1 = (this.gflows.children || []).filter(({ type }) => type === 'group');
    const idsList = [this.gflows, ...level1].map(({ id }) => id);
    const { selectedNodeId } = this.gflowStore;
    const pathSelected = selectedNodeId ? this.getPathToNode(selectedNodeId) : [];
    return union(idsList, pathSelected);
  }

  async getInitialGFlows(
    flowId: string,
    expanded: string[],
  ) {

    let { selectedNodeId } = this.gflowStore;
    this.gflowStore.isLoadingGFlows = true;
    const flowCfg = !selectedNodeId && flowId ? { flowId } : {};
    try {
      const params = {
        childrenLevel: 2,
        treeIdList: [...expanded, selectedNodeId].filter(identity),
        ...flowCfg,
      };
      const { data } = await this.apiService.instance.post('editor/tree/rootFlow', params);

      traverse(data, this.prepareRecord);

      runInAction(() => {
        this.gflowStore.gflows = data;
        const { gflowsMap, flowsHash } = buildMaps(this.gflows);
        this.gflowStore.gflowsMap = gflowsMap;
        this.gflowStore.flowsHash = flowsHash;
        if (!selectedNodeId && flowId) {
          const nodeId = flowsHash[flowId];
          selectedNodeId = nodeId || selectedNodeId;
          this.gflowStore.selectedNodeId = selectedNodeId;
        }
        this.gflowStore.loadedKeys = this.getInitialLoadedIds();
      });
    } catch (e) {
      notify.error('Не удалось загрузить данные по потокам');
      // eslint-disable-next-line no-console
      console.error(e);
    }
    this.gflowStore.isLoadingGFlows = false;
    return selectedNodeId
  }

  findGFlow(
    flowId: string,
  ) {
    let { selectedNodeId } = this.gflowStore;
    if (selectedNodeId || !flowId) {
      return selectedNodeId;
    }
    const nodeId = this.flowsHash[flowId];
    selectedNodeId = nodeId || selectedNodeId;
    this.gflowStore.selectedNodeId = selectedNodeId;
    return selectedNodeId
  }

  async syncFlowData(id: string): Promise<void> {
    const nodeId = this.findGFlow(id);
    const params = {
      childrenLevel: 0,
    };
    try {
      const { data } = await this.apiService.instance.post<GFlow>(`editor/tree/${nodeId}`, params);
      runInAction(() => {
        const node = this.gflowsMap[nodeId];
        set(node, 'data', data.data);
      });
    } catch {
      notify.error('Не удалось обновить данные потока');
    }
  }

  getGroups() {
    this.gflowStore.groups = prepareGroups(this.gflows);
  }

  private calcPriopity(children: { priority: number; }[], idx: number) {
    const rec2 = children[idx];
    if (!rec2) return last(children).priority - 1000;
    if (idx === 0) return rec2.priority + 1000;
    const rec1 = children[idx - 1];
    return Math.ceil((rec1.priority + rec2.priority) / 2);
  }

  async reorderGFlow(dragId: string, dropId: string, mode: string) {
    const node = this.gflowsMap[dragId];
    const srcGroup = this.gflowsMap[node.parentId];
    const srcIdx = srcGroup.children.indexOf(node);
    const parentId = mode === 'parent'
      ? dropId
      : this.gflowsMap[dropId].parentId;
    const destGroup = this.gflowsMap[parentId];

    let destIdx = 0;
    let priority = 5000;
    if (mode !== 'parent') {
      destIdx = destGroup.children.indexOf(this.gflowsMap[dropId]);
      destIdx += mode === 'top' ? 0 : 1;
      priority = this.calcPriopity(destGroup.children, destIdx);
    }
    if (mode === 'parent' && destGroup.children.length) {
      priority = destGroup.children[0].priority + 1000;
    }

    this.gflowStore.isLoadingReorderGFlow = true;
    try {
      const { data } = await this.apiService.instance.put<GFlow[]>('/editor/tree/smartSave', {
        ...node,
        priority,
        parentId,
      });

      /* commit change */
      runInAction(() => {
        if (!destGroup.children) {
          set(destGroup, 'children', []);
        }

        srcGroup.children.splice(srcIdx, 1);
        destGroup.children.splice(destIdx, 0, node);
        data.forEach((rec) => {
          const updatedRec = this.gflowsMap[rec.id];
          set(updatedRec, 'parentId', rec.parentId);
          set(updatedRec, 'priority', rec.priority);
        });
        if (destGroup.children.length > 1) {
          destGroup.children.sort((a, b) => b.priority - a.priority);
        }
        this.selectedNodeId = node.id;
      });
    } catch (e) {
      notify.error('Не удалось изменить порядок узлов в дереве');
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      this.gflowStore.isLoadingReorderGFlow = false;
    }
  }

  async deleteGFlow(nodeId: string): Promise<void> {
    this.gflowStore.isLoadingDeleteGFlow = true;
    const removing = this.gflowsMap[nodeId];
    const parentNode = this.gflowsMap[removing.parentId];
    const idx = parentNode.children.indexOf(removing);
    const { children } = removing;

    try {
      await this.apiService.instance.delete(`/editor/tree/${nodeId}`);

      runInAction(() => {
        remove(parentNode.children, String(idx));
        remove(this.gflowsMap, nodeId);
        remove(this.flowsHash, nodeId);
      });

      if (!children?.length) return;

      const { data } = await this.apiService.instance.post<GFlow>(`editor/tree/${parentNode.id}`, { childrenLevel: 1 });
      const { children: parentChildren } = data;
      const parentChildrenMap = keyBy(parentChildren, 'id');

      runInAction(() => {
        parentNode.children.splice(parentNode.children.length, 0, ...children);
        parentNode.children.forEach((node) => {
          set(node, 'parentId', parentNode.id);
          const serverNode = parentChildrenMap[node.id];
          if (!serverNode) {
            // eslint-disable-next-line no-console
            console.error(`Absent node on server: ${node.data.name}`, node);
            return;
          }
          set(node, 'priority', serverNode.priority);
        })
        parentNode.children.sort((a, b) => b.priority - a.priority);
      });
    } catch (error) {
      const entity = removing.type === 'group' ? 'группу' : 'поток';
      notify.error(`Не удалось удалить ${entity}`);
      throw error;
    } finally {
      this.gflowStore.isLoadingDeleteGFlow = false;
    }
  }

  async saveGFlow(values: (GFlow | RestrictedGFlow), nodeId: string): Promise<GFlow | null> {
    this.gflowStore.isLoadingSaveGFlow = true;
    if (!values.parentId) {
      values.parentId = this.gflowStore.gflows.id;
    }
    const isFlow = values.type === 'flow';
    const treeNode = this.gflowsMap[nodeId];
    const isUpdate = Boolean(treeNode);
    const prevParent = this.gflowsMap[treeNode?.parentId];
    let priority = values.priority || 5000;

    try {
      const newParent = this.gflowsMap[values.parentId];
      if (isUpdate
        && (prevParent.id !== newParent.id)
        && newParent.children.length) {
        priority = newParent.children[0].priority + 1000;
        values.priority = priority;
      }
      delete values.children;
      const { data: node } = await this.apiService.instance.put<GFlow>('/editor/tree/save', values);

      if (isUpdate) { // edit node
        runInAction(() => {
          set(treeNode.data, node.data);
          if (prevParent.id !== newParent.id) { // move edited node
            set(treeNode, 'parentId', node.parentId);
            set(treeNode, 'priority', priority);
            const idx = prevParent.children.indexOf(treeNode);
            remove(prevParent.children, String(idx));
            newParent.children.unshift(treeNode);
            this.selectedNodeId = node.id;
          }
        });
      } else { // create node
        const { parentId } = node;
        this.prepareRecord(node);
        const parentNode = this.gflowsMap[parentId];
        if (node.type === 'group') node.children = [];

        runInAction(() => {
          if (!parentNode.children) set(parentNode, 'children', []);
          parentNode.children.unshift(node);
          set(this.gflowsMap, node.id, parentNode.children[0]);
        });
      }
      return node;
    } catch (e) {
      const act = isUpdate ? 'изменить' : 'создать';
      const entity = isFlow ? 'поток' : 'группу';
      const msg = `Не удалось ${act} ${entity}`;
      notify.error(msg);
      // eslint-disable-next-line no-console
      console.error(e);
      return null;
    } finally {
      this.gflowStore.isLoadingSaveGFlow = false;
    }
  }
}

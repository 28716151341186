import React, {FC, memo, useEffect, useState} from 'react';

import {container} from 'tsyringe';
import {CreateKeyValueCard, UpsertKeyValueDialogForm} from '@/features/UpsertKeyValue';
import {KeyValueService, KeyValueStore, RequestFilter} from "@/entities/KeyValue";
import {KeyValueList} from "@/widgets/KeyValueList";
import {SearchParams, useSearchParamsTemplate} from "@/hooks/useTemplateSearchParams";
import styles from "./KeyValueStorePage.module.scss"
import {FilterKeyValueStore} from "@/features/FilterKeyValueStore";

const keyValueService = container.resolve(KeyValueService);
const keyValueStore = container.resolve(KeyValueStore);

export interface ExternalAppsPageProps {
}

export const KeyValueStorePage: FC<ExternalAppsPageProps> = memo(() => {
    const {get, set, remove} = useSearchParamsTemplate();
    const valueSearchParam = get(SearchParams.Search);

    useEffect(() => {
        if (valueSearchParam) {
            keyValueService.setFilterList([
                {
                    name: "name",
                    operator: "regex",
                    options: "",
                    value: valueSearchParam
                }
            ])
        } else {
            keyValueService.setFilterList([])
        }
    }, [valueSearchParam]);

    return (
        <div className={styles.root}>
            <div className={styles.filter}>
                <FilterKeyValueStore/>
            </div>
            <div className={styles.content}>
                <KeyValueList/>
            </div>
            <div className={styles.addCardPanel}></div>
            <div className={styles.addCard}>
                <CreateKeyValueCard/>
            </div>
            <UpsertKeyValueDialogForm/>
        </div>
    );
});

KeyValueStorePage.displayName = 'KeyValueStorePage';

import React, { FC, useCallback } from 'react';

import { Box, Stack } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ButtonClose } from '@/shared/ui/Button/ButtonClose/ButtonClose';

import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { ExecutorLogByBlockView } from '@/entities/ExecutorLog/ui/ExecutorLogByBlockView';

export interface ExecutorLogBlockProps {}

export const ExecutorLogBlock: FC<ExecutorLogBlockProps> = observer(() => {
  const { get, remove } = useSearchParamsTemplate();

  const blockId = get(SearchParams.BlockId);
  const handleButtonCloseClick = useCallback(() => {
    remove([SearchParams.ShowExecutorLogBlock]);
  }, [remove]);

  return (
    <Stack padding='10px 0 10px 10px' height='100%' gap={1}>
      <Stack
        margin='0px 10px -10px 10px'
        paddingRight='20px'
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        gap={1.5}
      >
        <Box component='h2' fontWeight={900} fontSize='2.5rem'>
          Лог блока
        </Box>
        <ButtonClose onClose={handleButtonCloseClick} width={40} height={40} />
      </Stack>
      <Box
        style={{
          flexGrow: 1,
          height: '100px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ExecutorLogByBlockView blockId={blockId} />
      </Box>
    </Stack>
  );
});

ExecutorLogBlock.displayName = 'ExecutorLogBlockProps';

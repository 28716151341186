import { FC } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { container } from 'tsyringe';
import { PythonBeanStore } from '@/entities/PythonBean';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';

import { DeletePythonBeanService } from '../api/DeletePythonBeanService';

const deletePythonBeanService = container.resolve(DeletePythonBeanService);
const pythonBeanStore = container.resolve(PythonBeanStore);

export interface DeletePythonBeanDialogProps {
  pythonScriptId?: string;

  onSuccess?(): void;
}

export const DeletePythonBeanDialog: FC<DeletePythonBeanDialogProps> = observer(
  ({ pythonScriptId, onSuccess }) => {
    const handleClose = () => {
      deletePythonBeanService.showDialog = false;
    };

    const handleAccept = () => {
      if (pythonScriptId) {
        deletePythonBeanService.deletePythonBean(pythonScriptId).then(onSuccess).then(handleClose);
      }
    };

    return (
      <Dialog open={deletePythonBeanService.showDialog} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удалить скрипт</DialogTitle>
        <DialogContent>
          Вы действительно хотите удалить скрипт{' '}
          {pythonScriptId && pythonBeanStore.scripts?.entities[pythonScriptId]?.name}?
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отменить
          </Button>
          <Button
            loading={deletePythonBeanService.isLoading}
            variant={ButtonVariants.Popup}
            onClick={handleAccept}
          >
            Да
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeletePythonBeanDialog.displayName = 'DeletePythonBeanDialog';

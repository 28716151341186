export const activeMenuStyle = ({ theme, isActive }) => {
  let style = {};

  if (isActive) {
    if (theme === 'light') {
      style = {
        ...style,
        borderBottom: '0.125rem solid #1976d2',
        borderRadius: '0.125rem',
      };
    } else {
      style = {
        ...style,
        borderBottom: '0.125rem solid rgba(230,230,230,0.9)',
        borderRadius: '0.125rem',
        color: 'rgba(230,230,230,0.9)',
      };
    }
  }

  return style;
};

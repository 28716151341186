import React, { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';

import { isEmpty } from 'lodash';
import { Divider, IconButton } from '@mui/material';
import { container } from 'tsyringe';
import { ExecutorLogService, ExecutorLogStore } from '@/entities/ExecutorLog';
import TypographyDate from '@/shared/ui/Typography/TypographyDate';
import { Loader } from '@/shared/ui/Loader/Loader';
import Typography from '@/shared/ui/Typography';
import { Select } from '@/shared/ui';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { filteredMessage } from '@/entities/ExecutorLog/utils/ExecutorLogSearch';
import { selectOption } from '@/entities/ExecutorLog/utils/constants';
import { CrossIcon } from '@/shared/ui/Icons/CrossIcon/CrossIcon';
import { Interweave } from 'interweave';

import styles from './styles.module.scss';

export interface ExecutorLogByBlockProps {
  blockId: string;
}

const executorLogService = container.resolve(ExecutorLogService);
const executorLogStore = container.resolve(ExecutorLogStore);
export const ExecutorLogByBlockView: FC<ExecutorLogByBlockProps> = memo(({ blockId }) => {
  const [rerender, setRerender] = useState<boolean>(false);
  const [typeList, setTypeList] = useState<string[]>(['Block']);
  /* Состояние для поиска по тексту сообщения */
  const [searchTextMessage, setSearchTextMessage] = useState<string>('');

  useEffect(() => {
    executorLogService.findByBlockId(blockId, typeList).then(() => {
      setRerender(!rerender);
    });
  }, [blockId, typeList]);

  const onChangeType = (event) => {
    setTypeList(event.target.value);
  };

  /* Функция ввода текста для поиска */
  const handlerSearch = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setSearchTextMessage(event.target.value);
    },
    []
  );

  /* Функция фильтрации сообщений в логе блока */
  const filteredExecutorLog = useMemo(() => {
    const messageLog = executorLogStore.executorLogByBlock.flatMap((item) =>
      Object.values(item.messageLogs)
    );
    return filteredMessage(searchTextMessage, messageLog);
  }, [searchTextMessage, rerender, executorLogStore, blockId]);

  const propsTextField = {
    startAdornment: <SearchIcon className={styles.searchIcon} />,
    endAdornment: (
      <IconButton type='button' onClick={() => setSearchTextMessage('')}>
        <CrossIcon width={16} height={16} />
      </IconButton>
    ),
  };
  return (
    <>
      <div className={styles.panel_filters}>
        {!isEmpty(typeList) && (
          <TextField
            value={searchTextMessage}
            onChange={handlerSearch}
            InputProps={propsTextField}
          />
        )}
        <Select options={selectOption} onChange={onChangeType} multiple value={typeList} />
      </div>
      {executorLogStore.isLoadingByBlock ? (
        <Loader />
      ) : (
        <div className={styles.root}>
          {filteredExecutorLog.map((message) => {
            return (
              <div className={styles.message} key={message.id}>
                <div className={styles.time}>
                  <TypographyDate time={message.createDate} />
                </div>
                <span>:</span>
                <span className={styles.name}>
                  <Typography>[{message.name}]</Typography>
                </span>
                <span>:</span>
                <div className={styles[message.level]}>
                  <Interweave
                    content={message.bodyHTML || message.text}
                    className={styles.text}
                    allowList={['mark']}
                  />
                </div>
              </div>
            );
          })}
          <Divider />
        </div>
      )}
    </>
  );
});

ExecutorLogByBlockView.displayName = 'ExecutorLogByBlockView';

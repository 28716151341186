export const truncateData = (text: string): string => {
    if (typeof text !== 'string') {
        return '';
    }

    if (text.length <= 70) {
        return text;
    }

    const truncated = text.slice(0, 70);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    return lastSpaceIndex > -1 ? `${truncated.slice(0, lastSpaceIndex)}...` : `${truncated}...`;
}
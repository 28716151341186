import { FC, memo } from 'react';

import MuiDialogActions, {
  DialogActionsProps as MuiDialogActionsProps,
} from '@mui/material/DialogActions';
import cn from 'classnames';

import styles from './Dialog.module.scss';

export interface DialogActionsProps extends MuiDialogActionsProps {}

export const DialogActions: FC<DialogActionsProps> = memo(({ className, ...props }) => {
  return <MuiDialogActions {...props} className={cn(styles.root, className)} />;
});

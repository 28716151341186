export enum SearchParams {
  ShowBlockForm = 'showBlockForm',
  ShowBlockDeleteModal = 'showBlockDeleteModal',
  ShowDiffFlow = 'showDiffFlow',
  ShowSendMessage = 'showSendMessage',
  ShowConnectorForm = 'showConnectForm',
  ShowConnectDeleteModal = 'showConnectDeleteModal',
  ShowMessageTracing = 'showMessageTracing',
  ShowBlockTesting = 'showBlockTesting',
  ShowBlockTestingDeleteModal = 'showBlockTestingDeleteModal',
  ShowTestForm = 'showTestForm',
  ShowCreateFlowForm = 'showCreateFlowForm',
  ShowCreateDashboardForm = 'showCreateDashboardForm',
  ShowDashboardPanelSettingsForm = 'showDashboardPanelSettingsForm',
  ShowEditFlowForm = 'showEditFlowForm',
  ShowEditDashboardForm = 'showEditDashboardForm',
  ShowFlowDeleteModal = 'showFlowDeleteModal',
  ShowDashboardDeleteModal = 'showDashboardDeleteModal',
  ShowCreateTFlowForm = 'showCreateTFlowForm',
  ShowEditTFlowForm = 'showEditTFlowForm',
  ShowTFlowDeleteModal = 'showTFlowDeleteModal',
  ShowMessageQueue = 'showMessageQueue',
  ShowExecutorLogBlock = 'showExecutorLogBlock',
  ShowMessageForm = 'showMessageForm',
  ShowLogSettings = 'showLogSettings',
  ShowMessageDeliverySettings = 'showMessageDeliverySettings',
  ShowCreatingTwoConnectsForm = 'showCreatingTwoConnectsForm',
  ShowPropertyForm = 'showPropertyForm',
  ShowClearLogsModal = 'showClearLogsModal',
  ShowFlowVersionDeleteModal = 'showFlowVersionDeleteModal',
  ShowFlowVersionForm = 'showFlowVersionForm',
  ShowFlowVersionList = 'showFlowVersionList',
  ShowFlowCloneModal = 'showFlowCloneModal',
  ShowAddPythonLibForm = 'showAddPythonLibForm',
  ShowPythonFilteredLibForm = 'showPythonFilteredLibForm',
  ShowEditPythonLibForm = 'showEditPythonLibForm',
  PythonPodsId = 'pythonPodsId',
  PythonLibName = 'PythonLibName',
  ShowPythonPodLog = 'ShowPythonPodLog',
  ShowExitConfirmModal = 'showExitConfirmModal',
  ShowMessageError = 'showMessageError',
  FlowId = 'flowId',
  DashboardId = 'dashboardId',
  DashboardPanelId = 'dashboardPanelId',
  BlockId = 'blockId',
  IsError = 'isError',
  TestId = 'testId',
  ItemId = 'itemId',
  MessageId = 'messageId',
  ErrorId = 'errorId',
  Statuses = 'statuses',
  ConnectorId = 'connectId',
  ConnectorName = 'connectName',
  ConnectorType = 'connectType',
  SourceId = 'sourceId',
  TargetId = 'targetId',
  PropertyId = 'propertyId',
  PythonScriptId = 'pythonScriptId',
  NotificationId = 'notificationId',
  CloseOnboarding = 'closeOnboarding',
  Search = 'search',
  FlowsMode = 'mode',
}

export type SearchParamsTemplateElement =
  | SearchParams
  | string
  | { [key in SearchParams | string]?: string };
export type SearchParamsTemplate = SearchParamsTemplateElement[];

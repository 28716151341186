import { FC, useCallback, useLayoutEffect } from 'react';

import { Sidebar } from '@/shared/ui/Sidebar/Sidebar';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import { MessageQueueService } from '@/entities/Block/services/MessageQueueService';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { MessageQueueList } from './MessageQueueList';
import { MessageQueueFilter } from '../features';

const messageQueueService = container.resolve(MessageQueueService);

export const MessageQueueSidebar: FC = observer(() => {
  const { get, append, getAll } = useSearchParamsTemplate();

  const blockId = get(SearchParams.BlockId);

  const handleMessageQueueFilterClose = useCallback(() => {
    append({ [SearchParams.Statuses]: messageQueueService.statusList });
  }, [append]);

  useLayoutEffect(() => {
    messageQueueService.statusList = getAll(SearchParams.Statuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sidebar
      title='Сообщения'
      showCreateButton={false}
      addonAfterTitle={
        <MessageQueueFilter blockId={blockId} onClose={handleMessageQueueFilterClose} />
      }
      addonAfter={
        <div style={{ height: 'calc(100vh - 264px)', display: 'flex', width: '100%' }}>
          <MessageQueueList blockId={blockId} />
        </div>
      }
    />
  );
});

import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import UploadFileJSON from '@/shared/ui/UploadFileJSON';
import { IntegrationTest } from '@/entities/Block/types';
import { UploadIcon } from '@/shared/ui/Icons/UploadIcon';

import { BlockTestingService } from '../../../services/BlockTestingService';

const blockTestingService = container.resolve(BlockTestingService);

export interface ImportTestsProps {
  blockId: string;
}

export const ImportTests: FC<ImportTestsProps> = ({ blockId }) => {
  const handleClick = useCallback(
    (integrationTestList: IntegrationTest[]) => {
      void blockTestingService.importAllTests({ integrationTestList, blockId });
    },
    [blockId]
  );

  return (
    <UploadFileJSON addFile={handleClick}>
      <Tooltip title='Импорт тестов' placement='top'>
        <span>
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            loading={blockTestingService.isLoadingImportTests}
          >
            <UploadIcon />
          </Button>
        </span>
      </Tooltip>
    </UploadFileJSON>
  );
};

import React, { FC } from 'react';

import { Route, Routes } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';

import { MonitoringModalRoute } from '../Monitoring/MonitoringModalRoute';
import { Tracing } from './Tracing';

export const TracingRoute: FC = () => {
  return (
    <Layout flex>
      <Routes>
        {/*<Route path='/:id/monitoring' element={<MonitoringModalRoute />} />*/}
        <Route index element={<Tracing />} />
      </Routes>
    </Layout>
  );
};

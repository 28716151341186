import { makeAutoObservable } from 'mobx';
import { container, singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { ConnectService } from '@/entities/Connect/services/ConnectService';
import { RegistrableValues } from '@/shared/lib/types';

const url = {
  input: 'connect',
  output: 'connect',
  processor: 'processor',
};

@singleton()
export class NotFoundConnectorService {
  connectorIsNotExist: boolean;

  constructor(private apiService: ApiService) {
    this.connectorIsNotExist = false;

    makeAutoObservable(this);
  }

  async checkConnectorExist(blockId: string, connectorId: string, type: string) {
    container.register(RegistrableValues.BlockId, { useValue: blockId });
    const connectService = container.resolve(ConnectService);

    try {
      await this.apiService.instance.get(
        `/editor/${url[type as keyof typeof url]}/${connectorId}/block/${blockId}`
      );

      this.connectorIsNotExist = false;
    } catch (error) {
      if (!connectService.getFormValues(type, connectorId)) {
        this.connectorIsNotExist = true;
      }
      throw error;
    }
  }
}

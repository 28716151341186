import { FC, useCallback } from 'react';

import Button from '@/shared/ui/Button';
import { Box } from '@mui/system';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Tooltip from '@/shared/ui/Tooltip';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';

import { DeletePropertyService } from '../api/DeletePropertyService';

const deletePropertyService = container.resolve(DeletePropertyService);

export interface DeletePropertyButtonProps {
  id: string;
}

export const DeletePropertyButton: FC<DeletePropertyButtonProps> = observer(({ id }) => {
  const handleClick = useCallback(() => {
    deletePropertyService.open = true;
    deletePropertyService.selectedId = id;
  }, [id]);

  return (
    <Tooltip title='Удалить конфигурацию' placement='top'>
      <Box>
        <Button
          circle
          size={ButtonSizes.Small}
          variant={ButtonVariants.Outlined}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </Box>
    </Tooltip>
  );
});

DeletePropertyButton.displayName = 'DeletePropertyButton';

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';

import { updateStateCache } from '../store/cache/action';

const useRedux = (key, defaultValue) => {
  const dispatch = useDispatch();
  const value = useSelector((state) => {
    return _.get(state.cache.states, key);
  });

  useEffect(() => {
    if (value === undefined || value === null) {
      dispatch(updateStateCache(key, defaultValue));
    }
  }, []);

  const setValue = (newValue) => {
    dispatch(updateStateCache(key, newValue));
  };

  return [value, setValue];
};

export default useRedux;

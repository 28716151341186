import { FC } from 'react';

import styles from './Loader.module.scss';
import {LoaderIcon} from "@/shared/ui/Icons/LoaderIcon/LoaderIcon";

export interface LoaderProps {
  width?: number;
  height?: number;
}

export const Loader: FC<LoaderProps> = ({ width = 20, height = 20 }) => {
  return (
      <div className={styles.root}>
          <LoaderIcon width={width} height={height} />
      </div>
  );
};

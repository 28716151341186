import { FC, SVGProps } from 'react';

export const UploadIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width='16'
      height='17'
      viewBox='0 0 16 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8.23352 15.6523L8.23352 5.38568'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M3.56706 10.0527L8.23372 5.38607L12.9004 10.0527'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M1 1.65234L15 1.65234'
        stroke='currentColor'
        strokeWidth='1.86667'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

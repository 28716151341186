import { injectable } from 'tsyringe';
import { JoyRideStore } from '@/entities/JoyRideOnboarding/model/JoyRideStore';
import { makeAutoObservable } from 'mobx';

export interface saveSteps {
  run: boolean;
  stepIndex: number;
}

@injectable()
export class JoyRideService {
  constructor(private joyrideStore: JoyRideStore) {
    makeAutoObservable(this);
  }

  handleJoyRide = (run: boolean) => {
    this.joyrideStore.run = run;
  };

  nextStep = () => {
    if (this.joyrideStore.currentStep < this.joyrideStore.steps.length - 1) {
      this.joyrideStore.currentStep += 1;
    }
  };

  handleClose = (close: boolean) => {
    this.joyrideStore.closeOnboarding = close;
    if (!close) {
      this.updateCurrentStep(this.stepIndex + 1);
    }
  };

  handleIsTourEnded = (end: boolean) => {
    this.joyrideStore.isTourEnded = end;
  };

  get isTourEnded(): boolean {
    return this.joyrideStore.isTourEnded;
  }

  get isClose(): boolean {
    return this.joyrideStore.closeOnboarding;
  }

  handleOpen = (open: boolean) => {
    this.joyrideStore.openOnboarding = open;
  };

  get isOpen(): boolean {
    return this.joyrideStore.openOnboarding;
  }

  set isOpen(value: boolean) {
    this.joyrideStore.openOnboarding = value;
  }

  get currentStep(): number {
    return this.joyrideStore.currentStep;
  }

  get run(): boolean {
    return this.joyrideStore.run;
  }

  get stepIndex(): number {
    return this.joyrideStore.stepIndex;
  }

  get steps() {
    return this.joyrideStore.steps;
  }

  updateCurrentStep = (stepIndex: number): void => {
    this.joyrideStore.stepIndex = stepIndex;
  };

  pause() {
    this.updateCurrentStep(this.stepIndex - 1);
  }

  unpause() {
    this.updateCurrentStep(this.stepIndex);
  }

  setContinuingOnboarding = (run: boolean, stepIndex: number): void => {
    this.joyrideStore.run = run;
    this.joyrideStore.stepIndex = stepIndex;
  };

  startOnboarding = () => {
    this.joyrideStore.run = true;
  };

  stopOnboarding = () => {
    this.joyrideStore.run = false;
  };
}

import { FC, useEffect } from 'react';

import Stack from '@mui/system/Stack';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { CodeEditor } from '@/shared/ui';
import { AcceptPythonBeanButton } from '@/features/UpsertPythonBean';
import Box from '@mui/system/Box';
import { PythonBeanStore, PythonScript } from '@/entities/PythonBean';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { DeletePythonBeanButton } from '@/features/DeletePythonBean';
import { ChoosePythonBeanButton } from '@/features/ChoosePythonScript';
import { yupResolver } from '@hookform/resolvers/yup';
import { nameAndDescriptionFieldsValidation } from '@/shared/lib';

import { UpsertPythonBeanService } from '../api/UpsertPythonBeanService';

const pythonBeanStore = container.resolve(PythonBeanStore);
const upsertPythonBeanService = container.resolve(UpsertPythonBeanService);

export interface UpsertPythonBeanFormProps {
  pythonScriptId?: string;
  hideChooseScriptButton?: boolean;

  onSuccess?(pythonScript: PythonScript): void;
}

export const UpsertPythonBeanForm: FC<UpsertPythonBeanFormProps> = observer(
  ({ pythonScriptId, onSuccess, hideChooseScriptButton }) => {
    const {
      control,
      register,
      handleSubmit,
      formState: { errors, isValid },
      reset,
    } = useForm<PythonScript>({
      values: pythonBeanStore.scripts?.entities[pythonScriptId],
      defaultValues: {
        name: '',
        code: '',
        description: '',
      },
      resolver: yupResolver(nameAndDescriptionFieldsValidation),
      mode: 'onChange',
    });

    const onValid: SubmitHandler<PythonScript> = (data) => {
      upsertPythonBeanService
        .upsertPythonBean({ formOfCreate: !pythonScriptId, request: data })
        .then(onSuccess);
    };

    useEffect(() => {
      if (!pythonScriptId) {
        reset({
          name: '',
          code: '',
          description: '',
        });
      }
    }, [pythonScriptId, reset]);

    return (
      <Box component='form' flexDirection='column' display='flex' onSubmit={handleSubmit(onValid)}>
        <Stack flex='auto' paddingX={2.5} gap={2.5} justifyContent='space-between'>
          <Stack flex='auto'>
            <Stack gap={2.5}>
              <FieldInput
                label='Наименование'
                description='Введите название, длиной не более 100 символов'
                error={errors.name}
                name='name'
                register={register}
                localeName='name'
              />
              <FieldInput
                textarea
                label='Описание'
                description='Введите описание, длиной не более 1000 символов'
                error={errors.description}
                name='description'
                register={register}
                localeName='description'
              />
              <Controller
                name='code'
                control={control}
                render={({ field, formState }) => {
                  return (
                    <CodeEditor
                      key={formState.defaultValues?.code}
                      height='293px'
                      language='python'
                      value={field.value || ''}
                      onChange={(value) => field.onChange(value)}
                    />
                  );
                }}
              />
            </Stack>
          </Stack>
          <Stack direction='row' justifyContent='space-between'>
            <Box width={212}>{pythonScriptId && <DeletePythonBeanButton formButton />}</Box>
            <Stack direction='row' gap={2.5}>
              {!hideChooseScriptButton && (
                <Box width={212}>
                  <ChoosePythonBeanButton pythonScriptId={pythonScriptId} />
                </Box>
              )}
              <Box width={212}>
                <AcceptPythonBeanButton
                  disabled={!isValid}
                  type='submit'
                  pythonScriptId={pythonScriptId}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    );
  }
);

UpsertPythonBeanForm.displayName = 'UpsertPythonBeanForm';

import React, { FC, useCallback } from 'react';

import { bindMenu } from 'material-ui-popup-state';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import {
  ToggleConnectorMenuItem,
  ToggleConnectorMenuItemProps,
} from '@/features/ToggleConnector';

interface ConnectorContextMenuProps extends ToggleConnectorMenuItemProps {
  children: (popupState: any) => JSX.Element;
  onEdit: () => void;
  onDelete: () => void;
}

export const ConnectorContextMenu: FC<ConnectorContextMenuProps> = ({
  children,
  connectorId,
  blockId,
  connectorType,
  isActiveConnector,
  onEdit,
  onDelete,
}) => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'demo-popup-menu' });

  const handleToggle = useCallback(
    (popupState: PopupState) => () => {
      popupState.close();
    },
    []
  );

  const handleEdit = useCallback(
    (popupState: PopupState) => () => {
      onEdit();
      popupState.close();
    },
    [onEdit]
  );

  const handleDelete = useCallback(
    (popupState: PopupState) => () => {
      onDelete();
      popupState.close();
    },
    [onDelete]
  );

  return (
    <>
      {children(popupState)}
      <Menu {...bindMenu(popupState)} elevation={0}>
        <ToggleConnectorMenuItem
          connectorId={connectorId}
          isActiveConnector={isActiveConnector}
          connectorType={connectorType}
          blockId={blockId}
          onClick={handleToggle(popupState)}
        />
        <MenuItem onClick={handleEdit(popupState)}>Редактировать</MenuItem>
        <MenuItem onClick={handleDelete(popupState)}>Удалить</MenuItem>
      </Menu>
    </>
  );
};

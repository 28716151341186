export const deleteAllSearchParams = (
  searchParams: URLSearchParams,
  except?: string[]
): URLSearchParams => {
  Array.from(searchParams.entries()).forEach(([key]) => {
    if (!except?.includes(key)) {
      searchParams.delete(key);
    }
  });

  return searchParams;
};

import { createBrowserRouter, Navigate } from 'react-router-dom';
import { Layout } from '@/shared/ui/Layout/Layout';
import { TracingRoute } from '@/entities/Tracing/TracingRoute';
import { flowRoutes } from '@/entities/Flow/routes';
import Onboarding from '@/entities/Onboarding/Onboarding';
import { propertyRoutes } from '@/app/routes/propertyRoutes';
import { PropertiesPage, PropertyContentPage } from '@/pages';
import { flowVersionsRoutes } from '@/app/routes/flowVersionsRoutes';
import { SettingsPage } from '@/pages/SettingsPage';
import { RoutePaths } from '@/shared/lib/types';
import {ExecutorLogRoute} from "@/entities/ExecutorLog/ExecutorLogRoute";
import {dashboardRoutes} from "@/entities/Dashboard/DashboardRoute";

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to='/flow' replace />,
  },
  {
    path: 'settings/*',
    element: (
      <Layout flex>
        <SettingsPage />
      </Layout>
    ),
  },
  {
    path: 'tracing/*',
    element: <TracingRoute />,
  },
  {
    path: 'logs/*',
    element: <ExecutorLogRoute />,
  },
  {
    path: RoutePaths.Properties,
    element: <PropertiesPage />,
    children: [{ path: ':propertyId', element: <PropertyContentPage /> }],
  },
  {
    path: RoutePaths.Onboarding,
    element: <Onboarding />,
  },
  ...flowRoutes,
  ...propertyRoutes,
  ...flowVersionsRoutes,
  ...dashboardRoutes,
]);

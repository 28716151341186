import { Segment } from '../enums';
import { YearValues } from '../segment-values';
import { BaseContainer } from './base.abstract';
export class YearContainer extends BaseContainer {
    getSegment() {
        return Segment.year;
    }
    cloneWith(d) {
        return new YearContainer(d);
    }
    createValues(values) {
        return new YearValues(values);
    }
}

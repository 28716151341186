import { parseExpression, stringifyDataModel, getSegmentsList, createValue } from '../utils';
import { Mode, MonthCode, Segment } from '../enums';
import { DataModel } from '../data.model';
import { applyDataModel, isSelectedSegment, selectSegmentMode, getSegmentValues, hasAndValue, toggleAndValue, setInValue } from './utils';
import { ViewData } from './view-data.model';
export class CronUIBaseService {
    constructor(renderYearsFrom) {
        this.renderYearsFrom = renderYearsFrom;
        this.listeners = getSegmentsList().reduce((a, s) => (Object.assign(Object.assign({}, a), { [s]: [] })), {});
        this.disabled = false;
        this.view = this.createDefaultView();
    }
    destroy() {
        this.listeners = {};
    }
    listen(segments, cb) {
        segments.forEach(s => {
            this.listeners[s].push(cb);
        });
        return () => {
            segments.forEach(s => {
                const listeners = this.listeners[s] || [];
                this.listeners[s] = listeners.filter(c => c !== cb);
            });
        };
    }
    toString() {
        const model = new DataModel(this.view.getSelected());
        return stringifyDataModel(model, this.cronType);
    }
    fillFromExpression(expression) {
        expression = expression.replace(/[ ]{2,}/g, ' ');
        const data = parseExpression(expression, this.cronType);
        this.view = applyDataModel(this.view, data);
        const segments = getSegmentsList();
        this.emitListener(segments);
    }
    setDisabled(disabled = false) {
        this.disabled = disabled;
        const segments = getSegmentsList();
        this.emitListener(segments);
    }
    isDisabled(mode, segment) {
        let disabled = this.disabled;
        if (segment && mode) {
            const view = this.view.get(segment);
            disabled = disabled || view.selected !== mode;
        }
        return disabled;
    }
    selectDaySegment(mode, segment, reset) {
        this.selectSegment(segment, mode);
        if (reset === Segment.dayOfMonth) {
            this.selectSegment(Segment.dayOfMonth, Mode.NONE);
        }
        if (reset === Segment.dayOfWeek) {
            this.selectSegment(Segment.dayOfWeek, Mode.NONE);
        }
    }
    setInValue(mode, index, value, segment) {
        const container = setInValue(this.view, mode, index, value, segment);
        this.setSegmentView(container);
    }
    isSelectedSegment(segment, mode) {
        return isSelectedSegment(this.view, segment, mode);
    }
    getSegmentValues(segment, mode) {
        return getSegmentValues(this.view, segment, mode);
    }
    getDayApi() {
        return {
            // Every
            isEverySelected: () => this.isSelectedSegment(Segment.dayOfWeek, Mode.EVERY),
            selectEvery: () => this.selectDaySegment(Mode.EVERY, Segment.dayOfWeek, Segment.dayOfMonth),
            // Every 5 day(s) starting on Monday
            isDayOfWeekIncrementSelected: () => this.isSelectedSegment(Segment.dayOfWeek, Mode.INCREMENT),
            selectDayOfWeekIncrement: () => this.selectDaySegment(Mode.INCREMENT, Segment.dayOfWeek, Segment.dayOfMonth),
            isDayOfWeekIncrementControlsDisabled: () => this.isDisabled(Mode.INCREMENT, Segment.dayOfWeek),
            getDayOfWeekIncrementPrimary: () => this.getSegmentValues(Segment.dayOfWeek, Mode.INCREMENT)[1],
            setDayOfWeekIncrementPrimary: (value) => this.setInValue(Mode.INCREMENT, 1, value, Segment.dayOfWeek),
            getDayOfWeekIncrementSecondary: () => this.getSegmentValues(Segment.dayOfWeek, Mode.INCREMENT)[0],
            setDayOfWeekIncrementSecondary: (value) => this.setInValue(Mode.INCREMENT, 0, value, Segment.dayOfWeek),
            // Every 3 day(s) starting on the 4th of the month
            isDayOfMonthIncrementSelected: () => this.isSelectedSegment(Segment.dayOfMonth, Mode.INCREMENT),
            selectDayOfMonthIncrement: () => this.selectDaySegment(Mode.INCREMENT, Segment.dayOfMonth, Segment.dayOfWeek),
            isDayOfMonthIncrementControlsDisabled: () => this.isDisabled(Mode.INCREMENT, Segment.dayOfMonth),
            getDayOfMonthIncrementPrimary: () => this.getSegmentValues(Segment.dayOfMonth, Mode.INCREMENT)[1],
            setDayOfMonthIncrementPrimary: (value) => this.setInValue(Mode.INCREMENT, 1, value, Segment.dayOfMonth),
            getDayOfMonthIncrementSecondary: () => this.getSegmentValues(Segment.dayOfMonth, Mode.INCREMENT)[0],
            setDayOfMonthIncrementSecondary: (value) => this.setInValue(Mode.INCREMENT, 0, value, Segment.dayOfMonth),
            // Specific day of week (choose one or many)
            isDayOfWeekAndSelected: () => this.isSelectedSegment(Segment.dayOfWeek, Mode.AND),
            selectDayOfWeekAnd: () => this.selectDaySegment(Mode.AND, Segment.dayOfWeek, Segment.dayOfMonth),
            isDayOfWeekAndControlsDisabled: () => this.isDisabled(Mode.AND, Segment.dayOfWeek),
            isSelectedDayOfWeekAndValue: (value) => this.hasAndValue(value, Segment.dayOfWeek),
            selectDayOfWeekAndValue: (value) => this.toggleAndValue(value, Segment.dayOfWeek),
            // Specific day of month (choose one or many)
            isDayOfMonthAndSelected: () => this.isSelectedSegment(Segment.dayOfMonth, Mode.AND),
            selectDayOfMonthAnd: () => this.selectDaySegment(Mode.AND, Segment.dayOfMonth, Segment.dayOfWeek),
            isDayOfMonthAndControlsDisabled: () => this.isDisabled(Mode.AND, Segment.dayOfMonth),
            isSelectedDayOfMonthAndValue: (value) => this.hasAndValue(value, Segment.dayOfMonth),
            selectDayOfMonthAndValue: (value) => this.toggleAndValue(value, Segment.dayOfMonth)
        };
    }
    getCommonApi(segment) {
        return {
            // Every
            isEverySelected: () => this.isSelectedSegment(segment, Mode.EVERY),
            selectEvery: () => this.selectSegment(segment, Mode.EVERY),
            // Every 2 hour(s) starting at hour 3
            isIncrementSelected: () => this.isSelectedSegment(segment, Mode.INCREMENT),
            selectIncrement: () => this.selectSegment(segment, Mode.INCREMENT),
            isIncrementControlsDisabled: () => this.isDisabled(Mode.INCREMENT, segment),
            getIncrementPrimaryValue: () => this.getSegmentValues(segment, Mode.INCREMENT)[1],
            setIncrementPrimaryValue: (value) => this.setInValue(Mode.INCREMENT, 1, value, segment),
            // Specific hour (choose one or many)
            isAndSelected: () => this.isSelectedSegment(segment, Mode.AND),
            selectAnd: () => this.selectSegment(segment, Mode.AND),
            isAndControlsDisabled: () => this.isDisabled(Mode.AND, segment),
            isSelectedAndValue: (value) => this.hasAndValue(value, segment),
            selectAndValue: (value) => this.toggleAndValue(value, segment),
            // Every hour between hour 0 and hour
            isRangeSelected: () => this.isSelectedSegment(segment, Mode.RANGE),
            selectRange: () => this.selectSegment(segment, Mode.RANGE),
            isRangeControlsDisabled: () => this.isDisabled(Mode.RANGE, segment),
            getRangePrimaryValue: () => this.getSegmentValues(segment, Mode.RANGE)[0],
            setRangePrimaryValue: (value) => this.setInValue(Mode.RANGE, 0, value, segment),
            getRangeSecondaryValue: () => this.getSegmentValues(segment, Mode.RANGE)[1],
            setRangeSecondaryValue: (value) => this.setInValue(Mode.RANGE, 1, value, segment)
        };
    }
    setSegmentView(container) {
        const segment = container.getSegment();
        this.view = this.view.set(container);
        this.emitListener([segment]);
    }
    hasAndValue(value, segment) {
        return hasAndValue(this.view, value, segment);
    }
    selectSegment(segment, mode) {
        const container = selectSegmentMode(this.view, segment, mode);
        if (!container) {
            return;
        }
        this.setSegmentView(container);
    }
    toggleAndValue(value, segment) {
        const container = toggleAndValue(this.view, value, segment);
        if (!container) {
            return false;
        }
        this.setSegmentView(container);
        return true;
    }
    emitListener(segments) {
        segments.forEach(s => {
            const view = this.view.get(s);
            const cbs = this.listeners[s] || [];
            cbs.forEach(cd => cd(view, s));
        });
    }
    createValue(mode, values) {
        return {
            [mode]: createValue(mode, values)
        };
    }
    createDefaultView() {
        const currentYear = new Date().getFullYear();
        const defaultYear = (this.renderYearsFrom || currentYear).toString();
        return new ViewData({
            [Segment.seconds]: {
                selected: Mode.AND,
                values: Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, ['0'])), this.createValue(Mode.RANGE, ['0', '0'])), this.createValue(Mode.INCREMENT, ['0', '1'])), this.createValue(Mode.EVERY, []))
            },
            [Segment.minutes]: {
                selected: Mode.AND,
                values: Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, ['0'])), this.createValue(Mode.RANGE, ['0', '0'])), this.createValue(Mode.INCREMENT, ['0', '1'])), this.createValue(Mode.EVERY, []))
            },
            [Segment.hours]: {
                selected: Mode.AND,
                values: Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, ['0'])), this.createValue(Mode.RANGE, ['0', '0'])), this.createValue(Mode.INCREMENT, ['0', '1'])), this.createValue(Mode.EVERY, []))
            },
            [Segment.month]: {
                selected: Mode.EVERY,
                values: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, [MonthCode.JAN])), this.createValue(Mode.RANGE, ['1', '1'])), this.createValue(Mode.INCREMENT, ['1', '1'])), this.createValue(Mode.EVERY, [])), this.createValue(Mode.NONE, []))
            },
            [Segment.dayOfMonth]: {
                selected: Mode.NONE,
                values: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, ['1'])), this.createValue(Mode.LAST_DAY, [])), this.createValue(Mode.NEAREST_WEEKDAY_OF_MONTH, ['1W'])), this.createValue(Mode.DAYS_BEFORE_END_MONTH, ['L-1'])), this.createValue(Mode.LAST_DAY_WEEK, [])), this.createValue(Mode.RANGE, ['0', '0'])), this.createValue(Mode.INCREMENT, ['1', '1'])), this.createValue(Mode.EVERY, [])), this.createValue(Mode.NONE, []))
            },
            [Segment.dayOfWeek]: {
                selected: Mode.NONE,
                values: Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, ['SUN'])), this.createValue(Mode.INCREMENT, ['1', '1'])), this.createValue(Mode.NTH_WEEKDAY_OF_MONTH, ['1', '1'])), this.createValue(Mode.LAST_NTH_DAY_WEEK, ['1L'])), this.createValue(Mode.RANGE, ['SUN', 'MON'])), this.createValue(Mode.EVERY, [])), this.createValue(Mode.NONE, []))
            },
            [Segment.year]: {
                selected: Mode.EVERY,
                values: Object.assign(Object.assign(Object.assign(Object.assign({}, this.createValue(Mode.AND, [defaultYear])), this.createValue(Mode.RANGE, [defaultYear, defaultYear])), this.createValue(Mode.INCREMENT, [defaultYear, '1'])), this.createValue(Mode.EVERY, []))
            }
        });
    }
}

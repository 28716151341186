import React, { FC, PropsWithChildren } from 'react';

import { Stack } from '@mui/material';
import cn from 'classnames';

import styles from './styles.module.scss';

interface Props {
  className?: string;
}

export const Paper: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return (
    <Stack component='div' className={cn(styles.root, className)}>
      {children}
    </Stack>
  );
};

import React, { FC, useMemo } from 'react';
import { observer } from 'mobx-react-lite';

import { Stack } from '@mui/system';
import { container } from 'tsyringe';
import { Controller, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';
import { PythonLibsStore } from '@/entities/PythonLib/stores/PythonLibsStore';
import { Autocomplete } from '@/shared/ui/Autocomplete/Autocomplete';
import { Filter } from '@/shared/ui';
import { PythonLibFilters, PythonLibsRequest } from '@/entities/PythonLib/stores/types';

const pythonLibsService = container.resolve(PythonLibsService);
const pythonLibsStore = container.resolve(PythonLibsStore);
export const PythonLibFilter: FC = observer(() => {
  const { control, handleSubmit, reset, getValues } = useForm();
  const libsPython = JSON.parse(JSON.stringify(pythonLibsStore.libs))?.data;
  const isActive = Boolean(pythonLibsService.filteredLibs?.length);
  const { title } = getValues();

  const option = libsPython?.map((item: PythonLibsRequest) => item.title);

  const tooltip = useMemo(
    () => (
      <Stack gap={1}>
        <p>Фильтрация</p>
        {title && (
          <Stack>
            <p>По названию библиотеки:</p>
            {title?.map((item: string, index: number) => (
              <p key={`${item}${index}`}>{item}</p>
            ))}
          </Stack>
        )}
      </Stack>
    ),
    [title]
  );
  const handleSearch = (data: PythonLibFilters): PythonLibsRequest[] => {
    if (isEmpty(data)) return null;

    pythonLibsService.setFilteredLibs(data);
  };

  return (
    <Filter
      python='python'
      isActive={isActive}
      title='Фильтр'
      tooltip={tooltip}
      onFilter={handleSubmit(handleSearch)}
      onReset={() => {
        reset();
        reset((formValues) => ({ ...formValues, title: '' }));
        pythonLibsService.resetFilter();
        pythonLibsService.getPythonLibs();
      }}
    >
      <form id='filterForm'>
        <Stack gap={5}>
          <Stack>
            <Controller
              name='title'
              control={control}
              render={({ field }) => {
                return (
                  <Autocomplete
                    multiple
                    freeSolo
                    autoComplete
                    filterSelectedOptions
                    label='Название'
                    options={option || []}
                    value={field.value || []}
                    onChange={(event, value) => {
                      field.onChange(value);
                    }}
                  />
                );
              }}
            />
          </Stack>
        </Stack>
      </form>
    </Filter>
  );
});

PythonLibFilter.displayName = 'PythonLibFilter';

import { FC, memo, useCallback, useContext } from 'react';

import Grid from '@mui/system/Unstable_Grid';
import Stack from '@mui/system/Stack';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import { Controller, useFieldArray } from 'react-hook-form';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Box } from '@mui/system';

import { MapArray } from '@/shared/ui/Fields/components/MappingData/MapArray';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import {
  arrayEmptyRule,
  arrayStatusByObject,
  arrayType,
} from '@/shared/ui/Fields/components/MappingData/constants';
import { MapPrimitives } from '@/shared/ui/Fields/components/MappingData/MapPrimitives';
import { DraggableItem, DraggableList } from "@/shared/ui/DraggableList";
import { amendDraggable } from "@/shared/lib/extendDndkit";
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import Button from '@/shared/ui/Button';
import { MapDate } from '@/shared/ui/Fields/components/MappingData/MapDate';
import Tooltip from '@/shared/ui/Tooltip';
import { Summary } from '@/shared/ui/Fields/components/MappingData/ui/Summary/Summary';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';

import { FieldInput } from '../FieldInput/FieldInput';
import { CopyPasteContext } from './lib/CopyPasteContext';

export interface MapObjectProps {
  control: any;
  name: string;
  register: any;
  draggable?: boolean;
}

export const MapObject: FC<MapObjectProps> = memo(({ control, name, register, draggable = false }) => {
  const { fields, append, remove, move } = useFieldArray({
    name,
    control,
  });

  const { copiedValue, setCopiedValue } = useContext(CopyPasteContext);

  const form = useCallback(
    (index: number, type: string) => {
      switch (type) {
        case 'string':
        case 'number':
        case 'boolean':
        case 'float': {
          return <MapPrimitives name={name} control={control} index={index} />;
        }
        case 'array': {
          return <MapArray control={control} name={name} index={index} register={register} />;
        }
        case 'object': {
          return (
            <MapObject control={control} name={`${name}.${index}.children`} register={register} />
          );
        }
        case 'date': {
          return <MapDate control={control} index={index} name={name} register={register} />;
        }
        default: {
          return <p>ads</p>;
        }
      }
    },
    [control, name, register]
  );
  const renderItem = ({ id }: { id: string }, index: number) => (
    <DraggableItem
      key={id}
      id={id}
      draggable={draggable}
    >
      <Accordion key={id} disableGutters>
        <AccordionSummary sx={{ width: '100%' }} expandIcon={<ArrowRightIcon {...amendDraggable(true)} />}>
          <Stack width='100%' direction='row' gap={2.5} alignItems='center'>
            <Summary control={control} name={`${name}.${index}`} />
            <Button
              circle
              size={ButtonSizes.Small}
              variant={ButtonVariants.Outlined}
              onClick={() => {
                remove(index);
              }}
              {...amendDraggable(true)}
            >
              <TrashIcon />
            </Button>
          </Stack>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2.5} marginLeft={2} alignItems='flex-end' {...amendDraggable(true)}>
            <Grid xs={12} sm={6} lg={4} xl={3}>
              <FieldInput
                label='Название свойства'
                key={id}
                error=''
                name={`${name}.${index}.targetName`}
                register={register}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4} xl={3}>
              <FieldInput
                label='Путь до значения'
                key={id}
                error=''
                name={`${name}.${index}.sourceName`}
                register={register}
              />
            </Grid>
            <Grid xs={12} sm={6} lg={4} xl={3}>
              <FieldSelect
                label='Тип данных'
                control={control}
                options={arrayType}
                error=''
                name={`${name}.${index}.type`}
              />
            </Grid>
            <Controller
              control={control}
              name={`${name}.${index}.type`}
              render={({ field }) => {
                return (
                  <>
                    {field.value === 'object' && (
                      <>
                        <Grid xs={12} sm={6} lg={4} xl={3}>
                          <FieldSelect
                            label='Действие'
                            control={control}
                            options={arrayStatusByObject}
                            error=''
                            name={`${name}.${index}.status`}
                          />
                        </Grid>
                        <Grid xs={12} sm={6} lg={4} xl={3}>
                          <FieldSelect
                            label='Если пустой'
                            control={control}
                            options={arrayEmptyRule}
                            error=''
                            name={`${name}.${index}.emptyRule`}
                          />
                        </Grid>
                      </>
                    )}
                    {form(index, field.value)}
                  </>
                );
              }}
            />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </DraggableItem>
  );

  return (
    <Grid xs={12}>
      <Grid container>
        <Grid xs={12}>
          <Stack gap={2.5}>
            <DraggableList
              items={fields}
              draggable={draggable}
              onReorder={move}
              renderItem={renderItem}
            />
            <Stack direction='row' gap={1.5}>
              <Tooltip title='Добавить'>
                <Box paddingLeft={1.5} width='max-content'>
                  <Button
                    circle
                    variant={ButtonVariants.Outlined}
                    size={ButtonSizes.Small}
                    onClick={() =>
                      append({
                        targetName: '',
                        sourceName: '',
                        type: 'string',
                        status: 'OK',
                        emptyRule: '',
                      })
                    }
                  >
                    <PlusIcon />
                  </Button>
                </Box>
              </Tooltip>
              {copiedValue && (
                <Tooltip title='Вставить'>
                  <Box width='max-content'>
                    <Button
                      circle
                      variant={ButtonVariants.Outlined}
                      size={ButtonSizes.Small}
                      onClick={() => {
                        append(copiedValue);
                        setCopiedValue(undefined);
                      }}
                    >
                      <ContentPasteOutlinedIcon />
                    </Button>
                  </Box>
                </Tooltip>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
});

MapObject.displayName = 'MapObject';

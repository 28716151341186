import React, {memo, useCallback, useState} from 'react';

import {OutlinedTextFieldProps} from '@mui/material';

import styles from './TextFieldSearchParams.module.scss';
import TextField from "@mui/material/TextField";
import {useSearchParamsTemplate} from "@/hooks/useTemplateSearchParams";
import {Input} from "@/shared/ui";

interface Props {
    label?: string;
    name: string;
    InputProps: object;
}

export const TextFieldSearchParams = memo<Props>(({label, name = "undefinedName", ...other}) => {
    const {get, set, remove} = useSearchParamsTemplate();
    const valueSearchParam = get(name);
    const [value, setValue] = useState(valueSearchParam)

    const handleChange = useCallback(
        (e: any) => {
            let newValue = e.target.value.toString()
            setValue(newValue)
        },
        [name, set]
    );

    const saveToSearchParam = () => {
        let newValue = value.toString()
        if (newValue) {
            set([name, {[name]: newValue}]);
        } else {
            remove([name])
        }
    }

    const onEnter = (e:any) => {
        if(e.keyCode == 13) {
            saveToSearchParam()
        }
    }

    return (
        <Input
            className={styles.root}
            label={label}
            value={value}
            onChange={handleChange}
            onBlur={saveToSearchParam}
            onKeyDown={onEnter}
            {...other}
        />
    );
});

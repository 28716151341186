import { makeAutoObservable } from 'mobx';
import { injectable } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { ConnectStore } from '@/entities/Connect/stores/ConnectStore';
import { Connect } from '@/entities/Connect/types';
import _ from 'lodash';
import { ConnectService } from '@/entities/Connect/services/ConnectService';

@injectable()
export class ConnectorUnsavedChangesService {
  constructor(
    private apiService: ApiService,
    private connectorStore: ConnectStore,
    private connectorService: ConnectService
  ) {
    makeAutoObservable(this);
  }

  get areThereChanges() {
    return this.connectorStore.areThereChanges;
  }

  set areThereChanges(value) {
    this.connectorStore.areThereChanges = value;
  }

  checkChanges(value: Connect, connectorType: string, connectorId: string) {
    this.areThereChanges = !_.isEqual(
      _.cloneDeep(this.connectorService.getFormValues(connectorType, connectorId)),
      _.cloneDeep(value)
    );
  }
}

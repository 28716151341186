import { FC, memo } from 'react';

import MuiDialogTitle, { DialogTitleProps as MuiDialogTitleProps } from '@mui/material/DialogTitle';
import cn from 'classnames';
import Stack from '@mui/system/Stack';
import { ButtonClose } from '@/shared/ui/Button/ButtonClose/ButtonClose';
import Box from '@mui/system/Box';

import styles from './Dialog.module.scss';

export interface DialogTitleProps extends MuiDialogTitleProps {
  onClose(): void;
}

export const DialogTitle: FC<DialogTitleProps> = memo(
  ({ className, children, onClose, ...props }) => {
    return (
      <MuiDialogTitle {...props} className={cn(styles.root, className)}>
        <Stack direction='row' alignItems='center' gap={2.5} justifyContent='space-between'>
          <Box component='p' overflow='hidden' textOverflow='ellipsis'>
            {children}
          </Box>
          <ButtonClose onClose={onClose} />
        </Stack>
      </MuiDialogTitle>
    );
  }
);

import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { observer } from 'mobx-react-lite';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { container } from 'tsyringe';
import { notify } from '@/shared/ui/Toast/notify';
import _ from 'lodash';
import { ExternalAppStore } from '@/entities/ExternalApp';

import { DeleteExternalAppService } from '../api/DeleteExternalAppService';

const deleteExternalAppService = container.resolve(DeleteExternalAppService);
const externalAppStore = container.resolve(ExternalAppStore);

export interface DeleteExternalAppDialogProps {
  onSuccess?(): void;
}

export const DeleteExternalAppDialog: FC<DeleteExternalAppDialogProps> = observer(
  ({ onSuccess }) => {
    const [isLoading, setIsLoading] = useState(false);

    const userName = _.get(
      externalAppStore.externalApps?.entities[deleteExternalAppService.selectedId || ''],
      'name',
      ''
    );

    const handleClose = useCallback(() => {
      deleteExternalAppService.open = false;
    }, []);

    const handleOkButtonClick = useCallback(async () => {
      try {
        setIsLoading(true);

        await deleteExternalAppService.deleteExternalApp();

        notify.success('Внешний сервис удален');
        handleClose();
        onSuccess?.();
      } catch {
        notify.error('Не удалось удалить внешний сервис');
      } finally {
        setIsLoading(false);
      }
    }, [handleClose, onSuccess]);

    return (
      <Dialog fullWidth open={deleteExternalAppService.open} onClose={handleClose}>
        <DialogTitle onClose={handleClose}>Удаление внешнего сервиса</DialogTitle>
        <DialogContent>Вы точно хотите удалить внешний сервис {userName}?</DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button variant={ButtonVariants.Popup} loading={isLoading} onClick={handleOkButtonClick}>
            Удалить
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
);

DeleteExternalAppDialog.displayName = 'DeleteExternalAppDialog';

import { makeAutoObservable, runInAction } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { Notification, NotificationStore } from '@/entities/Notification';

@singleton()
export class UpsertNotificationService {
  constructor(private apiService: ApiService, private notificationStore: NotificationStore) {
    makeAutoObservable(this);
  }

  async upsertNotification(notification: Notification) {
    const response = await this.apiService.instance.put<Notification>(
      '/editor/notification',
      notification
    );

    if (notification.id) {
      this.notificationStore.notifications!.entities[response.data.id] = response.data;
    } else {
      if (this.notificationStore.notifications?.entities) {
        runInAction(() => {
          this.notificationStore.notifications!.ids.unshift(response.data.id);
          this.notificationStore.notifications!.entities[response.data.id] = response.data;
        });
      } else {
        this.notificationStore.notifications = {
          ids: [response.data.id],
          entities: { [response.data.id]: response.data },
        };
      }
    }

    return response.data;
  }
}

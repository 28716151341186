import * as yup from 'yup';
import { Notification } from '@/entities/Notification';

export const validateForm = yup.object<Notification>({
  flowIdList: yup.array(yup.string()).default([]),
  // emailList: yup.array(yup.string().email('Введите правильный email')).nullable(),
  // cc: yup.array(yup.string().email('Введите правильный email')).nullable(),
  // bb: yup.array(yup.string().email('Введите правильный email')).nullable(),
  // replyTo: yup.string().email('Введите правильный email'),
  interval: yup
    .number()
    .default(0)
    .typeError('Необходимо ввести число')
    .moreThan(-1, 'Число должно быть положительным')
    .integer('Число должно быть целым'),
  emailTemplate: yup.string().default('').nullable(),
  templateWithoutAlerts: yup.string().default('').nullable(),
  alertsTemplate: yup.string().default('').nullable(),
  cron: yup.string().default(''),
});

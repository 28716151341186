import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { LicenseStore } from '@/entities/License';
import { CompanyService } from '@/entities/Company';

@singleton()
export class DeleteLicenseService {
  open: boolean;
  selectedLicenseId: string | null;

  constructor(private licenseStore: LicenseStore, private companyService: CompanyService) {
    this.open = false;
    this.selectedLicenseId = null;

    makeAutoObservable(this);
  }

  async deleteLicense() {
    if (this.licenseStore.licenses && this.selectedLicenseId) {
      const tempLicense = { ...this.licenseStore.licenses.entities };

      delete tempLicense[this.selectedLicenseId];

      await this.companyService.updateCompany(Object.values(tempLicense));
    }
  }
}

import { FC } from 'react';

import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { PythonBeanPage } from '@/pages/PythonBeanPage';
import { Dialog, DialogContent, DialogTitle } from '@/shared/ui';
import { amendDraggable } from '@/shared/lib/extendDndkit';

import { ChoosePythonScriptService } from '../api/ChoosePythonScriptService';

const choosePythonScriptService = container.resolve(ChoosePythonScriptService);

export interface PythonBeanPageDialogProps {}

export const PythonBeanPageDialog: FC<PythonBeanPageDialogProps> = observer(() => {
  const handleClose = () => {
    choosePythonScriptService.openDialog = false;
  };

  return (
    <Dialog
      fullScreen
      open={choosePythonScriptService.openDialog}
      onClose={handleClose}
      {...amendDraggable(true)}
    >
      <DialogTitle onClose={handleClose}>Выберите Python Script</DialogTitle>
      <DialogContent>
        <PythonBeanPage sidebarHeight='calc(100vh - 272px)' />
      </DialogContent>
    </Dialog>
  );
});

PythonBeanPageDialog.displayName = 'PythonBeanPageDialog';

import { FC, useCallback, useEffect } from 'react';

import { Observer, observer } from 'mobx-react-lite';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { container } from 'tsyringe';

import { ListWithInfiniteScroll } from '@/shared/ui';
import { DashboardService } from '@/entities/Dashboard/services/DashboardService';
import { deleteAllSearchParams } from '@/shared/lib/deleteAllSearchParams';
import { DashboardStore } from '@/entities/Dashboard/stores/DashboardStore';

import { DashboardCard } from '../../entities';
import { DashboardActionBar } from '../DashboardActionBar/DashboardActionBar';

const dashboardService = container.resolve(DashboardService);
const dashboardStore = container.resolve(DashboardStore);

export const DashboardList: FC = observer(() => {
  const { dashboardId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const isLoading = dashboardService.isLoadingDashboards;

  const animated = useCallback((key: string) => {
    const fromWidth = { width: 'calc(100% - 50px)' };
    const toWidth = { width: 'calc(100% - 25px)' };
    const props = {
      whileHover: toWidth,
      whileTap: toWidth,
      initial: fromWidth,
      animate: fromWidth,
    };

    if (key === dashboardId) {
      return {
        ...props,
        initial: toWidth,
        animate: toWidth,
      };
    }
    return props;
  }, [dashboardId]);

  const handleCardClick = useCallback(
    (id: string) => () => {
      if (!id) return;
      navigate(`${id}?${deleteAllSearchParams(searchParams, ['refresh', 'interval'])}`);
      dashboardStore.selectedDashboardId = id;
    },
    [navigate, searchParams]
  );

  useEffect(() => () => {
    dashboardService.resetDashboards();
  }, []);

  const fetchNextPage = () => dashboardService.getDashboards();
  return (
    <ListWithInfiniteScroll
      animated={animated}
      elementWidth="calc(100% - 50px)"
      elements={dashboardService.dashboards.ids}
      lastPage={1}
      stopAnimatedForKey={dashboardId}
      activeElementKey={dashboardId}
      loading={isLoading}
      fetchNextElement={fetchNextPage}
      renderElement={(id, index, rest) => (
        <Observer key={id}>
          {() => (
            <DashboardCard
              dashboardId={id}
              active={Boolean(rest?.active)}
              onClick={handleCardClick(id)}
              actions={<DashboardActionBar />}
            />
            )}
        </Observer>
      )}
    />
  );
});

import React from 'react';

import 'reflect-metadata';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {QueryClient, QueryClientProvider} from 'react-query';
import { ConfigProvider, theme } from 'antd';
import locale from 'antd/locale/ru_RU';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import Main from './shared/ui/Main';
import { store } from './store/configureStore';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import AuthVerifier from './shared/ui/AuthVerifier';
import Configuration from './shared/ui/Configuration';

import 'react-toastify/dist/ReactToastify.css';
import 'reactflow/dist/style.css';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import './assets/styles/global.scss';

dayjs.locale('ru');

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
    },
  },
});

root.render(
  <ConfigProvider
    locale={locale}
    theme={{
      algorithm: theme.darkAlgorithm,
      token: {
        colorBgContainer: '#242731',
        colorBgBase: '#242731',
        colorPrimary: '#6c5dd3',
      },
      components: {
        Tree: {
          nodeSelectedBg: '#6c5dd3',
          directoryNodeSelectedBg: '#6c5dd3'
        },
        Popover: {
          zIndexPopup: 1100,
        },
      }
    }}
  >
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <Configuration>
          <AuthVerifier>
            <Main />
          </AuthVerifier>
        </Configuration>
      </QueryClientProvider>
    </Provider>
  </ConfigProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

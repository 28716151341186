import { FC } from 'react';

import { Control, useFieldArray, UseFormRegister } from 'react-hook-form';
import Grid from '@mui/system/Unstable_Grid';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import _ from 'lodash';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { Stack } from '@mui/system';
import { PlusIcon } from '@/shared/ui/Icons/PlusIcon/PlusIcon';
import cn from 'classnames';
import { IntegrationTest, Matcher } from '@/entities/Block/types';

import styles from './BlockTestingForm.module.scss';

export interface MatcherListFormProps {
  control: Control<IntegrationTest>;
  register: UseFormRegister<IntegrationTest>;
  errors: unknown;
}

export const MatcherListForm: FC<MatcherListFormProps> = ({ control, register, errors }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'matcherList',
  });

  return (
    <>
      <h2 className={cn(styles.title, 'mb-5')}>Правила проверки</h2>
      {fields.map((field, index) => {
        return (
          <Grid key={field.id} container gap={2.5}>
            <Grid xs={3}>
              <FieldInput
                error={_.get(errors, `matcherList.${index}.nameProperty`)}
                name={`matcherList.${index}.nameProperty`}
                register={register}
                label='Наименование'
              />
            </Grid>
            <Grid xs={1}>
              <FieldSelect
                error={_.get(errors, `matcherList.${index}.operation`)}
                name={`matcherList.${index}.operation`}
                control={control}
                options={['==', 'in', '>=', '>', '<=', '<', '!=', 'like']}
                label='Операция'
                defaultValue='=='
              />
            </Grid>
            <Grid xs={2}>
              <FieldSelect
                error={_.get(errors, `matcherList.${index}.type`)}
                name={`matcherList.${index}.type`}
                control={control}
                options={['String', 'ObjectId', 'Integer', 'Double', 'Date']}
                label='Тип данных'
                defaultValue='String'
              />
            </Grid>
            <Grid xs={3}>
              <FieldInput
                error={_.get(errors, `matcherList.${index}.value`)}
                name={`matcherList.${index}.value`}
                register={register}
                label='Значение'
              />
            </Grid>
            <Grid xs={1} alignSelf='center'>
              <Button
                circle
                size={ButtonSizes.Small}
                variant={ButtonVariants.Outlined}
                onClick={() => remove(index)}
              >
                <TrashIcon />
              </Button>
            </Grid>
          </Grid>
        );
      })}
      <Grid container>
        <Grid xs={3}>
          <Button
            doesUseInFormOrTable
            variant={ButtonVariants.Secondary}
            onClick={() => append({ operation: '==', type: 'String' } as Matcher)}
          >
            <Stack direction='row' gap={1.5} alignItems='center'>
              <PlusIcon />
              <p>Добавить правило</p>
            </Stack>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

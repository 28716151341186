import {FC} from 'react';

import {Handle, Position} from 'reactflow';
import cn from 'classnames';
import {KeyboardArrowDown} from '@mui/icons-material';

import styles from './ConnectionHandle.module.scss';
import {container} from "tsyringe";
import {BlockService} from "@/entities/Block/services/BlockService";

export interface ConnectionHandleProps {
  id: string;
  visible?: boolean;
}

export const ConnectionHandle: FC<ConnectionHandleProps> = ({id, visible}) => {
  const blockService = container.resolve(BlockService);

  return (
    <>
      <Handle
        className={cn(styles.root, {[styles.invisible]: !visible})}
        id={id}
        type='source'
        isConnectableEnd={false}
        position={Position.Bottom}
      >
        <KeyboardArrowDown
          color='primary'
          pointerEvents='none'
          width={10}
          height={10}
          strokeWidth={3}
        />
      </Handle>
      <Handle
        className={cn(styles.target,
          {invisible: blockService.nodeIdStartConnect === null || blockService.nodeIdStartConnect === id}
        )}
        id={id}
        type='target'
        position={Position.Top}
        //isConnectable={blockService.nodeIdStartConnect !== null && blockService.nodeIdStartConnect !== id} Запрет на соединения с самим собой. Решили оставить возможность, но не подсвечивать
      />
    </>
  );
};

import { FC } from 'react';

import { CardWithActions } from '@/shared/ui';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { useResolve } from '@/hooks/useResolve';
import Typography from '@mui/material/Typography';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { RegistrableValues } from '@/shared/lib/types';

import { PropertiesListService } from '../api/PropertiesListService';
import { PropertyService } from '../api/PropertyService';

const propertiesListService = container.resolve(PropertiesListService);

export interface PropertyCardProps {
  propertyId: string;
  active: boolean;
  actions: JSX.Element;
}

export const PropertyCard: FC<PropertyCardProps> = observer(({ propertyId, active, actions }) => {
  const propertyService = useResolve(PropertyService, {
    [RegistrableValues.PropertyId]: propertyId,
  });

  return (
    <Link to={propertyId}>
      <CardWithActions
        title={propertyService.property?.name || 'Название отсутствует'}
        loading={propertiesListService.isLoadingProperties}
        active={active}
        actions={actions}
      >
        <Typography
          className={cn({ 'opacity-40': !active })}
          noWrap
          variant={'description' as never}
        >
          {propertyService.property?.description}
        </Typography>
      </CardWithActions>
    </Link>
  );
});

PropertyCard.displayName = 'PropertyCard';

import { FC, SVGProps } from 'react';

export const TrashIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='none'
      width='16'
      height='16'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_29_5896)'>
        <path
          d='M1 1.26592H5.93687V0H10.1544V1.26592H15.0913V3.18162H1V1.26592Z'
          fill='currentColor'
        />
        <path
          d='M2.28906 3.99365H14.0411L12.6442 16.0005H3.68605M5.73245 5.84913L4.04931 7.50787L6.31958 9.74524L4.02718 12.0044L5.7102 13.6631L8.00269 11.4039L10.2687 13.637L11.954 11.9806L9.80564 9.83101L12.0451 7.62397L10.3621 5.96523L8.12261 8.17226'
          fill='currentColor'
        />
      </g>
      <defs>
        <clipPath id='clip0_29_5896'>
          <rect width='16' height='16' fill='currentColor' />
        </clipPath>
      </defs>
    </svg>
  );
};

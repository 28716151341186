import { FC, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import dayjs from 'dayjs';
import { container } from 'tsyringe';
import { Stack } from '@mui/system';
import Typography from '@mui/material/Typography';
import { ActionPanel, CardWithActions } from '@/shared/ui';
import { FlowVersionStore } from '@/entities/FlowVersion';
import { OpenVersionsService } from '@/features/OpenVersions';
import { SetVersionButton } from '@/features';
import { ExportVersionButton } from '@/features/ExportVersion';


const openVersionsService = container.resolve(OpenVersionsService);
const flowVersionStore = container.resolve(FlowVersionStore);

export const ItemList: FC<{ id: string; }> = observer(({ id }) => {
  const handleCardClick = useCallback(
    (versionId: string) => () => {
      openVersionsService.selectedVersionId = versionId;
    },
    []
  );
  const active = openVersionsService.selectedVersionId === id;

  return (
    <div key={id}>
      <CardWithActions
        title={flowVersionStore.versions?.entities[id].name || ''}
        active={active}
        onClick={handleCardClick(id)}
        actions={
          <ActionPanel small withoutBorder>
            <SetVersionButton />
            <ExportVersionButton versionId={id} />
          </ActionPanel>
        }
      >
        <Stack paddingTop={0.5} gap={1}>
          <Typography
            noWrap
            variant={'description' as any}
            color={active ? undefined : 'var(--label-text-color)'}
          >
            {flowVersionStore.versions?.entities[id].description}
          </Typography>
          <Typography
            variant={'description' as any}
            color={active ? undefined : 'var(--label-text-color)'}
          >
            {dayjs(flowVersionStore.versions?.entities[id].versionDate).format(
              'DD.MM.YYYY | HH:mm:ss'
            )}
          </Typography>
        </Stack>
      </CardWithActions>
    </div>
  )
});
import { FC } from 'react';

import OptionUnstyled, { OptionUnstyledProps } from '@mui/base/OptionUnstyled';
import { useEllipsisText } from '@/hooks/useEllipsisText';

export const Option: FC<OptionUnstyledProps<string>> = ({ children, ...props }) => {
  const { isEllipsisActive, ellipsisTextRef } = useEllipsisText<HTMLLIElement>();

  return (
    <OptionUnstyled
      {...props}
      ref={ellipsisTextRef}
      slotProps={{ root: { title: isEllipsisActive ? (children as string) : undefined } }}
    >
      {children}
    </OptionUnstyled>
  );
};

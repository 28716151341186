import React from 'react';

import { Stack } from '@mui/system';

import { ReQuartzCron } from './lib/quartz';

import './styles.scss';
import styles from './Cron.module.scss';

const Cron = (props) => {
  const { cronValue, setCronValue } = props;

  return (
    <Stack gap={4} alignItems='center'>
      <span className={styles.title}>{cronValue}</span>

      <ReQuartzCron cssClassPrefix='my-' value={cronValue} onChange={setCronValue} />
    </Stack>
  );
};

export default Cron;

import { FC } from 'react';

import { observer } from 'mobx-react-lite';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import {
  CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
  SearchParams,
  useSearchParamsTemplate,
} from '@/hooks/useTemplateSearchParams';

import { LogSettingsFormContainer } from './LogSettingsFormContainer/LogSettingsFormContainer';

export const LogSettingsContainer: FC = observer(() => {
  const { remove, has } = useSearchParamsTemplate();

  const handleDrawerClose = () => {
    remove([
      SearchParams.ShowMessageDeliverySettings,
      SearchParams.ShowLogSettings,
      ...CONNECTOR_FORM_SEARCH_PARAMS_TEMPLATE,
    ]);
  };

  return has(SearchParams.ShowLogSettings) ? (
    <div className='col-span-1'>
      <Drawer largeTitle title='Настройка логирования' onClose={handleDrawerClose}>
        <LogSettingsFormContainer />
      </Drawer>
    </div>
  ) : null;
});

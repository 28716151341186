import { FC, useCallback, useState } from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ExternalService } from '@/entities/ExternalApp';
import Stack from '@mui/material/Stack';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { notify } from '@/shared/ui/Toast/notify';

import { UpsertExternalAppService } from '../api/UpsertExternalAppService';

const upsertExternalAppService = container.resolve(UpsertExternalAppService);

export interface UpsertExternalAppFormProps {}

export const UpsertExternalAppDialogForm: FC<UpsertExternalAppFormProps> = observer(() => {
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, reset } = useForm<ExternalService>({
    values: upsertExternalAppService.currentExternalApp,
  });

  const title = upsertExternalAppService.selectedId
    ? 'Изменить внешний сервис'
    : 'Добавить внешний сервис';
  const buttonSubmitText = upsertExternalAppService.selectedId ? 'Изменить' : 'Добавить';

  const handleClose = useCallback(() => {
    upsertExternalAppService.open = false;
    upsertExternalAppService.selectedId = null;

    reset({ name: '', description: '', serviceUrl: '' });
  }, [reset]);

  const handleValidSubmit = useCallback<SubmitHandler<ExternalService>>(
    async (externalService) => {
      try {
        setIsLoading(true);

        await upsertExternalAppService.upsertExternalApp(externalService);

        handleClose();

        if (upsertExternalAppService.selectedId) {
          notify.success('Внешний сервис изменен');
        } else {
          notify.success('Внешний сервис добавлен');
        }
      } catch {
        if (upsertExternalAppService.selectedId) {
          notify.error('Не удалось изменить внешний сервис');
        } else {
          notify.error('Не удалось добавить внешний сервис');
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleClose]
  );

  return (
    <Dialog fullWidth open={upsertExternalAppService.open} onClose={handleClose}>
      <form onSubmit={handleSubmit(handleValidSubmit)}>
        <DialogTitle onClose={handleClose}>{title}</DialogTitle>
        <DialogContent>
          <Stack gap={2.5} marginY='2px'>
            <FieldInput error='' name='name' label='Название сервиса' register={register} />
            <FieldInput textarea error='' name='description' label='Описание' register={register} />
            <FieldInput
              textarea
              error=''
              name='serviceUrl'
              label='URL до сервиса'
              register={register}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant={ButtonVariants.Secondary} onClick={handleClose}>
            Отмена
          </Button>
          <Button type='submit' variant={ButtonVariants.Popup} loading={isLoading}>
            {buttonSubmitText}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
});

UpsertExternalAppDialogForm.displayName = 'UpsertExternalAppForm';

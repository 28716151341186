import { FC, useMemo } from 'react';

import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Input } from '@/shared/ui';
import { amendDraggable } from '@/shared/lib/extendDndkit';

export interface FieldInputProps extends FieldProps {
  endAdornment?: JSX.Element;
  textarea?: boolean;
  id?: string;
  onChange?: () => void;
  disabled?: boolean;
  isVisible?: boolean;
}

/**
 * Компонент инпута для формы
 */
export const FieldInput: FC<FieldInputProps> = ({
  label,
  name,
  type,
  register,
  endAdornment,
  isRequired,
  description,
  error,
  warning,
  textarea,
  defaultValue,
  id,
  onChange,
  disabled = false,
  isVisible = true,
}) => {
  const multilineProps = useMemo(() => {
    if (!textarea) return {};

    return {
      multiline: true,
      minRows: 4,
      maxRows: 4,
    };
  }, [textarea]);

  return (
    <Input
      inputProps={register(name)}
      tooltip={description}
      required={isRequired}
      label={label}
      type={type}
      isVisible={isVisible}
      endAdornment={endAdornment}
      error={error?.message && error?.message}
      warning={warning?.message && warning?.message}
      defaultValue={defaultValue}
      id={id}
      {...multilineProps}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export const selectOption = [
  {
    value: 'Block',
    label: 'События по работе блока',
  },
  {
    value: 'Message',
    label: 'События по сообщениям в блоке',
  },
];

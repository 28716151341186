import { FC, memo } from 'react';

import Box from '@mui/system/Box';
import { PropertyListForm } from '@/features';
import { useParams } from 'react-router-dom';

export interface PropertyContentPageProps {}

export const PropertyContentPage: FC<PropertyContentPageProps> = memo(() => {
  const { propertyId } = useParams();

  return (
    <Box padding={2.5}>
      <PropertyListForm key={propertyId} />
    </Box>
  );
});

PropertyContentPage.displayName = 'PropertyContentPage';

import { FC, MouseEventHandler, useCallback } from 'react';

import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { TrashIcon } from '@/shared/ui/Icons/TrashIcon/TrashIcon';
import { PythonLibsService } from '@/entities/PythonLib/api/PythonLibsService';

const pythonLibsService = container.resolve(PythonLibsService);

export interface DeletePythonLibButtonProps {
  pythonLibTitle?: string;
}

export const DeletePythonLibButton: FC<DeletePythonLibButtonProps> = observer(() => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>((event) => {
    event.preventDefault();
    pythonLibsService.showDialog = true;
  }, []);

  return (
    <Tooltip title='Удалить библиотеку' placement='top'>
      <span>
        <Button
          loading={pythonLibsService.isLoading}
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <TrashIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

DeletePythonLibButton.displayName = 'DeletePythonLibButton';

import React, { useCallback, useLayoutEffect } from 'react';

import { useAuth } from 'react-oidc-context';

import AuthForm from '../../AuthForm';
import useLocalStorageRedux from '../../../../../hooks/useLocalStorageRedux';
import {container} from "tsyringe";
import {AuthService} from "@/shared/ui/AuthVerifier/services/AuthService";
import {observer} from "mobx-react-lite";

const authService = container.resolve(AuthService);

const KeyCloakProve = observer((props) => {
  const { children } = props;
  const auth = useAuth();
  const [headerAuth, setHeaderAuth] = useLocalStorageRedux('headerAuth');

  const isAuth = authService.isAuth

  useLayoutEffect(() => {
    if (auth.user && auth.user.access_token) {
      setHeaderAuth('Bearer ' + auth.user.access_token);
      authService.sendIsCheck()
    }
  }, [auth.user]);

  const login = useCallback(async () => {
    await auth.signinRedirect();
  }, []);

  return <>{auth && auth.isAuthenticated && isAuth ? children : <AuthForm onLogin={login} />}</>;
});

KeyCloakProve.propTypes = {};

export default KeyCloakProve;

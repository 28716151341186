import { FC, memo } from 'react';

import { DownloadIcon } from '../Icons/DownloadIcon/DownloadIcon';
import { ButtonSizes, ButtonVariants } from './types';

import Button, { ButtonProps } from './index';

export const ExportButton: FC<ButtonProps> = memo((props) => {
  return (
    <Button {...props} circle variant={ButtonVariants.Outlined} size={ButtonSizes.Small}>
      <DownloadIcon />
    </Button>
  );
});

ExportButton.displayName = 'ExportButton';

import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { ApiService } from '@/shared/api/Api/services/ApiService';
import { normalize } from '@/shared/lib/normalize';

import { Role } from '../model/types';
import { RoleStore } from '../model/RoleStore';

@singleton()
export class RoleService {
  constructor(private apiService: ApiService, private roleStore: RoleStore) {
    makeAutoObservable(this);
  }

  async getRoles() {
    const response = await this.apiService.instance.get<Role[]>('/editor/role/all');

    this.roleStore.roles = normalize(response.data, 'id');

    return response.data;
  }
}

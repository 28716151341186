import { FC, useEffect, useRef } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Stack } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import { container } from 'tsyringe';
import { observer } from 'mobx-react-lite';
import { difference, intersection, isEmpty, union } from 'lodash';
import {
  CaretDownOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { TreeSelect } from '@/shared/ui';
import { GFlowService } from '@/entities/Flow/services/GFlowService';
import { GFlowStore } from '@/entities/Flow/stores/GFlowStore';
import Button from '@/shared/ui/Button';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { yupResolver } from '@hookform/resolvers/yup';
import { withGroupFieldsValidation } from '@/shared/lib';
import { FieldControl } from '@/shared/ui/Fields/components/FieldControl/FieldControl';
import { applyFilter } from '@/shared/lib/buildTree';
import { GFlow, SelectGroup, RestrictedGFlow } from "@/entities/Flow/types";

const gflowService = container.resolve(GFlowService);
const gflowStore = container.resolve(GFlowStore);

const prepareData = (// todo separate update from create node
  { parentId, type, name, description }: InitialValues,
  rec: GFlow,
): (GFlow | RestrictedGFlow) => {
  if (!rec) {
    // @ts-ignore
    return { parentId, type, data: { name, description } };
  }

  return {
    ...rec,
    data: { ...rec.data, name, description },
    parentId,
    type,
  };
}

const defaultValues = {
  parentId: '',
  type: 'flow',
  name: '',
  description: '',
};

type InitialValues = {
  parentId: string;
  type: string;
  name: string;
  description?: string;
}

const TFlowForm: FC<{ initialValues: InitialValues; }> = observer(({ initialValues }) => {
  const navigate = useNavigate();
  const { has, remove, get } = useSearchParamsTemplate();
  const nodeId = get('itemId');
  const { loadedKeys } = gflowService;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(withGroupFieldsValidation),
    values: initialValues,
    defaultValues,
    mode: 'onChange',
  });

  const isEdit = has(SearchParams.ShowEditTFlowForm);
  const buttonText = isEdit ? 'Сохранить' : 'Создать';
  const onSubmit: SubmitHandler<InitialValues> = async (formValues) => {

    const editingNodeId = has(SearchParams.ShowEditTFlowForm) && nodeId;
    const editedRecord = gflowService.gflowsMap[editingNodeId];
    const data = prepareData(formValues, editedRecord);

    const node = await gflowService.saveGFlow(data, editingNodeId);

    if (editingNodeId) {
      remove([SearchParams.ShowEditTFlowForm]);
    } else if(node?.id) {
      const flowId = node?.componentId;
      const route = flowId
        ? `/flow/${flowId}?mode=tree&itemId=${node.id}`
        : `/flow?mode=tree&itemId=${node.id}`;
      navigate(route);
    }
  };

  useEffect(() => {
    gflowService.getGroups();
  }, [loadedKeys]);

  useEffect(() => {
    if (nodeId) return;
    reset();
  }, [nodeId, reset]);

  let treeData: SelectGroup[] = [];
  if (gflowService.groups?.children) {
    const excludeIds = isEdit ? [nodeId] : [];
    const filter = ({ value }: SelectGroup) => !excludeIds.includes(value);
    treeData = applyFilter(gflowService.groups.children, filter);
  }
  const treeDefaultExpandedKeys = gflowService.getPathToNode(nodeId);
  const prevExpanded = useRef(treeDefaultExpandedKeys);
  const handleExpandNode = (expanded: string[]) => {
    const [expandedId = null] = difference(expanded, prevExpanded.current)
    prevExpanded.current = expanded;
    if (!expandedId) return;
    const expandedNode = gflowService.gflowsMap[expandedId];
    const childIds = expandedNode.children
      .filter(({ type }) => type === 'group').map(({ id }) => id);
    if (isEmpty(childIds)) return;
    const isLoaded = !isEmpty(intersection(loadedKeys, childIds));
    if (isLoaded) return;
    const promise = gflowService.getExpandedGroup(expandedNode.id);
    promise.then(() => {
      gflowStore.loadedKeys = union(gflowStore.loadedKeys, childIds);
    });
  }
  const switcherIcon = ({ value, data: { children } }) => {
    const isLoaded = gflowStore.loadedKeys.includes(value);
    if (!isLoaded) return <LoadingOutlined />;
    if (children?.length) return <CaretDownOutlined />;
    return null;
  }
  return (
    <div>
      <Stack
        component='form'
        className='p-5'
        onSubmit={handleSubmit(onSubmit)}
        justifyContent='space-between'
      >
        <div>
          <Stack spacing={2.5}>
            <FieldControl
              label='Группа'
              description='Выберите группу в котором будет размещена группа'
              name='parentId'
              data={treeData}
              control={control}
              Field={TreeSelect}
              size='large'
              style={{
                width: '100%',
              }}
              dropdownStyle={{
                zIndex: 1100,
                maxHeight: 400,
                overflow: 'auto',
              }}
              id='parentIdTFlow'
              allowClear
              treeDefaultExpandedKeys={treeDefaultExpandedKeys}
              treeLoadedKeys={loadedKeys}
              onTreeExpand={handleExpandNode}
              switcherIcon={switcherIcon}
            />
            <FieldInput
              label='Наименование'
              name='name'
              localeName='name'
              register={register}
              error={errors.name}
              description='Введите название потока, длинной не более 100 символов'
              id='nameTFlow'
            />
            <FieldInput
              textarea
              label='Описание'
              name='description'
              localeName='description'
              register={register}
              error={errors.description}
              description='Введите описание потока, длинной не более 1000 символов'
              id='descriptionTFlow'
            />
            <FieldInput
              name='type'
              localeName='type'
              type='hidden'
              register={register}
              error={errors.type}
              id='typeTFlow'
            />
          </Stack>
        </div>
        <Box>
          <Button
            disabled={!isValid}
            loading={gflowStore.isLoadingSaveGFlow}
            type='submit'
            variant={ButtonVariants.Primary}
            onClick={handleSubmit(onSubmit)}
            id='createFlow'
          >
            {buttonText}
          </Button>
        </Box>
      </Stack>
    </div>
  );
});

TFlowForm.displayName = 'TFlowForm';

export default TFlowForm;

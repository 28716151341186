import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';
import { License, LicenseStore } from '@/entities/License';
import { CompanyService } from '@/entities/Company';

@singleton()
export class UpsertLicenseService {
  open: boolean;
  selectedLicenseId: string | null;

  constructor(private licenseStore: LicenseStore, private companyService: CompanyService) {
    this.open = false;
    this.selectedLicenseId = null;

    makeAutoObservable(this);
  }

  get license() {
    if (this.selectedLicenseId && this.licenseStore.licenses) {
      return this.licenseStore.licenses.entities[this.selectedLicenseId].license;
    }

    return '';
  }

  set license(value: string) {
    if (this.selectedLicenseId && this.licenseStore.licenses) {
      this.licenseStore.licenses.entities[this.selectedLicenseId].license = value;
    }
  }

  get type() {
    if (this.selectedLicenseId && this.licenseStore.licenses) {
      return this.licenseStore.licenses.entities[this.selectedLicenseId].type;
    }

    return '';
  }

  set type(value: string) {
    if (this.selectedLicenseId && this.licenseStore.licenses) {
      this.licenseStore.licenses.entities[this.selectedLicenseId].type = value;
    }
  }

  async updateLicense(license: string) {
    if (this.licenseStore.licenses && this.selectedLicenseId) {
      const tempLicense = { ...this.licenseStore.licenses.entities };

      tempLicense[this.selectedLicenseId].license = license;

      await this.companyService.updateCompany(Object.values(tempLicense));
    }
  }

  async addLicense(license: string) {
    if (this.licenseStore.licenses) {
      const tempLicense = { ...this.licenseStore.licenses.entities };

      tempLicense.new = {
        licenseBody: null,
        validLicenseBody: null,
        license,
      } as License;

      await this.companyService.updateCompany(Object.values(tempLicense));
    }
  }
}

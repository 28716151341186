import { Node } from 'reactflow';

/**
 * Возвращает центр блока
 * @param node Блок
 */
export const getNodeCenter = (node: Node): { x: number; y: number } => {
  return {
    x: node.positionAbsolute!.x + node.width! / 2,
    y: node.positionAbsolute!.y + node.height! / 2,
  };
};

export var WeekDayCode;
(function (WeekDayCode) {
    WeekDayCode["MON"] = "MON";
    WeekDayCode["TUE"] = "TUE";
    WeekDayCode["WED"] = "WED";
    WeekDayCode["THU"] = "THU";
    WeekDayCode["FRI"] = "FRI";
    WeekDayCode["SAT"] = "SAT";
    WeekDayCode["SUN"] = "SUN";
})(WeekDayCode || (WeekDayCode = {}));

import { Normalized } from '@/shared/lib/normalize';
import { Exchange } from '@/entities/Connect/types';
import { ElementEditor, Relation } from '@/entities/Flow/types';
import { makeAutoObservable } from 'mobx';
import { singleton } from 'tsyringe';

import { TracingMessageModel } from '../../TracingMessage/model/TracingMessageModel';

@singleton()
export class SendMessageStore implements TracingMessageModel {
  canvas: Normalized<ElementEditor> | null;
  exchangeList: Normalized<Exchange> | null;
  loading: boolean;
  relationSet: Relation[];
  exception: string | undefined;

  constructor() {
    this.canvas = null;
    this.exchangeList = null;
    this.loading = false;
    this.relationSet = [];
    this.exception = undefined;

    makeAutoObservable(this);
  }

  reset() {
    this.canvas = null;
    this.exchangeList = null;
    this.loading = false;
    this.relationSet = [];
    this.exception = undefined;
  }
}

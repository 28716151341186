import { FC } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Grid from '@mui/material/Grid';
import { Control, DeepPartial, UseFormRegister } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { FieldSelect } from '@/shared/ui/Fields/components/FieldSelect/FieldSelect';
import { Settings, SettingsStore } from '@/entities/Settings';
import { FieldInput } from '@/shared/ui/Fields/components/FieldInput/FieldInput';
import { FieldPassword } from '@/shared/ui/Fields/components/FieldPassword/FieldPassword';

const settingsStore = container.resolve(SettingsStore);

export interface EmailServerSettingsProps {
  errors: any;
  register: UseFormRegister<Settings>;
  control: Control<Settings, any>;
  defaultValues: Readonly<DeepPartial<Settings>> | undefined;
}

export const EmailServerSettings: FC<EmailServerSettingsProps> = observer(
  ({ errors, register, control, defaultValues }) => (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>Настройки сервера почты</AccordionSummary>
      <AccordionDetails>
        <Grid container gap={3} justifyContent='center'>
          <Grid xs={12} lg={5.8}>
            <FieldInput
              error={errors?.mailServer?.username}
              register={register}
              name='mailServer.username'
              label='Логин'
              defaultValue={defaultValues?.mailServer?.username}
            />
          </Grid>
          <Grid xs={12} lg={5.8}>
            <FieldPassword
              error={errors.mailServer?.password}
              register={register}
              name='mailServer.password'
              label={"Пароль"}
              defaultValue={defaultValues?.mailServer?.password}
            />
          </Grid>
          <Grid xs={12} lg={5.8}>
            <FieldInput
              error={errors.mailServer?.host}
              register={register}
              name='mailServer.host'
              label='Хост'
              defaultValue={defaultValues?.mailServer?.host}
            />
          </Grid>
          <Grid xs={12} lg={5.8}>
            <FieldInput
              error={errors.mailServer?.port}
              register={register}
              name='mailServer.port'
              label='Порт'
              defaultValue={defaultValues?.mailServer?.port}
            />
          </Grid>
          <Grid xs={12} lg={5.8}>
            <FieldSelect
              error={errors.mailServer?.protocol}
              control={control}
              name='mailServer.protocol'
              label='Протокол'
              options={['smtp', 'smtps']}
              defaultValue={settingsStore.settings?.mailServer.protocol}
            />
          </Grid>
          <Grid xs={12} lg={5.8}>
            <FieldInput
              error={errors.mailServer?.from}
              register={register}
              name='mailServer.from'
              label='Отправка письма от лица'
              defaultValue={defaultValues?.mailServer?.from}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
);

EmailServerSettings.displayName = 'EmailServerSettings';

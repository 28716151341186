import { FC } from 'react';

import { Stack } from '@mui/system';
import { Layout } from '@/shared/ui/Layout/Layout';
import { observer } from 'mobx-react-lite';
import { Outlet, useParams } from 'react-router-dom';
import { PropertyForm } from '@/features';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';

import { PropertySidebar } from '../../../widgets';

export interface PropertiesPageProps {}

export const PropertiesPage: FC<PropertiesPageProps> = observer(() => {
  const { propertyId } = useParams();
  const { get } = useSearchParamsTemplate();

  return (
    <Layout>
      <Stack display='grid' gridTemplateColumns='max-content 1fr max-content'>
        <PropertySidebar />
        {!propertyId && <p />}
        <Outlet />
        <PropertyForm
          key={`${get(SearchParams.PropertyId)}${get(SearchParams.ShowPropertyForm)}`}
        />
      </Stack>
    </Layout>
  );
});

PropertiesPage.displayName = 'PropertiesPageProps';

import * as React from 'react';
import { ChangeEventHandler, FC, useCallback, useEffect, useState } from 'react';

import { get } from 'lodash';
import { Controller, UseFormGetValues } from 'react-hook-form';
import { observer } from 'mobx-react-lite';
import { Stack } from '@mui/system';
import Box from '@mui/material/Box';

import { Input } from '@/shared/ui/InputNumber/Input';
import { Label } from '@/shared/ui/Label/Label';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import Button from '@/shared/ui/Button';
import { SettingsIcon } from '@/shared/ui/Icons/SettingsIcon/SettingsIcon';
import Cron from '@/shared/ui/Cron';
import { FieldProps } from '@/shared/ui/Fields/Fields';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@/shared/ui';
import { amendDraggable } from '@/shared/lib/extendDndkit';

import styles from '@/shared/ui/Fields/components/FieldInput/FieldInput.module.scss';

import cronStyles from './styles.module.scss';

export interface FieldCronProps extends FieldProps {
  getValues: UseFormGetValues<any>;
}

/** Компонент крона для формы */
export const FieldCron: FC<FieldCronProps> = observer(
  ({ name, description, isRequired, error, label, control, getValues, props }) => {
    const [isVisibleDialog, setIsVisibleDialog] = useState<boolean>(false);
    /** Дефолтное состояние крона */
    const [cronValue, setCronValue] = useState<string>('0 * * ? * * *');

    /** Изменение состояние крона  при редактировании*/
    useEffect(() => {
      const values = getValues();
      setCronValue(_.get(values, name, ''));
    }, [getValues, name]);

    const handleButtonIconClick = useCallback(() => {
      setIsVisibleDialog(true);
    }, []);

    const handleDialogClose = useCallback(() => {
      const values = getValues();
      setCronValue(_.get(values, name, cronValue));

      setIsVisibleDialog(false);
    }, [getValues, name]);

    const handleDialogOk = useCallback(
      (change: (value?: string) => void) => () => {
        change(cronValue);
        handleDialogClose();
      },
      [cronValue, handleDialogClose]
    );

    return (
      <Controller
        control={control}
        name={name}
        defaultValue={cronValue}
        render={({ field }) => {
          const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) =>
            field.onChange(event.target.value);
          return (
            <>
              <Stack spacing={1} flex={1} {...amendDraggable(true)}>
                <Stack spacing={2}>
                  <Label name={name} tooltip={description} required={isRequired}>
                    {label}
                  </Label>
                  <Input
                    value={field.value}
                    onChange={handleInputChange}
                    error={error}
                    endAdornment={
                      <Button
                        circle
                        variant={ButtonVariants.Outlined}
                        size={ButtonSizes.Small}
                        onClick={handleButtonIconClick}
                      >
                        <SettingsIcon />
                      </Button>
                    }
                  />
                </Stack>
                <p className={styles.helpText}>{error?.message}</p>
              </Stack>
              <Dialog
                className={cronStyles.root}
                open={isVisibleDialog}
                onClose={handleDialogClose}
                {...amendDraggable(true)}
              >
                <DialogTitle onClose={handleDialogClose}>Настройки Cron</DialogTitle>
                <DialogContent>
                  <Stack gap={4}>
                    <Cron cronValue={cronValue} setCronValue={setCronValue} />
                  </Stack>
                </DialogContent>
                <DialogActions>
                  <Box width={216}>
                    <Button variant={ButtonVariants.Secondary} onClick={handleDialogClose}>
                      Отменить
                    </Button>
                  </Box>
                  <Box width={216}>
                    <Button variant={ButtonVariants.Popup} onClick={handleDialogOk(field.onChange)}>
                      Применить
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </>
          );
        }}
      />
    );
  }
);

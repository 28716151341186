import { FC, MouseEventHandler, useCallback } from 'react';

import Tooltip from '@/shared/ui/Tooltip';
import Button from '@/shared/ui/Button';
import { ButtonSizes, ButtonVariants } from '@/shared/ui/Button/types';
import { DownloadIcon } from '@/shared/ui/Icons/DownloadIcon/DownloadIcon';
import { observer } from 'mobx-react-lite';
import { container } from 'tsyringe';

import { ExportVersionService } from '../api/ExportVersionService';

export interface ExportVersionButtonProps {
  versionId: string;
}

const exportVersionService = container.resolve(ExportVersionService);

export const ExportVersionButton: FC<ExportVersionButtonProps> = observer(({ versionId }) => {
  const handleClick = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      exportVersionService.exportVersion(versionId);
    },
    [versionId]
  );

  return (
    <Tooltip title='Экспортировать версию' placement='top'>
      <span>
        <Button
          circle
          variant={ButtonVariants.Outlined}
          size={ButtonSizes.Small}
          onClick={handleClick}
        >
          <DownloadIcon />
        </Button>
      </span>
    </Tooltip>
  );
});

ExportVersionButton.displayName = 'ExportVersionButton';

import { FC, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
import { observer } from 'mobx-react-lite';
import Box from '@mui/system/Box';
import Stack from '@mui/system/Stack';
import Grid from '@mui/system/Unstable_Grid';
import { DEFAULT_MAX_FILE_SIZE_IN_BYTES } from '@/shared/lib/constants';
import Button from '@/shared/ui/Button';
import { ButtonVariants } from '@/shared/ui/Button/types';
import { notify } from '@/shared/ui/Toast/notify';
import { transformBytesToMb } from '@/shared/lib/transformBytesToMb';
import { FileCard } from '@/shared/ui/FileCard/FileCard';
import { amendDraggable } from '@/shared/lib/extendDndkit';
import { Attachment } from '@/features/UploadFiles/api/types';

import styles from './FileField.module.scss';

export interface FileFieldProps {
  downloading?: boolean;
  onDownload: (files: File[]) => void;
  attachments: Attachment[];
  files: File[];

  onDrop(files: File[]): void;

  onRemove(fileName: string): void;
}

export const FileField: FC<FileFieldProps> = observer(
  ({ downloading, attachments, files, onDownload, onDrop, onRemove }) => {
    const { getRootProps, getInputProps } = useDropzone({
      maxSize: DEFAULT_MAX_FILE_SIZE_IN_BYTES,
      onDrop,
      onDropRejected(fileRejections) {
        if (fileRejections.length === 1) {
          notify.error(
            `Файл не должен превышать ${transformBytesToMb(DEFAULT_MAX_FILE_SIZE_IN_BYTES)} Mb`
          );
        }
      },
    });

    const handleDownload = useCallback(() => {
      onDownload(files);
    }, [onDownload, files]);

    const handleRemove = useCallback(
      (fileName: string) => () => {
        onRemove(fileName);
      },
      [onRemove]
    );

    return (
      <Stack gap={2.5} {...amendDraggable(true)}>
        <div className={styles.root} {...getRootProps()}>
          <p className={styles.description}>Переместите или выберите файл</p>
          <input {...getInputProps()} />
        </div>
        <Grid container spacing={2}>
          {attachments.map((attachment) => {
            const { fileName, fileSize, url, guid } = attachment;
            return (
              <Grid key={guid} xl={3} lg={4} md={6} xs={12} title={fileName}>
                <FileCard
                  name={fileName}
                  size={fileSize}
                  downloadLink={url}
                  onRemove={handleRemove(fileName)}
                />
              </Grid>
            );
          })}
          {files.map((file) => {
            const { lastModified, name, size } = file;
            return (
              <Grid key={`${name}${lastModified}`} xl={3} lg={4} md={6} xs={12} title={name}>
                <FileCard
                  loading={downloading}
                  isNew
                  name={name}
                  size={size}
                  onRemove={handleRemove(name)}
                />
              </Grid>
            );
          })}
        </Grid>
        <Stack alignItems='flex-end'>
          <Box minWidth={300}>
            <Button
              loading={downloading}
              disabled={!files.length}
              variant={ButtonVariants.Secondary}
              doesUseInFormOrTable
              onClick={handleDownload}
            >
              {files.length > 1 ? 'Загрузить файлы' : 'Загрузить файл'}
            </Button>
          </Box>
        </Stack>
      </Stack>
    );
  }
);

FileField.displayName = 'FileField';

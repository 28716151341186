import { FC, memo } from 'react';

import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Tooltip from '@/shared/ui/Tooltip';
import { Indicator } from '@/shared/ui/Indicator/Indicator';
import { IndicatorTypes } from '@/shared/ui/Indicator/types';
import dayjs from 'dayjs';
import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';

import {KeyValue} from '../model/types';

import styles from './styles.module.scss';
import {DownloadManualExternalServiceButton} from "@/features/DownloadManualExternalService";

export interface KeyValueCardProps extends KeyValue {
  actions?: JSX.Element;
  toggle?: JSX.Element;
}

export const KeyValueCard: FC<KeyValueCardProps> = memo(
  ({
       id,
       companyId,
       name,
       firstId,
       secondId,
       value,
       actions,
       toggle
  }) => {
    return (
      <Card
        variant='outlined'
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <CardContent style={{
            padding:"16px 8px 16px 16px"
        }}>
          <Stack justifyContent='space-between' flex={1} height='100%'>
              <Stack gap={1} width='100%'>
                  <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      <Typography fontWeight={600}>{name || 'Название отсутствует'}</Typography>
                  </Stack>

                  <Typography noWrap fontSize='0.75rem'>
                      {value}
                  </Typography>
              </Stack>
          </Stack>
        </CardContent>
          <CardActions>
              <Stack gap={2} flex={1}>
                  {actions}
              </Stack>
          </CardActions>
      </Card>
    );
  }
);

KeyValueCard.displayName = 'KeyValueCard';

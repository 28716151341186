import { useParams } from 'react-router-dom';
import { Drawer } from '@/shared/ui/Drawer/Drawer';
import { SearchParams, useSearchParamsTemplate } from '@/hooks/useTemplateSearchParams';
import { dashboardTitles } from '@/entities/Dashboard/widgets/DashboardPlatform/constants/titles';
import actionSearchParams from "@/entities/Dashboard/widgets/DashboardPlatform/constants/actionSearchParams";

import { DashboardForm } from './DashboardForm';

export function DashboardFormDrawer() {
  const { has, remove } = useSearchParamsTemplate();
  const { dashboardId } = useParams();

  const isOpenDrawer = has(SearchParams.ShowCreateDashboardForm) || has(SearchParams.ShowEditDashboardForm);
  const action = has(SearchParams.ShowEditDashboardForm) ? 'Редактировать' : 'Создать';

  const handleDrawerClose = (): void => {
    remove(actionSearchParams);
  };

  if (!isOpenDrawer) return null;
  return (
    <Drawer
      width={434}
      title={`${action} ${dashboardTitles.base}`}
      onClose={handleDrawerClose}
    >
      <DashboardForm
        key={dashboardId}
      />
    </Drawer>
  );
}

import { Stack } from "@mui/system";
import Box from "@mui/system/Box";
import Divider from "@mui/material/Divider";

import Button from "@/shared/ui/Button";
import { ButtonSizes, ButtonVariants } from "@/shared/ui/Button/types";
import { TrashIcon } from "@/shared/ui/Icons/TrashIcon/TrashIcon";
import { DraggableItem } from "@/shared/ui/DraggableList";
import { amendDraggable } from "@/shared/lib/extendDndkit";

interface FieldItemProps {
  id: string;
  draggable: boolean;
  render: () => JSX.Element;
  handleRemove: () => void;
  isDivider: boolean;
}

export function FieldItem ({
  id,
  draggable,
  render,
  handleRemove,
  isDivider,
}: FieldItemProps) {

  const field = (
    <Stack
      direction='row'
      gap={2}
      alignItems='center'
    >
      <Box width='100%'>{render()}</Box>
      {handleRemove && (
        <div>
          <Button
            circle
            variant={ButtonVariants.Outlined}
            size={ButtonSizes.Small}
            onClick={handleRemove}
            {...amendDraggable(true)}
          >
            <TrashIcon />
          </Button>
        </div>
      )}
    </Stack>
  );

  return (
    <>
      <DraggableItem
        key={id}
        id={id}
        draggable={draggable}
      >
        {field}
      </DraggableItem>
      {isDivider && <Divider key={`divider-${id}`} />}
    </>
  )
}

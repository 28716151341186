
import {ReactFlowProvider} from "reactflow";
import React from "react";
import {FlowPlatformComponent} from "@/shared/ui/FlowPlatform/FlowPlatformComponent";

export interface FlowPlatformProps {
  children?: any;
}

export const FlowPlatform = ({children, ...props}: FlowPlatformProps) => {

  return ( <ReactFlowProvider>
    <FlowPlatformComponent {...props}>
      {children}
    </FlowPlatformComponent>
  </ReactFlowProvider>)
}
import dayjs from 'dayjs';
import { TimeRangePickerProps } from 'antd';

export const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 500000000;

export const RANGE_PICKER_PRESETS: TimeRangePickerProps['presets'] = [
  {
    label: 'Сегодня',
    value: [dayjs().hour(0).minute(0).second(0), dayjs().hour(23).minute(59).second(59)],
  },
  {
    label: 'Вчера',
    value: [
      dayjs().hour(0).minute(0).second(0).add(-1, 'd'),
      dayjs().hour(23).minute(59).second(59),
    ],
  },
  {
    label: 'Неделя',
    value: [
      dayjs().hour(0).minute(0).second(0).add(-7, 'd'),
      dayjs().hour(23).minute(59).second(59),
    ],
  },
  {
    label: 'Месяц',
    value: [
      dayjs().hour(0).minute(0).second(0).add(-30, 'd'),
      dayjs().hour(23).minute(59).second(59),
    ],
  },
];

export const DATE_TIME_FORMAT_STRING = 'dd.MM.yyyy | HH:mm:ss';

export const KEYBOARD_KEYS = {
  Backspace: {
    which: 8,
    key: 'Backspace',
    code: 'Backspace',
  },
  Tab: {
    which: 9,
    key: 'Tab',
    code: 'Tab',
  },
  Enter: {
    which: 13,
    key: 'Enter',
    code: 'Enter',
  },
  Shift: {
    which: 16,
    key: 'Shift',
    code: ['ShiftLeft', 'ShiftRight'],
  },
  ShiftLeft: {
    which: 16,
    key: 'Shift',
    code: 'ShiftLeft',
  },
  ShiftRight: {
    which: 16,
    key: 'Shift',
    code: 'ShiftRight',
  },
  Control: {
    which: 17,
    key: 'Control',
    code: ['ControlLeft', 'ControlRight'],
  },
  ControlLeft: {
    which: 17,
    key: 'Control',
    code: 'ControlLeft',
  },
  ControlRight: {
    which: 17,
    key: 'Control',
    code: 'ControlRight',
  },
  Alt: {
    which: 18,
    key: 'Alt',
    code: ['AltLeft', 'AltRight'],
  },
  AltLeft: {
    which: 18,
    key: 'Alt',
    code: 'AltLeft',
  },
  AltRight: {
    which: 18,
    key: 'Alt',
    code: 'AltRight',
  },
  Pause: {
    which: 19,
    key: 'Pause',
    code: 'Pause',
  },
  CapsLock: {
    which: 20,
    key: 'CapsLock',
    code: 'CapsLock',
  },
  Escape: {
    which: 27,
    key: 'Escape',
    code: 'Escape',
  },
  Space: {
    which: 32,
    key: '',
    code: 'Space',
  },
  PageUp: {
    which: 33,
    key: 'PageUp',
    code: 'PageUp',
  },
  PageDown: {
    which: 34,
    key: 'PageDown',
    code: 'PageDown',
  },
  End: {
    which: 35,
    key: 'End',
    code: 'End',
  },
  Home: {
    which: 36,
    key: 'Home',
    code: 'Home',
  },
  ArrowLeft: {
    which: 37,
    key: 'ArrowLeft',
    code: 'ArrowLeft',
  },
  ArrowUp: {
    which: 38,
    key: 'ArrowUp',
    code: 'ArrowUp',
  },
  ArrowRight: {
    which: 39,
    key: 'ArrowRight',
    code: 'ArrowRight',
  },
  ArrowDown: {
    which: 40,
    key: 'ArrowDown',
    code: 'ArrowDown',
  },
  PrintScreen: {
    which: 44,
    key: 'PrintScreen',
    code: 'PrintScreen',
  },
  Insert: {
    which: 45,
    key: 'Insert',
    code: 'Insert',
  },
  Delete: {
    which: 46,
    key: 'Delete',
    code: 'Delete',
  },
  Digit0: {
    which: 48,
    key: '0',
    code: 'Digit0',
  },
  Digit1: {
    which: 49,
    key: '1',
    code: 'Digit1',
  },
  Digit2: {
    which: 50,
    key: '2',
    code: 'Digit2',
  },
  Digit3: {
    which: 51,
    key: '3',
    code: 'Digit3',
  },
  Digit4: {
    which: 52,
    key: '4',
    code: 'Digit4',
  },
  Digit5: {
    which: 53,
    key: '5',
    code: 'Digit5',
  },
  Digit6: {
    which: 54,
    key: '6',
    code: 'Digit6',
  },
  Digit7: {
    which: 55,
    key: '7',
    code: 'Digit7',
  },
  Digit8: {
    which: 56,
    key: '8',
    code: 'Digit8',
  },
  Digit9: {
    which: 57,
    key: '9',
    code: 'Digit9',
  },
  A: {
    which: 65,
    key: 'a',
    code: 'KeyA',
  },
  B: {
    which: 66,
    key: 'b',
    code: 'KeyB',
  },
  C: {
    which: 67,
    key: 'c',
    code: 'KeyC',
  },
  D: {
    which: 68,
    key: 'd',
    code: 'KeyD',
  },
  E: {
    which: 69,
    key: 'e',
    code: 'KeyE',
  },
  F: {
    which: 70,
    key: 'f',
    code: 'KeyF',
  },
  G: {
    which: 71,
    key: 'g',
    code: 'KeyG',
  },
  H: {
    which: 72,
    key: 'h',
    code: 'KeyH',
  },
  I: {
    which: 73,
    key: 'i',
    code: 'KeyI',
  },
  J: {
    which: 74,
    key: 'j',
    code: 'KeyJ',
  },
  K: {
    which: 75,
    key: 'k',
    code: 'KeyK',
  },
  L: {
    which: 76,
    key: 'l',
    code: 'KeyL',
  },
  M: {
    which: 77,
    key: 'm',
    code: 'KeyM',
  },
  N: {
    which: 78,
    key: 'n',
    code: 'KeyN',
  },
  O: {
    which: 79,
    key: 'o',
    code: 'KeyO',
  },
  P: {
    which: 80,
    key: 'p',
    code: 'KeyP',
  },
  Q: {
    which: 81,
    key: 'q',
    code: 'KeyQ',
  },
  R: {
    which: 82,
    key: 'r',
    code: 'KeyR',
  },
  S: {
    which: 83,
    key: 's',
    code: 'KeyS',
  },
  T: {
    which: 84,
    key: 't',
    code: 'KeyT',
  },
  U: {
    which: 85,
    key: 'u',
    code: 'KeyU',
  },
  V: {
    which: 86,
    key: 'v',
    code: 'KeyV',
  },
  W: {
    which: 87,
    key: 'w',
    code: 'KeyW',
  },
  X: {
    which: 88,
    key: 'x',
    code: 'KeyX',
  },
  Y: {
    which: 89,
    key: 'y',
    code: 'KeyY',
  },
  Z: {
    which: 90,
    key: 'z',
    code: 'KeyZ',
  },
  Meta: {
    which: 91,
    key: 'Meta',
    code: ['MetaLeft', 'MetaRight'],
  },
  MetaLeft: {
    which: 91,
    key: 'Meta',
    code: 'MetaLeft',
  },
  MetaRight: {
    which: 92,
    key: 'Meta',
    code: 'MetaRight',
  },
  ContextMenu: {
    which: 93,
    key: 'ContextMenu',
    code: 'ContextMenu',
  },
  Numpad0: {
    which: 96,
    key: '0',
    code: 'Numpad0',
  },
  Numpad1: {
    which: 97,
    key: '1',
    code: 'Numpad1',
  },
  Numpad2: {
    which: 98,
    key: '2',
    code: 'Numpad2',
  },
  Numpad3: {
    which: 99,
    key: '3',
    code: 'Numpad3',
  },
  Numpad4: {
    which: 100,
    key: '4',
    code: 'Numpad4',
  },
  Numpad5: {
    which: 101,
    key: '5',
    code: 'Numpad5',
  },
  Numpad6: {
    which: 102,
    key: '6',
    code: 'Numpad6',
  },
  Numpad7: {
    which: 103,
    key: '7',
    code: 'Numpad7',
  },
  Numpad8: {
    which: 104,
    key: '8',
    code: 'Numpad8',
  },
  Numpad9: {
    which: 105,
    key: '9',
    code: 'Numpad9',
  },
  NumpadMultiply: {
    which: 106,
    key: '*',
    code: 'NumpadMultiply',
  },
  NumpadAdd: {
    which: 107,
    key: '+',
    code: 'NumpadAdd',
  },
  NumpadSubtract: {
    which: 109,
    key: '-',
    code: 'NumpadSubtract',
  },
  NumpadDecimal: {
    which: 110,
    key: '.',
    code: 'NumpadDecimal',
  },
  NumpadDivide: {
    which: 111,
    key: '/',
    code: 'NumpadDivide',
  },
  F1: {
    which: 112,
    key: 'F1',
    code: 'F1',
  },
  F2: {
    which: 113,
    key: 'F2',
    code: 'F2',
  },
  F3: {
    which: 114,
    key: 'F3',
    code: 'F3',
  },
  F4: {
    which: 115,
    key: 'F4',
    code: 'F4',
  },
  F5: {
    which: 116,
    key: 'F5',
    code: 'F5',
  },
  F6: {
    which: 117,
    key: 'F6',
    code: 'F6',
  },
  F7: {
    which: 118,
    key: 'F7',
    code: 'F7',
  },
  F8: {
    which: 119,
    key: 'F8',
    code: 'F8',
  },
  F9: {
    which: 120,
    key: 'F9',
    code: 'F9',
  },
  F10: {
    which: 121,
    key: 'F10',
    code: 'F10',
  },
  F11: {
    which: 122,
    key: 'F11',
    code: 'F11',
  },
  F12: {
    which: 123,
    key: 'F12',
    code: 'F12',
  },
  NumLock: {
    which: 144,
    key: 'NumLock',
    code: 'NumLock',
  },
  ScrollLock: {
    which: 145,
    key: 'ScrollLock',
    code: 'ScrollLock',
  },
  Semicolon: {
    which: 186,
    key: ';',
    code: 'Semicolon',
  },
  Equal: {
    which: 187,
    key: '=',
    code: 'Equal',
  },
  Comma: {
    which: 188,
    key: ',',
    code: 'Comma',
  },
  Minus: {
    which: 189,
    key: '-',
    code: 'Minus',
  },
  Period: {
    which: 190,
    key: '.',
    code: 'Period',
  },
  Slash: {
    which: 191,
    key: '/',
    code: 'Slash',
  },
  Backquote: {
    which: 192,
    key: '`',
    code: 'Backquote',
  },
  BracketLeft: {
    which: 219,
    key: '[',
    code: 'BracketLeft',
  },
  Backslash: {
    which: 220,
    key: '\\',
    code: 'Backslash',
  },
  BracketRight: {
    which: 221,
    key: ']',
    code: 'BracketRight',
  },
  Quote: {
    which: 222,
    key: "'",
    code: 'Quote',
  },
};

import { FC, useCallback } from 'react';

import { EdgeLabelRenderer, EdgeProps, getBezierPath, useStore } from 'reactflow';

import { PanelChooseConnects } from '../PanelChooseConnects/PanelChooseConnects';
import { getEdgeParams } from './utils/getEdgeParams';

import styles from './ConnectionEdge.module.scss';

export const ConnectionEdge: FC<EdgeProps> = ({ source, target }) => {
  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));

  if (!sourceNode || !targetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(sourceNode, targetNode);

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <g>
      <path className={styles.root} fill='none' stroke='#fff' strokeWidth={2} d={edgePath} />
      <circle cx={tx} cy={ty} fill='#222' r={3} stroke='#fff' strokeWidth={2} />
      <EdgeLabelRenderer>
        <PanelChooseConnects x={labelX} y={labelY} />
      </EdgeLabelRenderer>
    </g>
  );
};

import React, {FC, memo, useCallback, useMemo} from 'react';

import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import {Stack} from '@mui/system';
import {CodeEditor, ErrorIcon, FileField} from '@/shared/ui';
import {Handle, Position} from 'reactflow';
import cn from 'classnames';
import {DATE_TIME_FORMAT_STRING} from '@/shared/lib/constants';

import styles from './MessageNode.module.scss';
import {Tab} from "@/shared/ui/Tab/Tab";
import {Tabs} from './lib/types';
import MaterialTabs, {tabsClasses} from '@mui/material/Tabs';
import Typography from "@/shared/ui/Typography";
import {FileCards} from "@/shared/ui/FileCards/FileCards";
import Button from "@/shared/ui/Button";
import {ButtonSizes, ButtonVariants} from "@/shared/ui/Button/types";
import {DownloadIcon} from "@/shared/ui/Icons/DownloadIcon/DownloadIcon";
import Tooltip from "@/shared/ui/Tooltip";
import {bytesToSize} from "@/shared/lib/byteUtils";

export interface MessageNodeProps {
  id: string;
  processorType: string;
  processorTypeLocale: string | undefined;
  processorName: string;
  value: string;
  context?: string;
  files?: any;
  headers?: { [key: string]: string };
  queryParams?: { [key: string]: string };
  pathParams?: { [key: string]: string };
  exception?: string;
  stackTrace?: string;
  messageId?: string;
  dateExecute?: string;
  timeExecute?: number;
  onDownloadBody?: any;
  valueSize?: number;
}

export const MessageNode: FC<{ data: MessageNodeProps }> = memo(
  ({
     data: {
       processorType,
       processorTypeLocale,
       processorName,
       value,
       context,
       headers,
       queryParams,
       pathParams,
       files,
       id,
       exception,
       stackTrace,
       messageId,
       dateExecute,
       timeExecute,
         onDownloadBody,
         valueSize
     }
   }) => {
    const codeEditorTitle = useMemo(() => {
      if (processorTypeLocale || processorType) {
        return `${processorName} | ${processorTypeLocale || processorType}`;
      }

      return processorName;
    }, [processorName, processorType, processorTypeLocale]);

    const [activeTab, setActiveTab] = React.useState<Tabs>(Tabs.body);
    const handleTabClick = useCallback(
      (tab: Tabs) => () => {
        setActiveTab(tab);
      },
      []
    );

    const formattedDate = useMemo(() => {
      if (dateExecute) {
        const date = parseISO(dateExecute);

        if (dateExecute) {
          return format(date, DATE_TIME_FORMAT_STRING);
        }
      }

      return null;
    }, [dateExecute]);

    const getText = (activeTab: Tabs) => {
      switch (activeTab) {
        case Tabs.body:
          return value;
        case Tabs.context:
          return context;
        case Tabs.pathParams:
          return JSON.stringify(pathParams)
        case Tabs.headers:
          return JSON.stringify(headers)
        case Tabs.queryParams:
          return JSON.stringify(queryParams)
        case Tabs.stackTrace:
          return stackTrace
      }
    }

    const renderTabs = () => {
      return (<MaterialTabs
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs"
        sx={{
          [`& .${tabsClasses.scrollButtons}`]: {
            '&.Mui-disabled': {opacity: 0.3},
          },
        }}
      >
        {value && <Tab isActive={activeTab === Tabs.body} onClick={handleTabClick(Tabs.body)}>
          <Typography size={"small"}>
            Сообщение
          </Typography>
            {onDownloadBody ? <Tooltip title={`Загрузить сообщение (${bytesToSize(valueSize)})`} placement='top'>
              <span>
                <Button
                    className={styles.downloadButton}
                    circle
                    variant={ButtonVariants.Outlined}
                    size={ButtonSizes.Small}
                    onClick={onDownloadBody}
                >
                  <DownloadIcon />
                </Button>
              </span>
            </Tooltip> : undefined}
        </Tab>}
        {context && <Tab isActive={activeTab === Tabs.context} onClick={handleTabClick(Tabs.context)}>
          <Typography size={"small"}>
            Контекст
          </Typography>
        </Tab>}
        {files && <Tab isActive={activeTab === Tabs.files} onClick={handleTabClick(Tabs.files)}>
          <Typography size={"small"}>
            Файлы
          </Typography>
        </Tab>}
        {headers && <Tab isActive={activeTab === Tabs.headers} onClick={handleTabClick(Tabs.headers)}>
          <Typography size={"small"}>
            Заголовки
          </Typography>
        </Tab>}
        {pathParams && <Tab isActive={activeTab === Tabs.pathParams} onClick={handleTabClick(Tabs.pathParams)}>
          <Typography size={"small"}>
            Параметры пути
          </Typography>
        </Tab>}
        {queryParams &&
          <Tab isActive={activeTab === Tabs.queryParams} onClick={handleTabClick(Tabs.queryParams)}>
            <Typography size={"small"}>
              Параметры запроса
            </Typography>
          </Tab>
        }
        {stackTrace &&
          <Tab isActive={activeTab === Tabs.stackTrace} onClick={handleTabClick(Tabs.stackTrace)}>
            <Typography size={"small"}>
              StackTrace ошибки
            </Typography>
          </Tab>
        }
      </MaterialTabs>)
    }
    return (
      <>
        <Handle className={styles.handle} type='source' position={Position.Right} id={id}/>
        <Handle
          className={cn(styles.handle, styles.hidden)}
          type='target'
          position={Position.Left}
          id={id}
        />
        <Stack className={cn(styles.root, 'nodrag', 'nowheel')}>
          <Stack className={styles.header} direction='row' alignItems='center' gap={1.5}>
            <Stack
              direction='row'
              gap={2.5}
              justifyContent='space-between'
              alignItems='center'
              width='100%'
            >
              <Stack direction='row' gap={2.5} overflow='hidden' alignItems='center'>
                {processorName && (
                  <p title={processorName} className={styles.processorName}>
                    {processorName}
                  </p>
                )}
                <p className={styles.processorType}>{processorTypeLocale || processorType}</p>
              </Stack>
              {exception && <ErrorIcon tooltip={exception}/>}
            </Stack>
          </Stack>
          <Stack className={styles.tabs} direction='row' gap={0.5} alignItems='center'>
            {renderTabs()}
          </Stack>
          {activeTab !== Tabs.files ? <CodeEditor
            readonly
            hideFormatSelect
            hideLanguageSelect
            value={getText(activeTab)}
            title={codeEditorTitle}
            height={'100%'}
            minimapEnabled={false}
            fullScreenHeader={renderTabs()}
          /> : <FileCards attachments={files} isRemove={false}/>
            }
          <Stack className={styles.dates} direction='row'>
            <Stack flex='1'>
              <span className={styles.text}>{timeExecute !== undefined && `${timeExecute} мс`}</span>
            </Stack>
            <Stack flex='1' alignItems='flex-end'>
              <span className={styles.text}>{formattedDate}</span>
            </Stack>
          </Stack>
        </Stack>
      </>
    );
  }
);

MessageNode.displayName = 'MessageNode';
